import React, { Component } from 'react'
import { connect } from 'react-redux'

import PDFViewer from '../Components/WebComponents/DisplayPdf.js'
import DisplayExcel from '../Components/WebComponents/DisplayExcel.js'
import DisplayXML from '../Components/WebComponents/DisplayXML.js'
import DisplayTiff from '../Components/WebComponents/DisplayTiff.js'
import ProcessData from './DisplayProcessData.js'
import Dropdown from '../Components/WebComponents/dropdown.js'
import Select from '../Components/Forms/Fields/select.js'

import { updateDatasheetProcessData, downloadDatasheetExcel, processSingleDatasheetPdf } from '../store/actions.js'

class ViewDS extends Component {
  constructor(props) {
    super(props)
    this.datasheetStatusRef = React.createRef()

    this.state = {
      checkInByForce: false,
      processedData: null,
      loading: true,
      showPages: false,
      pages: [],
      selectedPages: [],
      pageNumber: 1,
      processing: false,
      saveData: false,
      savingData: false,
      mode: 'Searchable PDF',
      pageTemplateModeRelation: {},
      infoCollapse: false,
    }
  }

  componentDidMount() {
    this.setState(
      {
        processedData: this.props.processedData,
        processErrorMessage: this.props.processErrorMessage,
        pages: new Array(this.props.file.pages).fill(false),
        loading: false,
      },
      this.formatPageTemplateModeRelation
    )
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  componentDidUpdate(props) {
    if (this.props.processedData !== props.processedData) {
      this.setState({ processedData: this.props.processedData }, this.formatPageTemplateModeRelation)
    }

    if (this.props.file.pages !== props.file.pages) {
      this.setState({
        pages: new Array(this.props.file.pages).fill(false),
        selectedPages: [],
        showPages: false,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  handleBeforeUnload = (e) => {
    e.preventDefault()
    if (this.props.allowEdit) {
      this.props.updateDocumentStatus(undefined, undefined, true, undefined)
      this.setState({ checkInByForce: true })
    }

    e.returnValue = ''
    return ''
  }

  formatPageTemplateModeRelation = () => {
    let pageTemplateModeRelation = []
    for (const [key, value] of Object.entries(this.state.processedData)) {
      const index = pageTemplateModeRelation.findIndex((relation) => relation.template === value.template && relation.mode === value.mode)
      if (index > -1) {
        pageTemplateModeRelation[index].pages.push(value.page)
      } else {
        pageTemplateModeRelation.push({
          template: value.template,
          mode: value.mode,
          pages: [value.page],
          key: key,
        })
      }
    }

    this.setState({ pageTemplateModeRelation: pageTemplateModeRelation })
  }

  downloadExcelHandler = async (fileName, fileId) => {
    const formData = new FormData()
    formData.append('datasheetIds', fileId)
    formData.append('userId', this.props.user.userId)
    formData.append('projectName', this.props.projectName)
    formData.append('projectId', this.props.projectId)
    await this.props.downloadDatasheetExcel(formData)

    if (this.props.user.downloadDatasheetExcelOutput.xlsx) {
      const base64 = `data:application/vnd.ms-excel;base64,${this.props.user.downloadDatasheetExcelOutput.xlsx}`
      const pdfContentType = 'application/vnd.ms-excel'

      const base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length)
        const bytes = atob(base64WithoutPrefix)
        let length = bytes.length
        let out = new Uint8Array(length)

        while (length--) {
          out[length] = bytes.charCodeAt(length)
        }

        return new Blob([out], { type: pdfContentType })
      }

      const url = window.URL.createObjectURL(base64toBlob(base64))
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = url
      a.download = `${fileName.split('.')[0]}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }

  processPdfHandler = async (mode, pages, reprocess = false, file_type = null) => {
    this.setState({ processing: true })

    const formData = new FormData()
    formData.append('userId', this.props.user.userId)
    formData.append('projectName', this.props.projectName)
    formData.append('projectId', this.props.projectId)
    formData.append('pdfName', this.props.file.name)
    formData.append('datasheetId', this.props.file.id)
    formData.append('mode', mode)
    formData.append('pages', pages)
    formData.append('reprocess', reprocess)
    formData.append('file_type', file_type)
    await this.props.processSingleDatasheetPdf(formData)

    if (!this.props.user.displayMessageCode) {
      this.reloadProcessData()
    }
    this.setState({ processing: false })
  }

  updatePage = (page) => {
    this.setState({ pageNumber: page })
  }

  pageHandler = (page) => {
    let { selectedPages } = this.state

    if (selectedPages.includes(page)) {
      const index = selectedPages.indexOf(page)
      selectedPages.splice(index, 1)
    } else {
      selectedPages.push(page)
    }

    this.setState({ selectedPages: selectedPages })
  }

  setDropdownOpen = (obj) => {
    this.setState(obj)
  }

  updateDatasheetProcessData = async (processData) => {
    let jsonProcessData = {}
    processData.map((data) => {
      let oldProcessData = jsonProcessData
      jsonProcessData = { ...oldProcessData, [data.tag]: data.value }
      return null
    })

    const formData = new FormData()
    formData.append('name', this.props.file.name)
    formData.append('datasheetId', this.props.file.id)
    formData.append('userId', this.props.user.userId)
    formData.append('projectName', this.props.projectName)
    formData.append('projectId', this.props.projectId)
    formData.append('processData', JSON.stringify(jsonProcessData))
    await this.props.updateDatasheetProcessData(formData)

    setTimeout(() => {
      this.props.fetchProcessData(this.props.file.id)
    }, 10000)
  }

  reloadProcessData = () => {
    this.props.fetchProcessData(this.props.file.id)
  }

  savingData = (save) => {
    this.setState({ savingData: save })
  }

  processedDataTable() {
    let dataArray = []
    for (const [key, value] of Object.entries(this.state.processedData)) {
      dataArray.push({ tag: key, value })
    }

    return (
      <ProcessData
        processedData={dataArray}
        updateDatasheet={this.updateDatasheetProcessData}
        updatePage={this.updatePage}
        saveData={this.state.saveData}
        savingData={this.savingData}
        uom_options={this.props.uom_options}
        allowEdit={this.props.allowEdit}
      />
    )
  }

  renderDatasheetStatusDropdownItems = () => {
    return (
      <React.Fragment>
        <button
          id="view_datasheet_mark_cleaned"
          type="button"
          className="dropdown-item"
          disabled={!this.props.allowed_status.includes('cleaned')}
          onClick={() => {
            this.props.updateDocumentStatus(true, undefined, undefined, undefined)
            let status = this.props.status
            const index = status.findIndex((s) => s === 'cleaned')
            if (index > -1) {
              status.splice(index, 1)
            } else {
              status.push('cleaned')
            }
            this.props.updateStatus(status)
          }}
        >
          <label className={`checkbox ${this.props.status.includes('cleaned') ? 'checked' : ''}`} id="unique-45258514label_1" /> Mark Cleaned
        </button>
        <button
          id="view_datasheet_mark_reviewed"
          type="button"
          className="dropdown-item"
          disabled={!this.props.allowed_status.includes('reviewed')}
          onClick={() => {
            this.props.updateDocumentStatus(undefined, undefined, undefined, true)
            let status = this.props.status
            const index = status.findIndex((s) => s === 'reviewed')
            if (index > -1) {
              status.splice(index, 1)
            } else {
              status.push('reviewed')
            }
            this.props.updateStatus(status)
          }}
        >
          <label className={`checkbox ${this.props.status.includes('reviewed') ? 'checked' : ''}`} id="unique-11548627label_2" /> Mark Reviewed
        </button>
        <button
          id="view_datasheet_mark_approved"
          type="button"
          className="dropdown-item"
          disabled={!this.props.allowed_status.includes('approved')}
          onClick={() => {
            this.props.updateDocumentStatus(undefined, true, undefined, undefined)
            let status = this.props.status
            const index = status.findIndex((s) => s === 'approved')
            if (index > -1) {
              status.splice(index, 1)
            } else {
              status.push('approved')
            }
            this.props.updateStatus(status)
          }}
        >
          <label className={`checkbox ${this.props.status.includes('approved') ? 'checked' : ''}`} id="unique-31501986label_3" /> Mark Approved
        </button>
      </React.Fragment>
    )
  }

  render() {
    let product = this.props.product.toLowerCase()
    let download_hidden = this.props.user.user_actions.includes(`${product}_view_download_hidden`)
    let save_hidden = this.props.user.user_actions.includes(`${product}_view_save_hidden`)
    let process_hidden = this.props.user.user_actions.includes(`${product}_view_process_hidden`)
    let download_intermediate_file_hidden = this.props.user.user_actions.includes(`${product}_download_intermediate_file_hidden`)
    let reprocess_hidden = this.props.user.user_actions.includes(`${product}_view_reprocess_hidden`)

    return this.state.loading && !this.state.processedData ? (
      <div className="text-center" id="unique-47207841div_1">
        <div className="spinner" id="unique-78762230div_2" />
      </div>
    ) : (
      <div
        id="unique-90052261div_3"
        onMouseOver={() => {
          if (this.state.checkInByForce && !this.props.allowEdit) {
            this.props.updateDocumentStatus(undefined, undefined, false, undefined)
            this.setState({ checkInByForce: false })
          }
        }}
      >
        <div className="card mb-2" id="unique-98760226div_4">
          <div className="card-body p-3" id="unique-10323584div_5">
            <form
              className="clearfix"
              id="unique-61205304form_1"
              onSubmit={(event) => {
                event.preventDefault()
                this.setState({ showPages: false })
                this.processPdfHandler(this.state.mode, this.state.selectedPages.toString(), false)
              }}
            >
              {!download_hidden ? (
                <button
                  id="view_datasheet_download_excel_btn"
                  className={`btn btn-primary btn-sm float-right`}
                  type="button"
                  onClick={() => {
                    this.downloadExcelHandler(this.props.file.name, this.props.file.id)
                  }}
                  disabled={this.state.savingData}
                >
                  Download
                </button>
              ) : null}

              {!save_hidden ? (
                <button
                  id="view_datasheet_save_btn"
                  type="button"
                  onClick={() =>
                    this.setState({
                      saveData: !this.state.saveData,
                    })
                  }
                  className={`btn btn-primary btn-sm float-right mr-2`}
                  disabled={!this.props.allowEdit || this.state.savingData}
                >
                  Save
                </button>
              ) : null}

              <Dropdown
                ref={this.datasheetStatusRef}
                disabled={this.props.allowEdit || this.state.savingData}
                isDropdownOpen={this.state.status_toggle}
                stateName="status_toggle"
                dropdownClass="dropdown float-right mr-2"
                dropdownPositionClass=""
                btnClass={`btn-primary btn-sm`}
                btnName="Datasheet Status"
                idPrefix="view_datasheet"
                setDropdownOpen={this.setDropdownOpen}
                render={this.renderDatasheetStatusDropdownItems}
              />

              <button
                id="view_datasheet_check_in_toggler_btn"
                type="button"
                className="btn btn-sm float-right"
                disabled={!this.props.allowCheckInCheckOut || this.state.savingData}
                onClick={() => this.props.updateDocumentStatus(undefined, undefined, this.props.allowEdit, undefined)}
              >
                <span className="badge badge-light p-2">{this.props.allowEdit ? 'Checked Out' : 'Checked In'}</span>{' '}
                {this.props.allowEdit ? (
                  <span className="badge badge-danger badge-pill" title="Check In" style={{ lineHeight: '18px', width: '24px' }} id="unique-28469343span_2">
                    <i className="fa fa-angle-up" id="unique-26101827i_1" />
                  </span>
                ) : (
                  <span className="badge badge-success badge-pill" title="Check Out" style={{ lineHeight: '18px', width: '24px' }} id="unique-19398479span_3">
                    <i className="fa fa-angle-down" id="unique-72691383i_2" />
                  </span>
                )}
              </button>

              <h4 className="d-inline-block mr-3" id="unique-11183599h4_1">
                {this.props.file.name}
              </h4>
              <button
                id="view_datasheet_info_toggler_btn"
                type="button"
                onClick={() =>
                  this.setState({
                    infoCollapse: !this.state.infoCollapse,
                  })
                }
                className="btn btn-sm"
              >
                {this.state.infoCollapse ? <i className="fa fa-angle-up" id="unique-86554634i_3" /> : <i className="fa fa-angle-down" id="unique-11926741i_4" />}
              </button>

              {this.props.selectedFiles.length > 1 ? (
                <div className="form-control-sm d-inline-block mx-2" style={{ width: 250 }} id="unique-94609606div_6">
                  <Select
                    options={this.props.selectedFiles.map((file) => {
                      return {
                        label: file.name,
                        value: file.id,
                      }
                    })}
                    value={{
                      label: this.props.file.name,
                      value: this.props.file.id,
                    }}
                    onChange={(selectedFile) => {
                      selectedFile = this.props.selectedFiles.find((file) => file.id === selectedFile.value)
                      this.props.viewDatasheetFile(selectedFile)
                    }}
                    isMulti={false}
                    isClearable={false}
                  />
                </div>
              ) : null}

              <div className="d-inline-block" style={{ width: 200 }} id="unique-10986733div_7">
                <div className="input-group input-group-sm" id="unique-13233579div_8">
                  <input type="text" name="pages" className="form-control" placeholder="Select Page No." value={this.state.selectedPages} readOnly id="unique-53727050input_1" />
                  <div className="input-group-append" id="unique-81208434div_9">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ borderRadius: 0 }}
                      id="unique-62058535button_8"
                      onClick={() => {
                        this.setState({
                          showPages: !this.state.showPages,
                        })
                      }}
                    >
                      {!this.state.showPages ? <i className="fa fa-caret-down" id="unique-98206439i_5" /> : <i className="fa fa-caret-up" id="unique-29298401i_6" />}
                    </button>
                    {this.state.showPages ? (
                      <div className="dropdown-menu" style={{ zIndex: '3001' }} id="unique-34402786div_10">
                        {this.state.pages.map((page, index) => {
                          return (
                            <div id="unique-92198362div_11" className="dropdown-item" key={index} onClick={() => this.pageHandler(index + 1)}>
                              <label
                                className={`checkbox d-inline-block mr-2 text-center ${this.state.selectedPages.includes(index + 1) ? 'checked' : ''}`}
                                style={{ fontSize: 13 }}
                                id="unique-92141731label_4"
                              />
                              {index + 1}
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-inline-block ml-2" style={{ width: 200 }} id="unique-39553781div_12">
                <select onChange={(e) => this.setState({ mode: e.target.value })} className="form-control form-control-sm" value={this.state.mode}>
                  <option value="Searchable PDF">Searchable PDF</option>
                  <option value="Searchable with no Lines">Searchable with no Lines</option>
                  <option value="None Searchable">None Searchable</option>
                  <option value="Handwritten">Handwritten</option>
                </select>
              </div>

              {!process_hidden ? (
                <button type="submit" className={`btn btn-primary btn-sm mr-2 ml-2`} disabled={this.state.savingData} id="unique-27890728button_9">
                  Process
                </button>
              ) : null}

              {!download_intermediate_file_hidden ? (
                <button
                  className={`btn btn-primary mr-2 btn-sm`}
                  type="button"
                  disabled={this.state.savingData}
                  id="unique-56795821button_10"
                  onClick={() => {
                    this.setState({ showPages: false })
                    this.props.downloadIntermediateFile(this.props.file.id, this.state.mode)
                  }}
                >
                  Download Intermediate File
                </button>
              ) : null}

              {reprocess_hidden ? (
                <button
                  className={`btn btn-primary btn-sm mr-2`}
                  type="button"
                  id="unique-26002714button_11"
                  onClick={() => {
                    this.setState({ showPages: false })
                    this.processPdfHandler(this.props.file.name.includes('.pdf') ? this.state.mode : 'Searchable PDF', this.state.selectedPages.toString(), true)
                  }}
                  disabled={this.state.savingData}
                >
                  Reprocess
                </button>
              ) : null}
            </form>

            {this.state.infoCollapse ? (
              <div className="p-3 border mb-2" style={{ height: '12rem' }} id="unique-31609065div_13">
                <div className="row" id="unique-82580204div_14">
                  <div className="col-4" id="unique-65766394div_15">
                    <div className="mb-1" id="unique-10531867div_16">
                      <b>File Name: </b> {this.props.file.name}
                    </div>
                    <div className="mb-1" id="unique-60638199div_17">
                      <b>Discipline: </b> {this.props.file.discipline}
                    </div>
                    <div className="mb-1" id="unique-20544034div_18">
                      <b>Category: </b> {this.props.file.category}
                    </div>
                    <div className="mb-1" id="unique-35227909div_19">
                      <b>Sub Category: </b> {this.props.file.subcategory}
                    </div>
                    <div id="unique-45471687div_20">
                      <b>Manufacturer: </b> {this.props.file.manufacturer}
                    </div>
                  </div>

                  <div className="col" id="unique-29735189div_21">
                    <h5 id="unique-18788688h5_1">Processing Mode & Template Information</h5>

                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr id="unique-64272762tr_1">
                          <th id="unique-10777959th_1">Mode</th>
                          <th id="unique-11480203th_2">Template</th>
                          <th id="unique-94281449th_3">Page(s)</th>
                        </tr>
                      </thead>
                      <tbody id="unique-74949303tbody_1">
                        {this.state.pageTemplateModeRelation.map((relation, index) => {
                          return (
                            <tr key={index} id="unique-58745828tr_2">
                              <td id="unique-34719636td_1">{relation.mode}</td>
                              <td id="unique-83429324td_2">{relation.template}</td>
                              <td id="unique-10113039td_3">{relation.pages.join(', ')}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="row mb-3" id="unique-85563757div_22">
          <div className="col-md-6" id="unique-10430712div_23">
            {!this.props.fetchingDocument ? (
              this.props.file.name.includes('.pdf') ? (
                <PDFViewer pdfBase64={this.props.base64File} />
              ) : this.props.file.name.includes('.xlsx') ||
                this.props.file.name.includes('.xls') ||
                this.props.file.name.includes('.XLSX') ||
                this.props.file.name.includes('.XLS') ? (
                <DisplayExcel excelBase64={this.props.base64File} fileName={this.props.file.name} />
              ) : this.props.file.name.includes('.tiff') || this.props.file.name.includes('.tif') ? (
                <DisplayTiff tiffBase64={this.props.base64File} />
              ) : (
                <DisplayXML xmlData={this.props.base64File} />
              )
            ) : (
              <div className="text-center" style={{ height: '40rem' }}>
                <div className="spinner"></div>
              </div>
            )}
          </div>
          <div className="col-md-6" id="unique-53509012div_26">
            {this.state.processing ? (
              <div className="text-center" id="unique-35597829div_27">
                <div className="spinner" id="unique-86879399div_28" />
                <p id="unique-78071867p_1">Processing {this.props.file.name} ...</p>
              </div>
            ) : !this.state.processedData || JSON.stringify(this.state.processedData) === JSON.stringify({}) ? null : (
              this.processedDataTable()
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  updateDatasheetProcessData,
  downloadDatasheetExcel,
  processSingleDatasheetPdf,
})(ViewDS)
