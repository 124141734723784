import { combineReducers } from 'redux'

const INITIAL_USER_STATE = {
  isSignedIn: false,
  errorMessage: null,
  userId: null,
  username: null,
  version_deatils: [],
  user_actions: [],
  user_clients: [],
  email: null,
  role: null,
  is_forcereset: false,
  show_migration: false,
  show_comparison: false,
  resetPassword: null,
  projects: [],
  projectsData: [],
  singleProjectData: null,
  singleProjectDataPdfs: null,
  projectFiles: [],
  fileStatus: {},
  hotspotXML: null,
  downloadDeliverablesOutput: null,
  singleProjectActiveDrawing: null,
  errorMessageSingleProject: null,
  products: [],
  userProducts: [],
  errorMessageUploadFile: null,
  documentUploadOptions: {},
  sppidProcessingStatusList: [],
  checkPdfMessage: '',
  checkPdfSimilarFiles: [],
  uploadSharePointPathMessage: {},
  excelMappingFileRelationshipMap: {},
  image_url: '',
  processDrawings: [],
  componentListInit: [],
  updateProductDeliverableExcel: null,
  deliverableData: {},
  AvevaConfigExcel: false,
  customDeliverableData: null,
  qualityMetricsExcel: null,
  qualityPlotsDrawing: null,

  roles: [],
  companies: [],
  measurementSystemCodes: [],
  epc_companies: [],

  clients: [],
  client: {},

  users: [],
  clientsAllData: [],
  userClientsAllData: [],
  companyAllData: [],
  disciplineAllData: [],
  user: {},
  projectUserAccess: [],
  allProducts: [],
  singleProduct: null,
  allProjects: [],
  deleteProjectMessage: null,
  fetchConfigData: [],
  fetchConfigList: [],
  configComponentsOptions: {},
  configClientName: null,

  createdProjectId: null,
  projectInfo: {},
  projectSetupStep: 1,
  projectCompanyOptions: [],

  datasheetData: {},
  datasheetProcessUpdates: [],

  tradeDocsData: {},
  processTradeDocMessages: null,
  tradeDocProcessUpdates: [],
  tradeDocumentIUIData: {},
  tradeDocumentReportsParams: {},
  tradeDocumentReports: {},

  processedPdfOutput: null,
  intermediateFile: null,
  base64File: null,
  updateDatasheetProcessedData: null,
  downloadDatasheetExcelOutput: null,
  datasheetUploadOptions: {},
  datasheetMappingOptions: [],

  taggingConfigData: null,
  taggingConfigDataErrorMessage: null,
  templateData: null,
  tagMatchingTemplates: null,
  templateDataErrorMessage: null,
  testTaggingConfigData: null,
  tagTitleRegion: null,

  propertyConfigData: null,
  propertyConfigDrawing: null,
  propertyConfigDataErrorMessage: null,
  testPropertyConfigData: null,
  propertyConfigConstants: null,

  modelsInfo: [],
  modelsDescription: {},
  selectedSymbols: [],
  drawingsPackageSelectedDrawings: [],
  drawingsPackageSelectedPackages: [],
  drawingsPackageStatus: null,
  taggingConfigurationFormData: {},

  processDrawingsPackageImageData: {},
  processDrawingsModelConfigurationDrawings: [],
  processDrawingsPacakgeData: [],
  processDrawingsPacakgeCountData: {},
  processDrawingsPacakgeStatusFlags: {},
  modelConfigurationRevertStatusFlags: {},
  modelConfigurationInitiateTrainingMessage: {},

  displayMessageCode: null,

  cadGeneratorParameters: {},

  report_form_params: {},
  available_report_types: [],
  reports: {},
  report_excel: null,
  report_file: null,
  clientProjectData: [],
  report_list: [],
  upload_report_list_status: '',
  report_upload_status: null,
  master_list_data: [],
  report_id: null,
  report_sort: {},
  reportData: {},
  editReportData: {},

  iuiData: {},
  updateIUIDataMessage: null,
  IUIEnhanceDataState: null,

  pdfListColumnWidths: [],
  drawingListColumnWidths: [],
  dsPdfListColumnWidths: [],

  lovData: [],
}

const userData = (state = INITIAL_USER_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_DATA':
      return {
        ...state,
        isSignedIn: false,
        errorMessage: null,
        userId: null,
        username: null,
        version_deatils: [],
        user_actions: [],
        user_clients: [],
        email: null,
        role: null,
        is_forcereset: false,
        show_migration: false,
        show_comparison: false,
        resetPassword: null,
        projects: [],
        projectsData: [],
        singleProjectData: null,
        singleProjectDataPdfs: null,
        projectFiles: [],
        fileStatus: {},
        hotspotXML: null,
        downloadDeliverablesOutput: null,
        singleProjectActiveDrawing: null,
        products: [],
        userProducts: [],
        errorMessageUploadFile: null,
        documentUploadOptions: {},
        updatePdfUploadStatus: {},
        sppidProcessingStatus: [],
        checkPdfMessage: '',
        checkPdfSimilarFiles: [],
        uploadSharePointPathMessage: {},
        excelMappingFileRelationshipMap: {},
        image_url: '',
        processDrawings: [],
        componentListInit: [],
        updateProductDeliverableExcel: null,
        deliverableData: {},
        AvevaConfigExcel: false,
        customDeliverableData: null,
        qualityMetricsExcel: null,
        qualityPlotsDrawing: null,

        roles: [],
        companies: [],
        measurementSystemCodes: [],
        epc_companies: [],

        clients: [],
        client: {},

        users: [],
        clientsAllData: [],
        userClientsAllData: [],
        companyAllData: [],
        disciplineAllData: [],
        user: {},
        projectUserAccess: [],
        allProducts: [],
        singleProduct: null,
        allProjects: [],
        deleteProjectMessage: null,
        fetchConfigData: [],
        fetchConfigList: [],
        configComponentsOptions: {},
        configClientName: null,

        createdProjectId: null,
        projectInfo: {},
        projectSetupStep: 1,
        projectCompanyOptions: [],

        datasheetData: {},
        datasheetProcessUpdates: [],

        tradeDocsData: {},
        processTradeDocMessages: null,
        tradeDocProcessUpdates: [],
        tradeDocumentIUIData: {},
        tradeDocumentReportsParams: {},
        tradeDocumentReports: {},

        processedPdfOutput: null,
        intermediateFile: null,
        base64File: null,
        updateDatasheetProcessedData: null,
        downloadDatasheetExcelOutput: null,
        datasheetUploadOptions: {},
        datasheetMappingOptions: [],

        taggingConfigData: null,
        taggingConfigDataErrorMessage: null,
        templateData: null,
        tagMatchingTemplates: null,
        templateDataErrorMessage: null,
        testTaggingConfigData: null,
        tagTitleRegion: null,

        propertyConfigData: null,
        propertyConfigDrawing: null,
        propertyConfigDataErrorMessage: null,
        testPropertyConfigData: null,
        propertyConfigConstants: null,

        modelsInfo: [],
        modelsDescription: {},
        selectedSymbols: [],
        drawingsPackageSelectedDrawings: [],
        drawingsPackageSelectedPackages: [],
        drawingsPackageStatus: null,
        taggingConfigurationFormData: {},

        processDrawingsPackageImageData: {},
        processDrawingsModelConfigurationDrawings: [],
        processDrawingsPacakgeData: [],
        processDrawingsPacakgeCountData: {},
        processDrawingsPacakgeStatusFlags: {},
        modelConfigurationRevertStatusFlags: {},
        modelConfigurationInitiateTrainingMessage: {},

        displayMessageCode: null,

        cadGeneratorParameters: {},

        report_form_params: {},
        available_report_types: [],
        reports: {},
        report_excel: null,
        report_file: null,
        clientProjectData: [],
        report_list: [],
        upload_report_list_status: '',
        report_upload_status: null,
        master_list_data: [],
        report_id: null,
        report_sort: {},
        reportData: {},
        editReportData: {},

        iuiData: {},
        updateIUIData: {},
        IUIEnhanceDataState: null,

        pdfListColumnWidths: [],
        drawingListColumnWidths: [],
        dsPdfListColumnWidths: [],

        lovData: [],
      }
    case 'LOGOUT_USER':
      return {
        ...state,
        isSignedIn: undefined,
      }
    case 'LOGIN_USER':
      return {
        ...state,
        errorMessage: null,
        isSignedIn: true,
        userId: action.payload.id,
        email: action.payload.email,
        username: action.payload.name,
        user_clients: action.payload.clients,
        role: action.payload.role,
        show_migration: action.payload.show_migration,
        show_comparison: action.payload.show_comparison,
      }
    case 'REFRESH_USER_AUTH':
      return {
        ...state,
        isSignedIn: true,
      }
    case 'RESET_PASSWORD':
      return {
        ...state,
        resetPassword: action.payload,
      }
    case 'VERSION_DETAILS':
      return { ...state, version_details: action.payload }
    case 'USER_ACTIONS':
      return { ...state, user_actions: action.payload.Actions }
    case 'ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        is_forcereset: action.payload.is_forcereset,
      }
    case 'PROJECT_DOCUMENT_UPLOAD_OPTIONS':
      return { ...state, documentUploadOptions: action.payload }
    case 'UPLOAD_SHAREPOINT_PATH_MESSAGE':
      return { ...state, uploadSharePointPathMessage: action.payload }
    case 'EXCEL_MAPPING_FILE_RELATIONSHIP_MAP':
      return { ...state, excelMappingFileRelationshipMap: action.payload }
    case 'UPDATE_PDF_UPLOAD_STATUS':
      return {
        ...state,
        updatePdfUploadStatus: action.payload,
      }

    case 'CHECK_PDF_MESSAGE':
      return {
        ...state,
        checkPdfMessage: action.payload.message,
        checkPdfSimilarFiles: action.payload.similarFilesDict,
      }
    case 'CLEAR_CHECK_PDF_MESSAGE':
      return {
        ...state,
        checkPdfMessage: '',
        checkPdfSimilarFiles: [],
      }
    case 'FETCH_PROJECTS_DATA':
      return { ...state, projectsData: action.payload.projectsData }
    case 'FETCH_PROJECTS':
      return { ...state, projects: action.payload.projects }

    // Project Dashboard
    case 'FETCH_SINGLE_PROJECT_DATA':
      return { ...state, singleProjectData: action.payload.project }
    case 'FETCH_SINGLE_PROJECT_DATA_PDFS':
      return { ...state, singleProjectDataPdfs: action.payload }
    case 'FETCH_PROJECT_FILES':
      return { ...state, projectFiles: action.payload }
    case 'UPDATE_FILE_STATUS':
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
        fileStatus: action.payload,
      }
    case 'UPDATE_ASSIGNMENT_STATUS':
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
        fileAssignmentStatus: action.payload,
      }
    case 'HOTSPOT_XML':
      return {
        ...state,
        hotspotXML: action.payload,
      }
    case 'DOWNLOAD_DELIVERABLES':
      return { ...state, downloadDeliverablesOutput: action.payload }
    case 'SPPID_PROCESSING_STATUS':
      return { ...state, sppidProcessingStatusList: action.payload }
    case 'FETCH_SINGLE_PROJECT_ACTIVE_DRAWING':
      return {
        ...state,
        singleProjectActiveDrawing: action.payload.drawing,
      }
    case 'FETCH_USER_PRODUCTS':
      return {
        ...state,
        userProducts: action.payload.userProducts,
      }
    case 'FETCH_PRODUCTS':
      return { ...state, products: action.payload.products }
    case 'PROCESS_DRAWINGS':
      return {
        ...state,
        processDrawings: action.payload,
        componentListInit: action.payload.componentListInit,
      }
    case 'IMAGE_URL':
      return { ...state, image_url: action.payload.url }
    case 'UPDATE_PRODUCT_DELIVERABLE_DATA':
      return {
        ...state,
        updateProductDeliverableExcel: action.payload.excel,
      }
    case 'DELIVERABLE_DATA':
      return {
        ...state,
        deliverableData: action.payload,
      }
    case 'CUSTOM_DELIVERABLE_DATA':
      return {
        ...state,
        customDeliverableData: action.payload.customDeliverableData,
      }
    case 'FETCH_QUALITY_METRICS_EXCEL':
      return { ...state, qualityMetricsExcel: action.payload.excel }
    case 'FETCH_QUALITY_PLOTS_DRAWING':
      return { ...state, qualityPlotsDrawing: action.payload.drawing }
    case 'FETCH_ROLES':
      return { ...state, roles: action.payload.roles }
    case 'FETCH_COMPANIES':
      return { ...state, companies: action.payload.companies }
    case 'FETCH_MEASUREMENT_SYSTEM_CODES':
      return {
        ...state,
        measurementSystemCodes: action.payload.Measurement_system_code,
      }
    case 'FETCH_EPC_COMPANIES':
      return { ...state, epc_companies: action.payload.companies }
    case 'FETCH_CLIENTS':
      return { ...state, clients: action.payload.clients }
    case 'FETCH_CLIENT':
      return { ...state, client: action.payload.client }
    case 'FETCH_USERS':
      return { ...state, users: action.payload.users }
    case 'FETCH_CLIENTS_ALL_DATA':
      return { ...state, clientsAllData: action.payload.clients }
    case 'FETCH_USER_CLIENTS_ALL_DATA':
      return { ...state, userClientsAllData: action.payload.client }
    case 'FETCH_USER':
      return { ...state, user: action.payload.user }
    case 'FETCH_PROJECT_USER_ACCESS':
      return { ...state, projectUserAccess: action.payload.Projects }
    case 'FETCH_ALL_PRODUCTS':
      return { ...state, allProducts: action.payload.allProducts }

    case 'SINGLE_PRODUCT':
      return { ...state, singleProduct: action.payload.singleProduct }
    case 'FETCH_ALL_PROJECTS':
      return { ...state, allProjects: action.payload.allProjects }

    case 'PROJECT_MESSAGES':
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
        projectSetupStep: action.payload.nextStep,
        createdProjectId: action.payload.id,
      }
    case 'DATASHEET_UPLOAD_OPTIONS':
      return {
        ...state,
        datasheetUploadOptions: action.payload,
      }
    case 'DATASHEET_MAPPING_INFO':
      return {
        ...state,
        datasheetMappingOptions: action.payload,
      }
    case 'RESPONSE_MESSAGE': {
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
      }
    }
    case 'FETCH_USER_COMPONENT':
      return {
        ...state,
        fetchConfigData: action.payload.components,
        fetchConfigList: action.payload.components_list,
        configComponentsOptions: action.payload.component_options,
        configClientName: action.payload.client_name,
      }
    case 'PROJECT_SETUP_STEP':
      return {
        ...state,
        projectSetupStep: action.payload.project.step,
      }
    case 'PROJECT_CONFIG_PARAMETERS':
      return {
        ...state,
        projectConfigParameters: action.payload,
      }
    case 'PROJECT_COMPANY_OPTIONS':
      return {
        ...state,
        projectCompanyOptions: action.payload.company_options,
      }
    case 'PROJECT_INFO':
      return {
        ...state,
        projectInfo: action.payload,
      }
    case 'CLEAR_PROJECT_INFO':
      return {
        ...state,
        projectInfo: {},
      }
    case 'FETCH_DATASHEET_FILES':
      return {
        ...state,
        datasheetData: action.payload,
      }
    case 'DATASHEET_PROCESS_UPDATES':
      return {
        ...state,
        datasheetProcessUpdates: action.payload,
      }
    case 'PROCESS_DATASHEET_PDF':
      return {
        ...state,
        processedPdfOutput: action.payload,
      }

    case 'FETCH_INTERMEDIATE_FILE':
      return {
        ...state,
        intermediateFile: action.payload,
      }
    case 'FETCH_FILE':
      return {
        ...state,
        base64File: action.payload,
      }
    case 'UPDATE_DATASHEET_PROCESS_DATA':
      return {
        ...state,
        updateDatasheetProcessedData: action.payload,
        displayMessageCode: { message: action.payload.messageCode },
      }
    case 'DOWNLOAD_DATSHEET_EXCEL':
      return {
        ...state,
        downloadDatasheetExcelOutput: action.payload,
        displayMessageCode: { message: action.payload.messageCode },
      }
    case 'TAG_CONFIG_DATA':
      return {
        ...state,
        taggingConfigData: action.payload,
        taggingConfigDataErrorMessage: null,
      }
    case 'TAG_TEMPLATE_DATA':
      return {
        ...state,
        templateDataErrorMessage: null,
        templateData: action.payload.addedTemplateData,
      }
    case 'TAG_MATCHING_TEMPLATES':
      return {
        ...state,
        templateDataErrorMessage: null,
        tagMatchingTemplates: action.payload,
      }
    case 'TAG_TEMPLATE_DATA_ERROR_MESSAGE':
      return {
        ...state,
        templateData: null,
        templateDataErrorMessage: action.payload.errorMessage,
      }
    case 'TAG_CONFIG_DATA_ERROR_MESSAGE':
      return {
        ...state,
        taggingConfigData: null,
        taggingConfigDataErrorMessage: action.payload.errorMessage,
        tagTitleRegion: null,
      }
    case 'TEST_TAG_CONFIG_DATA':
      return {
        ...state,
        testTaggingConfigData: action.payload,
        taggingConfigDataErrorMessage: null,
      }
    case 'TAG_TITLE_REGION':
      return {
        ...state,
        tagTitleRegion: action.payload.tagTitleRegion,
        taggingConfigDataErrorMessage: null,
      }
    case 'PROPERTY_CONFIG_DATA':
      return {
        ...state,
        propertyConfigData: JSON.parse(action.payload.propertyConfigData),
        propertyConfigConstants: action.payload.constants,
        propertyConfigDrawing: action.payload.drawingId,
        propertyConfigDataErrorMessage: null,
      }
    case 'PROPERTY_CONFIG_DATA_ERROR_MESSAGE':
      return {
        ...state,
        propertyConfigData: null,
        propertyConfigDrawing: null,
        propertyConfigDataErrorMessage: action.payload.errorMessage,
      }
    case 'TEST_PROPERTY_CONFIG_DATA':
      return {
        ...state,
        testPropertyConfigData: action.payload.testPropertyConfigData,
        propertyConfigDataErrorMessage: null,
      }

    case 'CREATE_NEW_ORGANIZATION':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'CREATE_NEW_SITE':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'UPDATE_SITE':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'CREATE_NEW_PLANT':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'CREATE_NEW_AREA':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'CREATE_NEW_UNIT':
      return {
        ...state,
        displayMessageCode: action.payload,
      }
    case 'CLEAR_DISPLAY_MESSAGE':
      return {
        ...state,
        displayMessageCode: null,
      }

    case 'PARSE_LOV_EXCEL_FILE':
      return {
        ...state,
        lovData: action.payload.lovData,
      }

    case 'CAD_GENERATOR_PARAMETERS':
      return {
        ...state,
        cadGeneratorParameters: action.payload.cadGeneratorParameters,
      }

    case 'COMPANY_ALL_DATA':
      return {
        ...state,
        companyAllData: action.payload.company,
      }

    case 'DISCIPLINE_ALL_DATA':
      return {
        ...state,
        disciplineAllData: action.payload,
      }

    case 'FETCH_REPORTS_PARAMS':
      return { ...state, report_form_params: action.payload }
    case 'FETCH_REPORTS_ADDITIONAL_PARAMS':
      return { ...state, report_additional_form_params: action.payload }
    case 'FETCH_AVAILABLE_REPORTS':
      return { ...state, available_report_types: action.payload.reports }
    case 'FETCH_REPORTS':
      return { ...state, reports: action.payload }
    case 'DOWNLOAD_REPORTS':
      return { ...state, report_excel: action.payload }
    case 'CLIENT_PROJECT_DATA':
      return { ...state, clientProjectData: action.payload.client }
    case 'FETCH_REPORT_LIST':
      return { ...state, report_list: action.payload }
    case 'UPLOAD_CLIENT_REPORT_STATUS':
      return { ...state, upload_report_list_status: action.payload.upload_status }
    case 'DOWNLOAD_REPORT_UPLOAD_STATUS':
      return { ...state, report_upload_status: action.payload }
    case 'FETCH_MASTER_LIST_DATA':
      return { ...state, master_list_data: action.payload }
    case 'FETCH_REPORT':
      return { ...state, reportData: action.payload }
    case 'FETCH_EDIT_REPORT':
      return { ...state, editReportData: action.payload }
    case 'UPDATE_REPORT_FILTER':
      return { ...state, displayMessageCode: action.payload }
    case 'CREATE_CLIENT_REPORT':
      return {
        ...state,
        report_id: action.payload.report_id,
        displayMessageCode: action.payload.errorMessage,
      }
    case 'CLONE_CLIENT_REPORT':
      return {
        ...state,
        report_id: action.payload.report_id,
        displayMessageCode: action.payload.errorMessage,
      }
    case 'DOWNLOAD_CLIENT_REPORT':
      return {
        ...state,
        report_file: action.payload,
        displayMessageCode: action.payload.errorMessage,
      }
    case 'DELETE_CLIENT_REPORT':
      return {
        ...state,
        report_id: action.payload.report_id,
        displayMessageCode: action.payload.errorMessage,
      }
    case 'SAVE_AND_PUBLISH_CLIENT_REPORT':
      return {
        ...state,
        displayMessageCode: action.payload,
      }

    case 'MODELS_INFO':
      return {
        ...state,
        modelsInfo: action.payload.model_names,
        selectedSymbols: action.payload.selected_symbols,
        modelsDescription: action.payload.packages_description,
      }
    case 'DRAWINGS_PACKAGE':
      return {
        ...state,
        drawingsPackageSelectedDrawings: action.payload.selected_drawings,
        drawingsPackageSelectedPackages: action.payload.selected_packages,
        drawingsPackageStatus: action.payload.status,
        modelConfigError: action.payload.partialMessage,
      }
    case 'PROCESS_DRAWINGS_PACKAGE_DATA':
      return {
        ...state,
        processDrawingsPacakgeData: action.payload.processDrawings,
        processDrawingsPacakgeCountData: action.payload.processCountData,
      }
    case 'PROCESS_DRAWINGS_PACKAGE_IMAGE_DATA':
      return {
        ...state,
        processDrawingsPackageImageData: action.payload,
      }
    case 'PROCESS_DRAWINGS_MODEL_CONFIGURATION_DRAWINGS':
      return {
        ...state,
        processDrawingsModelConfigurationDrawings: action.payload.modelConfigurationDrawings,
      }
    case 'PROCESS_DRAWINGS_MODEL_CONFIGURATION_STATUS_FLAGS':
      return {
        ...state,
        processDrawingsPacakgeStatusFlags: action.payload,
        modelConfigurationRevertStatusFlags: {},
      }
    case 'MODEL_CONFIGURATION_REVERT_STATUS_FLAGS':
      return {
        ...state,
        modelConfigurationRevertStatusFlags: action.payload,
      }
    case 'MODEL_CONFIGURATION_INITIATE_TRAINING_MESSAGE': {
      return {
        ...state,
        modelConfigurationInitiateTrainingMessage: action.payload,
      }
    }
    case 'TAGGING_CONFIGURATION_FORM_DATA':
      return {
        ...state,
        taggingConfigurationFormData: action.payload.Configuration,
      }

    // IUI
    case 'FETCH_IUI_DATA':
      return {
        ...state,
        iuiData: action.payload,
      }
    case 'UPDATE_IUI_DATA_MESSAGE':
      return {
        ...state,
        updateIUIData: action.payload,
      }
    case 'IUI_ENHANCE_DATA_STATE':
      return {
        ...state,
        IUIEnhanceDataState: action.payload,
      }
    case 'REMAP_IUI_DATA_MESSAGE':
      return {
        ...state,
        remapIUIDataMessage: action.payload.successMessage,
      }

    // iDocuments-TradeDocuments
    case 'FETCH_TRADE_DOCS':
      return {
        ...state,
        tradeDocsData: action.payload,
      }
    case 'PROCESS_TRADE_DOC_MESSAGES':
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
        processTradeDocMessages: action.payload,
      }
    case 'CLEAR_PROCESS_TRADE_DOC_MESSAGES':
      return {
        ...state,
        processTradeDocMessages: null,
      }
    case 'TRADE_DOC_PROCESS_UPDATES':
      return {
        ...state,
        tradeDocProcessUpdates: action.payload,
      }
    case 'TRADE_DOCUMENT_IUI_DATA':
      return {
        ...state,
        tradeDocumentIUIData: action.payload,
      }
    case 'UPDATE_TRADE_IUI_DATA_MESSAGES':
      return {
        ...state,
        displayMessageCode: { message: action.payload.messageCode },
      }
    case 'TRADE_DOCUMENT_REPORTS_PARAMS':
      return { ...state, tradeDocumentReportsParams: action.payload }
    case 'TRADE_DOCUMENT_REPORTS':
      return { ...state, tradeDocumentReports: action.payload }

    // Session
    case 'PDF_LIST_COLUMN_WIDTHS':
      return { ...state, pdfListColumnWidths: action.payload }
    case 'DRAWING_LIST_COLUMN_WIDTHS':
      return { ...state, drawingListColumnWidths: action.payload }
    case 'DS_PDF_LIST_COLUMN_WIDTHS':
      return { ...state, dsPdfListColumnWidths: action.payload }

    default:
      return state
  }
}

export default combineReducers({
  user: userData,
})
