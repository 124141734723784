import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './WebComponent.css'
import { fetchDrawingsPackage, fetchSingleProjectData, refreshUserAuth } from './../../store/actions'

import Step1 from './TaggingSymbolConfigComponents/step1'
import Step2 from './TaggingSymbolConfigComponents/step2'

class TaggingSymbolConfig extends React.Component {
  state = {
    projectName: null,
    projectId: null,
    tagging: false,
    product: null,
    clientName: null,
    step: 1,
    selectedPackages: [],
    selectedDrawings: [],
    loading: false,
  }

  componentDidUpdate(props) {
    if (props.user.isSignedIn !== this.props.user.isSignedIn) {
      this.changePage()
    }
  }

  changePage = async () => {
    if (this.props.user.isSignedIn === undefined) {
      await this.props.refreshUserAuth()
    }

    if (this.props.user.isSignedIn === false) {
      this.props.history.push('/')
    }
  }

  componentDidMount = async () => {
    this.changePage()
    const query = new URLSearchParams(this.props.location.search)
    const name = query.get('name')
    const projectId = query.get('id')
    const product = query.get('product')
    const clientName = query.get('client')
    const tagging = query.get('tagging')
    await this.setState({
      projectName: name,
      projectId: projectId,
      product: product,
      clientName: clientName,
      tagging: tagging === 'true',
    })

    await this.fetchSingleProjectData() // calling this method to make sure we have pdfs and drawings fetched for later use
    this.fetchDrawingsPackage()
  }

  fetchSingleProjectData = async () => {
    await this.setState({ loading: true })
    await this.props.fetchSingleProjectData(this.state.projectId, true)
    await this.setState({ loading: false })
  }

  fetchDrawingsPackage = async () => {
    // Status 0: Failed
    // Status 1: Successfully Processed
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('tagging', this.state.tagging)
    await this.props.fetchDrawingsPackage(formData)
    await this.setState({
      selectedPackages: this.props.user.drawingsPackageSelectedPackages,
      selectedDrawings: this.props.user.drawingsPackageSelectedDrawings,
    })

    if ((this.state.selectedDrawings.length && this.state.selectedPackages.length) || (this.state.tagging && this.state.selectedDrawings.length)) {
      if (this.props.user.drawingsPackageStatus === 1 || !this.props.user.drawingsPackageStatus) {
        this.changeStep(2)
      }
    }

    await this.setState({ loading: false })
  }

  exit = () => {
    this.props.history.push(`/pid/project?name=${this.state.projectName}&id=${this.state.projectId}&product=${this.state.product}`)
  }

  changeStep = (step) => {
    this.setState({ step: step })
  }

  renderTaggingSymbolConfig() {
    if (this.state.loading) {
      return (
        <div className="deliverables" id="unique-10984516div_3">
          <div id="preload">
            <div className="sk-folding-cube">
              <div className="sk-cube1 sk-cube"></div>
              <div className="sk-cube2 sk-cube"></div>
              <div className="sk-cube4 sk-cube"></div>
              <div className="sk-cube3 sk-cube"></div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="deliverables" id="unique-11186610div_1">
        <div className="inner-body" id="unique-55077667div_2">
          <div className="content" id="unique-38963262div_3">
            <Link to={`/pid/project?name=${this.state.projectName}&id=${this.state.projectId}&product=${this.state.product}`}>
              <button className="close custom">
                <i className="fa fa-times" />
              </button>
            </Link>

            {this.state.step === 1 ? (
              <Step1
                projectName={this.state.projectName}
                projectId={this.state.projectId}
                tagging={this.state.tagging}
                product={this.state.product}
                clientName={this.state.clientName}
                exit={this.exit}
                changeStep={this.changeStep}
                selectedDrawings={this.state.selectedDrawings}
                selectedPackages={this.state.selectedPackages}
              />
            ) : (
              <Step2
                projectName={this.state.projectName}
                projectId={this.state.projectId}
                tagging={this.state.tagging}
                product={this.state.product}
                clientName={this.state.clientName}
                exit={this.exit}
                changeStep={this.changeStep}
                selectedDrawings={this.state.selectedDrawings}
                selectedPackages={this.state.selectedPackages}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="dashboard main" id="unique-19653161div_5">
        {this.state.projectId ? this.renderTaggingSymbolConfig() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  fetchDrawingsPackage,
  fetchSingleProjectData,
  refreshUserAuth,
})(TaggingSymbolConfig)
