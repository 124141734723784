import React, { Component } from 'react'

import LinkedProjectInfoBox from './LinkProjectInfoBox'
import Input from '../Fields/input'
import UploadLOVExcel from '../../Forms/UploadLOVExcel'
import Select from '../Fields/select'

export default class ProjectSetupS11 extends Component {
  state = {
    loading: false,
    formLoader: false,
    data: [],
    data_columns: [],
    editRowIndex: '',
    select_value_data: false,
    hide_unchecked_rows: false,
    errorMessage: '',
    screen_blocked: false,
    selectAttrributeWarning: null,
  }

  componentDidMount = () => {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectCustomParams(formData)
    await this.setState({
      data_columns: this.props.user.projectInfo.data_columns,
      attribute_options: this.props.user.projectInfo.attributes,
      data: this.props.user.projectInfo.params,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
    await this.setState({ loading: false })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('params', JSON.stringify(this.state.data))
    formData.append('attributes', JSON.stringify(this.state.attribute_options))
    formData.append('step', 11)
    await this.props.updateProjectCustomParams(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  onChange = (e, path) => {
    let { data, attribute_options } = this.state
    this.setState({ errorMessage: '' })
    path = path.split('.')
    if (path.length === 1) {
      if (e.target.name === 'Attribute') {
        const index = attribute_options.findIndex((option) => option.Name === e.target.value)
        if (index > -1) {
          this.setState({
            errorMessage: 'Duplicates are not allowed!',
          })
          return
        }
      }
      data[path[0]][e.target.name].value = e.target.value
    }
    this.setState({ data: data })
  }

  addData = async () => {
    let { data, data_columns } = this.state

    let row = { selected: true }
    data_columns.forEach((column) => {
      if (!['Values', 'Attribute'].includes(column.name)) {
        row[column.name] = {
          value: ['Upload', 'Filter', 'List View'].includes(column.name) ? true : '',
          disabled: false,
        }
      }
    })
    data.push(row)
    this.setState({ select_value_data: true })
    await this.setState({ data: data })
  }

  checkIfAllSelected = () => {
    let { data } = this.state
    const index = data.findIndex((row) => row.selected === false)
    if (index > -1) {
      return false
    }
    return true
  }

  selectAll = () => {
    let { data } = this.state
    const index = data.findIndex((row) => row.selected === false)
    data.forEach((row) => {
      if (index > -1) {
        row.selected = true
      } else {
        row.selected = false
      }
    })

    this.setState({ data: data })
  }

  selectRow = (index) => {
    let { data } = this.state
    data[index].selected = !data[index].selected
    this.setState({ data: data })
  }

  deleteRow = (index) => {
    let { data } = this.state
    data.splice(index, 1)
    this.setState({ data: data })
  }

  onSelect = (index, name, selectedList) => {
    let { data } = this.state
    let values = []
    selectedList.forEach((item) => {
      values = [...values, ...item.values]
    })
    data[index][name].value = selectedList
    data[index].Values.value = values.toString()
    this.setState({
      data: data,
    })
  }

  renderData = () => {
    return (
      <table className="table table-striped">
        <thead className="thead-light">
          <tr id="unique-88303035tr_1">
            <th id="unique-24010411th_1">
              <label onClick={() => this.selectAll()} className={`checkbox ${this.checkIfAllSelected() ? 'checked' : null}`} />
            </th>
            {this.state.data_columns.map((column) => {
              return (
                <th key={column.name} id="unique-19016494th_2">
                  {column.name}
                </th>
              )
            })}
            <th id="unique-54670303th_3"></th>
          </tr>
        </thead>
        <tbody id="unique-17871510tbody_1">
          {this.state.data.map((row, index) => {
            if (this.state.hide_unchecked_rows && !row.selected) {
              return null
            }
            return (
              <tr key={index} id="unique-46854151tr_2">
                <td id="unique-10610291td_1">
                  <label onClick={() => this.selectRow(index)} className={`checkbox ${row.selected ? 'checked' : null}`} />
                </td>
                {this.state.data_columns.map((column, _index) => {
                  if (!(column.name in row)) {
                    return <td key={`${index}.${_index}`} id="unique-16189207td_2"></td>
                  }
                  return (
                    <td key={`${index}.${_index}`} style={{ maxWidth: 300 }} id="unique-82038516td_3">
                      {column.type === 'text' && !row[column.name].custom_input && !row[column.name].datetime ? (
                        <div className="input-group" id="unique-11563133div_1">
                          <Input
                            type="text"
                            name={column.name}
                            value={row[column.name].value}
                            onChange={this.onChange}
                            disabled={
                              column.name !== 'Display Name' &&
                              (row[column.name].disabled ||
                                !row.selected ||
                                !(
                                  'Attribute' in row &&
                                  (!Array.isArray(row.Attribute.value) ||
                                    (Array.isArray(row.Attribute.value) && row.Attribute.value.length === 1 && row.Attribute.value[0].is_custom))
                                ))
                            }
                            path={`${index}`}
                            id="57807675"
                          />
                          {column.name === 'Values' ? (
                            <div className="input-group-append" id="unique-77337291div_2">
                              <UploadLOVExcel
                                {...this.props}
                                name={column.name}
                                index={index}
                                disabled={
                                  column.name !== 'Display Name' &&
                                  (row[column.name].disabled ||
                                    !row.selected ||
                                    !(
                                      'Attribute' in row &&
                                      (!Array.isArray(row.Attribute.value) ||
                                        (Array.isArray(row.Attribute.value) && row.Attribute.value.length === 1 && row.Attribute.value[0].is_custom))
                                    ))
                                }
                                updateLOVData={this.onChange}
                                projectId={this.props.editProjectId}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : column.type === 'checkbox' ? (
                        !row[column.name].disabled && row.selected ? (
                          <label
                            className={`checkbox ${row[column.name].value ? 'checked' : null}`}
                            onClick={() =>
                              this.onChange(
                                {
                                  target: {
                                    name: column.name,
                                    value: !row[column.name].value,
                                  },
                                },
                                `${index}`
                              )
                            }
                          />
                        ) : (
                          <label className={`checkbox disabled ${row[column.name].value ? 'checked' : null}`} id="57807675" />
                        )
                      ) : column.type === 'multi-select' ? (
                        <div className="row align-items-center" id="unique-84923359div_3">
                          <div className="col-1" id="unique-12843458div_4">
                            <label
                              title="Select all"
                              className={`checkbox mr-2 ${
                                this.state.attribute_options.length && this.state.attribute_options.length === row[column.name].value.length ? 'checked' : ''
                              }`}
                              onClick={() => {
                                if (this.state.attribute_options.length === row[column.name].value.length) {
                                  this.onSelect(index, column.name, [], null)
                                } else {
                                  this.onSelect(index, column.name, this.state.attribute_options, null)
                                }
                              }}
                            />
                          </div>
                          <div className="col">
                            {/* {this.state.selectAttrributeWarning === index ? (
                              <div className="mb-2 alert alert-warning alert-dismiss p-2">
                                At least one attribute must be selected.{' '}
                                <button className="close" onClick={() => this.setState({ selectAttrributeWarning: null })}>
                                  &times;
                                </button>
                              </div>
                            ) : null} */}
                            {this.state.editRowIndex === index || !Array.isArray(row[column.name].value) ? (
                              <Input
                                type="text"
                                name={column.name}
                                value={Array.isArray(row[column.name].value) ? '' : row[column.name].value}
                                onChange={this.onChange}
                                disabled={row[column.name].disabled || !row.selected}
                                path={`${index}`}
                              />
                            ) : !row[column.name].disabled ? (
                              <Select
                                options={this.state.attribute_options}
                                onChange={(selectedOptions) => {
                                  this.onSelect(index, column.name, selectedOptions)
                                }}
                                value={row[column.name].value}
                                isMulti={true}
                                isDisabled={row[column.name].disabled}
                              />
                            ) : null}
                          </div>
                          {column.name === 'Attribute' && !row[column.name].value.length && !row[column.name].disabled && row.selected ? (
                            <div className="col-2" id="unique-39475784div_6">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  if (this.state.editRowIndex === index) {
                                    this.setState({
                                      editRowIndex: '',
                                    })
                                  } else {
                                    this.setState({
                                      editRowIndex: index,
                                    })
                                  }
                                }}
                              >
                                {this.state.editRowIndex === index ? (
                                  <i className="fa fa-minus" id="unique-58532378i_1"></i>
                                ) : (
                                  <i className="fa fa-plus" id="unique-63325193i_2"></i>
                                )}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </td>
                  )
                })}
                <td id="unique-28874843td_4">
                  <button className="btn text-secondary" onClick={() => this.deleteRow(index)}>
                    <i className="far fa-trash-alt" id="57807675" />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  addValueDataType = (type) => {
    let { data } = this.state
    let valueData = {
      value: '',
      disabled: false,
      datetime: false,
      custom_input: false,
    }
    if (type) {
      valueData[type] = true
    } else {
      this.addAttributeData()
    }

    data[data.length - 1].Values = valueData
    this.setState({ data: data, select_value_data: false })
  }

  addAttributeData = () => {
    let { data } = this.state
    let attributeData = {
      disabled: false,
      value: [],
    }

    data[data.length - 1].Attribute = attributeData
    this.setState({ data: data })
  }

  checkIfFormSubmissionIsDisabled = () => {
    const { data, formLoader } = this.state
    let response = {
      title: '',
      disabled: false,
    }

    if (formLoader) {
      response.disabled = true
      response.title = 'Loading form...'
      return response
    }

    for (let index = 0; index < data.length; index++) {
      if ('Values' in data[index] && (data[index].Values.custom_input || data[index].Values.datetime)) {
        continue
      }

      if (!('Attribute' in data[index]) || !data[index].Attribute.value.length) {
        response.disabled = true
        response.title = 'There must be at least one attribute selected for each configuration added!'
        break
      }
    }

    return response
  }

  renderForm() {
    let { hide_unchecked_rows, errorMessage } = this.state
    return (
      <div className="card" id="unique-10261306div_7">
        {this.state.select_value_data ? (
          <div className="modal" id="unique-20071496div_8">
            <div className="modal-dialog" id="unique-42228041div_9">
              <div className="modal-content" id="unique-85191372div_10">
                <div className="modal-header" id="unique-67220292div_11">
                  <h5 className="modal-title" id="unique-95041235h5_1">
                    Select Value Field Type
                  </h5>
                </div>
                <div className="modal-body" id="unique-54614127div_12">
                  <div className="mb-2" id="unique-73303943div_13">
                    <button className="btn btn-primary w-100" onClick={() => this.addValueDataType(null)}>
                      LOV
                    </button>
                  </div>
                  <div className="mb-2" id="unique-46474949div_14">
                    <button className="btn btn-primary w-100" onClick={() => this.addValueDataType('custom_input')}>
                      Custom Input
                    </button>
                  </div>
                  <div className="mb-2" id="unique-52789194div_15">
                    <button className="btn btn-primary w-100" onClick={() => this.addValueDataType('datetime')}>
                      Datetime
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {errorMessage ? (
          <div className="alert alert-danger mb-2" id="unique-26575196div_16">
            {errorMessage}
          </div>
        ) : null}

        <div className="clearfix card-header bg-light" id="87985714div_17">
          <button
            type="button"
            disabled={this.checkIfFormSubmissionIsDisabled().disabled}
            title={this.checkIfFormSubmissionIsDisabled().title}
            onClick={() => this.formDataHandler(false)}
            className="btn btn-primary float-right"
          >
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button
            type="button"
            disabled={this.checkIfFormSubmissionIsDisabled().disabled}
            title={this.checkIfFormSubmissionIsDisabled().title}
            onClick={() => this.formDataHandler(true)}
            className="btn btn-primary float-right mr-2"
          >
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-41820696button_9">
            Cancel
          </button>

          <h3 id="unique-91954346h3_1">Custom Attribute Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="57807675" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-16556462div_18">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          <div className="clearfix mb-3" id="unique-14524102div_19">
            <button className="btn btn-primary float-right" onClick={this.addData} id="unique-48272326button_10">
              Add
            </button>
            <span className="d-inline-block mr-3 float-right mt-2" id="unique-11713734span_1">
              <label
                onClick={() =>
                  this.setState({
                    hide_unchecked_rows: !hide_unchecked_rows,
                  })
                }
                className={`checkbox mr-2 ${hide_unchecked_rows ? 'checked' : null}`}
              />{' '}
              Hide Non-Selected
            </span>
          </div>
          {this.renderData()}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-47050587div_20">
          <div id="loading" />
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
