import React from 'react'
import { connect } from 'react-redux'

import { createProject, fetchSingleProject, fetchUserClientAllData } from './../../store/actions'
import Input from './Fields/input'
import Select from './Fields/select'
import Textarea from './Fields/textarea'
import './Forms.css'

class CloneProject extends React.Component {
  state = {
    projectId: '',
    projectDisplayName: '',
    projectNumber: '',
    description: '',
    projectScope: '',
    selectedProduct: '',
    tag_extraction: false,
    project_mode: null,
    createLinkedProject: false,
    cloneConfiguration: this.props.product === 'iDrawings-PID',
    cloneProjectData: false,
    cloneUserAccess: true,
    selectedUsersError: null,
    selectedUsers: [],
    clients: [],
    selected_products: [],
    selectedClient: '',
    ShowAdminUsers: this.props.user.role === 'SysAdmin-Admin',
    errorMessage: null,
    loading: false,
    formLoader: false,
  }

  componentDidMount() {
    this.setState({ loading: true, projectId: this.props.projectId }, () => {
      this.props.fetchUserClientAllData().then(() => {
        this.setState(
          {
            loading: false,
            clients: this.props.user.userClientsAllData,
          },
          this.fetchSingleProject
        )
      })
    })
  }

  fetchSingleProject = async () => {
    let formData = new FormData()
    formData.append('projectId', this.state.projectId)
    await this.setState({ loading: true })
    await this.props.fetchSingleProject(formData)
    await this.setState({
      loading: false,
      selectedClient: this.props.user.singleProjectData.clientIds,
      selectedUsers: this.props.user.singleProjectData.userIds,
      selected_products: this.props.user.singleProjectData.selected_products,
      selectedProduct: this.props.user.singleProjectData.productId,
      tag_extraction: this.props.user.singleProjectData.tag_extraction,
      description: this.props.user.singleProjectData.description,
      projectScope: this.props.user.singleProjectData.projectScope,
      project_mode: this.props.user.singleProjectData.project_mode,
      lastActivity: this.props.user.singleProjectData.lastActivity,
      startDate: this.props.user.singleProjectData.startDate,
      pdfs: this.props.user.singleProjectData.pdfs,
    })
  }

  cloneProjectDataHandler = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('projectDisplayName', this.state.projectDisplayName)
    formData.append('projectNumber', this.state.projectNumber)
    formData.append('description', this.state.description)
    formData.append('projectScope', this.state.projectScope)
    formData.append('totalSpace', 0.5)
    formData.append('usedSpace', 0)
    formData.append('clientId', JSON.stringify(this.state.selectedClient))
    formData.append('selected_products', JSON.stringify(this.state.selected_products))
    formData.append('project_mode', JSON.stringify(this.state.project_mode))
    formData.append('users', JSON.stringify(this.state.selectedUsers))
    formData.append('linked', false)

    formData.append('cloneConfiguration', this.state.cloneConfiguration)
    formData.append('createLinkedProject', this.state.createLinkedProject)
    formData.append('cloneProjectData', this.state.cloneProjectData)
    formData.append('cloneUserAccess', this.state.cloneUserAccess)
    formData.append('clone', true)
    formData.append('projectId', this.state.projectId)

    formData.append('productId', JSON.stringify(this.state.selectedProduct))
    formData.append('tag_extraction', this.state.tag_extraction)

    await this.setState({ formLoader: true })
    await this.props.createProject(formData)
    await this.setState({ formLoader: false })
    this.props.closeProjectCreation()
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderCloneProjectForm() {
    return (
      <form onSubmit={this.cloneProjectDataHandler} className="card" id="unique-44959919form_1">
        <div className="clearfix card-header bg-light" id="unique-94500690div_1">
          <button type="submit" disabled={this.state.formLoader ? true : false} className="btn btn-primary float-right" id="unique-63148307button_1">
            Clone {this.state.formLoader ? <i className="fa fa-spinner fa-spin" id="unique-32800800i_1"></i> : null}
          </button>

          <button type="button" onClick={this.props.closeProjectCreation} className="btn btn-secondary float-right mr-2" id="unique-79791511button_2">
            Cancel
          </button>

          <h3 id="unique-11362728h3_1">Clone Project</h3>
        </div>
        <div className="card-body" id="unique-95664932div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger mb-3" id="unique-35637361div_3">
              {this.state.errorMessage}
              <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
                &times;
              </button>
            </div>
          ) : null}
          <div className="row" id="unique-61874389div_4">
            <div className="col-md-6" id="unique-68262014div_5">
              <div className="row" id="unique-10354634div_6">
                <div className="form-group col" id="unique-23128139div_7">
                  <Input label="Project Name" type="text" value={this.state.projectDisplayName} name="projectDisplayName" onChange={this.onChange} required={true} id="54215668" />
                </div>
                <div className="form-group col" id="unique-30324631div_8">
                  <Input label="Project Number" type="text" value={this.state.projectNumber} name="projectNumber" onChange={this.onChange} required={true} id="54215668" />
                </div>
              </div>
              <div className="form-group">
                <Textarea name="description" label="Project Description" value={this.state.description} onChange={this.onChange} maxLength={250} rows={4} required={true} />
              </div>
              <div className="form-group">
                <Textarea name="projectScope" label="Project Scope Of Work" value={this.state.projectScope} onChange={this.onChange} maxLength={250} rows={4} required={true} />
              </div>
              <div className="form-group" id="unique-36477313div_11">
                <div className="d-inline-block text-center" id="unique-77145485div_12">
                  <label
                    onClick={() =>
                      this.setState(
                        {
                          createLinkedProject: !this.state.createLinkedProject,
                        },
                        () => {
                          if (this.state.createLinkedProject) {
                            this.setState({
                              cloneConfiguration: true,
                            })
                          }
                        }
                      )
                    }
                    className={`checkbox ${this.state.createLinkedProject ? 'checked' : ''}`}
                  />
                </div>{' '}
                &nbsp; Create Linked Project
              </div>
              <div className="form-group" id="unique-13543834div_13">
                <div className="d-inline-block text-center" id="unique-11553864div_14">
                  <label
                    onClick={() => {
                      if (!this.state.createLinkedProject) {
                        this.setState({
                          cloneConfiguration: !this.state.cloneConfiguration,
                        })
                      }
                    }}
                    className={`checkbox ${this.state.cloneConfiguration ? 'checked' : ''} ${this.state.createLinkedProject ? 'disabled' : ''}`}
                  />
                </div>{' '}
                &nbsp; Clone Configuration &nbsp;
                <i className="fa fa-info-circle cursor-pointer" title="Includes All Tagging and Model Configuration as applicable" id="54215668" />
              </div>
              <div className="form-group" id="unique-68353790div_15">
                <div className="d-inline-block text-center" id="unique-11337946div_16">
                  <label
                    onClick={() =>
                      this.setState({
                        cloneUserAccess: !this.state.cloneUserAccess,
                      })
                    }
                    className={`checkbox ${this.state.cloneUserAccess ? 'checked' : ''}`}
                  />
                </div>{' '}
                &nbsp; Clone User Access
              </div>
              <div className="form-group" id="unique-96632239div_17">
                <div className="d-inline-block text-center" id="unique-28975280div_18">
                  <label
                    onClick={() =>
                      this.setState({
                        cloneProjectData: !this.state.cloneProjectData,
                      })
                    }
                    className={`checkbox ${this.state.cloneProjectData ? 'checked' : ''}`}
                  />
                </div>{' '}
                &nbsp; Clone Project Data
              </div>
            </div>
            <div className="col-md-6" id="unique-36108149div_19">
              <div className="form-group" id="unique-40842689div_20">
                <label id="unique-94966226label_7">
                  Assign Client{' '}
                  <span className="small text-muted" id="unique-53607018span_3">
                    (*)
                  </span>
                </label>
                <Select options={this.state.clients} value={this.state.selectedClient} isMulti={false} isDisabled={true} />
              </div>
              <div className="form-group" id="unique-10259121div_21">
                <label id="unique-66914037label_8">
                  Assign Product{' '}
                  <span className="small text-muted" id="unique-49318621span_4">
                    (*)
                  </span>
                </label>

                <Select options={this.listProducts()} value={this.state.selected_products} isMulti={false} isDisabled={true} />
              </div>
              {this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1 ? (
                <div className="form-group" id="unique-94730700div_22">
                  <label id="unique-44977514label_9">
                    Project Mode{' '}
                    <span className="small text-muted" id="unique-81599322span_5">
                      (*)
                    </span>
                  </label>
                  <Select
                    options={[
                      { label: 'Digitization', value: 'Digitization' },
                      { label: 'SPPID to Aveva', value: 'SPPID to Aveva' },
                    ]}
                    value={this.state.project_mode}
                    onChange={(selectedMode) => {
                      this.setState({ project_mode: selectedMode })
                    }}
                    isDisabled={true}
                    isMulti={false}
                  />
                </div>
              ) : null}
              {!this.state.cloneUserAccess ? (
                <div className="form-group" id="unique-51698447div_23">
                  <div className="clearfix" id="unique-43698447div_23">
                    <label id="unique-21698447la_23">
                      Assign Users <span className="small text-muted">(*)</span>
                    </label>
                    <span className="d-inline-block float-right" id="unique-91396138span_7">
                      <label
                        onClick={() =>
                          this.setState({
                            ShowAdminUsers: !this.state.ShowAdminUsers,
                          })
                        }
                        className={`${this.state.ShowAdminUsers ? 'checked' : ''} checkbox`}
                      />{' '}
                      Show Admin Users
                    </span>
                  </div>
                  <Select
                    options={this.listUsers()}
                    onChange={(selectedList) => {
                      this.setState({ selectedUsers: selectedList })
                    }}
                    isMulti={true}
                    value={this.state.selectedUsers}
                    isDisabled={!this.state.selectedClient}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    )
  }

  listProducts() {
    if (!this.state.selectedClient) {
      return null
    }
    let products = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())
    if (index > -1) {
      this.state.clients[index].products.forEach((product) => {
        if (!(product.Product_id__Name.toLowerCase() === 'iworkflow') && product.Product_id__Name.toLowerCase() === this.props.product.toLowerCase()) {
          products.push(product)
        }
      })
    }
    return products
  }

  listUsers() {
    if (!this.state.selectedClient) {
      return
    }
    let users = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())

    if (index > -1) {
      users = this.state.clients[index].users
      if (!this.state.ShowAdminUsers) {
        users = users.filter((user) => !user.User_id__Default_role.split('-')[user.User_id__Default_role.split('-').length - 1].toLowerCase().includes('admin'))
      }
    }
    return users
  }

  handleUserSelect(event) {
    let selectedUsers = this.state.selectedUsers
    this.setState({ selectedUser: event.target.value }, () => {
      if (!this.state.selectedUsers.includes(parseInt(this.state.selectedUser))) {
        selectedUsers.push(parseInt(this.state.selectedUser))
      }
      this.setState({ selectedUsers: selectedUsers, selectedUser: '' })
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-79025373div_25">
          <div id="loading"></div>
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-11553266div_26">
        {this.renderCloneProjectForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  createProject,
  fetchSingleProject,
  fetchUserClientAllData,
})(CloneProject)
