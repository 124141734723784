import React, { forwardRef } from 'react'

import { useOnClickOutside } from 'usehooks-ts'

// const useClickOutside = (ref, callback) => {
//     const handleClick = e => {
//             if (ref.current && !ref.current.contains(e.target)) {
//             callback();
//         }
//     };
//     React.useEffect(() => {
//         document.addEventListener('click', handleClick);
//         return () => {
//             document.removeEventListener('click', handleClick);
//         };
//     });
// };

// Usage
const Dropdown = forwardRef((props, ref) => {
  const handleClickOutside = () => {
    let obj = {}
    obj[props.stateName] = false
    props.setDropdownOpen(obj)
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <div className={props.dropdownClass} ref={ref} id="unique-97590821div_1">
      <button
        id={`${props.idPrefix ? props.idPrefix + '_' : ''}${props.btnName.replaceAll(' ', '_').toLowerCase()}_dropdown_btn`}
        className={`btn ${props.btnClass}`}
        type="button"
        title={props.title ? props.title : ''}
        onClick={() => {
          let obj = {}
          obj[props.stateName] = !props.isDropdownOpen
          props.setDropdownOpen(obj)
        }}
        disabled={props.disabled}
      >
        {props.btnType === 'svg' ? (
          <svg className="d-block dx-g-bs4-filter-selector-icon" viewBox="0 0 32 32">
            <path d={props.svgD}></path>
          </svg>
        ) : (
          <React.Fragment>
            {props.btnName} {props.isDropdownOpen ? <i className="fa fa-caret-up" id="51692223" /> : <i className="fa fa-caret-down" id="51692223" />}
          </React.Fragment>
        )}
      </button>

      {props.isDropdownOpen ? (
        <div className={`dropdown-menu ${props.dropdownPositionClass}`} style={{ zIndex: 4001 }} id="unique-87374516div_2">
          {props.render()}
        </div>
      ) : null}
    </div>
  )
})

export default Dropdown
