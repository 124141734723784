import React from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'

import { fetchProduct, updateProduct } from '../../store/actions'
import './Forms.css'
import Input from './Fields/input'
import Textarea from './Fields/textarea'

class EditProduct extends React.Component {
  state = {
    productId: null,
    name: '',
    logoUrl: '',
    file: null,
    previewUrl: null,
    description: '',
    formLoader: false,
    fileErrorMessage: null,
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const productId = parseInt(query.get('id'))
    let formData = new FormData()
    formData.append('productId', productId)
    await this.setState({ loading: true })
    await this.props.fetchProduct(formData)
    if (this.props.user.singleProduct) {
      this.setState({
        name: this.props.user.singleProduct.name,
        description: this.props.user.singleProduct.description,
        logoUrl: this.props.user.singleProduct.logoUrl,
        productId: this.props.user.singleProduct.id,
      })
    }
    await this.setState({ loading: false })
  }

  handleDocument(files) {
    if (files[0].size > 5 * 1024 * 1024) {
      this.setState({
        fileErrorMessage: `${files[0].name}: Max allowed file size is 5MB`,
      })
      return
    }

    if (['image/jpg', 'image/png', 'image/jpeg'].includes(files[0].type)) {
      this.setState({
        previewUrl: URL.createObjectURL(files[0]),
        file: files,
        fileErrorMessage: null,
      })
    } else {
      this.setState({
        fileErrorMessage: 'File type is not allowed (must be PNG, JPEG or JPG image)',
      })
    }
  }

  updateProductDataHandler(event) {
    event.preventDefault()
    let formData = new FormData()
    formData.append('name', this.state.name)
    formData.append('description', this.state.description)
    formData.append('productId', this.state.productId)

    if (this.state.file) {
      formData.append('file', this.state.file[0])
    }

    this.setState({ formLoader: true, fileErrorMessage: null }, () => {
      this.props.updateProduct(formData).then(() => {
        this.props.fetchProduct(formData).then(() => {
          this.setState({
            file: null,
            formLoader: false,
            name: this.props.user.singleProduct.name,
            description: this.props.user.singleProduct.description,
            logoUrl: this.props.user.singleProduct.logoUrl,
          })
        })
      })
    })

    if (!this.props.user.createClientErrorMessage) {
      this.props.history.push(`/admin/products`)
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderEditProductForm() {
    return (
      <form onSubmit={(event) => this.updateProductDataHandler(event)} className="card">
        <div className="clearfix card-header bg-light" id="unique-91726189div_1">
          <button type="submit" disabled={this.state.formLoader} className="btn btn-primary float-right" id="unique-97363590button_1">
            {this.state.formLoader ? 'Saving...' : 'Save'}
          </button>
          <h3 className="mb-0" id="unique-32385400h3_1">
            Update Product
          </h3>
        </div>
        <div className="card-body" id="unique-99233724div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger alert-dismissible fade show mb-3" role="alert" id="unique-62430233div_3">
              <strong id="unique-78521610strong_1">Opps!</strong> Something went wrong
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: '' })}>
                <span aria-hidden="true" id="unique-75500912span_1">
                  &times;
                </span>
              </button>
            </div>
          ) : null}
          <div className="row" id="unique-98625984div_4">
            <div className="col-md-5" id="unique-73499588div_5">
              <div className="form-group" id="unique-91483765div_6">
                <Input label="Product Name" type="text" value={this.state.name} name="name" onChange={this.onChange} required={true} id="17066561" />
              </div>
              <div className="form-group">
                <Textarea name="description" label="Product Description" value={this.state.description} onChange={this.onChange} maxLength={500} rows={4} required={true} />
              </div>
            </div>
            <div className="col-md-7" id="unique-85548783div_8">
              <div className="form-group" id="unique-13709746div_9">
                <label id="unique-97309564label_2">
                  {' '}
                  Product Logo <span className="small text-muted">(*)</span>
                </label>
                <div className="form-group" id="unique-93003093div_10">
                  <img src={this.state.logoUrl} className="img-fluid" alt="Logo" id="17066561" />
                </div>
                <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      {this.state.file === null ? (
                        <div id="unique-12243156div_11">
                          <button {...getRootProps()} className="upload-content btn btn-primary" type="button" id="unique-70564332button_3">
                            <input {...getInputProps()} multiple={false} id="17066561" />
                            Select File <i className="fas fa-cloud-upload-alt" id="unique-56839254i_1"></i>
                          </button>
                          {this.state.fileErrorMessage ? (
                            <p className="mt-2 text-danger" id="unique-10569774p_1">
                              {this.state.fileErrorMessage}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div id="unique-65477442div_12">
                          <div className="img-container" id="unique-28297917div_13">
                            <img src={this.state.previewUrl} className="img-fluid" alt="preview" id="17066561" />
                          </div>
                          <div className="mt-2" id="unique-10001367div_14">
                            File Name: {this.state.file[0].name}
                            <i
                              className="text-danger"
                              onClick={() =>
                                this.setState({
                                  file: null,
                                  previewUrl: null,
                                })
                              }
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              {' '}
                              Remove{' '}
                            </i>
                          </div>
                        </div>
                      )}
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-14942243div_15">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-74904098div_16">
        {this.renderEditProductForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { fetchProduct, updateProduct })(EditProduct)
