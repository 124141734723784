import React from 'react'
import { connect } from 'react-redux'

import './Forms.css'
import { fetchProjectUserAccess, assignProjectUser } from './../../store/actions'

class ArchiveUser extends React.Component {
  state = {
    userId: null,
    projectUserAccess: [],
    project_users: {},
    loading: false,
    formLoader: false,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const userId = parseInt(query.get('id'))
    this.setState({ userId: userId }, this.fetchData)
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('userId', this.state.userId)
    await this.props.fetchProjectUserAccess(formData)
    await this.setState({
      projectUserAccess: this.props.user.projectUserAccess,
    })

    let project_users = {}
    this.state.projectUserAccess.forEach((project) => {
      project_users[project.id] = []
    })

    this.setState({ loading: false, project_users: project_users })
  }

  submitHandler = async (e) => {
    e.preventDefault()
    // projects: [{id:1, ids:"1,2"}]

    let projects = []
    let project_users = this.state.project_users
    for (const [key, value] of Object.entries(project_users)) {
      projects.push({ id: key, users: value })
    }
    const formData = new FormData()
    formData.append('projects', JSON.stringify(projects))
    await this.props.assignProjectUser(formData)
    this.props.history.push('/admin/users')
  }

  updateProjectUsers = (e) => {
    let project_users = this.state.project_users

    if (project_users[e.target.name].includes(e.target.value)) {
      const index = project_users[e.target.name].indexOf(e.target.value)
      if (index > -1) {
        project_users[e.target.name].splice(index, 1)
      }
    } else {
      project_users[e.target.name].push(e.target.value)
    }

    this.setState({ project_users: project_users })
  }

  renderArchiveForm = () => {
    return this.state.projectUserAccess.map((project) => {
      return (
        <div className="form-group col-md-4" key={project.id} id="unique-75696419div_1">
          <div className="card" id="unique-91921571div_2">
            <div className="card-body" id="unique-10337924div_3">
              <h5 className="card-title" id="unique-29013609h5_1">
                {project.projectName} ({project.product})
              </h5>
              <label id="unique-28905212label_1">Assign Users *</label>
              <select value={this.state.project_users[project.id]} className="form-control" name={project.id} onChange={(e) => this.updateProjectUsers(e)} multiple required>
                {project.users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="dashboard admin" id="unique-27897857div_4">
        <form onSubmit={this.submitHandler} className="card" id="unique-81152774form_1">
          <div className="clearfix card-header bg-light" id="unique-35709844div_5">
            <button type="submit" disabled={this.state.formLoader ? true : false} className="btn btn-primary float-right" id="unique-38629464button_1">
              Archive {this.state.formLoader ? <i className="fa fa-spinner fa-spin" id="unique-13364172i_1"></i> : null}
            </button>
            <h3 className="mb-0" id="unique-36153894h3_1">
              Archive User
            </h3>
          </div>

          <div className="card-body" id="unique-59754058div_6">
            {this.state.loading ? (
              <div className="screen-loader" id="unique-97365087div_7">
                <div id="loading"></div>
              </div>
            ) : !this.state.projectUserAccess.length ? (
              'No projects associated to this user'
            ) : (
              <div className="row" id="unique-46019802div_8">
                {this.renderArchiveForm()}
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjectUserAccess,
  assignProjectUser,
})(ArchiveUser)
