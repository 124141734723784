import React from 'react'
import './Forms.css'
import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'
import { uploadFile, checkPdf, uploadPidUpdateFile } from './../../store/actions'
import UploadOptions from './UploadOptions'
import Input from './Fields/input'

var _ = require('lodash')

class UploadDocument extends React.Component {
  state = {
    uploadView: true,
    files: [],
    original_file: null,
    markup_file: null,
    loader: false,
    errorMessage: null,
    checkPdf: null,
    maxFileSizeGB: 1, //GB
    totalSizeGB: 0,
    acceptedExtensions: [
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/tiff',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/xml',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
    ],
    disallowedChars: ['@', '.', ','],

    // Upload Options
    documentUploadOptions: {},
    uploadStructure: false,
    notMandatory: false,
    discipline: null,
    documentType: null,
    category: null,
    subcategory: null,
    manufacturer: null,
  }

  selectedDocument = async (files) => {
    if (!files || files.length === 0) {
      return
    }

    const { projectId, product } = this.props
    let { maxFileSizeGB, acceptedExtensions, disallowedChars } = this.state
    files = [...this.state.files, ...files.filter((file) => !this.state.files.some((existingFile) => existingFile.name === file.name))]

    let totalSize = 0
    let fileNames = []
    let capitalExtensionFilesList = []
    let invalidTypeFilesList = []
    let sizeExceededFilesList = []

    for (let index = 0; index < files.length; index++) {
      let file = files[index]
      const extension = file.name.split('.').pop()
      let fileError = false

      const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'))

      // Check if file name contains disallowed characters
      if (disallowedChars.some((char) => fileNameWithoutExtension.includes(char))) {
        this.setState({
          errorMessage: 'File name contains disallowed characters.',
        })
        return
      }

      // Check if the product is 'iDocuments-Datasheet' and if the extension is uppercase
      if (product === 'iDocuments-Datasheet' && extension !== extension.toLowerCase()) {
        capitalExtensionFilesList.push(file.name)
        fileError = true
      } else if (file.type && !acceptedExtensions.includes(file.type)) {
        invalidTypeFilesList.push(file.name)
        fileError = true
      } else if (file.size > 100 * 1024 * 1024) {
        sizeExceededFilesList.push(file.name)
        fileError = true
      } else {
        // If no error, add the file to the valid files list and update total size
        fileNames.push(file.name)
        totalSize += file.size
      }

      // If there's an error with the current file, remove it from the original files array
      if (fileError) {
        files.splice(index, 1)
        index-- // Adjust index after removing element
      }
    }

    if (capitalExtensionFilesList.length) {
      this.setState({
        errorMessage: `Files(s) ${capitalExtensionFilesList.join(', ')} have capital extensions. Please upload files with lowercase extensions.`,
      })
    }

    if (invalidTypeFilesList.length) {
      this.setState({
        errorMessage: `File(s) ${invalidTypeFilesList.join(', ')} are Invalid file types. Please upload files with valid extensions.`,
      })
    }

    if (sizeExceededFilesList.length) {
      this.setState({
        errorMessage: `${sizeExceededFilesList.join(', ')}: Max allowed file size is 100MB`,
      })
    }

    const totalSizeGB = totalSize / (1024 * 1024 * 1024) // Conversion to GB
    this.setState({ totalSizeGB: totalSizeGB })

    if (totalSizeGB > maxFileSizeGB) {
      this.setState({
        errorMessage: `Total file size exceeds ${maxFileSizeGB}GB.`,
      })
      return
    }

    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('fileNames', fileNames)
    formData.append('productType', product)

    await this.props.checkPdf(formData)

    await this.setState({ checkPdf: this.props.user.checkPdfMessage })

    Object.entries(this.props.user.checkPdfSimilarFiles).forEach(([key, val]) => {
      const index = files.findIndex((file) => file.name === key)
      if (index > -1) {
        if (val.fileExtension !== 'pdf') {
          files.splice(index, 1)
        } else {
          files[index].pages = val.pages
          files[index].allowEdit = val.allowEdit
          files[index].allowUpload = val.allowUpload
        }
      }
    })

    if (files.length) {
      this.setState({
        files: files,
        uploadView: false,
      })
    }
  }

  uploadDocument(e) {
    e.preventDefault()
    this.setState({ errorMessage: null })
    this.setState({ loader: true })
    let promiseArray = []

    const handleFileUpload = (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        if (file.allowUpload === true || !('allowUpload' in file)) {
          formData.append('file', file)
        }
        formData.append('projectId', this.props.projectId)
        formData.append('fileName', file.name)
        formData.append('product', this.props.product)
        formData.append('pages', file.pages)
        formData.append('uploadStructure', this.state.uploadStructure)

        if ('disciplines' in this.state.documentUploadOptions && this.state.documentUploadOptions.disciplines.length) {
          formData.append('discipline', this.state.discipline ? this.state.discipline.value : '')
          formData.append('documenttype', this.state.documentType ? this.state.documentType.value : '')
        }

        if ('additionalOptions' in this.state.documentUploadOptions && this.state.documentUploadOptions.additionalOptions.length) {
          formData.append('additionalOptions', JSON.stringify(this.state.documentUploadOptions.additionalOptions))
        }

        formData.append('category', this.state.category ? this.state.category.value : '')
        formData.append('subcategory', this.state.subcategory ? this.state.subcategory.value : '')
        formData.append('manufacturer', this.state.manufacturer ? this.state.manufacturer.value : '')
        formData.append('filePath', file.path)

        this.props
          .uploadFile(formData)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    this.state.files.forEach((file) => {
      promiseArray.push(handleFileUpload(file))
    })

    Promise.all(promiseArray)
      .then(() => {
        this.setState({ loader: false })
        if (!this.props.user.displayMessageCode) {
          this.props.reloadData()
        }
        this.props.closeUploadView()
      })
      .catch((error) => {
        // Handle any errors that occurred during file upload
        console.error(error)
      })
  }

  selectedPidUpdateDocument = async (acceptedFiles) => {
    let { acceptedExtensions, disallowedChars } = this.state

    let extensionError = false
    let disallowedCharsError = false
    acceptedFiles.forEach((file) => {
      const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'))

      // Check if file name contains disallowed characters
      if (disallowedChars.some((char) => fileNameWithoutExtension.includes(char))) {
        this.setState({
          errorMessage: 'File name contains disallowed characters.',
        })
        disallowedCharsError = true
      }

      if (!acceptedExtensions.includes(file.type)) {
        this.setState({
          errorMessage: `${file.type} files are not allowed`,
        })
        extensionError = true
      }
    })

    if (extensionError || disallowedCharsError) {
      return
    }

    let file = acceptedFiles
    if (!this.state.original_file) {
      await this.setState({ original_file: file })
    } else {
      await this.setState({ markup_file: file, uploadView: false })
      let fileNames = []
      fileNames.push(this.state.markup_file[0].name)

      const formData = new FormData()
      formData.append('projectId', this.props.projectId)
      formData.append('fileNames', fileNames)
      formData.append('productType', this.props.product)

      await this.props.checkPdf(formData)
      await this.setState({ checkPdf: this.props.user.checkPdfMessage })
    }
  }

  uploadPidUpdateDocument = async (e) => {
    e.preventDefault()
    this.setState({ loader: true })
    const formData = new FormData()
    formData.append('original_file', this.state.original_file[0])
    formData.append('markup_file', this.state.markup_file[0])
    formData.append('projectId', this.props.user.projectId)
    formData.append('product', this.props.product)
    await this.props.uploadPidUpdateFile(formData)
    await this.setState({ loader: false })

    if (!this.props.user.displayMessageCode) {
      this.props.reloadData()
    }

    this.props.closeUploadView()
  }

  removeFromSelection = async (fileName) => {
    let { files } = this.state
    files = files.filter((file) => file.name !== fileName)
    if (files.length) {
      await this.setState({ files: files })
    } else {
      await this.setState({ files: [] })
    }

    this.selectedDocument(files)
  }

  onChangeFilePages = (e, index) => {
    let { files } = this.state

    let value = ''
    if (files[index].name in this.props.user.checkPdfSimilarFiles) {
      if ('pages' in this.props.user.checkPdfSimilarFiles[files[index].name]) {
        value = this.props.user.checkPdfSimilarFiles[files[index].name].pages
      }
    }

    if (_.startsWith(e.target.value, value)) {
      files[index].pages = e.target.value
    }

    this.setState({ files: files })
  }

  renderUploadedFilesPreview() {
    if (this.props.product === 'iDrawings-PID-Update') {
      if (this.state.original_file && this.state.markup_file) {
        return (
          <>
            <div className="col-md-6" id="unique-39732331div_1">
              <div className="mb-2 selected-file" id="unique-23093325div_2">
                <h1 id="unique-10166794h1_1">
                  <b className="badge badge-danger">Original</b>
                </h1>
                <div className="file-name" id="unique-80601837div_3">
                  {this.state.original_file[0].name} <br id="29369677" /> ({Math.round((this.state.original_file[0].size * 100) / (1024 * 1024)) / 100} MB)
                </div>
              </div>
            </div>
            <div className="col-md-6" id="unique-71439002div_4">
              <div className="mb-2 selected-file" id="unique-54447955div_5">
                <h1 id="unique-34247845h1_2">
                  <b className="badge badge-danger">Markup</b>
                </h1>
                {this.state.markup_file[0].name} <br id="29369677" /> ({Math.round((this.state.markup_file[0].size * 100) / (1024 * 1024)) / 100} MB)
              </div>
            </div>
          </>
        )
      }

      return null
    }

    return this.state.files.map((file, index) => {
      return (
        <div className="col-md-12" key={file.name} id="unique-24872774div_6">
          <div className="mb-2 selected-file" id="unique-23266092div_7">
            <i className="fa fa-file-pdf" id="unique-37026813i_1"></i> {file.name} &nbsp; &nbsp; (<i className="fa fa-hdd" id="unique-90006620i_2"></i>{' '}
            {Math.round((file.size * 100) / (1024 * 1024)) / 100} MB)
            {file.type === 'application/pdf' ? (
              <div className="d-inline-block ml-3" style={{ width: 200 }}>
                <Input type={'text'} value={file.pages ? file.pages : ''} path={`${index}`} onChange={this.onChangeFilePages} disabled={file.allowEdit === false} />
              </div>
            ) : null}
            <i className="fa fa-info-circle ml-3" title={this.generateInfoMessage(file.pages)} id="29369677" />
            <i className="fa fa-trash-alt ml-3" onClick={() => this.removeFromSelection(file.name)}></i>
          </div>
        </div>
      )
    })
  }

  generateInfoMessage(input) {
    const pattern = /^(\d+(-\d+)?,?)+$/

    if (!pattern.test(input)) {
      return 'Error: Incorrect syntax. Please follow the correct input structure. Example: 1-3, 7, 10-12'
    }

    const pages = input
      .split(',')
      .map((page) => {
        const range = page.split('-')
        if (range.length === 1) {
          return parseInt(range[0])
        } else if (range.length === 2) {
          const start = parseInt(range[0])
          const end = parseInt(range[1])
          return Array.from({ length: end - start + 1 }, (_, i) => start + i)
        }
      })
      .flat()

    const uniquePages = [...new Set(pages)].sort((a, b) => a - b)

    if (uniquePages.length === 0) {
      return 'Error: No valid pages specified.'
    }

    const message = `The following pages will be uploaded: ${uniquePages.join(', ')}. Note: PDF file must include pages mentioned.`
    return message
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  checkIfUploadButtonDisabled = () => {
    let { loader, notMandatory, discipline, documentType, maxFileSizeGB, totalSizeGB } = this.state
    if (totalSizeGB > maxFileSizeGB || totalSizeGB === 0) {
      return true
    }

    if (loader) {
      return true
    }

    if (notMandatory) {
      return false
    }

    if (['iDrawings-PID', 'iDrawings-Common'].includes(this.props.product)) {
      if (!discipline || !documentType) {
        return true
      }
    }

    if (['iDocuments-Datasheet'].includes(this.props.product) && !this.props.notMandatory) {
      if (!discipline) {
        return true
      }
    }

    return false
  }

  renderUploadDocument() {
    return (
      <>
        {this.state.errorMessage ? (
          <div className="alert alert-danger" id="unique-34088202div_9">
            <i className="fa fa-exclamation-triangle" id="unique-10929464i_5"></i> &nbsp; {this.state.errorMessage}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: null })}>
              <span aria-hidden="true" id="unique-93415004span_1">
                &times;
              </span>
            </button>
          </div>
        ) : null}
        {this.state.notMandatory ? (
          <div className="alert alert-warning" id="unique-10068849div_10">
            <i className="fa fa-exclamation-triangle" id="unique-74193569i_6"></i> &nbsp; Automation might not be correct
          </div>
        ) : null}

        {this.state.checkPdf ? (
          <div className="alert alert-danger" id="unique-22978179div_11">
            <i className="fa fa-exclamation-triangle" id="unique-77156554i_7"></i> &nbsp; {this.state.checkPdf}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ checkPdf: null })}>
              <span aria-hidden="true" id="unique-75639523span_2">
                &times;
              </span>
            </button>
          </div>
        ) : null}

        <Dropzone
          onDrop={(acceptedFiles) => (this.props.product === 'iDrawings-PID-Update' ? this.selectedPidUpdateDocument(acceptedFiles) : this.selectedDocument(acceptedFiles))}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="upload-area">
              <div className="clearfix" id="unique-29210221div_12">
                <i className="fa fa-times float-right" onClick={() => this.props.closeUploadView()}></i>
              </div>

              <form id="upload_form" className="row" onSubmit={(e) => (this.props.product !== 'iDrawings-PID-Update' ? this.uploadDocument(e) : this.uploadPidUpdateDocument(e))}>
                <UploadOptions
                  projectId={this.state.projectId}
                  onChange={this.onChange}
                  uploadStructure={this.state.uploadStructure}
                  notMandatory={this.state.notMandatory}
                  discipline={this.state.discipline}
                  documentType={this.state.documentType}
                  category={this.state.category}
                  subcategory={this.state.subcategory}
                  manufacturer={this.state.manufacturer}
                  {...this.props}
                />

                <div className="col" id="unique-85971192div_13">
                  <div className="dropzone" id="unique-38692965div_14">
                    {!this.state.uploadView && this.state.files.length ? (
                      <div className="upload-content" id="unique-10962322div_15">
                        <div className="uploaded-files-view" id="unique-90253776div_16">
                          <h1 className="text-center" id="unique-75754624h1_3">
                            Selected Files{' '}
                            {this.state.files.length ? (
                              <span id="unique-24368451span_3">
                                ({this.state.files.length}) (<i className="fa fa-hdd" id="unique-62306298i_9"></i> {Math.round(this.state.totalSizeGB * 1024 * 100) / 100} MB)
                              </span>
                            ) : null}
                          </h1>

                          <div className="row" id="unique-48256623div_17">
                            {this.renderUploadedFilesPreview()}
                          </div>
                        </div>

                        <button
                          id="upload_add_more_files_btn"
                          className="btn btn-primary mr-2 mb-2"
                          type="button"
                          onClick={() => {
                            this.setState({
                              uploadView: true,
                            })
                          }}
                        >
                          Add More Files
                        </button>

                        <button
                          id="upload_deselect_all_btn"
                          type="button"
                          className="btn btn-primary mr-2 mb-2"
                          onClick={() =>
                            this.setState({
                              files: [],
                              original_file: null,
                              markup_file: null,
                              uploadView: true,
                            })
                          }
                          disabled={this.state.loader}
                        >
                          Deselect All
                        </button>

                        <button id="upload_submit_btn" type="submit" className="btn btn-primary mb-2" disabled={this.checkIfUploadButtonDisabled()}>
                          {this.state.loader ? 'Uploading ...' : 'Upload'}
                        </button>
                      </div>
                    ) : (
                      <div {...getRootProps()} className="upload-content" id="unique-22572326div_18">
                        <input {...getInputProps()} accept={this.props.acceptedFileTypes} multiple={this.props.product === 'iDrawings-PID-Update' ? false : true} id="29369677" />
                        <div className="cloud" id="unique-51057764div_19">
                          <i className="fas fa-cloud-upload-alt" id="unique-11135738i_10"></i>
                          <h1 id="unique-45827200h1_4">
                            Drag 'n' drop, or click to select{' '}
                            <b className="text-danger">
                              {this.props.product === 'iDrawings-PID-Update' && !this.state.original_file ? 'Original' : null}{' '}
                              {this.props.product === 'iDrawings-PID-Update' && this.state.original_file && !this.state.markup_file ? 'Markup' : null}
                            </b>{' '}
                            files
                          </h1>
                          <p id="unique-96017221p_1">{this.props.acceptedFileTypesMessage}</p>

                          {this.state.files.length ? (
                            <div className="mt-2" id="unique-10134779div_20">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.setState({
                                    uploadView: false,
                                  })
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </section>
          )}
        </Dropzone>
      </>
    )
  }

  render() {
    return this.renderUploadDocument()
  }
}

export default connect(null, {
  uploadFile,
  checkPdf,
  uploadPidUpdateFile,
})(UploadDocument)
