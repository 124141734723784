import React from 'react'
import './Forms.css'
import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'

import { uploadReport } from './../../store/actions'

class UploadReportFile extends React.Component {
  state = {
    uploading: false,
    errorMessage: null,
  }

  handleDocument = async (files) => {
    if (files[0].size > 100 * 1024 * 1024) {
      this.setState({
        errorMessage: `${files[0].name}: Max allowed file size is 100MB`,
      })
      return
    }

    await this.setState({ uploading: true })
    const formData = new FormData()
    formData.append('report_type', this.props.report_type)
    formData.append('projectId', this.props.projectId)
    formData.append('projectIds', JSON.stringify(this.props.selected_projects))
    formData.append('client', this.props.client)
    formData.append('file', files[0])
    formData.append('fileName', files[0].name)
    await this.props.uploadReport(formData)
    await this.setState({ uploading: false })
    if (!this.props.user.displayMessageCode) {
      this.setState({
        successMessage: 'File was uploaded Successfully!',
      })
    }
    await this.setState({ uploading: false })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <div className="alert alert-danger">
            {this.state.errorMessage}
            <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}

        <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <button {...getRootProps()} className="btn btn-primary" disabled={this.state.uploading} type="button">
              <input {...getInputProps()} multiple={false} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              {!this.state.uploading ? (
                <>
                  Select File <i className="fa fa-upload" />
                </>
              ) : (
                <>
                  Uploading File <i className="fa fa-spin fa-spinner"></i>
                </>
              )}
            </button>
          )}
        </Dropzone>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { uploadReport })(UploadReportFile)
