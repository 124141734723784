import React from 'react'
import { connect } from 'react-redux'
import { dataQualityMetrics, dataQualityPlots } from './../../store/actions'

class DataQuality extends React.Component {
  state = {
    allDrawings: [],
    activeDrawingIndex: 1,
    options: false,
    plotOptions: false,
    projectName: null,
    projectId: null,
    product: null,
    downloadingQualityExcel: false,
    downloadingQualityDrawing: false,
    module: null,
  }

  componentDidMount() {
    this.setState({
      allDrawings: this.props.allDrawings,
      activeDrawingIndex: this.props.activeDrawingIndex,
      projectName: this.props.projectName,
      projectId: this.props.projectId,
      product: this.props.product,
      module: this.props.module,
    })
  }

  componentDidUpdate(props) {
    if (this.props !== props) {
      this.setState({
        allDrawings: this.props.allDrawings,
        activeDrawingIndex: this.props.activeDrawingIndex,
      })
    }
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  fetchDataQualityMetricsExcel = (drawing) => {
    this.props.updateProcessDrawingsError(null)
    let formData = new FormData()
    formData.append('project_name', this.state.projectName)
    formData.append('excel_name', 'data_quality_metrics.xlsx')
    formData.append('product_type', this.state.product)
    formData.append('projectId', this.state.projectId)
    let drawing_id_list = []
    this.state.allDrawings.forEach((drawing) => {
      drawing_id_list.push(drawing.b_Id)
    })
    formData.append('drawing_id_list', drawing_id_list)

    this.setState({ downloadingQualityExcel: true }, async () => {
      await this.props.dataQualityMetrics(formData)
      if (this.props.user.processDrawingsError) {
        this.props.updateProcessDrawingsError(this.props.user.processDrawingsError)
      } else {
        if (this.props.user.qualityMetricsExcel) {
          let buffer = this._base64ToArrayBuffer(this.props.user.qualityMetricsExcel)
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `data_quality_metrics_${drawing.b_Id}.xlsx`) //or any other extension
          link.click()
        } else {
          this.props.updateProcessDrawingsError('Unable to fetch requested file')
        }
      }
      this.setState({ downloadingQualityExcel: false })
    })
  }

  fetchDataQualityPlotsDrawing = (data_type, drawing) => {
    this.props.updateProcessDrawingsError(null)
    let formData = new FormData()
    formData.append('project_name', this.state.projectName)
    formData.append('projectId', this.state.projectId)
    formData.append('product_type', this.state.product)
    formData.append('drawing_l_id', drawing.id)
    formData.append('drawing_b_id', drawing.b_Id)
    formData.append('data_type', data_type)
    formData.append('module', this.state.module)
    this.setState({ downloadingQualityDrawing: true }, async () => {
      await this.props.dataQualityPlots(formData)
      if (this.props.user.processDrawingsError) {
        this.props.updateProcessDrawingsError(this.props.user.processDrawingsError)
      } else {
        if (this.props.user.qualityPlotsDrawing) {
          let buffer = this._base64ToArrayBuffer(this.props.user.qualityPlotsDrawing)
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `data_quality_plots_${data_type} VS CAD_${drawing.b_Id}.png`) //or any other extension
          link.click()
        } else {
          this.props.updateProcessDrawingsError('Unable to fetch requested file')
        }
      }
      this.setState({ downloadingQualityDrawing: false })
    })
  }

  render() {
    if (this.state.allDrawings.length > 0) {
      let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
      let allDrawingsProcessed = true
      this.state.allDrawings.forEach((drawing) => {
        if (!drawing.processed) {
          allDrawingsProcessed = false
        }
      })

      let AIVsCAD_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_view-details_ai-vs-cad_hidden`)) {
        AIVsCAD_hidden = true
      }

      let ConnectivityPlot_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_view-details_connectivity-plot_hidden`)) {
        ConnectivityPlot_hidden = true
      }

      let CombinedDataMetrics_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_view-details_combined-data-metrics_hidden`)) {
        CombinedDataMetrics_hidden = true
      }

      let DataQuality_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_view-details_data-quality_hidden`)) {
        DataQuality_hidden = true
      }

      if (activeDrawing.processed || allDrawingsProcessed) {
        return (
          <div className="dropup" id="unique-93538168div_1">
            <div className="deliverable-btn text-center mt-3" id="unique-51105502div_2">
              <button
                className={`btn btn-primary ${DataQuality_hidden ? 'd-none' : ''}`}
                onClick={() => {
                  this.setState({
                    options: !this.state.options,
                  })
                }}
              >
                Data Quality {this.state.options ? <i className="fa fa-caret-up" id="10429521" /> : <i className="fa fa-caret-down" id="10429521" />}
              </button>
            </div>

            {this.state.options ? (
              <div className="dropdown-menu" id="unique-70204676div_3">
                {!ConnectivityPlot_hidden ? (
                  <button
                    disabled={!activeDrawing.processed}
                    className={`dropdown-item`}
                    onClick={() => {
                      this.setState({
                        plotOptions: !this.state.plotOptions,
                      })
                    }}
                  >
                    Connectivity Plot &nbsp;
                    {this.state.plotOptions ? <i className="fa fa-caret-up" id="10429521" /> : <i className="fa fa-caret-down" id="10429521" />}
                    {this.state.downloadingQualityDrawing ? (
                      <b>
                        &nbsp;&nbsp;
                        <i className="fa fa-spin fa-spinner" id="unique-63868467i_5"></i>
                      </b>
                    ) : null}
                  </button>
                ) : null}

                {this.state.plotOptions ? (
                  !AIVsCAD_hidden ? (
                    <button className={`dropdown-item ml-3`} onClick={() => this.fetchDataQualityPlotsDrawing('AI', activeDrawing)}>
                      AI vs CAD
                    </button>
                  ) : null
                ) : null}

                <button
                  disabled={!allDrawingsProcessed}
                  className={`dropdown-item ${CombinedDataMetrics_hidden ? 'd-none' : ''}`}
                  onClick={() => this.fetchDataQualityMetricsExcel(activeDrawing)}
                >
                  Combined Data Metrics {this.state.downloadingQualityExcel ? <i className="fa fa-spin fa-spinner" /> : null}
                </button>
              </div>
            ) : null}
          </div>
        )
      }
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  dataQualityMetrics,
  dataQualityPlots,
})(DataQuality)
