import React from 'react'
import { connect } from 'react-redux'

import DataQuality from './dataQuality.js'
import Select from '../Forms/Fields/select.js'
import { viewDrawings, updateProcessDrawings, applyCustomization, fetchImageUrl } from './../../store/actions'

class Process extends React.Component {
  state = {
    product: this.props.product,
    productId: null,
    projectName: null,
    userId: null,
    errorMessage: null,
    activeDrawingId: null,
    activeDrawingUrl: null,
    loading: false,
    zoom: 0.75,
    zoomOptions: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8.5, 9.5, 10, 10.5, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16],
    selectedDrawings: [],
    allDrawings: [],
    activeDrawingIndex: 1,
    currentQuantities: true,
    componentsListInit: [],
    componentLoading: false,
    drawingVersionIterator: 0,
    module: 'digitize',
    sideNavCollapse: false,
    slideBtns: true,
    zoomBtns: true,
    reload_type: 'AI',
    showOriginalDrawing: false,
  }

  componentDidMount() {
    this.setState(
      {
        projectName: this.props.projectName,
        userId: this.props.user.userId,
      },
      this.requestProcessedDrawings
    )
  }

  requestProcessedDrawings = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('drawingList', this.props.selectedDrawings)
    formData.append('documentList', this.props.selectedPdfs)
    formData.append('projectId', this.props.projectId)
    formData.append('module', this.state.module)
    formData.append('reprocess', false)

    await this.props.viewDrawings(formData)
    if (this.props.user.processDrawings.processDrawings.length > 0) {
      let selectedDrawings = []
      this.props.user.processDrawings.processDrawings.forEach((drawing) => {
        selectedDrawings.push(drawing.id)
      })

      await this.setState({
        selectedDrawings: selectedDrawings,
        allDrawings: this.props.user.processDrawings.processDrawings,
        componentsListInit: this.props.user.componentListInit,
        activeDrawingId: this.props.user.processDrawings.processDrawings[0].id,
      })
      this.updateProcessDrawings(false, 'AI')
    }

    this.setState({ loading: false })
  }

  updateProcessDrawings = async (reload, reload_type = 'AI') => {
    let allDrawings = this.state.allDrawings
    allDrawings[this.state.activeDrawingIndex - 1].reload_type = reload_type
    await this.setState({ allDrawings: allDrawings })

    const formData = new FormData()
    formData.append('drawingList', this.state.selectedDrawings)
    formData.append('projectId', this.props.projectId)
    formData.append('module', this.state.module)
    formData.append('mode', this.props.mode)

    if (reload) {
      formData.append('reload', true)
      formData.append('reload_type', reload_type)
      formData.append('reloadDrawingId', this.state.activeDrawingId)
      this.reloadDataFunction(formData)
    } else {
      formData.append('reload', false)
      formData.append('reloadDrawingId', null)
      await this.props.updateProcessDrawings(formData)
      if (this.props.user.processDrawingsError) {
        this.setState({
          errorMessage: this.props.user.processDrawingsError,
        })
      } else {
        await this.setState({ allDrawings: this.props.user.processDrawings.processDrawings })
        this.fetchActiveDrawingUrl()
      }
    }
  }

  reloadDataFunction(formData) {
    this.setState({ componentLoading: true, slideBtns: false }, () => {
      this.props.updateProcessDrawings(formData).then(() => {
        this.setState({ componentLoading: false, slideBtns: true }, this.updateReloadData)
      })
    })
  }

  updateReloadData = () => {
    let newAllDrawings = []
    this.props.user.processDrawings.processDrawings.map((drawing) => {
      if (drawing.id === this.state.activeDrawingId) {
        newAllDrawings.push(drawing)
      } else {
        let index = this.state.allDrawings.findIndex((d) => d.id === drawing.id)
        let old_drawing = this.state.allDrawings[index]
        newAllDrawings.push(old_drawing)
      }
      return null
    })
    this.setState({ allDrawings: newAllDrawings }, () => {
      this.renderComponentList()
      this.drawingCacheIterator()
    })
  }

  renderComponentList = () => {
    let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
    if (!this.state.componentsListInit.length) {
      return (
        <div className="mt-4 mb-4 text-center" id="unique-37734603div_1">
          <h3 id="unique-64107029h3_1">
            <i className="fa fa-spin fa-spinner" id="20102935" />
          </h3>
        </div>
      )
    }

    return this.state.componentsListInit.map((component) => {
      if (activeDrawing.processed && !this.state.showOriginalDrawing) {
        return (
          <div key={component} className="text-center mb-2" id="unique-33195761div_2">
            <label className="switch" id="unique-43446974label_1">
              {activeDrawing.component_list.includes(component) && activeDrawing.available_component_list.includes(component) ? (
                <input type="checkbox" className="checked" onClick={() => this.updateComponentList(component)} />
              ) : !activeDrawing.component_list.includes(component) && activeDrawing.available_component_list.includes(component) ? (
                <input type="checkbox" onClick={() => this.updateComponentList(component)} />
              ) : (
                <input type="checkbox" disabled id="20102935" />
              )}
              <span className={'slider ' + component} id="unique-70308823span_1">
                {activeDrawing.component_list.includes(component) ? <b className="text-left">{component}</b> : <b className="text-right">{component}</b>}
              </span>
            </label>
          </div>
        )
      } else {
        return (
          <div key={component} className="text-center mb-2" id="unique-93789716div_3">
            <label className="switch" id="unique-50632708label_2">
              <input type="checkbox" disabled id="20102935" />
              <span className="slider" id="unique-60019320span_2">
                <b className="text-right">{component}</b>
              </span>
            </label>
          </div>
        )
      }
    })
  }

  updateComponentList(component) {
    let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
    let new_component_list = activeDrawing.component_list
    let newAllDrawings = []
    if (new_component_list.includes(component)) {
      const index = new_component_list.indexOf(component)
      if (index > -1) {
        new_component_list.splice(index, 1)
      }
    } else {
      new_component_list.push(component)
    }
    activeDrawing.component_list = new_component_list
    this.state.allDrawings.map((drawing) => {
      if (drawing.id === activeDrawing.id) {
        newAllDrawings.push(activeDrawing)
      } else {
        newAllDrawings.push(drawing)
      }
      return null
    })

    this.setState({ allDrawings: newAllDrawings }, this.applyCustomization)
  }

  drawingCacheIterator() {
    if (this.state.activeDrawingUrl.includes('?')) {
      this.setState({
        activeDrawingUrl: this.state.activeDrawingUrl.replace('?' + this.state.drawingVersionIterator.toString(), '?' + (this.state.drawingVersionIterator + 1).toString()),
        drawingVersionIterator: this.state.drawingVersionIterator + 1,
      })
    } else {
      this.setState({
        activeDrawingUrl: this.state.activeDrawingUrl + '?' + this.state.drawingVersionIterator.toString(),
      })
    }
  }

  applyCustomization = () => {
    let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
    let formData = new FormData()
    formData.append('drawingId', this.state.activeDrawingId)
    formData.append('projectId', this.props.projectId)
    formData.append('component_list', this.state.allDrawings[this.state.activeDrawingIndex - 1].component_list)
    formData.append('reload_type', activeDrawing.reload_type)
    this.setState({ componentLoading: true }, () => {
      this.props
        .applyCustomization(formData)
        .then(() => {
          this.setState({
            componentLoading: false,
            slideBtns: false,
          })
          this.drawingCacheIterator()
        })
        .then(() => {
          let newAllDrawings = []
          this.state.allDrawings.map((drawing) => {
            if (drawing.id === this.state.activeDrawingId) {
              let newDrawing = drawing
              newDrawing.url = this.state.activeDrawingUrl
              newAllDrawings.push(newDrawing)
            } else {
              newAllDrawings.push(drawing)
            }
            return null
          })

          this.setState({
            allDrawings: newAllDrawings,
            slideBtns: true,
          })
        })
    })
  }

  zoomInPage = () => {
    const { zoom, zoomOptions } = this.state
    const currentIndex = zoomOptions.indexOf(zoom)
    if (currentIndex < zoomOptions.length - 1) {
      this.setState({ zoom: zoomOptions[currentIndex + 1] })
    }
  }

  zoomOutPage = () => {
    const { zoom, zoomOptions } = this.state
    const currentIndex = zoomOptions.indexOf(zoom)
    if (currentIndex > 0) {
      this.setState({ zoom: zoomOptions[currentIndex - 1] })
    }
  }

  nextDrawing() {
    let activeDrawingId = this.state.activeDrawingId
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === activeDrawingId)
    if (index > -1) {
      if (index + 1 === this.state.allDrawings.length) {
        this.changeActiveDrawing(this.state.allDrawings[0].id)
      } else {
        this.changeActiveDrawing(this.state.allDrawings[index + 1].id)
      }
    }
  }

  prevDrawing() {
    let activeDrawingId = this.state.activeDrawingId
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === activeDrawingId)
    if (index > -1) {
      if (index === 0) {
        this.changeActiveDrawing(this.state.allDrawings[this.state.allDrawings.length - 1].id)
      } else {
        this.changeActiveDrawing(this.state.allDrawings[index - 1].id)
      }
    }
  }

  fetchActiveDrawingUrl = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('drawingId', this.state.activeDrawingId)
    formData.append('product', this.state.product)
    formData.append('projectId', this.props.projectId)
    await this.props.fetchImageUrl(formData)
    await this.setState({
      activeDrawingUrl: this.props.user.image_url,
    })
    await this.setState({ loading: false })
  }

  changeActiveDrawing = async (id) => {
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === id)
    await this.setState({
      activeDrawingId: id,
      activeDrawingIndex: index + 1,
    })
    this.fetchActiveDrawingUrl()
  }

  updateProcessDrawingsError = (message) => {
    this.setState({ errorMessage: message })
  }

  renderDataQualityButton() {
    let product = this.state.product
    return (
      <DataQuality
        allDrawings={this.state.allDrawings}
        activeDrawingIndex={this.state.activeDrawingIndex}
        projectName={this.state.projectName}
        projectId={this.props.projectId}
        product={product}
        updateProcessDrawingsError={this.updateProcessDrawingsError}
        module={this.state.module}
      />
    )
  }

  renderSideNav() {
    if (this.state.loading) {
      return (
        <h1 className="text-center mt-4" id="unique-15708934h1_1">
          <i className="fa fa-spinner fa-spin" id="unique-88238119i_2"></i>
        </h1>
      )
    } else {
      return (
        <div className="mt-4" id="unique-55107872div_4">
          {this.renderOriginalDrawingButton()}
          {this.renderReloadButton()}
          <h5 className="text-center" id="unique-88789485h5_1">
            <b>Key Quantities</b>
          </h5>
          <div className="switch-quantities mt-3 mb-3 text-center" id="unique-55440865div_5">
            {this.state.currentQuantities ? (
              <span className="left active" id="unique-24866214span_3">
                Current
              </span>
            ) : (
              <span className="left" onClick={() => this.setState({ currentQuantities: true })}>
                Current
              </span>
            )}

            {!this.state.currentQuantities ? (
              <span className="right active" id="unique-61560054span_5">
                Total
              </span>
            ) : (
              <span className="right" onClick={() => this.setState({ currentQuantities: false })}>
                Total
              </span>
            )}
          </div>
          <div className="quantities" id="unique-77406545div_6">
            {this.renderQuantities()}
          </div>

          <h5 className="text-center mt-4 mb-4" id="unique-65531710h5_2">
            <b>Components</b>
          </h5>
          <div className="mb-4" id="unique-28743115div_7">
            {this.renderComponentList()}
          </div>

          {this.renderDataQualityButton()}
        </div>
      )
    }
  }

  renderReloadButton() {
    if (this.state.allDrawings.length > 0) {
      let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]

      if (activeDrawing.processed && !this.state.showOriginalDrawing) {
        return (
          <div className="text-center mb-3 mt-4" id="unique-85663976div_8">
            <label className="drawing-switch" id="unique-24860709label_3">
              {activeDrawing.reload_type === 'IUI' ? (
                <input type="checkbox" className="checked" onClick={() => this.updateProcessDrawings(true, 'AI')} />
              ) : (
                <input type="checkbox" onClick={() => this.updateProcessDrawings(true, 'IUI')} />
              )}

              <span className={'slider'} id="unique-64737935span_7">
                {activeDrawing.reload_type === 'IUI' ? (
                  <>
                    <b className="text-left">AI Mode</b>
                    <b className="text-right text-white">IUI Mode</b>
                  </>
                ) : (
                  <>
                    <b className="text-left text-white">AI Mode</b>
                    <b className="text-right">IUI Mode</b>
                  </>
                )}
              </span>
            </label>
          </div>
        )
      } else {
        return (
          <div className="text-center mb-3 mt-4" id="unique-97549337div_9">
            <label className="drawing-switch" id="unique-72030075label_4">
              <input type="checkbox" disabled id="20102935" />
              <span className="slider" id="unique-60374958span_8">
                <b className="text-left text-white">AI Mode</b>
                <b className="text-right">IUI Mode</b>
              </span>
            </label>
          </div>
        )
      }
    }
  }

  renderOriginalDrawingButton() {
    if (this.state.allDrawings.length > 0) {
      let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]

      if (activeDrawing.processed) {
        return (
          <div className="text-center mb-3 mt-4" id="unique-10968688div_10">
            <label className="drawing-switch" id="unique-25688252label_5">
              <input
                type="checkbox"
                className={`${this.state.showOriginalDrawing ? '' : 'checked'}`}
                onClick={() =>
                  this.setState({
                    showOriginalDrawing: !this.state.showOriginalDrawing,
                  })
                }
              />

              <span className={'slider'} id="unique-23065330span_9">
                <b className={`text-left ${!this.state.showOriginalDrawing ? '' : 'text-white'}`}>Original</b>
                <b className={`text-right ${this.state.showOriginalDrawing ? '' : 'text-white'}`}>Detected</b>
              </span>
            </label>
          </div>
        )
      } else {
        return (
          <div className="text-center mb-3 mt-4" id="unique-10183419div_11">
            <label className="drawing-switch" id="unique-49379959label_6">
              <input type="checkbox" disabled id="20102935" />
              <span className="slider" id="unique-47634083span_10">
                <b className="text-left text-white">Original</b>
                <b className="text-right">Detected</b>
              </span>
            </label>
          </div>
        )
      }
    }
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  renderQuantities = () => {
    if (this.state.allDrawings.length > 0) {
      if (this.state.currentQuantities) {
        let fetching = true
        Object.values(this.state.allDrawings[this.state.activeDrawingIndex - 1].key_quantities).forEach((val) => {
          if (val >= 0) {
            fetching = false
          }
        })

        if (Object.keys(this.state.allDrawings[this.state.activeDrawingIndex - 1].key_quantities).length) {
          fetching = false
        }

        if (!fetching) {
          return Object.entries(this.state.allDrawings[this.state.activeDrawingIndex - 1].key_quantities).map(([key, val]) => {
            return (
              <div className="clearfix mb-2" hidden={val < 0} key={key} id="unique-46341116div_12">
                <span className="float-right" id="unique-49502394span_11">
                  {val}
                </span>
                <b> {this.toTitleCase(key).replace('_', ' ')} </b>
              </div>
            )
          })
        } else {
          return (
            <div className="mt-4 mb-4 text-center" id="unique-51391019div_13">
              <h2 id="unique-41205303h2_1">
                <i className="fa fa-spinner fa-spin" id="unique-10859028i_3"></i>
              </h2>
            </div>
          )
        }
      } else {
        let total_key_quantities = {}
        this.state.allDrawings.forEach((drawing) => {
          Object.entries(drawing.key_quantities).forEach(([key, val]) => {
            if (!(key in total_key_quantities)) {
              total_key_quantities[key] = val
            } else {
              total_key_quantities[key] = total_key_quantities[key] + val
            }
          })
        })

        let fetching = true
        Object.entries(total_key_quantities).forEach(([key, val]) => {
          if (val >= 0) {
            fetching = false
          }
        })

        if (Object.keys(total_key_quantities).length) {
          fetching = false
        }

        if (!fetching) {
          return Object.entries(total_key_quantities).map(([key, val]) => {
            return (
              <div className="clearfix mb-2" hidden={val < 0} key={key} id="unique-93108471div_14">
                <span className="float-right" id="unique-68795446span_12">
                  {val}
                </span>
                <b> {this.toTitleCase(key).replace('_', ' ')} </b>
              </div>
            )
          })
        } else {
          return (
            <div className="mt-4 mb-4 text-center" id="unique-76814004div_15">
              <h2 id="unique-66453291h2_2">
                <i className="fa fa-spinner fa-spin" id="unique-76437847i_4"></i>
              </h2>
            </div>
          )
        }
      }
    }
  }

  renderSlider() {
    if (this.state.loading) {
      return (
        <div className="loader" id="unique-16037120div_16">
          <span id="unique-46639852span_13">Fetching Drawing ...</span>
        </div>
      )
    } else if (this.state.componentLoading) {
      return (
        <div className="loader" id="unique-70413671div_17">
          <i className="fa fa-spinner fa-spin" id="unique-80603177i_5"></i>
        </div>
      )
    } else {
      let activeDrawingUrl = this.state.activeDrawingUrl
      if (!activeDrawingUrl) {
        return null
      }

      let showOriginalDrawing = true
      if (this.state.allDrawings.length) {
        let activeDrawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
        if (activeDrawing.processed) {
          showOriginalDrawing = false
        }
      }

      if (this.state.showOriginalDrawing || showOriginalDrawing) {
        activeDrawingUrl = activeDrawingUrl.replace('_annotated', '')
      }
      return (
        <>
          {this.state.allDrawings.length > 0 ? (
            this.state.allDrawings[this.state.activeDrawingIndex - 1].errorMessage ? (
              <div className="alert alert-warning fade show" style={{ marginBottom: 0 }} id="unique-12926269div_18">
                <i className="fa fa-exclamation-triangle" id="unique-10118162i_6"></i> {this.state.allDrawings[this.state.activeDrawingIndex - 1].errorMessage}
              </div>
            ) : null
          ) : null}
          <img src={activeDrawingUrl} alt={this.state.activeDrawingId} style={{ width: `${Math.round(this.state.zoom * 100)}%` }} id="20102935" />
        </>
      )
    }
  }

  toggleSideNav() {
    this.setState({ sideNavCollapse: !this.state.sideNavCollapse })
  }

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + ' ...' : str
  }

  renderProcess() {
    return (
      <section className="view">
        {this.state.errorMessage ? (
          <div className="alert alert-danger alert-dismissible fade show" style={{ marginBottom: 0 }} id="unique-32402368div_19">
            <i className="fa fa-exclamation-triangle" id="unique-10232985i_7"></i> {this.state.errorMessage}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: null })}>
              <span aria-hidden="true" id="unique-76211638span_14">
                &times;
              </span>
            </button>
          </div>
        ) : null}
        <div className="process-flex" id="unique-15398063div_20">
          <div className="left-flex" id="unique-68898340div_21">
            <div className="slider-nav" id="unique-33115257div_22">
              <div className="clearfix" id="unique-26584752div_23">
                <span className="float-right" id="unique-14489744span_15">
                  <button className="btn btn-secondary" onClick={() => this.zoomOutPage()} disabled={this.state.errorMessage || this.state.zoom === this.state.zoomOptions[0]}>
                    <i className="fa fa-search-minus" id="unique-22068240i_8"></i>
                  </button>
                  <div className="d-inline-block mx-2" style={{ width: 120 }} id="unique-52689345div_24">
                    <Select
                      options={this.state.zoomOptions.map((option) => {
                        return {
                          label: `${Math.round(option * 100)} %`,
                          value: option,
                        }
                      })}
                      value={{
                        label: `${Math.round(this.state.zoom * 100)} %`,
                        value: this.state.zoom,
                      }}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          this.setState({ zoom: selectedOption.value })
                        }
                      }}
                      isMulti={false}
                      isClearable={false}
                    />
                  </div>
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.zoomInPage()}
                    disabled={this.state.errorMessage || this.state.zoom === this.state.zoomOptions[this.state.zoomOptions.length - 1]}
                  >
                    <i className="fa fa-search-plus" id="unique-53414063i_9"></i>
                  </button>
                </span>
                <span className="float-right" style={{ marginRight: '40px' }} id="unique-63681982span_16">
                  <button className="btn custom" onClick={() => this.prevDrawing()} disabled={this.state.slideBtns ? false : true}>
                    <i className="fa fa-angle-left" id="unique-72077419i_10"></i>
                  </button>
                  <button className="btn custom active-drawing" disabled id="unique-48729933button_5">
                    {this.state.activeDrawingIndex}/{this.state.allDrawings.length}
                  </button>
                  <button className="btn custom" onClick={() => this.nextDrawing()} disabled={this.state.slideBtns ? false : true}>
                    <i className="fa fa-angle-right" id="unique-47505165i_11"></i>
                  </button>
                </span>
                <h4 style={{ display: 'inline-block' }} id="unique-12638365h4_1">
                  <b>{this.state.projectName}</b>
                </h4>
                &nbsp;
                {this.state.allDrawings.length > 0 ? (
                  <span className="font-italic text-secondary" title={this.state.allDrawings[this.state.activeDrawingIndex - 1].b_Id} id="unique-67468013span_17">
                    {this.truncate(this.state.allDrawings[this.state.activeDrawingIndex - 1].b_Id, 120)}
                  </span>
                ) : null}
                {this.state.sideNavCollapse ? (
                  <span className="collapse-btn" onClick={() => this.toggleSideNav()}>
                    <i className="fa fa-angle-left" id="unique-20073088i_12"></i>
                  </span>
                ) : (
                  <span className="collapse-btn" onClick={() => this.toggleSideNav()}>
                    <i className="fa fa-angle-right" id="unique-61648825i_13"></i>
                  </span>
                )}
              </div>
            </div>
            <div className="slider" id="unique-84984690div_25">
              {this.renderSlider()}
            </div>
          </div>
          <div className={this.state.sideNavCollapse ? 'right-flex-collapse' : 'right-flex'} id="unique-67924008div_26">
            <div className="key-quantities" id="unique-75106862div_27">
              <div className="clearfix" id="unique-10737227div_28">
                <button onClick={() => this.props.closeProcessView()} className="close custom float-right">
                  <i className="fa fa-times" />
                </button>
              </div>
              {this.renderSideNav()}
            </div>
          </div>
        </div>
      </section>
    )
  }

  render() {
    return this.renderProcess()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  viewDrawings,
  updateProcessDrawings,
  applyCustomization,
  fetchImageUrl,
})(Process)
