import React from 'react'

class ToggleSwitch extends React.Component {
  onToggle = (e, switchName, path) => {
    this.props.updateToggledSwitchList(switchName, e.target.checked, path)
  }

  render() {
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={this.props.isToggled} onChange={(e) => this.onToggle(e, this.props.switchName, this.props.path)} />
        <span className={`switcher ${this.props.className}`} />
      </label>
    )
  }
}

export default ToggleSwitch
