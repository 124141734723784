import React from 'react'
import ReactDOM from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import App from './Components/App'
import reducer from './store/reducer'

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log(e)
  }
}

function loadToLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

const persistedState = loadToLocalStorage()

const store = createStore(reducer, persistedState, applyMiddleware(thunk))

store.subscribe(() => saveToLocalStorage(store.getState()))

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey="6LfkeaMZAAAAALgrg6TvpvU60Hp572f8rp6-5862">
        <App />
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.querySelector('#root'))
