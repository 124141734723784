import React from 'react'
import { connect } from 'react-redux'
import { modelConfigurationInitiateTraining } from './../../../store/actions'

class Step4 extends React.Component {
  state = {
    successMessage: null,
  }

  componentDidMount() {
    this.modelConfigurationInitiateTraining()
  }

  modelConfigurationInitiateTraining = async () => {
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('tagging', this.props.tagging)
    await this.props.modelConfigurationInitiateTraining(formData)

    if (this.props.user.displayMessageCode) {
      this.setState({ successMessage: null })
    } else {
      this.setState({
        successMessage: this.props.user.modelConfigurationInitiateTrainingMessage.message,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="card mb-2" id="unique-11374358div_1">
          <div className="card-body p-2" id="unique-88258588div_2">
            <div className="clearfix" id="unique-85920362div_3">
              <button
                onClick={() => {
                  this.props.changeScreen(3)
                }}
                className="btn btn-default"
              >
                Back
              </button>
            </div>
          </div>
        </div>

        <div className="table-container p-4" id="unique-79211239div_4">
          <h3 className="text-center" id="unique-47732091h3_1">
            {this.props.tagging ? 'Tagging' : 'Model '} Configuration Statistics/Updates
          </h3>
          {this.state.successMessage ? (
            <div className="alert alert-success text-center" id="unique-63869767div_5">
              {this.state.successMessage}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  modelConfigurationInitiateTraining,
})(Step4)
