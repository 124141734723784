import React, { useRef, useEffect, useState } from 'react'
import Tiff from 'browser-tiff.js'
import Select from '../Forms/Fields/select'

const TiffViewer = ({ tiffBase64 }) => {
  const canvasRef = useRef(null)
  const [zoomLevel, setZoomLevel] = useState(0.75)
  const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8.5, 9.5, 10, 10.5, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16]

  useEffect(() => {
    const binaryData = atob(tiffBase64)
    const uint8Array = new Uint8Array(binaryData.length)

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }

    const blob = new Blob([uint8Array], { type: 'image/tiff' })
    const blobURL = URL.createObjectURL(blob)

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    const xhr = new XMLHttpRequest()
    xhr.open('GET', blobURL, true)
    xhr.responseType = 'arraybuffer'

    xhr.onload = () => {
      const tiff = new Tiff({ buffer: xhr.response })
      const tiffCanvas = tiff.toCanvas()

      canvas.width = tiffCanvas.width * zoomLevel
      canvas.height = tiffCanvas.height * zoomLevel
      ctx.scale(zoomLevel, zoomLevel) // Apply the zoom level
      ctx.drawImage(tiffCanvas, 0, 0)
    }

    xhr.send()
  }, [tiffBase64, zoomLevel])

  const handleZoomChange = (selectedOption) => {
    if (selectedOption) {
      setZoomLevel(selectedOption.value)
    }
  }

  const zoomIn = () => {
    const currentIndex = zoomOptions.indexOf(zoomLevel)
    if (currentIndex < zoomOptions.length - 1) {
      setZoomLevel(zoomOptions[currentIndex + 1])
    }
  }

  const zoomOut = () => {
    const currentIndex = zoomOptions.indexOf(zoomLevel)
    if (currentIndex > 0) {
      setZoomLevel(zoomOptions[currentIndex - 1])
    }
  }

  return (
    <div style={{ position: 'relative' }} id="unique-22764964div_1">
      <div
        style={{
          height: '40.5rem',
          position: 'absolute',
          width: '100%',
          backgroundColor: '#fff',
          overflow: 'auto',
        }}
        className="border"
        id="unique-49019794div_2"
      >
        <canvas ref={canvasRef} />
      </div>

      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }} className="clearfix p-1 bg-light border" id="unique-17225555div_3">
        <button onClick={zoomOut} className="btn btn-secondary" disabled={zoomLevel === zoomOptions[0]} id="unique-10924796button_1">
          <i className="fa fa-search-minus" id="unique-39676927i_1"></i>
        </button>

        <div className="d-inline-block mx-2" style={{ width: 120 }} id="unique-10568856div_4">
          <Select
            options={zoomOptions.map((option) => {
              return {
                label: `${Math.round(option * 100)} %`,
                value: option,
              }
            })}
            value={{
              label: `${Math.round(zoomLevel * 100)} %`,
              value: zoomLevel,
            }}
            onChange={handleZoomChange}
            isMulti={false}
            isClearable={false}
          />
        </div>

        <button onClick={zoomIn} className="btn btn-secondary" disabled={zoomLevel === zoomOptions[zoomOptions.length - 1]} id="unique-95334990button_2">
          <i className="fa fa-search-plus" id="unique-18429066i_2"></i>
        </button>
      </div>
    </div>
  )
}

export default TiffViewer
