import React from 'react'
import './WebComponent.css'

const NotFound = () => {
  return (
    <div className="dashboard main" id="unique-61117573div_1">
      <section className="row">
        <div className="col-md-4 offset-md-4 text-center" id="unique-51460266div_2">
          <div className="card not-found" id="unique-10233142div_3">
            <div className="card-body" id="unique-43448127div_4">
              <h3 id="unique-10169700h3_1">OPPS PAGE NOT FOUND</h3>
              <h1 id="unique-88203592h1_1">404</h1>
              <h4 id="unique-41259401h4_1">WE ARE SORRY BUT THE PAGE YOU REQUESTED WAS NOT FOUND.</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotFound
