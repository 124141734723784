import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ProjectSetup from '../Components/Forms/ProjectSetup/ProjectSetup'
import ProjectCard from '../Components/WebComponents/ProjectCard'
import Delete from '../Components/WebComponents/DeletePopup'
import TradedocumentProject from './Project'

import { fetchProjects, fetchProjectsData, deleteProject } from '../store/actions'

class TradeDocumentDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      product: '',
      page: '',
      projectsData: [],

      loading: false,
      deletingProject: false,
      deleteProjectObject: null,
      deleteProjectId: null,
    }
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const product = query.get('product')
    await this.setState({
      product: product,
      page: this.props.page,
    })

    this.fetchProjectsHandler()
  }

  componentDidUpdate = (props) => {
    if (props.page !== this.props.page) {
      this.fetchProjectsHandler()
    }
  }

  fetchProjectsHandler = async () => {
    await this.setState({ loading: true })
    await this.props.fetchProjectsData()
    await this.setState({ loading: false, projectsData: this.props.user.projectsData })
  }

  deleteProjectHandler = async (projectId) => {
    await this.setState({ deletingProject: true })
    let formData = new FormData()
    formData.append('projectId', projectId)
    await this.props.deleteProject(formData)
    await this.setState({ deleteProjectObject: null, deletingProject: false })
    this.fetchProjectsHandler()
  }

  deleteProjectToggler = (project) => {
    this.setState({
      deleteProjectObject: project,
    })
  }

  renderProjects() {
    if (this.state.loading) {
      return (
        <div className="screen-loader">
          <div id="loading" />
        </div>
      )
    }

    let edit_project_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_edit-project_hidden`)
    let delete_project_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_delete-project_hidden`)

    return (
      <ProjectCard
        projectsData={this.state.projectsData}
        product={this.state.product}
        delete_project_hidden={delete_project_hidden}
        edit_project_hidden={edit_project_hidden}
        deleteProjectToggler={this.deleteProjectToggler}
      />
    )
  }

  setDropdownOpen = (obj) => {
    this.setState(obj)
  }

  render() {
    if (this.props.page === 'home') {
      let create_project_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_create-project_hidden`)

      return (
        <div className="dashboard main">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Product Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.product} Dashboard</li>
            </ol>
          </nav>

          <div className="clearfix">
            {!create_project_hidden ? (
              <Link
                id="tradedocument_dashboard_create_project_btn"
                className={`btn btn-secondary float-right`}
                to={`/idocuments-tradedocuments/project-setup?product=${this.state.product}&dashboard=true`}
              >
                Create Project
              </Link>
            ) : null}

            <h3 className="mb-4 font-weight-bold">{this.state.product} Dashboard</h3>
          </div>

          <div className="row">{this.renderProjects()}</div>

          {this.state.deleteProjectObject ? (
            <Delete
              toggle={this.deleteProjectToggler}
              deleteProject={this.deleteProjectHandler}
              project={this.state.deleteProjectObject}
              deletingProject={this.state.deletingProject}
            />
          ) : null}
        </div>
      )
    } else if (this.props.page === 'project') {
      return <TradedocumentProject {...this.props} />
    } else if (this.props.page === 'project-setup') {
      return <ProjectSetup {...this.props} />
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjects,
  fetchProjectsData,
  deleteProject,
})(TradeDocumentDashboard)
