import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Select from '../Forms/Fields/select.js'
import Input from '../Forms/Fields/input.js'
import DisplayMessage from '../ErrorHandling/DisplayMessage.js'

import { PagingState, CustomPaging, SearchState } from '@devexpress/dx-react-grid'
import { Grid, TableHeaderRow, PagingPanel, VirtualTable, TableColumnResizing, Toolbar, SearchPanel } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

import { fetchEditReport, updateReportFilter, saveAndPublishClientReport, refreshUserAuth } from '../../store/actions.js'

class ReportEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product: 'iworkflow-new',
      loading: false,

      user_clients: [],
      user_clients_selected: [],
      report_id: null,
      report_name: '',
      report_cols: [],
      report_rows: [],

      // Filtering
      filters: [],
      edit_filter_mode: false,
      filter_col_selected: '',
      filter_operator_selected: '',
      filter_value: '',
      operator_list: ['=', '!=', 'include(sen)', 'include'],

      // Togglers
      add_filter_view: false,
      save_as_draft_view: false,
      col_edit_view: false,
      save_and_publish_view: false,
      sort_view: false,
      show_columns_ordering: false,
      show_columns_grouping: false,

      report_col_group: [],
      report_original_cols: [],
      report_col_order: [],
      report_col_original_group: [],
      report_group_is_show: [],

      publishingDraft: false,
      is_published: null,
      report_client: [],
      savingDraft: false,
      col_edit_name: '',
      col_edit_name_save: '',

      // Sorting
      sorting: [],
      sort_col_selected: '',
      sort_order_selected: '',
      edit_sort_mode: false,

      // Paging
      currentPage: 0,
      pageSize: 100,
      totalCount: 0,
      pageSizes: [100, 150, 200],

      columnWidths: [],
      columnExtensions: [],

      // Search
      searchValue: '',
    }
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const reportId = query.get('reportId')
    await this.setState({
      report_id: reportId,
      user_clients: this.props.user.user_clients,
    })

    this.fetchEditReport()
  }

  getRowId = (row) => row.id

  setCurrentPage = async (page) => {
    await this.setState({ currentPage: page })
    this.fetchEditReport()
  }

  setPageSize = async (pageSize) => {
    await this.setState({ pageSize: pageSize, currentPage: 0 })
    this.fetchEditReport()
  }

  fetchEditReport = async () => {
    await this.setState({ loading: true })
    let take = this.state.pageSize
    let skip = this.state.pageSize * this.state.currentPage
    let formData = new FormData()
    formData.append('report_id', this.state.report_id)
    formData.append('take', take)
    formData.append('skip', skip)
    formData.append('sorting', JSON.stringify(this.state.sorting))
    formData.append('filters', JSON.stringify(this.state.filters))
    formData.append('searchValue', this.state.searchValue)
    await this.props.fetchEditReport(formData)
    this.setState({ loading: false })

    if (this.props.user.displayMessageCode) {
      return
    }

    let columns = []
    let col_order = []
    let report_col_group = []
    let columnWidths = []
    let columnExtensions = []
    this.props.user.editReportData.columns.forEach((item, index) => {
      if (this.props.user.editReportData.empty_column.includes(item.name)) {
        columnExtensions.push({
          columnName: item.name,
          filteringEnabled: false,
        })
      }

      columnWidths.push({
        columnName: item.name,
        width: 180,
      })
      if (!item.is_Group) {
        columns.push({
          id: index,
          name: item.name,
          title: item.displayName,
          type: item.type,
          checked: true,
          sequence: item.sequence,
          Column_group_id: item.Column_group_id,
          is_Group: item.is_Group,
          getCellValue: (row) => {
            return (
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  lineHeight: '32px',
                  height: '32px',
                }}
                title={row[item.name]}
                id="unique-54241428div_1"
              >
                {row[item.name]}
              </div>
            )
          },
        })
        col_order.push(item.name)
      } else {
        report_col_group.push({
          id: index,
          name: item.name,
          title: item.displayName,
          type: item.type,
          sequence: item.sequence,
          Column_group_id: item.Column_group_id,
          is_Group: item.is_Group,
        })
      }
    })

    let original_cols = []
    let report_col_original_group = []
    this.props.user.editReportData.original_columns.forEach((col) => {
      const is_present = this.props.user.editReportData.columns.some((o) => o.name === col.name)
      let check_status = true
      if (!is_present) {
        check_status = false
      }
      if (!col.is_Group) {
        original_cols.push({
          name: col.name,
          title: col.displayName,
          type: col.type,
          checked: check_status,
          sequence: col.sequence,
          Column_group_id: col.Column_group_id,
          is_Group: col.is_Group,
          getCellValue: (row) => {
            return (
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  lineHeight: '32px',
                  height: '32px',
                }}
                title={row[col.name]}
                id="unique-78243147div_2"
              >
                {row[col.name]}
              </div>
            )
          },
        })
      } else {
        report_col_original_group.push({
          name: col.name,
          title: col.displayName,
          type: col.type,
          checked: check_status,
          sequence: col.sequence,
          Column_group_id: col.Column_group_id,
          is_Group: col.is_Group,
        })
      }
    })
    var report_group_is_show = new Array(report_col_original_group.length).fill(false)

    let rows = []
    this.props.user.editReportData.report.forEach((items) => {
      rows.push(JSON.parse(JSON.stringify(items)))
    })

    await this.setState({
      report_cols: columns,
      report_original_cols: original_cols,
      report_col_order: col_order,
      report_col_group: report_col_group,
      report_col_original_group: report_col_original_group,
      report_group_is_show: report_group_is_show,
      report_rows: rows,
      report_name: this.props.user.editReportData.report_name,
      is_published: this.props.user.editReportData.is_published,
      report_client: this.props.user.editReportData.client,
      totalCount: this.props.user.editReportData.report_rows_count,
      filters: this.props.user.editReportData.filters,
      sorting: this.props.user.editReportData.sorting,
      columnWidths: columnWidths,
      columnExtensions: columnExtensions,
    })
  }

  handleChange = (index) => {
    let { report_original_cols, report_cols } = this.state

    report_original_cols[index].checked = !report_original_cols[index].checked

    if (!report_original_cols[index].checked) {
      report_cols = report_cols.filter((col) => col.name !== report_original_cols[index].name)
    } else {
      report_cols = report_original_cols.filter((col) => col.checked)
    }

    let columnWidths = []

    report_cols.forEach((item) => {
      columnWidths.push({
        columnName: item.name,
        width: 180,
      })
    })

    this.setState({
      report_cols: report_cols,
      report_original_cols: report_original_cols,
      columnWidths: columnWidths,
    })
  }

  handeleGroupShow = async (index) => {
    let { report_group_is_show } = this.state
    report_group_is_show[index] = !report_group_is_show[index]
    await this.setState({ report_group_is_show: report_group_is_show })
  }

  addFilter = async (e) => {
    e.preventDefault()
    let { filters, filter_col_selected, filter_operator_selected, filter_value, edit_filter_mode } = this.state

    if (!edit_filter_mode) {
      filters.push({
        operation: filter_operator_selected,
        columnName: filter_col_selected,
        value: filter_value,
      })
    } else {
      filters.forEach((filter) => {
        if (filter.columnName === filter_col_selected) {
          filter.value = filter_value
          filter.operation = filter_operator_selected
        }
      })
    }

    await this.setState({
      filter_col_selected: '',
      filter_operator_selected: '',
      filter_value: '',
      filters: filters,
      add_filter_view: false,
    })
  }

  deleteFilter = async (index) => {
    let { filters } = this.state
    filters.splice(index, 1)
    await this.setState({ filters: filters })
  }

  addSort = async (e) => {
    e.preventDefault()
    let { sorting, sort_col_selected, sort_order_selected, edit_sort_mode } = this.state

    if (!edit_sort_mode) {
      sorting.push({
        columnName: sort_col_selected,
        direction: sort_order_selected,
      })
    } else {
      sorting.forEach((item) => {
        if (item.columnName === sort_col_selected) {
          item.direction = sort_order_selected
        }
      })
    }

    await this.setState({
      sort_col_selected: '',
      sort_order_selected: '',
      sorting: sorting,
      sort_view: false,
    })
  }

  deleteSort = async (index) => {
    let { sorting } = this.state
    sorting.splice(index, 1)
    this.setState({ sorting: sorting })
  }

  setSearchState = async (searchValue) => {
    await this.setState({ searchValue: searchValue })
  }

  jump_back = () => {
    this.props.history.push({
      pathname: '/iworkflow-new/',
    })
  }

  updateReportFilter = async () => {
    await this.setState({ savingDraft: true })
    let formData = new FormData()
    formData.append('report_id', this.state.report_id)
    formData.append('report_name', this.state.report_name)
    formData.append('columns', JSON.stringify(this.state.report_cols))
    formData.append('col_original', JSON.stringify(this.state.report_original_cols))
    formData.append('col_groups', JSON.stringify(this.state.report_col_group))
    formData.append('col_original_group', JSON.stringify(this.state.report_col_original_group))
    formData.append('filters', JSON.stringify(this.state.filters))
    formData.append('sorting', JSON.stringify(this.state.sorting))
    formData.append('page', 'edit')

    await this.props.updateReportFilter(formData)
    await this.setState({ savingDraft: false, save_as_draft_view: false })
    if (!this.props.user.displayMessageCode) {
      this.fetchEditReport()
    }
  }

  saveAndPublishClientReport = async () => {
    await this.setState({ publishingDraft: true })
    let formData = new FormData()
    formData.append('report_id', this.state.report_id)
    formData.append('clients', JSON.stringify(this.state.user_clients_selected))
    await this.props.saveAndPublishClientReport(formData)
    await this.setState({ publishingDraft: false })
  }

  saveAndPublish = async () => {
    await this.updateReportFilter()
    if (this.props.user.displayMessageCode) {
      return
    }
    await this.saveAndPublishClientReport()
    this.setState({
      save_and_publish_view: !this.state.save_and_publish_view,
    })
    this.jump_back()
  }

  saveEditColName = async (e) => {
    e.preventDefault()
    let { report_cols, report_original_cols, col_edit_name, col_edit_name_save } = this.state

    report_cols.forEach((items) => {
      if (items.title === col_edit_name_save) {
        items.title = col_edit_name
      }
    })

    report_original_cols.forEach((items) => {
      if (items.title === col_edit_name_save) {
        items.title = col_edit_name
      }
    })

    await this.setState({
      report_cols: report_cols,
      report_original_cols: report_original_cols,
      col_edit_name: '',
      col_edit_name_save: '',
      col_edit_view: !this.state.col_edit_view,
    })

    // this.updateReportFilter()
  }

  swapArrayLocs = (arr, index1, index2) => {
    var temp = arr[index1]
    arr[index1] = arr[index2]
    arr[index2] = temp
    return arr
  }

  changeOrder = async (index, type) => {
    let { report_original_cols, report_cols } = this.state
    let arr = report_original_cols
    if (type === 'up' && index > 0) {
      arr = this.swapArrayLocs(arr, index, index - 1)
      let sequence = arr[index].sequence
      arr[index].sequence = arr[index - 1].sequence
      arr[index - 1].sequence = sequence
    } else if (type === 'down' && index < arr.length - 1) {
      arr = this.swapArrayLocs(arr, index, index + 1)
      let sequence = arr[index].sequence
      arr[index].sequence = arr[index + 1].sequence
      arr[index + 1].sequence = sequence
    }
    report_cols = arr.filter((col) => col.checked)
    await this.setState({
      report_original_cols: arr,
      report_cols: report_cols,
    })
  }

  setColumnWidths = (columnWidths) => {
    this.setState({ columnWidths: columnWidths })
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderColumnsOrdering = () => {
    let { show_columns_ordering, report_original_cols } = this.state

    return (
      <div className="form-group bg-light border p-2" id="unique-10993817div_3">
        <div className="clearfix" id="unique-97061271div_4">
          <button
            className="btn float-right p-0"
            id="unique-63865152button_1"
            onClick={() =>
              this.setState({
                show_columns_ordering: !show_columns_ordering,
              })
            }
          >
            {!show_columns_ordering ? <i className="fa fa-angle-down" id="unique-11412662i_1" /> : <i className="fa fa-angle-up" id="unique-76781795i_2" />}
          </button>
          <h5 className="mb-0" id="unique-17195530h5_1">
            Columns Ordering
          </h5>
        </div>
        {show_columns_ordering ? (
          <div className="mt-2" id="unique-69048644div_5">
            {report_original_cols.map((col, index) => {
              if (!col.checked) {
                return null
              }
              return (
                <div className="mb-2 clearfix" key={index} id="unique-54079169div_6">
                  <button id="unique-87281309button_2" type="button" className="btn btn-sm btn-primary float-right" onClick={() => this.changeOrder(index, 'up')}>
                    <i className="fa fa-angle-up" id="unique-21851133i_3"></i>
                  </button>

                  <button type="button" id="unique-40785279button_3" className="btn btn-sm btn-primary float-right mr-2" onClick={() => this.changeOrder(index, 'down')}>
                    <i className="fa fa-angle-down" id="unique-47269960i_4"></i>
                  </button>

                  <span className="text-truncate d-inline-block" style={{ width: 120 }} title={col.title} id="unique-10416533span_1">
                    {col.title}
                  </span>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }

  renderColumnsGrouping = () => {
    let { report_col_original_group, report_original_cols, report_group_is_show, show_columns_grouping } = this.state

    if (report_col_original_group.length) {
      return (
        <div className="form-group bg-light border p-2" id="unique-64136486div_7">
          <div className="clearfix" id="unique-78800602div_8">
            <button
              className="btn float-right p-0"
              onClick={() =>
                this.setState({
                  show_columns_grouping: !show_columns_grouping,
                })
              }
            >
              {!show_columns_grouping ? <i className="fa fa-angle-down" id="unique-96021090i_5" /> : <i className="fa fa-angle-up" id="unique-40362291i_6" />}
            </button>
            <h5 className="mb-0" id="unique-89806123h5_2">
              Columns
            </h5>
          </div>
          {show_columns_grouping ? (
            <div className="mt-2" id="unique-34248178div_9">
              {report_col_original_group.map((items, index) => {
                return (
                  <div key={index} className="mb-2" id="unique-87126981div_10">
                    <label className="ml-3 cursor-pointer" onClick={() => this.handeleGroupShow(index)}>
                      {report_group_is_show[index] ? <i className="fa fa-angle-down" id="unique-10545654i_7"></i> : <i className="fa fa-angle-right" id="unique-63239496i_8"></i>}{' '}
                      &nbsp; {items.title}
                    </label>
                    {report_group_is_show[index] ? (
                      <div id="unique-90878699div_11">
                        {report_original_cols.map((word, index) => {
                          if (word.Column_group_id === items.Column_group_id) {
                            return (
                              <div key={index} className="form-check" id="unique-91967374div_12">
                                <span className="text-truncate d-inline-block" style={{ width: 30 }} id="unique-83878682span_2">
                                  <label
                                    style={{
                                      fontSize: 13,
                                    }}
                                    className={`${word.checked ? 'checked' : null} checkbox mr-2`}
                                    onClick={() => this.handleChange(index)}
                                  />
                                </span>

                                <span
                                  className="text-truncate d-inline-block"
                                  style={{
                                    width: 140,
                                    fontSize: 13,
                                  }}
                                  title={word.title}
                                  id="unique-16329279span_3"
                                >
                                  {word.title}
                                </span>

                                <span className="text-truncate d-inline-block" style={{ width: 30 }} id="unique-66858400span_4">
                                  <button
                                    type="button"
                                    className="btn btn-sm ml-2 p-0"
                                    onClick={() =>
                                      this.setState({
                                        col_edit_name: word.title,
                                        col_edit_name_save: word.title,
                                        col_edit_view: !this.state.col_edit_view,
                                      })
                                    }
                                  >
                                    <i className="fa fa-edit" id="unique-42756648i_9" />
                                  </button>
                                </span>
                              </div>
                            )
                          }
                          return null
                        })}
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      )
    }

    return null
  }

  // Modal Views
  renderSaveAsDraftView = () => {
    return (
      <div className="modal" id="unique-20097823div_21">
        <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-421097823div_21">
          <div className="modal-content" id="unique-65557823div_21">
            <div className="modal-header">
              <h5 className="modal-title">Save & Apply Filters</h5>
              <button
                id="iworkflow-new_edit_report_save_and_apply_filters_confirmation_close_btn"
                type="button"
                className="close"
                onClick={() => this.setState({ save_as_draft_view: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" id="unique-10504317div_17">
              Are you sure to save all settings include Name, Filters, Columns and Sorting you have made right now?
            </div>

            <div className="modal-footer">
              <button
                id="iworkflow-new_edit_report_save_and_apply_filters_confirmation_cancel_btn"
                className="btn btn-default"
                onClick={() => this.setState({ save_as_draft_view: false })}
              >
                Cancel
              </button>
              <button id="iworkflow-new_edit_report_save_and_apply_filters_confirmation_btn" className="btn btn-primary" onClick={this.updateReportFilter}>
                Save & Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderColEditView = () => {
    return (
      <form className="modal" onSubmit={this.saveEditColName} id="unique-35292796form_1">
        <div className="modal-dialog modal-dialog-centered" id="unique-29291393div_19">
          <div className="modal-content" id="unique-62606448div_20">
            <div className="modal-header" id="unique-10097823div_21">
              <h5 className="modal-title" id="unique-10652505h5_4">
                Edit Column Name
              </h5>
            </div>
            <div className="modal-body" id="unique-10032823div_21">
              <Input type="text" placeholder="Column Name" value={this.state.col_edit_name} name="col_edit_name" onChange={this.onChange} required={true} />
            </div>
            <div className="modal-footer" id="unique-95089162div_23">
              <button
                type="button"
                className="btn btn-default"
                onClick={() =>
                  this.setState({
                    col_edit_view: false,
                    col_edit_name: '',
                    col_edit_name_save: '',
                  })
                }
              >
                Cancel
              </button>
              <button className="btn btn-primary" id="unique-47064808button_10">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderSaveAndPublishView = () => {
    return (
      <div className="modal" id="unique-10433183div_24">
        <div className="modal-dialog modal-dialog-centered" id="unique-17682297div_25">
          <div className="modal-content" id="unique-10920895div_26">
            <div className="modal-header" id="unique-10469288div_27">
              <h5 className="modal-title" id="unique-10532200h5_5">
                Save & Publish
              </h5>
            </div>
            <div className="modal-body" id="unique-10350855div_103" style={{ height: '26rem', overflowY: 'auto' }}>
              <Input type="text" label="Report Name" className="mb-3" value={this.state.report_name} name="report_name" onChange={this.onChange} />

              <label id="unique-46211271label_4">Client</label>
              <Select
                options={this.state.user_clients}
                value={this.state.user_clients_selected}
                onChange={(selectedClients) => {
                  this.setState({ user_clients_selected: selectedClients })
                }}
                isMulti={true}
                isDisabled={this.state.report_client.length}
              />
            </div>
            <div className="modal-footer" id="unique-53275794div_29">
              <button
                id="iworkflow-new_edit_report_save_and_publish_form_cancel_btn"
                className="btn btn-default"
                onClick={() =>
                  this.setState({
                    save_and_publish_view: false,
                  })
                }
              >
                Cancel
              </button>
              <button id="iworkflow-new_edit_report_save_and_publish_form_submit_btn" className="btn btn-primary" onClick={this.saveAndPublish}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAddFilterView = () => {
    return (
      <form id="iworkflow-new_edit_report_edit_add_default_filter_form" className="modal" onSubmit={this.addFilter}>
        <div className="modal-dialog modal-dialog-centered" id="unique-10350855divv_33">
          <div className="modal-content" id="unique-21350855div_33">
            <div className="modal-header" id="unique-103533333div_34">
              <h5 className="modal-title">{this.state.edit_filter_mode ? 'Edit' : 'Add'} Default Filter</h5>
              <button
                id="iworkflow-new_edit_report_edit_add_default_filter_close_btn"
                type="button"
                className="close"
                onClick={() => this.setState({ add_filter_view: false })}
                style={{ fontSize: 32 }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id="unique-10350855div_33">
              <select
                className="form-control mb-3"
                value={this.state.filter_col_selected}
                onChange={(e) =>
                  this.setState({
                    filter_col_selected: e.target.value,
                  })
                }
                disabled={this.state.edit_filter_mode}
                required
              >
                <option value={''}>Select Column</option>
                {this.state.report_cols.map((_item) => {
                  if (this.state.columnExtensions.findIndex((extension) => extension.columnName === _item.name) > -1) {
                    return null
                  }

                  const index = this.state.filters.findIndex((filter) => filter.columnName === _item.name)
                  if (index === -1 || this.state.filter_col_selected === _item.name) {
                    return (
                      <option key={_item.name} value={_item.name}>
                        {_item.title}
                      </option>
                    )
                  }
                  return null
                })}
              </select>

              <select
                className="form-control mb-3"
                value={this.state.filter_operator_selected}
                onChange={(e) =>
                  this.setState({
                    filter_operator_selected: e.target.value,
                  })
                }
                required
              >
                <option value={''}>Select Operater</option>
                {this.state.operator_list.map((_item) => {
                  return (
                    <option key={_item} value={_item}>
                      {_item}
                    </option>
                  )
                })}
              </select>

              <Input type="text" placeholder="Filter Value" className="mb-3" value={this.state.filter_value} name="filter_value" onChange={this.onChange} required={true} />
            </div>
            <div className="modal-footer">
              <button
                id="iworkflow-new_edit_report_edit_add_default_filter_cancel_btn"
                type="button"
                className="btn btn-default"
                onClick={() => this.setState({ add_filter_view: false })}
              >
                Cancel
              </button>
              <button id="iworkflow-new_edit_report_edit_add_default_filter_submit_btn" type="submit" className="btn btn-primary">
                {this.state.edit_filter_mode ? 'Edit' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderSortView = () => {
    return (
      <form id="iworkflow-new_edit_report_sort_settings_form" className="modal" onSubmit={this.addSort}>
        <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-49528281div_36">
          <div className="modal-content" id="unique-49522280div_36">
            <div className="modal-header" id="unique-495448280div_36">
              <h5 className="modal-title" id="unique-49338280h5_36">
                Sort Settings
              </h5>
              <button
                id="iworkflow-new_edit_report_sort_settings_close_btn"
                type="button"
                className="close"
                onClick={() => this.setState({ sort_view: false })}
                style={{ fontSize: 32 }}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body" id="unique-39516263div_38">
              <select
                value={this.state.sort_col_selected}
                className="form-control mb-3"
                onChange={(e) =>
                  this.setState({
                    sort_col_selected: e.target.value,
                  })
                }
                disabled={this.state.edit_sort_mode}
                required
              >
                <option value={''}>Select Column</option>
                {this.state.report_cols.map((_item, _index) => {
                  const index = this.state.sorting.findIndex((item) => item.columnName === _item.name)
                  if (index === -1 || this.state.sort_col_selected === _item.name) {
                    return (
                      <option value={_item.name} key={_index}>
                        {_item.title}
                      </option>
                    )
                  }
                  return []
                })}
              </select>

              <select
                value={this.state.sort_order_selected}
                className="form-control mb-3"
                onChange={(e) =>
                  this.setState({
                    sort_order_selected: e.target.value,
                  })
                }
                required
              >
                <option value={''}>Select Order</option>
                <option value={'asc'}>Ascending</option>
                <option value={'desc'}>Descending</option>
              </select>
            </div>
            <div className="modal-footer">
              <button id="iworkflow-new_edit_report_sort_settings_cancel_btn" type="button" className="btn btn-default" onClick={() => this.setState({ sort_view: false })}>
                Cancel
              </button>
              <button id="iworkflow-new_edit_report_sort_settings_submit_btn" type="submit" className="btn btn-primary">
                {this.state.edit_sort_mode ? 'Edit' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    let {
      product,
      totalCount,
      report_name,
      is_published,
      loading,
      savingDraft,
      publishingDraft,
      report_cols,
      report_rows,
      sorting,
      currentPage,
      pageSize,
      pageSizes,
      filters,
      columnWidths,

      add_filter_view,
      col_edit_view,
      save_as_draft_view,
      save_and_publish_view,
      sort_view,
    } = this.state

    let view_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_view-report_hidden`) ? true : false
    let save_and_apply_changes_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_save-apply-changes_hidden`) ? true : false
    let apply_changes_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_apply-changes_hidden`) ? true : false
    let save_and_publish_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_save-publish_hidden`) ? true : false

    return (
      <div className="dashboard main" id="unique-30772036div_40">
        <ol className="breadcrumb" id="unique-25375872ol_1">
          <li className="breadcrumb-item" id="unique-34906618li_1">
            <Link to="/dashboard" id="unique-46791614link_1">
              Product Dashboard
            </Link>
          </li>
          <li className="breadcrumb-item" id="unique-52224356li_2">
            <Link to="/iworkflow-new" id="unique-80493372link_2">
              iWorkflow New
            </Link>
          </li>
          <li className="breadcrumb-item active" id="unique-19811295li_3">
            {this.state.report_name}
          </li>
        </ol>

        <div className="mb-2 card" id="unique-99544747div_41">
          <div className="card-body p-2" id="unique-99544747div_541">
            <button id="iworkflow-new_edit_report_close_btn" type="button" className="btn btn-default float-right" onClick={() => this.jump_back()}>
              Close
            </button>

            {!view_report_hidden ? (
              <button
                id="iworkflow-new_edit_report_view_report_btn"
                className="btn btn-primary float-right mr-2"
                onClick={() =>
                  this.props.history.push({
                    pathname: '/iworkflow-new/report-detail',
                    search: 'reportId=' + this.state.report_id,
                  })
                }
              >
                View
              </button>
            ) : null}

            {!save_and_apply_changes_hidden ? (
              <button
                id="iworkflow-new_edit_report_save_and_apply_changes_btn"
                disabled={savingDraft || loading}
                className="btn btn-primary mr-2 float-right"
                onClick={() =>
                  this.setState({
                    save_as_draft_view: !save_as_draft_view,
                  })
                }
              >
                Save & Apply Changes
              </button>
            ) : null}

            {!apply_changes_hidden ? (
              <button disabled={savingDraft || loading} className="btn btn-primary mr-2 float-right" onClick={this.fetchEditReport} id="unique-10358438button_22">
                Apply Changes
              </button>
            ) : null}

            {!save_and_publish_hidden ? (
              <button
                id="iworkflow-new_edit_report_save_and_publish_btn"
                disabled={publishingDraft}
                className="btn btn-primary mr-2 float-right"
                onClick={() =>
                  this.setState({
                    save_and_publish_view: !save_and_publish_view,
                  })
                }
              >
                Save & Publish
              </button>
            ) : null}

            <h3 className="mb-0" id="unique-11355661h3_1">
              {report_name}
              {is_published ? (
                <span className="badge badge-success d-inline-block ml-2" id="unique-38691968span_8">
                  Published
                </span>
              ) : (
                <span className="badge badge-dark d-inline-block ml-2" id="unique-10745677span_9">
                  Draft
                </span>
              )}
            </h3>
          </div>
        </div>

        <div className="row no-gutters" id="unique-11567700div_43">
          <div className="col-10" id="unique-35906139div_44">
            <div style={{ position: 'relative' }} id="unique-12641048div_45">
              <div style={{ position: 'absolute', width: '100%' }} id="unique-10473444div_46">
                <div className="card report-edit-grid" id="unique-10759379div_47">
                  <Grid columns={report_cols} rows={report_rows} getRowId={this.getRowId}>
                    <PagingState currentPage={currentPage} onCurrentPageChange={this.setCurrentPage} pageSize={pageSize} onPageSizeChange={this.setPageSize} />
                    <CustomPaging totalCount={totalCount} />
                    <PagingPanel pageSizes={pageSizes} />
                    <SearchState onValueChange={this.setSearchState} />
                    <VirtualTable />
                    <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.setColumnWidths} />
                    <TableHeaderRow />
                    <Toolbar />
                    <SearchPanel />
                  </Grid>
                  {this.state.loading ? (
                    <div className="card-loader" id="unique-47770028div_48">
                      <div id="loading" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col" id="unique-78040586div_49">
            <div className="card" style={{ height: '53rem', overflow: 'auto' }} id="unique-62306097div_50">
              <div className="card-body p-3" id="unique-10817747div_51">
                <div className="form-group" id="unique-53940591div_52">
                  <label id="unique-64151384label_5">Report Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={report_name}
                    onChange={(e) =>
                      this.setState({
                        report_name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group" id="unique-11506040div_53">
                  Rows Count - {this.state.totalCount}
                </div>

                {this.renderColumnsGrouping()}
                {this.renderColumnsOrdering()}

                <div className="form-group" id="unique-96488800div_54">
                  <div className="mb-2" id="unique-31322053div_55">
                    <button
                      id="iworkflow-new_edit_report_add_default_filters_btn"
                      type="button"
                      className="btn btn-secondary w-100"
                      onClick={() =>
                        this.setState({
                          add_filter_view: !add_filter_view,
                          edit_filter_mode: false,
                          filter_col_selected: '',
                          filter_operator_selected: '',
                          filter_value: '',
                        })
                      }
                    >
                      Add Default Filters &nbsp; <i className="fa fa-plus" id="unique-13447881i_10"></i>
                    </button>
                  </div>

                  {filters.map((filter, index) => {
                    const _index = report_cols.findIndex((col) => col.name === filter.columnName)
                    return (
                      <span className="bg-light border m-1 p-2 d-inline-block" key={index} style={{ fontSize: 12 }} id="unique-77504958span_10">
                        <span
                          className="mr-2 cursor-pointer"
                          onClick={() =>
                            this.setState({
                              add_filter_view: true,
                              edit_filter_mode: true,
                              filter_col_selected: filter.columnName,
                              filter_operator_selected: filter.operation,
                              filter_value: filter.value,
                            })
                          }
                        >
                          {report_cols[_index].title}
                        </span>

                        <i className="fa fa-trash-alt cursor-pointer" onClick={() => this.deleteFilter(index)} />
                      </span>
                    )
                  })}
                </div>

                <div id="unique-11328637div_56">
                  <div className="mb-2" id="unique-59336440div_57">
                    <button
                      id="iworkflow-new_edit_report_add_sort_settings_btn"
                      type="button"
                      className="btn btn-secondary w-100"
                      onClick={() =>
                        this.setState({
                          sort_col_selected: '',
                          sort_order_selected: '',
                          sort_view: true,
                        })
                      }
                    >
                      Add Sort Settings &nbsp; <i className="fa fa-plus" id="unique-11765350i_12" />
                    </button>
                  </div>

                  {sorting.map((item, index) => {
                    const _index = report_cols.findIndex((col) => col.name === item.columnName)
                    if (index > -1) {
                      return (
                        <span className="bg-light m-1 p-2 border d-inline-block" key={index} style={{ fontSize: 12 }} id="unique-11147835span_12">
                          <span
                            className="mr-2 cursor-pointer"
                            onClick={() =>
                              this.setState({
                                sort_view: true,
                                edit_sort_mode: true,
                                sort_col_selected: item.columnName,
                                sort_order_selected: item.direction,
                              })
                            }
                          >
                            {report_cols[_index].title} &nbsp;
                            {item.direction === 'asc' ? '[Ascending]' : '[Descending]'}
                          </span>

                          <i className="fa fa-trash-alt cursor-pointer" onClick={() => this.deleteSort(index)} />
                        </span>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {sort_view ? this.renderSortView() : null}
        {add_filter_view ? this.renderAddFilterView() : null}
        {save_and_publish_view ? this.renderSaveAndPublishView() : null}
        {col_edit_view ? this.renderColEditView() : null}
        {save_as_draft_view ? this.renderSaveAsDraftView() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchEditReport,
  updateReportFilter,
  saveAndPublishClientReport,
  refreshUserAuth,
})(ReportEdit)
