import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import MasterList from './MasterList.js'
import ClientList from './ClientList.js'
import NotAllowed from '../WebComponents/NotAllowed.js'
import ReportDetail from './ReportDetail.js'
import ReportEdit from './ReportEdit.js'

import {
  fetchReport,
  fetchReportList,
  fetchMasterList,
  createClientReport,
  downloadClientReport,
  deleteClientReport,
  uploadClientReport,
  downloadReportUploadStatus,
} from '../../store/actions.js'

class ReportList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product: 'iworkflow-new',
      loading: false,
      client: '',
      errorMessageNotAllowed: false,
      is_ipsadmin: false,
      is_clientreport: false,
    }
  }

  componentDidMount = async () => {
    if (this.props.user.role === 'SysAdmin-Admin') {
      await this.setState({
        is_ipsadmin: true,
      })
    } else if (this.props.user.role === 'Client Admin') {
      await this.setState({ is_clientreport: true })
    }
  }

  setLoading = async (loading) => {
    await this.setState({ loading: loading })
  }

  render() {
    if (this.state.errorMessageNotAllowed) {
      return <NotAllowed />
    }

    if (this.props.page === 'home') {
      return (
        <div className="dashboard main" id="unique-10202094div_1">
          <ol className="breadcrumb" id="unique-82872477ol_1">
            <li className="breadcrumb-item" id="unique-87729327li_1">
              <Link to="/dashboard" id="unique-14412376link_1">
                Product Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active" id="unique-39771956li_2">
              iWorkflow New
            </li>
          </ol>

          <div className="card" id="unique-58051226div_2">
            <div className="card-body p-2" id="unique-69463849div_3">
              {this.state.is_ipsadmin ? (
                <div className="d-inline-block" id="unique-35799192div_4">
                  <div className="btn-group" id="unique-68347276div_5">
                    <button
                      type="button"
                      id="unique-32600155button_1"
                      className={`btn btn-primary ${!this.state.is_clientreport ? 'focus' : ''}`}
                      onClick={() => this.setState({ is_clientreport: false })}
                      disabled={this.state.loading}
                    >
                      Master Report
                    </button>
                    <button
                      type="button"
                      id="unique-96486360button_2"
                      className={`btn btn-primary ${this.state.is_clientreport ? 'focus' : ''}`}
                      onClick={() => this.setState({ is_clientreport: true })}
                      disabled={this.state.loading}
                    >
                      Client Report
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {this.state.is_clientreport ? (
            <ClientList product={this.state.product} user={this.props.user} setLoading={this.setLoading} loading={this.state.loading} {...this.props} />
          ) : (
            <MasterList product={this.state.product} user={this.props.user} setLoading={this.setLoading} loading={this.state.loading} {...this.props} />
          )}
        </div>
      )
    } else if (this.props.page === 'report-detail') {
      return <ReportDetail {...this.props} />
    } else if (this.props.page === 'report-edit') {
      return <ReportEdit {...this.props} />
    }
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchReport,
  fetchReportList,
  fetchMasterList,
  createClientReport,
  downloadClientReport,
  deleteClientReport,
  uploadClientReport,
  downloadReportUploadStatus,
})(ReportList)
