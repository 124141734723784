import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ProjectCard from '../WebComponents/ProjectCard.js'
import ProjectSetup from './../Forms/ProjectSetup/ProjectSetup'
import CloneProject from './../Forms/CloneProjectByClient'
import ProjectDashboard from './ProjectDashboard.js'
import Delete from '../WebComponents/DeletePopup'

import { fetchProjects, fetchProjectsData, deleteProject } from './../../store/actions'

class PidDashboard extends React.Component {
  state = {
    product: '',
    page: '',
    projects: [],
    projectsData: [],
    loading: false,
    deletingProject: false,
    cloneProjectId: null,
    deleteProjectObject: null,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const product = query.get('product')
    this.setState(
      {
        product: product,
        page: this.props.page,
      },
      this.fetchProjectsHandler
    )
  }

  componentDidUpdate(props) {
    if (props.page !== this.props.page) {
      if (this.props.page === 'home') {
        this.fetchProjectsHandler()
      }
      this.setState({ page: this.props.page }, this.renderPage)
    }
  }

  fetchProjectsHandler = async () => {
    await this.setState({ loading: true })
    await this.props.fetchProjectsData()
    await this.setState({
      loading: false,
      projectsData: this.props.user.projectsData,
    })
  }

  renderProjects() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-19337128div_15">
          <div id="loading" />
        </div>
      )
    }

    let edit_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_edit-project_hidden`)) {
      edit_project_hidden = true
    }

    let delete_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_delete-project_hidden`)) {
      delete_project_hidden = true
    }

    let clone_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_clone-project_hidden`)) {
      clone_project_hidden = true
    }

    return (
      <ProjectCard
        projectsData={this.state.projectsData}
        product={this.state.product}
        delete_project_hidden={delete_project_hidden}
        edit_project_hidden={edit_project_hidden}
        clone_project_hidden={clone_project_hidden}
        cloneProjectToggler={this.cloneProjectToggler}
        deleteProjectToggler={this.deleteProjectToggler}
      />
    )
  }

  closeProjectCreation = () => {
    this.setState(
      {
        page: 'home',
        cloneProjectId: null,
      },
      this.fetchProjectsHandler()
    )
  }

  deleteProjectHandler = async (projectId) => {
    await this.setState({ deletingProject: true })
    let formData = new FormData()
    formData.append('projectId', projectId)
    await this.props.deleteProject(formData)
    await this.setState({ deleteProjectObject: null, deletingProject: false })
    this.fetchProjectsHandler()
  }

  deleteProjectToggler = (project) => {
    this.setState({
      deleteProjectObject: project,
    })
  }

  cloneProjectToggler = (project) => {
    this.setState(
      {
        cloneProjectId: project.id,
      },
      () => {
        this.setState({ page: 'clone-project' })
      }
    )
  }

  renderPage() {
    if (this.state.page === 'home') {
      let product_name = this.state.product
      if (product_name.includes('-Update')) {
        product_name = product_name.replace('-Update', '') + '<sup>Update</sup>'
      }

      let create_project_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_create-project_hidden`)) {
        create_project_hidden = true
      }

      return (
        <div className="dashboard main" id="unique-17807875div_2">
          {this.state.deleteProjectObject ? (
            <Delete
              toggle={this.deleteProjectToggler}
              deleteProject={this.deleteProjectHandler}
              project={this.state.deleteProjectObject}
              deletingProject={this.state.deletingProject}
            />
          ) : null}

          <nav aria-label="breadcrumb" id="unique-57734342nav_1">
            <ol className="breadcrumb" id="unique-97314854ol_1">
              <li className="breadcrumb-item" id="unique-10846081li_1">
                <Link to="/dashboard" id="unique-71052158link_1">
                  Product Dashboard
                </Link>
              </li>
              <li className="breadcrumb-item active" id="unique-30375371li_2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: product_name,
                  }}
                  id="unique-54049360span_1"
                />{' '}
                Dashboard
              </li>
            </ol>
          </nav>

          <div className="clearfix mb-4" id="unique-11178092div_3">
            {!create_project_hidden ? (
              <Link id="pid_create_project_link" to={`/pid/project-setup?product=${this.state.product}&dashboard=pid`} className={`btn btn-secondary float-right`}>
                Create Project
              </Link>
            ) : null}

            <h3 className="font-weight-bold" id="unique-11281546h3_1">
              <span
                dangerouslySetInnerHTML={{
                  __html: product_name,
                }}
                id="unique-92380249span_2"
              />{' '}
              Dashboard
            </h3>
          </div>
          <div className="row" id="unique-20225756div_4">
            {this.renderProjects()}
          </div>
        </div>
      )
    } else if (this.state.page === 'project') {
      return <ProjectDashboard {...this.props} page={this.state.page} />
    } else if (this.state.page === 'clone-project') {
      return <CloneProject {...this.props} product={this.state.product} projectId={this.state.cloneProjectId} closeProjectCreation={this.closeProjectCreation} />
    } else if (this.state.page === 'project-setup') {
      return <ProjectSetup {...this.props} />
    }
    return null
  }

  render() {
    return this.renderPage()
  }
}

export default connect(null, {
  fetchProjects,
  fetchProjectsData,
  deleteProject,
})(PidDashboard)
