import React from 'react'

class Input extends React.Component {
  state = {
    // allowedSymbols: ['!', '_', '-', ',', '/'],
    disallowedSymbols: [],
    errorMessage: null,
    isEmptyAndRequired: false,
  }

  componentDidMount = () => {
    // if ('allowedSymbols' in this.props) {
    //   this.setState({ allowedSymbols: this.props.allowedSymbols })
    // }

    if ('disallowedSymbols' in this.props) {
      this.setState({ disallowedSymbols: this.props.disallowedSymbols })
    }
  }

  onChange = (e) => {
    let { disallowedSymbols } = this.state

    let clean_value = ''
    for (const index in e.target.value) {
      if (e.target.type === 'number') {
        if (e.target.value[index].match(/^[0-9]+$/)) {
          clean_value += e.target.value[index]
        } else {
          if (!disallowedSymbols.includes(e.target.value[index])) {
            clean_value += e.target.value[index]
          } else {
            this.setState({
              errorMessage: `${e.target.value[index]} symbol is not allowed!`,
            })
            setTimeout(() => this.setState({ errorMessage: null }), 5000)
          }
        }
      } else {
        if (e.target.value[index].match(/^[a-zA-Z0-9\s_]+$/) || e.target.value[index].match(/^\s*$/)) {
          clean_value += e.target.value[index]
        } else {
          if (!disallowedSymbols.includes(e.target.value[index])) {
            clean_value += e.target.value[index]
          } else {
            this.setState({
              errorMessage: `${e.target.value[index]} symbol is not allowed!`,
            })
            setTimeout(() => this.setState({ errorMessage: null }), 5000)
          }
        }
      }
    }

    if (this.props.path) {
      let obj = {
        target: {
          name: '',
          value: '',
        },
      }
      obj.target.value = clean_value
      obj.target.name = e.target.name
      this.props.onChange(obj, this.props.path, this.props.path2)
    } else {
      let obj = {}
      obj[e.target.name] = clean_value
      this.props.onChange(obj)
    }
  }

  onBlur = (e) => {
    let trimmed_value = e.target.value.trim() // Remove leading and trailing spaces
    if (trimmed_value !== e.target.value) {
      e.target.value = trimmed_value // Update input value without leading and trailing spaces
      this.onChange(e) // Call onChange to propagate changes
    }
    if (this.props.required && trimmed_value === '') {
      this.setState({ isEmptyAndRequired: true })
    } else {
      this.setState({ isEmptyAndRequired: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <div className="alert alert-danger" id="unique-82891665div_1">
            {this.state.errorMessage}{' '}
            <button className="close" onClick={() => this.setState({ errorMessage: null })}>
              &times;
            </button>
          </div>
        ) : null}
        {this.props.label ? (
          <label id="unique-66114919label_1">
            <span className={`${this.state.isEmptyAndRequired ? 'text-danger' : ''}`}>{this.props.label}</span>{' '}
            {this.props.required ? <span className={`small ${this.state.isEmptyAndRequired ? 'text-danger' : 'text-muted'}`}>(*)</span> : null}
            {this.props.info ? <i className="fas fa-info-circle d-inline-block ml-3 cursor-pointer" title={`If no owner, please put "Internal"`} /> : null}
          </label>
        ) : null}
        <input
          id={this.props.id ? this.props.id : this.props.name}
          type={this.props.type}
          name={this.props.name}
          max={this.props.maxValue ? this.props.maxValue : ''}
          step={this.props.step ? this.props.step : ''}
          list={this.props.list ? this.props.list : ''}
          className={`form-control ${this.state.isEmptyAndRequired ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`}
          disabled={this.props.disabled}
          value={this.props.value}
          onChange={this.onChange}
          onBlur={this.onBlur} // Add onBlur event handler
          required={this.props.required}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          readOnly={this.props.readOnly}
        />
      </React.Fragment>
    )
  }
}

export default Input
