import React, { Component } from 'react'

import LinkedProjectInfoBox from './LinkProjectInfoBox'
import Select from '../Fields/select'

export default class ProjectSetupS12 extends Component {
  state = {
    loading: false,
    formLoader: false,
    parent_attr: '',
    attr: '',
    attributes: {},
    data: [],
    mapping_index_collapsed: null,
    screen_blocked: false,
  }

  componentDidMount = () => {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectAttributeMappings(formData)
    await this.setState({
      attributes: this.props.user.projectInfo.attributes,
      data: this.props.user.projectInfo.data,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
    await this.setState({ loading: false })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('data', JSON.stringify(this.state.data))
    formData.append('step', 12)
    await this.props.updateProjectAttributeMappings(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  addMapping = (e) => {
    e.preventDefault()
    let { parent_attr, attr, data, attributes } = this.state

    data.push({
      parent_attr: attributes[parent_attr],
      attr: attributes[attr],
      values: [],
    })

    this.setState({
      data: data,
      parent_attr: '',
      attr: '',
    })
  }

  deleteMapping = (index) => {
    let { data } = this.state
    data.splice(index, 1)
    this.setState({ data: data })
  }

  addSubMapping = (index) => {
    let { data } = this.state
    let mapping = data[index]
    let subMapping = {}
    subMapping[mapping.parent_attr.Name] = ''
    subMapping[mapping.attr.Name] = ''
    data[index].values.push(subMapping)
    this.setState({ data: data })
  }

  onChangeSubMapping = (e, index, _index, key = null, selectedList = []) => {
    let { data } = this.state

    if (e) {
      data[index].values[_index][e.target.name] = e.target.value
    } else {
      data[index].values[_index][key] = selectedList
    }

    this.setState({ data: data })
  }

  deleteSubMapping = (index, _index) => {
    let { data } = this.state
    data[index].values.splice(_index, 1)
    this.setState({ data: data })
  }

  renderForm() {
    return (
      <div className="card" id="unique-28733167div_1">
        <div className="clearfix card-header bg-light" id="unique-24197984div_2">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-10372374button_4">
            Cancel
          </button>

          <h3 id="unique-10315545h3_1">Custom Attribute Mapping {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="62240315" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-10394884div_3">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          <form className="mb-3 card bg-light w-50" onSubmit={this.addMapping} id="unique-22963619form_1">
            <div className="card-body p-2" id="unique-65436199div_4">
              <div className="row" id="unique-34239169div_5">
                <div className="col-5" id="unique-59671568div_6">
                  <select
                    name="parent_attr"
                    value={this.state.parent_attr}
                    className="form-control"
                    onChange={(e) =>
                      this.setState({
                        parent_attr: e.target.value,
                      })
                    }
                    required
                  >
                    <option value={''}>--Select Parent Attribute--</option>
                    {Object.keys(this.state.attributes).map((key) => {
                      return (
                        <option key={key} val={key}>
                          {key}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-5" id="unique-96029581div_7">
                  <select
                    name="attr"
                    value={this.state.attr}
                    className="form-control"
                    onChange={(e) =>
                      this.setState({
                        attr: e.target.value,
                      })
                    }
                    required
                  >
                    <option value={''}>--Select Atrribute--</option>
                    {Object.keys(this.state.attributes).map((key) => {
                      return (
                        <option key={key} val={key}>
                          {key}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col" id="unique-83448212div_8">
                  <button className="btn btn-primary" id="unique-42752185button_5">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="templates" id="unique-59267412div_9">
            {this.state.data.map((mapping, index) => {
              return (
                <div className="template mb-1" key={index} style={{ overflow: 'visible' }} id="unique-32906785div_10">
                  <div className="row" id="unique-11069943div_11">
                    <div className="col-md-2" id="unique-49739220div_12">
                      <span className="badge badge-light" id="unique-14059689span_1">
                        {mapping.parent_attr.Name} : {mapping.attr.Name}
                      </span>
                    </div>
                    <div className="clearfix col-md-10" id="unique-38338465div_13">
                      <button className="float-right btn" onClick={() => this.deleteMapping(index)}>
                        <i className="fa fa-trash-alt text-secondary" style={{ fontSize: 22 }} id="62240315" />
                      </button>
                      <button
                        className="float-right btn btn-primary mr-2"
                        onClick={() => {
                          if (this.state.mapping_index_collapsed === index) {
                            this.setState({
                              mapping_index_collapsed: '',
                            })
                          } else {
                            this.setState({
                              mapping_index_collapsed: index,
                            })
                          }
                        }}
                      >
                        {this.state.mapping_index_collapsed === index ? <i className="fa fa-angle-up" id="62240315" /> : <i className="fa fa-angle-down" id="62240315" />}
                      </button>
                      <button className="float-right btn btn-primary mr-2" onClick={() => this.addSubMapping(index)}>
                        Add
                      </button>
                    </div>
                  </div>
                  {this.state.mapping_index_collapsed === index ? (
                    <table className="table table-striped mt-4">
                      <thead className="thead-light">
                        <tr id="unique-43758166tr_1">
                          <th id="unique-34489274th_1">{mapping.parent_attr.Name}</th>
                          <th id="unique-13617502th_2">{mapping.attr.Name}</th>
                          <th id="unique-35139077th_3"></th>
                        </tr>
                      </thead>
                      <tbody id="unique-19560796tbody_1">
                        {mapping.values.map((subMapping, _index) => {
                          return (
                            <tr key={_index} id="unique-50844033tr_2">
                              <td id="unique-72323413td_1">
                                <Select
                                  value={subMapping[mapping.parent_attr.Name]}
                                  options={this.state.attributes[mapping.attr.Name].values.map((option) => {
                                    return {
                                      label: option.Name,
                                      value: `${option.id}.${option.Name}`,
                                    }
                                  })}
                                  onChange={(selectedOption) => {
                                    const e = {
                                      target: {
                                        name: mapping.parent_attr.Name,
                                        value: selectedOption,
                                      },
                                    }
                                    this.onChangeSubMapping(e, index, _index)
                                  }}
                                  isMulti={false}
                                />
                                {/* <select
                                  name={mapping.parent_attr.Name}
                                  value={subMapping[mapping.parent_attr.Name]}
                                  className="form-control"
                                  onChange={(e) => this.onChangeSubMapping(e, index, _index)}
                                  required
                                >
                                  <option value={''}>--Select {mapping.parent_attr.Name}--</option>
                                  {mapping.parent_attr.Name in this.state.attributes &&
                                    this.state.attributes[mapping.parent_attr.Name].values.map((option) => {
                                      return (
                                        <option key={option.id} value={`${option.id}.${option.Name}`}>
                                          {option.Name}
                                        </option>
                                      )
                                    })}
                                </select> */}
                              </td>
                              <td id="unique-70212893td_2">
                                <Select
                                  options={this.state.attributes[mapping.attr.Name].values.map((option) => {
                                    option.label = option.Name
                                    option.value = option.Name
                                    return option
                                  })}
                                  value={this.state.data[index].values[_index][mapping.attr.Name]}
                                  onChange={(selectedList) => {
                                    this.onChangeSubMapping(null, index, _index, mapping.attr.Name, selectedList)
                                  }}
                                  isMulti={true}
                                />
                              </td>
                              <td id="unique-10746210td_3">
                                <button className="btn" onClick={() => this.deleteSubMapping(index, _index)}>
                                  <i className="fa fa-trash-alt text-secondary" id="62240315" />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-75199650div_14">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
