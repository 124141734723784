import React from 'react'

import { PagingState, CustomPaging, SortingState, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid'
import {
  Grid,
  DragDropProvider,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableColumnResizing,
  TableSelection,
  TableFixedColumns,
  TableColumnReordering,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

class PDFListView extends React.Component {
  state = {
    columns: [],
    data: [],
    columnWidths: [],

    defaultHiddenColumnNames: this.props.defaultHiddenColumnNames,
    tableColumnVisibilityColumnExtensions: [],
    _uploadingPdfList: [],
    _sppidProcessingList: [],
    selection: [],
    sorting: [],
    sortingStateColumnExtensions: [],
    columnOrder: [],
    loading: false,
    toggled_actions: '',
  }

  componentDidMount = async () => {
    if (this.props.projectData) {
      await this.setState({
        data: this.props.projectData.pdfs,
        sorting: this.props.sorting,
      })
      await this.setColumns()
      this.setInitialColumnWidths()
    }
  }

  componentDidUpdate = async (props) => {
    if (this.props.uploadingPdfList.length !== this.state._uploadingPdfList.length) {
      await this.setState({
        _uploadingPdfList: JSON.parse(JSON.stringify(this.props.uploadingPdfList)),
        data: this.props.projectData.pdfs,
      })
      this.setColumns()
    }

    if (this.props.sppidProcessingList.length !== this.state._sppidProcessingList.length) {
      await this.setState({
        _sppidProcessingList: JSON.parse(JSON.stringify(this.props.sppidProcessingList)),
        data: this.props.projectData.pdfs,
      })
      this.setColumns()
    }

    if (this.props.pageLoading !== props.pageLoading) {
      await this.setState({
        data: this.props.projectData.pdfs,
        sorting: this.props.sorting,
        selection: [],
      })
      await this.setColumns()
      this.setInitialColumnWidths()
    }
  }

  setInitialColumnWidths = () => {
    let columnWidths = []

    if (this.props.user.pdfListColumnWidths.length) {
      columnWidths = this.props.user.pdfListColumnWidths
    } else {
      this.props.columns.forEach((column) => {
        columnWidths.push({
          columnName: column.name,
          width: 'width' in column ? column.width : column.name === 'pdfName' ? 360 : column.name === 'actions' ? 100 : 180,
        })
      })
    }

    this.setState({
      columnWidths: columnWidths,
    })
  }

  setColumns = async () => {
    let { defaultHiddenColumnNames, columnOrder } = this.state

    let columns = []
    let sortingStateColumnExtensions = []
    let tableColumnVisibilityColumnExtensions = []
    this.props.columns.forEach((column) => {
      if (!column.name) {
        return
      }

      if (!column.allowSort) {
        sortingStateColumnExtensions.push({
          columnName: column.name,
          sortingEnabled: false,
        })
      }

      if (!column.visible && !defaultHiddenColumnNames.includes(column.name)) {
        defaultHiddenColumnNames.push(column.name)
      }

      if (['actions', 'pdfName', 'pdf_status'].includes(column.name)) {
        tableColumnVisibilityColumnExtensions.push({ columnName: column.name, togglingEnabled: false })
      } else {
        if (!columnOrder.includes(column.name)) {
          columnOrder.push(column.name)
        }
      }

      columns.push({
        name: column.name,
        title: column.header,
        getCellValue: (row) => {
          if (column.name === 'actions') {
            return this.getPDFActions(row)
          }
          if (column.name === 'pdfName') {
            return (
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  lineHeight: '38px',
                  height: '38px',
                }}
                id={`unique-95916740div_${row.id}_${column.name}`}
              >
                {['pdf', 'PDF'].includes(row['File Type']) && !row.showIcon ? (
                  <button className="btn text-danger p-0" onClick={() => this.props.fetchDocument(row)} disabled={row.uploading}>
                    <i className="far fa-file-pdf" />
                  </button>
                ) : null}

                <button
                  title={!row.directoryStructure ? row.pdfName : `../../${row.pdfName}`}
                  className="btn btn-sm ml-3 text-left"
                  onClick={() => this.props.setPdfDrawingsListView(row)}
                >
                  <u>{!row.directoryStructure ? row.pdfName : `../../${row.pdfName}`}</u>
                </button>
              </div>
            )
          }

          if (column.name === 'pdf_status') {
            return (
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  lineHeight: '38px',
                  height: '38px',
                }}
                id={`unique-95916740div_${row.id}_${column.name}`}
              >
                {row.uploading && row.file_upload_status === 'pending' ? (
                  <span className="d-inline-block ml-2">
                    <i className="fa fa-spin fa-spinner" title="Upload in progress" />
                  </span>
                ) : row.file_upload_status === 'failed' ? (
                  <i className="fa fa-exclamation text-danger ml-2" title={row.comment} />
                ) : null}

                {row.sppid_dump && row.sppid_processing ? (
                  <span className="d-inline-block ml-3" id="unique-71105859span_2">
                    <i className="fa fa-cog fa-spin" title="Processing" id="13702532" />
                  </span>
                ) : null}

                {row.showIcon && !row.hasDrawings ? (
                  <i className="fas fa-info-circle d-inline-block ml-2" title="Only directory structure present for this pdf, not the actual pdf." />
                ) : null}
              </div>
            )
          }

          return (
            <div
              className="cursor-pointer"
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                lineHeight: '38px',
                height: '38px',
              }}
              title={column.name in row.title ? row.title[column.name] : null}
              id={`unique-95916740div_${row.id}_${column.name}`}
            >
              {row[column.name]}
            </div>
          )
        },
      })
    })

    await this.setState({
      columns: columns,
      sortingStateColumnExtensions: sortingStateColumnExtensions,
      defaultHiddenColumnNames: defaultHiddenColumnNames,
      tableColumnVisibilityColumnExtensions: tableColumnVisibilityColumnExtensions,
      columnOrder: columnOrder,
    })
  }

  getPDFActions = (row) => {
    let { product } = this.props
    let delete_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_delete_hidden`)
    let process_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_process_hidden`)

    return (
      <React.Fragment>
        <div className="dropdown d-inline-block" id="unique-41467251div_3">
          <button
            className={`btn ${this.state.toggled_actions === row.id ? 'text-primary' : 'text-secondary'}`}
            onClick={async (e) => {
              e.stopPropagation()
              if (this.state.toggled_actions === row.id) {
                await this.setState({ toggled_actions: '' })
              } else {
                await this.setState({ toggled_actions: row.id })
              }
              this.setColumns()
            }}
          >
            <i className="fa fa-ellipsis-v" id="13702532" />
          </button>
          {this.state.toggled_actions === row.id ? (
            <div className="dropdown-menu" id="unique-85276799div_4">
              {!row.hasDrawings || row.uploading || row.sppid_dump ? null : (
                <React.Fragment>
                  <button
                    disabled={this.props.processingPdfs || this.props.processViewMinimized || this.props.processViewFullscreen}
                    type="button"
                    className={`dropdown-item ${process_hidden ? 'd-none' : ''}`}
                    onClick={async () => {
                      await this.props.setSelection([row.id])
                      this.props.processPdfs()
                      this.setState({
                        processOptionsToggled: '',
                      })
                    }}
                  >
                    Process
                  </button>

                  <div className="px-4" id="unique-28993062div_5">
                    <button
                      className="dropdown-item"
                      onClick={async () => {
                        await this.props.setSelection([row.id])
                        this.props.processPdfs('Searchable Text')
                        this.setState({
                          processOptionsToggled: '',
                        })
                      }}
                      disabled={this.props.processingPdfs || this.props.processViewMinimized || this.props.processViewFullscreen}
                    >
                      <i className="fa fa-caret-right " id="13702532" /> Searchable Text
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={async () => {
                        await this.setSelection([row.id])
                        this.props.processPdfs('AutoCAD Text')
                        this.setState({
                          processOptionsToggled: '',
                        })
                      }}
                      disabled={this.props.processingPdfs || this.props.processViewMinimized || this.props.processViewFullscreen}
                    >
                      <i className="fa fa-caret-right " id="13702532" /> AutoCAD Text
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={async () => {
                        await this.setSelection([row.id])
                        this.props.processPdfs('None-Searchable Text')
                        this.setState({
                          processOptionsToggled: '',
                        })
                      }}
                      disabled={this.props.processingPdfs || this.props.processViewMinimized || this.props.processViewFullscreen}
                    >
                      <i className="fa fa-caret-right " id="13702532" /> None-Searchable Text
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={async () => {
                        await this.setSelection([row.id])
                        this.props.processAllDrawingsInPdfWithWarning('SPPID Dump Excel')
                        this.setState({
                          processOptionsToggled: '',
                        })
                      }}
                      disabled={this.props.processingPdfs || this.props.processViewMinimized || this.props.processViewFullscreen}
                    >
                      <i className="fa fa-caret-right " id="13702532" /> SPPID Dump Excel
                    </button>
                  </div>
                  <div className="dropdown-divider" id="13702532" />
                </React.Fragment>
              )}

              {!delete_hidden ? (
                <button
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.setDeleteWarningMessage(true)
                  }}
                  className={`dropdown-item text-danger`}
                  disabled={this.props.processViewMinimized || this.props.processViewFullscreen || row.uploading}
                >
                  Delete
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    )
  }

  getRowId = (row) => row.id

  setSorting = async (sorting) => {
    this.props.setSorting(sorting)
  }

  // HeaderCell = ({children, tableRow, tableColumn, column, ...restProps}) => {
  //     return (
  //         <TableHeaderRow.Cell
  //             {...restProps}
  //             className={`${column.name}`}
  //         >
  //             {column.name === 'select'?
  //                 <label
  //                     className={`checkbox text-center d-inline-block mr-0 ${this.checkIfAllPdfsSelected()?'checked':''}`}
  //                     onClick={this.selectAll}
  //                 id="13702532"/>
  //                 :
  //                 null
  //             }
  //             {!['select'].includes(column.name)?
  //                 children
  //                 :
  //                 null
  //             }
  //         </TableHeaderRow.Cell>
  //     )
  // }

  setColumnWidths = async (columnWidths) => {
    await this.props.updateColumnWidths(columnWidths, 'PDFListView')
    this.setState({ columnWidths: columnWidths })
  }

  setColumnOrder = (order) => {
    this.setState({ columnOrder: order })
  }

  handleColumnVisibilityChange = (hiddenColumnNames) => {
    this.setState({ defaultHiddenColumnNames: hiddenColumnNames })
  }

  render() {
    let { loading, columns, data, columnWidths, sorting, sortingStateColumnExtensions, defaultHiddenColumnNames, columnOrder, tableColumnVisibilityColumnExtensions } = this.state
    let { pageLoading, pageSize, currentPage, totalCount, selection, leftColumns } = this.props
    leftColumns = [TableSelection.COLUMN_TYPE, ...leftColumns]

    return (
      <div style={{ position: 'relative' }} onClick={() => this.setState({ toggled_actions: '' }, this.setColumns)}>
        <div style={{ position: 'absolute', width: '100%' }} id="unique-74260107div_8">
          <div className="card" id="unique-34119638div_9">
            <Grid rows={data} columns={columns} getRowId={this.getRowId}>
              <DragDropProvider />
              <PagingState currentPage={currentPage} onCurrentPageChange={this.props.setCurrentPage} pageSize={pageSize} onPageSizeChange={this.props.setPageSize} />
              <CustomPaging totalCount={totalCount} />
              <SortingState sorting={sorting} onSortingChange={this.setSorting} columnExtensions={sortingStateColumnExtensions} />

              <SelectionState selection={selection} onSelectionChange={this.props.setSelection} />

              <VirtualTable />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.setColumnWidths} />
              <TableColumnReordering order={columnOrder} onOrderChange={this.setColumnOrder} />

              <TableHeaderRow showSortingControls />
              <IntegratedSelection />
              <TableSelection showSelectAll />

              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                onHiddenColumnNamesChange={this.handleColumnVisibilityChange}
                columnExtensions={tableColumnVisibilityColumnExtensions}
              />
              <PagingPanel pageSizes={this.props.pageSizes} />
              <TableFixedColumns
                leftColumns={leftColumns}
                // rightColumns={rightColumns}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
            {loading || pageLoading ? (
              <div className="card-loader" id="unique-94660024div_10">
                <div id="loading" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default PDFListView
