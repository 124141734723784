import React from 'react'
import { connect } from 'react-redux'

import DisplayMessage from '../../ErrorHandling/DisplayMessage'
import { fetchModelsInfo, processDrawingPackage } from './../../../store/actions'
import Input from '../../Forms/Fields/input'

class Step1 extends React.Component {
  state = {
    projectName: null,
    projectId: null,
    product: null,
    clientName: null,

    // PDFs/Drawings states
    activePdf: null,
    pdfs: [],
    maxDrawingsSelection: 5,
    selectedDrawings: [],

    // Models states
    fetchingModels: false,
    models: [],
    maxModelsSelection: 3,
    selectedPackages: [],
    packages_rank: {},

    // Symbol Config State
    collapsedSymbol: [],
    toggledOptions: '',
    symbols: [],
    newSymbol: '',
    addNewSymbolActivePath: null,
    processStarted: false,
  }

  componentDidMount() {
    let activePdf = null
    if (this.props.user.singleProjectData) {
      if (this.props.user.singleProjectData.pdfs.length > 0) {
        activePdf = !this.state.activePdf ? this.props.user.singleProjectData.pdfs[0].pdfName : this.state.activePdf
      }
    }

    this.setState(
      {
        projectName: this.props.projectName,
        projectId: this.props.projectId,
        product: this.props.product,
        clientName: this.props.clientName,
        selectedDrawings: this.props.selectedDrawings,
        selectedPackages: this.props.selectedPackages,
        pdfs: this.props.user.singleProjectData.pdfs,
        activePdf: activePdf,
      },
      this.fetchModelsInfo
    )
  }

  fetchModelsInfo = async () => {
    await this.setState({ fetchingModels: true })
    const formData = new FormData()
    formData.append('productType', this.state.product)
    formData.append('projectId', this.state.projectId)
    formData.append('tagging', this.props.tagging)
    await this.props.fetchModelsInfo(formData)
    if (!this.props.user.displayMessageCode) {
      let packages_rank = {}
      this.props.user.modelsInfo.forEach((model) => {
        packages_rank[model] = 0
      })
      await this.setState({
        models: this.props.user.modelsInfo,
        symbols: this.props.user.selectedSymbols,
        packages_rank: packages_rank,
      })
      this.updatePackagesRank()
    }
    this.setState({ fetchingModels: false })
  }

  selectDrawing = (drawing) => {
    let selectedDrawings = this.state.selectedDrawings
    if (selectedDrawings.includes(drawing.id)) {
      const index = selectedDrawings.indexOf(drawing.id)
      if (index > -1) {
        selectedDrawings.splice(index, 1)
      }
    } else {
      if (selectedDrawings.length < this.state.maxDrawingsSelection) {
        selectedDrawings.push(drawing.id)
      }
    }

    this.setState({ selectedDrawings: selectedDrawings })
  }

  checkSelectionInPdf = (pdf) => {
    let selected = false
    pdf.drawings.forEach((drawing) => {
      if (this.state.selectedDrawings.includes(drawing.id)) {
        selected = true
      }
    })
    return selected
  }

  renderPdfs = () => {
    return this.state.pdfs.map((pdf) => {
      return (
        <div key={pdf.pdfName} id="unique-32883395div_1">
          <div onClick={() => this.setState({ activePdf: pdf.pdfName })} className={`p-2 clearfix pdf ${this.state.activePdf === pdf.pdfName ? 'active' : ''}`}>
            {pdf.pdfName} &nbsp; ({pdf.drawings.length})
            {this.state.activePdf === pdf.pdfName ? <i className="fa fa-angle-up float-right"></i> : <i className="fa fa-angle-down float-right"></i>}
          </div>
          {this.state.activePdf === pdf.pdfName || this.checkSelectionInPdf(pdf) ? (
            <div id="unique-60781526div_3">
              {pdf.drawings.map((drawing) => {
                return (
                  <div className="drawing p-1 pl-4" key={drawing.id} id="unique-70206606div_4">
                    <div className="d-inline-block text-center" id="unique-95194126div_5">
                      {!this.state.selectedDrawings.includes(drawing.id) && this.state.selectedDrawings.length === 5 ? (
                        <label className="checkbox disabled" title="Maximum of 5 drawings are allowed for selection" id="66315270" />
                      ) : (
                        <label onClick={() => this.selectDrawing(drawing)} className={`checkbox ${this.state.selectedDrawings.includes(drawing.id) ? 'checked' : ''}`} />
                      )}
                    </div>
                    {drawing.b_Id}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      )
    })
  }

  selectPackage = (model) => {
    let selectedPackages = this.state.selectedPackages
    if (selectedPackages.includes(model)) {
      const index = selectedPackages.indexOf(model)
      if (index > -1) {
        selectedPackages.splice(index, 1) // 2nd parameter means remove one item only
      }
    } else {
      if (selectedPackages.length < this.state.maxModelsSelection) {
        selectedPackages.push(model)
      }
    }

    this.setState({ selectedPackages: selectedPackages })
  }

  renderPackages = () => {
    let packages_rank = this.state.packages_rank

    return Object.entries(packages_rank).map(([key, val]) => {
      return this.state.models.map((model) => {
        if (model !== key) {
          return null
        }

        let className = 'bg-light'
        if (val > 0) {
          className = `opacity-4`
        }

        return (
          <div className={`p-2 ${className}`} key={model} style={{ borderBottom: '1px solid #eee' }} id="unique-33880675div_6">
            <div className="d-inline-block text-center" id="unique-39809019div_7">
              {this.state.selectedPackages.length === 3 && !this.state.selectedPackages.includes(model) ? (
                <label className="checkbox disabled" title="Maximum of three packages are allowed for selection" id="66315270" />
              ) : (
                <label onClick={() => this.selectPackage(model)} className={`checkbox ${this.state.selectedPackages.includes(model) ? 'checked' : ''}`} />
              )}
            </div>
            {model}
            {Array.from(Array(val).keys()).map((_key) => {
              return <i className="fa fa-check text-success d-inline-block ml-2" key={_key} id="66315270" />
            })}
            <div className="mt-1" id="unique-11412536div_8">
              <small className="text-muted">{this.props.user.modelsDescription[model]}</small>
            </div>
          </div>
        )
      })
    })
  }

  processDrawingPackage = async () => {
    await this.setState({ processStarted: true })
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('drawingList', this.state.selectedDrawings)
    formData.append('packageList', this.state.selectedPackages)
    formData.append('reprocess', true)
    formData.append('selected_symbols', JSON.stringify(this.state.symbols))
    formData.append('tagging', this.props.tagging)
    await this.props.processDrawingPackage(formData)

    if (!this.props.user.displayMessageCode) {
      this.props.changeStep(2)
    }

    this.setState({ processStarted: false })
  }

  updatePackagesRank = () => {
    let symbols = this.state.symbols
    let packages_rank = this.state.packages_rank

    symbols.forEach((symbol) => {
      if (symbol.parent) {
        symbol.value.forEach((_symbol) => {
          if (_symbol.parent) {
            _symbol.value.forEach((__symbol) => {
              if (!__symbol.parent) {
                __symbol.symbol_options.forEach((__option) => {
                  if (__option.selected) {
                    __option.packages_overlap.forEach((___package) => {
                      packages_rank[___package] += 1
                    })
                  }
                })
              }
            })
          } else {
            _symbol.symbol_options.forEach((_option) => {
              if (_option.selected) {
                _option.packages_overlap.forEach((__package) => {
                  packages_rank[__package] += 1
                })
              }
            })
          }
        })
      } else {
        symbol.symbol_options.forEach((option) => {
          if (option.selected) {
            option.packages_overlap.forEach((_package) => {
              packages_rank[_package] += 1
            })
          }
        })
      }
    })

    this.setState({ packages_rank: packages_rank })
  }

  addNewSymbol = (e) => {
    e.preventDefault()
    let path = this.state.addNewSymbolActivePath
    path = path.split('.')
    let symbols = this.state.symbols

    if (path.length === 1) {
      const Index_1 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_1 > -1) {
        const find_existing_index_1 = symbols[Index_1].symbol_options.findIndex((option) => option.key === this.state.newSymbol)
        if (find_existing_index_1 === -1) {
          symbols[Index_1].symbol_options.push({
            key: this.state.newSymbol,
            selected: false,
            packages_overlap: [],
          })
        }
      }
    } else if (path.length === 2) {
      const Index_2 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_2 > -1) {
        const _Index_2 = symbols[Index_2].value.findIndex((data) => data.key.toString() === path[1].toString())
        if (_Index_2 > -1) {
          const find_existing_index_2 = symbols[Index_2].value[_Index_2].symbol_options.findIndex((option) => option.key === this.state.newSymbol)
          if (find_existing_index_2 === -1) {
            symbols[Index_2].value[_Index_2].symbol_options.push({
              key: this.state.newSymbol,
              selected: false,
              packages_overlap: [],
            })
          }
        }
      }
    } else if (path.length === 3) {
      const Index_3 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_3 > -1) {
        const _Index_3 = symbols[Index_3].value.findIndex((data) => data.key.toString() === path[1].toString())
        if (_Index_3 > -1) {
          const __Index_3 = symbols[Index_3].value[_Index_3].value.findIndex((data) => data.key === path[2])
          if (__Index_3 > -1) {
            const find_existing_index_3 = symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options.findIndex((option) => option.key === this.state.newSymbol)
            if (find_existing_index_3 === -1) {
              symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options.push({
                key: this.state.newSymbol,
                selected: false,
                packages_overlap: [],
              })
            }
          }
        }
      }
    }

    this.setState({
      symbols: symbols,
      newSymbol: '',
      addNewSymbolActivePath: null,
    })
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  toggleSelection = (path, option_index) => {
    path = path.split('.')
    let symbols = this.state.symbols
    let packages_rank = this.state.packages_rank

    if (path.length === 1) {
      const Index_1 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_1 > -1) {
        if (symbols[Index_1].symbol_options[option_index].selected) {
          symbols[Index_1].symbol_options[option_index].packages_overlap.forEach((_package) => {
            packages_rank[_package] -= 1
          })
        } else {
          symbols[Index_1].symbol_options[option_index].packages_overlap.forEach((_package) => {
            packages_rank[_package] += 1
          })
        }
        symbols[Index_1].symbol_options[option_index].selected = !symbols[Index_1].symbol_options[option_index].selected
      }
    } else if (path.length === 2) {
      const Index_2 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_2 > -1) {
        const _Index_2 = symbols[Index_2].value.findIndex((data) => data.key.toString() === path[1].toString())
        if (_Index_2 > -1) {
          if (symbols[Index_2].value[_Index_2].symbol_options[option_index].selected) {
            symbols[Index_2].value[_Index_2].symbol_options[option_index].packages_overlap.forEach((__package) => {
              packages_rank[__package] -= 1
            })
          } else {
            symbols[Index_2].value[_Index_2].symbol_options[option_index].packages_overlap.forEach((__package) => {
              packages_rank[__package] += 1
            })
          }
          symbols[Index_2].value[_Index_2].symbol_options[option_index].selected = !symbols[Index_2].value[_Index_2].symbol_options[option_index].selected
        }
      }
    } else if (path.length === 3) {
      const Index_3 = symbols.findIndex((data) => data.key.toString() === path[0].toString())
      if (Index_3 > -1) {
        const _Index_3 = symbols[Index_3].value.findIndex((data) => data.key.toString() === path[1].toString())
        if (_Index_3 > -1) {
          const __Index_3 = symbols[Index_3].value[_Index_3].value.findIndex((data) => data.key === path[2])
          if (__Index_3 > -1) {
            if (symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options[option_index].selected) {
              symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options[option_index].packages_overlap.forEach((___package) => {
                packages_rank[___package] -= 1
              })
            } else {
              symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options[option_index].packages_overlap.forEach((___package) => {
                packages_rank[___package] += 1
              })
            }
            symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options[option_index].selected =
              !symbols[Index_3].value[_Index_3].value[__Index_3].symbol_options[option_index].selected
          }
        }
      }
    }
    this.setState({ symbols: symbols, packages_rank: packages_rank })
  }

  updatedCollapseSymbols = (key) => {
    let { collapsedSymbol } = this.state
    const update_index = collapsedSymbol.findIndex((_key) => _key === key)
    if (update_index > -1) {
      collapsedSymbol.splice(update_index, 1)
    } else {
      collapsedSymbol.push(key)
    }
    this.setState({ collapsedSymbol: collapsedSymbol })
  }

  renderSymbols = () => {
    return this.state.symbols.map((data) => {
      return (
        <div key={data.key} className="p-2 pdf" id="unique-75814711div_9">
          <h6 className="mb-0">
            <i
              style={{ cursor: 'pointer' }}
              onClick={() => this.updatedCollapseSymbols(data.key)}
              className={`fa ${this.state.collapsedSymbol.includes(data.key) ? 'fa-minus' : 'fa-plus'} mr-3`}
            ></i>
            {data.key}
          </h6>
          {this.state.collapsedSymbol.includes(data.key) ? (
            data.parent ? (
              data.value.map((_data) => {
                return (
                  <div key={_data.key} className="p-2" id="unique-13471818div_10">
                    {_data.parent ? (
                      <>
                        <div id="unique-27276812div_11">{_data.key}</div>
                        {_data.value.map((__data) => {
                          return (
                            <div key={__data.key} className="p-2" id="unique-11200783div_12">
                              {__data.parent ? (
                                <div id="unique-49785608div_13">{__data.key}</div>
                              ) : (
                                <div className="bg-light p-2" id="unique-16123084div_14">
                                  <div id="unique-92363903div_15">{__data.key}</div>
                                  <div className="dropdown d-inline-block" id="unique-85578342div_16">
                                    <button
                                      onClick={() => {
                                        if (this.state.toggledOptions !== __data.key) {
                                          this.setState({
                                            toggledOptions: __data.key,
                                          })
                                        } else {
                                          this.setState({
                                            toggledOptions: null,
                                          })
                                        }
                                      }}
                                      className="btn btn-default btn-sm m-1"
                                    >
                                      {this.state.toggledOptions === __data.key ? (
                                        <i className="fa fa-minus" id="unique-57355640i_5"></i>
                                      ) : (
                                        <i className="fa fa-plus" id="unique-40200913i_6"></i>
                                      )}
                                    </button>
                                    {this.state.toggledOptions === __data.key ? (
                                      <div className="dropdown-menu" id="unique-65820104div_17">
                                        {__data.symbol_options.map((symbol, index) => {
                                          if (!symbol.selected) {
                                            return (
                                              <button
                                                onClick={() => this.toggleSelection(`${data.key}.${_data.key}.${__data.key}`, index)}
                                                className="dropdown-item"
                                                key={symbol.key}
                                              >
                                                {symbol.key} &nbsp;
                                                {symbol.packages_overlap.length ? (
                                                  <small className="text-muted">
                                                    (
                                                    {symbol.packages_overlap.map((_package) => {
                                                      return (
                                                        <span key={_package} className="mr-1 ml-1" id="unique-39435433span_1">
                                                          {_package}
                                                        </span>
                                                      )
                                                    })}
                                                    )
                                                  </small>
                                                ) : null}
                                              </button>
                                            )
                                          }
                                          return null
                                        })}

                                        <div className="dropdown-item mt-2 text-center" id="unique-38339675div_18">
                                          {this.state.addNewSymbolActivePath === `${data.key}.${_data.key}.${__data.key}` ? (
                                            <form onSubmit={this.addNewSymbol} className="input-group">
                                              <Input type="text" value={this.state.newSymbol} name="newSymbol" onChange={this.onChange} required={true} />
                                              <div className="input-group-append">
                                                <button className="btn btn-primary">
                                                  <i className="fa fa-plus"></i>
                                                </button>
                                              </div>
                                            </form>
                                          ) : (
                                            <button
                                              className="btn btn-primary btn-sm"
                                              onClick={() =>
                                                this.setState({
                                                  addNewSymbolActivePath: `${data.key}.${_data.key}.${__data.key}`,
                                                })
                                              }
                                            >
                                              Add New Symbol
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  {__data.symbol_options.map((symbol, index) => {
                                    if (symbol.selected) {
                                      return (
                                        <button
                                          onClick={() => this.toggleSelection(`${data.key}.${_data.key}.${__data.key}`, index)}
                                          className="btn btn-default btn-sm m-1"
                                          key={symbol.key}
                                        >
                                          {symbol.key}
                                        </button>
                                      )
                                    }
                                    return null
                                  })}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <div className="bg-light p-2" id="unique-10142476div_20">
                        <div id="unique-53970196div_21">{_data.key}</div>
                        <div className="dropdown d-inline-block" id="unique-10412790div_22">
                          <button
                            onClick={() => {
                              if (this.state.toggledOptions !== _data.key) {
                                this.setState({
                                  toggledOptions: _data.key,
                                })
                              } else {
                                this.setState({
                                  toggledOptions: null,
                                })
                              }
                            }}
                            className="btn btn-default btn-sm m-1"
                          >
                            {this.state.toggledOptions === _data.key ? (
                              <i className="fa fa-minus" id="unique-58189630i_8"></i>
                            ) : (
                              <i className="fa fa-plus" id="unique-10854922i_9"></i>
                            )}
                          </button>
                          {this.state.toggledOptions === _data.key ? (
                            <div className="dropdown-menu" id="unique-10917927div_23">
                              {_data.symbol_options.map((symbol, index) => {
                                if (!symbol.selected) {
                                  return (
                                    <button onClick={() => this.toggleSelection(`${data.key}.${_data.key}`, index)} className="dropdown-item" key={symbol.key}>
                                      {symbol.key} &nbsp;
                                      {symbol.packages_overlap.length ? (
                                        <small className="text-muted">
                                          (
                                          {symbol.packages_overlap.map((_package) => {
                                            return (
                                              <span key={_package} className="mr-1 ml-1" id="unique-27432610span_2">
                                                {_package}
                                              </span>
                                            )
                                          })}
                                          )
                                        </small>
                                      ) : null}
                                    </button>
                                  )
                                }
                                return null
                              })}

                              <div className="dropdown-item mt-2 text-center" id="unique-24532804div_24">
                                {this.state.addNewSymbolActivePath === `${data.key}.${_data.key}` ? (
                                  <form onSubmit={this.addNewSymbol} className="input-group">
                                    <Input type="text" value={this.state.newSymbol} name="newSymbol" onChange={this.onChange} required={true} />
                                    <div className="input-group-append">
                                      <button className="btn btn-primary">
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </div>
                                  </form>
                                ) : (
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      this.setState({
                                        addNewSymbolActivePath: `${data.key}.${_data.key}`,
                                      })
                                    }
                                  >
                                    Add New Symbol
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {_data.symbol_options.map((symbol, index) => {
                          if (symbol.selected) {
                            return (
                              <button onClick={() => this.toggleSelection(`${data.key}.${_data.key}`, index)} className="btn btn-default btn-sm m-1" key={symbol.key}>
                                {symbol.key}
                              </button>
                            )
                          }
                          return null
                        })}
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div className="p-2" id="unique-10849339div_26">
                <div className="bg-light p-2" id="unique-44202381div_27">
                  <div className="dropdown d-inline-block" id="unique-62833082div_28">
                    <button
                      onClick={() => {
                        if (this.state.toggledOptions !== data.key) {
                          this.setState({
                            toggledOptions: data.key,
                          })
                        } else {
                          this.setState({
                            toggledOptions: null,
                          })
                        }
                      }}
                      className="btn btn-default btn-sm m-1"
                    >
                      {this.state.toggledOptions === data.key ? <i className="fa fa-minus" id="unique-91808090i_11"></i> : <i className="fa fa-plus" id="unique-75223589i_12"></i>}
                    </button>
                    {this.state.toggledOptions === data.key ? (
                      <div className="dropdown-menu" id="unique-48437965div_29">
                        {data.symbol_options.map((symbol, index) => {
                          if (!symbol.selected) {
                            return (
                              <button onClick={() => this.toggleSelection(`${data.key}`, index)} className="dropdown-item" key={symbol.key}>
                                {symbol.key} &nbsp;
                                {symbol.packages_overlap.length ? (
                                  <small className="text-muted">
                                    (
                                    {symbol.packages_overlap.map((_package) => {
                                      return (
                                        <span key={_package} className="mr-1 ml-1" id="unique-50088938span_3">
                                          {_package}
                                        </span>
                                      )
                                    })}
                                    )
                                  </small>
                                ) : null}
                              </button>
                            )
                          }
                          return null
                        })}

                        <div className="dropdown-item mt-2 text-center" id="unique-38464216div_30">
                          {this.state.addNewSymbolActivePath === `${data.key}` ? (
                            <form onSubmit={this.addNewSymbol} className="input-group" id="unique-31138984form_3">
                              <input
                                className="form-control"
                                value={this.state.newSymbol}
                                onChange={(e) =>
                                  this.setState({
                                    newSymbol: e.target.value,
                                  })
                                }
                                required
                              />
                              <div className="input-group-append" id="unique-22049510div_31">
                                <button className="btn btn-primary" id="unique-93632692button_13">
                                  <i className="fa fa-plus" id="unique-18706207i_13"></i>
                                </button>
                              </div>
                            </form>
                          ) : (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                this.setState({
                                  addNewSymbolActivePath: `${data.key}`,
                                })
                              }
                            >
                              Add New Symbol
                            </button>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {data.symbol_options.map((symbol, index) => {
                    if (symbol.selected) {
                      return (
                        <button onClick={() => this.toggleSelection(`${data.key}`, index)} className="btn btn-default btn-sm m-1" key={symbol.key}>
                          {symbol.key}
                        </button>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            )
          ) : null}
        </div>
      )
    })
  }

  saveAndExit = async () => {
    await this.processDrawingPackage()
    this.props.exit()
  }

  render() {
    return (
      <>
        {!this.props.tagging ? (
          <div className="mb-4" id="unique-58731759div_32">
            <h3 id="unique-10614420h3_1">Package Configuration / Select Drawings & Packages</h3>
          </div>
        ) : null}

        <div className="card mb-2" id="unique-10748453div_33">
          <div className="card-body p-2" id="unique-55007765div_34">
            <div className="clearfix" id="unique-44274233div_35">
              <button
                disabled={!this.state.selectedDrawings.length || (!this.props.tagging && !this.state.selectedPackages.length) || this.state.processStarted}
                onClick={this.processDrawingPackage}
                className="btn btn-primary float-right"
                id="unique-57572973button_16"
              >
                Next
              </button>
              {!this.props.tagging ? (
                <button
                  disabled={!this.state.selectedDrawings.length || (!this.props.tagging && !this.state.selectedPackages.length) || this.state.processStarted}
                  className="btn btn-secondary float-right mr-2"
                  onClick={this.saveAndExit}
                  id="unique-94104014button_17"
                >
                  Save & Exit
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row" id="unique-47678537div_36">
          {!this.props.tagging ? (
            <div className="col-md-4" id="unique-15603968div_37">
              <div className="table-container p-0" id="unique-34350168div_38">
                {this.state.fetchingModels ? (
                  <div className="mt-4 text-center" style={{ fontSize: 40 }} id="unique-10199499div_39">
                    Fetching Symbols Info ...
                  </div>
                ) : (
                  <>
                    <div className="pdf p-2" id="unique-80497421div_40">
                      <h4 className="mb-0" id="unique-22519427h4_1">
                        Select Symbols
                      </h4>
                    </div>
                    {this.renderSymbols()}
                  </>
                )}
              </div>
            </div>
          ) : null}
          {!this.props.tagging ? (
            <div className="col-md-4" id="unique-87281558div_41">
              <div className="table-container p-0" id="unique-94321659div_42">
                {this.state.fetchingModels ? (
                  <div className="mt-4 text-center" style={{ fontSize: 40 }} id="unique-39513083div_43">
                    Fetching Package Info ...
                  </div>
                ) : (
                  <>
                    <div className="pdf p-2" id="unique-37871294div_44">
                      <h4 className="mb-0" id="unique-33033493h4_2">
                        Select Packages
                      </h4>
                    </div>
                    {this.renderPackages()}
                  </>
                )}
              </div>
            </div>
          ) : null}

          <div className="col" id="unique-24562980div_45">
            <div className="table-container p-0" id="unique-34041495div_46">
              <div className="pdf p-2" id="unique-56305514div_47">
                <h4 className="mb-0" id="unique-64588650h4_3">
                  Select Drawings
                </h4>
              </div>
              {this.renderPdfs()}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  fetchModelsInfo,
  processDrawingPackage,
})(Step1)
