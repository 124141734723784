import React from 'react'
import { connect } from 'react-redux'

import { updateDrawingsPackageImage } from './../../../store/actions'

import ToggleSwitch from '../ToggleSwitch'
import IUIAnnotation from '../../../IUI/IUIAnnotation'
import DisplayMessage from '../../ErrorHandling/DisplayMessage'
import Select from '../../Forms/Fields/select'

class Step2_2 extends React.Component {
  state = {
    schema: {},
    colorSet: {},
    toggledSwitchList: [],
    editorColumns: {},
    componentTreeSchema: {},
    collapseList: [],
    compNameMap: {},
    attrNameMap: {},
    componentAttrTypes: {},
    standardGroup: [],
    geoGroup: [],
    metaGroup: [],

    imageWidth: 0,
    imageHeight: 0,
    data: {},
    viewEditor: false,
    activeEditorComponent: '',
    activeEditorComponentType: '',
    // Zoom
    zoomIn: false,
    zoomOut: false,
    zoomOptions: [0.5, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 1.05, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.55, 1.6, 1.65, 1.7, 1.75, 1.8, 1.85, 1.9, 1.95, 2],
    zoom: 1,

    fetchingData: false,
    processDrawings: this.props.processDrawings,
    selectedPackages: this.props.selectedPackages,
    selectedPackage: this.props.selectedPackages.length ? this.props.selectedPackages[0] : '',
    activeDrawingUrl: null,
  }

  componentDidMount() {
    this.getDrawingUrl()
  }

  componentDidUpdate(props) {
    if (this.props.activeDrawing !== props.activeDrawing) {
      this.getDrawingUrl()
    }
  }

  getDrawingUrl = async () => {
    await this.setState({ imageHeight: 0, imageWidth: 0 })
    let drawing_url = ''
    const index = this.state.processDrawings.findIndex((drawing) => drawing.id === this.props.activeDrawing)
    if (index > -1) {
      drawing_url = this.state.processDrawings[index].url
    }
    await this.updateDrawingsPackageImage()
    await this.setState({ activeDrawingUrl: drawing_url })
  }

  updateDrawingsPackageImage = async () => {
    await this.setState({ fetchingData: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('packageId', this.state.selectedPackage)
    formData.append('tagging', this.props.tagging)
    await this.props.updateDrawingsPackageImage(formData)
    if (!this.props.user.displayMessageCode) {
      await this.setState({
        componentTreeSchema: this.props.user.processDrawingsPackageImageData.TreeStructure,
        toggledSwitchList: this.props.user.processDrawingsPackageImageData.ToggledSwitchList,
        data: this.props.user.processDrawingsPackageImageData.processDrawing,
        schema: this.props.user.processDrawingsPackageImageData.ComponentSchema,
        colorSet: this.props.user.processDrawingsPackageImageData.ColorSet,
        editorColumns: this.props.user.processDrawingsPackageImageData.EditorColumns,
        compNameMap: this.props.user.processDrawingsPackageImageData.comp_name_map,
        attrNameMap: this.props.user.processDrawingsPackageImageData.attr_name_map,
        componentAttrTypes: this.props.user.processDrawingsPackageImageData.ComponentAttrType,
        standardGroup: this.props.user.processDrawingsPackageImageData.StandardGroup,
        geoGroup: this.props.user.processDrawingsPackageImageData.GeoGroup,
        metaGroup: this.props.user.processDrawingsPackageImageData.MetaGroup,
      })
    }
    await this.setState({ fetchingData: false })
  }

  handleImageLoaded = () => {
    var myImg = document.querySelector('#ref-img')
    var realWidth = myImg.naturalWidth
    var realHeight = myImg.naturalHeight
    this.setState({
      imageWidth: realWidth,
      imageHeight: realHeight,
    })
  }

  updateCollapseList = (item) => {
    let collapseList = this.state.collapseList
    const index = collapseList.indexOf(item)
    if (index > -1) {
      collapseList.splice(index, 1)
    } else {
      collapseList.push(item)
    }
    this.setState({ collapseList: collapseList })
  }

  updateToggledSwitchList = async (switchName, switched, path = '') => {
    let toggledSwitchList = this.state.toggledSwitchList
    path = path.split('.')
    let data = this.state.componentTreeSchema

    if (switched) {
      toggledSwitchList.push(switchName)
    } else {
      const index = toggledSwitchList.findIndex((_switch) => _switch === switchName)
      if (index > -1) {
        toggledSwitchList.splice(index, 1)
      }
    }

    path.forEach((component, index) => {
      if (index === 0) {
        data = data[component]
      } else {
        data = data.Value[component]
      }
    })

    if (data.Value) {
      for (const key in data.Value) {
        let _data = data.Value[key]

        if (_data.Value) {
          for (const _key in _data.Value) {
            let __data = _data.Value[_key]

            if (switched) {
              toggledSwitchList.push(__data.for)
            } else {
              const index = toggledSwitchList.indexOf(__data.for)
              if (index > -1) {
                toggledSwitchList.splice(index, 1)
              }
            }
          }
        }

        if (switched) {
          toggledSwitchList.push(_data.for)
        } else {
          const index = toggledSwitchList.indexOf(_data.for)
          if (index > -1) {
            toggledSwitchList.splice(index, 1)
          }
        }
      }
    }

    await this.setState({ toggledSwitchList: toggledSwitchList })
    await this.setState({
      switchListUpdated: !this.state.switchListUpdated,
    })
  }

  renderComponentsTree = () => {
    let { componentTreeSchema } = this.state
    let schema = componentTreeSchema
    return Object.keys(schema).map((key) => {
      let _schema = schema[key].Value
      return (
        <div key={key} className="mb-1 bg-light border rounded">
          <span className="btn">
            <span className="d-inline-block mr-1" style={{ minWidth: 15 }}>
              {_schema ? (
                <i style={{ cursor: 'pointer' }} onClick={() => this.updateCollapseList(key)} className={`fa ${this.state.collapseList.includes(key) ? 'fa-minus' : 'fa-plus'}`} />
              ) : null}
            </span>
            <ToggleSwitch
              isToggled={this.state.toggledSwitchList.includes(schema[key].for)}
              className={schema[key].for.split('.')[0]}
              switchName={schema[key].for}
              path={key}
              updateToggledSwitchList={this.updateToggledSwitchList}
            />
            {schema[key].DisplayName}{' '}
          </span>

          {_schema && this.state.collapseList.includes(key)
            ? Object.keys(_schema).map((_key) => {
                let __schema = _schema[_key].Value
                return (
                  <div key={`${key}.${_key}`} id="unique-19406075div_2">
                    <span className="btn ml-3" id="unique-10313955span_3">
                      <span className="d-inline-block mr-1" style={{ minWidth: 15 }} id="unique-68164468span_4">
                        {__schema ? (
                          <i
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => this.updateCollapseList(`${key}_${_key}`)}
                            className={`fa ${this.state.collapseList.includes(`${key}_${_key}`) ? 'fa-minus' : 'fa-plus'}`}
                          />
                        ) : null}
                      </span>
                      <ToggleSwitch
                        isToggled={this.state.toggledSwitchList.includes(_schema[_key].for)}
                        className={_schema[_key].for.split('.')[0]}
                        switchName={_schema[_key].for}
                        path={`${key}.${_key}`}
                        updateToggledSwitchList={this.updateToggledSwitchList}
                      />
                      {_schema[_key].DisplayName}{' '}
                    </span>

                    {__schema && this.state.collapseList.includes(`${key}_${_key}`)
                      ? Object.keys(__schema).map((__key) => {
                          return (
                            <div key={`${key}.${_key}.${__key}`} id="unique-59292445div_3">
                              <span className="btn" id="unique-82210449span_5">
                                <div className="ml-4" id="unique-27984802div_4">
                                  <div className="ml-4" id="unique-26712751div_5">
                                    <ToggleSwitch
                                      isToggled={this.state.toggledSwitchList.includes(__schema[__key].for)}
                                      className={__schema[__key].for.split('.')[0]}
                                      switchName={__schema[__key].for}
                                      path={`${key}.${_key}.${__key}`}
                                      updateToggledSwitchList={this.updateToggledSwitchList}
                                    />
                                    {__schema[__key].DisplayName}{' '}
                                  </div>
                                </div>
                              </span>
                            </div>
                          )
                        })
                      : null}
                  </div>
                )
              })
            : null}
        </div>
      )
    })
  }

  toggleEditorView = async (view = undefined) => {
    await this.setState({ viewEditor: view === undefined ? !this.state.viewEditor : view })
  }

  updateZoomFactor = (zoom) => {
    this.setState({ zoom: zoom })
  }

  renderAnnotatedDrawings = () => {
    return (
      <div className="model-configuration" style={{ height: 'calc(48rem - 112px)' }} id="unique-59809337div_6">
        {!this.state.imageHeight || !this.state.imageWidth || this.state.fetchingData ? (
          <div className="text-center mt-4" id="unique-71859854div_7">
            Fetching Data ...
          </div>
        ) : (
          <IUIAnnotation
            img_url={this.state.activeDrawingUrl}
            img_height={this.state.imageHeight}
            img_width={this.state.imageWidth}
            drawingData={this.state.data}
            colorCodes={this.state.colorSet}
            schema={this.state.schema}
            toggledSwitchList={this.state.toggledSwitchList}
            switchListUpdated={this.state.switchListUpdated}
            ComponentAttributesType={this.state.componentAttrTypes}
            standardGroup={this.state.standardGroup}
            geoGroup={this.state.geoGroup}
            metaGroup={this.state.metaGroup}
            // Zoom
            zoomIn={this.state.zoomIn}
            zoomOut={this.state.zoomOut}
            zoomOptions={this.state.zoomOptions}
            zoom={this.state.zoom}
            updateZoomFactor={this.updateZoomFactor}
            // Zoom End

            canvasHeight={'calc(48rem - 112px)'}
            allow_annotation={false}
            allow_edit={false}
            editorColumns={this.state.editorColumns}
            viewEditor={this.state.viewEditor}
            activeEditorComponent={this.state.activeEditorComponent}
            activeEditorComponentType={this.state.activeEditorComponentType}
            compNameMap={this.state.compNameMap}
            attrNameMap={this.state.attrNameMap}
            toggleEditorView={this.toggleEditorView}
            update_rects_visibility_on_adding_bbox={false}
          />
        )}
      </div>
    )
  }

  getStatus = () => {
    let response = {}
    this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.forEach((drawing) => {
      if (drawing.id === this.props.activeDrawing) {
        response = drawing
      }
    })
    return response
  }

  render() {
    return (
      <div className="table-container model-configuration-controls p-0" style={{ height: '48rem' }} id="unique-11310350div_8">
        <div className="row no-gutters" style={{ backgroundColor: '#e9ecef' }} id="unique-33256182div_9">
          {!this.props.drawingsCollapsed ? (
            <div className="col-2" id="unique-89614173div_10">
              <span className="btn d-block" id="unique-28718974span_6">
                Components
              </span>
            </div>
          ) : null}

          {this.state.selectedPackages.map((_package) => {
            return (
              <div className="col" key={_package}>
                <span
                  onClick={() => this.setState({ selectedPackage: _package }, this.updateDrawingsPackageImage)}
                  className={`btn control d-block ${this.state.selectedPackage === _package ? 'active' : ''}`}
                >
                  {_package}
                </span>
              </div>
            )
          })}

          {this.getStatus().status === 2 ? (
            <div className="col" id="unique-89739113div_12">
              <span className={`btn d-block`} id="unique-10263959span_8">
                Selected <i className="fa fa-spin fa-spinner" id="unique-37556560i_3"></i>
              </span>
            </div>
          ) : this.getStatus().status === 1 ? (
            <div className="col" id="unique-23173667div_13">
              <span
                onClick={() => this.setState({ selectedPackage: 'Selected' }, this.updateDrawingsPackageImage)}
                className={`btn control d-block ${this.state.selectedPackage === 'Selected' ? 'active' : ''}`}
              >
                Selected
              </span>
            </div>
          ) : this.getStatus().status === -1 ? (
            <div className="col" id="unique-39930715div_14">
              <span className={`btn d-block`} id="unique-71136569span_10">
                Selected &nbsp; <i className="fa fa-exclamation-triangle text-warning" title={this.getStatus().error} id="unique-34703809i_4"></i>
              </span>
            </div>
          ) : null}
        </div>

        <div className="row no-gutters" id="unique-35688100div_15">
          <img src={this.state.activeDrawingUrl} className="d-none" id="ref-img" alt="ref img" onLoad={this.handleImageLoaded} />

          {!this.props.drawingsCollapsed ? (
            <div className="col-2" id="unique-90223287div_16">
              <div className="card rounded-0" style={{ height: 'calc(48rem - 38px)' }} id="unique-57996160div_17">
                <div className="card-body p-2" id="unique-80411756div_18">
                  {this.state.fetchingData ? (
                    <div className="text-center" id="unique-11078946div_19">
                      Fetching Data...
                    </div>
                  ) : (
                    this.renderComponentsTree()
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="col" id="unique-80256962div_20">
            <div className="card" style={{ borderRadius: 0 }} id="unique-24199262div_21">
              <div className="card-body p-2 px-3 clearfix" id="unique-29616335div_22">
                <button
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      zoomOut: !this.state.zoomOut,
                    })
                  }
                  disabled={this.state.zoom === this.state.zoomOptions[0]}
                >
                  <i className="fa fa-search-minus" id="unique-48107909i_5"></i>
                </button>
                <div className="d-inline-block mx-2" style={{ width: 120 }} id="unique-10080075div_23">
                  <Select
                    options={this.state.zoomOptions.map((option) => {
                      return {
                        label: `${Math.round(option * 100)} %`,
                        value: option,
                      }
                    })}
                    value={{
                      label: `${Math.round(this.state.zoom * 100)} %`,
                      value: this.state.zoom,
                    }}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        this.setState({ zoom: selectedOption.value })
                      }
                    }}
                    isMulti={false}
                    isClearable={false}
                  />
                </div>
                <button
                  className="btn btn-secondary mr-3"
                  onClick={() =>
                    this.setState({
                      zoomIn: !this.state.zoomIn,
                    })
                  }
                  disabled={this.state.zoom === this.state.zoomOptions[this.state.zoomOptions.length - 1]}
                >
                  <i className="fa fa-search-plus" id="unique-47996287i_6"></i>
                </button>

                <button
                  disabled={this.state.fetchingData}
                  className="btn btn-primary float-right"
                  onClick={() =>
                    this.setState({
                      viewEditor: !this.state.viewEditor,
                    })
                  }
                >
                  Review <i className="far fa-edit" id="unique-68807912i_7"></i>
                </button>
              </div>
            </div>
            <div className="card" style={{ borderRadius: 0 }} id="unique-25259087div_24">
              <div className="card-body p-2" id="unique-82762110div_25">
                {this.renderAnnotatedDrawings()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  updateDrawingsPackageImage,
})(Step2_2)
