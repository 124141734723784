import React, { Component } from 'react'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

import Select from '../Fields/select'

export default class ProjectSetupS8 extends Component {
  state = {
    components: [],
    loading: false,
    formLoader: false,
    deliverables_options: [],
    iworkflow_options: [],
    selected_deliverables: [],
    selected_iworkflow: [],
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectReportInfo(formData)
    await this.setState({
      loading: false,
      deliverables_options: this.props.user.projectInfo.deliverables_options,
      iworkflow_options: this.props.user.projectInfo.iworkflow_options,
      selected_deliverables: this.props.user.projectInfo.selected_deliverables.map((deliverable) => {
        return { label: deliverable, value: deliverable }
      }),
      selected_iworkflow: this.props.user.projectInfo.selected_iworkflow.map((iworkflow) => {
        return { label: iworkflow, value: iworkflow }
      }),
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append(
      'selected_iworkflow',
      this.state.selected_iworkflow.map((iworkflow) => {
        return iworkflow.value
      })
    )
    formData.append(
      'selected_deliverables',
      this.state.selected_deliverables.map((deliverable) => {
        return deliverable.value
      })
    )
    formData.append('step', 8)
    await this.props.updateProjectReportInfo(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  renderForm() {
    return (
      <div className="card" id="unique-39108566div_1">
        <div className="clearfix card-header bg-light" id="unique-97365428div_2">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-50844603button_4">
            Cancel
          </button>

          <h3 id="unique-68863588h3_1">Report Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="93000696" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-70020687div_3">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}
          <div className="row" id="unique-70521571div_4">
            <div className="col-6" id="unique-47003326div_5">
              <div className="card" id="unique-84911275div_6">
                <div className="card-header bg-light" id="unique-78746842div_7">
                  <h5 className="card-title mb-0" id="unique-10182440h5_1">
                    Select Deliverables
                  </h5>
                </div>
                <div className="card-body p-2" id="unique-38677102div_8">
                  <Select
                    options={this.state.deliverables_options.map((option) => {
                      return {
                        label: option,
                        value: option,
                      }
                    })}
                    value={this.state.selected_deliverables}
                    onChange={(selectedOptions) => {
                      this.setState({ selected_deliverables: selectedOptions })
                    }}
                    isMulti={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-6" id="unique-14858990div_9">
              <div className="card" id="unique-25218646div_10">
                <div className="card-header bg-light" id="unique-88736471div_11">
                  <h5 className="card-title mb-0" id="unique-48132425h5_2">
                    Select iWorkflows
                  </h5>
                </div>
                <div className="card-body p-2" id="unique-29082722div_12">
                  <Select
                    options={this.state.iworkflow_options.map((option) => {
                      return {
                        label: option,
                        value: option,
                      }
                    })}
                    value={this.state.selected_iworkflow}
                    onChange={(selectedOptions) => {
                      this.setState({ selected_iworkflow: selectedOptions })
                    }}
                    isMulti={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-95281654div_13">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
