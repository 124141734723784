import React, { Component } from 'react'

import { SortingState, IntegratedSorting, PagingState, SearchState, IntegratedPaging, FilteringState, IntegratedFiltering, EditingState } from '@devexpress/dx-react-grid'
import { Grid, VirtualTable, TableHeaderRow, PagingPanel, TableColumnResizing, TableFilterRow, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

export default class DataTable extends Component {
  state = {
    columns: [],
    rows: [],
    columnWidths: [],
    sorting: [],
    filters: [],
    columnExtensions: [
      {
        columnName: 'actions',
        sortingEnabled: false,
        filteringEnabled: false,
        editingEnabled: false,
      },
    ],
  }

  componentDidMount = async () => {
    await this.setState({ rows: this.props.rows })
    await this.setColumns()
    await this.setInitialColumnWidths()
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeTab !== this.props.activeTab || this.props.allow_edit !== prevProps.allow_edit) {
      await this.setState({ rows: this.props.rows })
      await this.setColumns()
      await this.setInitialColumnWidths()
    }
  }

  CustomTableCell = ({ value, style, row, column, ...restProps }) => {
    const backgroundColor = row.colored_cells && row.colored_cells.includes(column.name) ? '#f7adad' : '#ffffff'

    return (
      <VirtualTable.Cell
        {...restProps}
        style={{
          ...style,
          backgroundColor: backgroundColor,
        }}
      >
        {value}
      </VirtualTable.Cell>
    )
  }

  setColumns = () => {
    let columns = []
    let column_headers = [
      {
        name: 'actions',
        header: ' ',
      },
    ]
    column_headers.push(...this.props.column_headers)

    column_headers.forEach((column) => {
      columns.push({
        name: column.name,
        title: column.header,
        getCellValue: (row) => {
          if (column.name === 'actions') {
            return (
              <React.Fragment>
                <button disabled={!this.props.allow_edit} className={'btn btn-secondary btn-sm'} onClick={() => this.addRowBelow(row)}>
                  <i className={'fa fa-plus'} />
                </button>
              </React.Fragment>
            )
          } else {
            return row[column.name]
          }
        },
      })
    })
    this.setState({ columns: columns })
  }

  setInitialColumnWidths = async () => {
    let column_headers = [
      {
        name: 'actions',
        header: ' ',
      },
    ]
    column_headers.push(...this.props.column_headers)

    let columnWidths = []

    column_headers.forEach((column) => {
      columnWidths.push({
        columnName: column.name,
        width: 140,
      })
    })

    await this.setState({
      columnWidths: columnWidths,
    })
  }

  setSorting = (sorting) => {
    this.setState({ sorting: sorting })
  }

  setFilters = (filters) => {
    this.setState({ filters: filters })
  }

  uuid = () => {
    return 'xxxxxxxxxxxxyxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : r & 0x3 ? r & 0x3 : 0x8
      return v.toString(16)
    })
  }

  addRowBelow = (row) => {
    let { rows } = this.state
    const added_index = rows.findIndex((_row) => _row.uuid === row.uuid)
    this.commitChanges({ added: [{}], changed: undefined, deleted: undefined }, added_index)
  }

  commitChanges = async ({ added, changed, deleted }, added_index = 0) => {
    let { rows } = this.state
    let changedRows

    if (added) {
      // Insert the new rows at the specified index
      changedRows = [
        ...rows.slice(0, added_index + 1),
        ...added.map((row) => ({
          uuid: this.uuid(),
          ...row,
        })),
        ...rows.slice(added_index + 1),
      ]
    }

    if (changed) {
      changedRows = rows.map((row) => (changed[row.uuid] ? { ...row, ...changed[row.uuid] } : row))
    }
    if (deleted) {
      const deletedSet = new Set(deleted)
      changedRows = rows.filter((row) => !deletedSet.has(row.uuid))
    }

    await this.setState({ rows: changedRows })
    await this.setColumns()
    this.props.onChangeTableData(this.state.rows, this.props.data_index, this.props.table_index)
  }

  getRowId = (row) => row.uuid

  render() {
    let { columns, rows, columnWidths, sorting, filters, columnExtensions } = this.state
    const pageSizes = [5, 10, 15]
    return (
      <React.Fragment>
        <div className="table-container-wrapper">
          <div className="table-container">
            <Grid rows={rows} columns={columns} getRowId={this.getRowId}>
              <SortingState sorting={sorting} onSortingChange={this.setSorting} columnExtensions={columnExtensions} />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={15} />
              <IntegratedPaging />
              <SearchState />
              <FilteringState filters={filters} onFiltersChange={this.setFilters} columnExtensions={columnExtensions} />
              <IntegratedFiltering />
              <EditingState onCommitChanges={this.commitChanges} columnExtensions={columnExtensions} />

              <VirtualTable cellComponent={this.CustomTableCell} />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={(columnWidths) =>
                  this.setState({
                    columnWidths: columnWidths,
                  })
                }
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow showFilterSelector />
              <PagingPanel pageSizes={pageSizes} />

              {this.props.allow_edit && <TableEditRow />}
              {this.props.allow_edit && <TableEditColumn showEditCommand showDeleteCommand />}
            </Grid>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
