import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { createConfig, getConfigInfo, fetchUsers, fetchAllProjects } from '../../store/actions'

import './Forms.css'

class CreateConfig extends Component {
  state = {
    components: [],
    userAddedComponents: null,
    componentsId: [],
    selectedComponent: null,
    projectId: '',
    tag_extraction_mode: false,
    compCount: 0,
    usedComponents: [],
    projectName: '',
    loading: false,
    formLoader: false,
    configCreationStatus: false,
    errorMessage: null,
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const projectId = parseInt(query.get('id'))
    const product = query.get('product')
    let fetchedData = null
    let fetchedUserData = {}
    let compIdCount = 0
    let compId = []
    let usedComponents = []

    const userData = this.props.user.allProjects.find((project) => {
      return project.id === projectId
    })

    await this.setState({
      projectId: projectId,
      product: product,
      projectName: userData.projectName,
      tag_extraction_mode: userData.tagExtraction,
      loading: true,
    })

    await this.props.getConfigInfo(projectId, product)

    fetchedData = this.props.user.fetchConfigData
    if (fetchedData.length === 0) {
      fetchedData = this.props.user.fetchConfigList
    }

    fetchedData.forEach((data) => {
      const compids = 'comp' + compIdCount
      compIdCount += 1
      compId.push(compids)
      if (Array.isArray(data) && data.length > 1) {
        fetchedUserData[compids] = {
          component: data[0],
          standard: data[1] + ' : ' + data[2],
          isLocked: data[3],
          deleteDisabled: data[3] === 'locked' ? true : false,
        }
      } else {
        fetchedUserData[compids] = {
          component: data,
          standard: '',
          isLocked: 'unlocked',
          deleteDisabled: false,
        }
      }
      if (data[3] === 'locked') {
        usedComponents.push(data[0])
      }
    })

    this.setState({
      components: this.props.user.fetchConfigList,
      usedComponents: usedComponents,
      componentsId: compId,
      userAddedComponents: fetchedUserData,
      compCount: compIdCount,
      loading: false,
      configComponentsOptions: this.props.user.configComponentsOptions,
    })
  }

  // On form submit, this method will be called and data will be formatted according to API requirement and data will be sent to external API

  createConfigHandler = (e) => {
    e.preventDefault()
    this.setState({ formLoader: true })
    let outputData = []
    this.state.componentsId.forEach((comp) => {
      if (this.state.userAddedComponents[comp].standard.length > 0) {
        let singleOutput = this.state.userAddedComponents[comp].standard.split(' : ')
        singleOutput.unshift(this.state.userAddedComponents[comp].component)
        outputData.push(singleOutput)
        singleOutput = []
      }
    })

    let formData = new FormData()
    formData.append('project_id', this.state.projectId)
    formData.append('components', JSON.stringify(outputData))
    formData.append('tag_extraction_mode', this.state.tag_extraction_mode)
    formData.append('product', this.state.product)
    this.props.createConfig(formData).then(() => {
      this.setState({
        configCreationStatus: true,
        formLoader: false,
      })
      setTimeout(
        () =>
          this.setState({
            configCreationStatus: false,
          }),
        10000
      )
    })
  }

  //This method is called when user clicks on Add Component Button. A new component gets added to userAddedComponents state and it's value is added to componentsId state.

  addCompleteHandler = () => {
    const newComponentEntry = {
      component: this.state.selectedComponent,
      standard: '',
      isLocked: 'unlocked',
      deleteDisabled: false,
    }
    let newComponentObject = {
      ...this.state.userAddedComponents,
      ['comp' + this.state.compCount]: newComponentEntry,
    }

    let newState = {
      ...this.state,
      componentsId: [...this.state.componentsId, 'comp' + this.state.compCount],
      compCount: this.state.compCount + 1,
      userAddedComponents: newComponentObject,
    }
    const stateUpdateHandler = () => {}
    this.setState(newState, stateUpdateHandler)
  }

  //This method will be called when user selects a new component from the list of components.

  addComponentHandler = (event) => {
    this.setState(
      {
        selectedComponent: event.target.value,
      },
      () => {}
    )
  }

  // This method will be called when user selects Standard for a particular Component, and standard will be added to userAddedComponents state.
  standardHandler(event, comp) {
    let userData = this.state.userAddedComponents
    userData[comp].standard = event.target.value
    this.setState({
      userAddedComponents: userData,
    })
  }

  deleteComponent(index) {
    const newComponentArray = Array.from(this.state.componentsId)
    const newComponents = this.state.userAddedComponents
    delete newComponents[newComponentArray[index]]
    newComponentArray.splice(index, 1)
    const newState = {
      ...this.state,
      componentsId: newComponentArray,
      userAddedComponents: newComponents,
    }
    this.setState(newState)
  }

  // This method will be called when user reorders the component. It changes the state accordingly.
  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }
    const newComponentsOrder = Array.from(this.state.componentsId)
    newComponentsOrder.splice(source.index, 1)
    newComponentsOrder.splice(destination.index, 0, draggableId)
    const newState = {
      ...this.state,
      componentsId: newComponentsOrder,
    }
    const myFunction = () => {}

    this.setState(newState, myFunction)
  }

  render() {
    let addComponent_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-project_configure_add-component_hidden`)) {
      addComponent_hidden = true
    }

    let save_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-project_configure_save_hidden`)) {
      save_hidden = true
    }

    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-55338098div_1">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="admin" id="unique-80963059div_2">
        {this.state.errorMessage ? (
          <div className="mb-3 alert alert-danger" id="unique-10094641div_3">
            <i className="fa fa-exclamation-triangle" id="unique-11062428i_1" /> {this.state.errorMessage}
          </div>
        ) : null}

        <form onSubmit={this.createConfigHandler} id="unique-98459781form_1">
          <div className="banner clearfix" id="unique-25200850div_4">
            {!save_hidden ? (
              <button type="submit" className={`btn btn-primary float-right`} disabled={this.state.formLoader} id="unique-83819990button_1">
                {this.state.formLoader ? 'Saving...' : 'Save'}
              </button>
            ) : null}

            {!addComponent_hidden ? (
              <div className={`input-group float-right mr-2`} style={{ width: 340 }} id="unique-68453670div_5">
                <select className="form-control" onChange={this.addComponentHandler}>
                  <option>--Select Component--</option>
                  {this.state.components.map((component) => {
                    if (!this.state.usedComponents.includes(component)) {
                      return (
                        <option value={component} key={component}>
                          {component}
                        </option>
                      )
                    }
                    return null
                  })}
                </select>
                <div className="input-group-append" id="unique-10694669div_6">
                  <button
                    type="button"
                    id="unique-62131655button_2"
                    onClick={() => this.addCompleteHandler()}
                    className="btn btn-primary float-right"
                    disabled={!this.state.selectedComponent}
                  >
                    Add
                  </button>
                </div>
              </div>
            ) : null}
            <h3 id="unique-32345494h3_1">
              Create/Edit Configuration/
              {this.props.user.configClientName} {' : '} {this.state.projectName}
            </h3>
          </div>

          <div className="table-container p-0" id="unique-17642917div_7">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <table className="table table-striped">
                <thead className="thead-light">
                  <tr id="unique-24711994tr_1">
                    <th id="unique-29435483th_1"></th>
                    <th id="unique-72225633th_2">Components</th>
                    <th id="unique-18871543th_3">Standards</th>
                    <th id="unique-10116131th_4"></th>
                  </tr>
                </thead>
                <Droppable droppableId={'confiArea'}>
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps} id="unique-69964331tbody_1">
                      {this.state.componentsId.map((comp, index) => {
                        return (
                          <Draggable draggableId={comp} key={comp} index={index}>
                            {(provided) => (
                              <tr key={index} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="dragDrop" id="unique-55677703tr_2">
                                <td
                                  style={{
                                    padding: '0.75rem',
                                  }}
                                  id="unique-11110166td_1"
                                >
                                  {' '}
                                  <div className="dot-fire" id="unique-70056385div_8" />{' '}
                                </td>
                                <td id="unique-10407287td_2">{this.state.userAddedComponents[comp].component}</td>
                                <td id="unique-11071777td_3">
                                  <select
                                    className="form-control"
                                    onChange={(event) => this.standardHandler(event, comp)}
                                    disabled={this.state.userAddedComponents[comp].isLocked === 'locked'}
                                  >
                                    {this.state.userAddedComponents[comp].standard ? (
                                      <option value={this.state.userAddedComponents[comp].standard}>{this.state.userAddedComponents[comp].standard}</option>
                                    ) : (
                                      <option>--Select Standard--</option>
                                    )}

                                    {this.state.userAddedComponents[comp].component in this.state.configComponentsOptions &&
                                      this.state.configComponentsOptions[this.state.userAddedComponents[comp].component].map((option) => {
                                        return (
                                          <option value={option.Name} key={option.id}>
                                            {option.Name}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </td>
                                <td id="unique-62559733td_4">
                                  <button
                                    disabled={this.state.userAddedComponents[comp].deleteDisabled ? true : false}
                                    className="config-delete float-right"
                                    id="unique-11282342button_3"
                                    onClick={() => this.deleteComponent(index)}
                                  >
                                    <i className="fas fa-trash-alt fa-lg" id="unique-94053940i_2"></i>
                                  </button>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  createConfig,
  getConfigInfo,
  fetchAllProjects,
  fetchUsers,
})(CreateConfig)
