import React from 'react'
import Select, { components } from 'react-select'

export default (props) => {
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '')
    if ('getSearchValue' in props) {
      props.getSearchValue(inputValue)
    }
  }

  const CustomMultiValue = (customProps) => {
    return (
      <components.MultiValue {...customProps}>
        <div title={customProps.data.label} style={{ cursor: 'pointer' }}>
          {customProps.children}
        </div>
      </components.MultiValue>
    )
  }

  const selectComponents = props.no_title ? {} : { MultiValue: CustomMultiValue }

  return (
    <Select
      className={`basic-single ${'className' in props ? props.className : ''}`}
      classNamePrefix="select"
      isDisabled={props.isDisabled}
      isLoading={false}
      isClearable={'isClearable' in props ? props.isClearable : true}
      isRtl={false}
      isSearchable={true}
      closeMenuOnSelect={props.isMulti ? false : true}
      isMulti={props.isMulti}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      onInputChange={handleInputChange}
      components={selectComponents}
    />
  )
}
