import React from 'react'
import { Link } from 'react-router-dom'

import Account from './../Forms/Account'
import { connect } from 'react-redux'
import { fetchUserProducts, fetchProducts } from './../../store/actions'

import './Dashboard.css'

class Dashboard extends React.Component {
  state = {
    products: null,
    userProducts: null,
    role: null,
    page: '',
  }

  componentDidMount() {
    this.setState(
      {
        page: this.props.page,
        role: this.props.user.role,
      },
      this.fetchProductData
    )
  }

  componentDidUpdate(props) {
    if (props.page !== this.props.page) {
      if (this.props.page === 'home') {
        this.fetchProductData()
      }
      this.setState({ page: this.props.page }, this.renderPage)
    }
  }

  fetchProductData = async () => {
    await this.props.fetchProducts()
    await this.setState({ products: this.props.user.products })
    await this.props.fetchUserProducts()
    this.setState({ userProducts: this.props.user.userProducts })
  }

  renderProducts() {
    if (this.state.products && this.state.userProducts) {
      return this.state.products.map((product) => {
        let hidden = false
        if (this.props.user.user_actions.includes(`${product.name.toLowerCase()}_hidden`)) {
          hidden = true
        }

        let product_name = product.name
        if (product.name.includes('-Update')) {
          product_name = product.name.replace('-Update', '') + '<sup>Update</sup>'
        }

        let product_url = product.name !== 'iWorkflow' ? (product.name === 'iWorkflow-New' ? '/iworkflow-new' : `/pid/project-list?product=${product.name}`) : '/iworkflow'
        if (product.name === 'iDocuments-TradeDocuments') {
          product_url = `/idocuments-tradedocuments/project-list?product=${product.name}`
        } else if (product.name === 'iDocuments-Datasheet') {
          product_url = `/idocuments-datasheet/project-list?product=${product.name}`
        }

        if (this.state.userProducts.includes(product.id) && !hidden) {
          return (
            <div className={`col-md-3 col-sm-6`} key={product.id}>
              <Link id={product.name.toLowerCase() + '_product_link'} to={product_url}>
                <div className={`product-card bg-lightblue`} id="unique-cls57523012div_6">
                  <div className="meta" id="unique-meta57523012div_6">
                    <div className="name" id="unique-name57523012div_6">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product_name,
                        }}
                        id="unique-39153902span_1"
                      />
                    </div>
                    <div className="description" id="unique-34092442div_5">
                      {product.description}
                    </div>
                  </div>

                  <div className="logo" id="unique-11485490div_6">
                    <img src={product.logoUrl} width="90" alt="Logo" id="unique-36818740image_1" />
                  </div>
                </div>
              </Link>
            </div>
          )
        }
        return null
      })
    }

    return null
  }

  renderPage() {
    let create_project_hidden = this.props.user.user_actions.includes(`create-project_hidden`) ? true : false
    if (this.state.page === 'home') {
      return (
        <div className="dashboard main" id="unique-61059952div_13">
          <div className="mb-4 clear-fix" id="unique-11297863div_14">
            {!create_project_hidden ? (
              <Link id="general_create_project_link" to="/project-setup" className={`btn btn-secondary float-right`}>
                Create Project
              </Link>
            ) : null}
            <h3 id="unique-99802704h3_1">
              <b>Product Dashboard</b>
            </h3>
          </div>
          <div className="row" id="unique-42174161div_15">
            {this.renderProducts()}
          </div>
        </div>
      )
    } else if (this.state.page === 'account') {
      return <Account />
    }

    return null
  }

  render() {
    return <div id="unique-10812967div_16">{this.renderPage()}</div>
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { fetchUserProducts, fetchProducts })(Dashboard)
