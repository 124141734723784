import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { passwordStrength } from 'check-password-strength'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './Forms.css'
import Input from './Fields/input'
import { resetPassword, updatePassword } from './../../store/actions'

class ResetPassword extends React.Component {
  state = {
    email: '',
    token: '',
    password: '',
    confirm_password: '',
    action: 'generate',
    message: '',
    errorMessage: '',
    captcha: false,
    formLoader: false,
    forceResetMessage: this.props.user.is_forcereset,
  }

  formHandler = (e) => {
    e.preventDefault()
    if (this.state.action === 'update') {
      this.updatePassword()
    } else {
      this.resetPassword()
    }
  }

  resetPassword = async () => {
    await this.setState({
      message: '',
      errorMessage: '',
      formLoader: true,
    })
    const formData = new FormData()
    formData.append('email', this.state.email)
    formData.append('action', this.state.action)

    if (this.state.action === 'generate') {
      await this.props.resetPassword(formData)
      if (!this.props.user.displayMessageCode && !this.props.user.resetPassword.errorMessage) {
        this.setState({
          action: 'match',
          message: this.props.user.resetPassword.message,
        })
      } else if (this.props.user.resetPassword) {
        this.setState({
          errorMessage: this.props.user.resetPassword.errorMessage,
        })
      }
    } else {
      formData.append('token', this.state.token)
      await this.props.resetPassword(formData)
      if (!this.props.user.displayMessageCode && !this.props.user.resetPassword.errorMessage) {
        this.setState({
          action: 'update',
          message: this.props.user.resetPassword.message,
        })
      } else if (this.props.user.resetPassword) {
        this.setState({ errorMessage: this.props.user.resetPassword.errorMessage })
      }
    }

    await this.setState({ formLoader: false })
  }

  updatePassword = async () => {
    await this.setState({ errorMessage: '', message: '' })
    if (this.state.password !== this.state.confirm_password) {
      this.setState({
        errorMessage: 'Password and confirm password fields do not match!',
      })
      return
    }

    if (passwordStrength(this.state.password).value !== 'Strong') {
      this.setState({
        errorMessage: 'Password strength does not fullfill the required criteria!',
      })
      return
    }

    await this.setState({ formLoader: true })

    const formData = new FormData()
    formData.append('password', this.state.password)
    formData.append('email', this.state.email)
    formData.append('token', this.state.token)

    await this.props.updatePassword(formData)
    if (!this.props.user.displayMessageCode && !this.props.user.resetPassword.errorMessage) {
      await this.setState({
        message: this.props.user.resetPassword.message,
      })

      setTimeout(() => {
        this.props.history.push('/')
      }, 5000)
    } else if (this.props.user.resetPassword) {
      await this.setState({ errorMessage: this.props.user.resetPassword.errorMessage })
    }

    await this.setState({ formLoader: false })
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  ReCaptcha() {
    this.setState({ captcha: true })
  }

  render() {
    if (this.state.captcha) {
      return (
        <div className="form-container" id="unique-30618033div_1">
          <div className="form" id="unique-51039869div_2">
            <Link to="/" id="unique-29731005link_1">
              <div className="login-header text-center" id="unique-43423283div_3">
                <img src="ips-logo@3x.png" className="mb-4" alt="Logo" id="41333181" />
              </div>
            </Link>
            {this.state.errorMessage ? (
              <div className="alert alert-danger mb-3" id="unique-15419024div_4">
                {this.state.errorMessage}
              </div>
            ) : null}
            {this.state.message ? (
              <div className="alert alert-success mb-3" id="unique-63871841div_5">
                {this.state.message}
              </div>
            ) : null}

            {this.state.forceResetMessage ? (
              <div className="alert alert-warning mb-3" id="unique-27549063div_6">
                Your account requires a Password Reset. Forced by IPS Administration.
              </div>
            ) : null}
            <form onSubmit={this.formHandler} id="unique-98154561form_1">
              <div className="form-group" id="unique-59899700div_7">
                {this.state.action === 'generate' ? (
                  <Input type="email" name="email" placeholder="Email" disabled={!this.state.captcha} value={this.state.email} onChange={this.onChange} required={true} />
                ) : this.state.action === 'match' ? (
                  <Input
                    type="text"
                    name="token"
                    placeholder="Token"
                    disabled={!this.state.captcha}
                    value={this.state.token}
                    onChange={this.onChange}
                    required={true}
                    id="41333181"
                  />
                ) : (
                  <>
                    <div className="mb-2" id="unique-93716655div_8">
                      Password Rules:
                    </div>
                    <ul>
                      <li id="unique-25250800li_1">
                        Must contain 1 uppercase letter &nbsp;&nbsp;
                        {passwordStrength(this.state.password).contains.includes('uppercase') ? (
                          <i className="fa fa-check text-success" id="41333181" />
                        ) : (
                          <i className="fa fa-times text-danger" id="41333181" />
                        )}
                      </li>
                      <li id="unique-91072672li_2">
                        Must contain 1 lowercase letter &nbsp;&nbsp;
                        {passwordStrength(this.state.password).contains.includes('lowercase') ? (
                          <i className="fa fa-check text-success" id="41333181" />
                        ) : (
                          <i className="fa fa-times text-danger" id="41333181" />
                        )}
                      </li>
                      <li id="unique-90491247li_3">
                        Must contain 1 number &nbsp;&nbsp;
                        {passwordStrength(this.state.password).contains.includes('number') ? (
                          <i className="fa fa-check text-success" id="41333181" />
                        ) : (
                          <i className="fa fa-times text-danger" id="41333181" />
                        )}
                      </li>
                      <li id="unique-84179824li_4">
                        Must contain 1 symbol &nbsp;&nbsp;
                        {passwordStrength(this.state.password).contains.includes('symbol') ? (
                          <i className="fa fa-check text-success" id="41333181" />
                        ) : (
                          <i className="fa fa-times text-danger" id="41333181" />
                        )}
                      </li>
                      <li id="unique-20020641li_5">
                        Must be atleast of 10 characters in length &nbsp;&nbsp;
                        {passwordStrength(this.state.password).length >= 10 ? (
                          <i className="fa fa-check text-success" id="41333181" />
                        ) : (
                          <i className="fa fa-times text-danger" id="41333181" />
                        )}
                      </li>
                    </ul>

                    <div className="form-group">
                      <Input
                        label="New Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        disabled={!this.state.captcha}
                        onChange={this.onChange}
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        label="Confirm New Password"
                        type="password"
                        name="confirm_password"
                        value={this.state.confirm_password}
                        disabled={!this.state.captcha}
                        onChange={this.onChange}
                        required={true}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="form-group" id="unique-97102573div_11">
                <button className="btn btn-primary form-control" id="unique-11147063button_1">
                  {this.state.action === 'generate' ? 'Send Reset Token' : this.state.action === 'match' ? 'Confirm Token' : 'Update'}{' '}
                  {this.state.formLoader ? <i className="fa fa-spin fa-spinner" id="unique-68827291i_11"></i> : null}
                </button>
              </div>
            </form>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="screen-loader" id="unique-78925301div_12">
          <div id="loading" />
        </div>
        <GoogleReCaptcha onVerify={(token) => this.ReCaptcha(token)} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  resetPassword,
  updatePassword,
})(ResetPassword)
