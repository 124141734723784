import XLSX from 'xlsx'

export default (input_data, filename, sheetname) => {
  let excel_data = []
  const { columns, data, nestedHeaders } = input_data
  if (columns && columns.length) {
    const header = []
    for (const col of columns) {
      const { title } = col
      header.push(title)
    }
    excel_data.push(header)
  }
  excel_data = [...excel_data, ...data]

  const merge = []
  if (nestedHeaders) {
    const nested_row = []
    let r = 0
    let c = 0
    for (const row of nestedHeaders) {
      let row_data = []
      for (const header of row) {
        let { title, colspan } = header
        colspan = parseInt(colspan)
        const tmp = Array(colspan).fill('')
        tmp[0] = title
        merge.push({
          s: {
            r: r,
            c: c,
          },
          e: {
            r: r,
            c: c + colspan - 1,
          },
        })
        c += colspan
        row_data = [...row_data, ...tmp]
      }
      nested_row.push(row_data)
      r += 1
      c = 0
    }
    excel_data = [...nested_row, ...excel_data]
  }
  const ws = XLSX.utils.aoa_to_sheet(excel_data)
  ws['!merges'] = merge
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetname)
  XLSX.writeFile(wb, filename + '.xlsx')
}
