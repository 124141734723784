import React from 'react'
import { connect } from 'react-redux'

import JExcel from './jExcel.js'
import { base64ToArrayBuffer } from './utils.js'
import { fetchDeliverableData, downloadDeliverables } from './../../store/actions'

class Deliverables extends React.Component {
  state = {
    errorMessage: null,
    type: 'IUI',
    product: 'iDrawings-PID',
    allDrawings: [],
    loading: false,
    activeDrawingId: null,
    activeDrawingIndex: 1,
    toggleMultipleView: false,
    options: null,
    drawingNum: null,
    activeDeliverable: '',
    availableListOfDeliverables: [],
    cadOptions: false,
    cadFileDownloading: false,
    standardDeliverablesNavView: false,
    columnsNavView: false,
    columns: {},
  }

  componentDidMount = async () => {
    await this.setState({ product: this.props.product })
    this.fetchDeliverableData()
  }

  fetchDeliverableData = async () => {
    await this.setState({ loading: true })
    let formData = new FormData()
    formData.append('drawingList', this.props.selectedDrawings)
    formData.append('documentList', this.props.selectedDocuments)
    formData.append('type', this.state.type)
    formData.append('mode', 'tableData')
    formData.append('product', this.state.product)
    formData.append('projectId', this.props.projectId)
    await this.props.fetchDeliverableData(formData)

    if (!this.props.user.displayMessageCode) {
      await this.setState({
        allDrawings: this.props.user.deliverableData.deliverableData,
        columns: this.props.user.deliverableData.columns || {},
      })

      if (this.state.allDrawings.length > 0) {
        await this.setState({
          activeDrawingId: this.state.allDrawings[0].id,
        })
        await this.fetchListOfDeliverables()
        this.generateOptions()
      }
    }

    await this.setState({
      loading: false,
    })
  }

  downloadDeliverables = async (type) => {
    let { product } = this.state

    await this.setState({ cadFileDownloading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('type', type)
    formData.append('drawingList', this.props.selectedDrawings)
    formData.append('documentList', this.props.selectedDocuments)
    formData.append('product', product)
    await this.props.downloadDeliverables(formData)
    await this.setState({ cadFileDownloading: false })

    if (this.props.user.displayMessageCode) {
      return
    }

    if (this.props.user.downloadDeliverablesOutput.errorMessage) {
      this.setState({ errorMessage: this.props.user.downloadDeliverablesOutput.errorMessage })
    } else {
      let buffer = base64ToArrayBuffer(this.props.user.downloadDeliverablesOutput.fileData)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.props.user.downloadDeliverablesOutput.fileName) //or any other extension
      link.click()
    }
  }

  fetchListOfDeliverables() {
    let activeDeliverable = ''
    let availableListOfDeliverables = []
    this.state.allDrawings.forEach((drawing) => {
      for (var key in drawing.deliverableData) {
        if (key) {
          for (var deliverableOption in drawing.deliverableData[key]) {
            if (drawing.deliverableData[key][deliverableOption].length >= 0) {
              let index = -1
              for (var i in availableListOfDeliverables) {
                if (availableListOfDeliverables[i].option === deliverableOption) {
                  index = i
                }
              }

              if (index >= 0) {
                availableListOfDeliverables[index].size += drawing.deliverableData[key][deliverableOption].length
              } else {
                availableListOfDeliverables.push({
                  option: deliverableOption,
                  size: drawing.deliverableData[key][deliverableOption].length,
                })
              }
            }
          }
        }
      }
    })

    if (availableListOfDeliverables.length >= 0) {
      activeDeliverable = availableListOfDeliverables[0].option
    }
    this.setState({
      availableListOfDeliverables: availableListOfDeliverables,
      activeDeliverable: activeDeliverable,
    })
  }

  generateOptions = () => {
    let options = {}
    let data = []
    let columns = []
    let nestedHeaders = []
    if (!this.state.toggleMultipleView) {
      let index = this.state.allDrawings.findIndex((drawing) => drawing.id === this.state.activeDrawingId)
      let deliverableData = this.state.allDrawings[index].deliverableData
      for (const key in deliverableData) {
        this.setState({ drawingNum: key })
        for (const deliverableOption in deliverableData[key]) {
          if (this.state.activeDeliverable === deliverableOption) {
            let iterator = 0
            deliverableData[key][deliverableOption].forEach((dict) => {
              if (!(this.state.activeDeliverable in this.state.columns)) {
                return
              }

              let dataList = []
              this.state.columns[this.state.activeDeliverable].forEach((_key) => {
                // Extract the columnName from _key for better readability
                const columnName = _key.columnName
                if (_key.visible && columnName in dict) {
                  if (iterator === 0 && !columns.some((item) => item.title === columnName)) {
                    columns.push({
                      type: 'text',
                      title: columnName,
                      width: 200,
                    })
                  }

                  if (deliverableOption === 'Customer Connection List') {
                    // Check if the column name is 'RATING' or 'SIZE'
                    if (columnName === 'RATING' || columnName === 'SIZE') {
                      // Extract the rating and size values from the 'dict' object
                      let rating = dict['RATING']
                      let size = dict['SIZE']

                      // If the 'RATING' value contains a '#', prepend it with 'ASME'
                      if (columnName === 'RATING' && rating && rating.includes('#')) {
                        rating = 'ASME ' + rating
                      } else if (columnName === 'RATING' && rating === 'NPT') {
                        // If the 'RATING' is 'NPT', update 'rating' and 'size' accordingly
                        rating = 'Female'
                        size = 'NPT ' + size
                      }

                      // Add the appropriate value to the dataList based on the columnName
                      dataList.push(columnName === 'RATING' ? rating : size)
                    }
                  } else {
                    dataList.push(dict[_key.columnName])
                  }
                }
              })

              iterator += 1
              data.push(dataList)
            })
          }
        }
      }

      if (data.length) {
        options['nestedHeaders'] = nestedHeaders
        options['columns'] = columns
        options['includeHeadersOnDownload'] = true
        options['data'] = data
        options['columnDrag'] = true
      }

      this.setState({ options: options })
    } else {
      this.state.allDrawings.forEach((drawing, index) => {
        let deliverableData = drawing.deliverableData
        for (const key in deliverableData) {
          this.setState({ drawingNum: key })
          for (const deliverableOption in deliverableData[key]) {
            if (this.state.activeDeliverable === deliverableOption) {
              for (let i = 0; i < deliverableData[key][deliverableOption].length; i++) {
                let dict = deliverableData[key][deliverableOption][i]
                let dataList = []
                if (!(this.state.activeDeliverable in this.state.columns)) {
                  return
                }

                this.state.columns[this.state.activeDeliverable].forEach((_key) => {
                  // Extract the columnName from _key for better readability
                  const columnName = _key.columnName
                  if (_key.visible && columnName in dict) {
                    if (index === 0 && !columns.some((item) => item.title === columnName)) {
                      columns.push({
                        type: 'text',
                        title: columnName,
                        width: 200,
                      })
                    }

                    if (deliverableOption === 'Customer Connection List') {
                      // Check if the column name is 'RATING' or 'SIZE'
                      if (columnName === 'RATING' || columnName === 'SIZE') {
                        // Extract the rating and size values from the 'dict' object
                        let rating = dict['RATING']
                        let size = dict['SIZE']

                        // If the 'RATING' value contains a '#', prepend it with 'ASME'
                        if (columnName === 'RATING' && rating && rating.includes('#')) {
                          rating = 'ASME ' + rating
                        } else if (columnName === 'RATING' && rating === 'NPT') {
                          // If the 'RATING' is 'NPT', update 'rating' and 'size' accordingly
                          rating = 'Female'
                          size = 'NPT ' + size
                        }

                        // Add the appropriate value to the dataList based on the columnName
                        dataList.push(columnName === 'RATING' ? rating : size)
                      }
                    } else {
                      dataList.push(dict[_key.columnName])
                    }
                  }
                })

                data.push(dataList)
              }
            }
          }
        }
      })

      if (data.length) {
        options['nestedHeaders'] = nestedHeaders
        options['columns'] = columns
        options['includeHeadersOnDownload'] = true
        options['data'] = data
        options['columnDrag'] = true
      }
      this.setState({ options: options })
    }
  }

  nextDrawing = () => {
    let activeDrawingId = this.state.activeDrawingId
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === activeDrawingId)
    if (index > -1) {
      if (index + 1 === this.state.allDrawings.length) {
        this.changeActiveDrawing(this.state.allDrawings[0].id)
      } else {
        this.changeActiveDrawing(this.state.allDrawings[index + 1].id)
      }
    }
  }

  prevDrawing = () => {
    let activeDrawingId = this.state.activeDrawingId
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === activeDrawingId)
    if (index > -1) {
      if (index === 0) {
        this.changeActiveDrawing(this.state.allDrawings[this.state.allDrawings.length - 1].id)
      } else {
        this.changeActiveDrawing(this.state.allDrawings[index - 1].id)
      }
    }
  }

  changeActiveDrawing(id) {
    let index = this.state.allDrawings.findIndex((drawing) => drawing.id === id)
    this.setState(
      {
        activeDrawingId: id,
        activeDrawingIndex: index + 1,
        options: null,
      },
      this.generateOptions
    )
  }

  toggleMultipleView = () => {
    this.setState(
      {
        options: null,
        toggleMultipleView: !this.state.toggleMultipleView,
      },
      this.generateOptions
    )
  }

  changeActiveDeliverable(deliverable) {
    this.setState({ activeDeliverable: deliverable, options: null }, this.generateOptions)
  }

  renderListOfDeliverables = () => {
    return this.state.availableListOfDeliverables.map((deliverable) => {
      return (
        <div className="px-2 py-1" key={deliverable.option} id="unique-22221078div_1">
          <label
            id="unique-88214319label_1"
            className={`checkbox mr-3 ${this.state.activeDeliverable === deliverable.option ? 'checked' : ''} ${deliverable.size > 0 ? '' : 'disabled'}`}
            onClick={() => {
              if (deliverable.size > 0) {
                this.changeActiveDeliverable(deliverable.option)
              }
            }}
          />
          {deliverable.option}
        </div>
      )
    })
  }

  swapArrayLocs = (arr, index1, index2) => {
    var temp = arr[index1]
    arr[index1] = arr[index2]
    arr[index2] = temp
    return arr
  }

  changeOrder = (index, type) => {
    let columns = this.state.columns
    let arr = columns[this.state.activeDeliverable]
    if (type === 'up' && index > 0) {
      arr = this.swapArrayLocs(arr, index, index - 1)
    } else if (type === 'down' && index < arr.length - 1) {
      arr = this.swapArrayLocs(arr, index, index + 1)
    }
    columns[this.state.activeDeliverable] = arr
    this.setState({ columns: columns, options: null }, this.generateOptions)
  }

  renderListOfColumns = () => {
    if (this.state.activeDeliverable in this.state.columns) {
      let activeDeliverableColumns = this.state.columns[this.state.activeDeliverable]
      return activeDeliverableColumns.map((column, index) => {
        return (
          <div className="px-2 py-1 clearfix" key={column.columnName} id="unique-20394599div_2">
            <label
              className={`checkbox mr-2 ${column.visible ? 'checked' : ''}`}
              onClick={() => {
                let columns = this.state.columns
                columns[this.state.activeDeliverable][index].visible = !columns[this.state.activeDeliverable][index].visible
                this.setState({ columns: columns, options: null }, this.generateOptions)
              }}
            />
            <span className="d-inline-block text-truncate" style={{ width: '55%' }} id="unique-75039824span_1">
              {column.columnName}
            </span>
            <button className="btn btn-primary btn-sm float-right ml-1" onClick={() => this.changeOrder(index, 'up')}>
              <i className="fa fa-angle-up" id="60322946" />
            </button>
            <button className="btn btn-primary btn-sm float-right" onClick={() => this.changeOrder(index, 'down')}>
              <i className="fa fa-angle-down" id="60322946" />
            </button>
          </div>
        )
      })
    }

    return null
  }

  renderDrawingNum() {
    let drawing = this.state.allDrawings[this.state.activeDrawingIndex - 1]
    return drawing.b_Id
  }

  renderJExcel() {
    if (this.state.options && Object.keys(this.state.options).length) {
      return (
        <JExcel
          options={this.state.options}
          drawingNum={this.state.drawingNum}
          toggleMultipleView={this.state.toggleMultipleView}
          allDrawings={this.state.allDrawings}
          activeDrawingIndex={this.state.activeDrawingIndex}
          activeDeliverable={this.state.activeDeliverable}
        />
      )
    }
    return null
  }

  render() {
    let download_svg_hidden = false
    if (this.props.user.user_actions.includes(`deliverables_download-svg_hidden`)) {
      download_svg_hidden = true
    }

    if (this.state.loading) {
      return (
        <div className="deliverables" id="unique-10984516div_3">
          <div id="preload">
            <div className="sk-folding-cube">
              <div className="sk-cube1 sk-cube"></div>
              <div className="sk-cube2 sk-cube"></div>
              <div className="sk-cube4 sk-cube"></div>
              <div className="sk-cube3 sk-cube"></div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="deliverables" id="unique-61037918div_7">
        <div className="inner-body" id="unique-46925892div_8">
          <div className="content" id="unique-10717235div_9">
            <button onClick={this.props.closeDeliverableView} className="close custom">
              <i className="fa fa-times" />
            </button>

            {this.state.allDrawings ? (
              <div className="clearfix" id="unique-49996852div_10">
                <span className="float-right" id="unique-65674234span_4">
                  {!this.state.toggleMultipleView ? (
                    <>
                      <button className="btn custom" onClick={this.prevDrawing} id="unique-60726462button_3">
                        <i className="fa fa-angle-left" id="unique-51242701i_4"></i>
                      </button>
                      <button className="btn custom active-drawing" disabled id="unique-82722045button_4">
                        {this.state.activeDrawingIndex}/{this.state.allDrawings.length}
                      </button>
                      <button className="btn custom" onClick={this.nextDrawing} id="unique-68701095button_5">
                        <i className="fa fa-angle-right" id="unique-76350031i_5"></i>
                      </button>
                    </>
                  ) : null}
                </span>

                <button className="btn btn-primary float-right mr-2" onClick={this.toggleMultipleView} id="unique-11993572button_6">
                  {!this.state.toggleMultipleView ? (
                    <>
                      Multiple Files View &nbsp; <i className="far fa-copy" id="unique-12213541i_6"></i>
                    </>
                  ) : (
                    <>
                      Single File View &nbsp; <i className="far fa-file" id="unique-10149063i_7"></i>
                    </>
                  )}
                </button>

                <div className="dropdown float-right mr-2" id="unique-51080135div_11">
                  <button disabled={this.state.cadFileDownloading} className="btn btn-primary" type="button" onClick={() => this.setState({ cadOptions: !this.state.cadOptions })}>
                    CAD Interface{' '}
                    {this.state.cadOptions ? <i className="fa fa-caret-up" id="unique-11103704i_8"></i> : <i className="fa fa-caret-down" id="unique-47565800i_9"></i>}
                  </button>

                  {this.state.cadOptions ? (
                    <div className="dropdown-menu" id="unique-69598754div_12">
                      <button className="dropdown-item" disabled id="unique-24069602button_8">
                        AutoCAD
                      </button>
                      <button className="dropdown-item" onClick={() => this.downloadDeliverables('AutoCAD PID (Plant3D)')}>
                        AutoCAD PID (Plant3D)
                      </button>
                      {this.state.product !== 'iDrawings-Instrumentation' ? (
                        <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('SPPID')}>
                          SPPID
                        </button>
                      ) : null}
                      {this.state.product === 'iDrawings-Instrumentation' ? (
                        <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('SPI Sheet')}>
                          Download SPI Sheet
                        </button>
                      ) : null}
                      {this.state.product === 'iDrawings-Electrical' ? (
                        <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('SPEL Sheet')}>
                          Download SPEL Loading Sheet
                        </button>
                      ) : null}
                      <button className="dropdown-item" onClick={() => this.downloadDeliverables('AVEVA PID')}>
                        AVEVA PID
                      </button>
                      {!download_svg_hidden ? (
                        <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('SVG')}>
                          Download SVG
                        </button>
                      ) : null}
                      <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('LineTag Report')}>
                        Download LineTag Report
                      </button>
                      <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('Conflict Report')}>
                        Download Conflict Report
                      </button>
                      <button className={`dropdown-item`} onClick={() => this.downloadDeliverables('Topology Report')}>
                        Download Topology Report
                      </button>
                      <button className="dropdown-item" disabled id="unique-70326978button_18">
                        Bentley
                      </button>
                    </div>
                  ) : null}
                </div>

                <label className="drawing-switch float-right mr-2" id="unique-38217277label_3">
                  {this.state.type === 'IUI' ? (
                    <input type="checkbox" className="checked" onClick={() => this.setState({ type: 'AI' }, this.fetchDeliverableData)} />
                  ) : (
                    <input type="checkbox" onClick={() => this.setState({ type: 'IUI' }, this.fetchDeliverableData)} />
                  )}

                  <span className={'slider'} id="unique-14091684span_5">
                    {this.state.type === 'IUI' ? (
                      <>
                        <b className="text-left">AI Mode</b>
                        <b className="text-right text-white">IUI Mode</b>
                      </>
                    ) : (
                      <>
                        <b className="text-left text-white">AI Mode</b>
                        <b className="text-right">IUI Mode</b>
                      </>
                    )}
                  </span>
                </label>

                {/* Heading  */}

                {this.state.toggleMultipleView ? (
                  <h3 id="unique-81287655h3_1">{this.state.activeDeliverable}</h3>
                ) : this.state.allDrawings.length ? (
                  <h3 id="unique-10048181h3_2">
                    {this.state.allDrawings[this.state.activeDrawingIndex - 1].b_Id}_{this.state.activeDeliverable}
                  </h3>
                ) : null}
              </div>
            ) : null}

            {this.props.user.deliverableData.errorMessage || this.props.user.deliverableData.partialErrorMessage ? (
              <div className="alert alert-danger mt-3" id="unique-17910324div_13">
                <i className="fa fa-exclamation-triangle" id="unique-11408794i_10"></i> &nbsp;
                {this.props.user.deliverableData.errorMessage ? this.props.user.deliverableData.errorMessage : null}{' '}
                {this.props.user.deliverableData.partialErrorMessage ? this.props.user.deliverableData.partialErrorMessage : null}
              </div>
            ) : null}

            {this.state.errorMessage ? (
              <div className="alert alert-danger mt-3" id="unique-43153563div_14">
                <i className="fa fa-exclamation-triangle" id="60322946" /> {this.state.errorMessage}
                <button className="close" onClick={() => this.setState({ errorMessage: null })}>
                  &times;
                </button>
              </div>
            ) : null}

            {this.props.user.avevaXMLError ? (
              <div className="alert alert-danger mt-3" id="unique-69468905div_15">
                <i className="fa fa-exclamation-triangle" id="unique-84826537i_12"></i> &nbsp; {this.props.user.avevaXMLError}
              </div>
            ) : null}

            {this.props.user.deliverableDataError ? (
              <div className="alert alert-danger mt-3" id="unique-12533677div_16">
                <i className="fa fa-exclamation-triangle" id="unique-26336201i_13"></i> &nbsp; {this.props.user.deliverableDataError}
              </div>
            ) : (
              <div className="flex-container aside" id="unique-48790520div_17">
                <div className={this.state.standardDeliverablesNavView || this.state.columnsNavView ? 'left-flex' : 'left-flex-collapse'} id="unique-10301955div_18">
                  {!this.state.standardDeliverablesNavView ? (
                    <div id="turn-around">
                      <ol id="unique-46548806ol_1">
                        <li
                          onClick={() =>
                            this.setState({
                              standardDeliverablesNavView: true,
                            })
                          }
                        >
                          STANDARD DELIVERABLES &nbsp; <i className="fa fa-expand" id="60322946" />
                        </li>
                      </ol>
                    </div>
                  ) : (
                    <div className="components-wrapper p-2" id="unique-76264975div_19">
                      <div className="clearfix" id="unique-85263511div_20">
                        <button
                          className="close float-right"
                          onClick={() =>
                            this.setState({
                              standardDeliverablesNavView: false,
                            })
                          }
                        >
                          &times;
                        </button>
                        <h5 id="unique-11022391h5_1">STANDARD DELIVERABLES</h5>
                      </div>
                      <div className="body" id="unique-77212898div_21">
                        {this.renderListOfDeliverables()}
                      </div>
                    </div>
                  )}

                  <div className="mt-3" id="60322946" />

                  {!this.state.columnsNavView ? (
                    <div id="turn-around">
                      <ol id="unique-11933074ol_2">
                        <li
                          onClick={() =>
                            this.setState({
                              columnsNavView: true,
                            })
                          }
                        >
                          COLUMNS &nbsp; <i className="fa fa-expand" id="60322946" />
                        </li>
                      </ol>
                    </div>
                  ) : (
                    <div className="components-wrapper p-2" id="unique-11191654div_23">
                      <div className="clearfix" id="unique-84979059div_24">
                        <button
                          className="close float-right"
                          onClick={() =>
                            this.setState({
                              columnsNavView: false,
                            })
                          }
                        >
                          &times;
                        </button>
                        <h5 id="unique-10884145h5_2">COLUMNS</h5>
                      </div>
                      <div className="body" id="unique-73404151div_25">
                        {this.renderListOfColumns()}
                      </div>
                    </div>
                  )}
                </div>
                <div className="right-flex" id="unique-89821826div_26">
                  <div style={{ position: 'relative' }} id="unique-24122188div_27">
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                      }}
                      id="unique-55098501div_28"
                    >
                      {this.renderJExcel()}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  fetchDeliverableData,
  downloadDeliverables,
})(Deliverables)
