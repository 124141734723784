import React from 'react'
import { connect } from 'react-redux'
import Select from './../Forms/Fields/select'

import { fetchAllProducts, fetchEPCCompanies, fetchClient, updateClient } from '../../store/actions'

import './Forms.css'
import Input from './Fields/input'

class EditClient extends React.Component {
  state = {
    client_id: null,
    client_name: '',
    owner_name: '',
    epc_name: '',
    epc_id: '',
    products: [],
    epc_companies: [],
    epc_selection_mode: true,
    loading: false,
    allProducts: [],
    formLoader: false,
    errorMessage: null,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const client_id = parseInt(query.get('id'))
    this.setState({ client_id: client_id }, this.fetchData)
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    await this.props.fetchAllProducts()
    await this.props.fetchEPCCompanies()

    const formData = new FormData()
    formData.append('clientId', this.state.client_id)
    await this.props.fetchClient(formData)

    await this.setState({
      client_name: this.props.user.client.name,
      owner_name: this.props.user.client.owner_name,
      epc_id: this.props.user.client.epc_id,
      products: this.props.user.client.products,
      allProducts: this.props.user.allProducts,
      epc_companies: this.props.user.epc_companies,
      loading: false,
    })
  }

  editClientDataHandler = async (event) => {
    event.preventDefault()
    await this.setState({ formLoader: true })
    let { products, epc_id, epc_name, owner_name, client_name, client_id } = this.state
    products = products.map((product) => {
      return product.value
    })

    let formData = new FormData()
    formData.append('client_id', client_id)
    formData.append('client_name', client_name)
    formData.append('owner_name', owner_name)
    formData.append('epc_name', epc_name)
    formData.append('epc_id', epc_id)
    formData.append('products', products)

    await this.props.updateClient(formData)

    await this.setState({
      formLoader: false,
      errorMessage: this.props.user.createClientErrorMessage,
    })

    if (!this.props.user.createClientErrorMessage) {
      this.props.history.push(`/admin/clients`)
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderCreateClientForm() {
    return (
      <form onSubmit={(event) => this.editClientDataHandler(event)} className="card">
        <div className="clearfix card-header bg-light" id="unique-96438628div_1">
          <button type="submit" disabled={this.state.formLoader} className="btn btn-primary float-right" id="unique-10264110button_1">
            {this.state.formLoader ? 'Saving...' : 'Save'}
          </button>
          <h3 id="unique-11392093h3_1">Update Client</h3>
        </div>
        <div className="card-body" id="unique-41275128div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger alert-dismissible fade show mb-3" id="unique-94023247div_3">
              <strong id="unique-10305788strong_1">Opps!</strong> Something went wrong
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: '' })}>
                <span aria-hidden="true" id="unique-31406540span_1">
                  &times;
                </span>
              </button>
            </div>
          ) : null}

          <div className="row" id="unique-60433670div_4">
            <div className="col-md-5" id="unique-44283633div_5">
              <div className="form-group" id="unique-10368229div_6">
                <Input label="Client Name" type="text" value={this.state.client_name} name="client_name" onChange={this.onChange} required={true} id="64836505" />
              </div>

              <div className="form-group" id="unique-53315420div_7">
                <Input
                  label="Owner Name"
                  info={`If no owner, please put "Internal"`}
                  type="text"
                  name="owner_name"
                  value={this.state.owner_name}
                  onChange={this.onChange}
                  required={true}
                  id="64836505"
                />
              </div>

              <div className="form-group" id="unique-32993065div_8">
                <div className="clearfix mb-2" id="unique-75475772div_9">
                  <label id="unique-37093461label_1">EPC Name </label>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        epc_selection_mode: !this.state.epc_selection_mode,
                        epc_name: '',
                      })
                    }
                    className="btn btn-sm btn-primary float-right"
                  >
                    {this.state.epc_selection_mode ? 'Add' : 'Select'}
                  </button>
                </div>

                {this.state.epc_selection_mode ? (
                  <select
                    onChange={(event) =>
                      this.setState({
                        epc_id: event.target.value,
                      })
                    }
                    value={this.state.epc_id ? this.state.epc_id : ''}
                    className="form-control"
                  >
                    <option>--Select--</option>
                    {this.state.epc_companies.map((company) => {
                      return (
                        <option key={company.id} value={company.id}>
                          {company.Name}
                        </option>
                      )
                    })}
                  </select>
                ) : (
                  <Input type="text" name="epc_name" value={this.state.epc_name} onChange={this.onChange} required={false} id="64836505" />
                )}
              </div>
            </div>

            <div className="col-md-7" id="unique-34330874div_10">
              <div className="form-group" id="unique-97694703div_11">
                <label id="unique-24025842label_2">
                  Products{' '}
                  <span className="small text-muted" id="unique-41186463span_2">
                    (*)
                  </span>
                </label>
                {this.renderProductForm()}
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderProductForm = () => {
    let { allProducts, products } = this.state

    return (
      <Select
        options={allProducts}
        value={products}
        onChange={(selectedProducts) => {
          this.setState({ products: selectedProducts })
        }}
        isMulti={true}
        isDisabled={!allProducts.length}
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-60538638div_12">
          <div id="loading" />
        </div>
      )
    }
    return (
      <div className="dashboard admin" id="unique-60150898div_13">
        {this.renderCreateClientForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchAllProducts,
  fetchEPCCompanies,
  fetchClient,
  updateClient,
})(EditClient)
