import React, { Component } from 'react'

import Input from '../Fields/input'
import Select from '../Fields/select'
import Textarea from '../Fields/textarea'
import LinkedProjectModalBox from './LinkProjectModalBox'

export default class ProjectSetupS1 extends Component {
  state = {
    clients: [],
    ips_project_name: '',
    ips_project_description: '',
    ips_project_number: '',
    ips_project_scope_of_work: '',
    project_mode: null,
    selectedUsers: [],
    group_members: [],
    selected_products: [],
    selectedClient: '',
    errorMessage: null,
    ShowAdminUsers: false,
    linked: false,
    linkedWarningMessage: false,
    loading: false,
    formLoader: false,
    exit: false,
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    await this.props.fetchUserClientAllData()
    await this.setState({
      clients: this.props.user.userClientsAllData,
    })

    if (this.props.editProjectId) {
      this.fetchData()
    } else {
      await this.setState({ loading: false })
    }
  }

  fetchData = async () => {
    let formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.setState({ loading: true })
    await this.props.fetchSingleProject(formData)
    await this.setState({
      loading: false,
      selectedClient: this.props.user.singleProjectData.clientIds,
      selectedUsers: this.props.user.singleProjectData.userIds,
      selected_products: this.props.user.singleProjectData.selected_products,
      ips_project_name: this.props.user.singleProjectData.displayName,
      ips_project_description: this.props.user.singleProjectData.description,
      ips_project_number: this.props.user.singleProjectData.projectNumber,
      project_mode: this.props.user.singleProjectData.project_mode,
      ips_project_scope_of_work: this.props.user.singleProjectData.projectScope ? this.props.user.singleProjectData.projectScope : '',
      group_members: this.props.user.singleProjectData.group_members,
      screen_blocked: this.props.user.singleProjectData.screen_blocked ? this.props.user.singleProjectData.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (e) => {
    if (e) {
      e.preventDefault()
    }
    const formData = new FormData()
    formData.append('projectDisplayName', this.state.ips_project_name)
    formData.append('description', this.state.ips_project_description)
    formData.append('totalSpace', 0.5)
    formData.append('usedSpace', 0)
    formData.append('selected_products', JSON.stringify(this.state.selected_products))
    formData.append('clientId', JSON.stringify(this.state.selectedClient))
    formData.append('project_mode', JSON.stringify(this.state.project_mode))
    formData.append('clone', false)
    formData.append('projectNumber', this.state.ips_project_number)
    formData.append('projectScope', this.state.ips_project_scope_of_work)
    formData.append('step', 1)
    formData.append('users', JSON.stringify(this.state.selectedUsers))
    formData.append('linked', this.state.linked)

    await this.setState({ formLoader: true })
    if (this.props.editProjectId) {
      formData.append('projectId', this.props.editProjectId)
      await this.props.updateProject(formData)
    } else {
      await this.props.createProject(formData)
      this.props.setProjectId(this.props.user.createdProjectId)
    }

    await this.setState({ formLoader: false })

    if (this.state.exit) {
      this.props.closeProjectSetup()
    } else {
      this.props.changeStep(this.props.user.projectSetupStep)
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  toggleLink = () => {
    this.setState({ linked: !this.state.linked })
  }

  renderForm() {
    return (
      <form id="unique-51436946form_1" onSubmit={(e) => this.formDataHandler(e)} className="card">
        <div className="clearfix card-header bg-light" id="unique-93396870div_1">
          <button
            type={this.state.group_members.length ? 'button' : 'submit'}
            id="unique-62716347button_1"
            onClick={() => {
              this.setState({ exit: false })
              if (this.state.group_members.length) {
                this.setState({ linkedWarningMessage: true })
              }
            }}
            disabled={
              this.state.formLoader ||
              (!this.state.project_mode && this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1) ||
              !this.state.selectedUsers.length ||
              !this.state.selected_products.length ||
              !this.state.selectedClient
            }
            className="btn btn-primary float-right"
          >
            Save & Next
          </button>

          <button
            type="button"
            id="unique-68057674button_2"
            onClick={() => this.setState({ exit: true }, this.formDataHandler)}
            disabled={
              this.state.formLoader ||
              (!this.state.project_mode && this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1) ||
              !this.state.selectedUsers.length ||
              !this.state.selected_products.length ||
              !this.state.selectedClient
            }
            className="btn btn-primary float-right mr-2"
          >
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-47966940button_3">
            Cancel
          </button>

          <h3 id="unique-38241849h3_1">
            {this.props.editProjectId ? 'Edit' : 'Create'} Project {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="unique-44209225i_1" /> : null}
          </h3>
        </div>
        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-11362138div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger" id="unique-81515624div_3">
              {this.state.errorMessage}
              <button type="button" className="close" id="unique-13734926button_4" onClick={() => this.setState({ errorMessage: null })}>
                {' '}
                &times;{' '}
              </button>
            </div>
          ) : null}

          {this.state.linkedWarningMessage && this.state.group_members.length ? (
            <LinkedProjectModalBox toggleLink={this.toggleLink} linked={this.state.linked} group_members={this.state.group_members} />
          ) : null}

          <div className="row" id="unique-31858400div_4">
            <div className="col-md-6" id="unique-10117498div_5">
              <div className="row" id="unique-14752922div_6">
                <div className="form-group col" id="unique-10204745div_7">
                  <Input
                    label="Project Name"
                    type="text"
                    value={this.state.ips_project_name}
                    name="ips_project_name"
                    disallowedSymbols={['@', '.', '"']}
                    onChange={this.onChange}
                    required={true}
                    id="unique-11226142input_1"
                  />
                </div>
                <div className="form-group col" id="unique-32094569div_8">
                  <Input
                    label="Project Number"
                    type="text"
                    value={this.state.ips_project_number}
                    name="ips_project_number"
                    onChange={this.onChange}
                    required={true}
                    id="unique-45442718input_2"
                  />
                </div>
              </div>
              <div className="form-group" id="id0002-div">
                <Textarea
                  name="ips_project_description"
                  label="Project Description"
                  value={this.state.ips_project_description}
                  onChange={this.onChange}
                  maxLength={250}
                  rows={4}
                  required={true}
                />
              </div>

              <div className="form-group" id="div-002101">
                <Textarea
                  name="ips_project_scope_of_work"
                  label="Project Scope Of Work"
                  value={this.state.ips_project_scope_of_work}
                  onChange={this.onChange}
                  maxLength={250}
                  rows={4}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6" id="unique-71502435div_11">
              <div className="form-group" id="unique-62065663div_12">
                <label id="unique-70272067label_3">
                  Assign {`${this.props.product === 'iDocuments-TradeDocuments' ? 'Agent' : 'Client'}`}{' '}
                  <span className="small text-muted" id="unique-69771116span_7">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.state.clients}
                  value={this.state.selectedClient}
                  onChange={(selectedClient) => {
                    this.setState({
                      selectedClient: selectedClient,
                      selected_products: [],
                      selectedUsers: [],
                    })
                  }}
                  isDisabled={this.props.editProjectId ? true : false}
                  isMulti={false}
                />
              </div>
              <div className="form-group" id="unique-53398583div_13">
                <label id="unique-13541685label_4">
                  Assign Product{' '}
                  <span className="small text-muted" id="unique-87946876span_8">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.productList()}
                  value={this.state.selected_products}
                  onChange={(selectedProducts) => {
                    this.setState({ selected_products: selectedProducts })
                  }}
                  isDisabled={this.props.editProjectId || !this.state.selectedClient}
                  isMulti={true}
                />
              </div>
              {this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1 ? (
                <div className="form-group" id="unique-15039588div_14">
                  <label id="unique-26311052label_5">
                    Project Mode{' '}
                    <span className="small text-muted" id="unique-67923881span_9">
                      (*)
                    </span>
                  </label>
                  <Select
                    options={[
                      { label: 'Digitization', value: 'Digitization' },
                      { label: 'SPPID to Aveva', value: 'SPPID to Aveva' },
                    ]}
                    value={this.state.project_mode}
                    onChange={(selectedMode) => {
                      this.setState({ project_mode: selectedMode })
                    }}
                    isDisabled={this.props.editProjectId || !this.state.selectedClient}
                    isMulti={false}
                  />
                </div>
              ) : null}

              <div className="form-group" id="unique-41771921div_15">
                <div className="clearfix" id="unique-83407554div_16">
                  <label id="unique-26481899label_6">
                    Assign Users{' '}
                    <span className="small text-muted" id="unique-22588163span_10">
                      (*)
                    </span>
                  </label>
                  <span className="d-inline-block float-right" id="unique-34337300span_11">
                    <label
                      id="unique-26379902label_7"
                      onClick={() =>
                        this.setState({
                          ShowAdminUsers: !this.state.ShowAdminUsers,
                        })
                      }
                      className={`${this.state.ShowAdminUsers ? 'checked' : ''} checkbox`}
                    />{' '}
                    Show Admin Users
                  </span>
                </div>
                <Select
                  options={this.listUsers()}
                  value={this.state.selectedUsers}
                  onChange={(selectedList) => {
                    this.setState({ selectedUsers: selectedList })
                  }}
                  isDisabled={!this.state.selectedClient}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  productList = () => {
    if (!this.state.selectedClient) {
      return
    }
    let product_list = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())
    if (index > -1) {
      if (this.props.product) {
        this.state.clients[index].products.forEach((product) => {
          if (this.props.product === product.Product_id__Name) {
            product_list.push(product)
          }
        })
      } else {
        this.state.clients[index].products.forEach((product) => {
          if (!['iworkflow', 'iworkflow-new'].includes(product.Product_id__Name.toLowerCase())) {
            product_list.push(product)
          }
        })
      }
    }
    return product_list
  }

  listUsers() {
    if (!this.state.selectedClient) {
      return
    }
    let users = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())

    if (index > -1) {
      users = this.state.clients[index].users
      if (!this.state.ShowAdminUsers) {
        users = users.filter((user) => !user.User_id__Default_role.split('-')[user.User_id__Default_role.split('-').length - 1].toLowerCase().includes('admin'))
      }
    }
    return users
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-47617011div_17">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
