import React, { Component } from 'react'

import { PagingState, CustomPaging, SelectionState, IntegratedSelection, SortingState } from '@devexpress/dx-react-grid'

import {
  ColumnChooser,
  Grid,
  TableHeaderRow,
  PagingPanel,
  VirtualTable,
  TableColumnVisibility,
  DragDropProvider,
  TableColumnResizing,
  TableSelection,
  TableFixedColumns,
  TableColumnReordering,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

export default class ProjectTable extends Component {
  state = {
    toggled_actions: '',
    deleteFileId: null,

    data: [],
    columns: [],
    sortingStateColumnExtensions: [{ columnName: 'actions', sortingEnabled: false }],
    _process_selection: [],
    additionalColumns: this.props.additionalColumns ? this.props.additionalColumns : [],
    columnWidths: [],
    defaultHiddenColumnNames: [],
    tableColumnVisibilityColumnExtensions: [],
    columnOrder: [],
  }

  componentDidMount = async () => {
    this.setState({
      _process_selection: JSON.parse(JSON.stringify(this.props.process_selection)),
      data: this.props.tableList,
    })
    await this.setColumns()
    await this.setInitialColumnWidths()
  }

  componentDidUpdate = async (props) => {
    if (this.props.loading !== props.loading || this.props.process_selection.length !== this.state._process_selection.length) {
      await this.setState({
        _process_selection: JSON.parse(JSON.stringify(this.props.process_selection)),
        data: this.props.tableList,
      })
      await this.setColumns()
      await this.setInitialColumnWidths()
    }
  }

  getRowId = (row) => row.id

  setColumns = async () => {
    let { defaultHiddenColumnNames, columnOrder } = this.state

    let columns = []
    let sortingStateColumnExtensions = []
    let tableColumnVisibilityColumnExtensions = []

    this.props.columns.forEach((column) => {
      if (!column.name) {
        return
      }

      if (!column.allowSort) {
        sortingStateColumnExtensions.push({
          columnName: column.name,
          sortingEnabled: false,
        })
      }

      if (!column.visible && !defaultHiddenColumnNames.includes(column.name)) {
        defaultHiddenColumnNames.push(column.name)
      }

      if (['actions', 'name', 'status'].includes(column.name)) {
        tableColumnVisibilityColumnExtensions.push({ columnName: column.name, togglingEnabled: false })
      } else {
        if (!columnOrder.includes(column.name)) {
          columnOrder.push(column.name)
        }
      }

      columns.push({
        name: column.name,
        title: column.header,
        getCellValue: (row) => {
          if (column.name === 'status') {
            return this.getStatus(row)
          }

          if (column.name === 'actions') {
            return this.getActions(row)
          }

          return (
            <div
              className="cursor-pointer"
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                lineHeight: '38px',
                height: '38px',
              }}
              title={column.name === 'name' ? row[column.name] : column.name in row.title ? row.title[column.name] : null}
            >
              {column.name === 'name' ? (
                <u onClick={() => this.props.viewDocument(row)} className="cursor-pointer">
                  {row[column.name]}
                </u>
              ) : (
                row[column.name]
              )}
            </div>
          )
        },
      })
    })

    await this.setState({
      columns: columns,
      sortingStateColumnExtensions: sortingStateColumnExtensions,
      defaultHiddenColumnNames: defaultHiddenColumnNames,
      tableColumnVisibilityColumnExtensions: tableColumnVisibilityColumnExtensions,
      columnOrder: columnOrder,
    })
  }

  setInitialColumnWidths = async () => {
    let columnWidths = []

    this.props.columns.forEach((column) => {
      columnWidths.push({
        columnName: column.name,
        width: 'width' in column ? column.width : column.name === 'status' ? 100 : column.name === 'actions' ? 120 : column.name === 'name' ? 320 : 180,
      })
    })

    await this.setState({
      columnWidths: columnWidths,
    })
  }

  getActions = (row) => {
    let { product } = this.props
    let view_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_view_hidden`)
    let delete_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_delete_hidden`)
    let mark_approved_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-approved_hidden`)
    let mark_reviewed_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-reviewed_hidden`)
    let mark_cleaned_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-cleaned_hidden`)
    let check_in_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_check-in_hidden`)

    return (
      <React.Fragment>
        <div className="dropdown d-inline-block">
          <button
            className={`btn ${this.state.toggled_actions === row.id ? 'text-primary' : 'text-secondary'}`}
            onClick={async (e) => {
              e.stopPropagation()
              if (this.state.toggled_actions === row.id) {
                await this.setState({ toggled_actions: '' })
              } else {
                await this.setState({
                  toggled_actions: row.id,
                })
              }
              this.setColumns()
            }}
          >
            <i className="fa fa-ellipsis-v" />
          </button>

          {this.state.toggled_actions === row.id ? (
            <div className="dropdown-menu dropdown-menu-left">
              {!view_hidden ? (
                <button className={`dropdown-item`} onClick={() => this.props.viewDocument(row)}>
                  View
                </button>
              ) : null}

              <div className="dropdown-divider" />
              {!mark_cleaned_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowCleaned}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(!row.cleaned, undefined, undefined, undefined)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.cleaned ? 'checked' : ''}`} /> Mark Cleaned
                </button>
              ) : null}
              {!mark_reviewed_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowReviewed}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(undefined, undefined, undefined, !row.reviewed)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.reviewed ? 'checked' : ''}`} /> Mark Reviewed
                </button>
              ) : null}
              {!mark_approved_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowApproved}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(undefined, !row.approved, undefined, undefined)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.approved ? 'checked' : ''}`} /> Mark Approved
                </button>
              ) : null}
              {!check_in_hidden ? (
                <>
                  <div className="dropdown-divider" />
                  <button
                    className="dropdown-item"
                    disabled={!row.processed || !row.allowCheckInCheckOut}
                    onClick={async () => {
                      await this.props.setSelection([row.id])
                      this.props.updateDocumentStatus(undefined, undefined, !row.checkIn, undefined)
                    }}
                  >
                    {row.checkIn ? 'Check Out' : 'Check In'}
                  </button>
                </>
              ) : null}

              {!delete_hidden ? (
                <>
                  <div className="dropdown-divider" />
                  <button
                    className={`dropdown-item text-danger`}
                    onClick={() =>
                      this.setState({
                        deleteFileId: row.id,
                      })
                    }
                  >
                    Delete
                  </button>
                </>
              ) : null}
            </div>
          ) : null}
        </div>

        {!row.checkIn ? (
          <span className="badge badge-danger badge-sm badge-pill" title="Checked Out" style={{ lineHeight: '12px' }}>
            <i className="fa fa-angle-down" />
          </span>
        ) : null}
      </React.Fragment>
    )
  }

  getStatus = (row) => {
    if (row.ongoingProcessing && this.props.process_selection.includes(row.id)) {
      return (
        <div className="spinner-grow text-secondary" title="Ongoing Processing">
          <span className="sr-only">Processing...</span>
        </div>
      )
    } else if (row.error) {
      return <i className="fa fa-circle text-danger" title="Error" />
    } else if (row.approved) {
      return <i className="fa fa-check-circle" style={{ color: '#946db7' }} title="Approved" />
    } else if (row.ongoingReviewing) {
      return (
        <div className="spinner-grow" style={{ color: '#946db7' }} title="Ongoing Reviewing">
          <span className="sr-only">Ongoing Reviewing...</span>
        </div>
      )
    } else if (row.reviewed) {
      return <i className="fa fa-circle" style={{ color: '#946db7' }} title="Reviewed" />
    } else if (row.cleaned) {
      return <i className="fa fa-circle" style={{ color: '#0190b8' }} title="Cleaned" />
    } else if (row.ongoingCleaning) {
      return (
        <div className="spinner-grow" style={{ color: '#0190b8' }} title="Ongoing Cleaning">
          <span className="sr-only">Ongoing Cleaning...</span>
        </div>
      )
    } else if (row.processed) {
      return <i className="fa fa-circle text-success" title="Processed" />
    } else {
      return <i className="fa fa-circle text-secondary" title="Not Processed" />
    }
  }

  setColumnWidths = (columnWidths) => {
    this.setState({ columnWidths: columnWidths })
  }

  setColumnOrder = (order) => {
    this.setState({ columnOrder: order })
  }

  handleColumnVisibilityChange = (hiddenColumnNames) => {
    this.setState({ defaultHiddenColumnNames: hiddenColumnNames })
  }

  render() {
    let { columns, data, sortingStateColumnExtensions, columnWidths, _process_selection, defaultHiddenColumnNames, columnOrder, tableColumnVisibilityColumnExtensions } = this.state
    let { totalCount, pageSize, currentPage, pageSizes, selection, sorting, process_selection_length, leftColumns } = this.props
    leftColumns = [TableSelection.COLUMN_TYPE, ...leftColumns]

    return (
      <React.Fragment>
        {_process_selection.length ? (
          <div className="process-minimized">
            <span>
              Processing Document {process_selection_length - _process_selection.length + 1} of {process_selection_length}
            </span>
            <i className="fa fa-times" onClick={this.props.stopRequests} />
          </div>
        ) : null}

        {this.state.deleteFileId ? (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              this.setState({ deleteFileId: null })
              this.props.deleteDocument(this.state.deleteFileId)
            }}
          >
            <div className="modal">
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" onClick={() => this.setState({ deleteFileId: null })}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Do you really want to delete this file?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" value="Submit" className="btn btn-secondary">
                      Delete
                    </button>
                    <button type="button" className="btn btn-default" onClick={() => this.setState({ deleteFileId: null })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        <div className={'table-container-wrapper overflow-visible'} onClick={() => this.setState({ toggled_actions: '' }, this.setColumns)}>
          <div className="table-container p-0">
            <div className="card datasheet-grid">
              <Grid columns={columns} rows={data} getRowId={this.getRowId}>
                <DragDropProvider />
                <PagingState currentPage={currentPage} onCurrentPageChange={this.props.setCurrentPage} pageSize={pageSize} onPageSizeChange={this.props.setPageSize} />
                <SortingState sorting={sorting} onSortingChange={this.props.setSorting} columnExtensions={sortingStateColumnExtensions} />
                <SelectionState selection={selection} onSelectionChange={this.props.setSelection} />
                <CustomPaging totalCount={totalCount} />
                <PagingPanel pageSizes={pageSizes} />
                <VirtualTable />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.setColumnWidths} />
                <TableColumnReordering order={columnOrder} onOrderChange={this.setColumnOrder} />
                <TableHeaderRow showSortingControls />
                <IntegratedSelection />
                <TableSelection showSelectAll />
                <TableColumnVisibility
                  defaultHiddenColumnNames={defaultHiddenColumnNames}
                  onHiddenColumnNamesChange={this.handleColumnVisibilityChange}
                  columnExtensions={tableColumnVisibilityColumnExtensions}
                />

                <TableFixedColumns
                  leftColumns={leftColumns}
                  // rightColumns={rightColumns}
                />
                <Toolbar />
                <ColumnChooser />
              </Grid>
              {this.props.loading ? (
                <div className="card-loader">
                  <div id="loading" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
