import React, { Component } from 'react'
import Moment from 'react-moment'

import Select from '../Forms/Fields/select'
import Input from '../Forms/Fields/input'
import Textarea from '../Forms/Fields/textarea'

import { SortingState, IntegratedSorting, PagingState, SearchState, IntegratedPaging, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid'
import { Grid, VirtualTable, Toolbar, TableHeaderRow, PagingPanel, TableColumnResizing, TableFilterRow, SearchPanel } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

export default class MasterList extends Component {
  state = {
    columns: [],
    rows: [],
    columnExtensions: [
      {
        columnName: 'lastModifiedDate',
        filteringEnabled: false,
        sortingEnabled: false,
      },
      {
        columnName: 'actions',
        filteringEnabled: false,
        sortingEnabled: false,
      },
    ],
    columnWidths: [
      { columnName: 'displayName', width: 260 },
      { columnName: 'reportType', width: 260 },
      { columnName: 'lastModifiedDate', width: 180 },
      { columnName: 'lastModifiedBy', width: 180 },
      { columnName: 'isPublished', width: 180 },
      { columnName: 'actions', width: 460 },
    ],

    sorting: [],
    filters: [],

    //  Create Report Form
    create_report_obj: null,
    display_name: '',
    create_des: '',
    projectIds: [],

    creatingReport: false,
    createAndEdit: false,
  }

  componentDidMount = async () => {
    await this.fetchMasterList()
  }

  fetchMasterList = async () => {
    await this.props.setLoading(true)
    let { filters, sorting } = this.state
    const formData = new FormData()
    formData.append('filters', JSON.stringify(filters))
    formData.append('searchValue', '')
    formData.append('sorting', JSON.stringify(sorting))
    await this.props.fetchMasterList(formData)
    await this.props.setLoading(false)

    let rows = []
    if (!this.props.user.displayMessageCode) {
      this.props.user.master_list_data.masterlist.forEach((item) => {
        rows.push({
          id: item.id,
          displayName: item.displayName,
          reportType: item.reportType,
          lastModifiedDate: <Moment date={item.lastModifiedDate} format="DD-MMM-YYYY hh:mm" />,
          lastModifiedBy: item.lastModifiedBy,
          actions: this.getActions(item),
        })
      })

      await this.setState({ rows: rows })
      await this.setColumns()
    }
  }

  setColumns = async () => {
    await this.setState({
      columns: [
        {
          name: 'displayName',
          title: 'Report Name',
        },
        {
          name: 'reportType',
          title: 'Categories',
        },
        {
          name: 'lastModifiedDate',
          title: 'Last Modified',
        },
        {
          name: 'lastModifiedBy',
          title: 'Modified by',
        },
        { name: 'actions', title: 'Action' },
      ],
    })
  }

  getActions = (item) => {
    let { product } = this.props
    let create_client_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_create-client-report_hidden`) ? true : false

    if (create_client_report_hidden) {
      return null
    }

    return (
      <button
        id={`create_client_report_${item.id}_action_btn`}
        className="btn btn-sm text-secondary"
        onClick={() =>
          this.setState({
            create_report_obj: item,
          })
        }
      >
        <u>Create Client Report</u>
      </button>
    )
  }

  createClientReport = async (e) => {
    e.preventDefault()
    await this.setState({ creatingReport: true })
    let formData = new FormData()
    formData.append('projectIds', JSON.stringify(this.state.projectIds))
    formData.append('display_name', this.state.display_name)
    formData.append('report_type', this.state.create_report_obj.reportType)
    formData.append('parent_report', this.state.create_report_obj.id)
    formData.append('description', this.state.create_des)

    await this.props.createClientReport(formData)
    await this.setState({ creatingReport: true })

    if (!this.props.user.displayMessageCode) {
      this.setState({
        create_report_obj: null,
        display_name: '',
        create_des: '',
        projectIds: [],
      })
    }

    if (this.state.createAndEdit) {
      this.edit_jumpt_to(this.props.user.report_id)
    }
  }

  edit_jumpt_to = (id) => {
    this.props.history.push({
      pathname: '/iworkflow-new/report-edit',
      search: 'reportId=' + id,
    })
  }

  setSorting = (sorting) => {
    this.setState({ sorting: sorting })
  }

  setFilters = (filters) => {
    this.setState({ filters: filters })
  }

  getRowId = (row) => row.id

  onChange = (obj) => {
    this.setState(obj)
  }

  render() {
    let { columns, rows, columnWidths, sorting, filters, columnExtensions } = this.state
    const pageSizes = [5, 10, 15]
    return (
      <React.Fragment>
        {this.state.create_report_obj ? (
          <form id="create_client_report_form" className="modal" onSubmit={this.createClientReport}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Create Client {this.state.create_report_obj.displayName}</h5>
                  <button
                    id="create_client_report_close_btn"
                    type="button"
                    className="close"
                    onClick={() =>
                      this.setState({
                        create_report_obj: null,
                        projectIds: [],
                      })
                    }
                  >
                    <span aria-hidden="true" id="unique-83011609span_1">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body" style={{ height: '26rem', overflowY: 'auto' }} id="unique-30568373div_4">
                  <div className="form-group" id="unique-92750611div_5">
                    <div className="clearfix" id="unique-11093099div_6">
                      <span className="float-right" id="unique-80155260span_2">
                        <label
                          id="unique-73696374label_1"
                          className={`checkbox ${this.state.projectIds.length === this.props.user.master_list_data.projects.length ? 'checked' : ''} mr-2`}
                          onClick={() => {
                            if (this.state.projectIds.length === this.props.user.master_list_data.projects.length) {
                              this.setState({
                                projectIds: [],
                              })
                            } else {
                              this.setState({
                                projectIds: this.props.user.master_list_data.projects,
                              })
                            }
                          }}
                        />
                        Select All
                      </span>
                      <label id="unique-32369807label_2">Project</label>
                    </div>
                    <Select
                      options={this.props.user.master_list_data.projects}
                      onChange={(selectedList) => {
                        this.setState({ projectIds: selectedList })
                      }}
                      isMulti={true}
                      value={this.state.projectIds}
                    />
                  </div>
                  <div className="form-group" id="unique-10032630div_7">
                    <label id="unique-78179909label_3">
                      Name{' '}
                      <span className="small text-muted" id="unique-79640269span_3">
                        (Mandatory *)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          display_name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group" id="unique-58425315div_8">
                    <label id="unique-89898638label_4">Description</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={(e) =>
                        this.setState({
                          create_des: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="modal-footer" id="unique-11288636div_9">
                  <button
                    id="create_client_report_cancel_btn"
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      this.setState({
                        create_report_obj: null,
                        projectIds: [],
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button id="create_client_report_save_btn" type="submit" className="btn btn-primary" disabled={this.state.creatingReport}>
                    Save
                  </button>
                  <button
                    id="create_client_report_save_and_edit_btn"
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.creatingReport}
                    onClick={() => this.setState({ createAndEdit: true })}
                  >
                    Save & Edit
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        <div className="iworkflow" id="unique-64867524div_10">
          <div className="report-container-wrapper" id="unique-46109278div_11">
            <div className="report-container" id="unique-10994079div_12">
              <Grid rows={rows} columns={columns} getRowId={this.getRowId}>
                <SortingState sorting={sorting} onSortingChange={this.setSorting} columnExtensions={columnExtensions} />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                <IntegratedPaging />
                <SearchState />
                <FilteringState filters={filters} onFiltersChange={this.setFilters} columnExtensions={columnExtensions} />
                <IntegratedFiltering />

                <VirtualTable />
                <TableColumnResizing
                  columnWidths={columnWidths}
                  onColumnWidthsChange={(columnWidths) =>
                    this.setState({
                      columnWidths: columnWidths,
                    })
                  }
                />
                <TableHeaderRow showSortingControls />
                <Toolbar />
                <SearchPanel />
                <TableFilterRow showFilterSelector />
                <PagingPanel pageSizes={pageSizes} />
              </Grid>
              {this.props.loading ? (
                <div className="card-loader" id="unique-10841638div_13">
                  <div id="loading" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
