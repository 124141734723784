import React, { Component } from 'react'

import Input from '../Fields/input'
import Select from '../Fields/select'

import LinkedProjectInfoBox from './LinkProjectInfoBox'
import UploadLOVExcel from '../../Forms/UploadLOVExcel'

export default class ProjectSetupS3 extends Component {
  state = {
    components: [],
    loading: false,
    formLoader: false,

    company_id: null,
    company_lovs: '',

    project_info_data: [],
    project_name: '',
    project_code: '',
    duplicate_project_info_field_error: false,

    dynamic_data: [],
    dynamic_fields: {},
    show_dynamic_form: false,
    text_fields: [
      'Site Name',
      'Site Code',
      'Plant Name',
      'Plant Code',
      'Unit Name',
      'Unit Code',
      'Area Name',
      'Area Code',
      'Plant System Name',
      'Plant System Code',
      'Sub System Name',
      'Sub System Code',
      'Business Sector Name',
      'Business Sector Code',
    ],
    duplicate_plant_info_field_error: false,

    company_info_data: [],
    company_name: '',
    company_address: '',
    optionList: [],
    duplicate_company_info_field_error: false,
    company_identification: [],
    company_identification_options: [
      { label: 'Manufacturer Indicator', value: 'Manufacturer Indicator' },
      { label: 'Supplier Indicator', value: 'Supplier Indicator' },
      { label: 'Engineering Indicator', value: 'Engineering Indicator' },
      { label: 'Contractor Indicator', value: 'Contractor Indicator' },
    ],
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectPlantComponents(formData)
    await this.setState({
      loading: false,
      components: this.props.user.projectInfo.components,
      dynamic_data: this.props.user.projectInfo.component_data,
      project_info_data: this.props.user.projectInfo.project_data,
      company_info_data: this.props.user.projectInfo.company_data,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('project_info', JSON.stringify(this.state.project_info_data))
    formData.append('company_info', JSON.stringify(this.state.company_info_data))
    formData.append('plant_info', JSON.stringify(this.state.dynamic_data))
    formData.append('step', 3)
    await this.props.updateProjectPlantComponents(formData)
    await this.setState({ formLoader: false })
    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  addProjectInfoHandler = async (e) => {
    e.preventDefault()
    let project_info_data = this.state.project_info_data
    let max_id = Math.max(...project_info_data.map((o) => o.id))
    if (max_id === -Infinity) {
      max_id = 0
    }

    if (project_info_data.findIndex((data) => data.projectName === this.state.project_name || data.projectCode === this.state.project_code) > -1) {
      this.setState({ duplicate_project_info_field_error: true })
      return
    }

    project_info_data.push({
      id: max_id + 1,
      projectName: this.state.project_name,
      projectCode: this.state.project_code,
    })

    await this.setState({
      project_name: '',
      project_code: '',
      project_info_data: project_info_data,
      duplicate_project_info_field_error: false,
    })
  }

  isNumber(char) {
    if (typeof char !== 'string') {
      return false
    }

    if (char.trim() === '') {
      return false
    }

    return !isNaN(char)
  }

  onChange = (obj) => {
    if ('company_name' in obj) {
      if (this.isNumber(obj['company_name'])) {
        const optionIndex = this.state.optionList.findIndex((option) => option.id === parseInt(obj['company_name']))
        if (optionIndex > -1) {
          this.setState({
            company_id: this.state.optionList[optionIndex].company_id,
            company_name: this.state.optionList[optionIndex].companyName,
            company_address: this.state.optionList[optionIndex].companyAddress,
            company_identification: this.state.optionList[optionIndex].companyIdentification,
            company_lovs: this.state.optionList[optionIndex].companyLOVs,
          })
          return
        }
      }

      if (obj['company_name'].length > 2) {
        this.fetchCompanyOptions(obj)
      }
    }
    this.setState(obj)
  }

  fetchCompanyOptions = async (obj) => {
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('subText', obj['company_name'])
    await this.props.fetchCompanyOptions(formData)
    await this.setState({
      optionList: this.props.user.projectCompanyOptions,
    })
  }

  deleteProjectInfoDataEntry = (data) => {
    let project_info_data = this.state.project_info_data.filter((_data) => _data.id !== data.id)
    this.setState({ project_info_data: project_info_data })
  }

  addCompanyInfoHandler = async (e) => {
    e.preventDefault()
    let company_info_data = this.state.company_info_data

    let max_id = Math.max(...company_info_data.map((o) => o.id))
    if (max_id === -Infinity) {
      max_id = 0
    }

    if (company_info_data.findIndex((data) => data.companyName === this.state.company_name && data.companyAddress === this.state.company_address) > -1) {
      this.setState({ duplicate_company_info_field_error: true })
      return
    }

    company_info_data.push({
      id: max_id + 1,
      company_id: this.state.company_id,
      companyName: this.state.company_name,
      companyAddress: this.state.company_address,
      companyIdentification: this.state.company_identification,
      company_lovs: this.state.company_lovs,
    })

    await this.setState({
      company_name: '',
      company_address: '',
      company_identification: [],
      company_info_data: company_info_data,
      company_id: null,
      company_lovs: '',
      duplicate_company_info_field_error: false,
    })
  }

  deleteCompanyInfoDataEntry = (data) => {
    let company_info_data = this.state.company_info_data.filter((_data) => _data.id !== data.id)
    this.setState({ company_info_data: company_info_data })
  }

  addDynamicField = async (field, depth, path = []) => {
    let dynamic_fields = {
      path: path,
      depth: depth,
      field: field,
      data: [],
    }
    if ('subfields' in field) {
      field.subfields.forEach((_field) => {
        let data = {
          key: _field.key,
          name: _field.name,
          type: _field.field_type,
          options: _field.field_type === 'select' ? _field.options : null,
          value: _field.field_type === 'select' ? [] : '',
        }
        if (_field.subfields) {
          data.value = []
        }
        dynamic_fields.data.push(data)
      })
    }
    await this.setState({ dynamic_fields: dynamic_fields })
    await this.setState({ show_dynamic_form: true })
  }

  getFieldOptions = (field) => {
    let options = []
    if ('subfields' in field) {
      field.subfields.forEach((_field) => {
        if (_field.name === 'Measurement System Code') {
          options = _field.options
        }
      })
    }
    return options
  }

  deleteDynamicField = (path) => {
    let dynamic_data = this.state.dynamic_data

    if (path.length === 1) {
      dynamic_data.splice(path[0], 1)
    } else if (path.length === 2) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object') {
          dynamic_data[path[0]][key].splice(path[1], 1)
        }
      })
    } else if (path.length === 3) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (typeof _val === 'object') {
              dynamic_data[path[0]][key][path[1]][_key].splice(path[2], 1)
            }
          })
        }
      })
    } else if (path.length === 4) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (typeof _val === 'object') {
              Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]]).forEach(([__key, __val]) => {
                if (typeof __val === 'object') {
                  dynamic_data[path[0]][key][path[1]][_key][path[2]][__key].splice(path[3], 1)
                }
              })
            }
          })
        }
      })
    } else if (path.length === 5) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (typeof _val === 'object') {
              Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]]).forEach(([__key, __val]) => {
                if (typeof __val === 'object') {
                  Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]][__key][path[3]]).forEach(([___key, ___val]) => {
                    if (typeof ___val === 'object') {
                      dynamic_data[path[0]][key][path[1]][_key][path[2]][__key][path[3]][___key].splice(path[4], 1)
                    }
                  })
                }
              })
            }
          })
        }
      })
    }

    this.setState({ dynamic_data: dynamic_data })
  }

  addDynamicDataHandler = async (e) => {
    e.preventDefault()
    await this.setState({ duplicate_plant_info_field_error: false })
    let { dynamic_data, dynamic_fields, text_fields } = this.state

    let data = { key: dynamic_fields.field.key }
    dynamic_fields.data.forEach((_data) => {
      data[_data.key] = _data.value
    })

    let duplicate_plant_info_field_error = false

    if (dynamic_fields.depth === 1) {
      let add_data = true
      dynamic_data.forEach((_data) => {
        Object.entries(_data).forEach(([_key, _val]) => {
          if (data[_key] === _val && text_fields.includes(_key)) {
            add_data = false
          }
        })
      })
      if (add_data) {
        dynamic_data.push(data)
      } else {
        duplicate_plant_info_field_error = true
      }
    } else if (dynamic_fields.depth === 2) {
      let add_data = true
      dynamic_data[dynamic_fields.path[0]][dynamic_fields.field.key].forEach((__data) => {
        Object.entries(__data).forEach(([__key, __val]) => {
          if (data[__key] === __val && text_fields.includes(__key)) {
            add_data = false
          }
        })
      })
      if (add_data) {
        dynamic_data[dynamic_fields.path[0]][dynamic_fields.field.key].push(data)
      } else {
        duplicate_plant_info_field_error = true
      }
    } else if (dynamic_fields.depth === 3) {
      Object.entries(dynamic_data[dynamic_fields.path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object' && key !== 'Measurement System Code') {
          let add_data = true
          dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][dynamic_fields.field.key].forEach((___data) => {
            Object.entries(___data).forEach(([___key, ___val]) => {
              if (data[___key] === ___val && text_fields.includes(___key)) {
                add_data = false
              }
            })
          })
          if (add_data) {
            dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][dynamic_fields.field.key].push(data)
          } else {
            duplicate_plant_info_field_error = true
          }
        }
      })
    } else if (dynamic_fields.depth === 4) {
      Object.entries(dynamic_data[dynamic_fields.path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object' && key !== 'Measurement System Code') {
          Object.entries(dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]]).forEach(([_key, _val]) => {
            if (typeof _val === 'object' && _key !== 'Measurement System Code') {
              let add_data = true
              dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][_key][dynamic_fields.path[2]][dynamic_fields.field.key].forEach((____data) => {
                Object.entries(____data).forEach(([____key, ____val]) => {
                  if (data[____key] === ____val && text_fields.includes(____key)) {
                    add_data = false
                  }
                })
              })
              if (add_data) {
                dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][_key][dynamic_fields.path[2]][dynamic_fields.field.key].push(data)
              } else {
                duplicate_plant_info_field_error = true
              }
            }
          })
        }
      })
    } else if (dynamic_fields.depth === 5) {
      Object.entries(dynamic_data[dynamic_fields.path[0]]).forEach(([key, val]) => {
        if (typeof val === 'object' && key !== 'Measurement System Code') {
          Object.entries(dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]]).forEach(([_key, _val]) => {
            if (typeof _val === 'object' && _key !== 'Measurement System Code') {
              Object.entries(dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][_key][dynamic_fields.path[2]]).forEach(([__key, __val]) => {
                if (typeof __val === 'object' && __key !== 'Measurement System Code') {
                  let add_data = true
                  dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][_key][dynamic_fields.path[2]][__key][dynamic_fields.path[3]][dynamic_fields.field.key].forEach(
                    (_____data) => {
                      Object.entries(_____data).forEach(([_____key, _____val]) => {
                        if (data[_____key] === _____val && text_fields.includes(_____key)) {
                          add_data = false
                        }
                      })
                    }
                  )
                  if (add_data) {
                    dynamic_data[dynamic_fields.path[0]][key][dynamic_fields.path[1]][_key][dynamic_fields.path[2]][__key][dynamic_fields.path[3]][dynamic_fields.field.key].push(
                      data
                    )
                  } else {
                    duplicate_plant_info_field_error = true
                  }
                }
              })
            }
          })
        }
      })
    }

    if (duplicate_plant_info_field_error) {
      this.setState({ duplicate_plant_info_field_error: true })
    } else {
      this.setState({
        dynamic_data: dynamic_data,
        show_dynamic_form: false,
      })
    }
  }

  editDynamicField = async (e, path) => {
    let { dynamic_data } = this.state

    if (path.length === 1) {
      dynamic_data[path[0]][e.target.name] = e.target.value
    } else if (path.length === 2) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (Array.isArray(val) && key !== 'Measurement System Code') {
          dynamic_data[path[0]][key][path[1]][e.target.name] = e.target.value
        }
      })
    } else if (path.length === 3) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (Array.isArray(val) && key !== 'Measurement System Code') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (Array.isArray(_val) && _key !== 'Measurement System Code') {
              dynamic_data[path[0]][key][path[1]][_key][path[2]][e.target.name] = e.target.value
            }
          })
        }
      })
    } else if (path.length === 4) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (Array.isArray(val) && key !== 'Measurement System Code') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (Array.isArray(_val) && _key !== 'Measurement System Code') {
              Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]]).forEach(([__key, __val]) => {
                if (Array.isArray(__val) && __key !== 'Measurement System Code') {
                  dynamic_data[path[0]][key][path[1]][_key][path[2]][__key][path[3]][e.target.name] = e.target.value
                }
              })
            }
          })
        }
      })
    } else if (path.length === 5) {
      Object.entries(dynamic_data[path[0]]).forEach(([key, val]) => {
        if (Array.isArray(val) && key !== 'Measurement System Code') {
          Object.entries(dynamic_data[path[0]][key][path[1]]).forEach(([_key, _val]) => {
            if (Array.isArray(_val) && _key !== 'Measurement System Code') {
              Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]]).forEach(([__key, __val]) => {
                if (Array.isArray(__val) && __key !== 'Measurement System Code') {
                  Object.entries(dynamic_data[path[0]][key][path[1]][_key][path[2]][__key][path[3]]).forEach(([___key, ___val]) => {
                    if (Array.isArray(___val) && ___key !== 'Measurement System Code') {
                      dynamic_data[path[0]][key][path[1]][_key][path[2]][__key][path[3]][___key][path[4]][e.target.name] = e.target.value
                    }
                  })
                }
              })
            }
          })
        }
      })
    }

    await this.setState({
      dynamic_data: dynamic_data,
    })
  }

  dynamicFieldChangeHandler = (e) => {
    console.log(e, 'e jmsams,a')
    let { dynamic_fields } = this.state
    dynamic_fields.data.forEach((field) => {
      if (field.key === e.target.name) {
        field.value = e.target.value
      }
    })
    this.setState({ dynamic_fields: dynamic_fields })
  }

  onSelect = (selectedOptions, key) => {
    this.dynamicFieldChangeHandler({
      target: {
        name: key,
        value: selectedOptions,
      },
    })
  }

  onChangeCompanyData = (e, index) => {
    let { company_info_data } = this.state
    company_info_data[index][e.target.name] = e.target.value
    this.setState({ company_info_data: company_info_data })
  }

  renderForm() {
    if (!this.props.user.projectInfo) {
      return null
    }

    return (
      <div className="card">
        <div className="clearfix card-header bg-light">
          <button
            type="button"
            onClick={() => this.formDataHandler(false)}
            disabled={this.state.formLoader || (this.props.product !== 'iDocuments-TradeDocuments' && !this.state.dynamic_data.length)}
            className="btn btn-primary float-right"
          >
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2">
            Cancel
          </button>

          <h3 className="mb-0">Plant Common {this.state.screen_blocked ? <i className="fa fa-lock ml-2" /> : null}</h3>
        </div>
        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`}>
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          {/* Dynamic Fields */}
          {this.props.product !== 'iDocuments-TradeDocuments' ? (
            <React.Fragment>
              <div className="clearfix mb-3">
                <h4>
                  Plant Information <small className="text-secondary">(*)</small>
                </h4>
              </div>

              {this.state.show_dynamic_form ? (
                <form className="card bg-light mb-3" onSubmit={this.addDynamicDataHandler}>
                  <div className="card-body p-2">
                    {this.state.duplicate_plant_info_field_error ? (
                      <div className="alert alert-danger alert-dismissible">
                        Duplicate entries are not allowed!
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            this.setState({
                              duplicate_plant_info_field_error: false,
                            })
                          }
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                    <div className="row">
                      {this.state.dynamic_fields.data.map((field) => {
                        if (field.type === 'text') {
                          return (
                            <div className="col" key={field.key}>
                              <Input
                                type="text"
                                name={field.key}
                                value={field.value}
                                placeholder={field.name}
                                path={`void`}
                                onChange={this.dynamicFieldChangeHandler}
                                required={true}
                              />
                            </div>
                          )
                        } else if (field.type === 'select') {
                          return (
                            <div className="col-3" key={field.key}>
                              <Select
                                options={field.options}
                                value={field.value}
                                onChange={(selectedOptions) => {
                                  this.onSelect(selectedOptions, field.key)
                                }}
                                isMulti={true}
                              />
                            </div>
                          )
                        }
                        return null
                      })}
                      <div className="col-2">
                        <button type="submit" className="btn btn-primary">
                          {' '}
                          Add{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : null}
              {this.state.components.map((field, index) => {
                return (
                  <div key={index} className="mb-2">
                    <button className="btn btn-default mb-2 mr-2" onClick={() => this.addDynamicField(field, 1)}>
                      {field.key} <i className="fa fa-plus"></i>
                    </button>

                    {this.state.dynamic_data.map((data, _index) => {
                      if (data.key === field.key) {
                        return (
                          <div key={`${_index}`} className="border mb-1">
                            {Object.entries(data).map(([key, value]) => {
                              if (key !== 'key' && key !== 'id') {
                                if (typeof value !== 'object' || ['Measurement System Code'].includes(key)) {
                                  if (['Measurement System Code'].includes(key)) {
                                    return (
                                      <div className="d-inline-block m-2 flex" key={key}>
                                        <Select
                                          options={this.getFieldOptions(field)}
                                          value={value}
                                          onChange={(selectedOptions) => {
                                            this.editDynamicField({ target: { name: key, value: selectedOptions } }, [_index])
                                          }}
                                          isMulti={true}
                                        />
                                      </div>
                                    )
                                  }
                                  return (
                                    <div className="d-inline-block m-2" style={{ width: 320 }} key={key}>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">{key}</span>
                                        </div>
                                        <Input type="text" name={key} value={value} path={`${[_index]}`} onChange={this.editDynamicField} />
                                      </div>
                                    </div>
                                  )
                                } else {
                                  let _field = {}
                                  const index_1 = field.subfields.findIndex((_field) => _field.key === key)
                                  if (index_1 > -1) {
                                    _field = field.subfields[index_1]
                                  }
                                  return (
                                    <React.Fragment key={key}>
                                      <button className="btn btn-default m-2" onClick={() => this.addDynamicField(_field, 2, [_index])}>
                                        {key} <i className="fa fa-plus"></i>
                                      </button>
                                      <button onClick={() => this.deleteDynamicField([_index])} className="btn btn-default m-2">
                                        <i className="fa fa-trash-alt"></i>
                                      </button>
                                      {value.map((_data, __index) => {
                                        let _child_trash = true
                                        Object.values(_data).forEach((val) => {
                                          if (typeof val === 'object') {
                                            _child_trash = false
                                          }
                                        })
                                        return (
                                          <div key={`${index}_${__index}`} className="ml-4">
                                            {Object.entries(_data).map(([_key, _value]) => {
                                              if (_key !== 'key') {
                                                if (typeof _value !== 'object' || ['Measurement System Code'].includes(_key)) {
                                                  if (['Measurement System Code'].includes(_key)) {
                                                    return (
                                                      <div className="d-inline-block m-2 flex" key={_key}>
                                                        <Select
                                                          options={this.getFieldOptions(_field)}
                                                          value={_value}
                                                          onChange={(selectedOptions) => {
                                                            this.editDynamicField({ target: { name: _key, value: selectedOptions } }, [_index, __index])
                                                          }}
                                                          isMulti={true}
                                                        />
                                                      </div>
                                                    )
                                                  }
                                                  return (
                                                    <div className="d-inline-block m-2" style={{ width: 320 }} key={_key}>
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <span className="input-group-text">{_key}</span>
                                                        </div>
                                                        <Input type="text" name={_key} value={_value} path={`${[_index, __index]}`} onChange={this.editDynamicField} />
                                                      </div>
                                                    </div>
                                                  )
                                                } else {
                                                  let __field = {}
                                                  const index_2 = _field.subfields.findIndex((__field) => __field.key === _key)
                                                  if (index_2 > -1) {
                                                    __field = _field.subfields[index_2]
                                                  }
                                                  return (
                                                    <React.Fragment key={_key}>
                                                      <button className="btn btn-default m-2" onClick={() => this.addDynamicField(__field, 3, [_index, __index])}>
                                                        {_key} <i className="fa fa-plus"></i>
                                                      </button>
                                                      <button onClick={() => this.deleteDynamicField([_index, __index])} className="btn btn-default m-2">
                                                        <i className="fa fa-trash-alt"></i>
                                                      </button>

                                                      {_value.map((__data, ___index) => {
                                                        let __child_trash = true
                                                        Object.values(__data).forEach((val) => {
                                                          if (typeof val === 'object') {
                                                            __child_trash = false
                                                          }
                                                        })
                                                        return (
                                                          <div key={`${_index}_${___index}`} className="ml-4">
                                                            {Object.entries(__data).map(([__key, __value]) => {
                                                              if (__key !== 'key') {
                                                                if (typeof __value !== 'object' || ['Measurement System Code'].includes(__key)) {
                                                                  if (['Measurement System Code'].includes(__key)) {
                                                                    return (
                                                                      <div className="d-inline-block m-2 flex" key={__key}>
                                                                        <Select
                                                                          options={this.getFieldOptions(__field)}
                                                                          value={__value}
                                                                          onChange={(selectedOptions) => {
                                                                            this.editDynamicField({ target: { name: __key, value: selectedOptions } }, [_index, __index, ___index])
                                                                          }}
                                                                          isMulti={true}
                                                                        />
                                                                      </div>
                                                                    )
                                                                  }
                                                                  return (
                                                                    <div className="d-inline-block m-2" style={{ width: 320 }} key={__key}>
                                                                      <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                          <span className="input-group-text">{__key}</span>
                                                                        </div>
                                                                        <Input
                                                                          type="text"
                                                                          name={__key}
                                                                          value={__value}
                                                                          path={`${[_index, __index, ___index]}`}
                                                                          onChange={this.editDynamicField}
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                } else {
                                                                  let ___field = {}
                                                                  const index_3 = __field.subfields.findIndex((___field) => ___field.key === __key)
                                                                  if (index_3 > -1) {
                                                                    ___field = __field.subfields[index_3]
                                                                  }

                                                                  return (
                                                                    <React.Fragment key={__key}>
                                                                      <button
                                                                        className="btn btn-default m-2"
                                                                        onClick={() => this.addDynamicField(___field, 4, [_index, __index, ___index])}
                                                                      >
                                                                        {__key} <i className="fa fa-plus"></i>
                                                                      </button>
                                                                      <button onClick={() => this.deleteDynamicField([_index, __index, ___index])} className="btn btn-default m-2">
                                                                        <i className="fa fa-trash-alt"></i>
                                                                      </button>
                                                                      {__value.map((___data, ____index) => {
                                                                        return (
                                                                          <div key={`${__index}_${____index}`} className="ml-4">
                                                                            {Object.entries(___data).map(([___key, ___value]) => {
                                                                              if (___key !== 'key') {
                                                                                if (typeof ___value !== 'object' || ['Measurement System Code'].includes(___key)) {
                                                                                  if (['Measurement System Code'].includes(___key)) {
                                                                                    return (
                                                                                      <div className="d-inline-block m-2 flex" key={___key}>
                                                                                        <Select
                                                                                          options={this.getFieldOptions(___field)}
                                                                                          value={___value}
                                                                                          onChange={(selectedOptions) => {
                                                                                            this.editDynamicField({ target: { name: ___field, value: selectedOptions } }, [
                                                                                              _index,
                                                                                              __index,
                                                                                              ___index,
                                                                                              ____index,
                                                                                            ])
                                                                                          }}
                                                                                          isMulti={true}
                                                                                        />
                                                                                      </div>
                                                                                    )
                                                                                  }

                                                                                  return (
                                                                                    <div className="d-inline-block m-2" style={{ width: 320 }} key={___key}>
                                                                                      <div className="input-group">
                                                                                        <div className="input-group-prepend">
                                                                                          <span className="input-group-text">{___key}</span>
                                                                                        </div>
                                                                                        <Input
                                                                                          type="text"
                                                                                          name={___key}
                                                                                          value={___value}
                                                                                          path={`${[_index, __index, ___index]}`}
                                                                                          onChange={this.editDynamicField}
                                                                                        />
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                } else {
                                                                                  let ____field = {}
                                                                                  const index_4 = ___field.subfields.findIndex((____field) => ____field.key === ___key)
                                                                                  if (index_4 > -1) {
                                                                                    ____field = ___field.subfields[index_4]
                                                                                  }

                                                                                  return (
                                                                                    <React.Fragment key={___key}>
                                                                                      <button
                                                                                        className="btn btn-default m-2"
                                                                                        onClick={() => this.addDynamicField(____field, 5, [_index, __index, ___index, ____index])}
                                                                                      >
                                                                                        {___key} <i className="fa fa-plus"></i>
                                                                                      </button>
                                                                                      <button
                                                                                        onClick={() => this.deleteDynamicField([_index, __index, ___index, ____index])}
                                                                                        className="btn btn-default m-2"
                                                                                      >
                                                                                        <i className="fa fa-trash-alt"></i>
                                                                                      </button>
                                                                                      {___value.map((____data, _____index) => {
                                                                                        return (
                                                                                          <div key={`${___index}_${_____index}`} className="ml-4">
                                                                                            {Object.entries(____data).map(([____key, ____value]) => {
                                                                                              if (____key !== 'key') {
                                                                                                if (
                                                                                                  typeof ____value !== 'object' ||
                                                                                                  ['Measurement System Code'].includes(____key)
                                                                                                ) {
                                                                                                  if (['Measurement System Code'].includes(____key)) {
                                                                                                    return (
                                                                                                      <div className="d-inline-block m-2 flex" key={____key}>
                                                                                                        <Select
                                                                                                          options={this.getFieldOptions(____field)}
                                                                                                          value={____value}
                                                                                                          onChange={(selectedOptions) => {
                                                                                                            this.editDynamicField(
                                                                                                              {
                                                                                                                target: {
                                                                                                                  name: ____field,
                                                                                                                  value: selectedOptions,
                                                                                                                },
                                                                                                              },
                                                                                                              [_index, __index, ___index, ____index, _____index]
                                                                                                            )
                                                                                                          }}
                                                                                                          isMulti={true}
                                                                                                        />
                                                                                                      </div>
                                                                                                    )
                                                                                                  }

                                                                                                  return (
                                                                                                    <div className="d-inline-block m-2" style={{ width: 320 }} key={____key}>
                                                                                                      <div className="input-group">
                                                                                                        <div className="input-group-prepend">
                                                                                                          <span className="input-group-text">{____key}</span>
                                                                                                        </div>
                                                                                                        <Input
                                                                                                          type="text"
                                                                                                          name={____key}
                                                                                                          value={____value}
                                                                                                          path={`${[_index, __index, ___index, ____index, _____index]}`}
                                                                                                          onChange={this.editDynamicField}
                                                                                                        />
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  )
                                                                                                }
                                                                                                return null
                                                                                              }
                                                                                              return null
                                                                                            })}
                                                                                            <button
                                                                                              onClick={() =>
                                                                                                this.deleteDynamicField([_index, __index, ___index, ____index, _____index])
                                                                                              }
                                                                                              className="btn btn-default m-2"
                                                                                            >
                                                                                              <i className="fa fa-trash-alt"></i>
                                                                                            </button>
                                                                                          </div>
                                                                                        )
                                                                                      })}
                                                                                    </React.Fragment>
                                                                                  )
                                                                                }
                                                                              }
                                                                              return null
                                                                            })}
                                                                          </div>
                                                                        )
                                                                      })}
                                                                    </React.Fragment>
                                                                  )
                                                                }
                                                              }

                                                              return null
                                                            })}
                                                            {__child_trash ? (
                                                              <button onClick={() => this.deleteDynamicField([_index, __index, ___index])} className="btn btn-default m-2">
                                                                <i className="fa fa-trash-alt"></i>
                                                              </button>
                                                            ) : null}
                                                          </div>
                                                        )
                                                      })}
                                                    </React.Fragment>
                                                  )
                                                }
                                              }
                                              return null
                                            })}
                                            {_child_trash ? (
                                              <button onClick={() => this.deleteDynamicField([_index, __index])} className="btn btn-default m-2">
                                                <i className="fa fa-trash-alt"></i>
                                              </button>
                                            ) : null}
                                          </div>
                                        )
                                      })}
                                    </React.Fragment>
                                  )
                                }
                              }
                              return null
                            })}
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                )
              })}

              <hr className="mt-4 mb-4" />
            </React.Fragment>
          ) : null}

          {/* Project Information */}
          {this.props.product !== 'iDocuments-TradeDocuments' ? (
            <React.Fragment>
              <div className="clearfix mb-3">
                <h4>Project Information</h4>
              </div>
              <form className="card bg-light mb-3" onSubmit={this.addProjectInfoHandler}>
                <div className="card-body p-2">
                  {this.state.duplicate_project_info_field_error ? (
                    <div className="alert alert-danger alert-dismissible">
                      Duplicate entries are not allowed!
                      <button
                        type="button"
                        className="close"
                        onClick={() =>
                          this.setState({
                            duplicate_project_info_field_error: false,
                          })
                        }
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col">
                      <Input type="text" value={this.state.project_name} name="project_name" onChange={this.onChange} required={true} placeholder="Project Name *" />
                    </div>
                    <div className="col">
                      <Input type="text" value={this.state.project_code} name="project_code" onChange={this.onChange} required={true} placeholder="Project Code *" />
                    </div>
                    <div className="col-2">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <table className="table table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>Project Name</th>
                    <th>Project Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.project_info_data.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>{data.projectName}</td>
                        <td>{data.projectCode}</td>
                        <td>
                          <button type="button" className="btn btn-default" onClick={() => this.deleteProjectInfoDataEntry(data)}>
                            <i className="fa fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <hr className="mt-4 mb-4" />
            </React.Fragment>
          ) : null}

          {/* Company Information */}
          <div>
            <div className="clearfix mb-3">
              <h4>Company Information</h4>
            </div>

            <form className="card bg-light mb-3" onSubmit={this.addCompanyInfoHandler}>
              <div className="card-body p-2">
                {this.state.duplicate_company_info_field_error ? (
                  <div className="alert alert-danger alert-dismissible">
                    Duplicate entries are not allowed!
                    <button
                      type="button"
                      className="close"
                      onClick={() =>
                        this.setState({
                          duplicate_company_info_field_error: false,
                        })
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-3">
                    <Input
                      type="text"
                      value={this.state.company_name}
                      name="company_name"
                      list="company_name"
                      onChange={this.onChange}
                      required={true}
                      placeholder="Company Name *"
                    />

                    <datalist id="company_name">
                      {this.state.optionList.map((option, index) => {
                        return (
                          <option key={index} value={option.id}>
                            {option.companyName}
                          </option>
                        )
                      })}
                    </datalist>
                  </div>
                  <div className="col-3">
                    <Input type="text" value={this.state.company_address} name="company_address" onChange={this.onChange} required={true} placeholder="Company Address *" />
                  </div>
                  <div className="col-4">
                    <Select
                      options={this.state.company_identification_options}
                      value={this.state.company_identification}
                      onChange={(selectedList) => {
                        this.setState({ company_identification: selectedList })
                      }}
                      isMulti={true}
                    />
                  </div>
                  <div className="col-2">
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <table className="table table-striped">
              <thead className="thead-light">
                <tr>
                  <th>Company Name</th>
                  <th>Company Address</th>
                  {this.state.company_identification_options.map((option) => {
                    return <th key={option.value}>{option.value.replace(' Indicator', '')}</th>
                  })}
                  <th>Company LOVs</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.company_info_data.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Input type="text" value={data.companyName} name="companyName" onChange={this.onChangeCompanyData} path={`${index}`} placeholder="Company Name" />
                      </td>
                      <td>
                        <Input type="text" value={data.companyAddress} name="companyAddress" onChange={this.onChangeCompanyData} path={`${index}`} placeholder="Company Address" />
                      </td>
                      {this.state.company_identification_options.map((option) => {
                        return <td key={option.value}>{data.companyIdentification.find((identification) => identification.value === option.value) ? 'true' : 'false'}</td>
                      })}
                      <td>
                        <div className="input-group">
                          <Input type="text" value={data.companyLOVs} name="companyLOVs" onChange={this.onChangeCompanyData} path={`${index}`} placeholder="Company LOVs" />
                          <div className="input-group-append">
                            <UploadLOVExcel {...this.props} index={index} name={'companyLOVs'} updateLOVData={this.onChangeCompanyData} projectId={this.props.editProjectId} />
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-default" onClick={() => this.deleteCompanyInfoDataEntry(data)}>
                          <i className="fa fa-trash-alt" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {this.state.warningMessage ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={() => this.setState({ warningMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    If you will change the template on previous screen i.e. Plant Bussiness Structure (PBS) screen, Plant Information data will be lost. Do you still want to
                    proceed?
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => this.goBack(true)}>
                    Confirm
                  </button>
                  <button type="button" className="btn btn-default" onClick={() => this.setState({ warningMessage: false })}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
