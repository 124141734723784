import React from 'react'
import './Forms.css'
import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'
import { uploadSPPIDExcelFile } from '../../store/actions'

class UploadSPPID extends React.Component {
  state = {
    uploading: false,
  }

  handleDocument = (files) => {
    this.setState({ files: files, uploading: true }, this.uploadDocument)
  }

  uploadDocument = async () => {
    const formData = new FormData()
    formData.append('file', this.state.files[0])
    formData.append('projectName', this.props.projectName)
    formData.append('drawing_b_Id', this.props.drawing_b_Id)
    formData.append('drawing_Id', this.props.drawing_Id)
    formData.append('productName', this.props.product)
    formData.append('projectId', this.props.projectId)
    await this.props.uploadSPPIDExcelFile(formData)
    await this.setState({ uploading: false })

    if (!this.props.user.displayMessageCode) {
      this.props.reloadData()
    }
  }

  render() {
    let upload_hidden = false
    if (this.props.user.user_actions.includes(`idrawings-pid-update_upload-sppid_hidden`)) {
      upload_hidden = true
    }
    return (
      <React.Fragment>
        {this.props.fileName ? (
          <span className="text-success">
            <i className="fa fa-file-excel" /> {this.props.fileName}
          </span>
        ) : null}

        {!upload_hidden ? (
          <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <button
                {...getRootProps()}
                className={`btn btn-default btn-sm`}
                disabled={this.props.user.role === 'Guest'}
                title={this.props.user.role === 'Guest' ? 'Guest users are not allowed to upload SSPID excel' : null}
              >
                <input {...getInputProps()} multiple={false} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                {this.state.uploading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-upload" />}
              </button>
            )}
          </Dropzone>
        ) : null}
      </React.Fragment>
    )
  }
}

export default connect(null, { uploadSPPIDExcelFile })(UploadSPPID)
