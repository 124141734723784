import React from 'react'
import { connect } from 'react-redux'
import { processDrawings, updateProcessDrawings } from './../../store/actions'

class Process extends React.Component {
  state = {
    product: null,
    productId: null,
    projectName: null,
    projectId: null,
    userId: null,
    loading: false,
    selectedDrawings: [],
    allDrawings: [],
    activeDrawingIndex: 1,
    currentQuantities: true,
    numberOfProcessedDrawings: 0,
    availableComponentsPID: ['Equipment', 'Line', 'InstrumentAndControl', 'PipingComponent', 'Text', 'InstrumentControlLines'],
    availableComponentsElectrical: ['Equipment', 'InstrumentAndControl', 'Line', 'ElectricalComponent', 'Text'],
    componentLoading: false,
    drawingVersionIterator: 0,
    module: 'digitize',
    deliverableView: false,
    sideNavCollapse: false,
    slideBtns: true,
    zoomBtns: true,
    options: false,
    iframeUrl: null,
    reload_type: 'AI',
  }

  componentDidMount() {
    this.setState(
      {
        product: this.props.product,
        selectedDrawings: this.props.selectedDrawings.sort(),
        projectName: this.props.projectName,
        projectId: this.props.projectId,
        userId: this.props.user.userId,
      },
      this.requestProcessDrawings
    )
  }

  componentWillUnmount() {
    this.stopRequests()
  }

  requestProcessDrawings = () => {
    this.setState({ loading: true }, async () => {
      const formData = new FormData()
      formData.append('drawingList', this.state.selectedDrawings)
      formData.append('documentList', [])
      formData.append('projectId', this.state.projectId)
      formData.append('module', this.state.module)
      formData.append('reprocess', true)
      formData.append('mode', this.props.mode)
      await this.props.processDrawings(formData)
      if (this.props.user.displayMessageCode && !this.props.user.displayMessageCode.message.includes('err')) {
        if (this.props.user.processDrawings.processDrawings.length > 0) {
          this.setState({
            allDrawings: this.props.user.processDrawings.processDrawings,
          })
          this.updateProcessDrawings('AI')
        }
      }

      this.setState({ loading: false })
    })
  }

  refreshData = null

  updateProcessDrawings(reload_type = 'AI') {
    let allDrawings = this.state.allDrawings
    allDrawings[this.state.activeDrawingIndex - 1].reload_type = reload_type
    this.setState({ allDrawings: allDrawings })

    const formData = new FormData()
    formData.append('drawingList', this.state.selectedDrawings)
    formData.append('projectId', this.state.projectId)
    formData.append('module', this.state.module)
    formData.append('reload', false)
    formData.append('reloadDrawingId', null)
    formData.append('mode', this.props.mode)
    this.props.getProcessUpdates(this.state.allDrawings)
    this.refreshData = setInterval(async () => {
      await this.props.updateProcessDrawings(formData)
      if (this.props.user.processDrawingsError) {
        clearInterval(this.refreshData)
      } else {
        this.updateRefreshData()
      }
    }, 5000)
  }

  updateRefreshData = async () => {
    const processed_drawings = this.props.user.processDrawings.processDrawings.filter((drawing) => drawing.processed)

    await this.setState({
      numberOfProcessedDrawings: processed_drawings.length,
      allDrawings: this.props.user.processDrawings.processDrawings,
    })

    this.props.getProcessUpdates(this.state.allDrawings, this.props.user.processDrawings.numFilteredSuccDrawings)

    // if (this.state.allDrawings.filter((drawing) => drawing.processed === true).length !== processed_drawings.length) {

    // }

    if (processed_drawings.length >= this.state.allDrawings.length) {
      clearInterval(this.refreshData)
      setTimeout(() => {
        this.stopRequests()
      }, 5000)
    }
  }

  closeDeliverableView = () => {
    this.setState({ deliverableView: false })
  }

  stopRequests = () => {
    this.props.closeProcessView()
    clearInterval(this.refreshData)
  }

  minimizedView = () => {
    if (this.state.loading) {
      return <span id="unique-11208977span_1">Gathering Selected Drawings ...</span>
    }

    if (this.state.selectedDrawings.length) {
      return (
        <span id="unique-36697209span_2">
          Drawings Processed: {this.state.numberOfProcessedDrawings} of {this.state.allDrawings.length}
        </span>
      )
    }

    return (
      <span id="unique-11540546span_3">
        Job Completed <i className="fa fa-check-circle text-success" id="unique-16721549i_1"></i>
      </span>
    )
  }

  render() {
    return (
      <div className="process-minimized" id="unique-85670649div_1">
        {this.minimizedView()}
        <i className="fa fa-times" onClick={this.stopRequests} id="87061255" />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  processDrawings,
  updateProcessDrawings,
})(Process)
