import React from 'react'
import { connect } from 'react-redux'

import { fetchVersionDetails } from './../../store/actions'

class VersionDetails extends React.Component {
  state = {
    version_details: [],
    loading: false,
    active_version: '',
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.fetchVersionDetails().then(() => {
      this.setState({
        version_details: this.props.user.version_details,
        loading: false,
      })
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <h1 className="text-center" id="unique-94001730h1_1">
          <i className="fa fa-spin fa-spinner" id="unique-77114965i_1"></i>
        </h1>
      )
    }
    return (
      <div className="overlay" id="unique-58980626div_1">
        <div className="popup" id="unique-71547242div_2">
          <div className="header-background" id="unique-62632764div_3">
            <div className="header" id="unique-80398236div_4">
              <div className="close" onClick={() => this.props.closeVersionDetails()}>
                <i className="fa fa-times" id="unique-42486173i_2"></i>
              </div>
              <h4 style={{ lineHeight: '60px' }} id="unique-10925971h4_1">
                Version 4.6.0
              </h4>
            </div>
          </div>
          <div className="content" id="unique-11547877div_6">
            <div className="table-container-wrapper" id="unique-22387554div_7">
              <div
                className="card"
                style={{
                  // backgroundColor: "rgb(238, 238, 238)",
                  fontSize: '17px',
                  fontWeight: 'bold',
                  color: 'darkslategrey',
                  borderRadius: 0,
                  border: 0,
                }}
                id="unique-11140430div_8"
              >
                <div className="card-body p-2" id="unique-10906448div_9">
                  <div className="row" id="unique-11340395div_10">
                    <div className="col-4" id="unique-52358317div_11">
                      Module
                    </div>
                    <div className="col-3" id="unique-48160960div_12">
                      Version
                    </div>
                    <div className="col-4" id="unique-32925247div_13">
                      Release Date
                    </div>
                    <div className="col-1" id="unique-62968770div_14"></div>
                  </div>
                </div>
              </div>
              {this.state.version_details.map((version_detail) => {
                return (
                  <div
                    key={version_detail.parent_name}
                    className="card mb-1"
                    style={{
                      borderRadius: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      this.setState({
                        active_version: version_detail.parent_name,
                      })
                    }
                  >
                    <div className="card-body p-2" id="unique-95093096div_16">
                      <div className="row" id="unique-11044606div_17">
                        <div className="col-4" id="unique-75355332div_18">
                          {version_detail.parent_name}
                        </div>
                        <div className="col-3" id="unique-97480421div_19">
                          {version_detail.version}
                        </div>
                        <div className="col-4" id="unique-33986826div_20">
                          {version_detail.release_date}
                        </div>
                        {version_detail.details.length ? (
                          <div className="col-1" id="unique-11392885div_21">
                            {this.state.active_version === version_detail.parent_name ? (
                              <i className="fa fa-angle-up" id="unique-45475056i_3"></i>
                            ) : (
                              <i className="fa fa-angle-down" id="unique-50591621i_4"></i>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {this.state.active_version === version_detail.parent_name && version_detail.details.length ? (
                      <div
                        className="p-2"
                        style={{
                          backgroundColor: '#eee',
                        }}
                        id="unique-86143395div_22"
                      >
                        <ol className="mt-2" id="unique-97585062ol_1">
                          {version_detail.details.map((detail, index) => {
                            return (
                              <li key={index} id="unique-91422987li_1">
                                {detail.map((_detail, _index) => {
                                  return (
                                    <div key={_index} id="unique-23232647div_23">
                                      {_detail.map((val) => {
                                        return (
                                          <div key={val} id="unique-13078092div_24">
                                            {val}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )
                                })}
                              </li>
                            )
                          })}
                        </ol>
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchVersionDetails,
})(VersionDetails)
