import React from 'react'

class Textarea extends React.Component {
  state = {
    disallowedSymbols: [],
    maxLength: this.props.maxLength ? this.props.maxLength : 250,
    errorMessage: null,
    isEmptyAndRequired: false,
  }

  componentDidMount = () => {
    if ('disallowedSymbols' in this.props) {
      this.setState({ disallowedSymbols: this.props.disallowedSymbols })
    }
  }

  onChange = (e) => {
    let { disallowedSymbols } = this.state

    let clean_value = ''
    for (const index in e.target.value) {
      if (e.target.value[index].match(/^[a-zA-Z0-9\s_]+$/) || e.target.value[index].match(/^\s*$/)) {
        clean_value += e.target.value[index]
      } else {
        if (!disallowedSymbols.includes(e.target.value[index])) {
          clean_value += e.target.value[index]
        } else {
          this.setState({
            errorMessage: `${e.target.value[index]} symbol is not allowed!`,
          })
          setTimeout(() => this.setState({ errorMessage: null }), 5000)
        }
      }
    }

    if (this.props.path) {
      let obj = {
        target: {
          name: '',
          value: '',
        },
      }
      obj.target.value = clean_value
      obj.target.name = e.target.name
      this.props.onChange(obj, this.props.path)
    } else {
      let obj = {}
      obj[e.target.name] = clean_value
      this.props.onChange(obj)
    }
  }

  onBlur = (e) => {
    let trimmed_value = e.target.value.trim() // Remove leading and trailing spaces
    if (trimmed_value !== e.target.value) {
      e.target.value = trimmed_value // Update input value without leading and trailing spaces
      this.onChange(e) // Call onChange to propagate changes
    }
    if (this.props.required && trimmed_value === '') {
      this.setState({ isEmptyAndRequired: true })
    } else {
      this.setState({ isEmptyAndRequired: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <div className="alert alert-danger">
            {this.state.errorMessage}{' '}
            <button className="close" onClick={() => this.setState({ errorMessage: null })}>
              &times;
            </button>
          </div>
        ) : null}

        <div className="clearfix">
          <span className={`d-inline-block float-right fw-bold ${this.props.value.length >= this.props.maxLength ? 'text-danger' : 'text-secondary'}`}>
            ({this.props.value.length}/{this.state.maxLength})
            {this.props.value.length >= this.state.maxLength ? <span className="text-danger d-inline-block fw-bold ml-2">Limit Exceeded</span> : null}
          </span>

          {this.props.label ? (
            <label>
              <span className={`${this.state.isEmptyAndRequired ? 'text-danger' : ''}`}>{this.props.label}</span>{' '}
              {this.props.required ? <span className={`small ${this.state.isEmptyAndRequired ? 'text-danger' : 'text-muted'}`}>(*)</span> : null}
              {this.props.info ? <i className="fas fa-info-circle d-inline-block ml-3 cursor-pointer" title={`If no owner, please put "Internal"`} /> : null}
            </label>
          ) : null}
        </div>

        <textarea
          name={this.props.name}
          rows={this.props.rows ? this.props.rows : 4}
          className={`form-control ${this.state.isEmptyAndRequired ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`}
          disabled={this.props.disabled}
          value={this.props.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          required={this.props.required}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          readOnly={this.props.readOnly}
          maxLength={this.state.maxLength}
        />
      </React.Fragment>
    )
  }
}

export default Textarea
