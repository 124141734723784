import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ProjectCard from '../Components/WebComponents/ProjectCard'
import Delete from '../Components/WebComponents/DeletePopup'
import CloneProject from '../Components/Forms/CloneProjectByClient'
import ProjectSetup from '../Components/Forms/ProjectSetup/ProjectSetup'
import ConfigureDatasheet from './Configure'
import DatasheetProject from './Project'

import { fetchProjects, fetchProjectsData, deleteProject, fetchDatasheetUploadOptions, uploadDatasheetSetupFile, fetchDatasheetMappingInfo } from '../store/actions'

class DatasheetDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      product: '',
      page: '',
      projectsData: [],
      loading: false,
      deletingProject: false,
      deleteProjectObject: null,
      cloneProjectId: null,
      deleteProjectId: null,
      configurePopup: false,
      reportsToggled: false,

      disciplines: [],
      upload_options: [],
    }
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const product = query.get('product')
    await this.setState({
      product: product,
      page: this.props.page,
    })

    this.fetchProjectsHandler()
    this.fetchDatasheetUploadOptions()
  }

  componentDidUpdate = (props) => {
    if (props.page !== this.props.page) {
      this.fetchProjectsHandler()
    }
  }

  fetchDatasheetUploadOptions = async () => {
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    await this.props.fetchDatasheetUploadOptions()
    await this.setState({
      upload_options: this.props.user.datasheetUploadOptions.upload_options,
    })
  }

  fetchProjectsHandler = async () => {
    await this.setState({ loading: true })
    await this.props.fetchProjectsData()
    this.setState({
      loading: false,
      projectsData: this.props.user.projectsData,
    })
  }

  configureToggler = () => {
    this.setState({
      configurePopup: !this.state.configurePopup,
    })
  }

  deleteProjectHandler = async (projectId) => {
    await this.setState({ deletingProject: true })
    let formData = new FormData()
    formData.append('projectId', projectId)
    await this.props.deleteProject(formData)
    await this.setState({ deleteProjectObject: null, deletingProject: false })
    this.fetchProjectsHandler()
  }

  deleteProjectToggler = (project) => {
    this.setState({
      deleteProjectObject: project,
    })
  }

  cloneProjectToggler = async (project) => {
    await this.setState({ cloneProjectId: project.id })
    this.setState({ page: 'clone-project' })
  }

  renderProjects() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-19337128div_15">
          <div id="loading" />
        </div>
      )
    }

    let edit_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_edit-project_hidden`)) {
      edit_project_hidden = true
    }

    let delete_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_delete-project_hidden`)) {
      delete_project_hidden = true
    }

    let clone_project_hidden = false
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_clone-project_hidden`)) {
      clone_project_hidden = true
    }

    return (
      <ProjectCard
        projectsData={this.state.projectsData}
        product={this.state.product}
        delete_project_hidden={delete_project_hidden}
        edit_project_hidden={edit_project_hidden}
        clone_project_hidden={clone_project_hidden}
        cloneProjectToggler={this.cloneProjectToggler}
        deleteProjectToggler={this.deleteProjectToggler}
      />
    )
  }

  closeProjectCreation = () => {
    this.setState(
      {
        page: 'home',
        cloneProjectId: null,
      },
      this.fetchProjectsHandler
    )
  }

  setDropdownOpen = (obj) => {
    this.setState(obj)
  }

  render() {
    if (this.props.page === 'home') {
      let create_project_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_create-project_hidden`) ? true : false
      let configure_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_configure_hidden`) ? true : false
      let reports_hidden = this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_reports_hidden`) ? true : false

      return (
        <div className="dashboard main" id="unique-10382765div_2">
          <nav aria-label="breadcrumb" id="unique-91181455nav_1">
            <ol className="breadcrumb" id="unique-18540506ol_1">
              <li className="breadcrumb-item" id="unique-72375772li_1">
                <Link to="/dashboard" id="unique-54109299link_1">
                  Product Dashboard
                </Link>
              </li>
              <li className="breadcrumb-item active" id="unique-63650602li_2">
                {this.state.product} Dashboard
              </li>
            </ol>
          </nav>

          <div className="clearfix" id="unique-36154836div_3">
            {!create_project_hidden ? (
              <Link
                id="datasheet_dashboard_create_project_btn"
                className={`btn btn-secondary float-right`}
                to={`/datasheet/project-setup?product=${this.state.product}&dashboard=true`}
              >
                Create Project
              </Link>
            ) : null}

            {!configure_hidden ? (
              <button id="datasheet_dashboard_configure_btn" className={`btn btn-primary float-right  mr-2`} type="button" onClick={this.configureToggler}>
                Configure
              </button>
            ) : null}

            {this.props.user.show_comparison || this.props.user.show_migration ? (
              <div className="dropdown float-right mr-2" id="unique-10280440div_4">
                {!reports_hidden ? (
                  <button
                    id="datasheet_dashboard_report_dropdown_btn"
                    className={`btn btn-primary`}
                    type="button"
                    onClick={() => this.setState({ reportsToggled: !this.state.reportsToggled })}
                  >
                    Reports &nbsp; {this.state.reportsToggled ? <i className="fa fa-caret-up"></i> : <i className="fa fa-caret-down"></i>}
                  </button>
                ) : null}

                {this.state.reportsToggled ? (
                  <div className="dropdown-menu" id="unique-10910617div_5">
                    {this.props.user.show_comparison ? (
                      <Link id="datasheet_dashboard_comparison_report_btn" to="/iworkflow?report=InstrumentData">
                        <button className={`dropdown-item`}>Comparison Report - Instrumentation</button>
                      </Link>
                    ) : null}

                    {this.props.user.show_migration ? (
                      <Link id="datasheet_dashboard_migration_report_btn" to="/iworkflow?report=MechinicalMigration">
                        <button className={`dropdown-item`}>Migration Report - Mechanical</button>
                      </Link>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}

            <h3 className="mb-4 font-weight-bold" id="unique-84255562h3_1">
              {this.state.product} Dashboard
            </h3>
          </div>

          <div className="row" id="unique-58303012div_6">
            {this.renderProjects()}
          </div>

          {this.state.configurePopup ? (
            <ConfigureDatasheet
              toggle={this.configureToggler}
              upload_options={this.state.upload_options}
              fetchDatasheetMappingInfo={this.props.fetchDatasheetMappingInfo}
              uploadDatasheetSetupFile={this.props.uploadDatasheetSetupFile}
              displayMessage={this.displayMessage}
              user={this.props.user}
            />
          ) : null}

          {this.state.deleteProjectObject ? (
            <Delete
              toggle={this.deleteProjectToggler}
              deleteProject={this.deleteProjectHandler}
              project={this.state.deleteProjectObject}
              deletingProject={this.state.deletingProject}
            />
          ) : null}
        </div>
      )
    } else if (this.props.page === 'project') {
      return <DatasheetProject {...this.props} />
    } else if (this.state.page === 'clone-project') {
      return <CloneProject {...this.props} product={this.state.product} projectId={this.state.cloneProjectId} closeProjectCreation={this.closeProjectCreation} />
    } else if (this.props.page === 'project-setup') {
      return <ProjectSetup {...this.props} />
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjects,
  fetchProjectsData,
  deleteProject,
  fetchDatasheetUploadOptions,
  uploadDatasheetSetupFile,
  fetchDatasheetMappingInfo,
})(DatasheetDashboard)
