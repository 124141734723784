import React from 'react'
import './Forms.css'
import { connect } from 'react-redux'

import Select from '../Forms/Fields/select'
import { fetchProjectDocumentUploadOptions } from './../../store/actions'

class UploadOptions extends React.Component {
  state = {
    loading: false,
    add_mode_index: '',
    documentUploadOptions: {},
  }

  componentDidMount() {
    this.fetchProjectDocumentUploadOptions()
  }

  fetchProjectDocumentUploadOptions = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('product', this.props.product)
    await this.props.fetchProjectDocumentUploadOptions(formData)
    await this.setState({
      documentUploadOptions: this.props.user.documentUploadOptions,
    })
    this.props.onChange({
      documentUploadOptions: this.props.user.documentUploadOptions,
    })
    await this.setState({ loading: false })
  }

  onChangeAdditionalInfoSelection = (e, index) => {
    let { documentUploadOptions } = this.state
    documentUploadOptions.additionalOptions[index].Value = e.target.value
    this.setState({ documentUploadOptions: documentUploadOptions })
    this.props.onChange({ documentUploadOptions: documentUploadOptions })
  }

  onChangeDiscipline = (selectedDiscipline) => {
    this.props.onChange({
      discipline: selectedDiscipline,
      documentType: null,
      category: null,
      subcategory: null,
      manufacturer: null,
    })
  }

  renderUploadOptions() {
    let { documentUploadOptions } = this.state

    let disciplineOptions = []
    let documentTypeOptions = []
    let categoryOptions = []
    let subcategoryOptions = []
    let manufacturerOptions = []

    if ('disciplines' in documentUploadOptions && documentUploadOptions.disciplines.length) {
      disciplineOptions = this.state.documentUploadOptions.disciplines

      disciplineOptions.forEach((discipline) => {
        if (this.props.discipline && discipline.id === parseInt(this.props.discipline.value)) {
          documentTypeOptions = discipline.DocumentTypes
          if (this.props.product === 'iDocuments-Datasheet') {
            manufacturerOptions = discipline.Manufacturers
            if (!('Categories' in discipline)) {
              return
            }
            categoryOptions = discipline.Categories
            categoryOptions.forEach((category) => {
              if (this.props.category && category.id === parseInt(this.props.category.value)) {
                subcategoryOptions = category.SubCategories
              }
            })
          }
        }
      })
    }

    if (disciplineOptions.length || ('additionalOptions' in this.state.documentUploadOptions && this.state.documentUploadOptions.additionalOptions.length)) {
      return (
        <div className={`${this.props.modal ? '' : 'col-3'}`} id="unique-88654279div_1">
          <div className="fieldzone" id="unique-56398284div_2">
            {disciplineOptions.length ? (
              <>
                {'uploadStructure' in this.props ? (
                  <div className="mb-3" id="unique-25368082div_3">
                    <label
                      onClick={() =>
                        this.props.onChange({
                          uploadStructure: !this.props.uploadStructure,
                        })
                      }
                      className={`checkbox ${this.props.uploadStructure ? 'checked' : ''}`}
                    />{' '}
                    Upload Directory Structure
                  </div>
                ) : null}

                {'notMandatory' in this.props ? (
                  <div className="mb-3 d-none" id="unique-17941950div_4">
                    <label
                      onClick={() =>
                        this.props.onChange({
                          notMandatory: !this.props.notMandatory,
                        })
                      }
                      className={`checkbox ${this.props.notMandatory ? 'checked' : ''}`}
                    />{' '}
                    Automatic selection of Document/Discipline type
                  </div>
                ) : null}

                <div className="mb-3" id="unique-44057547div_5">
                  <label id="unique-31371877label_3">Discipline</label>{' '}
                  <span className="small text-muted" id="unique-93723994span_1">
                    (*)
                  </span>
                  <Select value={this.props.discipline} options={disciplineOptions} onChange={this.onChangeDiscipline} />
                </div>

                {documentTypeOptions.length && 'documentType' in this.props && this.props.discipline ? (
                  <div className="mb-3" id="unique-10219173div_6">
                    <label id="unique-11227779label_4">Document Type</label>{' '}
                    {this.props.product === 'iDrawings-PID' ? (
                      <span className="small text-muted" id="unique-11575334span_2">
                        (*)
                      </span>
                    ) : null}
                    <Select
                      value={this.props.documentType}
                      options={documentTypeOptions}
                      onChange={(selectedDocumentType) => {
                        this.props.onChange({
                          documentType: selectedDocumentType,
                        })
                      }}
                    />
                  </div>
                ) : null}

                {categoryOptions.length && 'category' in this.props && !(this.props.documentType && this.props.product !== 'iDocuments-Datasheet') ? (
                  <div className="mb-3" id="unique-38202438div_7">
                    <label id="unique-52689903label_5">Category</label>
                    <Select
                      value={this.props.category}
                      options={categoryOptions}
                      onChange={(selectedCategoryOption) => {
                        this.props.onChange({
                          category: selectedCategoryOption,
                          subcategory: null,
                        })
                      }}
                    />
                  </div>
                ) : null}

                {subcategoryOptions.length && 'subcategory' in this.props && !(!this.props.documentType && this.props.product !== 'iDocuments-Datasheet') ? (
                  <div className="mb-3" id="unique-14899187div_8">
                    <label id="unique-90577433label_6">Subcategory</label>
                    <Select
                      value={this.props.subcategory}
                      options={subcategoryOptions}
                      onChange={(selectedSubcategoryOption) => {
                        this.props.onChange({
                          subcategory: selectedSubcategoryOption,
                        })
                      }}
                    />
                  </div>
                ) : null}

                {manufacturerOptions.length && 'manufacturer' in this.props && !(!this.props.documentType && this.props.product !== 'iDocuments-Datasheet') ? (
                  <div className="mb-3" id="unique-72921664div_9">
                    <label id="unique-11287137label_7">Manufacturer</label>
                    <Select
                      value={this.props.manufacturer}
                      options={manufacturerOptions}
                      onChange={(selectedManufacturerOption) => {
                        this.props.onChange({
                          manufacturer: selectedManufacturerOption,
                        })
                      }}
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            {'additionalOptions' in this.state.documentUploadOptions && this.state.documentUploadOptions.additionalOptions.length
              ? this.state.documentUploadOptions.additionalOptions.map((option, index) => {
                  let options = []
                  if (this.state.add_mode_index !== index && option.multiple) {
                    option.Options.forEach((_option) => {
                      options.push({
                        value: _option.id,
                        label: _option.Name,
                      })
                    })
                  }

                  return (
                    <div key={index} className={`mb-3`} id="unique-36587941div_10">
                      <label id="unique-11188508label_8">{option.Name}</label>
                      <div className="row" id="unique-89787552div_11">
                        <div className="col" id="unique-35437002div_12">
                          {this.state.add_mode_index === index || !option.multiple ? (
                            <input type={option.type} value={option.Value} className="form-control" onChange={(e) => this.onChangeAdditionalInfoSelection(e, index)} />
                          ) : (
                            <Select
                              options={options}
                              onChange={(selectedOption) => {
                                let _value = ''
                                if (selectedOption) {
                                  _value = selectedOption.value
                                }

                                this.onChangeAdditionalInfoSelection({ target: { value: _value } }, index)
                              }}
                            />
                          )}
                        </div>
                        {option.multiple && option.type === 'text' ? (
                          <div className="col-3" id="unique-80049302div_13">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => {
                                this.onChangeAdditionalInfoSelection(
                                  {
                                    target: {
                                      value: '',
                                    },
                                  },
                                  index
                                )
                                if (this.state.add_mode_index === '') {
                                  this.setState({
                                    add_mode_index: index,
                                  })
                                } else {
                                  this.setState({
                                    add_mode_index: '',
                                  })
                                }
                              }}
                            >
                              {this.state.add_mode_index === index ? 'Cancel' : 'Add'}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      )
    }

    return null
  }

  render() {
    if (!this.state.loading) {
      return this.renderUploadOptions()
    }
    return (
      <div className={`${this.props.modal ? '' : 'col-3 text-light'}`} id="unique-10279912div_14">
        <h6 className="text-center">
          Fetching Options <i className="fa fa-spinner fa-spin" id="30077385" />
        </h6>
      </div>
    )
  }
}

export default connect(null, {
  fetchProjectDocumentUploadOptions,
})(UploadOptions)
