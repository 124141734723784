import ips from '../apis'
import { setAccessToken, getAccessToken, setEmailID, setUsername, setUserId } from './access'

const errorDispatcher = (dispatch, error, showInvalidCredentialsMessage = false) => {
  let messageCode = null

  if (error.code === 'ERR_NETWORK') {
    messageCode = 'err_network'
  } else if (error.response) {
    switch (error.response.status) {
      case 401:
        dispatch({ type: 'CLEAR_DATA' })
        if (showInvalidCredentialsMessage) {
          messageCode = 'err_invalid_credentials'
        } else {
          messageCode = error.response.data.messageCode || 'err_unauthorized'
        }
        break
      case 404:
        messageCode = error.response.data.messageCode || 'err_not_found'
        break
      case 504:
        messageCode = 'err_request_timeout'
        break
      case 502:
        messageCode = 'err_bad_gateway'
        break
      default:
        messageCode = error.response.data.messageCode
    }
  }

  if (messageCode) {
    dispatch({
      type: 'RESPONSE_MESSAGE',
      payload: { messageCode },
    })
  }
}

export const clearDisplayMessage = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_DISPLAY_MESSAGE' })
}

// User Actions
export const loginUser = (email, password) => async (dispatch) => {
  try {
    const response = await ips.post(
      '/api/login/',
      {
        email: email,
        password: password,
      },
      { withCredentials: true }
    )
    setAccessToken(response.data.token)
    setEmailID(response.data.email)
    setUsername(response.data.name)
    setUserId(response.data.id)
    // console.log("EMAIL AND USERNAME AND ID======>" , getEmailId(), getUsername(), getuserId())
    dispatch({ type: 'LOGIN_USER', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error, true)
  }
}

export const logoutUser = () => async (dispatch) => {
  try {
    await ips.get('/api/logout/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
      withCredentials: true,
    })
  } catch (error) {
    console.log(error)
  }
  setAccessToken(null)
  dispatch({ type: 'CLEAR_DATA' })
}

export const refreshUserAuth = () => async (dispatch) => {
  try {
    const response = await ips.get('/api/refresh-user-auth/', {
      withCredentials: true,
    })
    setAccessToken(response.data.token)
    dispatch({ type: 'REFRESH_USER_AUTH' })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchActions = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.get('/api/fetch-actions/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'USER_ACTIONS', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 404) {
      dispatch({
        type: 'RESPONSE_MESSAGE',
        payload: {
          messageCode: 'err_not_found',
        },
      })
    }
  }
}

export const fetchVersionDetails = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.get('/api/version-info/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'VERSION_DETAILS', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 404) {
      dispatch({
        type: 'RESPONSE_MESSAGE',
        payload: {
          messageCode: 'err_not_found',
        },
      })
    }
  }
}

// TODO: Update RESET_PASSWORD_ERROR_MESSAGE to errorCodes
export const updatePassword = (formData) => async (dispatch) => {
  try {
    const response = await ips.post('/api/update-password/', formData)
    dispatch({ type: 'RESET_PASSWORD', payload: response.data })
  } catch (error) {
    if (error.response.status === 500 || error.response.status === 401) {
      dispatch({
        type: 'RESET_PASSWORD_ERROR_MESSAGE',
        payload: error.response.data,
      })
    } else if (error.response.status === 404) {
      dispatch({
        type: 'RESPONSE_MESSAGE',
        payload: {
          messageCode: 'err_not_found',
        },
      })
    }
  }
}

export const resetPassword = (formData) => async (dispatch) => {
  try {
    const response = await ips.post('/api/reset-password/', formData)
    dispatch({ type: 'RESET_PASSWORD', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const forceResetPassword = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/force-reset-user-password/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const contactSupport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/contact-support/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProducts = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/product-info/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_PRODUCTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchUserProducts = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/user-product/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_USER_PRODUCTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjects = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/project/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_PROJECTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectsData = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/project-data/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_PROJECTS_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchSingleProjectData =
  (projectId, with_pdfs = false) =>
  async (dispatch) => {
    if (!getAccessToken()) {
      dispatch({ type: 'LOGOUT_USER' })
      return
    }

    try {
      const response = await ips.post(
        '/api/single-project-data/',
        {
          projectId: projectId,
          with_pdfs: with_pdfs,
        },
        {
          headers: { Authorization: `JWT ${getAccessToken()}` },
        }
      )
      dispatch({
        type: 'FETCH_SINGLE_PROJECT_DATA',
        payload: response.data,
      })
    } catch (error) {
      errorDispatcher(dispatch, error)
    }
  }

export const fetchSingleProjectDataPdfs = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/single-project-data-pdfs/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_SINGLE_PROJECT_DATA_PDFS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectFiles = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-files/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_PROJECT_FILES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateFileStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-files-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_FILE_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateAssignmentStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-assignment-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_ASSIGNMENT_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const reUploadFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/re-upload-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const downloadHotspotXML = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-hotspot-xml-deliverables/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'HOTSPOT_XML', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateColumnWidths = (columnWidths, page) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    if (page === 'PDFListView') {
      dispatch({ type: 'PDF_LIST_COLUMN_WIDTHS', payload: columnWidths })
    } else if (page === 'DrawingListView') {
      dispatch({
        type: 'DRAWING_LIST_COLUMN_WIDTHS',
        payload: columnWidths,
      })
    } else {
      dispatch({
        type: 'DS_PDF_LIST_COLUMN_WIDTHS',
        payload: columnWidths,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const downloadDeliverables = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/download-deliverables/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DOWNLOAD_DELIVERABLES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const sppidProcessingStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/sppid-processing-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'SPPID_PROCESSING_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchSingleProjectDrawing = (projectId, drawingId) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/project-drawing/', { projectId: projectId, drawingId: drawingId }, { headers: { Authorization: `JWT ${getAccessToken()}` } })
    dispatch({
      type: 'FETCH_SINGLE_PROJECT_ACTIVE_DRAWING',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/upload-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadSharepointPath = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/upload-from-sharepoint-path/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'UPLOAD_SHAREPOINT_PATH_MESSAGE',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchExcelMappingFileRelationshipMap = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-excel-mapping-file-relationship-map/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'EXCEL_MAPPING_FILE_RELATIONSHIP_MAP',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectDocumentUploadOptions = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-document-upload-options-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROJECT_DOCUMENT_UPLOAD_OPTIONS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateFileAttribute = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-file-attribute/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updatePdfUploadStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-pdf-upload-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_PDF_UPLOAD_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadPidUpdateFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/upload-pid-update-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadProjectSetupFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/upload-project-setup-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })

    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const parseLOVExcelFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/parse-lov-excel-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PARSE_LOV_EXCEL_FILE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadSPPIDExcelFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/upload-sppid-excel-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const checkPdf = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  dispatch({ type: 'CLEAR_CHECK_PDF_MESSAGE' })
  try {
    const response = await ips.post('/api/check-pdf/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'CHECK_PDF_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const processDrawings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/process-drawings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROCESS_DRAWINGS', payload: response.data })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const viewDrawings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/view-drawings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROCESS_DRAWINGS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchImageUrl = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/image/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'IMAGE_URL', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProcessDrawings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-process-drawings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROCESS_DRAWINGS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchUpdateProductDeliverableData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-update-product-deliverable-data', formData, { headers: { Authorization: `JWT ${getAccessToken()}` } })
    dispatch({
      type: 'UPDATE_PRODUCT_DELIVERABLE_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDeliverableData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-deliverable-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DELIVERABLE_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const applyCustomization = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/apply-customization/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const dataQualityMetrics = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/data-quality-metrics/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_QUALITY_METRICS_EXCEL',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const dataQualityPlots = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/data-quality-plots/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_QUALITY_PLOTS_DRAWING',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// TODO: Update errors method
export const fetchTagConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-tag-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TAG_CONFIG_DATA', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const fetchTagTemplateData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/add-template/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TAG_TEMPLATE_DATA', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const fetchMatchingTemplates = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/find-matching-templates/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TAG_MATCHING_TEMPLATES', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const tagConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    await ips.post('/api/tag-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const testTagConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/test-tag-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TEST_TAG_CONFIG_DATA', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const fetchTagTitleRegion = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/tag-title-region/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TAG_TITLE_REGION', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const autoTaggingToogle = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    await ips.post('/api/auto-tagging-toggle/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'TAG_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const fetchPropertyConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-property-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROPERTY_CONFIG_DATA', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'PROPERTY_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const propertyConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    await ips.post('/api/property-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'PROPERTY_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const testPropertyConfigData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/test-property-config-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'TEST_PROPERTY_CONFIG_DATA', payload: response.data })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: 'CLEAR_DATA' })
    } else if (error.response.status === 500) {
      dispatch({
        type: 'PROPERTY_CONFIG_DATA_ERROR_MESSAGE',
        payload: error.response.data,
      })
    }
  }
}

export const enableReprocessing = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/enable-reprocessing/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchCADGeneratorParameters = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-cad-generator-parameters/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'CAD_GENERATOR_PARAMETERS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateCADGeneratorParameters = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/update-cad-generator-parameters/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// Admin Actions

export const fetchRoles = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-roles/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_ROLES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchCompanies = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-companies/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_COMPANIES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchMeasurementSystemCodes = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-measure-system-codes/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_MEASUREMENT_SYSTEM_CODES',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchEPCCompanies = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-epccompany', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_EPC_COMPANIES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchClients = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/clients/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_CLIENTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchClient = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-client/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_CLIENT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateClient = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/update-client/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createClient = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/create-client/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteClient = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/delete-client/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchUsers = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/users/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_USERS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchClientAllData = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-client-all_data/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_CLIENTS_ALL_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchUserClientAllData = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-userclient-all_data/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_USER_CLIENTS_ALL_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-user/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_USER', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const unlockUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/unlock-user/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectUserAccess = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-projectuseraccess/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_PROJECT_USER_ACCESS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const assignProjectUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/assign-projectuser/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/update-user/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchAllProducts = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/all-products/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_ALL_PRODUCTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/create-user/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteUser = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/delete-user/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteProduct = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/delete-product/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createProduct = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/create-product/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProduct = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-product/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'SINGLE_PRODUCT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProduct = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/edit-product/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'SINGLE_PRODUCT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchAllProjects = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/all-projects/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_ALL_PROJECTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchSingleProject = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_SINGLE_PROJECT_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProject = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createProject = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/create-project/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteProject = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/delete-project/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createConfig = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/create-config/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const getConfigInfo = (projectId, product) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get(
      '/api/get-config-info/',
      {
        params: {
          project_id: projectId,
          product: product,
        },
      },
      {
        headers: { Authorization: `JWT ${getAccessToken()}` },
      }
    )
    dispatch({ type: 'FETCH_USER_COMPONENT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectSetupStep = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-step/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_SETUP_STEP', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectConfigParameters = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-configuration-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_CONFIG_PARAMETERS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectConfigParameters = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project-configuration-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchCompanyOptions = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-company-options/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_COMPANY_OPTIONS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const clearProjectInfo = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_PROJECT_INFO' })
}

export const fetchProjectPlantTemplate = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-plant-template/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectPlantTemplate = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-plant-template/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectPlantComponents = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-plant-components/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectPlantComponents = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-plant-components/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectDocumentConfig = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-document-config-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectDocumentConfig = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project-document-config-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectUOMInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-uom-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectUOMInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-uom-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectCADInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-cad-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectCADInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-cad-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectReportInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-report-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectReportInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-report-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectDocumentInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-document-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectDocumentInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-document-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectIPSTaginfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-ipstag-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectIPSTaginfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-ipstag-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const addSourceDocumentAttribute = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/add-source-document-attribute/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectTitleboxInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/fetch-project-titlebox-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectTitleboxInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/update-project-titlebox-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const addTitileTypeOption = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  try {
    const response = await ips.post('/api/add-title-type-option/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectCustomParams = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-custom-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectCustomParams = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project-custom-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectAttributeMappings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-attribute-mappings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectAttributeMappings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project-attribute-mappings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchProjectComponentConfigurationInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-project-component-configuration-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProjectComponentConfigurationInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-project-component-configuration-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROJECT_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteDrawings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/delete-drawings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deletePdf = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/delete-pdf/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchCompanyAllData = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-company-all_data', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'COMPANY_ALL_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDisciplineAllData = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-all-discipline', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DISCIPLINE_ALL_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// Datasheet Actions

export const fetchDatasheetUploadOptions = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-upload-options/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DATASHEET_UPLOAD_OPTIONS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDatasheetMappingInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-datasheet-mapping-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DATASHEET_MAPPING_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadDatasheetSetupFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/upload-setup-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDatasheetPdf = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-datasheet-pdf/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_DATASHEET_FILES',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteDocument = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/delete-document/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const processDatasheetPdf = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/process-datasheet-pdf/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const processSingleDatasheetPdf = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/process-single-datasheet-pdf/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DATASHEET_PDF',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDatasheetProcessData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-datasheet-process-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DATASHEET_PDF',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDatasheetProcessUpdates = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-datasheet-process-updates/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'DATASHEET_PROCESS_UPDATES',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchIntermediateFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-intermediate-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_INTERMEDIATE_FILE',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchFile = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-file/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_FILE',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateDatasheetProcessData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-datasheet-process-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'UPDATE_DATASHEET_PROCESS_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const downloadDatasheetExcel = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/download-datasheet-excel/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'DOWNLOAD_DATSHEET_EXCEL',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateDocumentStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-document-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_FILE_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateDocumentAssignmentStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-document-assignment-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_ASSIGNMENT_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}
//  iWorkflow Actions

export const fetchReportParams = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-report-params/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_REPORTS_PARAMS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchReportAdditionalParams = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-report-additional-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_REPORTS_ADDITIONAL_PARAMS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchAvailableReports = () => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.get('/api/fetch-available-reports/', {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_AVAILABLE_REPORTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/upload-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchReports = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/reporting/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_REPORTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const downloadReports = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/download-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DOWNLOAD_REPORTS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchReportList = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-report-list/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_REPORT_LIST', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const uploadClientReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/upload-client-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPLOAD_CLIENT_REPORT_STATUS', payload: response.data })
    dispatch({ type: 'RESPONSE_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const downloadReportUploadStatus = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/download-report-upload-status/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DOWNLOAD_REPORT_UPLOAD_STATUS', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchMasterList = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-master-list/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_MASTER_LIST_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_REPORT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchEditReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-edit-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_EDIT_REPORT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateReportFilter = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('api/update-report-filter/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_REPORT_FILTER', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const saveAndPublishClientReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('api/save-publish-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'SAVE_AND_PUBLISH_CLIENT_REPORT',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const createClientReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/create-client-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'CREATE_CLIENT_REPORT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const downloadClientReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/download-client-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DOWNLOAD_CLIENT_REPORT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const deleteClientReport = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/delete-client-report/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DELETE_CLIENT_REPORT', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// SymbolTagging Config Apis
export const fetchModelsInfo = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-ai-model-info/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'MODELS_INFO', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchDrawingsPackage = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/drawings-package/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'DRAWINGS_PACKAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const processDrawingPackage = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/process-drawing-package/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DRAWINGS_PACKAGE_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateProcessDrawingsPackage = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-process-drawings-package/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DRAWINGS_PACKAGE_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateDrawingsPackageImage = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-drawings-package-image/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DRAWINGS_PACKAGE_IMAGE_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateDrawingsPackageSelection = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/update-drawings-package-selection/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const showSuggestions = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/show-suggestions/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const modelConfigurationDrawings = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/model-configuration-drawings/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DRAWINGS_MODEL_CONFIGURATION_DRAWINGS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateModelConfigurationData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/update-model-configuration-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const modelConfigurationStatusFlags = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/model-configuration-status-flags/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'PROCESS_DRAWINGS_MODEL_CONFIGURATION_STATUS_FLAGS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const revertLastTemplateDetection = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/revert-last-template-detection/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const revertAllChanges = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/revert-all-changes/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const revertAllStatusFlags = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('api/revert-all-status-flags/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'MODEL_CONFIGURATION_REVERT_STATUS_FLAGS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const modelConfigurationInitiateTraining = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/model-configuration-initiate-training/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'MODEL_CONFIGURATION_INITIATE_TRAINING_MESSAGE',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const taggingConfigurationConfigure = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/tagging-configuration-configure/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'TAGGING_CONFIGURATION_FORM_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// IUI Actions
export const fetchIUIData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/interactive/fetch-iui-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'FETCH_IUI_DATA', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const remapIUIData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/interactive/remap-iui-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'REMAP_IUI_DATA_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateIUIData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/interactive/update-iui-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'UPDATE_IUI_DATA_MESSAGE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const revertEnhancedData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/interactive/revert-enhanced-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const checkIUIDataState = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/interactive/check-iui-data-state/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'IUI_ENHANCE_DATA_STATE', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const unlockIUI = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    await ips.post('/api/unlock-iui/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// iDocuments-TradeDocuments actions

export const fetchTradeDocs = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-trade-doc/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'FETCH_TRADE_DOCS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const processTradeDoc = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }
  // dispatch({ type: "CLEAR_PROCESS_TRADE_DOC_MESSAGES" })

  try {
    const response = await ips.post('/api/process-trade-doc/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({ type: 'PROCESS_TRADE_DOC_MESSAGES', payload: response.data })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchTradeDocProcessUpdates = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-trade-doc-process-updates/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'TRADE_DOC_PROCESS_UPDATES',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchTradeIUIData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-trade-iui-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'TRADE_DOCUMENT_IUI_DATA',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const updateTradeIUIData = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/update-trade-iui-data/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'UPDATE_TRADE_IUI_DATA_MESSAGES',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

export const fetchTradeReportParams = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/fetch-trade-reports-params/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'TRADE_DOCUMENT_REPORTS_PARAMS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}

// api/trade-doc-reports/
export const fetchTradeDocReports = (formData) => async (dispatch) => {
  if (!getAccessToken()) {
    dispatch({ type: 'LOGOUT_USER' })
    return
  }

  try {
    const response = await ips.post('/api/trade-doc-reports/', formData, {
      headers: { Authorization: `JWT ${getAccessToken()}` },
    })
    dispatch({
      type: 'TRADE_DOCUMENT_REPORTS',
      payload: response.data,
    })
  } catch (error) {
    errorDispatcher(dispatch, error)
  }
}
