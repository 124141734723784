import React, { Component } from 'react'

class ProcessData extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef(null)
    this.state = {
      pdfTable: 0,
      tabSelectedStyle: [],
      fetchedData: [],
    }
  }

  componentDidMount() {
    this.assembleData()
  }

  componentDidUpdate(props) {
    if (this.props.processedData !== props.processedData) {
      let fetchedProcessData = []

      props.processedData.forEach((data) => {
        if (data !== null) {
          fetchedProcessData.push(data)
        }
      })
      this.setState({ fetchedData: fetchedProcessData })
    }

    if (this.props.saveData !== props.saveData) {
      this.saveDataHandler()
    }
  }

  assembleData = () => {
    let fetchedProcessData = []
    this.props.processedData.forEach((data) => {
      if (data !== null) {
        fetchedProcessData.push(data)
      }
    })

    this.setState({ fetchedData: fetchedProcessData })

    let styleArr = []
    for (let i = 0; i < fetchedProcessData.length; i++) {
      if (i === 0) {
        styleArr.push({
          color: 'black',
          backgroundColor: 'white',
          // border: "0.2px solid rgb(160, 160, 160)",
          borderBottom: 'none',
        })
      } else {
        styleArr.push({
          color: 'white',
          backgroundColor: '#0190b8',
          // border: "0.2px solid rgb(160, 160, 160)",
          // borderBottom: "0.2px solid rgb(160, 160, 160)",
        })
      }
    }
    this.setState({ tabSelectedStyle: styleArr })
  }

  scroll = (scrollOffset) => {
    this.ref.current.scrollLeft += scrollOffset
  }

  changePdfTableHandler = (index, pageNumber) => {
    let styleArr = []
    for (var i = 0; i < this.state.tabSelectedStyle.length; i++) {
      if (i === index) {
        styleArr.push({
          color: 'black',
          backgroundColor: 'white',
          borderBottom: 'none',
        })
      } else {
        styleArr.push({
          color: 'white',
          backgroundColor: '#0190b8',
        })
      }
    }

    this.setState({ tabSelectedStyle: styleArr, pdfTable: index })
    this.props.updatePage(pageNumber)
  }

  addRowHandler = (index, key) => {
    let pdfTable = this.state.pdfTable
    let arrayData = [...this.state.fetchedData]
    let singleArray = arrayData[pdfTable]
    let arrayValue = singleArray.value
    let singleArrayData = arrayValue.data
    let newTag = {
      key: `${Math.round((parseFloat(key) + 0.1) * 10) / 10}`,
      name: '',
      value: '',
      userAdded: true,
    }
    singleArrayData.splice(index + 1, 0, newTag)
    arrayValue = { ...arrayValue, data: singleArrayData }
    singleArray = { ...singleArray, value: arrayValue }
    arrayData[pdfTable] = singleArray
    this.setState({ fetchedData: arrayData })
  }

  deleteRowHandler = (index) => {
    let pdfTable = this.state.pdfTable
    let arrayData = [...this.state.fetchedData]
    let singleArray = arrayData[pdfTable]
    let arrayValue = singleArray.value
    let singleArrayData = arrayValue.data
    singleArrayData.splice(index, 1)
    arrayValue = { ...arrayValue, data: singleArrayData }
    singleArray = { ...singleArray, value: arrayValue }
    arrayData[pdfTable] = singleArray
    this.setState({ fetchedData: arrayData })
  }

  changeHandler = (value, index, keyName) => {
    let pdfTable = this.state.pdfTable
    let arrayData = [...this.state.fetchedData]
    let singleArray = arrayData[pdfTable]
    let arrayValue = singleArray.value
    let singleArrayData = arrayValue.data
    let data = singleArrayData[index]

    data[keyName] = value.slice(0, 3000)

    if (keyName === 'name') {
      data.key_name_updated = true
    } else if (keyName === 'unit') {
      data.unit_updated = true
    } else if (keyName === 'value') {
      data.value_updated = true
    }

    singleArrayData[index] = data
    arrayValue = { ...arrayValue, data: singleArrayData }
    singleArray = { ...singleArray, value: arrayValue }
    arrayData[pdfTable] = singleArray

    this.setState({ fetchedData: arrayData })
  }

  saveDataHandler = async () => {
    this.props.savingData(true)
    let fetchedData = this.state.fetchedData
    fetchedData.forEach((data, index) => {
      let singleArray = data
      let arrayValue = singleArray.value
      let singleArrayData = arrayValue.data
      singleArrayData.forEach((processedData) => {
        return processedData.userAdded ? delete processedData['userAdded'] : null
      })
      arrayValue = { ...arrayValue, data: singleArrayData }
      singleArray = { ...singleArray, value: arrayValue }
      fetchedData.splice(index, 1, singleArray)
    })
    await this.props.updateDatasheet(fetchedData)
    this.props.savingData(false)
  }

  render() {
    if (!this.state.fetchedData.length) {
      return null
    }
    return (
      <div className="table-container p-0" style={{ height: '42.5rem', minHeight: '42.5rem' }} id="unique-44775867div_1">
        <div className="dataTable" id="unique-99244097div_2">
          {this.state.fetchedData.length === 1 ? null : (
            <div className="tabBar" id="unique-23932717div_3">
              <button id="unique-54122729button_1" onClick={() => this.scroll(-150)} className="scrollButton scrollButton-left">
                <i className="fa fa-chevron-left" id="unique-50575522i_1" />
              </button>
              <div className="tags" ref={this.ref} id="unique-68393409div_4">
                {this.state.fetchedData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      id="unique-10940113div_5"
                      className="tag"
                      style={this.state.tabSelectedStyle[index]}
                      onClick={() => this.changePdfTableHandler(index, data.value.page)}
                    >
                      {data.tag}
                    </div>
                  )
                })}
              </div>
              <button id="unique-91347343button_2" onClick={() => this.scroll(150)} className="scrollButton float-right scrollButton-right">
                <i className="fa fa-chevron-right" aria-hidden="true" id="unique-66138190i_2"></i>
              </button>
            </div>
          )}

          <table className={`table table-bordered mb-0 ${!this.props.allowEdit ? 'disable-click' : ''}`}>
            <thead className="thead-light">
              <tr id="unique-11379185tr_1">
                <th className="ds-process-row" id="unique-74182621th_1">
                  Design Parameter
                </th>
                <th className="ds-process-row" id="unique-48133023th_2">
                  Value
                </th>
                <th className="ds-process-row" id="unique-39085433th_3">
                  Unit
                </th>
                <th className="ds-add-button" id="unique-28634064th_4"></th>
              </tr>
            </thead>
            <tbody id="unique-10914366tbody_1">
              {this.state.fetchedData[this.state.pdfTable].value.data.map((data, index) => {
                return (
                  <tr key={index} className="tagData">
                    <td className={`ds-process-row ${data.key_name_updated ? 'bg-warning' : ''}`} id="unique-68481779td_1">
                      <div
                        className="dataInput"
                        id="unique-56280794div_6"
                        contentEditable
                        dangerouslySetInnerHTML={{
                          __html: data.name,
                        }}
                        onBlur={(e) => {
                          if (e.currentTarget.textContent.length > 96) {
                            alert('Design parameter length exceeded.')
                          } else {
                            if (data.name === e.currentTarget.textContent) {
                              return
                            }
                            this.changeHandler(e.currentTarget.textContent, index, 'name')
                          }
                        }}
                      ></div>
                    </td>
                    <td className={`ds-process-row ${data.value_updated ? 'bg-warning' : ''}`} id="unique-61080314td_2">
                      <div
                        className="dataInput"
                        contentEditable
                        dangerouslySetInnerHTML={{
                          __html: data.value,
                        }}
                        id="unique-81892976div_7"
                        onBlur={(e) => {
                          if (e.currentTarget.textContent.length > 3000) {
                            alert('Length of value exceeded.')
                          } else {
                            if (data.value === e.currentTarget.textContent) {
                              return
                            }
                            this.changeHandler(e.currentTarget.textContent, index, 'value')
                          }
                        }}
                      ></div>
                    </td>

                    <td className={`ds-process-row ${data.unit_updated ? 'bg-warning' : ''}`} id="unique-48452435td_3">
                      {this.props.uom_options.length ? (
                        <select
                          style={{
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            boxShadow: 'none',
                          }}
                          onChange={(e) => this.changeHandler(e.target.value, index, 'unit')}
                          value={data.unit ? data.unit : ''}
                        >
                          <option value="">--Select--</option>
                          {this.props.uom_options.map((option) => {
                            return (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            )
                          })}
                        </select>
                      ) : (
                        <div
                          className="dataInput"
                          id="unique-78459495div_8"
                          contentEditable
                          dangerouslySetInnerHTML={{
                            __html: data.unit,
                          }}
                          onBlur={(e) => {
                            if (e.currentTarget.textContent.length > 600) {
                              alert('Length of value exceeded')
                            } else {
                              this.changeHandler(e.currentTarget.textContent, index, 'unit')
                            }
                          }}
                        ></div>
                      )}
                    </td>

                    <td className="ds-add-button" id="unique-13509016td_4">
                      {data.userAdded ? (
                        <button
                          className="btn btn-danger btn-sm btnVisible"
                          id="unique-88037427button_3"
                          onClick={() => {
                            this.deleteRowHandler(index)
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true" id="unique-35800797i_3"></i>
                        </button>
                      ) : null}
                      <button
                        className="btn btn-secondary btn-sm btnVisible"
                        id="unique-50538306button_4"
                        onClick={() => {
                          this.addRowHandler(index, data.key)
                        }}
                      >
                        <i className="fa fa-plus" aria-hidden="true" id="unique-88892257i_4"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ProcessData
