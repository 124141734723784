import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import './NavBar.css'
import { logoutUser } from './../../store/actions'
import VersionDetails from '../WebComponents/VersionDetails'
class NavbarLeft extends React.Component {
  state = {
    logging_out: false,
    showVersionDetails: false,
  }

  handleLogout = async () => {
    await this.setState({ logging_out: true })
    await this.props.logoutUser()
    await this.setState({ logging_out: false })
    this.props.history.push('/')
  }

  closeVersionDetails = () => {
    this.setState({ showVersionDetails: false })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showVersionDetails ? <VersionDetails closeVersionDetails={this.closeVersionDetails} /> : null}
        <nav className="side-nav" id="unique-50113728nav_1">
          <span className="collapse-btn" onClick={() => this.props.collapseLeftNavTrigger(!this.props.leftNavCollapsed)}>
            {this.props.leftNavCollapsed ? <i className="fa fa-angle-right" id="10657448" /> : <i className="fa fa-angle-left" id="10657448" />}
          </span>

          <NavLink id="home_link" className="nav-item" activeClassName="nav-item-active" to="/dashboard" title="Product Dashboard">
            <div className="row">
              <div className="col-2">
                <i className="fas fa-home" />
              </div>
              {!this.props.leftNavCollapsed ? (
                <div className="col" id="unique-49982699div_3">
                  Home
                </div>
              ) : null}
            </div>
          </NavLink>

          {this.props.dashboardType !== 'admin' ? (
            <React.Fragment>
              {this.props.user.user_actions.includes('admin_view') ? (
                <NavLink id="cms_link" className="nav-item" activeClassName="nav-item-active" to="/admin" title="CMS">
                  <div className="row">
                    <div className="col-2">
                      <i className="fas fa-tachometer-alt" />
                    </div>
                    {!this.props.leftNavCollapsed ? (
                      <div className="col" id="unique-91210306div_6">
                        CMS
                      </div>
                    ) : null}
                  </div>
                </NavLink>
              ) : null}

              <NavLink id="account_link" className="nav-item" activeClassName="nav-item-active" to="/account" title="Account">
                <div className="row">
                  <div className="col-2">
                    <i className="fas fa-user" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-92623804div_9">
                      Account
                    </div>
                  ) : null}
                </div>
              </NavLink>

              <NavLink id="contact_support_btn" className="nav-item" to="/contact-support" activeClassName="nav-item-active" title="Contact Support">
                <div className="row">
                  <div className="col-2">
                    <i className="fas fa-phone fa-solid fa-flip-horizontal" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-25967715div_12">
                      Contact Support
                    </div>
                  ) : null}
                </div>
              </NavLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink id="clients_link" className="nav-item" activeClassName="nav-item-active" to="/admin/clients" title="Clients">
                <div className="row">
                  <div className="col-2">
                    <i className="fas fa-users" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-78263222div_15">
                      Clients
                    </div>
                  ) : null}
                </div>
              </NavLink>

              <NavLink id="users_link" className="nav-item" activeClassName="nav-item-active" to="/admin/users" title="Users">
                <div className="row">
                  <div className="col-2">
                    <i className="fa fa-users" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-14289700div_18">
                      Users
                    </div>
                  ) : null}
                </div>
              </NavLink>

              <NavLink id="products_link" className="nav-item" activeClassName="nav-item-active" to="/admin/products" title="Products">
                <div className="row">
                  <div className="col-2">
                    <i className="fas fa-cubes" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-20032554div_21">
                      Products
                    </div>
                  ) : null}
                </div>
              </NavLink>

              <NavLink id="projects_link" className="nav-item" activeClassName="nav-item-active" to="/admin/projects" title="Projects">
                <div className="row">
                  <div className="col-2">
                    <i className="fas fa-tasks" />
                  </div>
                  {!this.props.leftNavCollapsed ? (
                    <div className="col" id="unique-57762425div_24">
                      Projects
                    </div>
                  ) : null}
                </div>
              </NavLink>
            </React.Fragment>
          )}

          <span id="logout_btn" className="nav-item" onClick={this.handleLogout} title="Logout">
            <div className="row" id="unique-25926752div_25">
              <div className="col-2" id="unique-86658531div_26">
                <i className="fa fa-power-off" id="10657448" />
              </div>
              {!this.props.leftNavCollapsed ? (
                <div className="col" id="unique-91682832div_27">
                  {this.state.logging_out ? 'Logging Out ...' : 'Logout'}
                </div>
              ) : null}
            </div>
          </span>

          <div className="version nav-item" id="unique-74834628div_28">
            <span className="label" id="unique-56067721span_2">
              Version
            </span>
            {` `}
            <span id="unique-60447208span_3">4.6.0</span>
            {/* <i
              className="fas fa-info-circle ml-2 d-none"
              onClick={() =>
                this.setState({
                  showVersionDetails: !this.state.showVersionDetails,
                })
              }
            /> */}
          </div>
        </nav>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { logoutUser })(NavbarLeft)
