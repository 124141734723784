import React from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'

import UploadOptions from './UploadOptions'
import Input from './Fields/input'

import { uploadSharepointPath, fetchExcelMappingFileRelationshipMap } from './../../store/actions'

class UploadSharePoint extends React.Component {
  state = {
    message: null,
    uploading: false,
    viewModifyMapping: false,
    projectId: this.props.projectId,
    product: this.props.product,
    documentUploadOptions: {},
    directoryPathTemplate: [],
    templateOptions: [],
    excelFileRelationshipMap: {},
    discipline: null,
    documentType: null,
    sharepoint_path: '',
    uploadStructure: false,
    mapping_file: null,
    errorMessage: null,
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  handleDocument = async (files) => {
    if (files[0].size > 100 * 1024 * 1024) {
      this.setState({
        errorMessage: `${files[0].name}: Max allowed file size is 100MB`,
      })
      return
    }

    this.fetchExcelMappingFileRelationshipMap(files[0])
  }

  fetchExcelMappingFileRelationshipMap = async (mapping_file) => {
    let { projectId, product } = this.state

    const formData = new FormData()
    formData.append('product', product)
    formData.append('projectId', projectId)
    formData.append('mappingFile', mapping_file)
    formData.append('mappingFileName', mapping_file.name)

    await this.props.fetchExcelMappingFileRelationshipMap(formData)

    if (this.props.user.displayMessageCode || this.props.user.excelMappingFileRelationshipMap.errorMessage) {
      if (this.props.user.displayMessageCode && this.props.user.displayMessageCode.message.includes('err_')) {
        return
      }

      if (this.props.user.excelMappingFileRelationshipMap.errorMessage) {
        this.setState({ errorMessage: this.props.user.excelMappingFileRelationshipMap.errorMessage })
        return
      }
    }

    this.setState({
      templateOptions: this.props.user.excelMappingFileRelationshipMap.templateOptions,
      excelFileRelationshipMap: this.props.user.excelMappingFileRelationshipMap.excelFileRelationshipMap,
      mapping_file: mapping_file,
    })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    let { documentUploadOptions, discipline, documentType, projectId, product, sharepoint_path, mapping_file, directoryPathTemplate, excelFileRelationshipMap } = this.state

    await this.setState({ uploading: true })

    const formData = new FormData()
    formData.append('product', product)
    formData.append('projectId', projectId)
    formData.append('sharepointPath', sharepoint_path)
    formData.append('directoryPathTemplate', directoryPathTemplate)
    formData.append('uploadStructure', this.state.uploadStructure)

    if ('disciplines' in documentUploadOptions && documentUploadOptions.disciplines.length) {
      formData.append('discipline', discipline ? discipline.value : '')
      formData.append('documenttype', documentType ? documentType.value : '')
    }

    if ('additionalOptions' in documentUploadOptions && documentUploadOptions.additionalOptions.length) {
      formData.append('additionalOptions', JSON.stringify(documentUploadOptions.additionalOptions))
    }

    if (mapping_file) {
      formData.append('mappingFile', mapping_file)
      formData.append('mappingFileName', mapping_file.name)
    }

    formData.append('excelFileRelationshipMap', JSON.stringify(excelFileRelationshipMap))

    await this.props.uploadSharepointPath(formData)
    await this.setState({ uploading: false })
    if (this.props.user.displayMessageCode || this.props.user.uploadSharePointPathMessage.errorMessage) {
      if (this.props.user.uploadSharePointPathMessage.errorMessage) {
        this.setState({ errorMessage: this.props.user.uploadSharePointPathMessage.errorMessage })
      } else {
        this.props.close()
      }
    } else {
      this.setState({
        message: this.props.user.uploadSharePointPathMessage.message,
      })
      setTimeout(() => {
        this.setState({ message: null })
        this.props.close()
      }, 5000)
      this.props.reloadData()
    }
  }

  updateDirectoryPathTemplate = (e, removeIndex = null) => {
    let { directoryPathTemplate } = this.state

    if (e) {
      directoryPathTemplate.push(e.target.value)
    } else {
      directoryPathTemplate.splice(removeIndex, 1)
    }

    this.setState({ directoryPathTemplate: directoryPathTemplate })
  }

  addMapping = () => {
    let { excelFileRelationshipMap } = this.state
    excelFileRelationshipMap.attribute_mapping.push({
      header: '',
      attribute: '',
    })
    this.setState({ excelFileRelationshipMap: excelFileRelationshipMap })
  }

  deleteMapping = (index) => {
    let { excelFileRelationshipMap } = this.state
    excelFileRelationshipMap.attribute_mapping.splice(index, 1)
    this.setState({ excelFileRelationshipMap: excelFileRelationshipMap })
  }

  onChangeMappingValue = (e, index) => {
    let { excelFileRelationshipMap } = this.state
    excelFileRelationshipMap.attribute_mapping[index][e.target.name] = e.target.value
    this.setState({ excelFileRelationshipMap: excelFileRelationshipMap })
  }

  checkIfUploadDisabled = () => {
    if (this.state.uploading || !this.state.sharepoint_path || this.state.viewModifyMapping) {
      return true
    }

    if (this.state.mapping_file) {
      if (!this.state.directoryPathTemplate.length) {
        return true
      }
    } else {
      if (!this.state.discipline || (this.props.product !== 'iDocuments-Datasheet' && !this.state.documentType) || this.state.directoryPathTemplate.length) {
        return true
      }
    }
    return false
  }

  renderModifyMappingForm = () => {
    let { excelFileRelationshipMap } = this.state
    return (
      <div className="table-container p-0" style={{ height: '38rem' }} id="unique-55948345div_1">
        <table className="table table-striped">
          <thead className="thead-light">
            <tr id="unique-57556182tr_1">
              <th id="unique-83316195th_1">Column Headers</th>
              <th id="unique-18296423th_2">Project Attributes</th>
              <th id="unique-96826167th_3"></th>
            </tr>
          </thead>
          <tbody id="unique-14922973tbody_1">
            {excelFileRelationshipMap.attribute_mapping.map((map, index) => {
              return (
                <tr key={index} id="unique-78940177tr_2">
                  <td id="unique-72153645td_1">
                    <select name="header" className="form-control form-control-sm" value={map.header} onChange={(e) => this.onChangeMappingValue(e, index)}>
                      <option value={''}>--Select--</option>
                      {excelFileRelationshipMap.header_options.map((header) => {
                        return (
                          <option value={header} key={header}>
                            {header}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                  <td id="unique-61388176td_2">
                    <select name="attribute" className="form-control form-control-sm" value={map.attribute} onChange={(e) => this.onChangeMappingValue(e, index)}>
                      <option value={''}>--Select--</option>
                      {excelFileRelationshipMap.attribute_options.map((attribute) => {
                        return (
                          <option value={attribute} key={attribute}>
                            {attribute}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                  <td id="unique-10742891td_3">
                    <button className="btn text-secondary" type="button" onClick={() => this.deleteMapping(index)}>
                      <i className="fa fa-trash-alt" id="56282324" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <form id="upload_sharepoint_path_form" className="modal" onSubmit={this.onSubmit}>
        <div className="modal-dialog modal-dialog-centered modal-lg" id="unique-37074843div_2">
          <div className="modal-content" id="unique-38705054div_3">
            <div className="modal-header" id="unique-58120038div_4">
              <button type="button" className="close" onClick={() => this.props.close()}>
                <span aria-hidden="true" id="unique-10069428span_1">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="unique-13304153div_5">
              {this.state.message ? (
                <div className="alert alert-success" id="unique-46839096div_6">
                  {this.state.message}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ message: null })}>
                    <span aria-hidden="true" id="unique-38169287span_2">
                      &times;
                    </span>
                  </button>
                </div>
              ) : null}

              {this.state.errorMessage ? (
                <div className="alert alert-danger" id="unique-46839096div_6_1">
                  {this.state.errorMessage}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: null })}>
                    <span aria-hidden="true" id="unique-38169287span_2_1">
                      &times;
                    </span>
                  </button>
                </div>
              ) : null}

              <div className={`row ${this.state.viewModifyMapping ? 'd-none' : ''}`} id="unique-88060222div_7">
                <div className="col-7" id="unique-37295778div_8">
                  <UploadOptions
                    projectId={this.state.projectId}
                    uploadStructure={this.state.uploadStructure}
                    product={this.state.product}
                    onChange={this.onChange}
                    discipline={this.state.discipline}
                    documentType={this.state.documentType}
                    disciplineRequired={!this.state.mapping_file}
                    modal={true}
                    {...this.props}
                  />
                </div>
                <div className="col-5">
                  <div className="mb-3">
                    <Input type="text" label="SharePoint Path" name="sharepoint_path" onChange={(obj) => this.setState(obj)} value={this.state.sharepoint_path} required={true} />
                  </div>

                  <div className="mb-3" id="unique-34175331div_11">
                    <label id="unique-43595293label_2">Generate Consolidated Transmittal Register</label>
                    <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles)}>
                      {({ getRootProps, getInputProps }) => (
                        <button id="mapping_excel_upload_btn" type="button" {...getRootProps()} className="btn btn-primary d-block">
                          <input {...getInputProps()} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple={false} />
                          <i className="fa fa-file-excel" /> Mapping Excel
                        </button>
                      )}
                    </Dropzone>
                    <div className="mt-1" id="unique-16888809div_12">
                      {this.state.mapping_file ? (
                        <span className="d-inline-block bg-light p-2 border rounded text-truncate d-inline-block" style={{ maxWidth: '100%' }} id="unique-38357111span_4">
                          <i
                            className="far fa-trash-alt cursor-pointer text-secondary mr-2"
                            onClick={() =>
                              this.setState({
                                mapping_file: null,
                              })
                            }
                          />{' '}
                          <i className="fa fa-file-excel text-success" id="56282324" /> {this.state.mapping_file.name}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="mb-3" id="unique-37436829div_13">
                    <label id="unique-10379697label_3">Directory Path Template</label>
                    <select className="form-control mb-1" disabled={!this.state.templateOptions.length} onChange={(e) => this.updateDirectoryPathTemplate(e)}>
                      <option value={''}>--Select--</option>
                      {this.state.templateOptions.map((option) => {
                        if (!this.state.directoryPathTemplate.includes(option)) {
                          return (
                            <option value={option} key={option}>
                              {option}
                            </option>
                          )
                        }
                        return null
                      })}
                      <option value={'-'}>-</option>
                      <option value={'/'}>/</option>
                    </select>
                    {this.state.directoryPathTemplate.map((template, index) => {
                      return (
                        <span
                          className="d-inline-block m-1 bg-light border p-1 cursor-pointer"
                          key={template}
                          style={{ fontSize: 12 }}
                          onClick={() => this.updateDirectoryPathTemplate(null, index)}
                        >
                          {template}
                        </span>
                      )
                    })}
                  </div>

                  <div id="unique-93031775div_14">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!('attribute_mapping' in this.state.excelFileRelationshipMap)}
                      onClick={() => this.setState({ viewModifyMapping: !this.state.viewModifyMapping })}
                    >
                      <i className="fa fa-edit" id="56282324" /> Modify Mapping
                    </button>
                  </div>
                </div>
              </div>
              {this.state.viewModifyMapping ? (
                <>
                  <div className="clearfix mb-3" id="unique-21860008div_15">
                    <button type="button" className="btn btn-sm btn-primary float-right" onClick={this.addMapping} id="unique-93293414button_5">
                      Add
                    </button>
                    <button type="button" className="btn btn-sm btn-default float-right mr-2" onClick={() => this.setState({ viewModifyMapping: !this.state.viewModifyMapping })}>
                      Back
                    </button>
                    <h4 className="mb-0" id="unique-96272953h4_1">
                      Mapping Column Headers to Project Attributes
                    </h4>
                  </div>
                  {this.renderModifyMappingForm()}
                </>
              ) : null}
            </div>

            <div className="modal-footer" id="unique-48959792div_16">
              <button id="upload_sharepoint_path_cancel_btn" type="button" className="btn btn-default" onClick={() => this.props.close()}>
                Cancel
              </button>

              <button id="upload_sharepoint_path_submit_btn" type="submit" className="btn btn-primary" disabled={this.checkIfUploadDisabled()}>
                Upload
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  uploadSharepointPath,
  fetchExcelMappingFileRelationshipMap,
})(UploadSharePoint)
