import React, { Component } from 'react'
import Select from './../../Forms/Fields/select'

import UploadLOVExcel from '../../Forms/UploadLOVExcel'
import LinkedProjectInfoBox from './LinkProjectInfoBox'
import Input from '../Fields/input'

export default class ProjectSetupS8 extends Component {
  state = {
    components: [],
    loading: false,
    formLoader: false,
    tags: [],
    custom: {},
    sub_level_custom: {},
    disciplines: [],
    toggledTagIndex: '',
    screen_blocked: false,
    select_similar_mode: [],
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectIPSTaginfo(formData)
    await this.setState({
      loading: false,
      tags: this.props.user.projectInfo.tags,
      custom: this.props.user.projectInfo.custom,
      sub_level_custom: this.props.user.projectInfo.sub_level_custom,
      disciplines: this.props.user.projectInfo.disciplines,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('tags', JSON.stringify(this.state.tags))
    formData.append('step', 9)
    await this.props.updateProjectIPSTaginfo(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  addCustom = async (path) => {
    let { tags, custom, sub_level_custom } = this.state
    path = path.split('.')
    tags = JSON.parse(JSON.stringify(tags))
    if (path.length === 1) {
      let max_prefix = 0
      tags[path[0]].selected_options.forEach((option) => {
        if (option['IPS Name'].includes('Custom')) {
          let prefix = option['IPS Name'].split(':')[option['IPS Name'].split(':').length - 1]
          if (max_prefix < prefix) {
            max_prefix = prefix
          }
        }
      })
      var _custom = JSON.parse(JSON.stringify(custom))
      _custom['IPS Name'] = `Custom:${parseInt(max_prefix) + 1}`
      _custom['label'] = `Custom:${parseInt(max_prefix) + 1}`
      _custom['value'] = `Custom:${parseInt(max_prefix) + 1}`
      tags[path[0]].options.push(_custom)
      tags[path[0]].selected_options.push(_custom)
    } else if (path.length === 2) {
      let max_prefix = 0
      tags[path[0]].selected_options[path[1]].options.forEach((option) => {
        if (option['IPS Name'].includes('Custom')) {
          let prefix = option['IPS Name'].split(':')[option['IPS Name'].split(':').length - 1]
          if (max_prefix < prefix) {
            max_prefix = prefix
          }
        }
      })

      var _sub_level_custom = JSON.parse(JSON.stringify(sub_level_custom))
      _sub_level_custom['IPS Name'] = `Custom:${parseInt(max_prefix) + 1}`
      _sub_level_custom['label'] = `Custom:${parseInt(max_prefix) + 1}`
      _sub_level_custom['value'] = `Custom:${parseInt(max_prefix) + 1}`
      tags[path[0]].selected_options[path[1]].options.push(_sub_level_custom)
      tags[path[0]].selected_options[path[1]].selected_options.push(_sub_level_custom)
    }
    await this.setState({ tags: tags })
  }

  selectAll = async (path) => {
    let { tags } = this.state
    path = path.split('.')
    if (path.length === 1) {
      if (tags[path[0]].selected_options.length !== tags[path[0]].options.length) {
        tags[path[0]].selected_options = tags[path[0]].options
      } else {
        tags[path[0]].selected_options = []
      }
    } else if (path.length === 2) {
      if (tags[path[0]].selected_options[path[1]].selected_options.length !== tags[path[0]].options[path[1]].options.length) {
        tags[path[0]].selected_options[path[1]].selected_options = tags[path[0]].options[path[1]].options
      } else {
        tags[path[0]].selected_options[path[1]].selected_options = []
      }
    }
    await this.setState({ tags: tags })
  }

  onSelect = async (path, selectedList) => {
    let { tags } = this.state
    path = path.split('.')
    if (path.length === 1) {
      tags[path[0]].selected_options = selectedList
    } else if (path.length === 2) {
      tags[path[0]].selected_options[path[1]].selected_options = selectedList
    }
    await this.setState({ tags: tags })
  }

  handleInputChange = async (e, path) => {
    let { tags } = this.state
    path = path.split('.')
    if (path.length === 2) {
      tags[path[0]].selected_options[path[1]][e.target.name] = e.target.value
      if (e.target.name === 'Discipline') {
        let DocumentTypes = []
        tags[path[0]].selected_options[path[1]].Discipline.forEach((discipline) => {
          tags[path[0]].selected_options[path[1]]['Drawing Type'].forEach((documentType) => {
            this.state.disciplines.forEach((_discipline) => {
              if (discipline.id === _discipline.id) {
                _discipline.DocumentTypes.forEach((_documentType) => {
                  if (_documentType.id === documentType.id) {
                    DocumentTypes.push(documentType)
                  }
                })
              }
            })
          })
        })
        tags[path[0]].selected_options[path[1]]['Drawing Type'] = DocumentTypes
      }
    } else if (path.length === 3) {
      if (tags[path[0]].selected_options[path[1]].selected_options.findIndex((option) => option[e.target.name] === e.target.value) === -1) {
        tags[path[0]].selected_options[path[1]].selected_options[path[2]][e.target.name] = e.target.value
      }
    }
    await this.setState({ tags: tags })
  }

  isNextButtonDisabled = () => {
    const { tags, disciplines } = this.state
    let isDisabled = false
    let tooltipMessage = ''

    for (const tag of tags) {
      for (const option of tag.selected_options) {
        const hasDiscipline = option['Discipline'].length > 0
        const hasDrawingType = option['Drawing Type'].length > 0

        if (!hasDiscipline || !hasDrawingType) {
          isDisabled = true
          tooltipMessage = `${option['IPS Name']} Tag Class must have at least one Discipline and at least one corresponding Drawing Type selected.`
          break
        }

        for (const selectedDiscipline of option['Discipline']) {
          const discipline = disciplines.find((d) => d.id === selectedDiscipline.id)
          if (discipline) {
            const hasValidDrawingType = option['Drawing Type'].some((selectedDrawingType) =>
              discipline.DocumentTypes.some((drawingTypeOption) => drawingTypeOption.id === selectedDrawingType.id)
            )

            if (!hasValidDrawingType) {
              isDisabled = true
              tooltipMessage = `At least one Drawing Type should be selected per Discipline Type specified for ${option['IPS Name']} Tag Class.`
              break
            }
          }
        }

        if (isDisabled) {
          break
        }
      }

      if (isDisabled) {
        break
      }
    }

    return { disabled: isDisabled, title: tooltipMessage }
  }

  // getSearchValue = (searchValue) => {
  //   this.setState({ currentSelectSearchValue: searchValue })
  // }

  renderForm() {
    return (
      <div className="card" id="unique-56614620div_1">
        <div className="clearfix card-header bg-light" id="unique-75978153div_2">
          <button
            type="button"
            onClick={() => this.formDataHandler(false)}
            disabled={this.state.formLoader || this.isNextButtonDisabled().disabled}
            title={this.isNextButtonDisabled().title}
            className="btn btn-primary float-right"
          >
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button
            type="button"
            onClick={() => this.formDataHandler(true)}
            disabled={this.state.formLoader || this.isNextButtonDisabled().disabled}
            title={this.isNextButtonDisabled().title}
            className="btn btn-primary float-right mr-2"
          >
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-82564464button_4">
            Cancel
          </button>

          <h3 id="unique-76659437h3_1">IPS Tag Class Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="57297489" /> : null}</h3>
        </div>
        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-43238113div_3">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}
          {this.state.tags.map((tag, index) => {
            return (
              <div key={tag.id} className="mb-4" id="unique-66598401div_4">
                <div className="card bg-light mb-3" id="unique-43517305div_5">
                  <div className="card-body p-2" id="unique-93545917div_6">
                    <div className="clearfix mb-2" id="unique-73859165div_7">
                      <label id="unique-23101899label_1">{tag['IPS Name']}</label>
                      <span className="float-right d-inline-block mt-1" id="unique-91888877span_1">
                        <label className={`checkbox mr-1 ${tag.options.length === tag.selected_options.length ? 'checked' : ''}`} onClick={() => this.selectAll(`${index}`)} />{' '}
                        Select All
                      </span>
                      <button type="button" className="btn btn-primary btn-sm float-right mr-2" onClick={() => this.addCustom(`${index}`)}>
                        Add
                      </button>
                    </div>
                    <Select
                      options={tag.options.map((option) => {
                        return {
                          label: option.label,
                          value: option.label,
                        }
                      })}
                      value={tag.selected_options.map((option) => {
                        return {
                          label: option.label,
                          value: option.label,
                        }
                      })}
                      onChange={(selectedOptions) => {
                        var filteredOptions = tag.options.filter((option) => {
                          return selectedOptions.some((selectedOption) => {
                            return option.label === selectedOption.label
                          })
                        })
                        this.onSelect(`${index}`, filteredOptions)
                      }}
                      isMulti={true}
                    />
                  </div>
                </div>

                {tag.options.length ? (
                  <div className="card rounded-0" id="unique-11833094div_8">
                    <div className="card-body p-2 bg-eee" id="unique-85929556div_9">
                      <div className="row align-items-center" id="unique-64340211div_10">
                        {Object.entries(tag.options[0]).map(([key, val]) => {
                          if ((typeof val !== 'object' && !Array.isArray(val) && !['id', 'label', 'value'].includes(key)) || ['Drawing Type', 'Discipline'].includes(key)) {
                            return (
                              <div className={`${['Drawing Type', 'Discipline'].includes(key) ? 'col-3' : 'col'}`} key={key} id="unique-93916176div_11">
                                {key}
                              </div>
                            )
                          }
                          return null
                        })}
                        <div className="col-1" id="57297489" />
                      </div>
                    </div>
                  </div>
                ) : null}

                {tag.selected_options.map((option, _index) => {
                  return (
                    <div className={`card rounded-0 border-0`} key={_index} id="unique-92161948div_13">
                      <div className={`card-body p-2 ${_index % 2 === 1 ? 'bg-light' : ''}`} id="unique-10374312div_14">
                        <div className="row align-items-center" id="unique-21039231div_15">
                          {Object.entries(option).map(([key, val]) => {
                            if (typeof val !== 'object' && !Array.isArray(val) && !['id', 'label', 'value'].includes(key)) {
                              return (
                                <div className="col" key={key}>
                                  <Input
                                    type={'text'}
                                    name={key}
                                    value={val}
                                    path={`${index}.${_index}`}
                                    onChange={this.handleInputChange}
                                    disallowedSymbols={['@', '.', '"']}
                                    disabled={!['Display Name'].includes(key) || option['disabledFields'].includes(key)}
                                  />
                                </div>
                              )
                            }

                            if (!option.disabledFields.includes(key) && ['Drawing Type', 'Discipline'].includes(key)) {
                              let drawingTypeOptions = []
                              option['Discipline'].forEach((discipline) => {
                                this.state.disciplines.forEach((_discipline) => {
                                  if (discipline.id === _discipline.id) {
                                    drawingTypeOptions = [...drawingTypeOptions, ..._discipline.DocumentTypes]
                                  }
                                })
                              })

                              return (
                                <div className="col-3" key={key} id="unique-84801184div_17">
                                  <div className="row align-items-center" id="unique-78875083div_18">
                                    <div className="col-1" id="unique-64674370div_19">
                                      <label
                                        title="Select all"
                                        className={`checkbox d-inline-block text-center ${
                                          key === 'Discipline'
                                            ? val.length === this.state.disciplines.length && val.length
                                              ? 'checked'
                                              : ''
                                            : val.length === drawingTypeOptions.length && val.length
                                            ? 'checked'
                                            : ''
                                        }`}
                                        onClick={() => {
                                          this.handleInputChange(
                                            {
                                              target: {
                                                value:
                                                  key === 'Discipline'
                                                    ? val.length === this.state.disciplines.length
                                                      ? []
                                                      : this.state.disciplines
                                                    : val.length === drawingTypeOptions.length
                                                    ? []
                                                    : drawingTypeOptions,
                                                name: key,
                                              },
                                            },
                                            `${index}.${_index}`
                                          )
                                        }}
                                      />
                                    </div>

                                    {key === 'Drawing Type' ? (
                                      <div className="col-1" id="unique-11128586div_20">
                                        <label
                                          title="Select Similar Drawing Type"
                                          className={`checkbox d-inline-block text-center ${this.state.select_similar_mode.includes(`${index}.${_index}`) ? 'checked' : ''}`}
                                          onClick={() => {
                                            let { select_similar_mode } = this.state
                                            const found_index = select_similar_mode.findIndex((i) => i === `${index}.${_index}`)
                                            if (found_index > -1) {
                                              select_similar_mode.splice(found_index, 1)
                                            } else {
                                              select_similar_mode.push(`${index}.${_index}`)
                                            }
                                            this.setState({ select_similar_mode: select_similar_mode })
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                    <div className={`${key === 'Drawing Type' ? 'col-10' : 'col-11'}`} id="unique-33220537div_21">
                                      <Select
                                        options={key === 'Discipline' ? this.state.disciplines : drawingTypeOptions}
                                        onChange={(selectedList) => {
                                          if (this.state.select_similar_mode.includes(`${index}.${_index}`)) {
                                            let options = key === 'Drawing Type' ? drawingTypeOptions : []

                                            const newSelectedOptionValue = selectedList.find((obj) => !val.some((v) => v.value === obj.value))
                                            if (newSelectedOptionValue) {
                                              const selectedOptionIPSNameList = newSelectedOptionValue['IPS Name'].split('-')
                                              const selectedOptionLastName = selectedOptionIPSNameList[selectedOptionIPSNameList.length - 1].toLowerCase()

                                              const newSelectedOptions = options.filter((option) => {
                                                const optionIPSNameList = option['IPS Name'].split('-')
                                                const optionLastName = optionIPSNameList[optionIPSNameList.length - 1].toLowerCase()
                                                return optionLastName === selectedOptionLastName && !selectedList.some((selectedOption) => selectedOption.id === option.id)
                                              })
                                              selectedList.push(...newSelectedOptions)
                                            }
                                          }

                                          this.handleInputChange(
                                            {
                                              target: {
                                                value: selectedList,
                                                name: key,
                                              },
                                            },
                                            `${index}.${_index}`
                                          )
                                        }}
                                        value={val}
                                        isMulti={true}
                                        // getSearchValue={this.getSearchValue}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            return null
                          })}
                          <div className="col-1" id="unique-30491605div_22">
                            <button
                              className="btn text-secondary"
                              onClick={() => {
                                if (this.state.toggledTagIndex === `${index}.${_index}`) {
                                  this.setState({
                                    toggledTagIndex: ``,
                                  })
                                } else {
                                  this.setState({
                                    toggledTagIndex: `${index}.${_index}`,
                                  })
                                }
                              }}
                            >
                              {this.state.toggledTagIndex === `${index}.${_index}` ? (
                                <i className="fa fa-angle-up" id="unique-38194671i_2"></i>
                              ) : (
                                <i className="fa fa-angle-down" id="unique-24980873i_3"></i>
                              )}
                            </button>
                          </div>
                        </div>

                        {this.state.toggledTagIndex === `${index}.${_index}` && 'selected_options' in option && 'options' in option && option.options.length ? (
                          <div className="p-4" id="unique-59043259div_23">
                            <div className="card mb-3" id="unique-10351154div_24">
                              <div className="card-body bg-light p-2" id="unique-49326516div_25">
                                <div className="clearfix mb-2" id="unique-11524793div_26">
                                  <label id="unique-57065593label_5">{option['IPS Name']}</label>
                                  <span className="float-right d-inline-block mt-1" id="unique-75880469span_2">
                                    <label
                                      className={`checkbox mr-1 ${option.options.length === option.selected_options.length ? 'checked' : ''}`}
                                      onClick={() => this.selectAll(`${index}.${_index}`)}
                                    />{' '}
                                    Select All
                                  </span>
                                  <button type="button" className="btn btn-primary btn-sm float-right mr-2" onClick={() => this.addCustom(`${index}.${_index}`)}>
                                    Add
                                  </button>
                                </div>

                                <Select
                                  options={option.options.map((option) => {
                                    return {
                                      label: option.label,
                                      value: option.label,
                                    }
                                  })}
                                  value={option.selected_options.map((option) => {
                                    return {
                                      label: option.label,
                                      value: option.label,
                                    }
                                  })}
                                  onChange={(selectedOptions) => {
                                    var filteredOptions = option.options.filter((option) => {
                                      return selectedOptions.some((selectedOption) => {
                                        return option.label === selectedOption.label
                                      })
                                    })
                                    this.onSelect(`${index}.${_index}`, filteredOptions)
                                  }}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                            {option.selected_options.length ? (
                              <table className="table table-striped">
                                <thead className="thead-light">
                                  <tr id="unique-23243355tr_1">
                                    {Object.keys(option.options[0]).map((_key) => {
                                      if (!['label', 'value', 'disabledFields', 'id'].includes(_key)) {
                                        return (
                                          <th key={_key} id="unique-10254312th_1">
                                            {_key}
                                          </th>
                                        )
                                      }
                                      return null
                                    })}
                                  </tr>
                                </thead>
                                <tbody id="unique-10126750tbody_1">
                                  {option.selected_options.map((_option, __index) => {
                                    return (
                                      <tr key={_option.id} id="unique-79518597tr_2">
                                        {Object.entries(_option).map(([_key, _val]) => {
                                          if (!['label', 'value', 'disabledFields', 'id'].includes(_key)) {
                                            return (
                                              <td key={_key}>
                                                <div className="input-group">
                                                  <Input
                                                    type="text"
                                                    name={_key}
                                                    value={_val}
                                                    path={`${index}.${_index}.${__index}`}
                                                    onChange={this.handleInputChange}
                                                    disallowedSymbols={['@', '.', '"']}
                                                    disabled={
                                                      !['Display Name', 'Sample', 'LOV'].includes(_key) ||
                                                      _option['disabledFields'].includes(_key) ||
                                                      (_key === 'Sample' && _option['LOV'] !== '')
                                                    }
                                                  />
                                                  {_key === 'LOV' ? (
                                                    <div className="input-group-append">
                                                      <UploadLOVExcel
                                                        {...this.props}
                                                        name={_key}
                                                        index={index}
                                                        _index={_index}
                                                        __index={__index}
                                                        disabled={_option['disabledFields'].includes(_key)}
                                                        updateLOVData={this.handleInputChange}
                                                        projectId={this.props.editProjectId}
                                                      />
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </td>
                                            )
                                          }
                                          return null
                                        })}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-77652449div_29">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
