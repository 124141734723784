import React from 'react'
import './WebComponent.css'

const IsotopeLoader2 = () => {
  return (
    <div className="card-skeleton2" id="unique-34184682div_1">
      <div className="animated-background" id="unique-96936636div_2">
        <div className="skel-mask-container" id="unique-89928728div_3">
          <div className="skel-mask skel-mask-1" id="unique-10477015div_4"></div>
          <div className="skel-mask skel-mask-2" id="unique-10334311div_5"></div>
          <div className="skel-mask skel-mask-3" id="unique-23682966div_6"></div>
          <div className="skel-mask skel-mask-4" id="unique-94696348div_7"></div>
          <div className="skel-mask skel-mask-5" id="unique-78015468div_8"></div>
          <div className="skel-mask skel-mask-6" id="unique-10259860div_9"></div>
        </div>
      </div>
    </div>
  )
}

export default IsotopeLoader2
