import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createProject, fetchUserClientAllData } from './../../store/actions'
import Input from './Fields/input'
import Select from './Fields/select'
import Textarea from './Fields/textarea'

class CreateProject extends Component {
  state = {
    clients: [],
    ips_project_name: '',
    ips_project_description: '',
    ips_project_number: '',
    ips_project_scope_of_work: '',
    project_mode: null,
    selectedUsers: [],
    group_members: [],
    selected_products: [],
    selectedClient: '',
    errorMessage: null,
    ShowAdminUsers: true,
    loading: false,
    formLoader: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    await this.props.fetchUserClientAllData()
    await this.setState({
      clients: this.props.user.userClientsAllData,
    })

    await this.setState({ loading: false })
  }

  formDataHandler = async (e) => {
    if (e) {
      e.preventDefault()
    }
    const formData = new FormData()
    formData.append('projectDisplayName', this.state.ips_project_name)
    formData.append('description', this.state.ips_project_description)
    formData.append('totalSpace', 0.5)
    formData.append('usedSpace', 0)
    formData.append('selected_products', JSON.stringify(this.state.selected_products))
    formData.append('clientId', JSON.stringify(this.state.selectedClient))
    formData.append('project_mode', JSON.stringify(this.state.project_mode))
    formData.append('clone', false)
    formData.append('projectNumber', this.state.ips_project_number)
    formData.append('projectScope', this.state.ips_project_scope_of_work)
    formData.append('users', JSON.stringify(this.state.selectedUsers))
    formData.append('linked', false)
    await this.setState({ formLoader: true })
    await this.props.createProject(formData)
    await this.setState({ formLoader: false })
    this.props.history.push('/admin/projects')
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderForm() {
    return (
      <form onSubmit={(e) => this.formDataHandler(e)} className="card">
        <div className="clearfix card-header bg-light" id="unique-95316656div_1">
          <button
            type="submit"
            disabled={
              this.state.formLoader ||
              (!this.state.project_mode && this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1) ||
              !this.state.selectedUsers.length ||
              !this.state.selected_products.length ||
              !this.state.selectedClient
            }
            className="btn btn-primary float-right"
          >
            Create
          </button>

          <h3 id="unique-10428021h3_1">Create Project</h3>
        </div>
        <div className={`card-body`} id="unique-11052433div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger" id="unique-84465411div_3">
              {this.state.errorMessage}
              <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
                {' '}
                &times;{' '}
              </button>
            </div>
          ) : null}

          <div className="row" id="unique-96485121div_4">
            <div className="col-md-6" id="unique-95248998div_5">
              <div className="row" id="unique-16450127div_6">
                <div className="form-group col" id="unique-20607526div_7">
                  <Input
                    label="Project Name"
                    type="text"
                    value={this.state.ips_project_name}
                    name="ips_project_name"
                    onChange={this.onChange}
                    required={true}
                    id="unique-25519126input_1"
                  />
                </div>
                <div className="form-group col" id="unique-53644232div_8">
                  <Input
                    label="Project Number"
                    type="text"
                    value={this.state.ips_project_number}
                    name="ips_project_number"
                    onChange={this.onChange}
                    required={true}
                    id="unique-25629740input_2"
                  />
                </div>
              </div>
              <div className="form-group">
                <Textarea
                  name="ips_project_description"
                  label="Project Description"
                  value={this.state.ips_project_description}
                  onChange={this.onChange}
                  maxLength={250}
                  rows={4}
                  required={true}
                />
              </div>
              <div className="form-group">
                <Textarea
                  name="ips_project_scope_of_work"
                  label="Project Scope Of Work"
                  value={this.state.ips_project_scope_of_work}
                  onChange={this.onChange}
                  maxLength={250}
                  rows={4}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-6" id="unique-31546949div_11">
              <div className="form-group" id="unique-21016648div_12">
                <label id="unique-80035970label_3">
                  Assign Client{' '}
                  <span className="small text-muted" id="unique-94006610span_7">
                    (Mandatory *)
                  </span>
                </label>
                <Select
                  options={this.state.clients}
                  value={this.state.selectedClient}
                  onChange={(selectedClient) => {
                    this.setState({
                      selectedClient: selectedClient,
                      selected_products: [],
                      selectedUsers: [],
                    })
                  }}
                  isMulti={false}
                />
              </div>
              <div className="form-group" id="unique-14458527div_13">
                <label id="unique-49399833label_4">
                  Assign Product{' '}
                  <span className="small text-muted" id="unique-65653946span_8">
                    (Mandatory *)
                  </span>
                </label>
                <Select
                  options={this.productList()}
                  value={this.state.selected_products}
                  onChange={(selectedProducts) => {
                    this.setState({ selected_products: selectedProducts })
                  }}
                  isDisabled={!this.state.selectedClient}
                  isMulti={true}
                />
              </div>
              {this.state.selected_products.findIndex((product) => product.label === 'iDrawings-PID') > -1 ? (
                <div className="form-group" id="unique-36053149div_14">
                  <label id="unique-26555712label_5">
                    Project Mode{' '}
                    <span className="small text-muted" id="unique-84598941span_9">
                      (Mandatory *)
                    </span>
                  </label>
                  <Select
                    options={[
                      { label: 'Digitization', value: 'Digitization' },
                      { label: 'SPPID to Aveva', value: 'SPPID to Aveva' },
                    ]}
                    value={this.state.project_mode}
                    onChange={(selectedMode) => {
                      this.setState({ project_mode: selectedMode })
                    }}
                    isDisabled={!this.state.selectedClient}
                    isMulti={false}
                  />
                </div>
              ) : null}

              <div className="form-group" id="56661759div_15_div">
                <div className="clearfix" id="566617333div_15_div">
                  <label>
                    Assign Users <span className="small text-muted">(*)</span>
                  </label>
                  <span className="d-inline-block float-right" id="unique-31186390span_11">
                    <label
                      onClick={() =>
                        this.setState({
                          ShowAdminUsers: !this.state.ShowAdminUsers,
                        })
                      }
                      className={`${this.state.ShowAdminUsers ? 'checked' : ''} checkbox`}
                    />{' '}
                    Show Admin Users
                  </span>
                </div>
                <Select
                  options={this.listUsers()}
                  value={this.state.selectedUsers}
                  onChange={(selectedList) => {
                    this.setState({ selectedUsers: selectedList })
                  }}
                  isDisabled={!this.state.selectedClient}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  productList = () => {
    if (!this.state.selectedClient) {
      return
    }
    let product_list = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())
    if (index > -1) {
      if (this.props.product) {
        this.state.clients[index].products.forEach((product) => {
          if (this.props.product === product.Product_id__Name) {
            product_list.push(product)
          }
        })
      } else {
        this.state.clients[index].products.forEach((product) => {
          if (!['iworkflow', 'iworkflow-new'].includes(product.Product_id__Name.toLowerCase())) {
            product_list.push(product)
          }
        })
      }
    }
    return product_list
  }

  listUsers() {
    if (!this.state.selectedClient) {
      return
    }
    let users = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())

    if (index > -1) {
      users = this.state.clients[index].users
      if (!this.state.ShowAdminUsers) {
        users = users.filter((user) => !user.User_id__Default_role.split('-')[user.User_id__Default_role.split('-').length - 1].toLowerCase().includes('admin'))
      }
    }
    return users
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-52721036div_17">
          <div id="loading"></div>
        </div>
      )
    } else {
      return (
        <div className="dashboard admin" id="unique-20962007div_18">
          {this.renderForm()}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  createProject,
  fetchUserClientAllData,
})(CreateProject)
