import React from 'react'

const LinkedProjectModalBox = (props) => {
  return (
    <div className="modal" id="unique-24641448div_1">
      <div className="modal-dialog" id="unique-41644489div_2">
        <div className="modal-content" id="unique-31802865div_3">
          <div className="modal-body" id="unique-96949932div_4">
            <p id="unique-10264538p_1">You are editing a linked project which has information for following projects. So editing this will edit all these linked projects:</p>
            <ul>
              {props.group_members.map((member, index) => {
                return (
                  <li className="mt-2" key={index} id="unique-45559743li_1">
                    {member}
                  </li>
                )
              })}
            </ul>
            <p id="unique-29326628p_2">
              If you want to De-link this project, please select De-link checkbox below, and Confirm, after that all Edit will only happen in this project.
            </p>
            <div className="clearfix mt-3" id="unique-10701843div_5">
              <label className={`mr-3 checkbox ${props.linked ? 'checked' : null}`} onClick={props.toggleLink} id="unique-87597715label_1" /> de-link Project
            </div>
          </div>
          <div className="modal-footer" id="unique-68399862div_6">
            <button type="submit" className="btn btn-secondary" id="unique-38280050button_1">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkedProjectModalBox
