import React from 'react'
import { connect } from 'react-redux'

import { fetchProjects, fetchUserProducts, fetchProducts } from './../../store/actions'
import './Forms.css'

class Account extends React.Component {
  state = {
    name: this.props.user.username,
    email: this.props.user.email,
    role: this.props.user.role,
    products: [],
    userProducts: [],
    projects: [],
    errorMessage: null,
    loading: false,
    captcha: false,
  }

  componentDidMount() {
    this.fetchProductData()
  }

  fetchProductData = async () => {
    await this.setState({ loading: true })
    await this.props.fetchProducts()
    await this.setState({ products: this.props.user.products })
    await this.props.fetchUserProducts()
    await this.setState({ userProducts: this.props.user.userProducts })
    await this.props.fetchProjects()
    await this.setState({
      loading: false,
      projects: this.props.user.projects,
    })
  }

  renderNumberOfProjects = (product) => {
    let numberOfProjects = this.state.projects.filter((project) => project.productName === product).length
    if (numberOfProjects > 0) {
      return (
        <div id="unique-93247613div_1">
          <hr />
          <i id="unique-96129641i_1">No. Of Projects: </i>{' '}
          <span className="badge badge-danger badge-pill" id="unique-65720941span_1">
            {numberOfProjects}
          </span>
        </div>
      )
    }
    return null
  }

  renderUserProducts = () => {
    return this.state.products.map((product) => {
      if (this.state.userProducts.includes(product.id)) {
        return (
          <div className="product-card" key={product.id} id="unique-85963846div_2">
            <div className="meta" id="unique-80110258div_3">
              <div className="name" id="unique-10201360div_4">
                <span
                  dangerouslySetInnerHTML={{
                    __html: product.name,
                  }}
                  id="unique-78607426span_2"
                />
              </div>
              <div className="description" id="unique-99456871div_5">
                {product.description}
              </div>
              {this.renderNumberOfProjects(product.name)}
            </div>
          </div>
        )
      }
      return null
    })
  }

  ReCaptcha = () => {
    this.setState({ captcha: true })
  }

  onChange = (obj) => {
    this.setState(obj)
    this.setState({ errorMessage: null })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="account" id="unique-11137191div_6">
          <div className="screen-loader" id="unique-97799060div_7">
            <div id="loading"></div>
          </div>
        </div>
      )
    }
    return (
      <div className="main" id="unique-91489401div_8">
        <div className="account container" id="unique-34488682div_9">
          <div className="row" id="unique-22347841div_10">
            <div className="col-md-5" id="unique-74996606div_11">
              <h2 className="mb-3" id="unique-11065884h2_1">
                User Profile
              </h2>
              <div className="product-card" id="unique-81956732div_12">
                <b>User Name: </b> <span id="unique-24162233span_3">{this.state.name}</span>
                <br id="unique-13273800break_1" />
                <b>Email: </b> <span id="unique-10403192span_4">{this.state.email}</span>
                <br id="unique-35816791break_2" />
                <b>Role: </b> <span id="unique-32306045span_5">{this.state.role}</span>
              </div>
            </div>
            <div className="col-md-7 form-group" id="unique-79537184div_13">
              <h2 className="mb-3" id="unique-62518003h2_2">
                Assigned Products
              </h2>
              {this.renderUserProducts()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjects,
  fetchUserProducts,
  fetchProducts,
})(Account)
