import React, { Component } from 'react'
import { connect } from 'react-redux'

import PDFViewer from '../Components/WebComponents/DisplayPdf.js'
import DisplayExcel from '../Components/WebComponents/DisplayExcel.js'
import DisplayXML from '../Components/WebComponents/DisplayXML.js'
import DisplayTiff from '../Components/WebComponents/DisplayTiff.js'
import Dropdown from '../Components/WebComponents/dropdown.js'
import DisplayDocumentData from './DisplayDocumentData'

import { clearDisplayMessage, fetchFile, fetchTradeIUIData, updateDocumentStatus, updateTradeIUIData } from '../store/actions.js'
import Textarea from '../Components/Forms/Fields/textarea'

class View extends Component {
  constructor(props) {
    super(props)
    this.documentStatusRef = React.createRef()

    this.state = {
      data: [],
      metadata: {},
      column_headers: [],
      base64File: null,
      allowEdit: false,
      status: [],
      allowed_status: [],
      allowCheckInCheckOut: false,

      fetchingFile: false,
      fetchingData: false,
      savingData: false,
      expand_doc: false,
      minimize_doc: false,
      checkInByForce: false,
    }
  }

  componentDidMount() {
    this.fetchFile()
    this.fetchTradeIUIData()

    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  fetchTradeIUIData = async (id) => {
    this.props.clearDisplayMessage()
    await this.setState({ fetchingData: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('document_id', this.props.file.id)
    formData.append('record_type', 'IUI')
    formData.append('productName', this.props.product)

    await this.props.fetchTradeIUIData(formData)
    if (!this.props.user.displayMessageCode && this.props.user.tradeDocumentIUIData) {
      await this.setState({
        data: this.props.user.tradeDocumentIUIData.data.data,
        metadata: this.props.user.tradeDocumentIUIData.data.metadata,
        column_headers: this.props.user.tradeDocumentIUIData.column_headers,
        allowEdit: this.props.user.tradeDocumentIUIData.AllowEdit,
        status: this.props.user.tradeDocumentIUIData.fileStatus,
        allowed_status: this.props.user.tradeDocumentIUIData.allowedStatus,
        allowCheckInCheckOut: this.props.user.tradeDocumentIUIData.allowCheckInCheckOut,
      })
      this.props.updateBreadcrumbDisability(this.state.allowEdit)
    }

    await this.setState({ fetchingData: false })
  }

  getFileType = (fileName) => {
    const extension = fileName.split('.').pop()
    return extension
  }

  fetchFile = async () => {
    await this.setState({ fetchingFile: true })
    const formData = new FormData()
    formData.append('document_id', this.props.file.id)
    formData.append('drawing_id', null)
    formData.append('product_name', this.props.product)
    formData.append('client_id', this.props.user.tradeDocsData.client_id)
    formData.append('user_id', this.props.user.userId)
    formData.append('project_id', this.props.projectId)
    formData.append('file_type', this.getFileType(this.props.file.name))

    await this.props.fetchFile(formData)
    if (!this.props.user.displayMessageCode) {
      this.setState({
        base64File: this.props.user.base64File.file,
      })
    }

    await this.setState({ fetchingFile: false })
  }

  updateTradeIUIData = async () => {
    let { data, metadata } = this.state
    let form_data = {
      metadata: metadata,
      data: data,
    }
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('document_id', this.props.file.id)
    formData.append('data', JSON.stringify(form_data))
    formData.append('productName', this.props.product)

    await this.setState({ savingData: true })
    await this.props.updateTradeIUIData(formData)
    await this.setState({ savingData: false })
  }

  updateDocumentStatus = async (markCleaned = undefined, markApproved = undefined, checkIn = undefined, markReviewed = undefined) => {
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    if (markCleaned !== undefined) {
      formData.append('markCleaned', markCleaned)
    }
    if (markApproved !== undefined) {
      formData.append('markApproved', markApproved)
    }
    if (markReviewed !== undefined) {
      formData.append('markReviewed', markReviewed)
    }
    if (checkIn !== undefined) {
      formData.append('checkIn', checkIn)
    }

    formData.append('documentList', [this.props.file.id])

    await this.props.updateDocumentStatus(formData)

    if (checkIn !== undefined) {
      this.setState({ allowEdit: !checkIn })
      this.props.updateBreadcrumbDisability(!checkIn)
    }
  }

  handleBeforeUnload = (e) => {
    e.preventDefault()
    if (this.state.allowEdit) {
      this.updateDocumentStatus(undefined, undefined, true, undefined)
      this.setState({ checkInByForce: true })
    }

    e.returnValue = ''
    return ''
  }

  setDropdownOpen = (obj) => {
    this.setState(obj)
  }

  renderDocumentStatusDropdownItems = () => {
    let product = this.props.product.toLowerCase()
    let mark_approved_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-approved_hidden`)
    let mark_reviewed_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-reviewed_hidden`)
    let mark_cleaned_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-cleaned_hidden`)

    return (
      <React.Fragment>
        {!mark_cleaned_hidden ? (
          <button
            id="view_datasheet_mark_cleaned"
            type="button"
            className="dropdown-item"
            disabled={!this.state.allowed_status.includes('cleaned')}
            onClick={() => {
              this.updateDocumentStatus(true, undefined, undefined, undefined)
              let status = this.state.status
              const index = status.findIndex((s) => s === 'cleaned')
              if (index > -1) {
                status.splice(index, 1)
              } else {
                status.push('cleaned')
              }
              this.setState({ status: status })
            }}
          >
            <label className={`checkbox ${this.state.status.includes('cleaned') ? 'checked' : ''}`} /> Mark Cleaned
          </button>
        ) : null}
        {!mark_reviewed_hidden ? (
          <button
            id="view_datasheet_mark_reviewed"
            type="button"
            className="dropdown-item"
            disabled={!this.state.allowed_status.includes('reviewed')}
            onClick={() => {
              this.updateDocumentStatus(undefined, undefined, undefined, true)
              let status = this.state.status
              const index = status.findIndex((s) => s === 'reviewed')
              if (index > -1) {
                status.splice(index, 1)
              } else {
                status.push('reviewed')
              }
              this.setState({ status: status })
            }}
          >
            <label className={`checkbox ${this.state.status.includes('reviewed') ? 'checked' : ''}`} /> Mark Reviewed
          </button>
        ) : null}
        {!mark_approved_hidden ? (
          <button
            id="view_datasheet_mark_approved"
            type="button"
            className="dropdown-item"
            disabled={!this.state.allowed_status.includes('approved')}
            onClick={() => {
              this.updateDocumentStatus(undefined, true, undefined, undefined)
              let status = this.state.status
              const index = status.findIndex((s) => s === 'approved')
              if (index > -1) {
                status.splice(index, 1)
              } else {
                status.push('approved')
              }
              this.setState({ status: status })
            }}
          >
            <label className={`checkbox ${this.state.status.includes('approved') ? 'checked' : ''}`} /> Mark Approved
          </button>
        ) : null}
      </React.Fragment>
    )
  }

  renderDocument = () => {
    if (this.state.fetchingFile) {
      return (
        <div className="text-center" style={{ height: '42.5rem' }}>
          <h4>Fetching Document ...</h4>
        </div>
      )
    }

    if (this.props.file.name.toLowerCase().includes('.pdf') || this.props.file.name.toLowerCase().includes('.doc')) {
      return <PDFViewer pdfBase64={this.state.base64File} />
    } else if (
      this.props.file.name.toLowerCase().includes('.xlsx') ||
      this.props.file.name.toLowerCase().includes('.xls') ||
      this.props.file.name.includes('.XLSX') ||
      this.props.file.name.includes('.XLS')
    ) {
      return <DisplayExcel excelBase64={this.state.base64File} fileName={this.props.file.name} />
    } else if (this.props.file.name.toLowerCase().includes('.tiff') || this.props.file.name.toLowerCase().includes('.tif')) {
      return <DisplayTiff tiffBase64={this.state.base64File} />
    } else {
      return <DisplayXML xmlData={this.state.base64File} />
    }
  }

  renderDocumentData = () => {
    if (this.state.fetchingData) {
      return (
        <div className="text-center" style={{ height: '40rem' }}>
          <h4>Fetching Document Data ...</h4>
        </div>
      )
    }

    if (this.state.data.length && this.state.column_headers.length && this.state.metadata) {
      return (
        <DisplayDocumentData
          metadata={this.state.metadata}
          data={this.state.data}
          column_headers={this.state.column_headers}
          onChangeAttributeData={this.onChangeAttributeData}
          onChangeTableData={this.onChangeTableData}
          allowEdit={this.state.allowEdit}
        />
      )
    }

    return null
  }

  onChangeAttributeData = (e, index) => {
    let { data } = this.state
    data[index].attributes[e.target.name].value = e.target.value
    this.setState({ data: data })
  }

  onChangeTableData = (changed_table_rows_data, data_index, table_index) => {
    let { data } = this.state

    data[data_index].tables[table_index].rows = changed_table_rows_data
    this.setState({ data: data })
  }

  render() {
    let product = this.props.product.toLowerCase()
    let save_hidden = this.props.user.user_actions.includes(`${product}_view_save_hidden`)
    let check_in_hidden = this.props.user.user_actions.includes(`${product}_check-in_hidden`)

    return (
      <div
        onMouseOver={() => {
          if (this.state.checkInByForce && !this.state.allowEdit) {
            this.updateDocumentStatus(undefined, undefined, false, undefined)
            this.setState({ checkInByForce: false })
          }
        }}
      >
        <div className="card mb-2">
          <div className="card-body p-2">
            {!save_hidden ? (
              <button
                type="button"
                className={`btn btn-primary btn-sm float-right mr-2`}
                disabled={!this.state.allowEdit || this.state.savingData}
                onClick={() => this.updateTradeIUIData()}
              >
                Save
              </button>
            ) : null}

            <Dropdown
              ref={this.documentStatusRef}
              disabled={this.state.allowEdit || this.state.savingData}
              isDropdownOpen={this.state.status_toggle}
              stateName="status_toggle"
              dropdownClass="dropdown float-right mr-2"
              dropdownPositionClass="dropdown-menu-right"
              btnClass={`btn-primary btn-sm`}
              btnName="Document Status"
              idPrefix="view_document"
              setDropdownOpen={this.setDropdownOpen}
              render={this.renderDocumentStatusDropdownItems}
            />

            {!check_in_hidden ? (
              <button
                type="button"
                className="btn btn-light btn-sm float-right mr-2"
                disabled={!this.state.allowCheckInCheckOut || this.state.savingData}
                onClick={() => this.updateDocumentStatus(undefined, undefined, this.state.allowEdit, undefined)}
              >
                {this.state.allowEdit ? (
                  <React.Fragment>
                    Checked Out <i className="ml-2 fa fa-lock" />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Checked In <i className="ml-2 fa fa-unlock" />
                  </React.Fragment>
                )}
              </button>
            ) : null}

            <h4 className="d-inline-block mr-3 mb-0">{this.props.file.name}</h4>

            <button
              className={'btn btn-light btn-sm'}
              onClick={() => {
                if (this.state.minimize_doc) {
                  this.setState({ minimize_doc: false })
                } else {
                  this.setState({ expand_doc: !this.state.expand_doc })
                }
              }}
            >
              {!this.state.expand_doc ? <i className={'fa fa-expand'} /> : this.state.minimize_doc ? <i className="fas fa-window-maximize" /> : null}
            </button>
          </div>
        </div>

        {this.state.expand_doc ? (
          <div className={`modal ${this.state.minimize_doc ? 'd-none' : ''}`}>
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '98%' }}>
              <div className="modal-content">
                <div className="clearfix p-2">
                  <button
                    type="button"
                    className="btn btn-default btn-sm float-right"
                    onClick={() =>
                      this.setState({
                        expand_doc: false,
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <button
                    type="button"
                    className={'btn btn-default btn-sm float-right mr-2'}
                    onClick={() => {
                      this.setState({ minimize_doc: true })
                    }}
                  >
                    &minus;
                  </button>

                  <h5>{this.props.file.name}</h5>
                </div>
                <div className="modal-body p-0" style={{ minHeight: '50rem' }}>
                  {this.renderDocument()}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row mb-3">
          {!this.state.expand_doc ? <div className="col-md-6">{this.renderDocument()}</div> : null}
          <div className="col">{this.renderDocumentData()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  clearDisplayMessage,
  fetchFile,
  fetchTradeIUIData,
  updateDocumentStatus,
  updateTradeIUIData,
})(View)
