import React from 'react'
import { connect } from 'react-redux'

import { fetchUpdateProductDeliverableData } from '../../store/actions'

class UpdateProductDeliverables extends React.Component {
  state = {
    projectName: null,
    projectId: null,
    drawing: null,
    product: null,
    loading: false,
    errorMessage: null,
  }

  componentDidMount() {
    this.setState({
      projectName: this.props.projectName,
      projectId: this.props.projectId,
      product: this.props.product,
      drawing: this.props.drawing,
    })
  }

  fetchUpdateProductDeliverableData = async () => {
    this.setState({ loading: true })
    let formData = new FormData()
    formData.append('projectName', this.state.projectName)
    formData.append('projectId', this.state.projectId)
    formData.append('product', this.state.product)
    formData.append('drawingId', this.state.drawing.id)
    await this.props.fetchUpdateProductDeliverableData(formData)
    if (!this.props.user.displayMessageCode) {
      if (this.props.user.updateProductDeliverableExcel) {
        const link = document.createElement('a')
        link.href = this.props.user.updateProductDeliverableExcel
        link.setAttribute('download', this.state.drawing.sppid_excel_name) //or any other extension
        link.click()
      }
    }

    this.setState({ loading: false })
  }

  render() {
    if (this.state.drawing && this.state.product) {
      let download_hidden = false
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_download-sppid_hidden`)) {
        download_hidden = true
      }
      return (
        <>
          {!download_hidden ? (
            <button
              disabled={!this.state.drawing.processed || this.state.drawing.errorMessage || !this.state.drawing.sppid_excel_name}
              onClick={this.fetchUpdateProductDeliverableData}
              className={`dropdown-item`}
            >
              {this.state.loading ? 'Downloading...' : 'Download SPPID'}
            </button>
          ) : null}
          {this.state.errorMessage ? <span className="text-danger"> &nbsp; {this.state.errorMessage}</span> : null}
        </>
      )
    }

    return null
  }
}

export default connect(null, { fetchUpdateProductDeliverableData })(UpdateProductDeliverables)
