import React, { Component } from 'react'
import Moment from 'react-moment'
import Dropzone from 'react-dropzone'

import { base64ToArrayBuffer } from '../WebComponents/utils.js'

import { SortingState, IntegratedSorting, PagingState, SearchState, IntegratedPaging, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid'
import { Grid, VirtualTable, TableHeaderRow, Toolbar, PagingPanel, TableColumnResizing, TableFilterRow, SearchPanel } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

export default class ClientList extends Component {
  state = {
    columns: [],
    rows: [],
    columnExtensions: [
      {
        columnName: 'lastModifiedDate',
        filteringEnabled: false,
        sortingEnabled: false,
      },
      {
        columnName: 'actions',
        filteringEnabled: false,
        sortingEnabled: false,
      },
    ],
    columnWidths: [
      { columnName: 'displayName', width: 180 },
      { columnName: 'reportType', width: 180 },
      { columnName: 'parentReport', width: 180 },
      { columnName: 'scope', width: 100 },
      { columnName: 'lastModifiedDate', width: 180 },
      { columnName: 'lastModifiedBy', width: 180 },
      { columnName: 'isPublished', width: 180 },
      { columnName: 'actions', width: 460 },
    ],
    sorting: [],
    filters: [],

    downloadingReportUploadStatus: false,
    uploading: false,
    uploadRow: null,

    // Delete Report
    delete_status: false,
    deletingClientReport: false,
    delete_id: null,

    // Download Report
    download_id: null,
    download_file_type_selected: '',
    download_type_selected: '',
    downloadingClientReport: false,

    errorMessage: null,
  }

  componentDidMount = async () => {
    await this.fetchReportList()
  }

  fetchReportList = async () => {
    await this.props.setLoading(true)
    let { filters, sorting } = this.state

    const formData = new FormData()
    formData.append('filters', JSON.stringify(filters))
    formData.append('searchValue', '')
    formData.append('sorting', JSON.stringify(sorting))
    await this.props.fetchReportList(formData)
    await this.props.setLoading(false)

    if (this.props.user.displayMessageCode) {
      return
    }

    let rows = []
    this.props.user.report_list.reportlist.forEach((item) => {
      let row_data = {
        id: item.id,
        displayName: item.displayName,
        reportType: item.reportType,
        parentReport: item.parentReport,
        scope: item.scope,
        lastModifiedDate: <Moment date={item.lastModifiedDate} format="DD-MMM-YYYY hh:mm" />,
        lastModifiedBy: item.lastModifiedBy,
        actions: this.getActions(item),
      }
      if (this.state.is_ipsadmin || this.state.is_clientadmin) {
        row_data.isPublished = item.isPublished ? 'Published' : 'Draft'
      }
      rows.push(row_data)
    })

    await this.setState({ rows: rows })
    await this.setColumns()
  }

  deleteClientReport = async () => {
    await this.setState({ deletingClientReport: true })
    let formData = new FormData()
    formData.append('report_id', this.state.delete_id)
    await this.props.deleteClientReport(formData)
    if (!this.props.user.displayMessageCode) {
      await this.fetchReportList()
      this.setState({ delete_status: !this.state.delete_status })
    }

    await this.setState({ deletingClientReport: false })
  }

  fetchReport = async (id) => {
    let formData = new FormData()
    formData.append('report_id', id)
    formData.append('skip', 0)
    formData.append('take', 0)
    formData.append('sorting', JSON.stringify([]))
    formData.append('filters', JSON.stringify([]))
    formData.append('SearchValue', '')
    await this.props.fetchReport(formData)
  }

  downloadClientReport = async (e) => {
    e.preventDefault()
    await this.setState({ downloadingClientReport: true })
    await this.fetchReport(this.state.download_id)

    if (this.props.user.displayMessageCode) {
      await this.setState({
        download_id: null,
        download_file_type_selected: '',
        download_type_selected: '',
        downloadingClientReport: false,
      })
      return
    }

    let formData = new FormData()
    formData.append('report_id', this.state.download_id)
    formData.append('file_type', this.state.download_file_type_selected)
    formData.append('download_type', this.state.download_type_selected)
    formData.append('filters', JSON.stringify([]))

    await this.props.downloadClientReport(formData)
    this.setState({
      download_id: null,
      download_file_type_selected: '',
      download_type_selected: '',
      downloadingClientReport: false,
    })

    if (this.props.user.displayMessageCode) {
      return
    }

    if (this.props.user.report_file) {
      let buffer = base64ToArrayBuffer(this.props.user.report_file.report)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      // const url = this.props.user.report_file.report
      const link = document.createElement('a')
      link.href = url

      var currentdate = new Date()
      var datetime =
        '-' +
        (currentdate.getDate() < 10 ? '0' : '') +
        currentdate.getDate() +
        '_' +
        (currentdate.getMonth() + 1 < 10 ? '0' : '') +
        (currentdate.getMonth() + 1) +
        '_' +
        currentdate.getFullYear() +
        ''

      link.setAttribute('download', `${this.props.user.report_file.fileName}${datetime}.${this.props.user.report_file.extension}`)
      link.click()
    }
  }

  downloadReportUploadStatus = async (id) => {
    await this.setState({ downloadingReportUploadStatus: true })
    const formData = new FormData()
    formData.append('report_id', id)
    await this.props.downloadReportUploadStatus(formData)
    await this.setState({ downloadingReportUploadStatus: false })
    if (!this.props.user.displayMessageCode) {
      let buffer = base64ToArrayBuffer(this.props.user.report_upload_status.fileData)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.props.user.report_upload_status.fileName)
      link.click()
    }
  }

  uploadClientReport = async (files) => {
    if (files[0].size > 100 * 1024 * 1024) {
      this.setState({
        errorMessage: `${files[0].name}: Max allowed file size is 100MB`,
      })
      return
    }

    let { uploadRow } = this.state
    await this.setState({ uploading: true })
    const formData = new FormData()
    formData.append('report_id', uploadRow.id)
    formData.append('file', files[0])
    formData.append('fileName', files[0].name)
    await this.props.uploadClientReport(formData)
    await this.setState({ uploading: false, uploadRow: null })

    if (this.props.user.upload_report_list_status && this.props.user.upload_report_list_status !== 'fail') {
      await this.fetchReportList()
    }
  }

  setColumns = async () => {
    await this.setState({
      columns: [
        {
          name: 'displayName',
          title: 'Report Name',
        },
        {
          name: 'reportType',
          title: 'Categories',
        },
        {
          name: 'parentReport',
          title: 'Master Report',
        },
        {
          name: 'scope',
          title: 'Scope',
        },
        {
          name: 'lastModifiedDate',
          title: 'Last Modified',
        },
        {
          name: 'lastModifiedBy',
          title: 'Modified by',
        },
        { name: 'actions', title: 'Action' },
      ],
    })
  }

  getActions = (row) => {
    let { product } = this.props
    let view_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_view-report_hidden`)
    let download_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_download-report_hidden`)
    let edit_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_edit-report_hidden`)
    let upload_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_upload-report_hidden`)
    let report_status_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_report-status_hidden`)
    let delete_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_delete-report_hidden`)

    return (
      <React.Fragment>
        {!view_report_hidden ? (
          <button id={`view_report_${row.id}_action_btn`} className="btn text-secondary btn-sm" onClick={() => this.view_jump_to(row.id)}>
            <u>View</u>
          </button>
        ) : null}

        {!download_report_hidden ? (
          <button
            id={`download_report_${row.id}_action_btn`}
            className="btn text-secondary btn-sm"
            onClick={() =>
              this.setState({
                download_id: row.id,
                download_type_selected: '',
                download_file_type_selected: '',
              })
            }
          >
            <u>Download</u>
          </button>
        ) : null}

        {!edit_report_hidden ? (
          <button id={`edit_report_${row.id}_action_btn`} className="btn text-secondary btn-sm" onClick={() => this.edit_jumpt_to(row.id)}>
            <u>Edit</u>
          </button>
        ) : null}

        {upload_report_hidden && report_status_hidden ? null : (
          <div className="btn-group" id="unique-50006715div_1">
            {!upload_report_hidden ? (
              <button
                id={`upload_report_${row.id}_action_btn`}
                className="btn text-secondary btn-sm"
                onClick={() => this.setState({ uploadRow: row })}
                disabled={row.disable_upload}
              >
                <u>Upload Report</u> <i className="fa fa-upload" />
              </button>
            ) : null}

            {!report_status_hidden ? (
              <button
                id={`download_report_status_${row.id}_action_btn`}
                className="btn text-secondary btn-sm"
                onClick={() => this.downloadReportUploadStatus(row.id)}
                disabled={this.state.downloading || !row.upload_status}
              >
                <u>Status</u> {this.state.downloading ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-info-circle" />}
              </button>
            ) : null}
          </div>
        )}

        {!delete_report_hidden ? (
          <button
            id={`delete_report_${row.id}_action_btn`}
            className="btn text-danger btn-sm"
            onClick={() =>
              this.setState({
                delete_id: row.id,
                delete_status: !this.state.delete_status,
              })
            }
          >
            <u>Delete</u>
          </button>
        ) : null}
      </React.Fragment>
    )
  }

  view_jump_to = (id) => {
    this.props.history.push({
      pathname: '/iworkflow-new/report-detail',
      search: 'reportId=' + id,
    })
  }

  edit_jumpt_to = (id) => {
    this.props.history.push({
      pathname: '/iworkflow-new/report-edit',
      search: 'reportId=' + id,
    })
  }

  setSorting = (sorting) => {
    this.setState({ sorting: sorting })
  }

  setFilters = (filters) => {
    this.setState({ filters: filters })
  }

  getRowId = (row) => row.id

  render() {
    let { columns, rows, columnWidths, sorting, filters, columnExtensions } = this.state
    const pageSizes = [5, 10, 15]
    return (
      <React.Fragment>
        {this.state.delete_status ? (
          <div className="modal" tabIndex="-1" role="dialog" id="unique-93166413div_2">
            <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-45567510div_3">
              <div className="modal-content" id="unique-80998843div_4">
                <div className="modal-header" id="unique-66405710div_5">
                  <h5 className="modal-title" id="unique-65697663h5_1">
                    Delete Report
                  </h5>
                  <button
                    type="button"
                    className="close"
                    id="unique-87874312button_7"
                    onClick={() =>
                      this.setState({
                        delete_status: false,
                      })
                    }
                  >
                    <span aria-hidden="true" id="unique-38085653span_1">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body" id="unique-43426943div_6">
                  <p id="unique-66483401p_1">Are you sure to save Delete this report?</p>
                </div>
                <div className="modal-footer" id="unique-21690429div_7">
                  <button
                    className="btn btn-default btn-sm"
                    id="unique-80330995button_8"
                    onClick={() =>
                      this.setState({
                        delete_status: false,
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary btn-sm" onClick={this.deleteClientReport} disabled={this.state.deletingClientReport} id="unique-63469564button_9">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.download_id ? (
          <form className="modal" onSubmit={this.downloadClientReport} id="unique-21673914form_1">
            <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-12682818div_8">
              <div className="modal-content" id="unique-46518837div_9">
                <div className="modal-header" id="unique-29063658div_10">
                  <h5 className="modal-title" id="unique-59619903h5_2">
                    Download
                  </h5>
                  <button
                    type="button"
                    className="close"
                    id="unique-55993092button_10"
                    onClick={() =>
                      this.setState({
                        download_id: null,
                        download_type_selected: '',
                        download_file_type_selected: '',
                      })
                    }
                    disabled={this.state.downloadingClientReport}
                  >
                    <span aria-hidden="true" id="unique-83721960span_2">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body" id="unique-17537871div_11">
                  <div className="form-group" id="unique-53245202div_12">
                    <label id="unique-19804456label_1">Download Type</label>
                    <select
                      className="form-control"
                      value={this.state.download_type_selected}
                      onChange={(e) => this.setState({ download_type_selected: e.target.value })}
                      required={true}
                    >
                      <option value={''}>--Select--</option>
                      <option value={'Original Report'}>Original Report</option>
                      <option value={'Filtered Report'}>Filtered Report</option>
                    </select>
                  </div>

                  <div className="form-group" id="unique-87232001div_13">
                    <label id="unique-11968891label_2">File Type</label>
                    <select
                      className="form-control"
                      value={this.state.download_file_type_selected}
                      onChange={(e) => this.setState({ download_file_type_selected: e.target.value })}
                      required={true}
                    >
                      <option value={''}>--Select--</option>
                      <option value={'Excel File'}>Excel File</option>
                      <option value={'.CSV File'}>.CSV File</option>
                    </select>
                  </div>
                </div>

                <div className="modal-footer" id="unique-88363922div_14">
                  <button
                    type="button"
                    className="btn btn-default"
                    id="unique-18684932button_11"
                    onClick={() =>
                      this.setState({
                        download_id: null,
                        download_type_selected: '',
                        download_file_type_selected: '',
                      })
                    }
                    disabled={this.state.downloadingClientReport}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={this.state.downloadingClientReport} id="unique-12449671button_12">
                    Download {this.state.downloadingClientReport ? <i className="fa fa-spin fa-spinner" id="unique-11375957i_4" /> : null}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        {this.state.uploadRow ? (
          <div className="modal" tabIndex="-1" role="dialog" id="unique-82006855div_15">
            <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-11157976div_16">
              <div className="modal-content" id="unique-18713896div_17">
                <div className="modal-header" id="unique-65431109div_18">
                  <h5 className="modal-title" id="unique-11246164h5_3">
                    Upload
                  </h5>
                  <button
                    type="button"
                    id="unique-67907180button_13"
                    className="close"
                    onClick={() =>
                      this.setState({
                        uploadRow: null,
                      })
                    }
                    disabled={this.state.uploading}
                  >
                    <span aria-hidden="true" id="unique-56100463span_3">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body text-center" id="unique-10333div_21">
                  {this.state.errorMessage ? (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                      <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <Dropzone id="unique-1009782dropzone_21" onDrop={(acceptedFiles) => this.uploadClientReport(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <button id="select_and_upload_client_report_btn" {...getRootProps()} className="btn btn-primary" disabled={this.state.uploading}>
                        <input {...getInputProps()} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        Select & Upload Client Report {this.state.uploading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-upload" />}
                      </button>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="iworkflow" id="unique-30497264div_20">
          <div className="report-container-wrapper" id="unique-23527958div_21">
            <div className="report-container" id="unique-86282058div_22">
              <Grid rows={rows} columns={columns} getRowId={this.getRowId}>
                <SortingState sorting={sorting} onSortingChange={this.setSorting} columnExtensions={columnExtensions} />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                <IntegratedPaging />
                <SearchState />
                <FilteringState filters={filters} onFiltersChange={this.setFilters} columnExtensions={columnExtensions} />
                <IntegratedFiltering />

                <VirtualTable />
                <Toolbar />
                <SearchPanel />
                <TableColumnResizing
                  columnWidths={columnWidths}
                  onColumnWidthsChange={(columnWidths) =>
                    this.setState({
                      columnWidths: columnWidths,
                    })
                  }
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow showFilterSelector />
                <PagingPanel pageSizes={pageSizes} />
              </Grid>
              {this.props.loading ? (
                <div className="card-loader" id="unique-11129537div_23">
                  <div id="loading" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
