import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './Forms/Login'
import ResetPassword from './Forms/ResetPassword'
import TaggingConfig from './WebComponents/TaggingConfig'
import ModelConfiguration from './WebComponents/ModelConfiguration'
import NotFound from './WebComponents/NotFound'
import NotAllowed from './WebComponents/NotAllowed'
import Main from './Main'
import IdleTimer from './idleTimer'
import DisplayMessage from './ErrorHandling/DisplayMessage'

import { connect } from 'react-redux'
import { logoutUser, clearDisplayMessage } from './../store/actions'
import { getAccessToken } from './../store/access'

class App extends React.Component {
  state = {
    timedOut: false,
    remaining: 60000,
    debounce: 1000,
    showSessionExpiredMessage: false,
  }

  componentDidUpdate = (props) => {
    if (this.props.user.displayMessageCode !== props.user.displayMessageCode && this.props.user.displayMessageCode) {
      this.displayMessageHandler()
    }
  }

  displayMessageHandler = async () => {
    setTimeout(() => this.props.clearDisplayMessage(), 10000)
  }

  remainingTime = null
  showSessionExpiredMessage = async (show) => {
    await this.setState({ showSessionExpiredMessage: show })
    if (!show) {
      return
    }
    this.remainingTime = setInterval(async () => {
      if (this.state.remaining <= 0) {
        this.logoutUser()
      } else {
        const remaining = parseInt(this.state.remaining - this.state.debounce)
        await this.setState({ remaining: remaining })
      }
    }, this.state.debounce)
  }

  logoutUser = async () => {
    await this.clearInterval()

    setTimeout(() => {
      this.props.logoutUser()
    }, 2000)
  }

  clearInterval = async () => {
    await this.setState({
      remaining: 60000,
      timedOut: !this.state.timedOut,
      showSessionExpiredMessage: false,
    })
    clearInterval(this.remainingTime)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user.displayMessageCode ? (
          <div className="display-message">
            <DisplayMessage {...this.props.user.displayMessageCode} />
          </div>
        ) : null}
        <IdleTimer showSessionExpiredMessage={this.showSessionExpiredMessage} access_token={getAccessToken()} />
        {this.state.showSessionExpiredMessage && getAccessToken() ? (
          <div className="modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Your session will expire in {Math.floor(this.state.remaining / 1000)} seconds</h5>
                  <button type="button" className="close" onClick={this.clearInterval}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  You have been inactive for a while. For your security, we will automatically sign you out in approximately {Math.floor(this.state.remaining / 1000)} seconds.
                  Choose "Stay Logged In" to continue or "Log Out" if you are done.
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={this.clearInterval}>
                    Stay Logged In
                  </button>
                  <button className="btn btn-default" onClick={this.logoutUser}>
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/dashboard" exact render={(props) => <Main dashboardType="root" page="home" {...props} />} />
          <Route path="/account" exact render={(props) => <Main dashboardType="root" page="account" {...props} />} />
          <Route path="/contact-support" exact render={(props) => <Main dashboardType="root" page="contact" {...props} />} />
          <Route path="/pid/project-list" exact render={(props) => <Main dashboardType="pid" page="home" {...props} />} />
          <Route path="/pid/project" exact render={(props) => <Main dashboardType="pid" page="project" {...props} />} />
          <Route path="/project-setup" exact render={(props) => <Main dashboardType="pid" page="project-setup" {...props} />} />
          <Route path="/pid/project-setup" exact render={(props) => <Main dashboardType="pid" page="project-setup" {...props} />} />
          <Route path="/iworkflow-new/report-detail" exact render={(props) => <Main dashboardType="iworkflow-new" page="report-detail" {...props} />} />
          <Route path="/iworkflow-new/report-edit" exact render={(props) => <Main dashboardType="iworkflow-new" page="report-edit" {...props} />} />

          <Route path="/iworkflow-new" exact render={(props) => <Main dashboardType="iworkflow-new" page="home" {...props} />} />
          <Route path="/iworkflow" exact render={(props) => <Main dashboardType="iworkflow" page="home" {...props} />} />
          <Route path="/pid/project/view" exact render={(props) => <Main dashboardType="iworkflow" page="home" {...props} />} />
          <Route path="/pid/project/tagging-config" exact render={(props) => <TaggingConfig {...props} />} />
          <Route path="/pid/project/model-configuration" exact render={(props) => <ModelConfiguration {...props} />} />
          {/* Datasheet Routes */}
          <Route path="/idocuments-datasheet/project-list" exact render={(props) => <Main dashboardType="datasheet" page="home" {...props} />} />
          <Route path="/idocuments-datasheet/project-setup" exact render={(props) => <Main dashboardType="datasheet" page="project-setup" {...props} />} />
          <Route path="/idocuments-datasheet/project" exact render={(props) => <Main dashboardType="datasheet" page="project" {...props} />} />
          {/* Trade Document Routes */}
          <Route path="/idocuments-tradedocuments/project-list" exact render={(props) => <Main dashboardType="tradedocument" page="home" {...props} />} />
          <Route path="/idocuments-tradedocuments/project-setup" exact render={(props) => <Main dashboardType="tradedocument" page="project-setup" {...props} />} />
          <Route path="/idocuments-tradedocuments/project" exact render={(props) => <Main dashboardType="tradedocument" page="project" {...props} />} />

          <Route path="/iui" exact render={(props) => <Main dashboardType="iui" page="home" {...props} timedOut={this.state.timedOut} />} />

          {/* Admin Routes */}
          <Route path="/admin" exact render={(props) => <Main dashboardType="admin" page="users" {...props} />} />
          <Route path="/admin/users" exact render={(props) => <Main dashboardType="admin" page="users" {...props} />} />
          <Route path="/admin/edit-user" exact render={(props) => <Main dashboardType="admin" page="edit-user" {...props} />} />
          <Route path="/admin/create-user" exact render={(props) => <Main dashboardType="admin" page="create-user" {...props} />} />
          <Route path="/admin/archive-user" exact render={(props) => <Main dashboardType="admin" page="archive-user" {...props} />} />
          <Route path="/admin/clients" exact render={(props) => <Main dashboardType="admin" page="clients" {...props} />} />
          <Route path="/admin/create-client" exact render={(props) => <Main dashboardType="admin" page="create-client" {...props} />} />
          <Route path="/admin/edit-client" exact render={(props) => <Main dashboardType="admin" page="edit-client" {...props} />} />
          <Route path="/admin/products" exact render={(props) => <Main dashboardType="admin" page="products" {...props} />} />
          <Route path="/admin/create-product" exact render={(props) => <Main dashboardType="admin" page="create-product" {...props} />} />
          <Route path="/admin/edit-product" exact render={(props) => <Main dashboardType="admin" page="edit-product" {...props} />} />
          <Route path="/admin/projects" exact render={(props) => <Main dashboardType="admin" page="projects" {...props} />} />
          <Route path="/admin/edit-project" exact render={(props) => <Main dashboardType="admin" page="edit-project" {...props} />} />
          <Route path="/admin/create-project" exact render={(props) => <Main dashboardType="admin" page="create-project" {...props} />} />
          <Route path="/admin/create-config" exact render={(props) => <Main dashboardType="admin" page="create-config" {...props} />} />

          <Route path="/not-allowed" component={NotAllowed} />
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { logoutUser, clearDisplayMessage })(App)
