import React from 'react'
import { connect } from 'react-redux'

import Select from '../Fields/select'
import Input from '../Fields/input'
import { fetchProjectConfigParameters, updateProjectConfigParameters } from './../../../store/actions'
import './../Forms.css'

class ProjectConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: '',
      override_all_drawing_level_configuration: false,
      configParameters: [],
      loading: false,
      saving: false,
    }
  }

  componentDidMount = async () => {
    this.fetchProjectConfigParameters()
  }

  fetchProjectConfigParameters = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append(`projectId`, this.props.projectId)
    formData.append(`drawingIds`, this.props.selectedDrawings)
    await this.props.fetchProjectConfigParameters(formData)
    await this.setState({
      configParameters: this.props.user.projectConfigParameters,
    })
    await this.checkForDisabledFields()
    await this.setState({ loading: false })
  }

  updateProjectConfigParameters = async (e) => {
    e.preventDefault()
    await this.setState({ saving: true })
    const formData = new FormData()
    formData.append(`projectId`, this.props.projectId)
    formData.append(`drawingIds`, this.props.selectedDrawings)
    formData.append(`projectConfig`, JSON.stringify(this.state.configParameters))
    formData.append(`overrideDrawingConfig`, this.state.override_all_drawing_level_configuration)
    await this.props.updateProjectConfigParameters(formData)
    await this.setState({ saving: false })
  }

  checkForDisabledFields = async () => {
    let { configParameters } = this.state
    configParameters.map((param) => {
      param.fields.forEach((field) => {
        if (!('disable_codes' in field)) {
          return
        }

        field.disable_codes.forEach((code) => {
          let configParameter = configParameters.find((param) => param.fields[1].code === code)
          if (configParameter) {
            configParameter.fields[2].disabled = true
          }
        })
      })
    })

    await this.setState({ configParameters: configParameters })
  }

  onChange = async (obj, path) => {
    path = path.split(`.`)
    let { configParameters } = this.state
    if (path.length === 2) {
      configParameters[path[0]].fields[path[1]].disable_codes.forEach((code) => {
        let configParameter = configParameters.find((param) => param.fields[1].code === code)
        if (configParameter) {
          configParameter.fields[2].disabled = obj.target.value.includes('No')
        }
      })

      configParameters[path[0]].fields[path[1]].value = obj.target.value
      configParameters[path[0]].edited = configParameters[path[0]].fields[path[1]].set_value !== configParameters[path[0]].fields[path[1]].value
    }
    await this.setState({ configParameters: configParameters })
  }

  onSelect = async (selectedOptions, path) => {
    path = path.split(`.`)
    let { configParameters } = this.state
    if (path.length === 2) {
      configParameters[path[0]].fields[path[1]].value = selectedOptions.map((option) => {
        return option.value
      })

      if (Array.isArray(configParameters[path[0]].fields[path[1]].set_value) && Array.isArray(configParameters[path[0]].fields[path[1]].value)) {
        // Both are arrays, check for equality of arrays
        configParameters[path[0]].edited = JSON.stringify(configParameters[path[0]].fields[path[1]].set_value) !== JSON.stringify(configParameters[path[0]].fields[path[1]].value)
      } else {
        // Not both arrays, check for equality directly
        configParameters[path[0]].edited = configParameters[path[0]].fields[path[1]].set_value !== configParameters[path[0]].fields[path[1]].value
      }
    }

    await this.setState({ configParameters: configParameters })
  }

  isIntegrationDateAfterCreationDate = (integrationDate) => {
    const integration = new Date(integrationDate)
    const creation = new Date(this.props.projectCreationDate)
    return integration > creation
  }

  checkIfDisabled = () => {
    let { saving, loading, configParameters } = this.state
    let disabled = false
    if (saving || loading) {
      disabled = true
    }

    configParameters.forEach((config) => {
      if (!config.fields[2].disable_codes.length || !config.edited) {
        return
      }

      for (const _config of configParameters) {
        if (_config.fields[1].code in config.fields[2].disable_codes && !_config.edited) {
          disabled = true
          return
        }
      }
    })
    return disabled
  }

  renderDropdownItems = (options, name, path) => {
    return options.map((option) => {
      return (
        <button
          key={option}
          className="dropdown-item"
          type="button"
          onClick={() => {
            this.onChange({ target: { name: name, value: option } }, path)
            this.setState({ dropdownOpen: '' })
          }}
        >
          {option}
        </button>
      )
    })
  }

  render() {
    return (
      <div className="modal">
        <form id="pid_process_param_config_form" className="modal-dialog" onSubmit={this.updateProjectConfigParameters} style={{ maxWidth: '800px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.selectedDrawings.length ? 'Drawing Level Configuration' : 'Project Level Configuration'}</h5>
              <button id="pid_process_param_config_close_btn" type="button" className="close" onClick={this.props.closeProjectConfig}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id="unique-99627200div_4">
              <div className="clearfix" id="unique-27164160div_5">
                {!this.props.selectedDrawings.length ? (
                  <span className="float-right" id="unique-81241529span_2">
                    <label
                      onClick={() =>
                        this.setState({
                          override_all_drawing_level_configuration: !this.state.override_all_drawing_level_configuration,
                        })
                      }
                      className={`d-inline-block text-center mr-2 checkbox ${this.state.override_all_drawing_level_configuration ? 'checked' : null}`}
                    />
                    Override all drawing level configuration
                  </span>
                ) : null}
              </div>

              <div className="mt-3" id="unique-34029721div_6">
                <div className="table-container p-0" style={{ height: '20rem' }} id="unique-30713292div_7">
                  {!this.state.loading ? (
                    <table className="table table-striped">
                      <thead className="thead-light">
                        {this.state.configParameters.length ? (
                          <tr id="unique-17769605tr_1">
                            {this.state.configParameters[0].fields.map((field, index) => {
                              return (
                                <th key={index} width={160} id="unique-78102703th_1">
                                  {field.display_name}
                                </th>
                              )
                            })}
                          </tr>
                        ) : null}
                      </thead>
                      <tbody id="unique-69849165tbody_1">
                        {this.state.configParameters.map((param, index) => {
                          return (
                            <tr key={index} id="unique-26488506tr_2">
                              {param.fields.map((field, _index) => {
                                return (
                                  <td className={`${param.edited ? 'bg-warning-light' : ''}`} key={_index} id="unique-97959560td_1">
                                    {field.field_type === `input` ? (
                                      <div className="input-group" id="unique-44776387div_8">
                                        <Input
                                          type={field.type}
                                          name={field.name}
                                          value={field.value.toString()}
                                          list={field.name}
                                          step={field.step ? field.step : ``}
                                          path={`${index}.${_index}`}
                                          onChange={this.onChange}
                                          required={field.required}
                                          disabled={field.disabled || this.isIntegrationDateAfterCreationDate(param.integration_date)}
                                          id="55054880"
                                        />
                                        {field.options.length ? (
                                          <div className="input-group-append" id="unique-99184291div_9">
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() => {
                                                if (this.state.dropdownOpen === '') {
                                                  this.setState({
                                                    dropdownOpen: `${index}.${_index}`,
                                                  })
                                                } else {
                                                  this.setState({
                                                    dropdownOpen: ``,
                                                  })
                                                }
                                              }}
                                              style={{ zIndex: 0 }}
                                              disabled={field.disabled || this.isIntegrationDateAfterCreationDate(param.integration_date)}
                                            >
                                              {this.state.dropdownOpen === `${index}.${_index}` ? (
                                                <i className="fa fa-caret-up" id="unique-89898551i_1"></i>
                                              ) : (
                                                <i className="fa fa-caret-down" id="unique-70815751i_2"></i>
                                              )}
                                            </button>
                                            {this.state.dropdownOpen === `${index}.${_index}` ? (
                                              <div
                                                className="dropdown-menu dropdown-menu-right"
                                                style={{
                                                  maxHeight: 140,
                                                }}
                                                id="unique-98629064div_10"
                                              >
                                                {this.renderDropdownItems(field.options, field.name, `${index}.${_index}`)}
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : field.field_type === `checkbox` ? (
                                      <label
                                        onClick={() => {
                                          if (!field.disabled) {
                                            this.onChange(
                                              {
                                                target: {
                                                  value: !field.value,
                                                },
                                              },
                                              `${index}.${_index}`
                                            )
                                          }
                                        }}
                                        className={`checkbox ${field.value ? 'checked' : null} ${field.disabled ? 'disabled' : null}`}
                                        style={{
                                          fontSize: 16,
                                        }}
                                      />
                                    ) : field.field_type === `multi-select` ? (
                                      !field.disabled ? (
                                        <Select
                                          options={field.options.map((option) => {
                                            return {
                                              label: option,
                                              value: option,
                                            }
                                          })}
                                          value={field.value.map((val) => {
                                            return {
                                              label: val,
                                              value: val.includes('default') ? val.replace(' (default)', '') : val,
                                            }
                                          })}
                                          onChange={(selectedOptions) => {
                                            this.onSelect(selectedOptions, `${index}.${_index}`)
                                          }}
                                          isMulti={true}
                                          isDisabled={field.disabled || this.isIntegrationDateAfterCreationDate(param.integration_date)}
                                          isClearable={false}
                                        />
                                      ) : (
                                        <Input
                                          type={`text`}
                                          name={field.name}
                                          value={field.value.toString()}
                                          path={`${index}.${_index}`}
                                          onChange={this.onChange}
                                          required={field.required}
                                          disabled={field.disabled || this.isIntegrationDateAfterCreationDate(param.integration_date)}
                                          id="55054880"
                                        />
                                      )
                                    ) : field.field_type === `select` ? (
                                      <Select
                                        options={field.options.map((option) => {
                                          return {
                                            label: option,
                                            value: option,
                                          }
                                        })}
                                        value={{ label: field.value, value: field.value }}
                                        onChange={(selectedOption) =>
                                          this.onChange(
                                            {
                                              target: {
                                                value: selectedOption ? selectedOption.value : '',
                                              },
                                            },
                                            `${index}.${_index}`
                                          )
                                        }
                                        isDisabled={field.disabled || this.isIntegrationDateAfterCreationDate(param.integration_date)}
                                        isMulti={false}
                                      />
                                    ) : (
                                      <span id="unique-51086375span_3">
                                        {field.value} &nbsp;
                                        {field.description ? <i className="fa fa-info-circle cursor-pointer" title={`${field.description}`} id="55054880" /> : null}
                                      </span>
                                    )}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <h1 className="text-center mt-4 mb-4" id="unique-86583647h1_1">
                      <i className="fa fa-spin fa-spinner" id="55054880" />
                    </h1>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer" id="unique-97902612div_11">
              <button type="submit" value="Submit" className="btn btn-primary" disabled={this.state.saving || this.state.loading} id="unique-29092015button_4">
                {this.state.saving ? `Saving...` : `Save`}
              </button>
              <button type="button" className="btn btn-default mr-2" onClick={this.props.closeProjectConfig} disabled={this.state.saving} id="unique-95780701button_5">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjectConfigParameters,
  updateProjectConfigParameters,
})(ProjectConfig)
