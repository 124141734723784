import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DisplayMessage from '../ErrorHandling/DisplayMessage.js'

import { SortingState, PagingState, CustomPaging, FilteringState, SearchState } from '@devexpress/dx-react-grid'

import { Grid, TableHeaderRow, PagingPanel, VirtualTable, TableColumnResizing, TableFilterRow, Toolbar, SearchPanel } from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

import { fetchReport, downloadClientReport, updateReportFilter } from '../../store/actions'

class ReportDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product: 'iworkflow-new',
      loading: false,
      displayMessage: null,
      errorMessage: null,

      is_admins: false,
      report_name: '',
      report_id: null,
      report_cols: [],
      report_original_cols: [],
      report_original_rows: [],
      report_rows: [],
      download_status: false,
      download_type_selected: '',
      download_file_type_selected: '',
      view_save: false,
      savingReportFilter: false,
      downloadingClientReport: false,

      currentPage: 0,
      pageSize: 200,
      totalCount: 0,
      pageSizes: [100, 200, 300],
      sorting: [],
      filters: [],
      columnExtensions: [],
      columnWidths: [],

      // Search
      searchValue: '',
    }
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const reportId = query.get('reportId')

    await this.setState({ report_id: reportId })

    if (this.props.user.role === 'SysAdmin-Admin' || this.props.user.role === 'Client Admin') {
      await this.setState({
        is_admins: true,
      })
    }

    this.fetchReport()
  }

  setCurrentPage = async (page) => {
    await this.setState({ currentPage: page })
    this.fetchReport()
  }

  setPageSize = async (pageSize) => {
    await this.setState({ pageSize: pageSize, currentPage: 0 })
    this.fetchReport()
  }

  fetchReport = async () => {
    await this.setState({ loading: true })
    let formData = new FormData()
    let take = this.state.pageSize
    let skip = this.state.pageSize * this.state.currentPage
    formData.append('report_id', this.state.report_id)
    formData.append('skip', skip)
    formData.append('take', take)
    formData.append('sorting', JSON.stringify(this.state.sorting))
    formData.append('filters', JSON.stringify(this.state.filters))
    formData.append('SearchValue', this.state.searchValue)
    await this.props.fetchReport(formData)
    let columns = []
    let columnWidths = []
    let columnExtensions = []

    this.props.user.reportData.columns.forEach((col) => {
      if (col.is_Group) {
        return
      }
      if (this.props.user.reportData.empty_column.includes(col.name)) {
        columnExtensions.push({
          columnName: col.name,
          sortingEnabled: false,
          filteringEnabled: false,
        })
      }

      columnWidths.push({ columnName: col.name, width: 180 })
      columns.push({
        name: col.name,
        title: col.displayName,
        type: col.type,
        checked: true,
        sequence: col.sequence,
        Column_group_id: col.Column_group_id,
        is_Group: col.is_Group,
        getCellValue: (row) => {
          return (
            <div
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                lineHeight: '32px',
                height: '32px',
              }}
              title={row[col.name]}
              id="unique-86171410div_1"
            >
              {row[col.name]}
            </div>
          )
        },
      })
    })

    let rows = []
    this.props.user.reportData.report.forEach((row) => {
      var string = JSON.stringify(row)
      var parse = JSON.parse(string)
      rows.push(parse)
    })

    if (!this.props.user.displayMessageCode) {
      await this.setState({
        report_cols: columns,
        report_original_cols: columns,
        report_original_rows: rows,
        report_rows: rows,
        report_name: this.props.user.reportData.report_name,
        columnWidths: columnWidths,
        columnExtensions: columnExtensions,
        sorting: this.props.user.reportData.sorting,
        filters: this.props.user.reportData.filters,
        totalCount: this.props.user.reportData.report_rows_count,
      })
    }
    await this.setState({ loading: false })
  }

  downloadClientReport = async (e) => {
    e.preventDefault()
    await this.setState({ downloadingClientReport: true })
    let formData = new FormData()
    formData.append('report_id', this.state.report_id)
    formData.append('file_type', this.state.download_file_type_selected)
    formData.append('download_type', this.state.download_type_selected)
    formData.append('filters', JSON.stringify(this.state.filters))

    await this.props.downloadClientReport(formData)
    this.setState({
      download_status: false,
      download_file_type_selected: '',
      download_type_selected: '',
      downloadingClientReport: false,
    })

    if (!this.props.user.displayMessageCode && this.props.user.report_file) {
      let buffer = this._base64ToArrayBuffer(this.props.user.report_file.report)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      // const url = this.props.user.report_file.report
      const link = document.createElement('a')
      link.href = url

      var currentdate = new Date()
      var datetime =
        '-' +
        (currentdate.getDate() < 10 ? '0' : '') +
        currentdate.getDate() +
        '' +
        (currentdate.getMonth() + 1 < 10 ? '0' : '') +
        (currentdate.getMonth() + 1) +
        '' +
        currentdate.getFullYear() +
        '-' +
        currentdate.getHours() +
        '' +
        currentdate.getMinutes() +
        ''

      link.setAttribute('download', `${this.props.user.report_file.fileName}${datetime}.${this.props.user.report_file.extension}`)
      link.click()
    }
  }

  updateReportFilter = async (e) => {
    e.preventDefault()
    await this.setState({ savingReportFilter: true })
    let formData = new FormData()
    formData.append('report_id', this.state.report_id)
    formData.append('report_name', this.state.report_name)
    formData.append('columns', JSON.stringify(this.state.report_cols))
    formData.append('col_original', JSON.stringify(this.state.report_original_cols))
    formData.append('filters', JSON.stringify(this.state.filters))
    formData.append('sorting', JSON.stringify(this.state.sorting))
    formData.append('page', 'view')

    await this.props.updateReportFilter(formData)
    await this.setState({ savingReportFilter: false, view_save: false })
    if (!this.props.user.displayMessageCode) {
      this.fetchReport()
    }
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  setSorting = async (sorting) => {
    await this.setState({ sorting: sorting, currentPage: 0 })
    this.fetchReport()
  }

  getRowId = (row) => row.id

  setFilters = (filters) => {
    this.setState({ filters: filters })
  }

  setColumnWidths = (columnWidths) => {
    this.setState({ columnWidths: columnWidths })
  }

  setSearchState = async (searchValue) => {
    await this.setState({ searchValue: searchValue })
  }

  render() {
    let { product, displayMessage, download_status, report_name, report_id, is_admins } = this.state
    let download_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_download-report_hidden`) ? true : false
    let edit_report_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_edit-report_hidden`) ? true : false
    let save_and_apply_filters_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_save-apply-filters_hidden`) ? true : false
    let apply_filters_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_apply-filters_hidden`) ? true : false

    return (
      <div className="dashboard main" id="unique-62933069div_2">
        <ol className="breadcrumb" id="unique-10520949ol_1">
          <li className="breadcrumb-item" id="unique-92198308li_1">
            <Link to="/dashboard" id="unique-95032463link_1">
              Product Dashboard
            </Link>
          </li>
          <li className="breadcrumb-item" id="unique-10114501li_2">
            <Link to="/iworkflow-new" id="unique-17824715link_2">
              iWorkflow New
            </Link>
          </li>
          <li className="breadcrumb-item active" id="unique-11263356li_3">
            {report_name}
          </li>
        </ol>

        {displayMessage ? <DisplayMessage {...this.props.user.displayMessageCode} /> : null}

        <div className="mb-2 card" id="unique-44734608div_3">
          <div className="card-body p-2 clearfix" id="unique-68703599div_4">
            {!download_report_hidden ? (
              <button
                id="view_report_download_status_btn"
                className="btn btn-primary float-right"
                onClick={() =>
                  this.setState({
                    download_status: !download_status,
                  })
                }
              >
                Download <i className="fa fa-download" id="unique-54894380i_1"></i>
              </button>
            ) : null}

            {is_admins && !edit_report_hidden ? (
              <button
                id="view_report_edit_report_link"
                className="btn btn-primary float-right mr-2"
                onClick={() =>
                  this.props.history.push({
                    pathname: '/iworkflow-new/report-edit',
                    search: 'reportId=' + report_id,
                  })
                }
                disabled={this.state.loading}
              >
                Edit <i className="fa fa-edit" id="unique-49561044i_2"></i>
              </button>
            ) : null}

            {!save_and_apply_filters_hidden ? (
              <button
                id="view_report_save_and_apply_filters_btn"
                className="btn btn-primary float-right mr-2"
                onClick={() =>
                  this.setState({
                    view_save: !this.state.view_save,
                  })
                }
              >
                Save & Apply Filters
              </button>
            ) : null}

            {!apply_filters_hidden ? (
              <button id="view_report_apply_filters_btn" className="btn btn-primary float-right mr-2" onClick={() => this.fetchReport()}>
                Apply Filters
              </button>
            ) : null}

            <span className="bg-light border d-inline-block float-right mr-2 py-2 px-2 rounded" style={{ fontSize: 14 }} id="unique-25940604span_1">
              Rows Count - {this.state.totalCount}
            </span>

            <h3 className="mb-0" id="unique-56745181h3_1">
              {this.state.report_name}
            </h3>
          </div>
        </div>

        {this.state.download_status ? (
          <form id="view_report_download_report_form" className="modal" onSubmit={this.downloadClientReport}>
            <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-10097823div_56">
              <div className="modal-content" id="unique-10097824div_25">
                <div className="modal-header" id="unique-div10097823div_21">
                  <h5 className="modal-title">Download</h5>
                  <button
                    id="view_report_download_report_form_close_btn"
                    type="button"
                    className="close"
                    onClick={() =>
                      this.setState({
                        download_status: false,
                        download_type_selected: '',
                        download_file_type_selected: '',
                      })
                    }
                  >
                    <span aria-hidden="true" id="unique-66142183span_2">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body" id="unique-66045023div_8">
                  <div className="form-group" id="unique-86464623div_9">
                    <label id="unique-47090272label_1">Download Type</label>
                    <select
                      className="form-control"
                      value={this.state.download_type_selected}
                      onChange={(e) => this.setState({ download_type_selected: e.target.value })}
                      required={true}
                    >
                      <option value={''}>--Select--</option>
                      <option value={'Original Report'}>Original Report</option>
                      <option value={'Filtered Report'}>Filtered Report</option>
                    </select>
                  </div>

                  <div className="form-group" id="unique-45985502div_10">
                    <label id="unique-49852488label_2">File Type</label>
                    <select
                      className="form-control"
                      value={this.state.download_file_type_selected}
                      onChange={(e) => this.setState({ download_file_type_selected: e.target.value })}
                      required={true}
                    >
                      <option value={''}>--Select--</option>
                      <option value={'Excel File'}>Excel File</option>
                      <option value={'.CSV File'}>.CSV File</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer" id="unique-92096042div_11">
                  <button
                    id="view_report_download_report_form_cancel_btn"
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      this.setState({
                        download_status: false,
                        download_type_selected: '',
                        download_file_type_selected: '',
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button id="view_report_download_report_form_submit_btn" type="submit" className="btn btn-primary" disabled={this.state.downloadingClientReport}>
                    Download {this.state.downloadingClientReport ? <i className="fa fa-spin fa-spinner" /> : null}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        {this.state.view_save ? (
          <form id="view_report_save_and_apply_filters_form" className="modal" onSubmit={this.updateReportFilter}>
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Save & Apply Filters</h5>
                  <button id="view_report_save_and_apply_filters_form_close_btn" type="button" className="close" onClick={() => this.setState({ view_save: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" id="unique-56212029div_16">
                  <p id="unique-75954718p_1">Are you sure to save all settings include Filters, Columns and Sorting you have made right now?</p>
                </div>
                <div className="modal-footer" id="unique-23058112div_17">
                  <button
                    type="button"
                    className="btn btn-default"
                    id="unique-23008019button_9"
                    onClick={() =>
                      this.setState({
                        view_save: false,
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button type="submit" id="view_report_save_and_apply_filters_form_submit_btn" className="btn btn-primary" disabled={this.state.savingReportFilter}>
                    Save & Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        <div style={{ position: 'relative' }} id="unique-52288151div_18">
          <div style={{ position: 'absolute', width: '100%' }} id="unique-80588115div_19">
            <div className="card report-view-grid" id="unique-15467521div_20">
              <Grid columns={this.state.report_cols} rows={this.state.report_rows} getRowId={this.getRowId}>
                <FilteringState filters={this.state.filters} onFiltersChange={this.setFilters} columnExtensions={this.state.columnExtensions} />
                <PagingState currentPage={this.state.currentPage} onCurrentPageChange={this.setCurrentPage} pageSize={this.state.pageSize} onPageSizeChange={this.setPageSize} />
                <PagingPanel pageSizes={this.state.pageSizes} />
                <SearchState onValueChange={this.setSearchState} />
                <SortingState sorting={this.state.sorting} onSortingChange={this.setSorting} columnExtensions={this.state.columnExtensions} />
                <CustomPaging totalCount={this.state.totalCount} />
                <VirtualTable />
                <TableColumnResizing columnWidths={this.state.columnWidths} onColumnWidthsChange={this.setColumnWidths} />

                <TableHeaderRow showSortingControls />
                <TableFilterRow showFilterSelector />
                <Toolbar />
                <SearchPanel />
              </Grid>
              {this.state.loading ? (
                <div className="card-loader" id="unique-76915842div_21">
                  <div id="loading" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchReport,
  downloadClientReport,
  updateReportFilter,
})(ReportDetail)
