import React from 'react'

class ToggleThumbnails extends React.Component {
  render() {
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={this.props.isToggled} onChange={(e) => this.props.updateToggledThumbnails(e.target.checked)} />
        <span className={`switcher`} />
      </label>
    )
  }
}

export default ToggleThumbnails
