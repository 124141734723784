import React from 'react'
import { connect } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import Select from './../Forms/Fields/select'
import Input from '../Forms/Fields/input'

import './WebComponent.css'
import PropertyConfig from './PropertyConfig'
import {
  autoTaggingToogle,
  tagConfigData,
  fetchTagConfigData,
  testTagConfigData,
  fetchTagTitleRegion,
  fetchTagTemplateData,
  fetchMatchingTemplates,
  refreshUserAuth,
} from './../../store/actions'
import UploadLOVExcel from '../Forms/UploadLOVExcel'

class TaggingConfig extends React.Component {
  state = {
    projectName: null,
    projectId: null,
    partialSuccessMessage: null,
    partialErrorMessage: null,
    loading: false,
    addingTemplate: false,
    unlockedTemplatesWarning: false,
    autoTagging: false,
    product: null,
    tag_sample: '',
    fetchingMatchedTemplates: false,

    taggingConfigData: {},
    componentList: [],
    activeComponent: 'Lines',
    activeSubComponent: null,
    orderComponents: [],
    orderComponentsDisabled: [],
    order: {},
    orderOptions: {},
    symbolNameOptions: {},
    standardList: {},
    disciplines: [],
    manufacturers: [],
    finalized: false,

    saving: false,
    finalizing: false,
    tagTitleRegion: null,
    tagTitleRegionLoading: false,
    add_mode: false,
  }

  componentDidUpdate(props) {
    if (props.user.isSignedIn !== this.props.user.isSignedIn) {
      this.changePage()
    }
  }

  uuid = () => {
    return 'xxxxxxxxxxxxyxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : r & 0x3 ? r & 0x3 : 0x8
      return v.toString(16)
    })
  }

  componentDidMount = async () => {
    this.changePage()
    const query = new URLSearchParams(this.props.location.search)
    const name = query.get('name')
    const projectId = query.get('id')
    const product = query.get('product')

    await this.setState({
      projectName: name,
      projectId: projectId,
      product: product,
    })
    await this.fetchTagConfigData()
  }

  changePage = async () => {
    if (this.props.user.isSignedIn === undefined) {
      await this.props.refreshUserAuth()
    }

    if (this.props.user.isSignedIn === false) {
      this.props.history.push('/')
    }
  }

  clearDisplayMessage = () => {
    setTimeout(
      () =>
        this.setState({
          partialErrorMessage: null,
          partialSuccessMessage: null,
        }),
      10000
    )
  }

  testTagConfigData = async (mode, template) => {
    let { activeComponent, activeSubComponent, taggingConfigData, product } = this.state

    let _taggingConfigData = taggingConfigData
    let templates = []
    if (activeSubComponent && activeSubComponent !== 'Property') {
      templates = _taggingConfigData[activeComponent][activeSubComponent]
    }

    let template_locked_map = {}
    let _templates = []

    if (mode === 'Test') {
      if (template.ReplaceChar) {
        if (template.ReplaceChar.Source.split(',').length !== template.ReplaceChar.Target.split(',').length) {
          this.setState({
            partialErrorMessage: `Template ${template.TemplateNumber}: Source & Target for Replace Char field do not match in length`,
          })
          this.clearDisplayMessage()
          return
        }
      }

      templates.forEach((_template) => {
        if (_template.TemplateNumber === template.TemplateNumber) {
          template_locked_map[_template.TemplateID] = _template.locked
          _templates.push(_template)
          _template.Testing = true
        }
      })

      this.setState({ taggingConfigData: _taggingConfigData })
    } else {
      await this.saveTagConfigData()
      await this.setState({ finalizing: true })
      templates.forEach((template) => {
        template_locked_map[template.TemplateID] = template.locked
        if (!template.locked) {
          _templates.push(template)
        }
      })
    }

    // formatting request
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('taggingConfigData', JSON.stringify(_templates))
    formData.append('component', activeComponent)
    formData.append('text_type', activeSubComponent)
    formData.append('product_type', product)
    formData.append('mode', mode)
    formData.append('order', this.state.order[activeComponent][activeSubComponent])

    await this.props.testTagConfigData(formData)
    let finalized = false
    if (!this.props.user.taggingConfigDataErrorMessage && this.props.user.testTaggingConfigData) {
      finalized = this.props.user.testTaggingConfigData.finalized
      this.props.user.testTaggingConfigData.testTaggingConfigData.forEach((TemplateResult) => {
        if (!TemplateResult['success']) {
          if (!this.state.partialErrorMessage) {
            this.setState({
              partialErrorMessage: 'Template ' + TemplateResult['TemplateNumber'] + ': ' + TemplateResult['message'],
            })
          } else {
            this.setState({
              partialErrorMessage: this.state.partialErrorMessage + '\n Template ' + TemplateResult['TemplateNumber'] + ': ' + TemplateResult['message'],
            })
          }
        } else {
          if (!this.state.partialSuccessMessage) {
            this.setState({
              partialSuccessMessage: 'Template ' + TemplateResult['TemplateNumber'] + ': ' + TemplateResult['message'],
            })
          } else {
            this.setState({
              partialSuccessMessage: this.state.partialSuccessMessage + '\n Template ' + TemplateResult['TemplateNumber'] + ': ' + TemplateResult['message'],
            })
          }
        }

        this.clearDisplayMessage()

        templates.forEach((template) => {
          if (template.TemplateNumber === TemplateResult.TemplateNumber) {
            template.TemplateRules.forEach((attr) => {
              attr.TagAttribute = TemplateResult.TagAttributes[attr.AttributeName]
            })
          }
        })
      })

      this.setState({
        taggingConfigData: _taggingConfigData,
        finalized: finalized,
      })
    }

    if (mode === 'Test') {
      templates.forEach((_template) => {
        if (_template.TemplateNumber === template.TemplateNumber) {
          _template.Testing = false
        }
      })
    } else {
      templates.forEach((_template) => {
        if (_template.TemplateID in this.props.user.testTaggingConfigData.template_locked_map) {
          _template.locked = this.props.user.testTaggingConfigData.template_locked_map[_template.TemplateID]
        }
      })
    }
    this.setState({
      taggingConfigData: _taggingConfigData,
      finalizing: false,
    })
  }

  fetchTagConfigData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    await this.props.fetchTagConfigData(formData)
    if (!this.props.user.taggingConfigDataErrorMessage && this.props.user.taggingConfigData) {
      this.setConstants()
    }
    this.setState({ loading: false })
  }

  setConstants = () => {
    this.setState({
      taggingConfigData: this.props.user.taggingConfigData.taggingConfigData,
      componentList: this.props.user.taggingConfigData.componentList,
      activeComponent: this.props.user.taggingConfigData.activeComponent,
      orderOptions: this.props.user.taggingConfigData.orderOptions,
      order: this.props.user.taggingConfigData.order,
      symbolNameOptions: this.props.user.taggingConfigData.symbolNameOptions,
      finalized: this.props.user.taggingConfigData.finalized,
      orderComponents: this.props.user.taggingConfigData.orderComponents,
      orderComponentsDisabled: this.props.user.taggingConfigData.orderComponentsDisabled,
      standardList: this.props.user.taggingConfigData.standardList,
      disciplines: this.props.user.taggingConfigData.disciplines,
      autoTagging: this.props.user.taggingConfigData.autoTagging,
      manufacturers: this.props.user.taggingConfigData.manufacturers,
    })
  }

  saveTagConfigData = async () => {
    let { projectId, taggingConfigData, activeComponent, activeSubComponent, order } = this.state

    let template_locked_map = {}
    let templates = []
    taggingConfigData[activeComponent][activeSubComponent].forEach((template) => {
      template_locked_map[template.TemplateID] = template.locked ? true : false
      if (!template.locked) {
        templates.push(template)
      }
    })

    order = activeComponent in order && activeSubComponent in order[activeComponent] ? order[activeComponent][activeSubComponent] : []

    await this.setState({ saving: true })
    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('taggingConfigData', JSON.stringify(templates))
    formData.append('tagCategory', activeComponent)
    formData.append('tagType', activeSubComponent)
    formData.append('order', order)
    formData.append('template_locked_map', JSON.stringify(template_locked_map))

    await this.props.tagConfigData(formData)
    await this.setState({ saving: false })
  }

  renderSubComponentList = (subComponentList) => {
    return subComponentList.map((component) => {
      return (
        <div key={component} style={{ fontSize: 13 }} id="unique-29956520div_1">
          <div className="px-2 py-1" id="unique-67111076div_2">
            <span className="d-inline-block text-truncate" style={{ width: 25, fontSize: 12 }} id="unique-26381833span_1">
              {this.state.activeSubComponent === component ? (
                <label className="checkbox checked" id="75559034" />
              ) : this.state.finalized ? (
                <label
                  className={`checkbox ${this.checkIfActiveCategoriesContainsUnlockedTemplates() ? 'disabled' : ''}`}
                  onClick={() => {
                    if (this.checkIfActiveCategoriesContainsUnlockedTemplates()) {
                      return
                    }
                    this.setState({
                      activeSubComponent: component,
                    })
                  }}
                />
              ) : component !== 'Property' ? (
                <label
                  className={`checkbox ${this.checkIfActiveCategoriesContainsUnlockedTemplates() ? 'disabled' : ''}`}
                  onClick={() => {
                    if (this.checkIfActiveCategoriesContainsUnlockedTemplates()) {
                      return
                    }
                    this.setState({
                      activeSubComponent: component,
                    })
                  }}
                />
              ) : (
                <label className="checkbox disabled" id="75559034" />
              )}
            </span>

            <span title={component} className="ml-1 d-inline-block text-truncate" style={{ width: 160 }} id="unique-50959003span_2">
              {component}
            </span>
          </div>
        </div>
      )
    })
  }

  changeActiveComponent = async (name) => {
    await this.setState({
      activeComponent: name,
      activeSubComponent: null,
    })
    if (name === 'TitleBoxes' || name === 'TitleBoxes_Contractor') {
      this.fetchTagTitleRegion()
    }
  }

  fetchTagTitleRegion = async () => {
    this.setState({ tagTitleRegionLoading: true })
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('component', this.state.activeComponent)
    formData.append('product_type', this.state.product)
    await this.props.fetchTagTitleRegion(formData)
    if (!this.props.taggingConfigDataErrorMessage) {
      this.setState({ tagTitleRegion: this.props.user.tagTitleRegion })
    }
    this.setState({ tagTitleRegionLoading: false })
  }

  checkIfActiveCategoriesContainsUnlockedTemplates = () => {
    let { activeComponent, activeSubComponent, taggingConfigData } = this.state

    if (
      activeComponent in taggingConfigData &&
      activeSubComponent in taggingConfigData[activeComponent] &&
      taggingConfigData[activeComponent][activeSubComponent].filter((template) => !template.locked).length
    ) {
      return true
    }

    return false
  }

  renderComponentList = () => {
    return this.state.componentList.map((component) => {
      return (
        <div key={component.name} id="unique-11116198div_3">
          <div className="px-2 py-1" id="unique-75445095div_4">
            <span className="d-inline-block text-truncate" style={{ width: 30, fontSize: 14 }} id="unique-10546563span_3">
              {this.state.activeComponent === component.name ? (
                <label className="checkbox checked" id="75559034" />
              ) : (
                <label
                  className={`checkbox ${this.checkIfActiveCategoriesContainsUnlockedTemplates() ? 'disabled' : ''}`}
                  onClick={() => {
                    if (this.checkIfActiveCategoriesContainsUnlockedTemplates()) {
                      return
                    }
                    this.changeActiveComponent(component.name)
                  }}
                />
              )}
            </span>
            <span title={component.displayName} className="ml-1 d-inline-block text-truncate" style={{ width: 160 }} id="unique-16164928span_4">
              {component.displayName}
            </span>
          </div>

          {component.options && this.state.activeComponent === component.name ? (
            <div className="pl-4" id="unique-79724955div_5">
              {this.renderSubComponentList(component.options)}
            </div>
          ) : null}
        </div>
      )
    })
  }

  addTemplate = async (template = null) => {
    await this.saveTagConfigData()

    if (!template) {
      await this.setState({ addingTemplate: true })
    } else {
      await this.setState({ cloningTemplate: template.TemplateID })
    }

    let _taggingConfigData = this.state.taggingConfigData
    let templates = _taggingConfigData[this.state.activeComponent]
    if (this.state.activeSubComponent && this.state.activeSubComponent !== 'Property') {
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    let temp = null

    const formData = new FormData()
    formData.append(`projectId`, this.state.projectId)
    formData.append(`component`, this.state.activeComponent)
    formData.append(`text_type`, this.state.activeSubComponent)
    if (template) {
      formData.append(`tagId`, template.TemplateID)
    }

    await this.props.fetchTagTemplateData(formData)
    if (!this.props.user.templateDataErrorMessage) {
      temp = this.props.user.templateData
      templates.push(temp)
      if (this.state.activeSubComponent && this.state.activeSubComponent && this.state.activeSubComponent !== 'Property') {
        _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent] = templates
      } else {
        _taggingConfigData[this.state.activeComponent] = templates
      }
      await this.setState({ taggingConfigData: _taggingConfigData }, this.scrollToMyRef)
    } else {
      this.setState({
        partialErrorMessage: this.props.user.templateDataErrorMessage,
      })
      this.clearDisplayMessage()
    }

    this.setState({ addingTemplate: false, cloningTemplate: '' })
    await this.saveTagConfigData()
  }

  addAttribute = (TemplateNumber, AttributeName = '') => {
    this.setState({ add_mode: false })

    let taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in taggingConfigData) {
      templates = taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in taggingConfigData[this.state.activeComponent]) {
        templates = taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let max_Id = 0
        let max_order = 0
        template.TemplateRules.forEach((attr) => {
          if (attr.Id > max_Id) {
            max_Id = attr.Id
          }
          if (attr.Order > max_order) {
            max_order = attr.Order
          }
        })
        template.TemplateRules.push({
          Id: max_Id + 1,
          AttributeName: AttributeName,
          Optional: false,
          Split: false,
          Order: max_order + 1,
          Separator: '',
          Length: '',
          CharType: { Letter: false, Digit: false, Special: '' },
          ListOfValues: '',
          TagAttribute: '',
          LOV: '',
          LOVMap: {},
        })
      }
    })

    this.setState({ taggingConfigData: taggingConfigData })
  }

  checkStandardAttributeFormFilled = (TemplateNumber) => {
    let filled = true
    let taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in taggingConfigData) {
      templates = taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in taggingConfigData[this.state.activeComponent]) {
        templates = taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }
    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        template.TemplateRules.forEach((attr) => {
          if (attr.AttributeName === '') {
            filled = false
          }
        })
      }
    })
    return filled
  }

  checkOthersAttributeFormFilled = (TemplateNumber) => {
    let filled = true
    let taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in taggingConfigData) {
      templates = taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in taggingConfigData[this.state.activeComponent]) {
        templates = taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }
    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        template.TemplateRules.forEach((attr) => {
          if (attr.AttributeName.includes('Others:') && attr.AttributeName === 'Others:') {
            filled = false
          }
        })
      }
    })
    return filled
  }

  autoTaggingToogle = async () => {
    await this.setState({ autoTagging: !this.state.autoTagging })

    const formData = new FormData()
    formData.append(`projectId`, this.state.projectId)
    formData.append('autoTagging', this.state.autoTagging)
    await this.props.autoTaggingToogle(formData)
  }

  fetchMatchingTemplates = async (e) => {
    e.preventDefault()
    let { product, projectId, tag_sample, activeComponent, activeSubComponent } = this.state

    await this.setState({
      fetchingMatchedTemplates: true,
    })

    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('product_type', product)
    formData.append('tag_sample', tag_sample)
    formData.append('tagCategory', activeComponent)
    formData.append('tagType', activeSubComponent)
    await this.props.fetchMatchingTemplates(formData)
    await this.setState({
      fetchingMatchedTemplates: false,
    })

    if (!this.props.user.tagMatchingTemplates) {
      return
    }
    if (this.props.user.tagMatchingTemplates.errorMessage) {
      this.setState({ partialErrorMessage: this.props.user.tagMatchingTemplates.errorMessage })
    } else {
      let matchedTemplates = this.props.user.tagMatchingTemplates.matchedTemplates
      if (matchedTemplates.length) {
        let message = `Tag Sample matched with the following templates:`
        matchedTemplates.forEach((template, index) => {
          message += ` Template ${template.TemplateNumber}${index !== matchedTemplates.length - 1 ? ',' : ''}`
        })
        this.setState({ partialSuccessMessage: message })
      } else {
        this.setState({ partialErrorMessage: 'Tag Sample did not match any existing templates.' })
      }
    }
  }

  scrollToMyRef = () => {
    var elmnt = document.getElementById('scroll_ref')
    if (elmnt) {
      elmnt.scrollIntoView()
    }
  }

  onSelect = (selectedList, selectedItem) => {
    let order = this.state.order
    order[this.state.activeComponent][this.state.activeSubComponent] = selectedList
    this.setState({ order: order })
  }

  onRemove = (selectedList, removedItem) => {
    let order = this.state.order
    order[this.state.activeComponent][this.state.activeSubComponent] = selectedList
    this.setState({ order: order })
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderHeading = () => {
    let activeComponent = null
    this.state.componentList.forEach((component) => {
      if (component.name === this.state.activeComponent) {
        activeComponent = component
      }
    })
    return (
      <h3 className="d-inline-block mr-3" id="unique-16012831h3_1">
        {activeComponent.displayName} {this.state.activeSubComponent} Configuration
      </h3>
    )
  }

  renderTaggingConfigForm = () => {
    let activeComponent = null
    this.state.componentList.forEach((component) => {
      if (component.name === this.state.activeComponent) {
        activeComponent = component
      }
    })

    if (activeComponent && (!activeComponent.options || (activeComponent.options && this.state.activeSubComponent && this.state.activeSubComponent !== 'Property'))) {
      return (
        <div id="unique-92869015div_6">
          <div className="mb-3 clearfix" id="unique-10518347div_7">
            <button
              id={`tagging_config_add_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_btn`}
              className={'btn btn-primary float-right'}
              disabled={
                this.state.addingTemplate ||
                (this.state.orderComponentsDisabled.includes(this.state.activeComponent) &&
                  !(
                    'order' in this.state.taggingConfigData &&
                    this.state.activeComponent in this.state.taggingConfigData.order &&
                    this.state.taggingConfigData.order[this.state.activeComponent].length
                  ))
                  ? true
                  : false
              }
              onClick={() => this.addTemplate()}
            >
              {this.state.addingTemplate ? 'Adding Template...' : 'Add Template'}
            </button>
            <button
              id={`tagging_config_save_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_btn`}
              disabled={this.state.saving ? true : false}
              className={'btn btn-primary float-right mr-2'}
              onClick={this.saveTagConfigData}
            >
              {this.state.saving ? 'Saving...' : 'Save'}
            </button>

            <button
              id={`tagging_config_finalize_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_btn`}
              disabled={this.state.finalizing ? true : false}
              className={'btn btn-primary float-right mr-2'}
              onClick={() => this.testTagConfigData('Save')}
            >
              {!this.state.finalizing ? 'Finalize' : 'Finalizing ...'}
            </button>

            {this.state.orderComponents.includes(this.state.activeComponent) ? (
              <div style={{ maxWidth: 600 }} className="d-inline-block float-right mr-2 multiselect-bg-light" id="unique-54048489div_8">
                <Multiselect
                  closeOnSelect={false}
                  options={
                    this.state.activeSubComponent
                      ? this.state.orderOptions[this.state.activeComponent][this.state.activeSubComponent]
                      : this.state.orderOptions[this.state.activeComponent].default
                  }
                  selectedValues={
                    this.state.activeComponent in this.state.order && this.state.activeSubComponent in this.state.order[this.state.activeComponent]
                      ? this.state.order[this.state.activeComponent][this.state.activeSubComponent]
                      : []
                  }
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  isObject={false}
                  placeholder={'Select Enclosed Text Order'}
                  showCheckbox={true}
                  avoidHighlightFirstOption={true}
                  keepSearchTerm={true}
                />
              </div>
            ) : null}

            {this.renderHeading()}
            <form
              id={`tagging_config_find_tag_sample_for_${this.state.activeComponent}_${this.state.activeSubComponent}_form`}
              className="d-inline-block"
              style={{ width: 400 }}
              onSubmit={this.fetchMatchingTemplates}
            >
              <div className="input-group" id="div_003_input12121">
                <Input type="text" placeholder={'Tag Sample'} name="tag_sample" value={this.state.tag_sample} onChange={this.onChange} required={true} />
                <div className="input-group-append" id="div_001_input">
                  <button
                    id={`tagging_config_find_tag_sample_for_${this.state.activeComponent}_${this.state.activeSubComponent}_submit_btn`}
                    type="submit"
                    className="btn btn-primary"
                    title="Find Matching Templates"
                    disabled={this.state.fetchingMatchedTemplates}
                  >
                    Find
                  </button>
                </div>
              </div>
            </form>
          </div>

          {this.state.partialErrorMessage ? <div className="alert alert-warning mb-2">{this.state.partialErrorMessage}</div> : null}
          {this.state.partialSuccessMessage ? <div className="alert alert-success mb-2">{this.state.partialSuccessMessage}</div> : null}
          <div className="templates">
            {(this.state.activeComponent === 'TitleBoxes' || this.state.activeComponent === 'TitleBoxes_Contractor') &&
            (this.state.tagTitleRegion || this.state.tagTitleRegionLoading) ? (
              <div className="template text-center" id="div_001_214214211">
                {this.state.tagTitleRegionLoading ? <i className="fa fa-spin fa-spinner"></i> : <img src={this.state.tagTitleRegion} className="img-fluid" alt="Drawing" />}
              </div>
            ) : null}
            {this.renderTemplates()}
            <div id="scroll_ref" />
          </div>
        </div>
      )
    }

    if (activeComponent && activeComponent.options && this.state.activeSubComponent === 'Property') {
      return <PropertyConfig activeComponent={this.state.activeComponent} projectId={this.state.projectId} product={this.state.product} />
    }

    return null
  }

  renderSymbolNameOptions = () => {
    return this.state.symbolNameOptions[this.state.activeComponent].map((option) => {
      return (
        <option key={option} value={option}>
          {option}
        </option>
      )
    })
  }

  renderTemplateConditionalForm = (template) => {
    if (this.state.activeComponent.includes('TitleBoxes')) {
      return (
        <div className="col-6" id="unique-44220647div_15">
          <div className="row no-gutters" id="unique-97921943div_16">
            <div className="col mt-2" id="unique-90261839div_17">
              <div className="d-inline-block text-center" id="unique-11503805div_18">
                <label onClick={(e) => this.onTemplateKeyValueChange(e, template, 'Location')} className={template.Location ? 'checkbox checked' : 'checkbox'} />
              </div>
              Location
            </div>
            <div className="col mt-2" id="unique-63557205div_19">
              <div className="d-inline-block text-center" id="unique-11235504div_20">
                <label onClick={(e) => this.onTemplateKeyValueChange(e, template, 'TagPattern')} className={template.TagPattern ? 'checkbox checked' : 'checkbox'} />
              </div>
              Tag Pattern
            </div>
            <div className="col mr-2" id="div_033214214211">
              <Input
                type="text"
                placeholder="Keyword"
                name="Keyword"
                value={template.Keyword}
                path={`${template.TemplateNumber}`}
                onChange={this.onTemplateKeyValueChange}
                disabled={!this.state.tagTitleRegion}
              />
            </div>
            <div className="col" id="unique_001_214214211">
              <Input
                type="text"
                placeholder="Cell Number"
                name="CellNumber"
                value={template.CellNumber}
                path={`${template.TemplateNumber}`}
                onChange={this.onTemplateKeyValueChange}
                disabled={!this.state.tagTitleRegion}
              />
            </div>
          </div>
        </div>
      )
    }

    if (this.state.activeComponent in this.state.symbolNameOptions && this.state.symbolNameOptions[this.state.activeComponent].length > 0) {
      return (
        <div className="col" id="unique-73187396div_23">
          <div className="input-group" id="unique-11142914div_24">
            <div className="input-group-prepend" id="unique-19976413div_25">
              <span className="input-group-text" id="unique-66447053span_5">
                SymbolName
              </span>
            </div>
            <select value={template.SymbolName} name="SymbolName" onChange={(e) => this.onTemplateKeyValueChange(e, template)} className="form-control" disabled={template.locked}>
              <option value=""> -- Select -- </option>
              {this.renderSymbolNameOptions()}
            </select>
          </div>
        </div>
      )
    }

    return null
  }

  renderTemplates = () => {
    let { disciplines, activeComponent, activeSubComponent, taggingConfigData, cloningTemplate } = this.state

    let templates = []

    if (activeComponent in taggingConfigData) {
      templates = taggingConfigData[activeComponent]
      if (activeSubComponent in taggingConfigData[activeComponent]) {
        templates = taggingConfigData[activeComponent][activeSubComponent]
      }
    }

    return templates.map((template) => {
      let documentTypeOptions = []
      if ('selectedDisciplines' in template) {
        template.selectedDisciplines.forEach((discipline) => {
          disciplines[activeComponent][activeSubComponent].forEach((_discipline) => {
            if (_discipline.id === discipline.id) {
              documentTypeOptions = [...documentTypeOptions, ..._discipline.DocumentTypes]
            }
          })
        })
      }

      return (
        <div key={template.TemplateNumber} className={'template'} id="unique-21932615div_26">
          <div className="row" id="unique-42238108div_27">
            <div className="col-md-1" id="unique-21213670div_28">
              <span className="badge badge-light" id="unique-34818809span_6">
                {template.TemplateNumber}
              </span>
            </div>
            <div className="col-md-7" id="unique-95897588div_29">
              <div className="row" id="unique-45278401div_30">
                {this.renderTemplateConditionalForm(template)}
                <div className="col-6" id="unique-29560039div_31">
                  <div className="input-group" id="unique-97021074div_32">
                    <div className="input-group-prepend" id="unique-72783493div_33">
                      <span className="input-group-text" id="unique-24407207span_7">
                        Tag Sample
                      </span>
                    </div>
                    <Input
                      type="text"
                      name="TagSample"
                      value={template.TagSample}
                      path={`${template.TemplateNumber}`}
                      onChange={this.onTemplateKeyValueChange}
                      disabled={(activeComponent.includes('TitleBoxes') && !template.TagPattern ? true : false) || template.locked}
                    />
                    <div className="input-group-append">
                      <button
                        id={`tagging_config_test_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                        className={'btn btn-primary btn-sm float-right mr-2'}
                        onClick={() => this.testTagConfigData('Test', template)}
                      >
                        {!template.Testing ? 'Test' : 'Testing ...'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 clearfix">
              <button
                id={`tagging_config_delete_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                className="btn float-right"
                onClick={() => this.deleteTemplate(template)}
              >
                <i className="fa fa-trash-alt text-secondary" style={{ fontSize: 22 }} />
              </button>

              <button
                id={`tagging_config_collapse_template_toggler_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                className="btn btn-primary float-right mr-1"
                onClick={() => this.collapseHandler(template)}
              >
                {template.Collapse ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
              </button>

              <button
                id={`tagging_config_clone_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                className={'btn btn-primary float-right mr-1'}
                onClick={() => this.addTemplate(template)}
                disabled={cloningTemplate === template.TemplateID}
              >
                {cloningTemplate === template.TemplateID ? 'Cloning...' : 'Clone'}
              </button>

              <button
                id={`tagging_config_template_lock_toggler_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                className="btn float-right mr-1 text-secondary"
                disabled={!template.locked}
              >
                {template.locked ? (
                  <i className="fa fa-lock" style={{ fontSize: 22 }} title={'Locked'} onClick={() => this.unlockTemplate(template)} />
                ) : (
                  <i className="fa fa-lock-open" style={{ fontSize: 22 }} title={'Unlocked'} />
                )}
              </button>

              <span className="d-inline-block mr-2 float-right" style={{ padding: '.375rem 0' }} id="unique-24638942span_8">
                <label
                  className={`checkbox d-inline-block text-center ${template.FloatingText ? 'checked' : ''} ${template.locked ? 'disabled' : ''}`}
                  onClick={() => {
                    if (!template.locked) {
                      this.onTemplateKeyValueChange(
                        {
                          target: {
                            name: 'FloatingText',
                            value: !template.FloatingText,
                          },
                        },
                        template.TemplateNumber
                      )
                    }
                  }}
                />{' '}
                Floating Text
              </span>
            </div>
          </div>

          {template.Collapse ? (
            <div className="row mt-4" id="unique-38612156div_36">
              {this.state.activeComponent.includes('TitleBoxes') && template.Location ? (
                <>
                  <div className="col" id="div_1101_214214211">
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="MinX"
                      name="MinX"
                      className="mb-3"
                      value={template.MinX}
                      path={`${template.TemplateNumber}`}
                      onChange={this.onTemplateKeyValueChange}
                      disabled={template.locked}
                    />
                  </div>
                  <div className="col">
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="MinY"
                      name="MinY"
                      className="mb-3"
                      value={template.MinY}
                      path={`${template.TemplateNumber}`}
                      onChange={this.onTemplateKeyValueChange}
                      disabled={template.locked}
                    />
                  </div>

                  <div className="col">
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="MaxX"
                      name="MaxX"
                      className="mb-3"
                      value={template.MaxX}
                      path={`${template.TemplateNumber}`}
                      onChange={this.onTemplateKeyValueChange}
                      disabled={template.locked}
                    />
                  </div>
                  <div className="col">
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="MaxY"
                      name="MaxY"
                      className="mb-3"
                      value={template.MaxY}
                      path={`${template.TemplateNumber}`}
                      onChange={this.onTemplateKeyValueChange}
                      disabled={template.locked}
                    />
                  </div>
                </>
              ) : null}

              {activeComponent in disciplines && activeSubComponent in disciplines[activeComponent] && disciplines[activeComponent][activeSubComponent].length ? (
                <>
                  <div className="col-md-4 mb-3" id="unique-28458911div_41">
                    <div className="clearfix mb-1" id="unique-10449728div_42">
                      {!template.locked ? (
                        <span className="float-right" id="unique-99692854span_9">
                          <label
                            className={`checkbox ${disciplines[activeComponent][activeSubComponent].length === template.selectedDisciplines.length ? 'checked' : ''}`}
                            onClick={() => {
                              if (template.selectedDisciplines.length === disciplines[activeComponent][activeSubComponent].length) {
                                this.onSelectDiscipline([], template, 'selectedDisciplines')
                              } else {
                                this.onSelectDiscipline(disciplines[activeComponent][activeSubComponent], template, 'selectedDisciplines')
                              }
                            }}
                          />{' '}
                          Select All
                        </span>
                      ) : null}
                      <label id="unique-75252499label_11">Disciplines</label>
                    </div>
                    <Select
                      options={disciplines[activeComponent][activeSubComponent]}
                      onChange={(selectedDisciplines) => {
                        this.onSelectDiscipline(selectedDisciplines, template, 'selectedDisciplines')
                      }}
                      isMulti={true}
                      value={template.selectedDisciplines}
                      isDisabled={template.locked}
                    />
                  </div>
                  <div className="col-md-4 mb-3" id="unique-25572497div_43">
                    <div className="clearfix mb-1" id="unique-14235533div_44">
                      {!template.locked ? (
                        <span className="float-right" id="unique-34346822span_10">
                          <label
                            className={`checkbox ${documentTypeOptions.length === template.selectedDocumentTypes.length ? 'checked' : ''}`}
                            onClick={() => {
                              if (documentTypeOptions.length === template.selectedDocumentTypes.length) {
                                this.onSelectDiscipline([], template, 'selectedDocumentTypes')
                              } else {
                                this.onSelectDiscipline(documentTypeOptions, template, 'selectedDocumentTypes')
                              }
                            }}
                          />{' '}
                          Select All
                        </span>
                      ) : null}
                      <label id="unique-46970094label_13">Document Types</label>
                    </div>
                    <Select
                      options={documentTypeOptions}
                      onChange={(selectedDocumentTypes) => {
                        this.onSelectDiscipline(selectedDocumentTypes, template, 'selectedDocumentTypes')
                      }}
                      isMulti={true}
                      value={template.selectedDocumentTypes}
                      isDisabled={template.locked || !documentTypeOptions.length}
                    />
                  </div>
                </>
              ) : null}

              {this.state.manufacturers.length ? (
                <div className="col-md-4 mb-3" id="unique-10978520div_45">
                  <div className="clearfix mb-1" id="unique-30866723div_46">
                    {!template.locked ? (
                      <span className="float-right" id="unique-72178237span_11">
                        <label
                          className={`checkbox ${this.state.manufacturers.length === template.selectedManufacturers.length ? 'checked' : ''}`}
                          onClick={() => {
                            if (template.selectedManufacturers.length === this.state.manufacturers.length) {
                              this.onSelectDiscipline([], template, 'selectedManufacturers')
                            } else {
                              this.onSelectDiscipline(this.state.manufacturers, template, 'selectedManufacturers')
                            }
                          }}
                        />{' '}
                        Select All
                      </span>
                    ) : null}
                    <label id="unique-10427443label_15">Manufacturers</label>
                  </div>
                  <Select
                    options={this.state.manufacturers}
                    onChange={(selectedDisciplines) => {
                      this.onSelectDiscipline(selectedDisciplines, template, 'selectedManufacturers')
                    }}
                    isMulti={true}
                    value={template.selectedManufacturers}
                    isDisabled={template.locked}
                  />
                </div>
              ) : null}

              <div className={activeComponent.includes('TitleBoxes') && template.Location ? 'col-3' : 'col'} id="unique-50719792div_47">
                <div className="input-group mb-3" id="unique-52702394div_48">
                  <div className="input-group-prepend" id="unique-90386720div_49">
                    <span className="input-group-text" id="unique-89209456span_12">
                      REGEX
                    </span>
                  </div>
                  <Input
                    type="text"
                    placeholder={'Tag Sample'}
                    name="REGEX"
                    value={template.REGEX}
                    path={`${template.TemplateNumber}`}
                    onChange={this.onTemplateKeyValueChange}
                    disabled={(activeComponent.includes('TitleBoxes') && !template.TagPattern ? true : false) || template.locked}
                  />
                </div>
              </div>
              <div className={activeComponent.includes('TitleBoxes') && template.Location ? 'col-4' : 'col'} id="unique-40009728div_50">
                <div className="input-group mb-3" id="unique-21469231div_51">
                  <div className="input-group-prepend" id="unique-86516354div_52">
                    <span className="input-group-text" id="unique-35739556span_13">
                      Replace Char
                    </span>
                  </div>
                  <Input
                    type="text"
                    name="Source"
                    value={template.ReplaceChar ? template.ReplaceChar.Source : ''}
                    path={`${template.TemplateNumber}`}
                    onChange={this.onTemplateKeyValueChange}
                    disabled={(activeComponent.includes('TitleBoxes') && !template.TagPattern ? true : false) || template.locked}
                  />
                  <div className="input-group-append" id="unique-34333831div_53">
                    <span className="input-group-text" id="unique-69211912span_14">
                      By
                    </span>
                  </div>
                  <Input
                    type="text"
                    name="Target"
                    className="border-left-0"
                    value={template.ReplaceChar ? template.ReplaceChar.Target : ''}
                    path={`${template.TemplateNumber}`}
                    onChange={this.onTemplateKeyValueChange}
                    disabled={(activeComponent.includes('TitleBoxes') && !template.TagPattern ? true : false) || template.locked}
                  />
                </div>
              </div>

              <div className="col-md-12" id="unique-70240653div_54">
                <table className={'form table'}>
                  <thead>
                    <tr id="unique-60559583tr_1">
                      <th id="unique-14829469th_1">Optional</th>
                      <th id="unique-10797931th_2">Attribute</th>
                      {['TitleBoxes_Contractor', 'TitleBoxes'].includes(this.state.activeComponent) ? <th id="unique-72685034th_3">Line</th> : null}
                      <th id="unique-63129766th_4">Split</th>
                      <th id="unique-20822243th_5">Tag Attribute</th>
                      <th style={{ width: 100 }} id="unique-11568286th_6">
                        Order
                      </th>
                      <th id="unique-80011505th_7">Seperator</th>
                      <th id="unique-37905299th_8">Length</th>
                      <th id="unique-38584610th_9">Char Type</th>
                      <th id="unique-54204787th_10">LOVs</th>
                      <th id="unique-72954901th_11"></th>
                    </tr>
                  </thead>
                  <tbody id="unique-11385278tbody_1">{this.renderTemplateRulesForm(template, template.TemplateNumber, template.TemplateRules)}</tbody>
                </table>

                <div className="clearfix mt-2 mb-2" id="unique-11319837div_55">
                  <button
                    id={`tagging_config_add_template_for_${this.state.activeComponent}_${this.state.activeSubComponent}_tempalte_${template.TemplateNumber}_btn`}
                    className="btn btn-primary btn-sm float-right"
                    disabled={
                      (this.checkStandardAttributeFormFilled(template.TemplateNumber) && this.checkOthersAttributeFormFilled(template.TemplateNumber) ? false : true) ||
                      template.locked
                    }
                    onClick={() => this.addAttribute(template.TemplateNumber)}
                  >
                    Attribute &nbsp; <i className="fa fa-plus" id="unique-47269324i_7"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )
    })
  }

  // Form Helper methods
  deleteTemplate = async (Template) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    let _templates = []
    templates.forEach((template) => {
      if (template.TemplateNumber !== Template.TemplateNumber) {
        template.TemplateNumber = _templates.length + 1
        _templates.push(template)
      }
    })

    if (this.state.activeComponent && this.state.activeSubComponent) {
      _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent] = _templates
    } else {
      _taggingConfigData[this.state.activeComponent] = _templates
    }

    await this.setState({ taggingConfigData: _taggingConfigData })
    this.saveTagConfigData()
  }

  onTemplateKeyValueChange = (event, TemplateNumber, name = null) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber.toString() === TemplateNumber.toString()) {
        if (!name) {
          if (event.target.name === 'Source' || event.target.name === 'Target') {
            if (!template.ReplaceChar) {
              template.ReplaceChar = {
                Source: '',
                Target: '',
              }
            }
            template.ReplaceChar[event.target.name] = event.target.value
          } else {
            template[event.target.name] = event.target.value
          }
        } else {
          template[name] = !template[name]
        }
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onSelectDiscipline = (selectedList, Template, name = 'selectedDisciplines') => {
    let { disciplines, taggingConfigData, activeComponent, activeSubComponent } = this.state

    let templates = []
    if (activeComponent in taggingConfigData) {
      templates = taggingConfigData[activeComponent]
      if (activeSubComponent in taggingConfigData[activeComponent]) {
        templates = taggingConfigData[activeComponent][activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber === Template.TemplateNumber) {
        template[name] = selectedList
        if (name === 'selectedDisciplines' && 'selectedDocumentTypes' in template) {
          let DocumentTypes = []
          template.selectedDisciplines.forEach((discipline) => {
            disciplines[activeComponent][activeSubComponent].forEach((_discipline) => {
              if (discipline.id === _discipline.id) {
                template.selectedDocumentTypes.forEach((documentType) => {
                  _discipline.DocumentTypes.forEach((_documentType) => {
                    if (_documentType.id === documentType.id) {
                      DocumentTypes.push(documentType)
                    }
                  })
                })
              }
            })
          })

          template.selectedDocumentTypes = DocumentTypes
        }
      }
    })

    this.setState({ taggingConfigData: taggingConfigData })
  }

  collapseHandler = (Template) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
    const index = templates.findIndex((template) => template.TemplateNumber === Template.TemplateNumber)
    if (index > -1) {
      templates[index].Collapse = !Template.Collapse
    }
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  unlockTemplate = (Template) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
    const index = templates.findIndex((template) => template.TemplateNumber === Template.TemplateNumber)
    if (index > -1) {
      templates[index].locked = !Template.locked
    }
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onChangeOrderHandler = (TemplateNumber, Id, up) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let index = template.TemplateRules.findIndex((attr) => attr.Id === Id)
        if (index > -1) {
          if (up) {
            if (index > 0) {
              let swap_order = template.TemplateRules[index - 1].Order
              template.TemplateRules[index - 1].Order = template.TemplateRules[index].Order
              template.TemplateRules[index].Order = swap_order
            }
          } else {
            if (index + 1 < template.TemplateRules.length) {
              let swap_order = template.TemplateRules[index].Order
              template.TemplateRules[index].Order = template.TemplateRules[index + 1].Order
              template.TemplateRules[index + 1].Order = swap_order
            }
          }
        }
      }
    })

    this.setState({ taggingConfigData: _taggingConfigData })
  }

  updateLOVData = (TemplateNumber, Id, data) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let index = template.TemplateRules.findIndex((attr) => attr.Id === Id)
        if (index > -1) {
          template.TemplateRules[index]['LOV'] = data
        }
      }
    })

    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onInputChangeHandler = (event, TemplateNumber, Id) => {
    if (event.target.value === 'add_attribute') {
      this.setState({ add_mode: true })
      return
    }

    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber.toString() === TemplateNumber.toString()) {
        let addedStandardString = this.getAddedStandardString(template.TemplateRules)
        let index = template.TemplateRules.findIndex((attr) => attr.Id.toString() === Id.toString())

        if (index > -1) {
          if (event.target.name === 'AttributeName' && addedStandardString.includes(event.target.value) && !this.state.add_mode) {
            let counter = 0
            template.TemplateRules.forEach((attr) => {
              if (attr.AttributeName.includes(event.target.value)) {
                counter += 1
              }
            })

            if (counter === 1) {
              let update_index = template.TemplateRules.findIndex((attr) => attr.AttributeName.includes(event.target.value))
              if (update_index > -1) {
                template.TemplateRules[update_index][event.target.name] = `${event.target.value}:${event.target.value}${counter}`
              }
            }

            template.TemplateRules[index][event.target.name] = `${event.target.value}:${event.target.value}${counter + 1}`
          } else {
            template.TemplateRules[index][event.target.name] = event.target.value
          }
        }
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onInputChangeCharTypeHandler = (event, TemplateNumber, Id) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }
    templates.forEach((template) => {
      if (template.TemplateNumber.toString() === TemplateNumber.toString()) {
        let index = template.TemplateRules.findIndex((attr) => attr.Id.toString() === Id.toString())
        if (index > -1) {
          template.TemplateRules[index].CharType[event.target.name] = event.target.value
        }
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onCheckChangeHandler = (TemplateNumber, Id, name, checked) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }
    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let index = template.TemplateRules.findIndex((attr) => attr.Id === Id)
        if (index > -1) {
          template.TemplateRules[index][name] = checked
        }
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  onCheckChangeCharTypeHandler = (TemplateNumber, Id, name, checked) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }
    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let index = template.TemplateRules.findIndex((attr) => attr.Id === Id)
        if (index > -1) {
          template.TemplateRules[index].CharType[name] = checked
        }
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  deleteTemplateRule = (TemplateNumber, Id, _deleteCustomSizeAttributes = true) => {
    let _taggingConfigData = this.state.taggingConfigData
    let templates = []
    if (this.state.activeComponent in _taggingConfigData) {
      templates = _taggingConfigData[this.state.activeComponent]
      if (this.state.activeSubComponent in _taggingConfigData[this.state.activeComponent]) {
        templates = _taggingConfigData[this.state.activeComponent][this.state.activeSubComponent]
      }
    }

    templates.forEach((template) => {
      if (template.TemplateNumber === TemplateNumber) {
        let _templateRules = []
        let deleteCustomSizeAttributes = false
        template.TemplateRules.forEach((attr) => {
          if (attr.AttributeName === 'Size:Size1' || attr.AttributeName === 'Size:Size2') {
            deleteCustomSizeAttributes = true
          }
        })

        let orderIndex = 1

        template.TemplateRules.forEach((attr) => {
          if (attr.Id !== Id) {
            attr.Order = orderIndex
            attr.Id = orderIndex
            orderIndex += 1

            if (deleteCustomSizeAttributes && _deleteCustomSizeAttributes) {
              if (attr.AttributeName !== 'Size:Size1' && attr.AttributeName !== 'Size:Size2') {
                _templateRules.push(attr)
              }
            } else {
              _templateRules.push(attr)
            }
          }
        })
        template.TemplateRules = _templateRules
      }
    })
    this.setState({ taggingConfigData: _taggingConfigData })
  }

  //   End Form Helper Methods
  getAddedStandardString = (TemplateRules) => {
    let addedStandardString = ''
    TemplateRules.forEach((attr) => {
      if (!attr.AttributeName.includes('Others:')) {
        addedStandardString += attr.AttributeName
      }
    })
    return addedStandardString
  }

  renderStandardListOptions = (TemplateRules) => {
    let { standardList, activeComponent, activeSubComponent } = this.state
    if (!(activeComponent in standardList && activeSubComponent in standardList[activeComponent])) {
      return
    }

    let options = standardList[activeComponent].default
    if (activeSubComponent) {
      options = standardList[activeComponent][activeSubComponent]
    }

    TemplateRules.forEach((_template) => {
      if (!options.includes(_template.AttributeName) && _template.AttributeName) {
        if (_template.AttributeName.includes(':')) {
          if (!options.includes(_template.AttributeName.split(':')[0])) {
            options.push(_template.AttributeName.split(':')[0])
          }
        } else {
          options.push(_template.AttributeName)
        }
      }
    })

    return options.map((attr) => {
      return (
        <option value={attr} key={attr}>
          {attr}
        </option>
      )
    })
  }

  templateRulesHaveSplit = (TemplateRules) => {
    let has_split = false
    TemplateRules.forEach((attr) => {
      if (attr.Split) {
        has_split = true
      }
    })
    return has_split
  }

  renderTemplateRulesForm = (template, TemplateNumber, TemplateRules) => {
    TemplateRules.sort(function (a, b) {
      return a.Order - b.Order
    })

    return TemplateRules.map((attr) => {
      return (
        <tr key={attr.Id} id="unique-15416865tr_2">
          <td className="text-center" id="unique-21564110td_1">
            <label
              className={`checkbox ${attr.Optional ? 'checked' : ''} ${
                (this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked ? 'disabled' : ''
              }`}
              onClick={() => {
                if ((this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked) {
                  return
                }
                this.onCheckChangeHandler(TemplateNumber, attr.Id, 'Optional', !attr.Optional)
              }}
            />
          </td>

          <td id="unique-86098111td_2">
            {attr.AttributeName === '' && !this.state.add_mode ? (
              <select
                name="AttributeName"
                value={attr.AttributeName}
                className="form-control"
                onChange={(e) => this.onInputChangeHandler(e, TemplateNumber, attr.Id)}
                disabled={template.locked}
              >
                <option value=""> -- Select -- </option>
                {this.renderStandardListOptions(TemplateRules)}
                <option value="add_attribute"> Add Attribute </option>
              </select>
            ) : this.state.add_mode ? (
              <Input
                type="text"
                name="AttributeName"
                value={attr.AttributeName}
                path={`${TemplateNumber}`}
                path2={`${attr.Id}`}
                onChange={this.onInputChangeHandler}
                disallowedSymbols={['@', '.', '"']}
                disabled={template.locked}
              />
            ) : (
              attr.AttributeName
            )}
          </td>
          {['TitleBoxes_Contractor', 'TitleBoxes'].includes(this.state.activeComponent) ? (
            <td>
              <Input
                type="text"
                name="Line"
                value={attr.Line}
                path={`${TemplateNumber}`}
                path2={`${attr.Id}`}
                onChange={this.onInputChangeHandler}
                disabled={(template.CellNumber === '' && template.Keyword === '') || template.locked}
              />
            </td>
          ) : null}
          <td className="text-center" id="unique-80481443td_4">
            {attr.Split ? (
              (this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked ? (
                <label className="checkbox checked disabled" id="75559034" />
              ) : (
                <label className="checkbox checked" onClick={() => this.onCheckChangeHandler(TemplateNumber, attr.Id, 'Split', false)} />
              )
            ) : !this.templateRulesHaveSplit(TemplateRules) ? (
              (this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked ? (
                <label className="checkbox disabled" id="75559034" />
              ) : (
                <label className="checkbox" onClick={() => this.onCheckChangeHandler(TemplateNumber, attr.Id, 'Split', true)} />
              )
            ) : (
              <label className="checkbox disabled" id="75559034" />
            )}
          </td>
          <td id="unique-11332957td_5">
            <input value={attr.TagAttribute} name="Special" className="form-control mb-1" disabled id="75559034" />
          </td>
          <td id="unique-93667196td_6">
            <button type="button" className="btn btn-primary btn-sm mr-1" onClick={() => this.onChangeOrderHandler(TemplateNumber, attr.Id, false)} disabled={template.locked}>
              <i className="fa fa-angle-down" id="75559034" />
            </button>
            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.onChangeOrderHandler(TemplateNumber, attr.Id, true)} disabled={template.locked}>
              <i className="fa fa-angle-up" id="75559034" />
            </button>
          </td>
          <td id="td_001_214214211">
            <Input
              type="text"
              name="Separator"
              value={attr.Separator}
              path={`${TemplateNumber}`}
              path2={`${attr.Id}`}
              onChange={this.onInputChangeHandler}
              disabled={(this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked}
            />
          </td>
          <td>
            <Input
              type="text"
              name="Length"
              value={attr.Length}
              path={`${TemplateNumber}`}
              path2={`${attr.Id}`}
              onChange={this.onInputChangeHandler}
              disabled={(this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked}
            />
          </td>
          <td>
            <div className="input-group">
              <Input
                type="text"
                name="Special"
                value={attr.CharType.Special}
                className="mb-1"
                path={`${TemplateNumber}`}
                path2={`${attr.Id}`}
                onChange={this.onInputChangeCharTypeHandler}
                disabled={(this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked}
              />

              <div className="input-group-append text-center" style={{ padding: '3px 8px' }} id="unique-68503584div_57">
                <span className="d-inline-block" id="unique-63120753span_15">
                  <label
                    className={`checkbox ${attr.CharType.Letter ? 'checked' : ''} ${
                      (this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      if ((this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked) {
                        return
                      }
                      this.onCheckChangeCharTypeHandler(TemplateNumber, attr.Id, 'Letter', !attr.CharType.Letter)
                    }}
                  />
                  Letter
                </span>
              </div>
              <div className="input-group-append text-center" style={{ padding: '3px 8px' }} id="unique-10178662div_58">
                <span className="d-inline-block" id="unique-53785627span_16">
                  <label
                    className={`checkbox ${attr.CharType.Digit ? 'checked' : ''} ${
                      (this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      if ((this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked) {
                        return
                      }
                      this.onCheckChangeCharTypeHandler(TemplateNumber, attr.Id, 'Digit', !attr.CharType.Digit)
                    }}
                  />
                  Digit
                </span>
              </div>
            </div>
          </td>

          <td>
            <div className="input-group">
              <Input
                type="text"
                name="LOV"
                value={attr.LOV}
                path={`${TemplateNumber}`}
                path2={`${attr.Id}`}
                onChange={this.onInputChangeHandler}
                disabled={(this.state.activeComponent.includes('TitleBoxes') && !template.TagPattern) || template.locked}
              />

              <div className="input-group-append" id="div_011_214214211">
                <UploadLOVExcel
                  {...this.props}
                  TemplateNumber={TemplateNumber}
                  attrId={attr.Id}
                  updateLOVData={this.updateLOVData}
                  disabled={template.locked}
                  projectId={this.state.projectId}
                />
              </div>
            </div>
          </td>

          <td id="unique-44553498td_11">
            <button style={{ fontSize: 16 }} className="btn text-secondary" onClick={() => this.deleteTemplateRule(TemplateNumber, attr.Id)} disabled={template.locked}>
              <i className="fa fa-trash-alt" id="75559034" />
            </button>
          </td>
        </tr>
      )
    })
  }

  renderTaggingConfig() {
    if (this.state.loading) {
      return (
        <div className="deliverables" id="unique-10984516div_3">
          <div id="preload">
            <div className="sk-folding-cube">
              <div className="sk-cube1 sk-cube"></div>
              <div className="sk-cube2 sk-cube"></div>
              <div className="sk-cube4 sk-cube"></div>
              <div className="sk-cube3 sk-cube"></div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="deliverables" id="unique-82383038div_61">
        <div className="inner-body" id="unique-73542032div_62">
          <div className="content" id="unique-53690429div_63">
            <button
              className="close custom float-right"
              onClick={() => {
                if (this.checkIfActiveCategoriesContainsUnlockedTemplates()) {
                  this.setState({
                    unlockedTemplatesWarning: true,
                  })
                } else {
                  this.props.history.push(`/pid/project?name=${this.state.projectName}&id=${this.state.projectId}&product=${this.state.product}`)
                }
              }}
            >
              <i className="fa fa-times" />
            </button>

            <div className="clearfix mb-3" id="di22v_001_214214211">
              <label id="tagging_config_enable_auto_tagging_toggler" className="d-inline-block float-right text-center cursor-pointer" onClick={this.autoTaggingToogle}>
                <label className={`checkbox ${this.state.autoTagging ? 'checked' : ''}`} /> Enable Auto-Tagging
              </label>

              <h3 className="mb-3 font-weight-bold" id="unique-78857800h3_2">
                Component Tagging & Symbol Configuration
              </h3>
            </div>

            <div className="row" id="unique-77046478div_66">
              <div className="col-md-2" id="unique-40313592div_67">
                <div className="components-wrapper p-2" id="unique-40176393div_68">
                  <h5 id="unique-79693361h5_1">
                    <b>Components</b>
                  </h5>
                  <div className="body" style={{ maxHeight: 600 }} id="unique-60279501div_69">
                    {this.renderComponentList()}
                  </div>
                </div>
              </div>
              <div className="col-md-10" id="unique-55791661div_70">
                {this.props.user.taggingConfigDataErrorMessage ? (
                  <div className="alert alert-danger mb-2" id="unique-35289669div_71">
                    {this.props.user.taggingConfigDataErrorMessage}
                  </div>
                ) : null}
                {this.renderTaggingConfigForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="dashboard main" id="unique-89334655div_72">
        {this.state.unlockedTemplatesWarning ? (
          <div className="modal" id="div_021_214214211">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    id={`tagging_config_close_unlocked_templates_warning_btn`}
                    type="button"
                    className="close"
                    onClick={() => this.setState({ unlockedTemplatesWarning: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" id="unique-30896862div_77">
                  <p id="unique-56380423p_1">
                    There are unsaved templates in {this.state.activeComponent} {this.state.activeSubComponent}. Please Finalize them first before exiting.
                  </p>
                </div>
                <div className="modal-footer" id="div_001213213214214211">
                  <button
                    id={`tagging_config_confirm_unlocked_templates_warning_btn`}
                    className="btn btn-secondary"
                    onClick={() => this.setState({ unlockedTemplatesWarning: false })}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.renderTaggingConfig()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  autoTaggingToogle,
  tagConfigData,
  fetchTagConfigData,
  testTagConfigData,
  fetchTagTitleRegion,
  fetchTagTemplateData,
  fetchMatchingTemplates,
  refreshUserAuth,
})(TaggingConfig)
