import React from 'react'
import Paginator from './Paginator'

class DynamicTable extends React.Component {
  state = {
    actual_data: [],
    data: [],
    columns: [],
    sortedOption: '',
    reverseSortedOption: '',
    collapsedData: '',
    nested: false,
    reset_paginator: false,
  }

  componentDidMount = function () {
    this.setState(
      {
        actual_data: this.props.data,
        data: this.props.data,
        nested: this.props.nested,
      },
      this.generateColumns
    )
  }

  componentDidUpdate = (props) => {
    if (this.props.data !== props.data) {
      this.setState({ data: this.props.data, actual_data: this.props.data }, this.generateColumns)
    }
  }

  generateColumns = () => {
    let columns = []
    if (this.state.data.length) {
      let obj = this.state.data[0]
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value !== 'object' || value === null) {
          columns.push(key)
        }
      }
    }
    this.setState({ columns: columns })
  }

  compareObjects(object1, object2, key) {
    let obj1 = object1[key]
    let obj2 = object2[key]

    if (typeof obj1 === 'string' && typeof obj2 === 'string') {
      obj1 = object1[key].toUpperCase()
      obj2 = object2[key].toUpperCase()
    } else {
      obj1 = parseFloat(object1[key])
      obj2 = parseFloat(object2[key])
    }

    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }
    return 0
  }

  sort = () => {
    let data = this.state.actual_data
    data.sort((pdf1, pdf2) => {
      return this.compareObjects(pdf1, pdf2, this.state.sortedOption)
    })

    this.setState({ data: data, reset_paginator: true })
  }

  reverseCompareObjects(object1, object2, key) {
    let obj1 = object1[key]
    let obj2 = object2[key]

    if (typeof obj1 === 'string' && typeof obj2 === 'string') {
      obj1 = object1[key].toUpperCase()
      obj2 = object2[key].toUpperCase()
    } else {
      obj1 = parseFloat(object1[key])
      obj2 = parseFloat(object2[key])
    }

    if (obj1 > obj2) {
      return -1
    }
    if (obj1 < obj2) {
      return 1
    }
    return 0
  }

  reverseSort = () => {
    let data = this.state.actual_data
    data.sort((pdf1, pdf2) => {
      return this.reverseCompareObjects(pdf1, pdf2, this.state.reverseSortedOption)
    })

    this.setState({ data: data, reset_paginator: true })
  }

  changePage = (data) => {
    this.setState({
      data: data,
      reset_paginator: false,
    })
  }

  renderTable = () => {
    return (
      <table className="table table-striped">
        <thead className="thead-light">
          <tr id="unique-95351563tr_1">
            {this.state.columns.map((column) => {
              return (
                <th key={column} style={{ whiteSpace: 'nowrap' }} id="unique-43067576th_1">
                  {column} &nbsp;
                  <i
                    onClick={() => {
                      if (this.state.sortedOption === '') {
                        this.setState(
                          {
                            sortedOption: column,
                            reverseSortedOption: '',
                          },
                          this.sort
                        )
                      } else {
                        this.setState(
                          {
                            sortedOption: '',
                            reverseSortedOption: column,
                          },
                          this.reverseSort
                        )
                      }
                    }}
                    className={
                      this.state.sortedOption === column
                        ? `fas fa-sort-amount-up cursor-pointer text-muted ${this.state.sortedOption === column ? 'active' : null}`
                        : `fas fa-sort-amount-down cursor-pointer text-muted ${this.state.reverseSortedOption === column ? 'active' : null}`
                    }
                  />
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody id="unique-58681843tbody_1">
          {this.state.data.map((row, index) => {
            return (
              <tr key={index} id="unique-11036340tr_2">
                {Object.entries(row).map(([key, value]) => {
                  if (typeof value !== 'object' || value === null) {
                    return (
                      <td key={`${index}__${key}`} id="unique-90183322td_1">
                        {value}
                      </td>
                    )
                  }

                  return null
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  nestedTableHeader = () => {
    return this.state.columns.map((column) => {
      if (column === 'Tag Number') {
        return (
          <div
            className="card"
            key={column}
            style={{
              backgroundColor: '#eee',
              fontSize: 17,
              fontWeight: 'bold',
              color: 'darkslategrey',
            }}
            id="unique-11791582div_1"
          >
            <div className="card-body" id="unique-26180821div_2">
              {column} &nbsp;
              {/* <i 
                                onClick={() => {
                                    if(this.state.sortedOption === ''){
                                        this.setState({sortedOption: column, reverseSortedOption: ''}, this.sort)
                                    }else{
                                        this.setState({sortedOption: '', reverseSortedOption: column}, this.reverseSort)
                                    }
                                    
                                }}
                                className={
                                    (this.state.sortedOption === column)?
                                    `fas fa-sort-amount-up cursor-pointer text-muted ${this.state.sortedOption === column? 'active':null}` 
                                    :
                                    `fas fa-sort-amount-down cursor-pointer text-muted ${this.state.reverseSortedOption === column? 'active':null}`
                                }
                            /> */}
            </div>
          </div>
        )
      }
      return null
    })
  }

  nestedTable = () => {
    return this.state.data.map((row, index) => {
      return (
        <div className={`card`} key={index} id="unique-10157212div_3">
          <div className={`card-body ${index % 2 === 0 ? 'bg-eee' : ''}`} id="unique-59682195div_4">
            {row['data'].length ? (
              <button
                onClick={() => {
                  if (this.state.collapsedData === row['Tag Number']) {
                    this.setState({ collapsedData: '' })
                  } else {
                    this.setState({
                      collapsedData: row['Tag Number'],
                    })
                  }
                }}
                className="btn btn-default btn-sm"
              >
                <i className={`fa ${this.state.collapsedData === row['Tag Number'] ? 'fa-minus' : 'fa-plus'}`} id="67458355" />
              </button>
            ) : null}
            <span className="tag-number" id="unique-49930253span_1">
              {row['Tag Number']}
            </span>

            {row['JFilename'] !== '' ? (
              <span className="badge badge-light d-inline-block j-file" id="unique-80970759span_2">
                J
              </span>
            ) : null}
            {row['VFilename'] !== '' ? (
              <span className="badge badge-light d-inline-block v-file" id="unique-31021922span_3">
                V
              </span>
            ) : null}
          </div>

          {row['data'].length && this.state.collapsedData === row['Tag Number'] ? (
            <div className="p-2" id="unique-54571606div_5">
              <table className="table">
                <thead>
                  <tr id="unique-10995178tr_3">
                    {Object.entries(row['data'][0]).map(([key, value]) => {
                      if (typeof value !== 'object' || value === null) {
                        return (
                          <th key={`${key}`} id="unique-75244799th_2">
                            {key}
                          </th>
                        )
                      }

                      return null
                    })}
                  </tr>
                </thead>
                <tbody id="unique-90984228tbody_2">
                  {row['data'].map((_row, _index) => {
                    return (
                      <tr key={_index} id="unique-52650702tr_4">
                        {Object.entries(_row).map(([key, value]) => {
                          if (typeof value !== 'object' || value === null) {
                            return (
                              <td key={`${_index}__${key}`} id="unique-89543944td_2">
                                {value}
                              </td>
                            )
                          }

                          return null
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      )
    })
  }

  // renderFlippedTable = () => {
  //     return(
  //         <table className="table table-striped">
  //             <tbody id="unique-83479482tbody_3">
  //                 {this.state.columns.map(column => {
  //                     return (
  //                         <tr key={column} id="unique-14940406tr_5">
  //                             <th   style={{maxWidth: 200, minWidth: 100}} id="unique-38117910th_3">
  //                                 {column} &nbsp;
  //                                 <i
  //                                     onClick={() => {
  //                                         if(this.state.sortedOption === ''){
  //                                             this.setState({sortedOption: column, reverseSortedOption: ''}, this.sort)
  //                                         }else{
  //                                             this.setState({sortedOption: '', reverseSortedOption: column}, this.reverseSort)
  //                                         }

  //                                     }}
  //                                     className={
  //                                         (this.state.sortedOption === column)?
  //                                         `fas fa-sort-amount-up cursor-pointer text-muted ${this.state.sortedOption === column? 'active':null}`
  //                                         :
  //                                         `fas fa-sort-amount-down cursor-pointer text-muted ${this.state.reverseSortedOption === column? 'active':null}`
  //                                       }
  //                                 />
  //                             </th>
  //                             {this.state.data.map((row, index) => {
  //                                 return(
  //                                     <td key={`${index}__${column}`}   id="unique-19168580td_3">
  //                                         {row[column]}
  //                                     </td>
  //                                 )
  //                             })}
  //                         </tr>
  //                     )
  //                 })}
  //             </tbody>
  //         </table>
  //     )
  // }

  render() {
    return (
      <>
        {this.props.pagination ? (
          <div className="mt-2 mb-3" id="unique-59355826div_6">
            <Paginator data={this.props.data} paginationSize={50} changePage={this.changePage} reset_paginator={this.state.reset_paginator} />
          </div>
        ) : null}

        <div className="table-container-wrapper" id="unique-33197828div_7">
          <div className="table-container p-0" style={{ height: '34rem' }} id="unique-67345967div_8">
            {!this.state.nested ? (
              this.renderTable()
            ) : (
              <>
                {this.nestedTableHeader()}
                {this.nestedTable()}
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default DynamicTable
