import React from 'react'
import jexcel from 'jexcel'
import download from './downloadExcel'
import './jexcel.css'

class JExcel extends React.Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
  }

  componentDidMount = function () {
    this.el = jexcel(this.wrapper.current, this.props.options)
  }

  downloadExcel = function () {
    if (this.props.toggleMultipleView) {
      download(this.props.options, this.props.activeDeliverable, this.props.activeDeliverable)
    } else {
      let drawing = this.props.allDrawings[this.props.activeDrawingIndex - 1]
      download(this.props.options, this.props.drawingNum + '_' + this.props.activeDeliverable, 'pg' + drawing.b_Id.split('pg')[1])
    }
  }

  render() {
    return (
      <>
        <div className="clearfix" id="unique-10188340div_1">
          <span className="float-right" id="unique-26852474span_1">
            <button className="btn btn-primary btn-sm mb-1" onClick={() => this.downloadExcel()}>
              Download Excel &nbsp; <i className="fa fa-download" id="unique-41361629i_1"></i>
            </button>
          </span>
        </div>

        <div className="jexcel-table" id="unique-65511948div_2">
          <div ref={this.wrapper} id="53986081" />
        </div>
      </>
    )
  }
}

export default JExcel
