import React from 'react'
import { connect } from 'react-redux'

import ToggleSwitch from '../ToggleSwitch'
import IUIAnnotation from '../../../IUI/IUIAnnotation'
import Select from '../../Forms/Fields/select'

import {
  updateDrawingsPackageImage,
  showSuggestions,
  updateModelConfigurationData,
  revertLastTemplateDetection,
  revertAllChanges,
  taggingConfigurationConfigure,
} from './../../../store/actions'

var _ = require('lodash')

class Step3 extends React.Component {
  state = {
    schema: {},
    componentAttrTypes: {},
    selectTypeAttrs: [],
    selectLookupDict: {},
    standardGroup: [],
    geoGroup: [],
    metaGroup: [],
    editorColumns: {},
    groupVisibility: [],
    requiredGroupFields: [],
    colorSet: {},
    toggledSwitchList: [],
    componentTreeSchema: {},
    collapseList: [],
    configurableGroup: [],
    compNameMap: {},
    attrNameMap: {},

    imageWidth: 0,
    imageHeight: 0,
    data: {},
    viewEditor: false,
    activeEditorComponent: '',
    activeEditorComponentType: '',

    // Zoom
    zoomIn: false,
    zoomOut: false,
    zoomOptions: [0.5, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 1.05, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.55, 1.6, 1.65, 1.7, 1.75, 1.8, 1.85, 1.9, 1.95, 2],
    zoom: 1,

    activeDrawingUrl: null,
    fetchedDrawingDataId: null,

    AdditionalInfo: [],
    loadingSuggestions: false,
    revertingSuggestions: false,
    updatingSuggestions: false,
    suggestions: {},
    showBackModal: false,
    showNextModal: false,

    taggingConfigurationFormData: {},
    loadingTaggingConfigFormData: false,
    revertingChanges: false,
  }

  componentDidMount() {
    this.getDrawingUrl()
  }

  componentDidUpdate(props) {
    if (this.props.activeDrawing !== props.activeDrawing) {
      this.getDrawingUrl()
    }

    // only checks for on going process and fetch data for active drawing
    if (this.props.underProgressDrawingsLength !== props.underProgressDrawingsLength) {
      if (this.state.fetchedDrawingDataId !== this.props.activeDrawing) {
        this.updateDrawingsPackageImage()
      }
    }
  }

  getDrawingUrl = async () => {
    if (!('DrawingStatus' in this.props.user.processDrawingsPacakgeStatusFlags)) return
    await this.setState({ imageHeight: 0, imageWidth: 0 })
    const index = this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.findIndex((drawing) => drawing.id === this.props.activeDrawing)
    if (index > -1) {
      await this.setState({
        activeDrawingUrl: this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus[index].url,
      })
      // In case other drawing was selected we will again need to fetch data
      if (this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus[index].status === 1) {
        this.updateDrawingsPackageImage()
      }
    }
  }

  handleImageLoaded = () => {
    var myImg = document.querySelector('#ref-img')
    var realWidth = myImg.naturalWidth
    var realHeight = myImg.naturalHeight
    this.setState({
      imageWidth: realWidth,
      imageHeight: realHeight,
    })
  }

  updateDrawingsPackageImage = async () => {
    await this.props.updateFetchingData(true)
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('packageId', 'Selected')
    formData.append('tagging', this.props.tagging)
    await this.props.updateDrawingsPackageImage(formData)
    if (!this.props.user.displayMessageCode) {
      await this.setState({
        componentTreeSchema: this.props.user.processDrawingsPackageImageData.TreeStructure,
        toggledSwitchList: this.props.user.processDrawingsPackageImageData.ToggledSwitchList,
        data: this.props.user.processDrawingsPackageImageData.processDrawing,
        schema: this.props.user.processDrawingsPackageImageData.ComponentSchema,
        colorSet: this.props.user.processDrawingsPackageImageData.ColorSet,
        componentAttrTypes: this.props.user.processDrawingsPackageImageData.ComponentAttrType,
        selectTypeAttrs: this.props.user.processDrawingsPackageImageData.SelectTypeAttrs,
        selectLookupDict: this.props.user.processDrawingsPackageImageData.SelectLookupDict,
        compNameMap: this.props.user.processDrawingsPackageImageData.comp_name_map,
        attrNameMap: this.props.user.processDrawingsPackageImageData.attr_name_map,
        standardGroup: this.props.user.processDrawingsPackageImageData.StandardGroup,
        geoGroup: this.props.user.processDrawingsPackageImageData.GeoGroup,
        metaGroup: this.props.user.processDrawingsPackageImageData.MetaGroup,
        groupVisibility: this.props.user.processDrawingsPackageImageData.GroupVisibility,
        requiredGroupFields: this.props.user.processDrawingsPackageImageData.requiredGroupFields,
        editorColumns: this.props.user.processDrawingsPackageImageData.EditorColumns,
        configurableGroup: this.props.user.processDrawingsPackageImageData.ConfigurableGroup,
        fetchedDrawingDataId: this.props.activeDrawing,
      })
    }

    await this.props.updateFetchingData(false)
  }

  updateModelConfigurationData = async (data, deleted_rect = null) => {
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('deletedRect', deleted_rect)
    formData.append('data', JSON.stringify(data))
    formData.append('tagging', this.props.tagging)
    await this.props.updateModelConfigurationData(formData)
  }

  showSuggestions = async (suggestionUuid = null, data, tagType = null, taggingConfiguration = null, taggingConfigurationParentRectUuid = null) => {
    await this.setState({ loadingSuggestions: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('suggestionUuid', suggestionUuid)
    formData.append('taggingConfiguration', JSON.stringify(taggingConfiguration))
    formData.append('tagType', tagType)
    formData.append('data', JSON.stringify(data))
    formData.append('parentUuid', taggingConfigurationParentRectUuid)
    formData.append('tagging', this.props.tagging)

    await this.props.showSuggestions(formData)
    await this.setState({ loadingSuggestions: false })
    if (!this.props.user.displayMessageCode) {
      await this.updateDrawingsPackageImage()
    }
  }

  taggingConfigurationConfigure = async (Uuids, tagType, taggingConfigurationParentRectUuid) => {
    await this.setState({ loadingTaggingConfigFormData: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('uuids', Uuids)
    formData.append('tagType', tagType)
    formData.append('parentUuid', taggingConfigurationParentRectUuid)
    await this.props.taggingConfigurationConfigure(formData)
    await this.setState({ loadingTaggingConfigFormData: false })
    if (!this.props.user.displayMessageCode) {
      this.setState({
        taggingConfigurationFormData: this.props.user.taggingConfigurationFormData,
      })
    }
  }

  handleChangeInTaggingConfigFormData = (e, path, parent = 'Text') => {
    let { taggingConfigurationFormData } = this.state
    path = path.split('.')
    if (path.length === 1) {
      taggingConfigurationFormData.FormList[path[0]].Value = e.target.value
    } else if (path.length === 2) {
      if (parent === 'Text') {
        if (['Attribute', 'Sample Values'].includes(e.target.name) || e.target.name.includes('Text')) {
          if (e.target.name === 'Attribute') {
            let counter = 0
            taggingConfigurationFormData.FormList[path[0]].AttributeData.forEach((attr) => {
              if (attr.Attribute.split(':')[0] === e.target.value) {
                counter += 1
              }
            })

            if (counter === 1) {
              let update_index = taggingConfigurationFormData.FormList[path[0]].AttributeData.findIndex((attr) => attr.Attribute.split(':')[0] === e.target.value)
              if (update_index > -1) {
                taggingConfigurationFormData.FormList[path[0]].AttributeData[update_index].Attribute = `${e.target.value}:${e.target.value}${counter}`
              }
            }

            if (counter >= 1) {
              taggingConfigurationFormData.FormList[path[0]].AttributeData[path[1]].Attribute = `${e.target.value}:${e.target.value}${counter + 1}`
            } else {
              taggingConfigurationFormData.FormList[path[0]].AttributeData[path[1]].Attribute = e.target.value
            }
          } else {
            if (e.target.name.includes('Text')) {
              taggingConfigurationFormData.FormList[path[0]].Value[path[1]].Value = e.target.value
            } else {
              taggingConfigurationFormData.FormList[path[0]].AttributeData[path[1]][e.target.name] = e.target.value
            }
          }
        }
      } else {
        if (['Attribute'].includes(e.target.name)) {
          taggingConfigurationFormData.FormList[path[0]].AttributeData[path[1]][e.target.name] = e.target.value
        } else if (['Template'].includes(e.target.name)) {
          taggingConfigurationFormData.FormList[path[0]].AttributeData[path[1]][e.target.name].push(e.target.value)
        } else {
          taggingConfigurationFormData.FormList[path[0]].Value[path[1]].Value = e.target.value
        }
      }
    }

    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  onSelectDiscipline = (selectedList, name = 'selectedDisciplines') => {
    let { taggingConfigurationFormData } = this.state
    taggingConfigurationFormData[name] = selectedList
    if (name === 'selectedDisciplines' && 'selectedDocumentTypes' in taggingConfigurationFormData) {
      let DocumentTypes = []
      taggingConfigurationFormData.selectedDisciplines.forEach((discipline) => {
        taggingConfigurationFormData.selectedDocumentTypes.forEach((documentType) => {
          discipline.DocumentTypes.forEach((_documentType) => {
            if (_documentType.id === documentType.id) {
              DocumentTypes.push(documentType)
            }
          })
        })
      })

      taggingConfigurationFormData.selectedDocumentTypes = DocumentTypes
    }
    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  deleteTaggingConfigurationFormData = (path, key = 'Value') => {
    let { taggingConfigurationFormData } = this.state
    path = path.split('.')
    if (path.length === 1) {
      taggingConfigurationFormData.FormList.splice(path[0], 1)
    } else if (path.length === 2) {
      taggingConfigurationFormData.FormList[path[0]][key].splice(path[1], 1)
    }
    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  addTaggingConfigBboxUuid = (path, Uuid) => {
    let { taggingConfigurationFormData } = this.state
    path = path.split('.')
    if (path.length === 2) {
      taggingConfigurationFormData.FormList[path[0]].Value[path[1]].Uuid = Uuid
    }
    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  addTaggingConfigFormDataAttribute = (path, key = 'AttributeData') => {
    let { taggingConfigurationFormData } = this.state
    path = path.split('.')

    if (key === 'Value') {
      let max_prefix = 1
      taggingConfigurationFormData.FormList[path[0]][key].forEach((text) => {
        if (parseInt(text.Name.split(' ')[1]) > max_prefix) {
          max_prefix = parseInt(text.Name.split(' ')[1])
        }
      })
      taggingConfigurationFormData.FormList[path[0]][key].push({
        Name: `Text ${max_prefix + 1}`,
        Value: '',
        Uuid: '',
        AttributeData: [],
      })
    } else if (key === `AttributeData`) {
      taggingConfigurationFormData.FormList[path[0]][key].push({
        Attribute: '',
        Template: [],
      })
    } else {
      taggingConfigurationFormData.FormList[path[0]].AttributeData.push({
        Attribute: '',
        'Sample Values': '',
        order: taggingConfigurationFormData.FormList[path[0]].AttributeData.length,
      })
    }

    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  onChangeTaggingConfigOrderHandler = (index, _index, up) => {
    let { taggingConfigurationFormData } = this.state

    let swap_order = taggingConfigurationFormData.FormList[index].AttributeData[_index].order
    if (up) {
      if (_index > 0) {
        taggingConfigurationFormData.FormList[index].AttributeData[_index].order = taggingConfigurationFormData.FormList[index].AttributeData[_index - 1].order
        taggingConfigurationFormData.FormList[index].AttributeData[_index - 1].order = swap_order
      }
    } else {
      if (_index < taggingConfigurationFormData.FormList[index].AttributeData.length - 1) {
        taggingConfigurationFormData.FormList[index].AttributeData[_index].order = taggingConfigurationFormData.FormList[index].AttributeData[_index + 1].order
        taggingConfigurationFormData.FormList[index].AttributeData[_index + 1].order = swap_order
      }
    }

    let sorted_subdata = _.sortBy(taggingConfigurationFormData.FormList[index].AttributeData, 'order')
    taggingConfigurationFormData.FormList[index].AttributeData = sorted_subdata
    this.setState({
      taggingConfigurationFormData: taggingConfigurationFormData,
    })
  }

  updateCollapseList = (item) => {
    let collapseList = this.state.collapseList
    const index = collapseList.indexOf(item)
    if (index > -1) {
      collapseList.splice(index, 1)
    } else {
      collapseList.push(item)
    }
    this.setState({ collapseList: collapseList })
  }

  updateToggledSwitchList = async (switchName, switched, path = '') => {
    let toggledSwitchList = this.state.toggledSwitchList
    path = path.split('.')
    let data = this.state.componentTreeSchema

    if (switched) {
      toggledSwitchList.push(switchName)
    } else {
      const index = toggledSwitchList.findIndex((_switch) => _switch === switchName)
      if (index > -1) {
        toggledSwitchList.splice(index, 1)
      }
    }

    path.forEach((component, index) => {
      if (index === 0) {
        data = data[component]
      } else {
        data = data.Value[component]
      }
    })

    if (data.Value) {
      for (const key in data.Value) {
        let _data = data.Value[key]

        if (_data.Value) {
          for (const _key in _data.Value) {
            let __data = _data.Value[_key]

            if (switched) {
              toggledSwitchList.push(__data.for)
            } else {
              const index = toggledSwitchList.indexOf(__data.for)
              if (index > -1) {
                toggledSwitchList.splice(index, 1)
              }
            }
          }
        }

        if (switched) {
          toggledSwitchList.push(_data.for)
        } else {
          const index = toggledSwitchList.indexOf(_data.for)
          if (index > -1) {
            toggledSwitchList.splice(index, 1)
          }
        }
      }
    }

    await this.setState({ toggledSwitchList: toggledSwitchList })
    await this.setState({
      switchListUpdated: !this.state.switchListUpdated,
    })
  }

  renderComponentsTree = () => {
    let { componentTreeSchema } = this.state
    let schema = componentTreeSchema
    return Object.keys(schema).map((key) => {
      let _schema = schema[key].Value
      return (
        <div key={key} className="mb-1 bg-light border rounded" id="unique-89936613div_1">
          <span className="btn" id="unique-24659850span_1">
            <span className="d-inline-block mr-1" style={{ minWidth: 15 }} id="unique-72720581span_2">
              {_schema ? (
                <i style={{ cursor: 'pointer' }} onClick={() => this.updateCollapseList(key)} className={`fa ${this.state.collapseList.includes(key) ? 'fa-minus' : 'fa-plus'}`} />
              ) : null}
            </span>
            <ToggleSwitch
              isToggled={this.state.toggledSwitchList.includes(schema[key].for)}
              className={schema[key].for.split('.')[0]}
              switchName={schema[key].for}
              path={key}
              updateToggledSwitchList={this.updateToggledSwitchList}
            />
            {schema[key].DisplayName}{' '}
          </span>

          {_schema && this.state.collapseList.includes(key)
            ? Object.keys(_schema).map((_key) => {
                let __schema = _schema[_key].Value
                return (
                  <div key={`${key}.${_key}`} id="unique-52171306div_2">
                    <span className="btn ml-3" id="unique-84559816span_3">
                      <span className="d-inline-block mr-1" style={{ minWidth: 15 }} id="unique-10878260span_4">
                        {__schema ? (
                          <i
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => this.updateCollapseList(`${key}_${_key}`)}
                            className={`fa ${this.state.collapseList.includes(`${key}_${_key}`) ? 'fa-minus' : 'fa-plus'}`}
                          />
                        ) : null}
                      </span>
                      <ToggleSwitch
                        isToggled={this.state.toggledSwitchList.includes(_schema[_key].for)}
                        className={_schema[_key].for.split('.')[0]}
                        switchName={_schema[_key].for}
                        path={`${key}.${_key}`}
                        updateToggledSwitchList={this.updateToggledSwitchList}
                      />
                      {_schema[_key].DisplayName}{' '}
                    </span>

                    {__schema && this.state.collapseList.includes(`${key}_${_key}`)
                      ? Object.keys(__schema).map((__key) => {
                          return (
                            <div key={`${key}.${_key}.${__key}`} id="unique-79620277div_3">
                              <span className="btn" id="unique-80674527span_5">
                                <div className="ml-4" id="unique-59381501div_4">
                                  <div className="ml-4" id="unique-10933113div_5">
                                    <ToggleSwitch
                                      isToggled={this.state.toggledSwitchList.includes(__schema[__key].for)}
                                      className={__schema[__key].for.split('.')[0]}
                                      switchName={__schema[__key].for}
                                      path={`${key}.${_key}.${__key}`}
                                      updateToggledSwitchList={this.updateToggledSwitchList}
                                    />
                                    {__schema[__key].DisplayName}{' '}
                                  </div>
                                </div>
                              </span>
                            </div>
                          )
                        })
                      : null}
                  </div>
                )
              })
            : null}
        </div>
      )
    })
  }

  toggleEditorView = async (view = undefined) => {
    await this.setState({ viewEditor: view === undefined ? !this.state.viewEditor : view })
  }

  renderBackMessage() {
    return (
      <div className="modal" id="unique-73607510div_6">
        <div className="modal-dialog modal-sm" id="unique-90294425div_7">
          <div className="modal-content" id="unique-26343308div_8">
            <div className="modal-header" id="unique-77918220div_9">
              <button type="button" className="close" onClick={() => this.setState({ showBackModal: false })}>
                <span aria-hidden="true" id="unique-73780473span_6">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="unique-72424798div_10">
              <p id="unique-89269679p_1">All modified data will be deleted!</p>
            </div>
            <div className="modal-footer" id="unique-72490687div_11">
              <button disabled={this.state.revertingChanges} className="btn btn-secondary" onClick={this.revertAllChanges} id="unique-12461325button_2">
                OK {this.state.revertingChanges ? <i className="fa fa-spin fa-spinner" id="80440327" /> : null}
              </button>
              <button type="button" disabled={this.state.revertingChanges} className="btn btn-default" onClick={() => this.setState({ showBackModal: false })}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNextMessage() {
    return (
      <div className="modal" id="unique-80533312div_12">
        <div className="modal-dialog modal-sm" id="unique-63986695div_13">
          <div className="modal-content" id="unique-71559606div_14">
            <div className="modal-header" id="unique-30125909div_15">
              <button type="button" className="close" onClick={() => this.setState({ showNextModal: false })}>
                <span aria-hidden="true" id="unique-53494460span_7">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="unique-36013342div_16">
              <p id="unique-95643022p_2">Please annotate atleast 5-10 drawings completely to initiate training. If already done so, click on Next to send the data for review.</p>
            </div>
            <div className="modal-footer" id="unique-30924093div_17">
              <button className="btn btn-primary" onClick={() => this.props.changeScreen(4)}>
                Next
              </button>
              <button type="button" className="btn btn-default" onClick={() => this.setState({ showNextModal: false })}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  revertLastTemplateDetection = async () => {
    await this.setState({ revertingSuggestions: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('tagging', this.props.tagging)
    await this.props.revertLastTemplateDetection(formData)
    if (!this.props.user.displayMessageCode) {
      await this.updateDrawingsPackageImage()
    }
    await this.setState({ revertingSuggestions: false })
  }

  revertAllChanges = async () => {
    await this.setState({ revertingChanges: true })
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('drawingId', this.props.activeDrawing)
    formData.append('tagging', this.props.tagging)
    await this.props.revertAllChanges(formData)
    this.props.changeScreen(1)
    await this.setState({ revertingChanges: false })
  }

  updateZoomFactor = (zoom) => {
    this.setState({ zoom: zoom })
  }

  renderAnnotatedDrawings = () => {
    return (
      <div className="model-configuration" style={{ height: 'calc(55rem - 70px)' }} id="unique-71041822div_18">
        {!this.state.imageHeight || !this.state.imageWidth ? (
          <div className="text-center mt-4" id="unique-10606592div_19">
            Fetching Drawing ...
          </div>
        ) : (
          <IUIAnnotation
            in_progress={this.props.in_progress}
            fetchingData={this.props.fetchingData}
            img_url={this.state.activeDrawingUrl}
            img_height={this.state.imageHeight}
            img_width={this.state.imageWidth}
            drawingData={this.state.data}
            colorCodes={this.state.colorSet}
            schema={this.state.schema}
            ComponentAttributesType={this.state.componentAttrTypes}
            selectTypeAttrs={this.state.selectTypeAttrs}
            selectLookupDict={this.state.selectLookupDict}
            compNameMap={this.state.compNameMap}
            attrNameMap={this.state.attrNameMap}
            toggledSwitchList={this.state.toggledSwitchList}
            switchListUpdated={this.state.switchListUpdated}
            standardGroup={this.state.standardGroup}
            geoGroup={this.state.geoGroup}
            metaGroup={this.state.metaGroup}
            groupVisibility={this.state.groupVisibility}
            requiredGroupFields={this.state.requiredGroupFields}
            // Zoom
            zoomIn={this.state.zoomIn}
            zoomOut={this.state.zoomOut}
            zoomOptions={this.state.zoomOptions}
            zoom={this.state.zoom}
            updateZoomFactor={this.updateZoomFactor}
            // Zoom End

            canvasHeight={'calc(55rem - 70px)'}
            allow_edit={true}
            editorColumns={this.state.editorColumns}
            viewEditor={this.state.viewEditor}
            activeEditorComponent={this.state.activeEditorComponent}
            activeEditorComponentType={this.state.activeEditorComponentType}
            toggleEditorView={this.toggleEditorView}
            suggestions={true}
            updateModelConfigurationData={this.updateModelConfigurationData}
            showSuggestions={this.showSuggestions}
            loadingSuggestions={this.state.loadingSuggestions}
            update_rects_visibility_on_adding_bbox={this.props.tagging}
            configurableGroup={this.state.configurableGroup}
            taggingConfigurationConfigure={this.taggingConfigurationConfigure}
            loadingTaggingConfigFormData={this.state.loadingTaggingConfigFormData}
            taggingConfigurationFormData={this.state.taggingConfigurationFormData}
            handleChangeInTaggingConfigFormData={this.handleChangeInTaggingConfigFormData}
            addTaggingConfigFormDataAttribute={this.addTaggingConfigFormDataAttribute}
            deleteTaggingConfigurationFormData={this.deleteTaggingConfigurationFormData}
            addTaggingConfigBboxUuid={this.addTaggingConfigBboxUuid}
            onChangeTaggingConfigOrderHandler={this.onChangeTaggingConfigOrderHandler}
            onSelectDiscipline={this.onSelectDiscipline}
          />
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="table-container p-0" style={{ height: '55rem' }} id="unique-98133339div_20">
        <div className="row no-gutters" id="unique-39538132div_21">
          {!this.props.drawingsCollapsed ? (
            <div className="col-2" id="unique-85251784div_22">
              <div className="card rounded-0" style={{ height: '55rem' }} id="unique-53132090div_23">
                <div className="card-body p-2" id="unique-10879858div_24">
                  <h5 className="text-center mb-3" id="unique-11053702h5_1">
                    {' '}
                    Annotation Mode{' '}
                  </h5>
                  {this.props.fetchingData ? (
                    <div className="text-center" id="unique-98760085div_25">
                      Fetching Data...
                    </div>
                  ) : (
                    this.renderComponentsTree()
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <div className="col" id="unique-18768687div_26">
            <div className="card rounded-0" id="unique-11682267div_27">
              <div className="card-body p-2 clearfix" id="unique-11006852div_28">
                <button
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      zoomOut: !this.state.zoomOut,
                    })
                  }
                  disabled={this.state.zoom === this.state.zoomOptions[0]}
                >
                  <i className="fa fa-search-minus" id="unique-85412508i_4"></i>
                </button>
                <div className="d-inline-block mx-2" style={{ width: 120 }} id="unique-18459093div_29">
                  <Select
                    options={this.state.zoomOptions.map((option) => {
                      return {
                        label: `${Math.round(option * 100)} %`,
                        value: option,
                      }
                    })}
                    value={{
                      label: `${Math.round(this.state.zoom * 100)} %`,
                      value: this.state.zoom,
                    }}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        this.setState({ zoom: selectedOption.value })
                      }
                    }}
                    isMulti={false}
                    isClearable={false}
                  />
                </div>
                <button
                  className="btn btn-secondary mr-3"
                  onClick={() =>
                    this.setState({
                      zoomIn: !this.state.zoomIn,
                    })
                  }
                  disabled={this.state.zoom === this.state.zoomOptions[this.state.zoomOptions.length - 1]}
                >
                  <i className="fa fa-search-plus" id="unique-91219835i_5"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary float-right btn-sm"
                  disabled={this.props.fetchingData || this.props.in_progress}
                  onClick={() => {
                    if (!this.props.tagging) {
                      this.setState({
                        showNextModal: true,
                      })
                    } else {
                      this.props.changeScreen(4)
                    }
                  }}
                >
                  Next
                </button>

                <button
                  type="button"
                  disabled={this.props.fetchingData || this.props.in_progress}
                  className="btn btn-primary float-right btn-sm mr-2"
                  onClick={() =>
                    this.setState({
                      viewEditor: !this.state.viewEditor,
                    })
                  }
                >
                  Review <i className="far fa-edit" id="unique-11975592i_6"></i>
                </button>

                <button
                  type="button"
                  disabled={this.state.revertingSuggestions || this.props.fetchingData || this.props.in_progress}
                  className="btn btn-danger float-right btn-sm mr-2"
                  onClick={this.revertLastTemplateDetection}
                  id="unique-52372465button_11"
                >
                  Revert Last Suggestion <i className="fa fa-undo" id="unique-48375068i_7"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-default mr-2 btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      showBackModal: true,
                    })
                  }
                  disabled={this.props.fetchingData || this.props.in_progress}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="card rounded-0" id="unique-26247825div_30">
              <div className="card-body p-2" id="unique-33349432div_31">
                {this.renderAnnotatedDrawings()}
              </div>
            </div>
          </div>
        </div>
        {this.state.showBackModal ? this.renderBackMessage() : null}
        {this.state.showNextModal ? this.renderNextMessage() : null}
        <img src={this.state.activeDrawingUrl} className="d-none" id="ref-img" alt="ref img" onLoad={this.handleImageLoaded} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  updateDrawingsPackageImage,
  showSuggestions,
  updateModelConfigurationData,
  revertLastTemplateDetection,
  revertAllChanges,
  taggingConfigurationConfigure,
})(Step3)
