import React, { Component } from 'react'

import DataTable from './DataTable'

class DocumentData extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      activeTab: '',
    }
  }

  componentDidMount() {
    if (this.props.data.length) {
      this.setState({
        activeTab: this.props.data[0].tab_name,
      })
    }
  }

  scroll = (scrollOffset) => {
    this.ref.current.scrollLeft += scrollOffset
  }

  render() {
    return (
      <div className="table-container p-0" style={{ height: '42.5rem', minHeight: '42.5rem' }}>
        <div className="dataTable">
          {!this.props.data.length ? null : (
            <div className="tabBar">
              <button onClick={() => this.scroll(-150)} className="scrollButton scrollButton-left">
                <i className="fa fa-chevron-left" />
              </button>
              <div className="tags" ref={this.ref}>
                {this.props.data.map((data, index) => {
                  return (
                    <div key={index} className={`tag ${this.state.activeTab === data.tab_name ? 'active' : ''}`} onClick={() => this.setState({ activeTab: data.tab_name })}>
                      {data.tab_name}
                    </div>
                  )
                })}
              </div>
              <button onClick={() => this.scroll(150)} className="scrollButton float-right scrollButton-right">
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </button>
            </div>
          )}

          <div className={'p-3'}>
            <div className={'row'}>
              {this.props.data.map((data, index) => {
                if (data.tab_name === this.state.activeTab) {
                  // Sort the attributes by key
                  const sortedAttributes = Object.entries(data.attributes).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))

                  return sortedAttributes.map(([key, val]) => {
                    return (
                      <div key={key} className={'col-6 mb-3'}>
                        <div className={'input-group'}>
                          <span className={'input-group-text'}>{key}</span>
                          <input
                            name={key}
                            className={'form-control'}
                            value={val.value}
                            onChange={(e) => this.props.onChangeAttributeData(e, index)}
                            disabled={!this.props.allowEdit}
                          />
                        </div>
                      </div>
                    )
                  })
                }

                return null
              })}
            </div>

            {this.props.data.map((data, index) => {
              if (data.tab_name === this.state.activeTab) {
                return data.tables.map((table, _index) => {
                  return (
                    <div key={index} className={'mt-3'}>
                      <h4>{table.table_name}</h4>
                      <p>{table.summary}</p>

                      <DataTable
                        allow_edit={this.props.allowEdit}
                        data_index={index}
                        table_index={_index}
                        onChangeTableData={this.props.onChangeTableData}
                        activeTab={this.state.activeTab}
                        column_headers={this.props.column_headers}
                        rows={table.rows}
                      />
                    </div>
                  )
                })
              }
              return null
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentData
