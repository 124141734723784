import React, { useState } from 'react'
import message from './properties.json'

export default function DisplayMessage(props) {
  const [cancelStyle, updateCancelStyle] = useState({})
  // const language = process.env.REACT_APP_MESSAGE_LANGUAGE
  const language = 'English'
  if (props.message) {
    if (props.message.includes('succ_')) {
      return (
        <div className="alert alert-success" style={cancelStyle} id="unique-72312944div_1">
          <i className="fa fa-check-circle" id="68553706" /> &nbsp; {message[language][props.message]}
          <button
            type="button"
            className="close"
            onClick={() => {
              updateCancelStyle({ display: 'none' })
            }}
          >
            <span aria-hidden="true" id="unique-68581385span_1">
              &times;
            </span>
          </button>
        </div>
      )
    } else if (props.message.includes('err_')) {
      return (
        <div className="alert alert-danger" style={cancelStyle} id="unique-44372523div_2">
          <i className="fa fa-exclamation-triangle" id="68553706" /> &nbsp; {message[language][props.message]}
          <button
            type="button"
            className="close"
            onClick={() => {
              updateCancelStyle({ display: 'none' })
            }}
          >
            <span aria-hidden="true" id="unique-49037561span_2">
              &times;
            </span>
          </button>
        </div>
      )
    } else if (props.message.includes('warn_')) {
      return (
        <div className="alert alert-warning" style={cancelStyle} id="unique-11500256div_3">
          <i className="fa fa-exclamation-triangle" id="68553706" /> &nbsp; {message[language][props.message]}
          <button
            type="button"
            className="close"
            onClick={() => {
              updateCancelStyle({ display: 'none' })
            }}
          >
            <span aria-hidden="true" id="unique-22396701span_3">
              &times;
            </span>
          </button>
        </div>
      )
    } else if (props.message.includes('misc_')) {
      return (
        <div className="alert alert-dark" style={cancelStyle} id="unique-64651957div_4">
          <i className="fa fa-exclamation-triangle" id="68553706" /> &nbsp; {message[language][props.message]}
          <button
            type="button"
            className="close"
            onClick={() => {
              updateCancelStyle({ display: 'none' })
            }}
          >
            <span aria-hidden="true" id="unique-67846839span_4">
              &times;
            </span>
          </button>
        </div>
      )
    } else {
      return null
    }
  }
  return null
}
