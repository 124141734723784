import React, { Component } from 'react'

import Select from './../../Forms/Fields/select'
import Input from '../Fields/input'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS13 extends Component {
  state = {
    components: [],
    selected_components: [],
    new_component: '',
    new_symbol_name: '',
    new_symbol_display_name: '',
    new_symbol_index: null,

    loading: false,
    formLoader: false,
    add_new_component_modal: false,
    add_new_symbol_modal: false,
    screen_blocked: false,
    errorMessage: null,
  }

  componentDidMount = async () => {
    await this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectComponentConfigurationInfo(formData)
    await this.setState({ loading: false })
    await this.setState({
      components: this.props.user.projectInfo.components,
      selected_components: this.props.user.projectInfo.selected_components,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('data', JSON.stringify(this.state.selected_components))
    formData.append('step', 13)
    await this.props.updateProjectComponentConfigurationInfo(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  toggle = (path) => {
    let { selected_components } = this.state
    path = path.split('.')
    if (path.length === 1) {
      selected_components[path[0]].toggled = !selected_components[path[0]].toggled
    } else if (path.length === 2) {
      selected_components[path[0]].selected_subcategory[path[1]].toggled = !selected_components[path[0]].selected_subcategory[path[1]].toggled
    }

    this.setState({ selected_components: selected_components })
  }

  updateSelection = (selectedList, key, path) => {
    let { selected_components } = this.state
    path = path.split('.')
    if (path.length === 1) {
      selected_components[path[0]][key] = selectedList
    } else if (path.length === 2) {
      selected_components[path[0]].selected_subcategory[path[1]][key] = selectedList
    }

    this.setState({ selected_components: selected_components })
  }

  renderComponentConfiguration = () => {
    let { components, selected_components } = this.state
    if (!selected_components) {
      return null
    }
    return (
      <>
        <div className="card bg-light mb-3" id="unique-35724164div_1">
          <div className="card-body p-2 clearfix" id="unique-11213109div_2">
            <div className="clearfix mb-2" id="unique-17065053div_3">
              <label id="unique-25811724label_1">Select Components</label>
              <span className="float-right d-inline-block mt-1" id="unique-10134178span_1">
                <label
                  className={`checkbox mr-1 ${selected_components.length === components.length ? 'checked' : ''}`}
                  onClick={() => {
                    if (selected_components.length === components.length) {
                      this.setState({ selected_components: [] })
                    } else {
                      this.setState({ selected_components: JSON.parse(JSON.stringify(components)) })
                    }
                  }}
                />{' '}
                Select All
                <button className="btn btn-primary ml-3 btn-sm d-none" onClick={this.toggleNewComponentModal} id="unique-96421195button_1">
                  Add
                </button>
              </span>
            </div>

            <Select
              options={components}
              value={selected_components}
              onChange={(selectedList) => {
                this.setState({ selected_components: selectedList })
              }}
              isMulti={true}
            />
          </div>
        </div>

        <div className="card rounded-0" id="unique-99618432div_4">
          <div className="card-body p-1 px-2 bg-eee" id="unique-11493550div_5">
            <div className="row align-items-center" id="unique-78935710div_6">
              <div className="col" id="unique-29784355div_7">
                Component Name
              </div>
              <div className="col-1" id="73605564" />
            </div>
          </div>
        </div>

        {selected_components.map((component, index) => {
          return (
            <div className="card rounded-0 border-0" key={index} id="unique-92849264div_9">
              <div className={`card-body p-1 px-2 ${index % 2 === 1 ? 'bg-light' : ''}`} id="unique-60945812div_10">
                <div className="row align-items-center" id="unique-22235379div_11">
                  <div className="col" id="unique-92970189div_12">
                    {component.ComponentName}
                  </div>
                  <div className="col-1" id="unique-75399024div_13">
                    <button className="btn text-secondary float-right" onClick={() => this.toggle(`${index}`)}>
                      {component.toggled ? <i className="fa fa-angle-up" id="73605564" /> : <i className="fa fa-angle-down" id="73605564" />}
                    </button>
                  </div>
                </div>
              </div>

              {component.toggled ? (
                <div className="p-3" id="unique-16839236div_14">
                  <div className="card bg-light mb-3" id="unique-30827743div_15">
                    <div className="card-body p-2 clearfix" id="unique-71902618div_16">
                      <div className="clearfix mb-2" id="unique-68325544div_17">
                        {'symbol_options' in component ? (
                          <>
                            <label id="unique-30538555label_3">Select Symbols</label>
                            <span className="float-right d-inline-block mt-1" id="unique-73189249span_2">
                              <label
                                className={`checkbox mr-1 ${component.symbol_options.length === component.selected_symbol_options.length ? 'checked' : ''}`}
                                onClick={() => {
                                  if (component.symbol_options.length === component.selected_symbol_options.length) {
                                    this.updateSelection([], 'selected_symbol_options', `${index}`)
                                  } else {
                                    this.updateSelection(component.symbol_options, 'selected_symbol_options', `${index}`)
                                  }
                                }}
                              />{' '}
                              Select All
                              <button
                                className="btn btn-primary btn-sm ml-3"
                                onClick={() => {
                                  this.toggleNewSymbolModal()
                                  this.setState({ new_symbol_index: index })
                                }}
                              >
                                Add
                              </button>
                            </span>
                          </>
                        ) : (
                          <>
                            <label id="unique-25530808label_5">Select Subcategory</label>
                            <span className="float-right d-inline-block mt-1" id="unique-67694576span_3">
                              <label
                                className={`checkbox mr-1 ${component.subcategory.length === component.selected_subcategory.length ? 'checked' : ''}`}
                                onClick={() => {
                                  if (component.subcategory.length === component.selected_subcategory.length) {
                                    this.updateSelection([], 'selected_subcategory', `${index}`)
                                  } else {
                                    this.updateSelection(component.subcategory, 'selected_subcategory', `${index}`)
                                  }
                                }}
                              />{' '}
                              Select All
                            </span>
                          </>
                        )}
                      </div>

                      {'symbol_options' in component ? (
                        <Select
                          options={component.symbol_options}
                          value={component.selected_symbol_options}
                          onChange={(selectedList) => {
                            this.updateSelection(selectedList, 'selected_symbol_options', `${index}`)
                          }}
                          isMulti={true}
                        />
                      ) : (
                        <Select
                          options={component.subcategory}
                          value={component.selected_subcategory}
                          onChange={(selectedList) => {
                            this.updateSelection(selectedList, 'selected_subcategory', `${index}`)
                          }}
                          isMulti={true}
                        />
                      )}
                    </div>
                  </div>
                  {'symbol_options' in component ? (
                    <table className="table table-striped">
                      <thead className="bg-eee">
                        <tr id="unique-10587022tr_1">
                          <th id="unique-11538336th_1">Name</th>
                          <th id="unique-80893876th_2">Display Name</th>
                        </tr>
                      </thead>
                      <tbody id="unique-76285433tbody_1">
                        {component.selected_symbol_options.map((symbol, _index) => {
                          return (
                            <tr key={_index} id="unique-66997227tr_2">
                              <td id="unique-70007806td_1">{symbol.Name}</td>
                              <td id="unique-20506892td_2">{symbol.DisplayName}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <div className="card rounded-0" id="unique-10863970div_18">
                        <div className="card-body p-1 px-2 bg-eee" id="unique-11322968div_19">
                          <div className="row align-items-center" id="unique-98810113div_20">
                            <div className="col" id="unique-11464870div_21">
                              Component Name
                            </div>
                            <div className="col-1" id="73605564" />
                          </div>
                        </div>
                      </div>
                      {component.selected_subcategory.map((subcategory, _index) => {
                        return (
                          <div className="card rounded-0 border-0" key={_index} id="unique-95503654div_23">
                            <div className={`card-body p-1 px-2 ${_index % 2 === 1 ? 'bg-light' : ''}`} id="unique-80727401div_24">
                              <div className="row align-items-center" id="unique-33323614div_25">
                                <div className="col" id="unique-55437540div_26">
                                  {subcategory.ComponentName}
                                </div>
                                <div className="col" id="unique-36094799div_27">
                                  {subcategory.DisplayNameName}
                                </div>
                                <div className="col-1" id="unique-37216335div_28">
                                  <button className="btn text-secondary float-right" onClick={() => this.toggle(`${index}.${_index}`)}>
                                    {subcategory.toggled ? <i className="fa fa-angle-up" id="73605564" /> : <i className="fa fa-angle-down" id="73605564" />}
                                  </button>
                                </div>
                              </div>
                            </div>

                            {subcategory.toggled ? (
                              <div className="p-3" id="unique-68889872div_29">
                                <div className="card bg-light mb-3" id="unique-63559430div_30">
                                  <div className="card-body p-2 clearfix" id="unique-19216748div_31">
                                    <div className="clearfix mb-2" id="unique-74426625div_32">
                                      {'symbol_options' in subcategory ? (
                                        <>
                                          <label id="unique-50232359label_7">Select Symbols</label>
                                          <span className="float-right d-inline-block mt-1" id="unique-79842370span_4">
                                            <label
                                              className={`checkbox mr-1 ${subcategory.symbol_options.length === subcategory.selected_symbol_options.length ? 'checked' : ''}`}
                                              onClick={() => {
                                                if (subcategory.symbol_options.length === subcategory.selected_symbol_options.length) {
                                                  this.updateSelection([], 'selected_symbol_options', `${index}.${_index}`)
                                                } else {
                                                  this.updateSelection(subcategory.symbol_options, 'selected_symbol_options', `${index}.${_index}`)
                                                }
                                              }}
                                            />{' '}
                                            Select All
                                          </span>
                                        </>
                                      ) : null}
                                    </div>

                                    {'symbol_options' in subcategory ? (
                                      <Select
                                        options={subcategory.symbol_options}
                                        value={subcategory.selected_symbol_options}
                                        onChange={(selectedList) => {
                                          this.updateSelection(selectedList, 'selected_symbol_options', `${index}.${_index}`)
                                        }}
                                        isMulti={true}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                {'symbol_options' in subcategory ? (
                                  <table className="table table-striped">
                                    <thead className="bg-eee">
                                      <tr id="unique-25170068tr_3">
                                        <th id="unique-69848174th_3">Name</th>
                                        <th id="unique-34534111th_4">Display Name</th>
                                      </tr>
                                    </thead>
                                    <tbody id="unique-72606718tbody_2">
                                      {subcategory.selected_symbol_options.map((symbol, __index) => {
                                        return (
                                          <tr key={__index} id="unique-69971989tr_4">
                                            <td id="unique-82978221td_3">{symbol.Name}</td>
                                            <td id="unique-11450701td_4">{symbol.DisplayName}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
              ) : null}
            </div>
          )
        })}
      </>
    )
  }

  onAddingNewComponent = async (e) => {
    e.preventDefault()
    let { components, new_component } = this.state

    if (components.find((option) => option.ComponentName === new_component)) {
      this.toggleNewComponentModal()
      await this.setState({ errorMessage: 'The component name already exists.' })
      setTimeout(() => this.setState({ errorMessage: null }), 5000)
      return
    }

    let new_component_data = {
      ComponentName: new_component,
      label: new_component,
      value: new_component,
      toggled: false,
      symbol_options: [],
      selected_symbol_options: [],
    }

    components.push(new_component_data)
    await this.setState({ components: components })
    this.toggleNewComponentModal()
  }

  toggleNewComponentModal = () => {
    this.setState({
      add_new_component_modal: !this.state.add_new_component_modal,
      new_component: '',
    })
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderAddNewComponentModal = () => {
    return (
      <form className="modal" onSubmit={this.onAddingNewComponent} id="unique-77676320form_1">
        <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-27355583div_33">
          <div className="modal-content" id="unique-10451754div_34">
            <div className="modal-header" id="unique-48143493div_35">
              <h5 className="mb-0" id="unique-52402036h5_1">
                Add New Component
              </h5>
              <button type="button" className="close" onClick={this.toggleNewComponentModal} id="unique-86434133button_5">
                <span aria-hidden="true" id="unique-62527787span_5">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="321321313113">
              <Input type="text" label="Component Name" name="new_component" value={this.state.new_component} onChange={this.onChange} required={true} />
            </div>

            <div className="modal-footer" id="unique-45101742div_37">
              <button type="button" className="btn btn-default" onClick={this.toggleNewComponentModal} id="unique-47413747button_6">
                Cancel
              </button>

              <button type="submit" className="btn btn-primary" id="unique-66430863button_7">
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  toggleNewSymbolModal = () => {
    this.setState({
      add_new_symbol_modal: !this.state.add_new_symbol_modal,
      new_symbol_name: '',
      new_symbol_display_name: '',
      new_symbol_index: null,
    })
  }

  onAddingNewSymbol = async (e) => {
    e.preventDefault()
    let { selected_components, new_symbol_name, new_symbol_display_name, new_symbol_index } = this.state

    if (selected_components[new_symbol_index].symbol_options.find((option) => option.Name.toLowerCase() === new_symbol_name.toLowerCase())) {
      this.toggleNewSymbolModal()
      await this.setState({ errorMessage: 'The symbol name already exists.' })
      setTimeout(() => this.setState({ errorMessage: null }), 5000)
      return
    }

    let new_symbol_data = {
      Name: new_symbol_name,
      DisplayName: new_symbol_display_name,
      label: new_symbol_display_name,
      value: new_symbol_name,
    }

    selected_components[new_symbol_index].symbol_options.push(new_symbol_data)
    await this.setState({ selected_components: selected_components })
    this.toggleNewSymbolModal()
  }

  renderAddNewSymbolModal = () => {
    return (
      <form className="modal" onSubmit={this.onAddingNewSymbol} id="unique-82192349form_2">
        <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-28572242div_38">
          <div className="modal-content" id="unique-58938415div_39">
            <div className="modal-header" id="unique-72271670div_40">
              <h5 className="mb-0" id="unique-51461636h5_2">
                Add New Symbol
              </h5>
              <button type="button" className="close" onClick={this.toggleNewSymbolModal} id="unique-49007479button_8">
                <span aria-hidden="true" id="unique-10997065span_6">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="div_022911">
              <div className="form-group" id="div_0ww2291">
                <Input type="text" label="Name" name="new_symbol_name" value={this.state.new_symbol_name} onChange={this.onChange} required={true} />
              </div>
              <div className="form-group">
                <Input type="text" label="Display Name" name="new_symbol_display_name" value={this.state.new_symbol_display_name} onChange={this.onChange} required={true} />
              </div>
            </div>

            <div className="modal-footer" id="unique-66038082div_44">
              <button type="button" className="btn btn-default" onClick={this.toggleNewSymbolModal} id="unique-56185035button_9">
                Cancel
              </button>

              <button type="submit" className="btn btn-primary" id="unique-91683949button_10">
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderForm() {
    return (
      <div className="card" id="unique-31798729div_45">
        {this.state.add_new_component_modal ? this.renderAddNewComponentModal() : null}
        {this.state.add_new_symbol_modal ? this.renderAddNewSymbolModal() : null}
        <div className="clearfix card-header bg-light" id="unique-68228713div_46">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-76699087button_14">
            Cancel
          </button>

          <h3 id="unique-91903696h3_1">Component Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="73605564" /> : null}</h3>
        </div>
        <div id="div_02291div" className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`}>
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          {this.state.errorMessage ? (
            <div className="alert alert-danger alert-dismiss" id="unique-74071531div_48">
              {this.state.errorMessage}{' '}
              <button className="close" onClick={() => this.setState({ errorMessage: null })}>
                &times;
              </button>
            </div>
          ) : null}

          {this.renderComponentConfiguration()}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-11400686div_49">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
