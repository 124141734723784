import React from 'react'
import { connect } from 'react-redux'

import Select from './../Forms/Fields/select'
import { fetchAllProducts, fetchEPCCompanies, createClient } from '../../store/actions'
import './Forms.css'
import Input from './Fields/input'

class CreateClient extends React.Component {
  state = {
    client_name: '',
    owner_name: '',
    epc_name: '',
    epc_id: '',
    products: [],
    loading: false,
    allProducts: [],
    epc_companies: [],
    epc_selection_mode: true,
    formLoader: false,
    errorMessage: null,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    await this.props.fetchAllProducts()
    await this.props.fetchEPCCompanies()
    await this.setState({
      epc_companies: this.props.user.epc_companies,
      allProducts: this.props.user.allProducts,
      loading: false,
    })
  }

  createClientDataHandler = async (e) => {
    e.preventDefault()
    let { products, client_name, owner_name, epc_name, epc_id } = this.state
    await this.setState({ formLoader: true })

    products = products.map((product) => {
      return product.value
    })

    let formData = new FormData()
    formData.append('client_name', client_name)
    formData.append('owner_name', owner_name)
    formData.append('epc_name', epc_name)
    formData.append('epc_id', epc_id)
    formData.append('products', products)

    await this.props.createClient(formData)

    await this.setState({
      formLoader: false,
      errorMessage: this.props.user.createClientErrorMessage,
    })

    if (!this.state.errorMessage) {
      this.props.history.push('/admin/clients')
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderCreateClientForm() {
    return (
      <form onSubmit={(event) => this.createClientDataHandler(event)} className="card">
        <div className="clearfix card-header bg-light" id="unique-93882467div_1">
          <button type="submit" disabled={this.state.formLoader} className="btn btn-primary float-right" id="unique-23387846button_1">
            {this.state.formLoader ? 'Creating...' : 'Create'}
          </button>
          <h3 id="unique-88783219h3_1">Create Client</h3>
        </div>
        <div className="card-body" id="unique-17105242div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger alert-dismissible fade show mb-3" role="alert" id="unique-95638753div_3">
              <strong id="unique-47904237strong_1">Opps!</strong> Something went wrong
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ errorMessage: '' })}>
                <span aria-hidden="true" id="unique-89893727span_1">
                  &times;
                </span>
              </button>
            </div>
          ) : null}
          <div className="row" id="unique-40272128div_4">
            <div className="col-md-5" id="unique-81804286div_5">
              <div className="form-group" id="unique-26879740div_6">
                <Input label="Client Name" type="text" value={this.state.client_name} name="client_name" onChange={this.onChange} required={true} id="unique-27138112input_1" />
              </div>

              <div className="form-group" id="unique-67967356div_7">
                <Input
                  label="Owner Name"
                  info={`If no owner, please put "Internal"`}
                  className="form-control"
                  name="owner_name"
                  value={this.state.owner_name}
                  onChange={this.onChange}
                  required={true}
                  id="unique-82385145input_2"
                />
              </div>

              <div className="form-group" id="unique-41823302div_8">
                <div className="clearfix mb-2" id="unique-85008399div_9">
                  <label id="unique-54874680label_1">EPC Name </label>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        epc_selection_mode: !this.state.epc_selection_mode,
                        epc_name: '',
                      })
                    }
                    className="btn btn-sm btn-primary float-right"
                  >
                    {this.state.epc_selection_mode ? 'Add' : 'Select'}
                  </button>
                </div>

                {this.state.epc_selection_mode ? (
                  <select
                    onChange={(event) =>
                      this.setState({
                        epc_id: event.target.value,
                      })
                    }
                    value={this.state.epc_id}
                    className="form-control"
                  >
                    <option value="">--Select--</option>
                    {this.state.epc_companies.map((company) => {
                      return (
                        <option key={company.id} value={company.id}>
                          {company.Name}
                        </option>
                      )
                    })}
                  </select>
                ) : (
                  <Input name="epc_name" value={this.state.epc_name} onChange={this.onChange} required={false} id="unique-25824098input_3" />
                )}
              </div>
            </div>

            <div className="col-md-7" id="unique-80157833div_10">
              <div className="form-group" id="unique-79295456div_11">
                <label id="unique-84879965label_2">
                  {' '}
                  Products{' '}
                  <span className="small text-muted" id="unique-10646515span_2">
                    (Mandatory *)
                  </span>{' '}
                </label>
                {this.renderProductForm()}
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderProductForm = () => {
    let { allProducts, products } = this.state

    return (
      <Select
        options={allProducts}
        value={products}
        onChange={(selectedProducts) => {
          this.setState({ products: selectedProducts })
        }}
        isMulti={true}
        isDisabled={!allProducts.length}
      />
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-11303630div_12">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-84467607div_13">
        {this.renderCreateClientForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchAllProducts,
  fetchEPCCompanies,
  createClient,
})(CreateClient)
