import React, { Component } from 'react'

export default class Delete extends Component {
  render() {
    return (
      <form
        className="modal"
        id="unique-22813606form_1"
        onSubmit={(event) => {
          event.preventDefault()
          this.props.deleteProject(this.props.project.id)
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" id="unique-67291074div_1">
          <div className="modal-content" id="unique-72092958div_2">
            <div className="modal-header" id="unique-54528552div_3">
              <h5 className="mb-0" id="unique-11371933h5_1">
                <b>Delete {this.props.project.name}</b>
              </h5>
              <button id="unique-90578821button_1" type="button" className="close" onClick={() => this.props.toggle(null)}>
                <span aria-hidden="true" id="unique-36994572span_1">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body" id="unique-80457538div_4">
              <b>{this.props.project.name}</b>:{' '}
              <span className="text-danger" id="unique-30507885span_2">
                all data, including templates, databases, and linkages, will be deleted.
              </span>
            </div>

            <div className="modal-footer" id="unique-76468663div_5">
              <button id="unique-99257297button_2" type="button" className="btn btn-default" onClick={() => this.props.toggle(null)} disabled={this.props.deletingProject}>
                Cancel
              </button>
              <button type="submit" className="btn btn-secondary" disabled={this.props.deletingProject} id="unique-11104613button_3">
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
