import React from 'react'
import { Link } from 'react-router-dom'

import EditProfile from '../Forms/EditProfile'
import CreateUser from '../Forms/CreateUser'
import ArchiveUser from '../Forms/ArchiveUser'
import CreateClient from '../Forms/CreateClient'
import EditClient from '../Forms/EditClient'
import CreateProduct from '../Forms/createProduct'
import EditProduct from '../Forms/EditProduct'
import CreateConfig from '../Forms/CreateConfig'
import EditProject from '../Forms/EditProject'
import CreateProject from '../Forms/CreateProject'

import Moment from 'react-moment'

import { connect } from 'react-redux'
import {
  fetchUsers,
  deleteUser,
  fetchAllProducts,
  fetchAllProjects,
  deleteProject,
  fetchClients,
  deleteClient,
  deleteProduct,
  unlockUser,
  forceResetPassword,
} from './../../store/actions'

import './Dashboard.css'
import NotAllowed from '../WebComponents/NotAllowed'

class AdminDashboard extends React.Component {
  state = {
    role: null,
    page: '',
    deleteUserId: null,
    users: [],
    deleteClientId: null,
    clients: [],
    deleteProductId: null,
    allProducts: [],
    deleteProjectObject: null,
    allProjects: [],
    loading: false,
    deletingEntry: false,

    selectedUsers: [],
  }

  componentDidMount() {
    if (this.props.page === 'users') {
      this.props.history.push('/admin/users')
    }

    this.setState(
      {
        role: this.props.user.role,
        page: this.props.page,
      },
      () => {
        if (this.props.page === 'users') {
          this.fetchUsers()
        } else if (this.props.page === 'products') {
          this.fetchAllProducts()
        } else if (this.props.page === 'projects') {
          this.fetchAllProjects()
        } else if (this.state.page === 'clients') {
          this.fetchClients()
        }
      }
    )
  }

  componentDidUpdate(props) {
    if (props.page !== this.props.page) {
      if (this.props.page === 'users') {
        this.fetchUsers()
      } else if (this.props.page === 'products') {
        this.fetchAllProducts()
      } else if (this.props.page === 'projects') {
        this.fetchAllProjects()
      } else if (this.props.page === 'clients') {
        this.fetchClients()
      }
      this.setState({ page: this.props.page }, this.renderPage)
    }
  }

  fetchUsers = () => {
    this.setState({ loading: true }, () => {
      this.props.fetchUsers().then(() => {
        this.setState({ loading: false, users: this.props.user.users })
      })
    })
  }

  fetchClients = () => {
    this.setState({ loading: true }, () => {
      this.props.fetchClients().then(() => {
        this.setState({
          loading: false,
          clients: this.props.user.clients,
        })
      })
    })
  }

  fetchAllProducts = () => {
    this.setState({ loading: true }, () => {
      this.props.fetchAllProducts().then(() => {
        this.setState({
          loading: false,
          allProducts: this.props.user.allProducts,
        })
      })
    })
  }

  fetchAllProjects = () => {
    this.setState({ loading: true }, () => {
      this.props.fetchAllProjects().then(() => {
        this.setState({
          loading: false,
          allProjects: this.props.user.allProjects,
        })
      })
    })
  }

  deleteUser = async (userId) => {
    await this.setState({ deletingEntry: true })
    let formData = new FormData()
    formData.append('userId', userId)
    await this.props.deleteUser(formData)
    await this.setState({ deletingEntry: false, deleteUserId: null })
    this.fetchUsers()
  }

  deleteProduct = async (productId) => {
    await this.setState({ deletingEntry: true })
    let formData = new FormData()
    formData.append('productId', productId)
    await this.props.deleteProduct(formData)
    await this.setState({ deletingEntry: false, deleteProductId: null })
    this.fetchAllProducts()
  }

  deleteClient = async (clientId) => {
    await this.setState({ deletingEntry: true })
    let formData = new FormData()
    formData.append('clientId', clientId)
    await this.props.deleteClient(formData)
    await this.setState({ deletingEntry: false, deleteClientId: null })
    this.fetchClients()
  }

  deleteProject = async (projectId) => {
    await this.setState({ deletingEntry: true })
    let formData = new FormData()
    formData.append('projectId', projectId)
    await this.props.deleteProject(formData)
    await this.setState({ deletingEntry: false, deleteProjectObject: null })
    this.fetchAllProjects()
  }

  renderClients() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="9682">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="table-container p-0" id="1079">
        <table className="table table-striped">
          <thead className="thead-light">
            <tr id="8556">
              <th id="6079">Id</th>
              <th id="9719">Client</th>
              <th id="9770">Owner</th>
              <th id="6730">Epc</th>
              <th id="3969"></th>
            </tr>
          </thead>
          <tbody id="8146">{this.renderClientsData()}</tbody>
        </table>
      </div>
    )
  }

  renderClientsData() {
    let viewClient_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-client_hidden`)) {
      viewClient_hidden = true
    }

    let deleteClient_hidden = false
    if (this.props.user.user_actions.includes(`admin_delete-client_hidden`)) {
      deleteClient_hidden = true
    }

    return this.state.clients.map((client) => {
      return (
        <tr id={`1396_${client.id}`} key={client.id}>
          <td>{client.id}</td>
          <td>{client.Name}</td>
          <td>{client.Owner_id__Company_name}</td>
          <td>{client.Epc_id__Company_name}</td>
          <td>
            {!viewClient_hidden ? (
              <Link className={`btn btn-sm text-secondary`} to={`/admin/edit-client?id=${client.id}`}>
                View
              </Link>
            ) : null}

            {!deleteClient_hidden ? (
              <button className={`btn btn-sm text-danger`} onClick={() => this.setState({ deleteClientId: client.id })}>
                Delete
              </button>
            ) : null}
          </td>
        </tr>
      )
    })
  }

  renderUsers() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="8568">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="table-container p-0" id="3230">
        <table className="table table-striped">
          <thead className="thead-light">
            <tr id="3913">
              <th id="7372">Id</th>
              <th id="1338">Name</th>
              <th id="9701">Email</th>
              <th id="9608">Organization</th>
              <th id="3647">Default Role</th>
              <th id="6665">Creation Date</th>
              <th id="8576"></th>
            </tr>
          </thead>
          <tbody id="1857">{this.renderUsersData()}</tbody>
        </table>
      </div>
    )
  }

  unlockUser = (user) => {
    let formData = new FormData()
    formData.append('userId', user.id)
    this.props.unlockUser(formData).then(() => {
      this.fetchUsers()
    })
  }

  forceResetPassword = async () => {
    await this.setState({ forcingResetPassword: true })
    const formData = new FormData()
    formData.append('userIds', this.state.selectedUsers)
    await this.props.forceResetPassword(formData)
    await this.setState({ forcingResetPassword: false })
    this.fetchUsers()
  }

  renderUsersData() {
    let viewUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-user_hidden`)) {
      viewUser_hidden = true
    }

    let deleteUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_delete-user_hidden`)) {
      deleteUser_hidden = true
    }

    let archiveUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_archive-user_hidden`)) {
      archiveUser_hidden = true
    }

    return this.state.users.map((user) => {
      return (
        <tr id={`6517_${user.id}`} key={user.id}>
          <td>{user.id}</td>
          <td>
            {user.Name} &nbsp;
            {user.is_locked ? <i style={{ cursor: 'pointer' }} className="fa fa-lock" title="Unlock User" onClick={() => this.unlockUser(user)} /> : null} &nbsp;
            {user.is_forcereset ? (
              <span style={{ padding: 4 }} className="badge badge-danger">
                Forced Reset Password
              </span>
            ) : (
              ''
            )}
          </td>
          <td>{user.email}</td>
          <td>{user.Company_id__Company_name}</td>
          <td>{user.Default_role__Name}</td>
          <td>
            <Moment date={user.Creation_date} format="DD-MMM-YYYY hh:mm" />
          </td>
          <td>
            {!viewUser_hidden ? (
              <Link to={`/admin/edit-user?id=${user.id}`} className={`btn btn-sm text-secondary`}>
                Profile
              </Link>
            ) : null}

            {!archiveUser_hidden ? (
              <Link to={`/admin/archive-user?id=${user.id}`} className={`btn btn-sm text-secondary`}>
                Archive
              </Link>
            ) : null}

            {!deleteUser_hidden ? (
              <button
                className={`btn btn-sm text-danger`}
                onClick={() => {
                  this.setState({ deleteUserId: user.id })
                }}
              >
                Delete
              </button>
            ) : null}
          </td>
        </tr>
      )
    })
  }

  renderProducts() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="2197">
          <div id="loading"></div>
        </div>
      )
    }

    return (
      <div className="table-container p-0" id="3827">
        <table className="table table-striped">
          <thead className="thead-light">
            <tr id="6184">
              <th id="2163">Id</th>
              <th id="7793">Name</th>
              <th id="1143">Description</th>
              <th id="1489"></th>
            </tr>
          </thead>
          <tbody id="1902">{this.renderProductsData()}</tbody>
        </table>
      </div>
    )
  }

  renderProductsData() {
    let viewProduct_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-product_hidden`)) {
      viewProduct_hidden = true
    }

    let deleteProduct_hidden = false
    if (this.props.user.user_actions.includes(`admin_delete-product_hidden`)) {
      deleteProduct_hidden = true
    }

    return this.state.allProducts.map((product) => {
      return (
        <tr id="6820_{product.id}" key={product.id}>
          <td>{product.id}</td>
          <td>{product.name}</td>
          <td>{product.description}</td>
          <td>
            {!viewProduct_hidden ? (
              <Link to={`/admin/edit-product?id=${product.id}`} className={`btn btn-sm text-secondary`}>
                View
              </Link>
            ) : null}

            {!deleteProduct_hidden ? (
              <button onClick={() => this.setState({ deleteProductId: product.id })} className={`btn btn-sm text-danger`}>
                Delete
              </button>
            ) : null}
          </td>
        </tr>
      )
    })
  }

  renderProjects() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="5504">
          <div id="loading" />
        </div>
      )
    }
    return (
      <div className="table-container p-0" id="1144">
        <table className="table table-striped">
          <thead className="thead-light">
            <tr id="6358">
              <th id="8136">Id</th>
              <th id="5791" width="300">
                Project Name
              </th>
              <th id="5736" width="300">
                Assigned Users
              </th>
              <th id="8569">Product</th>
              <th id="1043">Start Date</th>
              <th id="1071">Last Activity</th>
              <th id="5232">Tag Extraction</th>
              <th id="9307" width="250"></th>
            </tr>
          </thead>
          <tbody id="5352">{this.renderProjectsData()}</tbody>
        </table>
      </div>
    )
  }

  renderProjectsData() {
    let viewProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-project_hidden`)) {
      viewProject_hidden = true
    }

    let configureProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_configure-project_hidden`)) {
      configureProject_hidden = true
    }

    let deleteProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_delete-project_hidden`)) {
      deleteProject_hidden = true
    }

    return this.state.allProjects.map((project) => {
      return (
        <tr id={`2696_${project.id}`} key={project.id}>
          <td>{project.id}</td>
          <td>{project.projectName}</td>
          <td>{project.user}</td>
          <td>{project.product}</td>
          <td>
            <Moment date={project.startDate} format="DD-MMM-YYYY hh:mm" />
          </td>
          <td>
            <Moment date={project.lastActivity} format="DD-MMM-YYYY hh:mm" />
          </td>
          <td>{project.tagExtraction ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>}</td>
          <td>
            {!viewProject_hidden ? (
              <Link to={`/admin/edit-project?id=${project.id}`} className={`btn btn-sm text-secondary`}>
                Edit
              </Link>
            ) : null}

            {!configureProject_hidden ? (
              <Link to={`/admin/create-config?id=${project.id}&product=${project.product}`} className={`btn btn-sm text-secondary`}>
                Configure
              </Link>
            ) : null}

            {!deleteProject_hidden ? (
              <button className={`btn btn-sm text-danger`} onClick={() => this.setState({ deleteProjectObject: project })}>
                Delete
              </button>
            ) : null}
          </td>
        </tr>
      )
    })
  }

  renderDeleteConfirmationModal = () => {
    return (
      <div className="modal" id="div_021">
        <div className="modal-dialog" id="div_102291">
          <div className="modal-content modal-sm" id="2div_02291">
            <div className="modal-header" id="001div_02291">
              <h5 className="modal-title">Delete Entry</h5>
              <button type="button" className="close">
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.deleteProjectObject ? (
                <p>
                  <b>{this.state.deleteProjectObject.projectName}</b> : All data, including templates, databases, and linkages, will be deleted. Proceed?
                </p>
              ) : (
                <p>Are you sure you want to proceed with deleting this entry?</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={this.state.deletingEntry}
                onClick={() => {
                  if (this.state.deleteUserId) {
                    this.deleteUser(this.state.deleteUserId)
                  }
                  if (this.state.deleteProductId) {
                    this.deleteProduct(this.state.deleteProductId)
                  }
                  if (this.state.deleteProjectObject) {
                    this.deleteProject(this.state.deleteProjectObject.id)
                  }
                  if (this.state.deleteClientId) {
                    this.deleteClient(this.state.deleteClientId)
                  }
                }}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.setState({ deleteUserId: null, deleteClientId: null, deleteProductId: null, deleteProjectObject: null })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPage() {
    if (this.props.user.user_actions.includes(`admin_view-${this.props.page}_hidden`)) {
      return <NotAllowed />
    }

    let createUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_create-user_hidden`)) {
      createUser_hidden = true
    }

    let resetPassword_hidden = false
    if (this.props.user.user_actions.includes(`admin_reset-password_hidden`)) {
      resetPassword_hidden = true
    }

    let editUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_edit-user_hidden`)) {
      editUser_hidden = true
    }

    let viewUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-user_hidden`)) {
      viewUser_hidden = true
    }

    let createProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_create-project_hidden`)) {
      createProject_hidden = true
    }

    let editProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_edit-project_hidden`)) {
      editProject_hidden = true
    }

    let viewProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-project_hidden`)) {
      viewProject_hidden = true
    }

    let configureProject_hidden = false
    if (this.props.user.user_actions.includes(`admin_configure-project_hidden`)) {
      configureProject_hidden = true
    }

    let createClient_hidden = false
    if (this.props.user.user_actions.includes(`admin_create-client_hidden`)) {
      createClient_hidden = true
    }

    let editClient_hidden = false
    if (this.props.user.user_actions.includes(`admin_edit-client_hidden`)) {
      editClient_hidden = true
    }

    let viewClient_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-client_hidden`)) {
      viewClient_hidden = true
    }

    let createProduct_hidden = false
    if (this.props.user.user_actions.includes(`admin_create-product_hidden`)) {
      createProduct_hidden = true
    }

    let editProduct_hidden = false
    if (this.props.user.user_actions.includes(`admin_edit-product_hidden`)) {
      editProduct_hidden = true
    }

    let viewProduct_hidden = false
    if (this.props.user.user_actions.includes(`admin_view-product_hidden`)) {
      viewProduct_hidden = true
    }

    let archiveUser_hidden = false
    if (this.props.user.user_actions.includes(`admin_archive-user_hidden`)) {
      archiveUser_hidden = true
    }

    if (this.state.page === 'users') {
      return (
        <div className="dashboard admin" id="8982">
          <div className="card" id="7738">
            <div className="clearfix card-header bg-light" id="2473">
              {!createUser_hidden ? (
                <Link to="/admin/create-user" className={`btn btn-primary float-right`}>
                  Create User
                </Link>
              ) : null}

              {resetPassword_hidden ? (
                <button disabled={!this.state.selectedUsers.length} className={`btn btn-secondary float-right mr-2`} onClick={this.forceResetPassword}>
                  Reset Password {this.state.forcingResetPassword ? <i className="fa fa-spin fa-spinner"></i> : null}
                </button>
              ) : null}
              <h3 className="mb-0" id="3091">
                Users
              </h3>
            </div>
            <div className="card-body p-0" id="2209">
              {this.renderUsers()}
            </div>
          </div>
        </div>
      )
    } else if (this.state.page === 'clients') {
      return (
        <div className="dashboard admin" id="9003">
          <div className="card" id="1112">
            <div className="clearfix card-header bg-light" id="9161">
              {!createClient_hidden ? (
                <Link to={'/admin/create-client'} className={`btn btn-primary float-right`}>
                  Create Client
                </Link>
              ) : null}
              <h3 className="mb-0" id="1047">
                Clients
              </h3>
            </div>
            <div className="card-body p-0" id="1012">
              {this.renderClients()}
            </div>
          </div>
        </div>
      )
    } else if (this.state.page === 'products') {
      return (
        <div className="dashboard admin" id="1098">
          <div className="card" id="8981">
            <div className="clearfix card-header bg-light" id="9674">
              {!createProduct_hidden ? (
                <Link to={'/admin/create-product'} className={`btn btn-primary float-right`}>
                  Create Product
                </Link>
              ) : null}
              <h3 className="mb-0" id="1109">
                Products
              </h3>
            </div>
            <div className="card-body p-0" id="3614">
              {this.renderProducts()}
            </div>
          </div>
        </div>
      )
    } else if (this.state.page === 'projects') {
      return (
        <div className="dashboard admin" id="div_02291">
          <div className="card" id="div_0229133">
            <div className="clearfix card-header bg-light" id="div_02291121">
              {!createProject_hidden ? (
                <Link id="create_project_link" to="/admin/create-project" className={`btn btn-primary float-right`}>
                  Create Project
                </Link>
              ) : null}
              <h3 className="mb-0">Projects</h3>
            </div>
            <div className="card-body p-0">{this.renderProjects()}</div>
          </div>
        </div>
      )
    } else if (this.state.page === 'create-user') {
      if (!createUser_hidden) {
        return <CreateUser {...this.props} />
      }
      return <NotAllowed />
    } else if (this.state.page === 'create-client') {
      if (!createClient_hidden) {
        return <CreateClient {...this.props} />
      }
      return <NotAllowed />
    } else if (this.state.page === 'create-product') {
      if (!createProduct_hidden) {
        return <CreateProduct {...this.props} />
      }
      return <NotAllowed />
    } else if (this.state.page === 'create-project') {
      if (!createProject_hidden) {
        return <CreateProject {...this.props} />
      }
      return <NotAllowed />
    } else if (this.state.page === 'edit-user') {
      if (viewUser_hidden || editUser_hidden) {
        return <NotAllowed />
      }
      return <EditProfile {...this.props} />
    } else if (this.state.page === 'archive-user') {
      if (archiveUser_hidden) {
        return <NotAllowed />
      }
      return <ArchiveUser {...this.props} />
    } else if (this.state.page === 'edit-product') {
      if (editProduct_hidden || viewProduct_hidden) {
        return <NotAllowed />
      }
      return <EditProduct {...this.props} />
    } else if (this.state.page === 'edit-project') {
      if (editProject_hidden || viewProject_hidden) {
        return <NotAllowed />
      }
      return <EditProject {...this.props} />
    } else if (this.state.page === 'create-config') {
      if (configureProject_hidden) {
        return <NotAllowed />
      }
      return <CreateConfig {...this.props} />
    } else if (this.state.page === 'edit-client') {
      if (editClient_hidden || viewClient_hidden) {
        return <NotAllowed />
      }
      return <EditClient {...this.props} />
    }

    return null
  }

  render() {
    return (
      <div id="div00011111">
        {this.state.deleteUserId || this.state.deleteClientId || this.state.deleteProductId || this.state.deleteProjectObject ? this.renderDeleteConfirmationModal() : null}
        {this.renderPage()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchUsers,
  deleteUser,
  fetchAllProducts,
  fetchAllProjects,
  deleteProject,
  fetchClients,
  deleteClient,
  deleteProduct,
  unlockUser,
  forceResetPassword,
})(AdminDashboard)
