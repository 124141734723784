import React, { Component, lazy } from 'react'
import UploadLOVExcel from '../../Forms/UploadLOVExcel'
import LinkedProjectInfoBox from './LinkProjectInfoBox'
import Input from '../Fields/input'
import Select from '../Fields/select'

var _ = require('lodash')
export default class ProjectSetupS6 extends Component {
  state = {
    loading: false,
    formLoader: false,
    select_source_mode: true,

    selected_source_id: '',
    selected_source_name: '',
    selected_attribute_id: '',
    data: {},
    field_options: [],
    source_options: [],
    additional_source_options: [],
    selected_source: '',
    selected_attribute: '',
    attributeNameId: '',
    newSource: '',

    add_titlebox_attribute_mode: false,
    titlebox_attribute_value: '',
    screen_blocked: false,
  }

  componentDidMount = () => {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectDocumentInfo(formData)

    await this.setState({
      loading: false,
      field_options: this.props.user.projectInfo.field_options,
      source_options: this.props.user.projectInfo.source_options,
      additional_source_options: this.props.user.projectInfo.additional_source_options,
      data: this.props.user.projectInfo.document_data,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })

    if (this.state.source_options.length > 0 && !this.state.selected_source_id && !this.state.selected_source_name) {
      await this.setState({
        selected_source_id: this.state.source_options[0].id,
        selected_source_name: this.state.source_options[0].Name,
      })
    }
  }

  formDataHandler = async (exit, change_page = true) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('document_data', JSON.stringify(this.state.data))
    formData.append('source_options', JSON.stringify(this.state.source_options))
    formData.append('step', 7)
    await this.props.updateProjectDocumentInfo(formData)

    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && change_page && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if ((exit || this.props.user.projectInfo.isLast) && change_page) {
      this.props.closeProjectSetup()
    }
  }

  checkIfDisabled = (field_name, index, _index) => {
    let { data, selected_source_id, source_options } = this.state
    let disabled = true
    let attribute_name_id = data[selected_source_id][index].subData[_index].attribute_name_id
    let attribute_type = data[selected_source_id][index].subData[_index].attribute_type

    source_options.forEach((option) => {
      if (option.id === parseInt(selected_source_id)) {
        option.attribute_names.forEach((_option) => {
          if (attribute_name_id && _option.id === parseInt(attribute_name_id)) {
            _option.attribute_type.forEach((__option) => {
              if (__option.Name === attribute_type) {
                __option.fields.forEach((field) => {
                  if (field === field_name) {
                    disabled = false
                  }
                })
              }
            })
          }
        })
      }
    })
    return disabled
  }

  addData = () => {
    let { data, selected_source_id } = this.state
    if (!(selected_source_id in data)) {
      data[selected_source_id] = []
    }

    let max_number = _.maxBy(data[selected_source_id], function (o) {
      return o.number
    })
    data[selected_source_id].push({
      number: max_number ? max_number.number + 1 : 1,
      collapsed: true,
      subData: [],
    })

    this.setState({ data: data })
  }

  cloneData = (data_copy) => {
    let { data, selected_source_id } = this.state
    let max_number = _.maxBy(data[selected_source_id], function (o) {
      return o.number
    })

    data[selected_source_id].push({
      number: max_number ? max_number.number + 1 : 1,
      collapsed: true,
      subData: JSON.parse(JSON.stringify(data_copy.subData)),
    })

    this.setState({ data: data })
  }

  toggleCollapse = (index) => {
    let { data, selected_source_id } = this.state
    data[selected_source_id][index].collapsed = !data[selected_source_id][index].collapsed
    this.setState({ data: data })
  }

  deleteData = (index) => {
    let { data, selected_source_id } = this.state
    data[selected_source_id].splice(index, 1)
    this.setState({ data: data })
  }

  addSubData = (index) => {
    let { data, selected_source_id, field_options } = this.state
    let max_order = _.maxBy(data[selected_source_id][index].subData, function (o) {
      return o.order
    })
    let subData = {
      attribute_name_id: '',
      attribute_type: '',
      order: max_order ? max_order.order + 1 : 1,
    }
    field_options.forEach((option) => {
      if ('multi' in option && option.multi) {
        subData[option.name] = []
      } else {
        subData[option.name] = ''
      }
    })
    data[selected_source_id][index].subData.push(subData)

    this.setState({ data: data })
  }

  deleteSubData = (index, _index) => {
    let { data, selected_source_id } = this.state
    data[selected_source_id][index].subData.splice(_index, 1)
    this.setState({ data: data })
  }

  addSourceDocumentAttribute = async (e) => {
    e.preventDefault()
    await this.formDataHandler(false, false)
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('attributeNameId', this.state.attributeNameId)
    formData.append('newSource', this.state.newSource)
    await this.props.addSourceDocumentAttribute(formData)
    await this.setState({ loading: false, select_source_mode: true })
    this.fetchData()
  }

  onChangeSubDataHandler = (e, path) => {
    const index = path.split('.')[0]
    const sub_index = path.split('.')[1]
    let { data, selected_source_id } = this.state
    data[selected_source_id][index].subData[sub_index][e.target.name] = e.target.value
    this.setState({ data: data })
  }

  updateLOVData = (index, sub_index, lov_data) => {
    let { data, selected_source_id } = this.state
    data[selected_source_id][index].subData[sub_index].lov = lov_data
    this.setState({ data: data })
  }

  onChangeOrderHandler = (index, _index, up) => {
    let { data, selected_source_id } = this.state
    let swap_order = data[selected_source_id][index].subData[_index].order
    if (up) {
      if (_index > 0) {
        data[selected_source_id][index].subData[_index].order = data[selected_source_id][index].subData[_index - 1].order
        data[selected_source_id][index].subData[_index - 1].order = swap_order
      }
    } else {
      if (_index < data[selected_source_id][index].subData.length - 1) {
        data[selected_source_id][index].subData[_index].order = data[selected_source_id][index].subData[_index + 1].order
        data[selected_source_id][index].subData[_index + 1].order = swap_order
      }
    }

    let sorted_subdata = _.sortBy(data[selected_source_id][index].subData, 'order')
    data[selected_source_id][index].subData = sorted_subdata
    this.setState({ data: data })
  }

  onSelect = async (selectedList, field) => {
    let { selected_source_id, source_options } = this.state
    const index = source_options.findIndex((option) => option.id === selected_source_id)
    if (index > -1) {
      source_options[index][field] = selectedList
    }

    await this.setState({ source_options: source_options })
  }

  deleteSourceName = async (index, deleted_source_id) => {
    let { source_options, data, selected_source_id } = this.state
    if (selected_source_id !== deleted_source_id) {
      source_options.splice(index, 1)
      if (deleted_source_id in data) {
        delete data[deleted_source_id]
      }
      await this.setState({ source_options: source_options, data: data })
    }
  }

  addTitleboxAttribute = async (e) => {
    e.preventDefault()
    let { titlebox_attribute_value, source_options, selected_source_id } = this.state
    source_options.forEach((option) => {
      if (option.id === selected_source_id && option.selected_titlebox_options.length === 1) {
        let selected_titlebox_option = option.selected_titlebox_options[0]
        option.titlebox_options.forEach((_option) => {
          if (_option.id === selected_titlebox_option.id) {
            let max_id = Math.max.apply(
              Math,
              _option.options.map(function (o) {
                return parseInt(o.id)
              })
            )
            _option.options.push({
              id: (max_id + 1).toString(),
              Name: titlebox_attribute_value,
              parent_id: option.id,
            })
          }
        })
      }
    })

    await this.setState({
      source_options: source_options,
      add_titlebox_attribute_mode: false,
    })
  }

  renderForm() {
    let { selected_source_id, selected_source_name, source_options } = this.state

    let titlebox_options = []
    let selected_titlebox_options = []
    let titlebox_attribute_options = []
    let selected_titlebox_attribute_options = []

    let source_option = source_options.find((option) => option.id === selected_source_id)
    if (source_option) {
      if ('titlebox_options' in source_option && source_option.titlebox_options.length) {
        titlebox_options = source_option.titlebox_options
        selected_titlebox_options = source_option.selected_titlebox_options

        titlebox_options.forEach((option) => {
          if (selected_titlebox_options.findIndex((_option) => _option.id === option.id) > -1) {
            titlebox_attribute_options = titlebox_attribute_options.concat(option.options)
          }
        })

        selected_titlebox_attribute_options = source_option.selected_titlebox_attribute_options
      }
    }

    return (
      <div className="card" id="unique-18750232div_1">
        <div className="clearfix card-header bg-light" id="unique-45771806div_2">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-98687108button_4">
            Cancel
          </button>

          <h3 id="unique-10412457h3_1">Document Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="42582240" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-12801316div_3">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}
          <div className="row" id="unique-65305666div_4">
            <div className="col-3" id="unique-67437671div_5">
              <div className="table-container bg-light" style={{ height: '46rem' }} id="unique-81107867div_6">
                <h4 className="mb-3" id="unique-60128468h4_1">
                  Source Name
                </h4>
                {this.state.source_options.map((option, index) => {
                  return (
                    <div key={option.id} value={option.id} className="mb-2 clearfix" id="unique-65422132div_7">
                      <button
                        disabled={option.id === this.state.selected_source_id}
                        title={option.id === this.state.selected_source_id ? "Can't remove selected source name." : null}
                        className="float-right btn btn-default"
                        onClick={() => this.deleteSourceName(index, option.id)}
                      >
                        <i className="fa fa-trash-alt" id="unique-32546916i_2"></i>
                      </button>
                      <label
                        onClick={() =>
                          this.setState({
                            selected_source_id: option.id,
                            selected_source_name: option.Name,
                            selected_attribute: '',
                          })
                        }
                        className={`checkbox mr-2 ${this.state.selected_source_id === option.id ? 'checked' : null}`}
                      />{' '}
                      {option.Name}
                    </div>
                  )
                })}
                <form className="mt-4" onSubmit={(e) => this.addSourceDocumentAttribute(e)}>
                  <div className="input-group" id="unique-78225245div_8">
                    {!this.state.select_source_mode ? (
                      <Input
                        type="text"
                        value={this.state.newSource}
                        path={`void`}
                        onChange={(e) => {
                          if (e.target.value !== 'New') {
                            this.setState({
                              newSource: e.target.value,
                            })
                          }
                        }}
                        required={true}
                      />
                    ) : (
                      <select
                        className="form-control"
                        value={this.state.attributeNameId}
                        onChange={(e) => {
                          if (e.target.value === 'New') {
                            this.setState({
                              select_source_mode: false,
                              attributeNameId: '',
                            })
                          } else {
                            this.setState({ attributeNameId: e.target.value })
                          }
                        }}
                        required={true}
                      >
                        <option value="">--Select--</option>
                        {this.state.additional_source_options.map((option) => {
                          return (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        })}
                        <option value="New">New</option>
                      </select>
                    )}
                    <div className="input-group-append" id="unique-33349872div_9">
                      <button className="btn btn-primary" type="submit" id="unique-18727634button_6">
                        <i className="fa fa-plus" id="unique-22063593i_3"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {selected_source_id ? (
              <div className="col" id="unique-56709591div_10">
                <div className="row mb-3" id="unique-88901879div_11">
                  <div className="col" id="unique-51724798div_12">
                    <h3 className="mb-0" id="unique-75175304h3_2">
                      {selected_source_name}
                    </h3>
                  </div>
                  <div className="col-9" id="unique-63855425div_13">
                    <div className="row" id="unique-50328704div_14">
                      <div className="col-4" id="unique-10749566div_15">
                        <Select
                          options={titlebox_options.map((option) => {
                            return {
                              label: option.Name,
                              value: option.Name,
                            }
                          })}
                          onChange={(selectedList) => {
                            this.onSelect(selectedList, 'selected_titlebox_options')
                          }}
                          value={selected_titlebox_options.map((option) => {
                            return {
                              label: option.Name,
                              value: option.Name,
                            }
                          })}
                          isMulti={true}
                          isDisabled={!titlebox_options.length}
                        />
                      </div>
                      <div className="col-5" id="unique-95882349div_16">
                        <div className="row no-gutters" id="unique-11502773div_17">
                          <div className="col-9" id="unique-29533622div_18">
                            {this.state.add_titlebox_attribute_mode ? (
                              <form className="input-group" onSubmit={this.addTitleboxAttribute} id="unique-17636757form_2">
                                <input
                                  className="form-control"
                                  value={this.state.titlebox_attribute_value}
                                  name="titlebox_attribute_value"
                                  onChange={(obj) => this.setState(obj)}
                                  required={true}
                                />
                                <div className="input-group-append" id="unique-30665081div_19">
                                  <button className="btn btn-primary" type="submit" id="unique-27066433button_7">
                                    Add
                                  </button>
                                </div>
                              </form>
                            ) : (
                              <Select
                                options={titlebox_attribute_options.map((option) => {
                                  return {
                                    label: option.Name,
                                    value: option.Name,
                                  }
                                })}
                                onChange={(selectedList) => {
                                  this.onSelect(selectedList, 'selected_titlebox_attribute_options')
                                }}
                                value={selected_titlebox_attribute_options.map((option) => {
                                  return {
                                    label: option.Name,
                                    value: option.Name,
                                  }
                                })}
                                isMulti={true}
                                isDisabled={!selected_titlebox_attribute_options.length}
                              />
                            )}
                          </div>
                          <div className="col-3" id="unique-64059945div_20">
                            <button
                              className={`btn ${!this.state.add_titlebox_attribute_mode ? 'btn-primary' : 'btn-secondary'} float-right`}
                              disabled={selected_titlebox_options.length !== 1}
                              onClick={() =>
                                this.setState({
                                  add_titlebox_attribute_mode: !this.state.add_titlebox_attribute_mode,
                                })
                              }
                            >
                              {this.state.add_titlebox_attribute_mode ? 'Cancel' : 'Add'}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col" id="unique-73641806div_21">
                        <button className="btn btn-primary float-right" onClick={this.addData} id="unique-77006144button_9">
                          Add Configuration
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="templates" id="unique-86813195div_22">
                  {this.state.selected_source_id in this.state.data
                    ? this.state.data[this.state.selected_source_id].map((data, index) => {
                        return (
                          <div className="template" key={data.number} id="unique-25204996div_23">
                            <div className="row" id="unique-98968662div_24">
                              <div className="col-md-2" id="unique-66061528div_25">
                                <span className="badge badge-light" id="unique-11076342span_1">
                                  Configuration {data.number}
                                </span>
                              </div>
                              <div className="col-md-7" id="unique-76208692div_26"></div>
                              <div className="col-md-3 clearfix" id="unique-70675982div_27">
                                <button className="btn float-right" onClick={() => this.deleteData(index)}>
                                  <i
                                    className="fa fa-trash-alt text-secondary"
                                    style={{
                                      fontSize: 22,
                                    }}
                                    id="42582240"
                                  />
                                </button>
                                <button className="btn btn-primary float-right mr-1" onClick={() => this.toggleCollapse(index)}>
                                  {data.collapsed ? <i className="fa fa-angle-up" id="unique-21005747i_5"></i> : <i className="fa fa-angle-down" id="unique-55370921i_6"></i>}
                                </button>
                                <button className="btn btn-primary float-right mr-1" onClick={() => this.cloneData(data)}>
                                  Clone
                                </button>
                              </div>
                            </div>
                            {data.collapsed ? (
                              <div className="mt-4" id="unique-19642836div_28">
                                <table className="form table">
                                  <thead>
                                    <tr id="unique-60457125tr_1">
                                      <th className="text-nowrap" id="unique-74430891th_1">
                                        {' '}
                                        Order{' '}
                                      </th>
                                      <th className="text-nowrap" id="unique-50001978th_2">
                                        {' '}
                                        Attribute Name{' '}
                                      </th>
                                      <th className="text-nowrap" id="unique-72262036th_3">
                                        {' '}
                                        Attribute Type{' '}
                                      </th>

                                      {this.state.field_options.map((option) => {
                                        return (
                                          <th className="text-nowrap" key={option.name} id="unique-50413772th_4">
                                            {option.display_name}
                                          </th>
                                        )
                                      })}
                                      <th id="unique-10958195th_5"></th>
                                    </tr>
                                  </thead>
                                  <tbody id="unique-98210241tbody_1">
                                    {data.subData.map((_data, _index) => {
                                      let attribute_names = []
                                      let attribute_types = []
                                      this.state.source_options.forEach((option) => {
                                        if (option.id === this.state.selected_source_id) {
                                          option.attribute_names.forEach((_option) => {
                                            attribute_names.push(_option)
                                            if (_data.attribute_name_id && _option.id === parseInt(_data.attribute_name_id.value)) {
                                              _option.attribute_type.forEach((__option) => {
                                                attribute_types.push(__option)
                                              })
                                            }
                                            return 0
                                          })
                                        }
                                      })

                                      return (
                                        <tr key={_index} id="unique-89488746tr_2">
                                          <td className="text-nowrap" id="unique-74092011td_1">
                                            <button className="btn btn-sm btn-primary" onClick={() => this.onChangeOrderHandler(index, _index, true)}>
                                              <i className="fa fa-angle-up" id="unique-84651593i_7"></i>
                                            </button>
                                            &nbsp;
                                            <button className="btn btn-sm btn-primary" onClick={() => this.onChangeOrderHandler(index, _index, false)}>
                                              <i className="fa fa-angle-down" id="unique-11546051i_8"></i>
                                            </button>
                                          </td>
                                          <td id="unique-23194311td_2">
                                            <Select
                                              value={_data.attribute_name_id}
                                              options={attribute_names.map((option) => {
                                                option.label = option.Name
                                                option.value = option.id
                                                return option
                                              })}
                                              onChange={(selectedOption) => {
                                                const e = {
                                                  target: {
                                                    name: 'attribute_name_id',
                                                    value: selectedOption,
                                                  },
                                                }
                                                this.onChangeSubDataHandler(e, `${index}.${_index}`)
                                              }}
                                              isMulti={false}
                                            />
                                          </td>
                                          <td id="unique-10510277td_3">
                                            {attribute_types.length ? (
                                              <Select
                                                options={attribute_types.map((option) => {
                                                  option.label = option.Name
                                                  option.value = option.Name
                                                  return option
                                                })}
                                                value={_data.attribute_type}
                                                onChange={(selectedOption) => {
                                                  const e = {
                                                    target: {
                                                      name: 'attribute_type',
                                                      value: selectedOption,
                                                    },
                                                  }
                                                  this.onChangeSubDataHandler(e, `${index}.${_index}`)
                                                }}
                                                isMulti={false}
                                              />
                                            ) : _data.attribute_name_id && _data.attribute_name_id.value ? (
                                              `Info Already Present`
                                            ) : null}
                                          </td>

                                          {this.state.field_options.map((field) => {
                                            if (['text', 'number', 'date'].includes(field.field_type)) {
                                              return (
                                                <td key={field.name}>
                                                  <div className="input-group" hidden={this.checkIfDisabled(field.name, index, _index)}>
                                                    <Input
                                                      type={field.field_type}
                                                      className="form-control"
                                                      name={field.name}
                                                      value={_data[field.name]}
                                                      path={`${index}.${_index}`}
                                                      onChange={this.onChangeSubDataHandler}
                                                    />
                                                    {field.name === 'lov' ? (
                                                      <div className="input-group-append">
                                                        <UploadLOVExcel
                                                          {...this.props}
                                                          index={index}
                                                          _index={_index}
                                                          updateLOVData={this.updateLOVData}
                                                          projectId={this.props.editProjectId}
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </td>
                                              )
                                            } else if (field.field_type === 'select') {
                                              if ('multi' in field && field.multi) {
                                                return (
                                                  <td key={field.name} style={{ width: 300 }} id="unique-33430732td_5">
                                                    <Select
                                                      options={field.options.map((option) => {
                                                        return {
                                                          label: option,
                                                          value: option,
                                                        }
                                                      })}
                                                      onChange={(selectedList) => {
                                                        selectedList = selectedList.map((selectedOption) => {
                                                          return selectedOption.value
                                                        })
                                                        this.onChangeSubDataHandler({ target: { name: field.name, value: selectedList } }, `${index}.${_index}`)
                                                      }}
                                                      value={_data[field.name].map((selectedOption) => {
                                                        return {
                                                          label: selectedOption,
                                                          value: selectedOption,
                                                        }
                                                      })}
                                                      isMulti={true}
                                                    />
                                                  </td>
                                                )
                                              }
                                              return (
                                                <td key={field.name} id="unique-10488955td_6">
                                                  <Select
                                                    value={_data[field.name]}
                                                    options={field.options.map((option) => {
                                                      return {
                                                        label: option,
                                                        value: option,
                                                      }
                                                    })}
                                                    onChange={(selectedOption) => {
                                                      const e = {
                                                        target: {
                                                          name: field.name,
                                                          value: selectedOption,
                                                        },
                                                      }
                                                      this.onChangeSubDataHandler(e, `${index}.${_index}`)
                                                    }}
                                                    isDisabled={this.checkIfDisabled(field.name, index, _index)}
                                                    isMulti={false}
                                                  />
                                                </td>
                                              )
                                            }
                                            return null
                                          })}
                                          <td id="unique-38840717td_7">
                                            <i
                                              className="fa fa-trash-alt cursor-pointer text-secondary"
                                              style={{
                                                fontSize: 16,
                                              }}
                                              onClick={() => this.deleteSubData(index, _index)}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                                <div className="clearfix mt-2" id="unique-52257152div_31">
                                  <button className="btn btn-primary btn-sm float-right" onClick={() => this.addSubData(index)}>
                                    <i className="fa fa-plus" id="unique-47726509i_10"></i>
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )
                      })
                    : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-11452739div_32">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
