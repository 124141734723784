import React from 'react'

class Step2_1 extends React.Component {
  state = {
    processDrawingsCountData: [],
    selectedPackages: [],
    collapseList: [],
  }

  componentDidMount() {
    this.setState({
      processDrawingsCountData: this.props.processDrawingsCountData,
      selectedPackages: this.props.selectedPackages,
    })
  }

  updateCollapseList = (item) => {
    let collapseList = this.state.collapseList
    const index = collapseList.indexOf(item)
    if (index > -1) {
      collapseList.splice(index, 1)
    } else {
      collapseList.push(item)
    }
    this.setState({ collapseList: collapseList })
  }

  toggleSelection = (path, depth, type) => {
    path = path.split('.')
    let processDrawingsCountData = this.state.processDrawingsCountData
    if (depth === 1) {
      const selection_index_1 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (selection_index_1 > -1) {
        processDrawingsCountData[selection_index_1][type].selected = !processDrawingsCountData[selection_index_1][type].selected
      }

      processDrawingsCountData[selection_index_1].value.forEach((data) => {
        data[type].selected = processDrawingsCountData[selection_index_1][type].selected
        if (data.value.length) {
          data.value.forEach((_data) => {
            _data[type].selected = processDrawingsCountData[selection_index_1][type].selected
          })
        }
      })
    } else if (depth === 2) {
      const selection_index_2 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (selection_index_2 > -1) {
        const _selection_index_2 = processDrawingsCountData[selection_index_2].value.findIndex((data) => data.key === path[1])
        if (_selection_index_2 > -1) {
          processDrawingsCountData[selection_index_2].value[_selection_index_2][type].selected =
            !processDrawingsCountData[selection_index_2].value[_selection_index_2][type].selected
        }

        processDrawingsCountData[selection_index_2].value[_selection_index_2].value.forEach((data) => {
          data[type].selected = processDrawingsCountData[selection_index_2].value[_selection_index_2][type].selected
        })
      }
    } else if (depth === 3) {
      const selection_index_3 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (selection_index_3 > -1) {
        const _selection_index_3 = processDrawingsCountData[selection_index_3].value.findIndex((data) => data.key === path[1])
        if (_selection_index_3 > -1) {
          const __selection_index_3 = processDrawingsCountData[selection_index_3].value[_selection_index_3].value.findIndex((data) => data.key === path[2])
          if (__selection_index_3 > -1) {
            processDrawingsCountData[selection_index_3].value[_selection_index_3].value[__selection_index_3][type].selected =
              !processDrawingsCountData[selection_index_3].value[_selection_index_3].value[__selection_index_3][type].selected
          }
        }
      }
    }

    this.setState({ processDrawingsCountData: processDrawingsCountData }, this.props.updateProcessDrawingsCountData(processDrawingsCountData))
  }

  checkSelection = (path, depth, type) => {
    let selectionClass = ''
    let foundOneSelected = false
    let foundOneNotSelected = false

    path = path.split('.')
    let processDrawingsCountData = this.state.processDrawingsCountData
    if (depth === 1) {
      const selection_index_1 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (!processDrawingsCountData[selection_index_1][type].selected) {
        foundOneNotSelected = true
      } else {
        foundOneSelected = true
      }

      processDrawingsCountData[selection_index_1].value.forEach((data) => {
        if (!data[type].selected) {
          foundOneNotSelected = true
        } else {
          foundOneSelected = true
        }
        if (data.value.length) {
          data.value.forEach((_data) => {
            if (!_data[type].selected) {
              foundOneNotSelected = true
            } else {
              foundOneSelected = true
            }
          })
        }
      })
    } else if (depth === 2) {
      const selection_index_2 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (selection_index_2 > -1) {
        const _selection_index_2 = processDrawingsCountData[selection_index_2].value.findIndex((data) => data.key === path[1])
        if (!processDrawingsCountData[selection_index_2].value[_selection_index_2][type].selected) {
          foundOneNotSelected = true
        } else {
          foundOneSelected = true
        }

        processDrawingsCountData[selection_index_2].value[_selection_index_2].value.forEach((data) => {
          if (!data[type].selected) {
            foundOneNotSelected = true
          } else {
            foundOneSelected = true
          }
        })
      }
    } else if (depth === 3) {
      const selection_index_3 = processDrawingsCountData.findIndex((data) => data.key === path[0])
      if (selection_index_3 > -1) {
        const _selection_index_3 = processDrawingsCountData[selection_index_3].value.findIndex((data) => data.key === path[1])
        if (_selection_index_3 > -1) {
          const __selection_index_3 = processDrawingsCountData[selection_index_3].value[_selection_index_3].value.findIndex((data) => data.key === path[2])
          if (!processDrawingsCountData[selection_index_3].value[_selection_index_3].value[__selection_index_3][type].selected) {
            foundOneNotSelected = true
          } else {
            foundOneSelected = true
          }
        }
      }
    }

    if (!foundOneSelected) {
      selectionClass = 'checkbox'
    } else if (foundOneSelected && foundOneNotSelected) {
      selectionClass = 'half-checkbox checked'
    } else {
      selectionClass = 'checkbox checked'
    }

    return selectionClass
  }

  renderProcessDrawingsCountData = (show_combined_data) => {
    return this.state.processDrawingsCountData.map((data, index) => {
      return (
        <div key={data.key}>
          <div className={`row no-gutters ${index % 2 === 0 ? 'bg-light' : ''}`} style={{ borderTop: '1px solid #eee' }}>
            <div className="col">
              <span className="btn font-weight-bold">
                <span className="d-inline-block" style={{ minWidth: 30 }}>
                  {data.parent ? (
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.updateCollapseList(data.key)}
                      className={`fa mr-2 ${this.state.collapseList.includes(data.key) ? 'fa-minus' : 'fa-plus'}`}
                    />
                  ) : null}
                </span>{' '}
                {data.key}
              </span>
            </div>

            {show_combined_data ? (
              <div className="col" id="unique-24022551div_4">
                <span className="btn" id="unique-10572877span_3">
                  <span className="d-inline-block" style={{ minWidth: 30 }} id="unique-22436418span_4">
                    {data.Combined.count}
                  </span>
                  <div className="d-inline-block text-center ml-4" id="unique-77016385div_5">
                    <label className={`${this.checkSelection(`${data.key}`, 1, 'Combined')}`} onClick={() => this.toggleSelection(`${data.key}`, 1, 'Combined')} />
                  </div>
                </span>
              </div>
            ) : null}

            {this.state.selectedPackages.map((_package) => {
              return (
                <div className="col" key={_package} id="unique-11952586div_6">
                  <span className="btn" id="unique-10308723span_5">
                    <span className="d-inline-block" style={{ minWidth: 30 }} id="unique-85448915span_6">
                      {_package in data ? data[_package].count : 'undefined'}
                    </span>

                    <div className="d-inline-block text-center ml-4" id="unique-10962922div_7">
                      <label className={`${this.checkSelection(`${data.key}`, 1, _package)}`} onClick={() => this.toggleSelection(`${data.key}`, 1, _package)} />
                    </div>
                  </span>
                </div>
              )
            })}
          </div>

          {data.parent && this.state.collapseList.includes(data.key)
            ? data.value.map((_data) => {
                return (
                  <div key={_data.key} style={{ fontSize: '14px' }} id="unique-24271888div_8">
                    <div className="row no-gutters" id="unique-88815209div_9">
                      <div className="col font-weight-bold" id="unique-36596878div_10">
                        <div className="ml-3" id="unique-40752238div_11">
                          <span className="btn font-weight-bold" id="unique-35582060span_7">
                            <span
                              className="d-inline-block"
                              style={{
                                minWidth: 30,
                              }}
                              id="unique-32960283span_8"
                            >
                              {_data.parent ? (
                                <i
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.updateCollapseList(`${data.key}_${_data.key}`)}
                                  className={`fa mr-2 ${this.state.collapseList.includes(`${data.key}_${_data.key}`) ? 'fa-minus' : 'fa-plus'}`}
                                />
                              ) : null}
                            </span>{' '}
                            {_data.key}
                          </span>
                        </div>
                      </div>
                      {show_combined_data ? (
                        <div className="col" id="unique-99258241div_12">
                          <span className="btn" id="unique-10357253span_9">
                            <span className="d-inline-block" style={{ minWidth: 30 }} id="unique-15453856span_10">
                              {_data.Combined.count}
                            </span>

                            <div className="d-inline-block text-center ml-4" id="unique-24829250div_13">
                              <label
                                className={`${this.checkSelection(`${data.key}.${_data.key}`, 2, 'Combined')}`}
                                onClick={() => this.toggleSelection(`${data.key}.${_data.key}`, 2, 'Combined')}
                              />
                            </div>
                          </span>
                        </div>
                      ) : null}
                      {this.state.selectedPackages.map((_package) => {
                        return (
                          <div className="col" key={_package} id="unique-74378533div_14">
                            <span className="btn" id="unique-54524119span_11">
                              <span
                                className="d-inline-block"
                                style={{
                                  minWidth: 30,
                                }}
                                id="unique-45714499span_12"
                              >
                                {_data[_package].count}
                              </span>

                              <div className="d-inline-block text-center ml-4" id="unique-34560497div_15">
                                <label
                                  className={`${this.checkSelection(`${data.key}.${_data.key}`, 2, _package)}`}
                                  onClick={() => this.toggleSelection(`${data.key}.${_data.key}`, 2, _package)}
                                />
                              </div>
                            </span>
                          </div>
                        )
                      })}
                      {/* <div className="col" id="unique-45087842div_16">
                        <span className="btn" id="unique-11332313span_13">
                          <div className="d-inline-block text-center" id="unique-48121680div_17">
                            <label
                              className={`${this.checkSelection(`${data.key}.${_data.key}`, 2, 'Custom')}`}
                              onClick={() => this.toggleSelection(`${data.key}.${_data.key}`, 2, 'Custom')}
                            />
                          </div>
                        </span>
                      </div> */}
                    </div>

                    {_data.parent && this.state.collapseList.includes(`${data.key}_${_data.key}`)
                      ? _data.value.map((__data) => {
                          return (
                            <div
                              key={__data.key}
                              style={{
                                fontSize: '12px',
                              }}
                              id="unique-42171003div_18"
                            >
                              <div className="row no-gutters" id="unique-66610533div_19">
                                <div className="col" id="unique-89291384div_20">
                                  <span className="btn font-weight-bold" id="unique-10491008span_14">
                                    <div className="ml-4" id="unique-35842016div_21">
                                      <div className="ml-4" id="unique-93253934div_22">
                                        {__data.key}
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                {show_combined_data ? (
                                  <div className="col" id="unique-33250193div_23">
                                    <span className="btn" id="unique-41575077span_15">
                                      <span
                                        className="d-inline-block"
                                        style={{
                                          minWidth: 30,
                                        }}
                                        id="unique-13913056span_16"
                                      >
                                        {__data.Combined.count}
                                      </span>

                                      <div className="d-inline-block text-center ml-4" id="unique-90589699div_24">
                                        <label
                                          className={`${this.checkSelection(`${data.key}.${_data.key}.${__data.key}`, 3, 'Combined')}`}
                                          onClick={() => this.toggleSelection(`${data.key}.${_data.key}.${__data.key}`, 3, 'Combined')}
                                        />
                                      </div>
                                    </span>
                                  </div>
                                ) : null}
                                {this.state.selectedPackages.map((_package) => {
                                  return (
                                    <div className="col" key={_package} id="unique-25644680div_25">
                                      <span className="btn" id="unique-72761287span_17">
                                        <span
                                          className="d-inline-block"
                                          style={{
                                            minWidth: 30,
                                          }}
                                          id="unique-10945479span_18"
                                        >
                                          {__data[_package].count}
                                        </span>

                                        <div className="d-inline-block text-center ml-4" id="unique-77213522div_26">
                                          <label
                                            className={`${this.checkSelection(`${data.key}.${_data.key}.${__data.key}`, 3, _package)}`}
                                            onClick={() => this.toggleSelection(`${data.key}.${_data.key}.${__data.key}`, 3, _package)}
                                          />
                                        </div>
                                      </span>
                                    </div>
                                  )
                                })}
                                {/* <div className="col" id="unique-22642573div_27">
                                  <span className="btn" id="unique-22405006span_19">
                                    <div className="d-inline-block text-center" id="unique-13010544div_28">
                                      <label
                                        className={`${this.checkSelection(`${data.key}.${_data.key}.${__data.key}`, 3, 'Custom')}`}
                                        onClick={() => this.toggleSelection(`${data.key}.${_data.key}.${__data.key}`, 3, 'Custom')}
                                      />
                                    </div>
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </div>
                )
              })
            : null}
        </div>
      )
    })
  }

  render() {
    let show_combined_data = true
    this.state.processDrawingsCountData.forEach((data) => {
      if (!('Combined' in data)) {
        show_combined_data = false
      }
    })
    return (
      <div className="table-container mb-3 model-configuration-controls p-0" id="unique-55378482div_29">
        <div className="row no-gutters" style={{ backgroundColor: '#eee' }} id="unique-90841263div_30">
          <div className="col" id="unique-66228941div_31">
            <span className="btn d-block" id="unique-66769215span_20">
              Components
            </span>
          </div>
          {show_combined_data ? (
            <div className="col" id="unique-94951523div_32">
              <span className="btn d-block" id="unique-86481812span_21">
                Combined Packages
              </span>
            </div>
          ) : null}

          {this.state.selectedPackages.map((_package) => {
            return (
              <div className="col" key={_package} id="unique-80441680div_33">
                <span className="btn d-block" id="unique-94263055span_22">
                  {_package}
                </span>
              </div>
            )
          })}
        </div>

        {this.renderProcessDrawingsCountData(show_combined_data)}
      </div>
    )
  }
}

export default Step2_1
