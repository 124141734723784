import React from 'react'

class LinkedProjectInfoBox extends React.Component {
  state = {
    toggled: false,
  }
  render() {
    return (
      <div className="card bg-light mb-4" id="unique-41296547div_1">
        <div className="card-body p-2" id="unique-11578036div_2">
          <div className="clearfix" id="unique-11947759div_3">
            <button type="button" onClick={() => this.setState({ toggled: !this.state.toggled })} className="btn btn-default float-right">
              {!this.state.toggled ? <i className="fa fa-angle-down" id="unique-65097476i_1"></i> : <i className="fa fa-angle-up" id="unique-23062960i_2"></i>}
            </button>
            <h4 className="mb-0" style={{ lineHeight: '40px' }} id="unique-88440068h4_1">
              Linked Projects
            </h4>
          </div>
          {this.state.toggled ? (
            <div className="mt-2" id="unique-38259855div_4">
              You are making change to a Linked project group hence impacting configuration of following projects:
              <ul>
                {this.props.group_members.map((member, index) => {
                  return (
                    <li className="mt-2" key={index} id="unique-26440119li_1">
                      {member}
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default LinkedProjectInfoBox
