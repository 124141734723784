import React, { Component } from 'react'

import { PagingState, CustomPaging, SelectionState, IntegratedSelection, SortingState } from '@devexpress/dx-react-grid'

import {
  ColumnChooser,
  Grid,
  TableHeaderRow,
  PagingPanel,
  VirtualTable,
  TableColumnVisibility,
  DragDropProvider,
  TableColumnResizing,
  TableSelection,
  TableFixedColumns,
  TableColumnReordering,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

export default class ProjectTable extends Component {
  state = {
    toggled_actions: '',
    deleteFileId: null,

    data: [],
    columns: [],
    sortingStateColumnExtensions: [{ columnName: 'actions', sortingEnabled: false }],
    _process_selection: [],
    additionalColumns: this.props.additionalColumns ? this.props.additionalColumns : [],
    columnWidths: [],
    defaultHiddenColumnNames: [],
    tableColumnVisibilityColumnExtensions: [],
    columnOrder: [],
  }

  componentDidMount = async () => {
    this.setState({
      _process_selection: JSON.parse(JSON.stringify(this.props.process_selection)),
      data: this.props.tableList,
    })
    await this.setColumns()
  }

  componentDidUpdate = async (props) => {
    if (this.props.loading !== props.loading || this.props.process_selection.length !== this.state._process_selection.length) {
      await this.setState({
        _process_selection: JSON.parse(JSON.stringify(this.props.process_selection)),
        data: this.props.tableList,
      })
      await this.setColumns()
      await this.setInitialColumnWidths()
    }
  }

  getRowId = (row) => row.id

  setColumns = async () => {
    let { defaultHiddenColumnNames, columnOrder } = this.state

    let columns = []
    let sortingStateColumnExtensions = []
    let tableColumnVisibilityColumnExtensions = []

    this.props.columns.forEach((column) => {
      if (!column.name) {
        return
      }

      if (!column.allowSort) {
        sortingStateColumnExtensions.push({
          columnName: column.name,
          sortingEnabled: false,
        })
      }

      if (!column.visible && !defaultHiddenColumnNames.includes(column.name)) {
        defaultHiddenColumnNames.push(column.name)
      }

      if (['actions', 'name', 'status'].includes(column.name)) {
        tableColumnVisibilityColumnExtensions.push({ columnName: column.name, togglingEnabled: false })
      } else {
        if (!columnOrder.includes(column.name)) {
          columnOrder.push(column.name)
        }
      }

      columns.push({
        name: column.name,
        title: column.header,
        getCellValue: (row) => {
          if (column.name === 'status') {
            return this.getStatus(row)
          }

          if (column.name === 'actions') {
            return this.getActions(row)
          }
          return (
            <div
              className="cursor-pointer"
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                lineHeight: '38px',
                height: '38px',
              }}
              title={column.name === 'name' ? row[column.name] : column.name in row.title ? row.title[column.name] : null}
              id="unique-22533526div_1"
            >
              {column.name === 'name' ? (
                <u onClick={() => this.props.viewDatasheetFile(row)} className="cursor-pointer">
                  {row[column.name]}
                </u>
              ) : (
                row[column.name]
              )}
            </div>
          )
        },
      })
    })

    await this.setState({
      columns: columns,
      sortingStateColumnExtensions: sortingStateColumnExtensions,
      defaultHiddenColumnNames: defaultHiddenColumnNames,
      tableColumnVisibilityColumnExtensions: tableColumnVisibilityColumnExtensions,
      columnOrder: columnOrder,
    })
  }

  setInitialColumnWidths = async () => {
    let columnWidths = []

    if (this.props.dsPdfListColumnWidths && this.props.dsPdfListColumnWidths.length) {
      columnWidths = this.props.dsPdfListColumnWidths
    } else {
      this.props.columns.forEach((column) => {
        columnWidths.push({
          columnName: column.name,
          width: 'width' in column ? column.width : column.name === 'status' ? 100 : column.name === 'actions' ? 120 : column.name === 'name' ? 320 : 180,
        })
      })
    }

    await this.setState({
      columnWidths: columnWidths,
    })
  }

  getActions = (row) => {
    let { product } = this.props
    let view_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_view_hidden`) ? true : false
    let download_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_download_hidden`) ? true : false
    let delete_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_delete_hidden`) ? true : false
    let mark_approved_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-approved_hidden`) ? true : false
    let mark_reviewed_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-reviewed_hidden`) ? true : false
    let mark_cleaned_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_mark-cleaned_hidden`) ? true : false
    let check_in_hidden = this.props.user_actions.includes(`${product.toLowerCase()}_check-in_hidden`) ? true : false

    return (
      <>
        <div className="dropdown d-inline-block" id="unique-11491603div_2">
          <button
            className={`btn ${this.state.toggled_actions === row.id ? 'text-primary' : 'text-secondary'}`}
            id="unique-55742986button_1"
            onClick={async (e) => {
              e.stopPropagation()
              if (this.state.toggled_actions === row.id) {
                await this.setState({ toggled_actions: '' })
              } else {
                await this.setState({
                  toggled_actions: row.id,
                })
              }
              this.setColumns()
            }}
          >
            <i className="fa fa-ellipsis-v" id="unique-71237274i_1" />
          </button>

          {this.state.toggled_actions === row.id ? (
            <div className="dropdown-menu dropdown-menu-left" id="unique-10682740div_3">
              {!view_hidden ? (
                <button id="unique-51874397button_2" className={`dropdown-item`} onClick={() => this.props.viewDatasheetFile(row)}>
                  View
                </button>
              ) : null}
              {!download_hidden ? (
                <button id="unique-26357438button_3" className={`dropdown-item`} onClick={() => this.props.downloadExcel(row, 'single')}>
                  Download
                </button>
              ) : null}

              <div className="dropdown-divider" id="unique-58237229div_4" />
              {!mark_cleaned_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowCleaned}
                  id="unique-11127480button_4"
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(!row.cleaned, undefined, undefined, undefined)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.cleaned ? 'checked' : ''}`} id="unique-71413673label_1" /> Mark Cleaned
                </button>
              ) : null}
              {!mark_reviewed_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowReviewed}
                  id="unique-84160678button_5"
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(undefined, undefined, undefined, !row.reviewed)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.reviewed ? 'checked' : ''}`} id="unique-11102144label_2" /> Mark Reviewed
                </button>
              ) : null}
              {!mark_approved_hidden ? (
                <button
                  className="dropdown-item"
                  id="unique-75078171button_6"
                  disabled={!row.allowApproved}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.updateDocumentStatus(undefined, !row.approved, undefined, undefined)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.approved ? 'checked' : ''}`} id="unique-40257926label_3" /> Mark Approved
                </button>
              ) : null}
              {!check_in_hidden ? (
                <>
                  <div className="dropdown-divider" id="unique-96279974div_5" />
                  <button
                    className="dropdown-item"
                    disabled={!row.processed || !row.allowCheckInCheckOut}
                    id="unique-62855699button_7"
                    onClick={async () => {
                      await this.props.setSelection([row.id])
                      this.props.updateDocumentStatus(undefined, undefined, !row.checkIn, undefined)
                    }}
                  >
                    {row.checkIn ? 'Check Out' : 'Check In'}
                  </button>
                </>
              ) : null}

              {!delete_hidden ? (
                <>
                  <div className="dropdown-divider" id="unique-79757296div_6" />
                  <button
                    className={`dropdown-item text-danger`}
                    id="unique-23644996button_8"
                    onClick={() =>
                      this.setState({
                        deleteFileId: row.id,
                      })
                    }
                  >
                    Delete
                  </button>
                </>
              ) : null}
            </div>
          ) : null}
        </div>

        {!row.checkIn ? (
          <span className="badge badge-danger badge-sm badge-pill" title="Checked Out" style={{ lineHeight: '12px' }} id="unique-27816644span_1">
            <i className="fa fa-angle-down" id="unique-83706974i_2" />
          </span>
        ) : null}
      </>
    )
  }

  getStatus = (row) => {
    if (row.ongoingProcessing) {
      return (
        <div className="spinner-grow text-secondary" title="Ongoing Processing" id="unique-88064004div_7">
          <span className="sr-only" id="unique-81920885span_2">
            Processing...
          </span>
        </div>
      )
    } else if (row.error) {
      return <i className="fa fa-circle text-danger" title="Error" id="unique-10024713i_3" />
    } else if (row.partiallyProcessed) {
      return <i className="fa fa-circle text-warning" title="Partially Processed" id="unique-55242961i_4" />
    } else if (row.approved) {
      return <i className="fa fa-check-circle" style={{ color: '#946db7' }} title="Approved" id="unique-11404241i_5" />
    } else if (row.ongoingReviewing) {
      return (
        <div className="spinner-grow" style={{ color: '#946db7' }} title="Ongoing Reviewing" id="unique-27949997div_8">
          <span className="sr-only" id="unique-69290216span_3">
            Ongoing Reviewing...
          </span>
        </div>
      )
    } else if (row.reviewed) {
      return <i className="fa fa-circle" style={{ color: '#946db7' }} title="Reviewed" id="unique-10588698i_6" />
    } else if (row.cleaned) {
      return <i className="fa fa-circle" style={{ color: '#0190b8' }} title="Cleaned" id="unique-10328895i_7" />
    } else if (row.ongoingCleaning) {
      return (
        <div className="spinner-grow" style={{ color: '#0190b8' }} title="Ongoing Cleaning" id="unique-34142262div_9">
          <span className="sr-only" id="unique-85614058span_4">
            Ongoing Cleaning...
          </span>
        </div>
      )
    } else if (row.processed) {
      return <i className="fa fa-circle text-success" title="Processed" id="unique-10816552i_8" />
    } else {
      return <i className="fa fa-circle text-secondary" title="Not Processed" id="unique-37947329i_9" />
    }
  }

  setColumnWidths = async (columnWidths) => {
    await this.props.updateColumnWidths(columnWidths, 'DatasheetPDFListView')
    this.setState({ columnWidths: columnWidths })
  }

  setColumnOrder = (order) => {
    this.setState({ columnOrder: order })
  }

  handleColumnVisibilityChange = (hiddenColumnNames) => {
    this.setState({ defaultHiddenColumnNames: hiddenColumnNames })
  }

  render() {
    let { columns, data, sortingStateColumnExtensions, columnWidths, _process_selection, defaultHiddenColumnNames, columnOrder, tableColumnVisibilityColumnExtensions } = this.state
    let { totalCount, pageSize, currentPage, pageSizes, selection, sorting, process_selection_length, leftColumns } = this.props
    leftColumns = [TableSelection.COLUMN_TYPE, ...leftColumns]

    return (
      <>
        {_process_selection.length ? (
          <div className="process-minimized" id="unique-11093619div_10">
            <span id="unique-31112938span_5">
              Processing Document {process_selection_length - _process_selection.length + 1} of {process_selection_length}
            </span>
            <i className="fa fa-times" onClick={this.props.stopRequests} id="unique-11076487i_10" />
          </div>
        ) : null}

        {this.state.deleteFileId ? (
          <form
            id="unique-60942999form_1"
            onSubmit={(e) => {
              e.preventDefault()
              this.setState({ deleteFileId: null })
              this.props.deleteDocument(this.state.deleteFileId)
            }}
          >
            <div className="modal" id="unique-35169647div_11">
              <div className="modal-dialog modal-sm" id="unique-11408499div_12">
                <div className="modal-content" id="unique-17768897div_13">
                  <div className="modal-header" id="unique-91229072div_14">
                    <button type="button" id="unique-68624382button_9" className="close" onClick={() => this.setState({ deleteFileId: null })}>
                      <span aria-hidden="true" id="unique-63050938span_6">
                        &times;
                      </span>
                    </button>
                  </div>
                  <div className="modal-body" id="unique-10073840div_15">
                    <p id="unique-53308311p_1">Do you really want to delete this file?</p>
                  </div>
                  <div className="modal-footer" id="unique-75299708div_16">
                    <button type="submit" value="Submit" className="btn btn-secondary" id="unique-99644510button_10">
                      Delete
                    </button>
                    <button type="button" id="unique-10329014button_11" className="btn btn-default" onClick={() => this.setState({ deleteFileId: null })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        <div style={{ position: 'relative' }} id="unique-74585580div_17" className={`clearfix`} onClick={() => this.setState({ toggled_actions: '' }, this.setColumns)}>
          <div style={{ position: 'absolute', width: '100%' }} id="unique-26920221div_18">
            <div className="card datasheet-grid" id="unique-26006149div_19">
              <Grid columns={columns} rows={data} getRowId={this.getRowId}>
                <DragDropProvider />
                <PagingState currentPage={currentPage} onCurrentPageChange={this.props.setCurrentPage} pageSize={pageSize} onPageSizeChange={this.props.setPageSize} />
                <SortingState sorting={sorting} onSortingChange={this.props.setSorting} columnExtensions={sortingStateColumnExtensions} />
                <SelectionState selection={selection} onSelectionChange={this.props.setSelection} />
                <CustomPaging totalCount={totalCount} />
                <PagingPanel pageSizes={pageSizes} />
                <VirtualTable />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.setColumnWidths} />
                <TableColumnReordering order={columnOrder} onOrderChange={this.setColumnOrder} />
                <TableHeaderRow showSortingControls />
                <IntegratedSelection />
                <TableSelection showSelectAll />
                <TableColumnVisibility
                  defaultHiddenColumnNames={defaultHiddenColumnNames}
                  onHiddenColumnNamesChange={this.handleColumnVisibilityChange}
                  columnExtensions={tableColumnVisibilityColumnExtensions}
                />

                <TableFixedColumns
                  leftColumns={leftColumns}
                  // rightColumns={rightColumns}
                />
                <Toolbar />
                <ColumnChooser />
              </Grid>
              {this.props.loading ? (
                <div className="card-loader" id="unique-83036980div_20">
                  <div id="loading" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}
