import React from 'react'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css' //for css

class Paginator extends React.Component {
  state = {
    currentPage: 1,
    totalPages: 1,
    data: [],
  }

  componentDidMount = () => {
    let data_length = this.props.data.length
    let pagination_size = this.props.paginationSize
    let totalPages = data_length % pagination_size === 0 ? data_length / pagination_size : Math.ceil(data_length / pagination_size)
    this.setState(
      {
        paginationSize: this.props.paginationSize,
        totalPages: totalPages,
        data: this.props.data,
        actual_data: this.props.data,
      },
      this.changePage
    )
  }

  componentDidUpdate = (props) => {
    if (this.props.data !== props.data || this.props.reset_paginator) {
      let data_length = this.props.data.length
      let pagination_size = this.props.paginationSize
      let totalPages = data_length % pagination_size === 0 ? data_length / pagination_size : Math.ceil(data_length / pagination_size)
      this.setState(
        {
          paginationSize: this.props.paginationSize,
          totalPages: totalPages,
          data: this.props.data,
          currentPage: 1,
        },
        this.changePage
      )
    }
  }

  changeCurrentPage = (numPage) => {
    this.setState({ currentPage: numPage }, this.changePage)
    //fetch a data
    //or update a query to get data
  }

  changePage = () => {
    let sliced_data = this.state.data.slice(
      (this.state.currentPage - 1) * this.props.paginationSize,
      (this.state.currentPage - 1) * this.props.paginationSize + this.props.paginationSize
    )
    this.props.changePage(sliced_data)
  }

  render() {
    return (
      <div className="clearfix" id="unique-81677819div_1">
        <span className="d-inline-block float-right" id="unique-10177550span_1">
          <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} changeCurrentPage={this.changeCurrentPage} theme="square-fill" />
        </span>
        <h4>
          Showing {(this.state.currentPage - 1) * this.props.paginationSize + 1} to {(this.state.currentPage - 1) * this.props.paginationSize + this.props.paginationSize} of{' '}
          {this.state.data.length} entries
        </h4>
      </div>
    )
  }
}
export default Paginator
