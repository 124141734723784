import React from 'react'
import { fabric } from 'fabric'
import _ from 'lodash'

import Select from '../Components/Forms/Fields/select'
import Input from '../Components/Forms/Fields/input'
import ToggleThumbnails from '../Components/WebComponents/ToggleThumbnails'
import Draggable from 'react-draggable'
import './canvas.css'

class IUIAnnotation extends React.Component {
  constructor(props) {
    super(props)

    // Create a ref map
    this.rowRefs = {}
    this.editorContentRef = React.createRef()

    this.state = {
      data: {},

      // Schema Parameters
      schema: {},
      ComponentAttributesType: {},
      selectTypeAttrs: [],
      selectLookupDict: {},
      colorCodes: {},
      canvas: '',
      canvas_scroll_controller_node: null,
      canvas_height: 0,
      canvas_width: 0,
      current_rect: undefined,
      copied_rect: undefined,
      customError: '',
      last_active_editor_row: null,

      // Drawing parameters
      mouse_left_down: false,
      new_rect: undefined,
      is_drawing: true,
      selectComponent: false,
      selectedComponent: '',
      _selectedComponent: null,

      viewConfiguration: false,

      // bird viewer
      bird_viewer: undefined,
      view_area: undefined,
      view_area_move_factor: undefined,
      scroller_move_factor: undefined,
      outline_X: 0,
      outline_Y: 0,
      outline_tmp_X: 0,
      outline_tmp_Y: 0,

      activeGroup: 'standard',
      new_rect_Uuid: undefined,
      activeEditorComponent: this.props.activeEditorComponent,
      activeEditorComponentType: this.props.activeEditorComponentType,
      editor_cols_search: '',
      viewThumbnails: true,
      activeEditingCell: null,
      editorHeight: 400,
      editorFullWidth: false,
      editorSelectionList: [],

      processTextList: [],
      processTextListCreated: [],

      lineBreakMessage: false,
      lineBreakSwitchMessage: false,
      lineBreakCreationMessage: false,
      lineBreakMode: false,
      lineBreakRectsList: [],

      deleteRectsWarningMessage: false,
      delete_rects_list: [],
      editorWarning: false,

      MovementMarkupObj: {
        Uuid: null,
        MovementType: null,
        Source: null,
        Target: null,
      },
      MovementCompletedMessage: 'After source and target points are marked for all the movement markups, close the IUI and re-process drawing in UI',

      updated_visiblity_group: [],
      toggleTemplateDropdown: '',
      add_tagging_config_bbox: '',
      view_current_rect: false,
      minimize_configure_box: false,
    }
  }

  componentDidMount = async () => {
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerColor = 'blue'
    fabric.Object.prototype.cornerStyle = 'circle'
    fabric.Object.prototype.cornerSize = 5
    fabric.Object.prototype.hasRotatingPoint = false
    fabric.Object.prototype.strokeUniform = true
    fabric.Object.prototype.borderScaleFactor = 2

    document.onkeydown = this.on_key_down_handler
    document.onkeyup = this.shortcut_handler

    await this.setConstants()
    this.zoom(null, this.props.zoom)
  }

  componentDidUpdate = async (props) => {
    if (
      ('fetchingData' in props && this.props.fetchingData !== props.fetchingData) ||
      ('in_progress' in props && this.props.in_progress !== props.in_progress) ||
      props.allow_edit !== this.props.allow_edit
    ) {
      if (this.state.canvas) {
        this.state.canvas.dispose()
        await this.setState({ canvas: null })
      }
      await this.setConstants()
    }

    if (props.switchListUpdated !== this.props.switchListUpdated) {
      this.apply_annotations(true)
    }

    if (props.zoomIn !== this.props.zoomIn) {
      this.zoom('in')
    }

    if (props.zoomOut !== this.props.zoomOut) {
      this.zoom('out')
    }

    if (props.zoom !== this.props.zoom) {
      this.zoom(null, this.props.zoom)
    }

    if (props.activeEditorComponent !== this.props.activeEditorComponent || props.viewEditor !== this.props.viewEditor) {
      this.setState(
        {
          activeEditorComponent: this.props.activeEditorComponent,
          activeEditorComponentType: this.props.activeEditorComponentType,
          editorSelectionList: [],
        },
        () => {
          if (this.props.viewEditor && this.state.viewThumbnails) {
            this.renderCanvasThumbnial()
          }
        }
      )
    }

    if (props.remapIUIData !== this.props.remapIUIData && this.props.remapIUIDataMethod) {
      this.props.remapIUIDataMethod(this.state.data, this.state.processTextList)
    }

    if (props.updateData !== this.props.updateData && this.props.updateIUIData) {
      this.props.updateIUIData(this.state.data, false, this.state.processTextList, false, this.state.lineBreakRectsList)
    }

    if (props.enhance !== this.props.enhance && this.props.updateIUIData) {
      this.props.updateIUIData(this.state.data, false, this.state.processTextList, true, this.state.lineBreakRectsList)
    }

    if (props.undo_enhance !== this.props.undo_enhance && this.props.revertEnhancedData) {
      this.props.revertEnhancedData(this.state.data, false, this.state.processTextList, true, this.state.lineBreakRectsList)
    }

    if (props.processText !== this.props.processText && this.props.updateIUIData) {
      this.props.updateIUIData(this.state.data, true, this.state.processTextList, false, this.state.lineBreakRectsList)
    }

    if (this.props.whiteOutDetected !== props.whiteOutDetected) {
      this.set_rect_group_color_to_white()
    }

    if (this.props.download !== props.download) {
      this.download()
    }
  }

  setConstants = async () => {
    await this.setState({
      schema: this.props.schema,
      colorCodes: this.props.colorCodes,
      ComponentAttributesType: this.props.ComponentAttributesType,
      selectTypeAttrs: this.props.selectTypeAttrs,
      selectLookupDict: this.props.selectLookupDict,
      canvas_height: this.props.img_height,
      canvas_width: this.props.img_width,
      lineBreakRectsList: this.props.lineBreakRectsList ? this.props.lineBreakRectsList : this.state.lineBreakRectsList,
      canvas_scroll_controller_node: document.getElementById('canvas-scroll-controller'),
    })

    this.generateData()
  }

  generateData = async () => {
    await this.setState({
      data: this.props.drawingData,
    })

    await this.setState({ canvas: this.initCanvas() })
    await this.setBackgroundImage()
    this.apply_annotations()
  }

  initCanvas = () => {
    let canvas = new fabric.Canvas('canvas', {
      height: this.state.canvas_height,
      width: this.state.canvas_width,
      backgroundColor: '#fff',
    })

    canvas.on({
      'mouse:down': this.onMouseDown,
      'mouse:up': this.onMouseUp,
      'mouse:wheel': this.onMouseWheel,
      'object:moving': this.onObjectMoved,
      'object:scaling': this.onObjectScaled,
    })

    return canvas
  }

  initBirdViewer = async () => {
    const bird_view_thumbnail_img_node = document.getElementById('bird_view_thumbnail_img')
    let bird_viewer = new fabric.Canvas('bird-viewer', {
      width: bird_view_thumbnail_img_node.width,
      height: bird_view_thumbnail_img_node.height,
    })
    bird_viewer.selection = false

    let view_area = new fabric.Rect({
      left: 0,
      top: 0,
      fill: 'rgba(0,0,0,0)',
      width: (bird_view_thumbnail_img_node.width / this.state.canvas.width) * this.state.canvas_scroll_controller_node.offsetWidth,
      height: (bird_view_thumbnail_img_node.height / this.state.canvas.height) * this.state.canvas_scroll_controller_node.offsetHeight,
      strokeWidth: 1,
      stroke: 'blue',
      hasControls: false,
    })

    let view_area_move_factor = (view_area.width * view_area.scaleX) / this.state.canvas_scroll_controller_node.offsetWidth
    let scroller_move_factor = this.state.canvas_scroll_controller_node.offsetWidth / (view_area.width * view_area.scaleX)
    bird_viewer.add(view_area)
    bird_viewer.requestRenderAll()

    bird_viewer.on({
      'object:moving': this.onObjectMovedBirdViewer,
    })

    await this.setState({
      bird_viewer: bird_viewer,
      view_area: view_area,
      view_area_move_factor: view_area_move_factor,
      scroller_move_factor: scroller_move_factor,
    })
  }

  copy_BBox = (rect) => {
    if (rect && this.state.ComponentAttributesType) {
      const left = rect.left || 0
      const top = rect.top || 0
      const width = rect.width || 0
      const height = rect.height || 0

      if (!(width && height)) {
        return undefined
      }

      const copied_rect = new fabric.Rect({
        left: left + 8,
        top: top + 8,
        fill: 'rgba(0,0,0,0)',
        width: width,
        height: height,
        strokeWidth: 2,
        stroke: this.state.colorCodes[rect.type.split('.')[0]],
      })
      copied_rect.alive = true
      copied_rect.type = rect.type

      let no_copy = ['Number', 'Uuid', 'left', 'top', 'Others']

      for (const key of Object.keys(this.state.ComponentAttributesType[rect.type])) {
        if (!no_copy.includes(key)) {
          copied_rect[key] = rect[key]
        }

        if (key === 'Others' && typeof rect[key] === 'object') {
          let _obj = {}
          Object.keys(rect[key]).forEach((key) => {
            _obj[key] = ''
          })
          copied_rect[key] = _obj
        }
      }

      return copied_rect
    }
  }

  copy_current_rect = () => {
    let { current_rect, copied_rect, data } = this.state
    if (current_rect) {
      copied_rect = this.copy_BBox(current_rect)

      let data_path = copied_rect.type.split('.')
      data_path.forEach((component) => {
        data = data[component]
      })
      let max_sn = Math.max.apply(
        Math,
        data.map(function (o) {
          return o.Number
        })
      )
      copied_rect.Number = max_sn + 1
      copied_rect.Uuid = this.uuid()
    }
    this.setState({ copied_rect: copied_rect })
  }

  paste_current_rect = () => {
    let { copied_rect, canvas, data } = this.state
    if (copied_rect) {
      canvas.add(copied_rect)
      canvas.setActiveObject(copied_rect)
      let data_path = copied_rect.type.split('.')
      try {
        if (data_path.length === 1) {
          data[data_path[0]].push(copied_rect)
        } else if (data_path.length === 2) {
          data[data_path[0]][data_path[1]].push(copied_rect)
        } else if (data_path.length === 3) {
          data[data_path[0]][data_path[1]][data_path[2]].push(copied_rect)
        } else if (data_path.length === 4) {
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].push(copied_rect)
        }
      } catch (err) {
        this.setState({
          customError: `${err}, Path: ${copied_rect.type}`,
        })
        return
      }

      this.setState({
        current_rect: copied_rect,
        copied_rect: undefined,
        data: data,
      })
    }
  }

  move_1px = (dir) => {
    let { current_rect, canvas } = this.state
    if (current_rect) {
      current_rect.left = current_rect.left + dir.x
      current_rect.top = current_rect.top + dir.y
      this.setState({ current_rect: current_rect }, this.update_current_rect_coord)
      canvas.requestRenderAll()
    }
  }

  on_key_down_handler = (e) => {
    console.log('on_key_down_handler triggered')

    if (e.target.tagName !== 'BODY') return
    let { current_rect } = this.state
    if (e.ctrlKey) {
      if (e.key === 'd' && current_rect) {
        this.update_delete_rects_list(current_rect.Uuid)
      } else if (e.key === '+') {
        this.zoom('in')
      } else if (e.key === '-') {
        this.zoom('out')
      }
    }

    // Prevent the default behavior (e.g., opening the browser's bookmark dialog)
    e.preventDefault()
  }

  shortcut_handler = async (e) => {
    if (e.target.tagName !== 'BODY') return
    const key = e.key

    switch (key) {
      case 'Delete':
        if (!this.props.viewEditor) {
          const { delete_rects_list } = this.state

          if (delete_rects_list.length) {
            delete_rects_list.length > 6 ? this.setState({ deleteRectsWarningMessage: true, ctrlDeleteWarning: true }) : this.delete_selected_rects(delete_rects_list)
          }

          this.delete_current_and_child_rects()
        }
        break
      case 'c':
        this.copy_current_rect()
        break
      case 'v':
        this.paste_current_rect()
        break
      case 'ArrowRight':
        this.move_1px({
          x: 1,
          y: 0,
        })
        break
      case 'ArrowLeft':
        this.move_1px({
          x: -1,
          y: 0,
        })
        break
      case 'ArrowUp':
        this.move_1px({
          x: 0,
          y: -1,
        })
        break
      case 'ArrowDown':
        this.move_1px({
          x: 0,
          y: 1,
        })
        break
      default:
        break
    }
    e.preventDefault()
  }

  setBackgroundImage = async () => {
    let canvas = this.state.canvas
    await canvas.setBackgroundImage(this.props.img_url, canvas.renderAll.bind(canvas), {
      width: canvas.width,
      height: canvas.height,
      // Needed to position backgroundImage at 0/0
      originX: 'left',
      originY: 'top',
    })
  }

  apply_annotations = (destroy = false) => {
    if (destroy) {
      this.state.canvas.getObjects().forEach((obj) => {
        this.state.canvas.remove(obj)
      })
      this.state.canvas.discardActiveObject().renderAll()
    }

    for (const value of Object.values(this.state.schema)) {
      let path = value.split('.')
      let data = this.state.data
      path.forEach((component) => {
        if (component in data) {
          data = data[component]
        }
      })

      if (Array.isArray(data)) {
        let colorCode = this.state.colorCodes[path[0]]
        let isVisible = this.props.toggledSwitchList.includes(value)
        data.forEach((bbox) => {
          if ('isVisible' in bbox) {
            isVisible = bbox.isVisible
          }
          let rect = this.BBox(bbox, colorCode, isVisible)
          rect.type = value
          this.state.canvas.add(rect)
        })
      }
    }

    this.state.lineBreakRectsList.forEach((bbox) => {
      let colorCode = this.state.colorCodes['PipingComponent']
      let rect = this.BBox(bbox, colorCode, true, true)
      this.state.canvas.add(rect)
    })
  }

  BBox = (bbox_info, color, isVisible, fill = false) => {
    let { delete_rects_list } = this.state
    let left = parseInt(bbox_info['MinX']) || 0
    let top = parseInt(bbox_info['MinY']) || 0
    let width = parseInt(bbox_info['MaxX']) - left || 0
    let height = parseInt(bbox_info['MaxY']) - top || 0
    if (height === 0) {
      height = 14
      top -= 7
    }
    if (width === 0) {
      width = 14
      left -= 7
    }
    if (!(width && height)) {
      return undefined
    }
    const rect = new fabric.Rect({
      left: left,
      top: top,
      fill: delete_rects_list.includes(bbox_info.Uuid) ? 'rgba(250,2,23,0.3)' : fill ? color : 'rgba(0,0,0,0)',
      width: width,
      height: height,
      strokeWidth: 2,
      stroke: color,
    })

    rect.setControlsVisibility({
      mt: true,
      mb: true,
      ml: true,
      mr: true,
      mtr: true,
      tl: false,
      bl: false,
      tr: false,
      br: false,
    })

    const pairs = Object.entries(bbox_info)
    pairs.forEach((pair) => {
      if (pair[0] === 'OtherAttributes') {
        const { ConnPrefix, ConnSerialNumber, Rating, Size } = pair[1]
        rect.ConnPrefix = ConnPrefix
        rect.ConnSerialNumber = ConnSerialNumber
        rect.Rating = Rating
        rect.Size = Size
      } else {
        rect[pair[0]] = pair[1]
      }
    })

    rect.alive = true
    rect.visible = isVisible
    rect.lockMovementX = !this.props.allow_edit
    rect.lockMovementY = !this.props.allow_edit
    rect.lockScalingX = !this.props.allow_edit
    rect.lockScalingY = !this.props.allow_edit
    return rect
  }

  uuid = () => {
    return 'xxxxxxxxxxxxyxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : r & 0x3 ? r & 0x3 : 0x8
      return v.toString(16)
    })
  }

  delete_rect = async (Uuid) => {
    console.log('delete_rect triggered')
    let { canvas } = this.state
    const index = canvas.getObjects().findIndex((obj) => obj.Uuid === Uuid)
    if (index > -1) {
      let rect = canvas.getObjects()[index]
      if (rect && rect.visible) {
        this.delete_current_rect(rect, true)
      }
    }
  }

  delete_selected_rects = async (Uuids) => {
    Uuids.forEach(async (Uuid) => {
      await this.delete_rect(Uuid)
    })
    await this.setState({ editorSelectionList: [] })
    if (this.state.viewThumbnails) {
      this.renderCanvasThumbnial()
    }
  }

  delete_current_and_child_rects = async (confirmation = false) => {
    let { current_rect, schema } = this.state

    if (!current_rect) {
      return
    }

    try {
      let rect = current_rect
      let _data = []
      for (const value of Object.values(schema)) {
        let path = value.split('.')
        let data = this.state.data
        path.forEach((component) => {
          if (component in data) {
            data = data[component]
          }
        })
        if (Array.isArray(data)) {
          data = data.filter((bbox) => rect.MinX < bbox.MinX && rect.MaxX > bbox.MaxX && rect.MinY < bbox.MinY && rect.MaxY > bbox.MaxY)
          _data = [..._data, ...data]
        }
      }

      if (!_data.length) {
        this.delete_current_rect()
        return
      }

      _data.forEach(async (bbox, index) => {
        if (_data.length < 6) {
          await this.delete_rect(bbox.Uuid)
          if (index + 1 === _data.length) {
            this.delete_current_rect()
          }
        } else {
          if (confirmation) {
            await this.delete_rect(bbox.Uuid)
            if (index + 1 === _data.length) {
              this.delete_current_rect()
            }
          } else {
            this.setState({
              deleteRectsWarningMessage: true,
              editorWarning: false,
            })
          }
        }
      })
    } catch (err) {
      this.setState({ customError: `${err}` })
      return
    }
  }

  update_delete_rects_list = async (Uuid) => {
    let { delete_rects_list } = this.state

    if (delete_rects_list.includes(Uuid)) {
      delete_rects_list = delete_rects_list.filter((_Uuid) => _Uuid !== Uuid)
    } else {
      delete_rects_list.push(Uuid)
    }

    await this.setState({ delete_rects_list: delete_rects_list })
    this.apply_annotations(true)
  }

  delete_current_rect = async (rect, config_mode_deletion = false) => {
    console.log('delete_current_rect triggered')
    let { current_rect, canvas, processTextList, processTextListCreated, updated_visiblity_group } = this.state

    if (rect) {
      current_rect = rect
    }

    if (current_rect && current_rect.alive) {
      let data_path = current_rect.type.split('.')
      let data = this.state.data
      try {
        if (data_path.length === 1) {
          let rect_list = data[data_path[0]].filter((rect) => rect.Uuid !== current_rect.Uuid)
          data[data_path[0]] = rect_list
        } else if (data_path.length === 2) {
          let rect_list = data[data_path[0]][data_path[1]].filter((rect) => rect.Uuid !== current_rect.Uuid)
          data[data_path[0]][data_path[1]] = rect_list
        } else if (data_path.length === 3) {
          let rect_list = data[data_path[0]][data_path[1]][data_path[2]].filter((rect) => rect.Uuid !== current_rect.Uuid)
          data[data_path[0]][data_path[1]][data_path[2]] = rect_list
        } else if (data_path.length === 4) {
          let rect_list = data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].filter((rect) => rect.Uuid !== current_rect.Uuid)
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]] = rect_list
        }
      } catch (err) {
        this.setState({
          customError: `${err}, Path: ${this.state.current_rect.type}`,
        })
        return
      }

      if (this.props.suggestions && !config_mode_deletion) {
        await this.props.updateModelConfigurationData(data, current_rect)
      }

      processTextList = processTextList.filter((uuid) => uuid !== current_rect.Uuid)
      processTextListCreated = processTextListCreated.filter((uuid) => uuid !== current_rect.Uuid)
      updated_visiblity_group = updated_visiblity_group.filter((uuid) => uuid !== current_rect.Uuid)

      current_rect.alive = false
      canvas.remove(current_rect)
      await this.setState({
        data: data,
        processTextList: processTextList,
        processTextListCreated: processTextListCreated,
        updated_visiblity_group: updated_visiblity_group,
      })

      this.update_rects_visibility(current_rect, false)

      if (!config_mode_deletion) {
        await this.setState({
          current_rect: undefined,
          activeGroup: 'standard',
        })
      }
    }
  }

  // setup listeners
  // http://fabricjs.com/docs/fabric.Canvas.html
  /**
   * Fabric Object Listener
   * Event: mouse:down
   * Actions: String Drawing
   */
  onMouseDown = async (opt) => {
    console.log('canvas.on(mouse:down) triggered')

    this.setState({ selectComponent: false, activeEditingCell: null })
    let mouse_event = opt.e

    //if user selected an empty space
    if (!mouse_event.ctrlKey && !opt.target) {
      await this.setState({ is_drawing: true })
    }

    if (this.state.is_drawing && !mouse_event.ctrlKey) {
      const pointer = this.state.canvas.getPointer(mouse_event)
      let origX = pointer.x
      let origY = pointer.y
      let new_rect = new fabric.Rect({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: 'rgba(255,0,0,0.0)',
        strokeWidth: 2,
        stroke: 'red',
      })

      new_rect.setControlsVisibility({
        mt: true,
        mb: true,
        ml: true,
        mr: true,
        mtr: true,
        tl: false,
        bl: false,
        tr: false,
        br: false,
      })

      await this.setState({
        new_rect: new_rect,
        origX: origX,
        origY: origY,
      })
    }
  }

  /**
   * Fabric Object Listener
   * Event: mouse:up
   * Actions: Drawing End OR Canvas Component is selected.
   */
  onMouseUp = async (opt) => {
    console.log('canvas.on(mouse:up) triggered')
    try {
      const { new_rect, is_drawing, origX, origY, canvas, lineBreakRectsList } = this.state

      if (is_drawing && new_rect) {
        const pointer = canvas.getPointer(opt.e)
        if (origX > pointer.x) {
          new_rect.set({
            left: Math.abs(pointer.x),
          })
        }
        if (origY > pointer.y) {
          new_rect.set({
            top: Math.abs(pointer.y),
          })
        }
        new_rect.set({
          width: Math.abs(origX - pointer.x),
        })

        new_rect.set({
          height: Math.abs(origY - pointer.y),
        })

        if (new_rect.width !== 0 && new_rect.height !== 0) {
          const coords = this.get_rect_coordinates(new_rect)
          new_rect.MinX = coords.MinX
          new_rect.MinY = coords.MinY
          new_rect.MaxX = coords.MaxX
          new_rect.MaxY = coords.MaxY
          if (this.state.add_tagging_config_bbox && !this.check_if_new_rect_inside_parent_rect(new_rect)) {
            this.setState({
              customError: 'Please draw bbox inside parent config bbox region',
            })
            return
          }

          new_rect.Uuid = this.uuid()

          if (!this.state.lineBreakMode) {
            this.setState({
              selectComponent: this.state.MovementMarkupObj.Source ? false : this.props.allow_edit,
              new_rect: new_rect,
              new_rect_Uuid: new_rect.Uuid,
            })

            if (this.state.MovementMarkupObj.Source) {
              this.add_new_rect()
            }
          } else {
            let current_rect = this.state.current_rect
            const linebreak_rect_index = lineBreakRectsList.findIndex((rect) => rect.parent_Uuid === current_rect.Uuid)
            if (linebreak_rect_index > -1) {
              lineBreakRectsList.splice(linebreak_rect_index, 1)
            }

            const lineBreakRectObj = {
              MaxX: new_rect.MaxX,
              MaxY: new_rect.MaxY,
              MinX: new_rect.MinX,
              MinY: new_rect.MinY,
              Uuid: new_rect.Uuid,
              parent_Uuid: current_rect.Uuid,
            }
            lineBreakRectsList.push(lineBreakRectObj)
            await this.setState({ lineBreakRectsList: lineBreakRectsList })
            this.apply_annotations(true)

            if ('BreakpointX' in current_rect && 'BreakpointY' in current_rect) {
              current_rect['BreakpointX'] = Math.round((new_rect.MinX + new_rect.MaxX) / 2)
              current_rect['BreakpointY'] = Math.round((new_rect.MinY + new_rect.MaxY) / 2)
              await this.setState({
                current_rect: current_rect,
                lineBreakCreationMessage: true,
              })
            }
          }
        } else {
          //clicked empty space
          await this.update_current_rect()

          if (this.state.lineBreakMode && this.state.current_rect && this.state.current_rect.type.includes('LineBreak')) {
            this.setState({ lineBreakSwitchMessage: true })
            return
          }

          if (!this.state.add_tagging_config_bbox) {
            this.setState({
              current_rect: undefined,
              activeGroup: 'standard',
            })
          }

          this.setState({
            new_rect: undefined,
            selectComponent: false,
          })
        }
        this.setState({
          is_drawing: false,
        })
      }

      // selected a bbox
      if (opt.target) {
        const { type } = opt.target
        if (type && type !== 'rect' && type !== 'activeSelection') {
          let current_rect = this.state.canvas.getActiveObject()
          if (current_rect && current_rect.type === 'LineBreakIndicator') {
            return
          }
          if (this.state.current_rect && current_rect.Uuid === this.state.current_rect.Uuid) {
            return
          }
          if (current_rect && current_rect.selectable) {
            await this.setState({ current_rect: current_rect })
          }

          await this.setState({ updated_visiblity_group: [] })
          if (this.props.update_rects_visibility_on_adding_bbox) {
            this.update_rects_visibility(this.state.current_rect)
          }
          if (this.props.configurableGroup && this.props.configurableGroup.includes(this.state.current_rect.type.split('.')[this.state.current_rect.type.split('.').length - 1])) {
            if (this.props.taggingConfigurationConfigure) {
              let Uuids = this.state.updated_visiblity_group
              Uuids = Uuids.filter((Uuid) => Uuid !== this.state.current_rect.Uuid)
              let tagType = this.state.current_rect.type.split('.')[this.state.current_rect.type.split('.').length - 1]
              this.props.taggingConfigurationConfigure(Uuids, tagType, this.state.current_rect.Uuid)
            }
            await this.setState({
              viewConfiguration: true,
              selectComponent: false,
            })
          }

          this.openAndHighlightCorrespondingEditorRow()
        }
      }
    } catch (err) {
      this.setState({
        customError: `${err}`,
      })
      return
    }
  }

  /**
   * Fabric Object Listener
   * Event: mouse:wheel
   * Actions: Canvas Zooming
   */
  onMouseWheel = async (opt) => {
    console.log('canvas.on(mouse:wheel) triggered')
    if (opt.e.ctrlKey) {
      opt.e.preventDefault()
      const delta = opt.e.deltaY
      if (delta < 0) {
        this.zoom('in')
      } else {
        this.zoom('out')
      }
    }
  }

  /**
   * Fabric Object Listener
   * Event: object:moved
   * Actions: when the canvas component is moving,update coordinates
   */
  onObjectMoved = async (opt) => {
    if (!this.props.allow_edit) {
      this.setState({
        customError: `Moving the bbox will have no effect on the data if drawing is in check in mode`,
      })
      return
    }

    console.log('canvas.on(object:moved) triggered')
    if (opt.target && opt.target.alive) {
      let current_rect = this.state.canvas.getActiveObject()
      if (current_rect && current_rect.selectable) {
        const coords = this.get_rect_coordinates(opt.target)
        current_rect.MinX = coords.MinX
        current_rect.MinY = coords.MinY
        current_rect.MaxX = coords.MaxX
        current_rect.MaxY = coords.MaxY
        await this.setState({ current_rect: current_rect })
      }
    }
  }

  /**
   * Fabric Object Listener
   * Event: object:scaled
   * Actions: when the canvas component is scaling,update coordinates
   */
  onObjectScaled = async (opt) => {
    if (!this.props.allow_edit) {
      return
    }

    console.log('canvas.on(object:scaled) triggered')
    if (opt.target) {
      let current_rect = this.state.canvas.getActiveObject()
      if (current_rect && current_rect.selectable) {
        const coords = this.get_rect_coordinates(opt.target)
        current_rect.MinX = coords.MinX
        current_rect.MinY = coords.MinY
        current_rect.MaxX = coords.MaxX
        current_rect.MaxY = coords.MaxY
        await this.setState({ current_rect: current_rect })
      }
    }
  }

  // bird viewer
  // force window inside the outline viewer
  /**
   * Fabric Object Listener
   * Event: object:moving
   * Actions: When the outline view is moving, the main canvas will move simultaneously
   */
  onObjectMovedBirdViewer = async (e) => {
    let { bird_viewer, scroller_move_factor, canvas_scroll_controller_node } = this.state
    if (e.target.left < 0) e.target.left = 0
    if (e.target.left > bird_viewer.width - e.target.width * e.target.scaleX) e.target.left = bird_viewer.width - e.target.width * e.target.scaleX
    if (e.target.top < 0) e.target.top = 0
    if (e.target.top > bird_viewer.height - e.target.height * e.target.scaleY) e.target.top = bird_viewer.height - e.target.height * e.target.scaleY

    const x = e.target.left * scroller_move_factor
    const y = e.target.top * scroller_move_factor
    canvas_scroll_controller_node.scroll(x, y)
  }

  obj_type_converter = (rect, data_path_str) => {
    if (!this.state.ComponentAttributesType) {
      return
    }
    let rect_data = {}
    for (const [key, value] of Object.entries(this.state.ComponentAttributesType[data_path_str])) {
      if (value === 'number' && key !== 'Uuid') {
        rect_data[key] = parseInt(rect[key])
      } else if (value === 'Boolean') {
        rect_data[key] = rect[key] === 'true'
      } else if (value === 'object') {
        if (typeof rect[key] === 'object') {
          rect_data[key] = this.state.current_rect[key]
        } else {
          rect_data[key] = JSON.parse(this.state.current_rect[key])
        }
      } else if (value === 'array') {
        if (rect[key] && Array.isArray(rect[key])) {
          rect_data[key] = rect[key]
        } else if (rect[key]) {
          rect_data[key] = rect[key].split(',')
        } else {
          rect_data[key] = []
        }
      } else {
        rect_data[key] = rect[key]
      }
    }
    return rect_data
  }

  add_new_rect = async (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!this.state.ComponentAttributesType) {
      return
    }
    if (!this.state.add_tagging_config_bbox) {
      await this.setState({ updated_visiblity_group: [] })
    }

    let new_rect = this.state.new_rect
    if (this.state.selectedComponent) {
      if (this.state.selectedComponent.includes('MovementType')) {
        this.build_movement_data()
        return
      }

      if (this.state.selectedComponent === 'LineBreaks' && this.props.line_break_feature) {
        this.setState({ lineBreakMessage: true })
      }

      let data_path_str = this.state.schema[this.state.selectedComponent]
      for (const [key, value] of Object.entries(this.state.ComponentAttributesType[data_path_str])) {
        if (!(key in new_rect)) {
          if (value === 'object') {
            new_rect[key] = {}
          } else if (value === 'Boolean') {
            new_rect[key] = false
          } else if (value === 'array') {
            new_rect[key] = []
          } else if (value === 'number') {
            if (['ScaleX', 'ScaleY'].includes(key)) {
              new_rect[key] = 1
            } else {
              new_rect[key] = 0
            }
          } else {
            new_rect[key] = ''
          }
        }
      }

      new_rect.stroke = this.state.colorCodes[data_path_str.split('.')[0]]
      new_rect.type = data_path_str
      new_rect.alive = true

      let rect_data = {}
      for (const key of Object.keys(this.state.ComponentAttributesType[data_path_str])) {
        rect_data[key] = new_rect[key]
      }

      let data_path = data_path_str.split('.')
      let data = this.state.data

      try {
        if (data_path.length === 1) {
          let max_sn = Math.max.apply(
            Math,
            data[data_path[0]].map(function (o) {
              return o.Number
            })
          )
          if (max_sn < 0) {
            max_sn = 0
          }
          rect_data.Number = max_sn + 1
          new_rect.Number = rect_data.Number
          data[data_path[0]].push(rect_data)
        } else if (data_path.length === 2) {
          let max_sn = Math.max.apply(
            Math,
            data[data_path[0]][data_path[1]].map(function (o) {
              return o.Number
            })
          )
          if (max_sn < 0) {
            max_sn = 0
          }
          rect_data.Number = max_sn + 1
          new_rect.Number = rect_data.Number
          data[data_path[0]][data_path[1]].push(rect_data)
        } else if (data_path.length === 3) {
          let max_sn = Math.max.apply(
            Math,
            data[data_path[0]][data_path[1]][data_path[2]].map(function (o) {
              return o.Number
            })
          )
          if (max_sn < 0) {
            max_sn = 0
          }
          rect_data.Number = max_sn + 1
          new_rect.Number = rect_data.Number
          data[data_path[0]][data_path[1]][data_path[2]].push(rect_data)
        } else if (data_path.length === 4) {
          let max_sn = Math.max.apply(
            Math,
            data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].map(function (o) {
              return o.Number
            })
          )
          if (max_sn < 0) {
            max_sn = 0
          }
          rect_data.Number = max_sn + 1
          new_rect.Number = rect_data.Number
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].push(rect_data)
        }
      } catch (err) {
        this.setState({
          customError: `${err}, Path: ${data_path_str}`,
        })
        return
      }

      await this.setState({ data: data })
      this.state.canvas.add(new_rect)

      if (this.state.add_tagging_config_bbox && this.state.current_rect) {
        await this.setState({ copied_rect: this.state.current_rect })
      }

      await this.setState({
        new_rect: undefined,
        current_rect: new_rect,
        selectComponent: false,
        data: data,
      })

      if (!this.state.add_tagging_config_bbox) {
        if (this.props.update_rects_visibility_on_adding_bbox) {
          this.update_rects_visibility(new_rect)
        }
        if (this.props.configurableGroup && this.props.configurableGroup.includes(this.state.current_rect.type.split('.')[this.state.current_rect.type.split('.').length - 1])) {
          if (this.props.taggingConfigurationConfigure) {
            let Uuids = this.state.updated_visiblity_group
            Uuids = Uuids.filter((Uuid) => Uuid !== this.state.current_rect.Uuid)
            let tagType = this.state.current_rect.type.split('.')[this.state.current_rect.type.split('.').length - 1]
            this.props.taggingConfigurationConfigure(Uuids, tagType, new_rect.Uuid)
          }
          await this.setState({
            viewConfiguration: true,
            selectComponent: false,
          })
        }
      } else {
        if (this.props.addTaggingConfigBboxUuid) {
          this.props.addTaggingConfigBboxUuid(this.state.add_tagging_config_bbox, new_rect.Uuid)
          let updated_visiblity_group = this.state.updated_visiblity_group
          updated_visiblity_group.push(new_rect.Uuid)
          this.update_rects_visibility(null, true)
          await this.update_current_rect(false)
          await this.setState({
            updated_visiblity_group: updated_visiblity_group,
            add_tagging_config_bbox: '',
            current_rect: this.state.copied_rect,
            copied_rect: undefined,
          })
        }
      }

      let processTextListCreated = this.state.processTextListCreated
      if (data_path_str.includes('Text')) {
        processTextListCreated.push(new_rect.Uuid)
      }
      this.setState({
        processTextListCreated: processTextListCreated,
      })
    }
  }

  check_if_new_rect_inside_parent_rect = (new_rect) => {
    let { current_rect } = this.state
    if (current_rect.MinX <= new_rect.MinX && current_rect.MaxX >= new_rect.MaxX && current_rect.MinY <= new_rect.MinY && current_rect.MaxY >= new_rect.MaxY) {
      return true
    }
    return false
  }

  update_rects_visibility = async (rect, visibility = true, pop = true) => {
    let _data = this.state.data
    let { updated_visiblity_group } = this.state
    for (const value of Object.values(this.state.schema)) {
      let path = value.split('.')
      let data = this.state.data
      path.forEach((component) => {
        if (component in data) {
          data = data[component]
        }
      })

      if (Array.isArray(data)) {
        let _updated_visiblity_group = updated_visiblity_group
        data.forEach((bbox) => {
          if (rect) {
            if (rect.MinX <= bbox.MinX && rect.MaxX >= bbox.MaxX && rect.MinY <= bbox.MinY && rect.MaxY >= bbox.MaxY) {
              if (visibility) {
                bbox.isVisible = true
                _updated_visiblity_group.push(bbox.Uuid)
              } else {
                if (_updated_visiblity_group.includes(bbox.Uuid)) {
                  bbox.isVisible = false
                  if (pop) {
                    _updated_visiblity_group = _updated_visiblity_group.filter((Uuid) => Uuid !== bbox.Uuid)
                  }
                }
              }
            }
          } else {
            if (_updated_visiblity_group.includes(bbox.Uuid)) {
              bbox.isVisible = visibility
            }
          }
        })
        updated_visiblity_group = _updated_visiblity_group
      }

      try {
        if (path.length === 1) {
          _data[path[0]] = data
        } else if (path.length === 2) {
          _data[path[0]][path[1]] = data
        } else if (path.length === 3) {
          _data[path[0]][path[1]][path[2]] = data
        } else if (path.length === 4) {
          _data[path[0]][path[1]][path[2]][path[3]] = data
        } else if (path.length === 5) {
          _data[path[0]][path[1]][path[2]][path[3]][path[4]] = data
        }
      } catch (err) {
        this.setState({ customError: `${err}, Path: ${value}` })
        return
      }
    }

    await this.setState({
      data: _data,
      updated_visiblity_group: updated_visiblity_group,
    })
    this.apply_annotations(true)
  }

  update_current_rect = async (showSuggestions = false) => {
    console.log('update_current_rect triggered')
    if (this.state.current_rect) {
      let data_path_str = this.state.current_rect.type
      let data_path = data_path_str.split('.')
      let data = this.state.data

      let rect_data = this.obj_type_converter(this.state.current_rect, data_path_str)

      try {
        if (data_path.length === 1) {
          data[data_path[0]].forEach((obj, index) => {
            if (obj.Uuid === this.state.current_rect.Uuid) {
              data[data_path[0]][index] = rect_data
            }
          })
        } else if (data_path.length === 2) {
          data[data_path[0]][data_path[1]].forEach((obj, index) => {
            if (obj.Uuid === this.state.current_rect.Uuid) {
              data[data_path[0]][data_path[1]][index] = rect_data
            }
          })
        } else if (data_path.length === 3) {
          data[data_path[0]][data_path[1]][data_path[2]].forEach((obj, index) => {
            if (obj.Uuid === this.state.current_rect.Uuid) {
              data[data_path[0]][data_path[1]][data_path[2]][index] = rect_data
            }
          })
        } else if (data_path.length === 4) {
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].forEach((obj, index) => {
            if (obj.Uuid === this.state.current_rect.Uuid) {
              data[data_path[0]][data_path[1]][data_path[2]][data_path[3]][index] = rect_data
            }
          })
        }
      } catch (err) {
        this.setState({
          customError: `${err}, Path: ${data_path_str}`,
        })
        return
      }

      if (this.props.suggestions) {
        this.props.updateModelConfigurationData(data)
        if (showSuggestions) {
          this.props.showSuggestions(this.state.new_rect_Uuid, data, null, null)
        }
      } else {
        await this.setState({ current_rect: undefined })
      }

      await this.setState({ data: data, activeGroup: 'standard' })
    }
  }

  get_rect_coordinates = (rect) => {
    const rtn_dict = {}
    rtn_dict.MinX = Math.round(rect.left)
    rtn_dict.MinY = Math.round(rect.top)
    rtn_dict.MaxX = Math.round(rect.left + rect.width * rect.scaleX)
    rtn_dict.MaxY = Math.round(rect.top + rect.height * rect.scaleY)
    return rtn_dict
  }

  // when editor record is selected
  // select the correspoended bbox
  component_list_selected = async (Uuid) => {
    console.log('component_list_selected triggered')
    let { canvas, canvas_scroll_controller_node, view_area, canvas_height, canvas_width, bird_viewer } = this.state

    const index = canvas.getObjects().findIndex((obj) => obj.Uuid === Uuid)
    if (index > -1) {
      canvas.setActiveObject(canvas.getObjects()[index])
      canvas.requestRenderAll()
    }

    let current_rect = canvas.getActiveObject()

    if (canvas.getZoom() !== 1) {
      canvas.setZoom(1)
      canvas.setHeight(canvas_height)
      canvas.setWidth(canvas_width)
      view_area.scale(1)
      bird_viewer.requestRenderAll()
    }
    canvas_scroll_controller_node.scroll(current_rect.MinX - canvas_scroll_controller_node.offsetWidth / 2, current_rect.MinY - canvas_scroll_controller_node.offsetHeight * 0.1)
    await this.setState({ current_rect: current_rect, last_active_editor_row: Uuid })
  }

  update_current_rect_coord = () => {
    let current_rect = this.state.current_rect
    const coords = this.get_rect_coordinates(current_rect)
    current_rect.MinX = coords.MinX
    current_rect.MinY = coords.MinY
    current_rect.MaxX = coords.MaxX
    current_rect.MaxY = coords.MaxY
  }

  zoom = (direction = null, factor = null) => {
    const { canvas, canvas_height, canvas_width, view_area, bird_viewer, canvas_scroll_controller_node } = this.state
    const { zoomOptions } = this.props
    if (canvas && view_area) {
      let currentZoom = canvas.getZoom()
      let currentIndex = zoomOptions.indexOf(currentZoom)

      if (factor !== null && zoomOptions.includes(factor)) {
        canvas.setZoom(factor)
        canvas.setHeight(canvas_height * factor)
        canvas.setWidth(canvas_width * factor)
      } else if (direction === 'in' && currentIndex < zoomOptions.length - 1) {
        let nextFactor = zoomOptions[currentIndex + 1]
        canvas.setZoom(nextFactor)
        canvas.setHeight(canvas_height * nextFactor)
        canvas.setWidth(canvas_width * nextFactor)
      } else if (direction === 'out' && currentIndex > 0) {
        let prevFactor = zoomOptions[currentIndex - 1]
        canvas.setZoom(prevFactor)
        canvas.setHeight(canvas_height * prevFactor)
        canvas.setWidth(canvas_width * prevFactor)
      }

      view_area.scale(1 / canvas.getZoom())
      this.props.updateZoomFactor(canvas.getZoom())
      let view_area_move_factor = (view_area.width * view_area.scaleX) / canvas_scroll_controller_node.offsetWidth
      let scroller_move_factor = canvas_scroll_controller_node.offsetWidth / (view_area.width * view_area.scaleX)

      this.setState({
        view_area_move_factor: view_area_move_factor,
        scroller_move_factor: scroller_move_factor,
      })

      bird_viewer.requestRenderAll()
    }
  }

  updateToggledThumbnails = async (checked) => {
    await this.setState({ viewThumbnails: checked })
    if (this.state.viewThumbnails) {
      this.renderCanvasThumbnial()
    }
  }

  handleChangeInCurrentRect = async (e) => {
    let current_rect = this.state.current_rect
    current_rect[e.target.name] = e.target.value
    await this.setState({ current_rect: current_rect })
    if (e.target.name === 'SymbolName') {
      this.populateActuatorAndValveTypeData()
    }
  }

  canvasOnScrollHandler = (e) => {
    let { bird_viewer, view_area, view_area_move_factor } = this.state
    if (bird_viewer && view_area) {
      const new_top = e.target.scrollTop * view_area_move_factor
      const new_left = e.target.scrollLeft * view_area_move_factor
      view_area.left = new_left
      view_area.top = new_top
      view_area.setCoords()
      bird_viewer.requestRenderAll()
    }
    this.setState({
      bird_viewer: bird_viewer,
      view_area: view_area,
    })
  }

  handleChangeInCurrentRectCheckbox = (key) => {
    let current_rect = this.state.current_rect
    current_rect[key] = !current_rect[key]
    this.setState({ current_rect: current_rect })
  }

  add_others_field = (key) => {
    let { current_rect } = this.state
    if (current_rect) {
      if (!current_rect[key]) {
        current_rect[key] = {}
      }
      let max_key = 0
      Object.keys(current_rect[key]).forEach((key) => {
        if (key.split('_').length === 2 && max_key < parseInt(key.split('_')[1])) {
          max_key = parseInt(key.split('_')[1])
        }
      })
      if (!(`key_${max_key + 1}` in current_rect[key])) {
        current_rect[key][`key_${max_key + 1}`] = `val_${max_key + 1}`
      }
    }
    this.setState({ current_rect: current_rect })
  }

  delete_others_field = (key, _key) => {
    let { current_rect } = this.state
    if (current_rect) {
      if (key in current_rect) {
        delete current_rect[key][_key]
      }
    }
    this.setState({ current_rect: current_rect })
  }

  handle_change_in_others_field = async (e, type, key) => {
    e.persist()
    let { current_rect } = this.state
    let val = e.target.value
    if (current_rect) {
      if (type === 'key') {
        let same_key_found = false
        Object.keys(current_rect[key]).forEach((_key) => {
          if (_key === val) {
            same_key_found = true
          }
        })
        if (same_key_found) {
          return
        }

        let aligned_data = []
        Object.entries(current_rect[key]).forEach(([_key, _val]) => {
          if (_key === e.target.name) {
            aligned_data.push({
              key: e.target.value,
              val: current_rect[key][e.target.name],
            })
          } else {
            aligned_data.push({
              key: _key,
              val: _val,
            })
          }
        })

        let data = {}
        aligned_data.forEach((_data) => {
          data[_data.key] = _data.val
        })

        current_rect[key] = data
        await this.setState({ current_rect: current_rect })
        document.getElementById(`${key}_${val}`).focus()
      } else {
        current_rect[key][e.target.name] = val
        await this.setState({ current_rect: current_rect })
      }
    }
  }

  renderCurrentRectAttributes = (group) => {
    let { current_rect, ComponentAttributesType, selectTypeAttrs, selectLookupDict } = this.state
    let { attrNameMap } = this.props

    if (!current_rect) {
      return
    }
    let type = current_rect.type
    if (!type || !(ComponentAttributesType && type in ComponentAttributesType) || !ComponentAttributesType) {
      return
    }

    return Object.entries(ComponentAttributesType[type]).map(([key, val]) => {
      if (group.includes(key)) {
        if (val === 'Boolean') {
          return (
            <div className="mb-2" key={key}>
              {key}:
              <label
                onClick={() => {
                  if (this.props.allow_edit) {
                    this.handleChangeInCurrentRectCheckbox(key)
                  }
                }}
                className={`ml-2 ${!this.props.allow_edit ? 'disabled' : ''} checkbox ${current_rect[key] ? 'checked' : ''}`}
              />
            </div>
          )
        }
        return (
          <div key={key}>
            <div className="input-group input-group-sm mb-2">
              {selectTypeAttrs && selectTypeAttrs.includes(key) ? (
                <div>
                  <label>{attrNameMap && type in attrNameMap && key in attrNameMap[type] ? attrNameMap[type][key] : key}</label>
                </div>
              ) : (
                <div className="input-group-prepend">
                  <span className="input-group-text">{attrNameMap && type in attrNameMap && key in attrNameMap[type] ? attrNameMap[type][key] : key}</span>
                </div>
              )}
              {val === 'object' ? (
                <React.Fragment>
                  <input type="text" className="form-control" readOnly value={current_rect[key] ? JSON.stringify(current_rect[key]) : '{}'} />
                  <div className="input-group-append">
                    <button type="button" className="btn btn-success" onClick={() => this.add_others_field(key)} disabled={!this.props.allow_edit}>
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </React.Fragment>
              ) : selectTypeAttrs && selectTypeAttrs.includes(key) ? (
                <Select
                  className="w-100 mb-2"
                  options={
                    key === 'Rotation'
                      ? Object.entries(selectLookupDict[key]).map(([key, val]) => {
                          return {
                            label: val,
                            value: key,
                          }
                        })
                      : key in selectLookupDict && current_rect.type in selectLookupDict[key]
                      ? Object.entries(_.fromPairs(_.sortBy(_.toPairs(selectLookupDict[key][current_rect.type]), (pair) => pair[1]))).map(([key, val]) => {
                          return {
                            label: val,
                            value: key,
                          }
                        })
                      : []
                  }
                  onChange={(selectedOption) => {
                    this.handleChangeInCurrentRect({ target: { name: key, value: selectedOption ? selectedOption.value : '' } })
                  }}
                  value={
                    current_rect[key]
                      ? {
                          label: key === 'Rotation' ? current_rect[key] : selectLookupDict[key][current_rect.type][current_rect[key]],
                          value: current_rect[key],
                        }
                      : null
                  }
                  isDisabled={!this.props.allow_edit}
                  isMulti={false}
                />
              ) : val === 'number' ? (
                <Input
                  type={'number'}
                  name={key}
                  readOnly={['MinX', 'MinY', 'MaxX', 'MaxY', 'Number', 'Uuid'].includes(key)}
                  path={true}
                  value={current_rect[key] !== null && !isNaN(current_rect[key]) ? current_rect[key] : ''}
                  onChange={this.handleChangeInCurrentRect}
                  disabled={!this.props.allow_edit}
                />
              ) : (
                <Input
                  type={'text'}
                  name={key}
                  readOnly={['Uuid'].includes(key)}
                  path={true}
                  value={current_rect[key] ? current_rect[key] : ''}
                  onChange={this.handleChangeInCurrentRect}
                  disabled={!this.props.allow_edit}
                />
              )}
            </div>
            {val === 'object' && current_rect[key]
              ? Object.keys(current_rect[key]).map((_key) => {
                  return (
                    <div className="mb-1 row no-gutters" key={_key}>
                      <div className="col-5">
                        <input
                          className="form-control"
                          id={`${key}_${_key}`}
                          value={_key}
                          name={_key}
                          onChange={(e) => this.handle_change_in_others_field(e, 'key', key)}
                          disabled={!this.props.allow_edit}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className="form-control"
                          name={_key}
                          value={current_rect[key][_key]}
                          onChange={(e) => this.handle_change_in_others_field(e, 'val', key)}
                          disabled={!this.props.allow_edit}
                        />
                      </div>
                      <div className="col">
                        <button type="button" className="btn form-control" onClick={() => this.delete_others_field(key, _key)} disabled={!this.props.allow_edit}>
                          <i className="fa fa-trash-alt fa-2x" />
                        </button>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        )
      }
      return null
    })
  }

  renderSymbolNameSelectionField = (symbolNameList) => {
    return symbolNameList.map((symbol) => {
      return (
        <option value={symbol} key={symbol}>
          {symbol}
        </option>
      )
    })
  }

  update_process_text_list = () => {
    let { current_rect, processTextList } = this.state
    if (processTextList.includes(current_rect.Uuid)) {
      processTextList = processTextList.filter((uuid) => uuid !== current_rect.Uuid)
    } else {
      processTextList.push(current_rect.Uuid)
    }
    this.setState({ processTextList: processTextList })
  }

  checkEditorDataLength = () => {
    let { schema, activeEditorComponent } = this.state
    if (activeEditorComponent && schema) {
      let data_path = schema[activeEditorComponent].split('.')
      let data_length = 0
      let _data = this.state.data
      data_path.forEach((_component) => {
        if (_component in _data) {
          _data = _data[_component]
        }
      })
      data_length = _data.length
      if (data_length) {
        return true
      }
    }
    return false
  }

  renderEditorComponents = () => {
    let { schema, activeEditorComponent } = this.state
    let { compNameMap, editorColumns } = this.props

    if (editorColumns) {
      return Object.keys(editorColumns).map((component) => {
        let data_length = 0
        if (component in schema) {
          let data_path = schema[component].split('.')
          let _data = this.state.data
          data_path.forEach((_component) => {
            if (_component in _data) {
              _data = _data[_component]
            }
          })
          data_length = _data.length
        }

        if (data_length) {
          return (
            <div className="form-check" key={component}>
              <input
                className="form-check-input"
                type="radio"
                onChange={() =>
                  this.setState(
                    {
                      activeEditorComponent: component,
                      activeEditorComponentType: schema[component],
                      editorSelectionList: [],
                    },
                    () => {
                      if (this.state.viewThumbnails) {
                        this.renderCanvasThumbnial()
                      }
                    }
                  )
                }
                id={component}
                checked={activeEditorComponent === component ? true : false}
              />
              <label className="form-check-label" htmlFor={component}>
                {component in compNameMap ? compNameMap[component] : component} &nbsp;
                <span className="badge badge-dark badge-pill">{data_length}</span>
              </label>
            </div>
          )
        }

        return null
      })
    }
  }

  isNumberString(str) {
    try {
      JSON.parseInt(str)
    } catch (e) {
      return false
    }
    return true
  }

  handleChangeInEditorData = (e, Uuid) => {
    let { data, activeEditorComponent, schema, current_rect, editorSelectionList } = this.state
    if (current_rect && activeEditorComponent) {
      let _value = e.target.value
      current_rect[e.target.name] = _value

      for (const [key, value] of Object.entries(this.state.ComponentAttributesType[schema[activeEditorComponent]])) {
        if (key === e.target.name && _value) {
          if (value === 'number' && key !== 'Uuid') {
            if (this.isNumberString(_value)) {
              _value = parseInt(_value)
            }
          } else if (value === 'Boolean') {
            _value = _value === 'true'
          } else if (value === 'object' || value === 'array') {
            return
          }
        }
      }

      let data_path = schema[activeEditorComponent].split('.')
      try {
        if (data_path.length === 1) {
          data[data_path[0]].forEach((obj) => {
            if (obj.Uuid === Uuid || editorSelectionList.includes(obj.Uuid)) {
              obj[e.target.name] = _value
            }
          })
        } else if (data_path.length === 2) {
          data[data_path[0]][data_path[1]].forEach((obj) => {
            if (obj.Uuid === Uuid || editorSelectionList.includes(obj.Uuid)) {
              obj[e.target.name] = _value
            }
          })
        } else if (data_path.length === 3) {
          data[data_path[0]][data_path[1]][data_path[2]].forEach((obj) => {
            if (obj.Uuid === Uuid || editorSelectionList.includes(obj.Uuid)) {
              obj[e.target.name] = _value
            }
          })
        } else if (data_path.length === 4) {
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].forEach((obj) => {
            if (obj.Uuid === Uuid || editorSelectionList.includes(obj.Uuid)) {
              obj[e.target.name] = _value
            }
          })
        }
      } catch (err) {
        this.setState({
          customError: `${err}, Path: ${schema[activeEditorComponent]}`,
        })
        return
      }

      this.setState({ data: data, current_rect: current_rect })
    }
  }

  toggleEditorSelection = (Uuid) => {
    let { editorSelectionList } = this.state
    const index = editorSelectionList.findIndex((uuid) => uuid === Uuid)
    if (index > -1) {
      editorSelectionList.splice(index, 1)
    } else {
      editorSelectionList.push(Uuid)
    }
    this.setState({ editorSelectionList: editorSelectionList })
  }

  toggleSelectAllActiveEditorComponentRows = () => {
    let select = !this.checkIfAllActiveEditorComponentRowsSelected()
    let { editorSelectionList, schema, data } = this.state
    if (schema[this.state.activeEditorComponent] && Object.keys(data).length !== 0 && select) {
      let path = schema[this.state.activeEditorComponent].split('.')
      path.forEach((component) => {
        data = data[component]
      })

      data.forEach((row) => {
        if (!editorSelectionList.includes(row.Uuid)) {
          editorSelectionList.push(row.Uuid)
        }
      })
    }

    if (!select) {
      editorSelectionList = []
    }
    this.setState({ editorSelectionList: editorSelectionList })
  }

  openAndHighlightCorrespondingEditorRow = async () => {
    let { current_rect } = this.state
    if (!this.props.viewEditor) {
      return
    }
    let activeEditorComponent = current_rect.type.split('.')[current_rect.type.split('.').length - 1]
    await this.setState({ activeEditorComponent: activeEditorComponent, last_active_editor_row: current_rect.Uuid })
    const scrollToActiveEditorRowId = `editor_row_${current_rect.Uuid}`
    this.scrollToRow(scrollToActiveEditorRowId)
  }

  // Function to scroll to a specific row by ID
  scrollToRow = (id) => {
    const ref = this.rowRefs[id]
    if (ref) {
      const editorContent = this.editorContentRef.current
      if (editorContent) {
        editorContent.scrollTop = ref.offsetTop - editorContent.offsetTop
      }
    }
  }

  checkIfAllActiveEditorComponentRowsSelected = () => {
    let { editorSelectionList, schema, data } = this.state
    if (schema[this.state.activeEditorComponent] && Object.keys(data).length !== 0) {
      let path = schema[this.state.activeEditorComponent].split('.')
      path.forEach((component) => {
        data = data[component]
      })
      if (data.length === editorSelectionList.length) {
        return true
      }
    }
    return false
  }

  renderEditorData = () => {
    let { selectLookupDict, schema, activeEditorComponent, data, last_active_editor_row, activeEditingCell, selectTypeAttrs } = this.state

    if (schema[activeEditorComponent] && Object.keys(data).length !== 0) {
      let path = schema[activeEditorComponent].split('.')
      let data = this.state.data
      path.forEach((component) => {
        data = data[component]
      })

      return data.map((row, index) => {
        // check if row data in editor search term
        let render_row = true
        if (this.state.editor_cols_search) {
          render_row = false
          for (const column of this.props.editorColumns[this.state.activeEditorComponent]) {
            if (row[column] && row[column].toString().toLowerCase().includes(this.state.editor_cols_search.toString().toLowerCase())) {
              render_row = true
              break
            }
          }
        }

        if (!render_row) {
          return
        }

        let swidth = row.MaxX - row.MinX
        let sheight = row.MaxY - row.MinY
        return (
          <tr
            ref={(el) => (this.rowRefs[`editor_row_${row.Uuid}`] = el)}
            id={`editor_row_${row.Uuid}`}
            key={index}
            onClick={() => this.component_list_selected(row.Uuid)}
            className={`${last_active_editor_row === row.Uuid ? 'bg-warning' : ''}`}
          >
            <th>{index + 1}</th>
            {this.props.allow_edit ? (
              <td>
                <label
                  className={`checkbox ${this.state.editorSelectionList.includes(row.Uuid) ? 'checked' : null}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    this.toggleEditorSelection(row.Uuid)
                  }}
                />
              </td>
            ) : null}
            {this.state.viewThumbnails ? (
              <td>
                <canvas id={`canvas_${row.Uuid}`} width={swidth} height={sheight} />
              </td>
            ) : null}

            {this.props.editorColumns[this.state.activeEditorComponent].map((column, _index) => {
              let value = row[column]
              let disabled = false
              if (value && typeof value === 'object') {
                value = JSON.stringify(value)
                disabled = true
              }

              return (
                <td
                  key={`${index}.${_index}`}
                  onDoubleClick={() => {
                    if (!disabled && this.props.allow_edit) {
                      this.setState({
                        activeEditingCell: `${row.Uuid}_${column}`,
                      })
                    }
                  }}
                >
                  {activeEditingCell === `${row.Uuid}_${column}` ? (
                    selectLookupDict && selectTypeAttrs && selectTypeAttrs.includes(column) ? (
                      <div style={{ minWidth: 180 }}>
                        <Select
                          options={
                            column === 'Rotation'
                              ? Object.entries(selectLookupDict[column]).map(([key, val]) => {
                                  return {
                                    label: val,
                                    value: key,
                                  }
                                })
                              : column in selectLookupDict && schema[activeEditorComponent] in selectLookupDict[column]
                              ? Object.entries(_.fromPairs(_.sortBy(_.toPairs(selectLookupDict[column][schema[activeEditorComponent]]), (pair) => pair[1]))).map(([key, val]) => {
                                  return {
                                    label: val,
                                    value: key,
                                  }
                                })
                              : []
                          }
                          onChange={(selectedOption) => {
                            this.handleChangeInEditorData({ target: { name: column, value: selectedOption ? selectedOption.value : '' } }, row.Uuid)
                          }}
                          value={
                            value
                              ? {
                                  label: column === 'Rotation' ? value : selectLookupDict[column][schema[activeEditorComponent]][value],
                                  value: value,
                                }
                              : null
                          }
                          isMulti={false}
                        />
                      </div>
                    ) : (
                      <Input name={column} path={row.Uuid} value={value} onChange={this.handleChangeInEditorData} disabled={!this.props.allow_edit} style={{ minWidth: 180 }} />
                    )
                  ) : selectLookupDict &&
                    column in selectLookupDict &&
                    schema[activeEditorComponent] in selectLookupDict[column] &&
                    selectTypeAttrs &&
                    selectTypeAttrs.includes(column) &&
                    column !== 'Rotation' ? (
                    selectLookupDict[column][schema[activeEditorComponent]][value]
                  ) : (
                    value
                  )}
                </td>
              )
            })}
          </tr>
        )
      })
    }
  }

  renderEditorHeader = () => {
    let { activeEditorComponentType } = this.state
    let { attrNameMap } = this.props
    return this.props.editorColumns[this.state.activeEditorComponent].map((column, index) => {
      return (
        <th key={index}>
          {attrNameMap && activeEditorComponentType in attrNameMap && column in attrNameMap[activeEditorComponentType] ? attrNameMap[activeEditorComponentType][column] : column}
        </th>
      )
    })
  }

  renderCanvasThumbnial = () => {
    if (this.state.schema[this.state.activeEditorComponent] && Object.keys(this.state.data).length !== 0) {
      let path = this.state.schema[this.state.activeEditorComponent].split('.')
      let data = this.state.data
      path.forEach((component) => {
        data = data[component]
      })

      for (let i = 0; i < data.length; i++) {
        var canvas = document.getElementById(`canvas_${data[i].Uuid}`)
        var contex = canvas.getContext('2d')
        var img = document.getElementById('ref-img')
        let swidth = data[i].MaxX - data[i].MinX
        let sheight = data[i].MaxY - data[i].MinY
        contex.drawImage(img, data[i].MinX, data[i].MinY, swidth, sheight, 0, 0, swidth, sheight)
      }
    }
  }

  // increase editor height
  editor_up = () => {
    let height = this.state.editorHeight
    if (height + 50 <= 700) {
      height = height + 50
    }
    this.setState({ editorHeight: height })
  }

  // decrease editor height
  editor_down = () => {
    let height = this.state.editorHeight
    if (height - 50 >= 200) {
      height = height - 50
    }
    this.setState({ editorHeight: height })
  }

  set_rect_group_color_to_white = () => {
    let { canvas, colorCodes } = this.state
    for (let i = 0; i < canvas._objects.length; i++) {
      if (this.props.whiteOutDetected) {
        canvas._objects[i].selectable = false
        canvas._objects[i].set('fill', 'white')
        canvas._objects[i].set('stroke', 'white')
      } else {
        canvas._objects[i].selectable = true
        canvas._objects[i].set('fill', 'rgba(0,0,0,0)') // trasparent

        let component = canvas._objects[i].type.split('.')[0]
        let color = colorCodes[component]
        canvas._objects[i].set('stroke', color)
      }
    }
    canvas.renderAll()
  }

  download = () => {
    this.update_current_rect()
    let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.state.data))
    let exportFileDefaultName = 'data.json'
    if (this.props.drawingName) {
      exportFileDefaultName = this.props.drawingName + '.json'
    }
    let linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUri)
    linkElement.setAttribute('download', exportFileDefaultName)
    linkElement.click()
  }

  build_movement_data = () => {
    let { selectedComponent, MovementMarkupObj, MovementCompletedMessage, new_rect } = this.state
    if (selectedComponent === 'MovementType-Primary') {
      if (!MovementMarkupObj.Source) {
        MovementMarkupObj.Uuid = this.uuid()
        MovementMarkupObj.MovementType = 'Primary'
        MovementMarkupObj.Source = {
          MinX: new_rect.MinX,
          MinY: new_rect.MinY,
          MaxX: new_rect.MaxX,
          MaxY: new_rect.MaxY,
        }
      } else {
        MovementMarkupObj.Target = {
          MinX: new_rect.MinX,
          MinY: new_rect.MinY,
          MaxX: new_rect.MaxX,
          MaxY: new_rect.MaxY,
        }
        this.props.updateAdditionalInfoMovementTypesData(MovementMarkupObj)
        MovementMarkupObj = {
          Uuid: null,
          MovementType: null,
          Source: null,
          Target: null,
        }
        window.alert(MovementCompletedMessage)
      }
    } else {
      if (!MovementMarkupObj.Source) {
        MovementMarkupObj.Uuid = this.uuid()
        MovementMarkupObj.MovementType = 'Secondary'
        MovementMarkupObj.Source = {
          MinX: new_rect.MinX,
          MinY: new_rect.MinY,
          MaxX: new_rect.MaxX,
          MaxY: new_rect.MaxY,
        }
      } else {
        MovementMarkupObj.Target = {
          MinX: new_rect.MinX,
          MinY: new_rect.MinY,
          MaxX: new_rect.MaxX,
          MaxY: new_rect.MaxY,
        }
        this.props.updateAdditionalInfoMovementTypesData(MovementMarkupObj)
        MovementMarkupObj = {
          Uuid: null,
          MovementType: null,
          Source: null,
          Target: null,
        }
        window.alert(MovementCompletedMessage)
      }
    }
    this.setState({
      MovementMarkupObj: MovementMarkupObj,
      selectComponent: false,
    })
  }

  renderDisciplineForm = () => {
    let documentTypeOptions = []
    this.props.taggingConfigurationFormData.selectedDisciplines.forEach((discipline) => {
      documentTypeOptions = [...documentTypeOptions, ...discipline.DocumentTypes]
    })

    return (
      <div className="mb-3 row">
        <div className="col-4">
          <Select
            options={this.props.taggingConfigurationFormData.disciplines}
            onChange={(selectedList) => {
              this.props.onSelectDiscipline(selectedList, 'selectedDisciplines')
            }}
            isMulti={true}
            value={this.props.taggingConfigurationFormData.selectedDisciplines}
            isDisabled={!this.props.taggingConfigurationFormData.disciplines.length}
          />
        </div>
        <div className="col-4">
          <Select
            options={documentTypeOptions}
            onChange={(selectedList) => {
              this.props.onSelectDiscipline(selectedList, 'selectedDocumentTypes')
            }}
            isMulti={true}
            value={this.props.taggingConfigurationFormData.selectedDocumentTypes}
            isDisabled={!documentTypeOptions.length}
          />
        </div>
        <div className="col-4">
          <Select
            options={this.props.taggingConfigurationFormData.manufacturers}
            onChange={(selectedList) => {
              this.props.onSelectDiscipline(selectedList, 'selectedManufacturers')
            }}
            isMulti={true}
            value={this.props.taggingConfigurationFormData.selectedManufacturers}
            isDisabled={!this.props.taggingConfigurationFormData.manufacturers.length}
          />
        </div>
      </div>
    )
  }

  changeType = async (type, current_rect, select_current_rect = true) => {
    let { data, ComponentAttributesType, processTextListCreated, processTextList } = this.state

    let data_path_str = type

    let rect_data = {}
    for (const [key, value] of Object.entries(ComponentAttributesType[data_path_str])) {
      if ((key === 'Text' || key === 'DisplayText' || key === 'NoteText') && ('Text' in current_rect || 'DisplayText' in current_rect || 'NoteText' in current_rect)) {
        rect_data[key] = 'DisplayText' in current_rect ? current_rect['DisplayText'] : 'NoteText' in current_rect ? current_rect['NoteText'] : current_rect['Text']
      } else if (key in current_rect) {
        rect_data[key] = current_rect[key]
      } else {
        if (value === 'object') {
          rect_data[key] = {}
        } else if (value === 'Boolean') {
          rect_data[key] = false
        } else if (value === 'array') {
          rect_data[key] = []
        } else if (value === 'number') {
          if (['ScaleX', 'ScaleY'].includes(key)) {
            rect_data[key] = 1
          } else {
            rect_data[key] = 0
          }
        } else {
          rect_data[key] = ''
        }
      }
    }

    let data_path = data_path_str.split('.')

    try {
      if (data_path.length === 1) {
        let max_sn = Math.max.apply(
          Math,
          data[data_path[0]].map(function (o) {
            return o.Number
          })
        )
        if (max_sn < 0) {
          max_sn = 0
        }
        rect_data.Number = max_sn + 1
        data[data_path[0]].push(rect_data)
      } else if (data_path.length === 2) {
        let max_sn = Math.max.apply(
          Math,
          data[data_path[0]][data_path[1]].map(function (o) {
            return o.Number
          })
        )
        if (max_sn < 0) {
          max_sn = 0
        }
        rect_data.Number = max_sn + 1
        data[data_path[0]][data_path[1]].push(rect_data)
      } else if (data_path.length === 3) {
        let max_sn = Math.max.apply(
          Math,
          data[data_path[0]][data_path[1]][data_path[2]].map(function (o) {
            return o.Number
          })
        )
        if (max_sn < 0) {
          max_sn = 0
        }
        rect_data.Number = max_sn + 1
        data[data_path[0]][data_path[1]][data_path[2]].push(rect_data)
      } else if (data_path.length === 4) {
        let max_sn = Math.max.apply(
          Math,
          data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].map(function (o) {
            return o.Number
          })
        )
        if (max_sn < 0) {
          max_sn = 0
        }
        rect_data.Number = max_sn + 1
        data[data_path[0]][data_path[1]][data_path[2]][data_path[3]].push(rect_data)
      }
    } catch (err) {
      this.setState({ customError: `${err}, Path: ${data_path_str}` })
      return
    }

    await this.setState({ data: data })
    await this.delete_current_rect(current_rect)
    await this.apply_annotations()
    if (select_current_rect) {
      this.component_list_selected(rect_data.Uuid)
    }

    processTextListCreated.push(current_rect.Uuid)
    processTextList.push(current_rect.Uuid)
    await this.setState({
      processTextListCreated: processTextListCreated,
      processTextList: processTextList,
    })
  }

  populateActuatorAndValveTypeData = () => {
    let { current_rect } = this.state
    let name_parts = current_rect['SymbolName'].split('_')

    if (name_parts[name_parts.length - 1] === 'Closed') {
      if ('ValveType' in current_rect) {
        current_rect['ValveType'] = name_parts[1] + '_Valve_' + name_parts[name_parts.length - 1]
      }
      if ('ActuatorType' in current_rect) {
        current_rect['ActuatorType'] = name_parts.slice(2, -1).join('_')
      }
    } else {
      if ('ValveType' in current_rect) {
        current_rect['ValveType'] = name_parts[1] + '_Valve'
      }

      if ('ActuatorType' in current_rect) {
        current_rect['ActuatorType'] = name_parts.slice(2).join('_')
      }
    }

    this.setState({ current_rect: current_rect })
  }

  checkForRequiredFields = () => {
    const { current_rect } = this.state
    const { requiredGroupFields } = this.props

    // Initialize response with default values
    let response = {
      disabled: false,
      title: '',
    }

    // Check if requiredGroupFields and current_rect exist
    if (!requiredGroupFields || !current_rect) {
      return response
    }

    // Iterate through required fields
    for (const field of requiredGroupFields) {
      // Check if field is not in current_rect or is empty
      if (!(field in current_rect) || !current_rect[field]) {
        // Update response
        response.disabled = true
        response.title = `${field} is required!`

        // Break the loop since one missing field is enough to disable
        break
      }
    }

    return response
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderEditorView = () => {
    return (
      <div className={`editor  ${this.props.viewEditor ? 'active' : null}`}>
        <div className="row no-gutters">
          {!this.state.editorFullWidth ? (
            <div className="col-2">
              <div
                className="p-2 components"
                style={{
                  height: `${this.state.editorHeight}px`,
                }}
              >
                {this.renderEditorComponents()}
              </div>
            </div>
          ) : null}
          <div className={`${this.state.editorFullWidth ? 'col-12' : 'col-10'}`}>
            <div className="p-2 clearfix">
              <div className="btn-group float-right">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() =>
                    this.setState({
                      editorFullWidth: !this.state.editorFullWidth,
                    })
                  }
                >
                  {this.state.editorFullWidth ? <i className="fa fa-angle-right" /> : <i className="fa fa-angle-left" />}
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.editor_up}>
                  <i className="fa fa-angle-up" />
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.editor_down}>
                  <i className="fa fa-angle-down" />
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.props.toggleEditorView()}>
                  <i className="fa fa-times"></i>
                </button>
              </div>

              {this.state.activeEditorComponent && this.checkEditorDataLength() ? (
                <>
                  <div className="d-inline-block float-right mr-3">
                    <ToggleThumbnails updateToggledThumbnails={this.updateToggledThumbnails} isToggled={this.state.viewThumbnails} /> View Thumbnails
                  </div>

                  <button
                    className="btn btn-secondary float-right mr-3 btn-sm"
                    disabled={!this.state.editorSelectionList.length}
                    onClick={() => {
                      if (this.state.editorSelectionList.length < 6) {
                        this.delete_selected_rects(this.state.editorSelectionList)
                      } else {
                        this.setState({
                          deleteRectsWarningMessage: true,
                          editorWarning: true,
                        })
                      }
                    }}
                  >
                    Delete
                  </button>

                  {this.props.schema[this.state.activeEditorComponent].includes('Text.GeneralTexts') ||
                  this.props.schema[this.state.activeEditorComponent].includes('TextRecords') ||
                  this.props.schema[this.state.activeEditorComponent].includes('Tags') ? (
                    <select
                      className="form-control form-control-sm w-25 d-inline-block mr-3 float-right"
                      disabled={!this.state.editorSelectionList.length}
                      onChange={async (e) => {
                        let type = e.target.value
                        this.state.editorSelectionList.forEach(async (Uuid) => {
                          const index = this.state.canvas.getObjects().findIndex((obj) => obj.Uuid === Uuid)
                          if (index > -1) {
                            await this.changeType(type, this.state.canvas.getObjects()[index], false)
                          }
                        })
                        await this.setState({
                          editorSelectionList: [],
                        })
                        if (this.state.viewThumbnails) {
                          this.renderCanvasThumbnial()
                        }
                      }}
                    >
                      <option value="">--Change Type--</option>
                      {Object.entries(this.props.schema).map(([key, val]) => {
                        if ((val.includes('TextRecords') || val.includes('Tags')) && this.state.activeEditorComponent !== key) {
                          return (
                            <option key={key} value={val}>
                              {key in this.props.compNameMap ? this.props.compNameMap[key] : key}
                            </option>
                          )
                        }
                        return null
                      })}
                    </select>
                  ) : null}
                </>
              ) : null}

              {this.state.activeEditorComponent && this.checkEditorDataLength() ? (
                <Input
                  type={'text'}
                  name={'editor_cols_search'}
                  placeholder={'Search'}
                  className={'form-control form-control-sm w-25 d-inline-block mr-3 float-right'}
                  value={this.state.editor_cols_search}
                  onChange={this.onChange}
                  disabled={!this.props.allow_edit}
                />
              ) : null}

              <h4 className="mb-0">
                {this.state.activeEditorComponent in this.props.compNameMap ? this.props.compNameMap[this.state.activeEditorComponent] : this.state.activeEditorComponent}
              </h4>
            </div>
            {this.state.activeEditorComponent && this.checkEditorDataLength() ? (
              <div
                className="table-container p-0"
                style={{
                  height: `${this.state.editorHeight - 40}px`,
                }}
                ref={this.editorContentRef}
              >
                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                  <thead className="thead-light">
                    <tr>
                      <th width="30">#</th>
                      {this.props.allow_edit ? (
                        <th width="30">
                          <label
                            onClick={() => this.toggleSelectAllActiveEditorComponentRows()}
                            className={`checkbox ${this.checkIfAllActiveEditorComponentRowsSelected() ? 'checked' : null}`}
                          />
                        </th>
                      ) : null}
                      {this.state.viewThumbnails ? <th>Thumbnails</th> : null}
                      {this.renderEditorHeader()}
                    </tr>
                  </thead>
                  <tbody>{this.renderEditorData()}</tbody>
                </table>
              </div>
            ) : this.state.activeEditorComponent && !this.checkEditorDataLength() ? (
              <p className="p-2">No data found for {this.state.activeEditorComponent}!</p>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderCurrentRectview = () => {
    let groupVisibility = this.props.groupVisibility
    let { current_rect, new_rect_Uuid, processTextListCreated, processTextList, lineBreakMode } = this.state

    let change_type_options = []
    if (current_rect) {
      Object.entries(this.props.schema).forEach(([key, val]) => {
        if ((val.includes('TextRecords') || val.includes('Tags')) && current_rect.type !== val) {
          if (!this.props.compNameMap[key]) {
            console.info(`${key} not found in compNameMap!`)
            return
          }
          change_type_options.push({
            label: this.props.compNameMap[key],
            value: val,
          })
        }
      })
    }

    return (
      <Draggable handle="#current_rect_handle">
        <form className={`p-2 current-rect`}>
          <div id="current_rect_handle">
            <div className="handle">
              <i className="fa fa-arrows-alt" />
            </div>
          </div>

          <div className="clearfix mb-2">
            <button
              className="btn float-right p-0"
              onClick={() =>
                this.setState({
                  current_rect: undefined,
                })
              }
              style={{ fontSize: 18 }}
            >
              <i className="fa fa-times" />
            </button>
            <h4 className="mb-0 w-75">
              {current_rect.type.split('.')[current_rect.type.split('.').length - 1] in this.props.compNameMap
                ? this.props.compNameMap[current_rect.type.split('.')[current_rect.type.split('.').length - 1]]
                : current_rect.type}
            </h4>
          </div>

          {current_rect.type.includes('LineBreaks') && this.props.line_break_feature ? (
            <div className="mb-2">
              <label
                className={`checkbox ${lineBreakMode ? 'checked' : null}`}
                onClick={() =>
                  this.setState({
                    lineBreakMode: !lineBreakMode,
                  })
                }
              />{' '}
              Select Break Points
            </div>
          ) : null}

          {current_rect && current_rect.type.includes('Text') && processTextListCreated.includes(current_rect.Uuid) ? (
            <div className="mb-2">
              <label
                title={this.props.annotation ? 'Text recognition is not supported for this standard' : null}
                className={`checkbox 
                                ${this.props.annotation ? 'disabled ' : null}
                                ${processTextList.includes(current_rect.Uuid) ? 'checked' : null}`}
                onClick={() => (!this.props.annotation ? this.update_process_text_list() : null)}
              />{' '}
              Detect Text
            </div>
          ) : null}

          {current_rect && (current_rect.type.includes('Text.GeneralTexts') || current_rect.type.includes('TextRecords') || current_rect.type.includes('Tags')) ? (
            <div className="mb-2">
              <Select
                options={change_type_options}
                onChange={(selectedType) => this.changeType(selectedType.value, current_rect)}
                isMulti={false}
                isDisabled={!this.props.allow_edit}
              />
            </div>
          ) : null}

          <div style={{ overflowY: 'auto', height: this.props.currentRectHeight ? this.props.currentRectHeight : '50vh' }}>
            {!groupVisibility || !groupVisibility.length ? (
              <>
                <div className="card rounded bordered bg-light mb-2">
                  <div className="card-body p-2">
                    <div
                      className="clearfix"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (this.state.activeGroup !== 'standard') {
                          this.setState({
                            activeGroup: 'standard',
                          })
                        } else {
                          this.setState({
                            activeGroup: '',
                          })
                        }
                      }}
                    >
                      <span className="float-right">{this.state.activeGroup === 'standard' ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}</span>
                      <h5 className="mb-0">Standard Attributes</h5>
                    </div>
                    {this.state.activeGroup === 'standard' ? <div className="mt-2">{this.renderCurrentRectAttributes(this.props.standardGroup)}</div> : null}
                  </div>
                </div>

                <div className="card rounded bordered bg-light mb-2">
                  <div className="card-body p-2">
                    <div
                      className="clearfix"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (this.state.activeGroup !== 'geo') {
                          this.setState({
                            activeGroup: 'geo',
                          })
                        } else {
                          this.setState({
                            activeGroup: '',
                          })
                        }
                      }}
                    >
                      <span className="float-right">{this.state.activeGroup === 'geo' ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}</span>
                      <h5 className="mb-0">Geo Attributes</h5>
                    </div>
                    {this.state.activeGroup === 'geo' ? <div className="mt-2">{this.renderCurrentRectAttributes(this.props.geoGroup)}</div> : null}
                  </div>
                </div>

                <div className="card rounded bordered bg-light mb-2">
                  <div className="card-body p-2">
                    <div
                      className="clearfix"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (this.state.activeGroup !== 'meta') {
                          this.setState({
                            activeGroup: 'meta',
                          })
                        } else {
                          this.setState({
                            activeGroup: '',
                          })
                        }
                      }}
                    >
                      <span className="float-right">{this.state.activeGroup === 'meta' ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}</span>
                      <h5 className="mb-0">Meta Attributes</h5>
                    </div>
                    {this.state.activeGroup === 'meta' ? <div className="mt-2">{this.renderCurrentRectAttributes(this.props.metaGroup)}</div> : null}
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-2">{this.renderCurrentRectAttributes(groupVisibility)}</div>
            )}
          </div>

          <div className="clearfix mb-3">
            {this.props.suggestions && this.state.current_rect && this.state.current_rect.Uuid === new_rect_Uuid ? (
              <button
                type="button"
                disabled={this.props.loadingSuggestions || this.checkForRequiredFields().disabled}
                title={this.checkForRequiredFields().title}
                className="btn btn-primary w-100 mb-2"
                onClick={() => this.update_current_rect(true)}
              >
                Save & Suggest
              </button>
            ) : null}

            <button type="button" className="btn btn-secondary w-100" disabled={!this.props.allow_edit} onClick={() => this.delete_current_rect()}>
              Delete
            </button>
          </div>
        </form>
      </Draggable>
    )
  }

  render() {
    let { current_rect, viewConfiguration, view_current_rect, ComponentAttributesType } = this.state

    if (current_rect) {
      if (!current_rect.type || !(ComponentAttributesType && current_rect.type in ComponentAttributesType) || !ComponentAttributesType) {
        this.setState({
          current_rect: undefined,
          activeGroup: 'standard',
        })
      }
    }

    let merged_attrs = []
    let saveAndSuggestDisabled = false
    if (this.props.taggingConfigurationFormData && 'FormList' in this.props.taggingConfigurationFormData) {
      this.props.taggingConfigurationFormData.FormList.forEach((field) => {
        if (field.Name === 'Texts') {
          field.AttributeData.forEach((attr) => {
            if (attr['Sample Values'] === '') {
              saveAndSuggestDisabled = true
            }
            merged_attrs.push(attr.Attribute)
          })
        }
      })
    }

    return (
      <>
        {this.state.lineBreakCreationMessage ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={() => this.setState({ lineBreakCreationMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>BreakpointX and BreakpointY are computed for this LineBreak.</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => this.setState({ lineBreakCreationMessage: false })}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.deleteRectsWarningMessage ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={() => this.setState({ deleteRectsWarningMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Confirm bulk deletion!</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      if (this.state.editorWarning) {
                        this.delete_selected_rects(this.state.editorSelectionList)
                      } else if (this.state.ctrlDeleteWarning) {
                        this.delete_selected_rects(this.state.delete_rects_list)
                      } else {
                        this.delete_current_and_child_rects(true)
                      }

                      this.setState({
                        deleteRectsWarningMessage: false,
                        editorWarning: false,
                        ctrlDeleteWarning: false,
                      })
                    }}
                  >
                    Delete
                  </button>
                  <button type="button" className="btn btn-default" onClick={() => this.setState({ deleteRectsWarningMessage: false })}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.lineBreakMessage && this.props.line_break_feature ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Create Breakpoint for this LineBreak in a two-step process as:</h5>
                  <button type="button" className="close" onClick={() => this.setState({ lineBreakMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ol>
                    <li>Check the box “Breakpoint” in the right menu. This will activate LineBreak mode.</li>
                    <li>
                      Create as many times a tiny bbox on a process lines where this LineBreak is breaking it. The tiny bbox will disappear and BreakpointX, and BreakpointY will be
                      set for this LineBreak. Uncheck the box “Breakpoint” to deactivate it.
                    </li>
                  </ol>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => this.setState({ lineBreakMessage: false })}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.customError ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Error!</h5>
                  <button type="button" className="close" onClick={() => this.setState({ customError: '' })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{this.state.customError}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary float-right" onClick={() => this.setState({ customError: '' })}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.lineBreakSwitchMessage ? (
          <div className="modal">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={() => this.setState({ lineBreakSwitchMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Please switch off break points selection mode to procced further.</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary float-right" onClick={() => this.setState({ lineBreakSwitchMessage: false })}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.props.in_progress || this.props.fetchingData ? (
          <div className="message-layover">{this.props.in_progress ? <div className="message">Processing Data...</div> : <div className="message">Fetching Data...</div>}</div>
        ) : null}

        {/* Current Rect Modal Box */}
        {(current_rect && !viewConfiguration && !this.props.viewEditor) || view_current_rect ? this.renderCurrentRectview() : null}

        {/* Select Component Modal Box */}
        {this.props.allow_edit ? (
          <form onSubmit={this.add_new_rect} className={`p-3 select-component ${this.state.selectComponent ? 'active' : null}`}>
            <Select
              value={this.state._selectedComponent}
              options={_.map(Object.keys(this.state.schema), (key) => {
                return { label: this.props.compNameMap && key in this.props.compNameMap ? this.props.compNameMap[key] : key, value: key }
              })}
              onChange={(selectedComponent) => {
                this.setState({ _selectedComponent: selectedComponent, selectedComponent: selectedComponent.value })
              }}
              isMulti={false}
            />

            <div className="clearfix mt-3">
              <button className="btn btn-primary float-right" disabled={!this.state.selectedComponent}>
                Select
              </button>
              <button type="button" className="btn btn-default float-right mr-2" onClick={() => this.setState({ selectComponent: false })}>
                Cancel
              </button>
            </div>
          </form>
        ) : null}

        {/* Minimized Configure Modal box */}
        <button
          onClick={() => this.setState({ minimize_configure_box: false })}
          className={`minimize-configure btn btn-primary ${this.state.viewConfiguration && this.state.minimize_configure_box ? 'active' : null}`}
        >
          Configure Box &nbsp; <i className="fa fa-window-maximize"></i>
        </button>

        {/* Add Tagging Config Bbox Mode Info box */}
        <div className={`configure-info-box p-3 bg-primary ${this.state.viewConfiguration && this.state.add_tagging_config_bbox ? 'active' : null}`}>
          <div className="mb-2 text-light">Add Text Bbox Mode is active</div>
          <div className="clearfix">
            <button
              className="btn btn-default btn-sm float-right"
              onClick={async () => {
                await this.setState({
                  add_tagging_config_bbox: '',
                })
                await this.update_rects_visibility(this.state.current_rect)
              }}
            >
              Cancel Mode
            </button>
          </div>
        </div>

        {/* Configure Modal Box */}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            this.props.showSuggestions(
              null,
              this.state.data,
              this.state.current_rect.type.split('.')[this.state.current_rect.type.split('.').length - 1],
              this.props.taggingConfigurationFormData,
              this.state.current_rect.Uuid
            )
          }}
          className={`p-3 configure ${this.state.viewConfiguration && !this.state.add_tagging_config_bbox && !this.state.minimize_configure_box ? 'active' : null}`}
        >
          {this.props.loadingTaggingConfigFormData ? (
            <h1 className="mt-4 mb-4 text-center">
              <i className="fa fa-spin fa-spinner"></i>
            </h1>
          ) : (
            <div>
              <div className="clearfix mb-3">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      view_current_rect: !this.state.view_current_rect,
                    })
                  }
                  className="float-right btn btn-primary btn-sm"
                >
                  {!this.state.view_current_rect ? 'View' : 'Hide'} Current Bbox
                </button>

                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      minimize_configure_box: true,
                    })
                  }
                  className="float-right btn btn-default btn-sm mr-2"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>

              {this.props.taggingConfigurationFormData && 'disciplines' in this.props.taggingConfigurationFormData ? this.renderDisciplineForm() : null}

              {this.props.taggingConfigurationFormData && 'FormList' in this.props.taggingConfigurationFormData
                ? this.props.taggingConfigurationFormData.FormList.map((field, index) => {
                    return (
                      <div className="mb-3" key={index}>
                        {field.Name}:
                        {!field.Parent && field.Value ? (
                          'Options' in field ? (
                            <select
                              className="form-control d-inline-block ml-2 w-25"
                              value={field.Value}
                              onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}`)}
                            >
                              <option value={''}>--Select--</option>
                              {field.Options.map((option) => {
                                return (
                                  <option value={option} key={option}>
                                    {option}
                                  </option>
                                )
                              })}
                            </select>
                          ) : (
                            <input
                              className="form-control d-inline-block ml-2 w-25"
                              value={field.Value}
                              onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}`)}
                            />
                          )
                        ) : null}
                        {field.Name !== 'Custom Mapping' && field.Parent ? (
                          <div className="ml-4 mt-2">
                            <div className="mb-2">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={async () => {
                                  this.props.addTaggingConfigFormDataAttribute(`${index}`, 'Value')
                                  await this.setState({
                                    add_tagging_config_bbox: `${index}.${field.Value.length - 1}`,
                                    selectedComponent: 'GeneralTexts',
                                  })
                                  await this.update_rects_visibility(this.state.current_rect, false, false)
                                }}
                              >
                                Add Text
                              </button>
                            </div>
                            {field.Value.map((_field, _index) => {
                              return (
                                <div className="mb-2" key={_index}>
                                  {_field.Name}
                                  :
                                  <input
                                    className="form-control d-inline-block ml-2 w-25"
                                    name={_field.Name}
                                    value={_field.Value}
                                    onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}.${_index}`, `Text`)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-default ml-2"
                                    onClick={() => {
                                      this.props.deleteTaggingConfigurationFormData(`${index}.${_index}`, 'Value')
                                      this.delete_rect(_field.Uuid)
                                    }}
                                  >
                                    <i className="fa fa-trash-alt"></i>
                                  </button>
                                </div>
                              )
                            })}

                            {'AttributeData' in field ? (
                              <div className="mb-2">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.addTaggingConfigFormDataAttribute(`${index}`, `Text Attribute`)}>
                                  Add Attribute
                                </button>
                              </div>
                            ) : null}
                            {field.AttributeData.map((_field, _index) => {
                              return (
                                <div className="row mb-2" key={_index}>
                                  <div className="col">
                                    Attribute:
                                    {!_field.Attribute ? (
                                      <select
                                        disabled={_field.Attribute ? true : false}
                                        className="form-control d-inline-block ml-2 w-50"
                                        value={_field.Attribute}
                                        name={'Attribute'}
                                        onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}.${_index}`, 'Text')}
                                      >
                                        <option>-- Select --</option>
                                        {Object.entries(this.props.taggingConfigurationFormData.AttributeOptions).map(([key, value]) => {
                                          return (
                                            <option key={key} value={key}>
                                              {value}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    ) : (
                                      <input className="form-control d-inline-block ml-2 w-50" value={_field.Attribute} disabled={true} />
                                    )}
                                  </div>
                                  <div className="col">
                                    Sample Values:
                                    <input
                                      disabled={_field['isLOV']}
                                      className="form-control d-inline-block ml-2 w-50"
                                      value={_field['Sample Values']}
                                      name={'Sample Values'}
                                      onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}.${_index}`, 'Text')}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.props.onChangeTaggingConfigOrderHandler(index, _index, false)}>
                                      <i className="fa fa-angle-down"></i>
                                    </button>
                                    <button type="button" className="btn btn-primary ml-1" onClick={() => this.props.onChangeTaggingConfigOrderHandler(index, _index, true)}>
                                      <i className="fa fa-angle-up"></i>
                                    </button>
                                  </div>
                                  <div className="col-2">
                                    <button
                                      type="button"
                                      className="btn btn-default ml-2"
                                      onClick={() => this.props.deleteTaggingConfigurationFormData(`${index}.${_index}`, 'AttributeData')}
                                    >
                                      <i className="fa fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        ) : null}
                        {field.Name === 'Custom Mapping' ? (
                          <div className="ml-4 mt-2">
                            <div>
                              {field.AttributeData.map((_field, _index) => {
                                return (
                                  <div className="row mb-2" key={_index}>
                                    <div className="col-4">
                                      Attribute:
                                      <select
                                        className="form-control d-inline-block ml-2 w-50"
                                        value={_field.Attribute}
                                        name={'Attribute'}
                                        onChange={(e) => this.props.handleChangeInTaggingConfigFormData(e, `${index}.${_index}`, 'Custom')}
                                      >
                                        <option>-- Select --</option>
                                        {Object.entries(this.props.taggingConfigurationFormData.AttributeOptions).map(([key, value]) => {
                                          return (
                                            <option key={key} value={key}>
                                              {value}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                    <div className="col">
                                      Template:
                                      {_field.Template.map((item, index) => {
                                        return (
                                          <button type="button" disabled={true} className="btn btn-default m-1" key={index}>
                                            {item}
                                          </button>
                                        )
                                      })}
                                      <div className="dropdown d-inline-block ml-2">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() => {
                                            if (this.state.toggleTemplateDropdown === '') {
                                              this.setState({
                                                toggleTemplateDropdown: `${index}.${_index}`,
                                              })
                                            } else {
                                              this.setState({
                                                toggleTemplateDropdown: ``,
                                              })
                                            }
                                          }}
                                        >
                                          <i className="fa fa-plus" />
                                        </button>
                                        {this.state.toggleTemplateDropdown === `${index}.${_index}` ? (
                                          <div className="dropdown-menu">
                                            {merged_attrs.map((option) => {
                                              return (
                                                <button
                                                  type="button"
                                                  className="dropdown-item"
                                                  key={option}
                                                  onClick={() =>
                                                    this.props.handleChangeInTaggingConfigFormData(
                                                      {
                                                        target: {
                                                          value: option,
                                                          name: 'Template',
                                                        },
                                                      },
                                                      `${index}.${_index}`,
                                                      'Custom'
                                                    )
                                                  }
                                                >
                                                  {option}
                                                </button>
                                              )
                                            })}
                                            <button
                                              type="button"
                                              className="dropdown-item"
                                              onClick={() =>
                                                this.props.handleChangeInTaggingConfigFormData(
                                                  {
                                                    target: {
                                                      value: '-',
                                                      name: 'Template',
                                                    },
                                                  },
                                                  `${index}.${_index}`,
                                                  'Custom'
                                                )
                                              }
                                            >
                                              Split (-)
                                            </button>
                                            <button
                                              type="button"
                                              className="dropdown-item"
                                              onClick={() =>
                                                this.props.handleChangeInTaggingConfigFormData(
                                                  {
                                                    target: {
                                                      value: '/',
                                                      name: 'Template',
                                                    },
                                                  },
                                                  `${index}.${_index}`,
                                                  'Custom'
                                                )
                                              }
                                            >
                                              Split (/)
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <button
                                        type="button"
                                        className="btn btn-default ml-2"
                                        onClick={() => this.props.deleteTaggingConfigurationFormData(`${index}.${_index}`, 'AttributeData')}
                                      >
                                        <i className="fa fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>

                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.addTaggingConfigFormDataAttribute(`${index}`)}>
                              Add Custom
                            </button>
                          </div>
                        ) : null}
                      </div>
                    )
                  })
                : null}

              <div className="clearfix">
                <button
                  className="btn btn-primary float-right"
                  disabled={this.props.loadingSuggestions || saveAndSuggestDisabled}
                  title={saveAndSuggestDisabled ? 'Add atleast 1 sample value for all attributes.' : null}
                >
                  Save & Suggest {this.props.loadingSuggestions ? <i className="fa fa-spin fa-spinner"></i> : null}
                </button>
                <button
                  type="button"
                  disabled={this.props.loadingSuggestions}
                  className="btn btn-secondary float-right mr-2"
                  onClick={() => {
                    this.delete_current_rect()
                    this.setState({
                      viewConfiguration: false,
                      current_rect: undefined,
                      activeGroup: 'standard',
                    })
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  disabled={this.props.loadingSuggestions}
                  className="btn btn-default float-right mr-2"
                  onClick={() =>
                    this.setState({
                      viewConfiguration: false,
                      current_rect: undefined,
                      activeGroup: 'standard',
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </form>

        {/* Editor View */}
        {this.renderEditorView()}

        {/* Canvas */}
        <div className="canvas-wrapper" style={{ height: this.props.canvasHeight }} id="canvas-scroll-controller" onScroll={this.canvasOnScrollHandler}>
          <div className="canvas-wrapper-inner">
            <canvas id="canvas" />
          </div>
        </div>

        {/* Bird View */}
        <Draggable handle="#handle">
          <div className="bird-view">
            <div id="handle"></div>
            <div>
              <img id="bird_view_thumbnail_img" alt="bird viewer" src={this.props.img_url} style={{ width: 350 }} onLoad={this.initBirdViewer} />
            </div>
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              <canvas id="bird-viewer"></canvas>
            </div>
          </div>
        </Draggable>
      </>
    )
  }
}

export default IUIAnnotation
