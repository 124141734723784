import React from 'react'
import './WebComponent.css'

const IsotopeLoader3 = () => {
  return (
    <div className="card-skeleton3" id="unique-62071088div_1">
      <div className="animated-background" id="unique-34298307div_2">
        <div className="skel-mask-container" id="unique-47214544div_3">
          <div className="skel-mask skel-mask-1" id="unique-10096809div_4"></div>
          <div className="skel-mask skel-mask-2" id="unique-14302455div_5"></div>
          <div className="skel-mask skel-mask-3" id="unique-38969897div_6"></div>
          <div className="skel-mask skel-mask-4" id="unique-92976021div_7"></div>
          <div className="skel-mask skel-mask-5" id="unique-13741067div_8"></div>
          <div className="skel-mask skel-mask-6" id="unique-11964433div_9"></div>
          <div className="skel-mask skel-mask-7" id="unique-52031668div_10"></div>
          <div className="skel-mask skel-mask-8" id="unique-63011610div_11"></div>
          <div className="skel-mask skel-mask-9" id="unique-52601552div_12"></div>
        </div>
      </div>
    </div>
  )
}

export default IsotopeLoader3
