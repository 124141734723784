import React from 'react'

import Select from '../Components/Forms/Fields/select'
import { base64ToArrayBuffer } from '../Components/WebComponents/utils'
import DataTable from './DataTable'

class ViewReport extends React.Component {
  state = {
    options: {},
    selected_package: null,
    selected_purchase_order: null,
    selected_quotations: [],
    selected_inquiry_doc: null,
    selected_output_type: null,
    rows: [],
    column_headers: [],
    view_reports_data: false,

    loadingOptions: false,
    runningReports: false,
  }

  componentDidMount() {
    this.fetchTradeReportParams()
  }

  fetchTradeReportParams = async () => {
    await this.setState({ loadingOptions: true })
    const formData = new FormData()
    formData.append('report_name', this.props.view_report_name)
    formData.append('projectId', this.props.projectId)
    await this.props.fetchTradeReportParams(formData)
    await this.setState({ loadingOptions: false })

    if (this.props.user.displayMessageCode) {
      return
    }

    this.setState({
      options: this.props.user.tradeDocumentReportsParams,
    })
  }

  fetchTradeDocReports = async (e) => {
    e.preventDefault()
    await this.setState({ runningReports: true })
    const formData = new FormData()
    formData.append('report_name', this.props.view_report_name)
    formData.append('projectId', this.props.projectId)
    formData.append('pacakge', this.state.selected_package.value)
    formData.append('output_type', this.state.selected_output_type.value)
    if (this.props.view_report_name === 'Comparison Report - PO vs Quotation') {
      formData.append('purchase_order', this.state.selected_purchase_order.value)
      formData.append('quotation', JSON.stringify(this.state.selected_quotations))
    } else if (this.props.view_report_name === 'Draft Report - RFQ') {
      formData.append('inquiry_doc', this.state.selected_inquiry_doc.value)
    }

    await this.props.fetchTradeDocReports(formData)
    await this.setState({ runningReports: false })

    if (this.props.user.displayMessageCode) {
      return
    }
    this.setState({
      rows: this.props.user.tradeDocumentReports.data,
      column_headers: this.props.user.tradeDocumentReports.column_headers,
      view_reports_data: this.props.user.tradeDocumentReports.view,
    })

    if (this.props.user.tradeDocumentReports.auto_download) {
      this.downloadReportFile()
    }
  }

  downloadReportFile = () => {
    if (this.props.user.tradeDocumentReports.report_file) {
      let buffer = base64ToArrayBuffer(this.props.user.tradeDocumentReports.report_file)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      // const url = this.props.user.report_file.report
      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', `${this.props.user.tradeDocumentReports.file_name}`)
      link.click()
    }
  }

  checkIfRunReportDisabled = () => {
    let { selected_package, selected_output_type, selected_inquiry_doc } = this.state
    if (this.props.view_report_name === 'Comparison Report - PO vs Quotation') {
      if (!selected_package || !selected_output_type) {
        return true
      }
    } else if (this.props.view_report_name === 'Draft Report - RFQ') {
      if (!selected_package || !selected_inquiry_doc || !selected_output_type) {
        return true
      }
    }
    return false
  }

  render() {
    let { options, loadingOptions, selected_package, selected_output_type, selected_purchase_order, selected_quotations, selected_inquiry_doc } = this.state
    let package_options = []
    let purchase_order_options = []
    let quotation_options = []
    let output_type_options = []
    let inquiry_doc_options = []

    if (!loadingOptions) {
      package_options = options.data_options
      if (this.props.view_report_name === 'Comparison Report - PO vs Quotation') {
        if (selected_package) {
          purchase_order_options = package_options.find((option) => option.value === selected_package.value).Purchase_Order
          quotation_options = package_options.find((option) => option.value === selected_package.value).Quotation
        }
      } else if (this.props.view_report_name === 'Draft Report - RFQ') {
        if (selected_package) {
          inquiry_doc_options = package_options.find((option) => option.value === selected_package.value).Inquiry_document
        }
      }

      output_type_options = options.download_options
    }

    return (
      <form onSubmit={this.fetchTradeDocReports} id="tradedocument_view_report_form">
        <div className="clearfix card mb-2">
          <div className={'card-body p-2'}>
            {!this.state.view_reports_data ? (
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={this.state.runningReports || this.checkIfRunReportDisabled()}
                id="tradedocument_submit_view_report_form"
              >
                Run Report
              </button>
            ) : (
              <button type="button" className={'btn btn-primary float-right'} onClick={() => this.downloadReportFile()}>
                Download
              </button>
            )}
            <h3 className={'mb-0'}>{this.props.view_report_name}</h3>
          </div>
        </div>
        {!this.state.view_reports_data ? (
          <div className={'card'}>
            <div className={'card-body p-3'}>
              <div className={'row'}>
                <div className={'mb-3 col-6'}>
                  <label>Package (*)</label>
                  <Select
                    options={package_options}
                    value={this.state.selected_package}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        quotation_options = package_options.find((option) => option.value === selectedOption.value).Quotation
                      }

                      this.setState({
                        selected_package: selectedOption,
                        selected_purchase_order: null,
                        selected_quotations: quotation_options,
                        selected_inquiry_doc: null,
                        selected_output_type: null,
                      })
                    }}
                    isMulti={false}
                  />
                </div>
                {this.props.view_report_name === 'Comparison Report - PO vs Quotation' ? (
                  <React.Fragment>
                    <div className={'mb-3 col-6'}>
                      <label>Purchase Order</label>
                      <Select
                        options={purchase_order_options}
                        value={selected_purchase_order}
                        onChange={(selectedOption) => {
                          this.setState({ selected_purchase_order: selectedOption })
                        }}
                        isDisabled={!purchase_order_options.length}
                        isMulti={false}
                      />
                    </div>

                    <div className={'mb-3 col-6'}>
                      <label>Quotation</label>
                      <Select
                        options={quotation_options}
                        value={selected_quotations}
                        onChange={(selectedList) => {
                          this.setState({ selected_quotations: selectedList })
                        }}
                        isDisabled={true}
                        isMulti={true}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={'mb-3 col-6'}>
                      <label>Inquiry Document (*)</label>
                      <Select
                        options={inquiry_doc_options}
                        value={selected_inquiry_doc}
                        onChange={(selectedOption) => {
                          this.setState({ selected_inquiry_doc: selectedOption })
                        }}
                        isDisabled={!inquiry_doc_options.length}
                        isMulti={false}
                      />
                    </div>
                  </React.Fragment>
                )}
                <div className={'mb-3 col-6'}>
                  <label>Output Type (*)</label>
                  <Select
                    options={output_type_options}
                    value={selected_output_type}
                    onChange={(selectedOption) => {
                      this.setState({ selected_output_type: selectedOption })
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DataTable rows={this.state.rows} column_headers={this.state.column_headers} />
        )}
      </form>
    )
  }
}

export default ViewReport
