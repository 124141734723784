import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

class ProjectCard extends React.Component {
  state = {
    projectsData: [],
    product: null,
    editProjectId: null,
  }

  componentDidMount = () => {
    this.setState({
      projectsData: this.props.projectsData,
      product: this.props.product,
    })
  }

  getProjectCard(project, productType) {
    let width = 0
    if (['iDocuments-TradeDocuments', 'iDocuments-Datasheet'].includes(productType)) {
      width = (project.processedPdfs / project.numOfPdfs) * 100
    } else {
      width = (project.processedPages / project.numOfPages) * 100
    }
    width = Math.round(width * 100) / 100
    const processStyle = { width: `${width}%` }

    return (
      <div className="col-md-3 col-sm-6" key={project.id} id={`${productType.toLowerCase()}_project_${project.id}`}>
        <div className="project-card bg-grey">
          <div className="clearfix mb-3">
            {!this.props.clone_project_hidden && productType !== 'iDocuments-TradeDocuments' && (
              <button className="datasheetDelete float-right" onClick={() => this.props.cloneProjectToggler(project)}>
                <i className="fas fa-copy fa-2x"></i>
              </button>
            )}
            {!this.props.edit_project_hidden && (
              <Link
                className="datasheetDelete float-right"
                to={`/${['iDocuments-TradeDocuments', 'iDocuments-Datasheet'].includes(productType) ? `${productType.toLowerCase()}` : 'pid'}/project-setup?product=${
                  this.props.product
                }&id=${project.id}&dashboard=true`}
              >
                <i className="fa fa-edit fa-2x"></i>
              </Link>
            )}
            {!this.props.delete_project_hidden && (
              <button className="datasheetDelete float-right" onClick={() => this.props.deleteProjectToggler(project)}>
                <i className="fas fa-trash-alt fa-2x"></i>
              </button>
            )}
            <h4>
              <b>{project.name}</b>
            </h4>
          </div>

          <Link
            to={`/${['iDocuments-TradeDocuments', 'iDocuments-Datasheet'].includes(productType) ? `${productType.toLowerCase()}` : 'pid'}/project?product=${
              this.state.product
            }&name=${project.name}&id=${project.id}`}
            className="d-block"
          >
            {project.description && (
              <div className="mb-1">
                <span className="text-truncate d-inline-block" style={{ maxWidth: 260 }}>
                  {project.description}
                </span>
              </div>
            )}
            <div className="mb-1">
              <b>No. of Documents: </b> <span>{project.numOfPdfs}</span>
            </div>
            <div className="mb-1">
              <b>Start Date:</b> <span>{project.startDate === 'N/A' ? project.startDate : <Moment date={project.startDate} format="DD-MMM-YYYY hh:mm" />}</span>
            </div>
            <div className="mb-2">
              <b>Last Activity: </b> <span>{project.lastActivity === 'N/A' ? project.lastActivity : <Moment date={project.lastActivity} format="DD-MMM-YYYY hh:mm" />}</span>
            </div>

            <div className="progress-box">
              <div className="left-text">
                <b>Total {productType === 'iDocuments-Datasheet' ? 'datasheets' : productType === 'iDocuments-TradeDocuments' ? 'documents' : 'drawings'} in project:</b> &nbsp;
                <span>{project.numOfPdfs === 'N/A' ? 'N/A' : project.numOfPdfs}</span>
              </div>
              <div
                title={`Number of ${productType === 'iDocuments-Datasheet' ? 'datasheets' : 'drawings'} processed: ${
                  productType === 'iDocuments-Datasheet' ? project.processedPdfs : project.processedPages
                }/${productType === 'iDocuments-Datasheet' ? project.numOfPdfs : project.numOfPages} (${width}%)`}
                className="progress"
              >
                <div className="progress-bar" role="progressbar" style={processStyle}></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const { product, projectsData } = this.props

    return projectsData.map((project) => {
      if (project.productName === this.state.product) {
        return this.getProjectCard(project, product)
      }
      return null
    })
  }
}
export default ProjectCard
