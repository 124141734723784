import axios from 'axios'

let baseURL = window.location.href

if (baseURL.includes('http://localhost:3000/') || baseURL.includes('http://127.0.0.1:3000/')) {
  // baseURL = 'http://127.0.0.1:8000'
  baseURL = 'http://api.digitize-prod-test.ips-ai.com' //mcc
  // baseURL = 'http://digitize-testing.ips-ai.com:3000'
}
baseURL = 'http://api.digitize-prod-test.ips-ai.com'
export default axios.create({
  baseURL: baseURL,
})
