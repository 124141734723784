import React, { Component } from 'react'
import XMLViewer from 'react-xml-viewer'

class DisplayXML extends Component {
  state = {}

  render() {
    if (this.props.xmlData) {
      return (
        <div className="table-container p-2" id="unique-32478357div_1">
          <XMLViewer xml={this.props.xmlData} />
        </div>
      )
    }
    return null
  }
}

export default DisplayXML
