import React, { Component } from 'react'

import Input from '../../Forms/Fields/input'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS6 extends Component {
  state = {
    components: [],
    loading: false,
    formLoader: false,
    cad_system_options_fields: [],
    cad_system_options_fields_copy: [],

    selected_product: '',
    selected_cad_system: '',
    product_options: [],
    cad_config_data: {},
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectCADInfo(formData)

    await this.setState({
      loading: false,
      cad_system_options_fields: this.props.user.projectInfo.cad_system_options_fields,
      cad_system_options_fields_copy: JSON.stringify(this.props.user.projectInfo.cad_system_options_fields),
      product_options: this.props.user.projectInfo.product_options,
      selected_product: this.props.user.projectInfo.product_options.length === 1 ? this.props.user.projectInfo.product_options[0].id : '',
      cad_config_data: this.props.user.projectInfo.cad_config_data,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('cad_config_data', JSON.stringify(this.state.cad_config_data))
    formData.append('step', 6)
    await this.props.updateProjectCADInfo(formData)

    await this.setState({ formLoader: false })
    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  onChange = (obj) => {
    let { cad_system_options_fields } = this.state
    cad_system_options_fields.forEach((field) => {
      if (field.name in obj) {
        field.value = obj[field.name]
      }
    })
    this.setState({ cad_system_options_fields: cad_system_options_fields })
  }

  updateFieldCheckboxType = (name) => {
    let { cad_system_options_fields } = this.state
    cad_system_options_fields.forEach((field) => {
      if (field.name === name) {
        field.value = !field.value
      }
    })

    this.setState({ cad_system_options_fields: cad_system_options_fields })
  }

  updateFieldSelectType = (e) => {
    let { cad_system_options_fields } = this.state
    let name = e.target.name
    let value = e.target.value
    cad_system_options_fields.forEach((field) => {
      if (field.name === name) {
        field.value = value
      }
    })

    this.setState({ cad_system_options_fields: cad_system_options_fields })
  }

  checkIfDisabled = (name) => {
    let { selected_cad_system, product_options, selected_product } = this.state
    let cad_system_options = []
    product_options.forEach((product_option) => {
      if (product_option.id.toString() === selected_product.toString()) {
        cad_system_options = product_option.cad_options
      }
    })
    let disabled = true
    cad_system_options.forEach((option) => {
      if (parseInt(selected_cad_system.split('-')[0]) === option.id) {
        if (option.fields.includes(name)) {
          disabled = false
        }
      }
    })
    return disabled
  }

  renderSubmitButton = () => {
    const { cad_config_data, selected_cad_system, selected_product } = this.state
    const btnName =
      selected_cad_system &&
      selected_product in cad_config_data &&
      cad_config_data[selected_product].find((config_data) => config_data.cad_system_id === parseInt(selected_cad_system.split('-')[0]))
        ? 'Update'
        : 'Add'

    return (
      <div className="clearfix">
        <button className="btn btn-primary float-right">{btnName}</button>
      </div>
    )
  }

  addData = (e) => {
    e.preventDefault()
    let { cad_config_data, cad_system_options_fields, product_options, selected_product, selected_cad_system } = this.state
    let cad_system_options = []
    product_options.forEach((product_option) => {
      if (product_option.id.toString() === selected_product.toString()) {
        cad_system_options = product_option.cad_options
      }
    })
    let data = {}
    cad_system_options.forEach((option) => {
      if (option.id === parseInt(selected_cad_system.split('-')[0])) {
        data = {
          cad_system_id: option.id,
          cad_system_name: option.name,
        }
        option.fields.forEach((field) => {
          var field_index = cad_system_options_fields.findIndex((_field) => _field.name === field)
          if (field_index > -1) {
            data[field] = cad_system_options_fields[field_index].value
          }
        })
      }
    })

    let product_data = []
    if (selected_product in cad_config_data) {
      product_data = cad_config_data[selected_product].filter((_data) => _data.cad_system_id !== data.cad_system_id)
    }
    product_data.push(data)

    cad_config_data[selected_product] = product_data
    this.setState(
      {
        cad_config_data: cad_config_data,
        selected_cad_system: '',
      },
      this.resetFieldsData
    )
  }

  resetFieldsData = () => {
    let { cad_system_options_fields_copy, cad_system_options_fields } = this.state
    cad_system_options_fields_copy = JSON.parse(cad_system_options_fields_copy)
    cad_system_options_fields.forEach((field) => {
      cad_system_options_fields_copy.forEach((_field) => {
        if (field.name === _field.name) {
          field.value = _field.value
        }
      })
    })
    this.setState({ cad_system_options_fields: cad_system_options_fields })
  }

  deleteConfigData = (index) => {
    let { cad_config_data, selected_product } = this.state
    cad_config_data[selected_product].splice(index, 1)
    this.setState({ cad_config_data: cad_config_data })
  }

  populateExistingData = () => {
    let { cad_config_data, selected_cad_system, cad_system_options_fields, selected_product } = this.state
    let _index = -1
    if (selected_product in cad_config_data) {
      _index = cad_config_data[selected_product].findIndex((data) => data.cad_system_id === parseInt(selected_cad_system.split('-')[0]))
    }
    if (_index > -1) {
      cad_system_options_fields.forEach((field) => {
        if (field.name in cad_config_data[selected_product][_index]) {
          field.value = cad_config_data[selected_product][_index][field.name]
        }
      })
    } else {
      this.resetFieldsData()
    }

    this.setState({ cad_system_options_fields: cad_system_options_fields })
  }

  renderForm() {
    let cad_system_options = []
    let product_options = this.state.product_options
    product_options.forEach((product_option) => {
      if (product_option.id.toString() === this.state.selected_product.toString()) {
        cad_system_options = product_option.cad_options
      }
    })
    return (
      <div className="card" id="unique-10431430div_1">
        <div className="clearfix card-header bg-light" id="unique-32968358div_2">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-57541255button_4">
            Cancel
          </button>

          <h3 id="unique-70525508h3_1">CAD Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="31140038" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} style={{ height: '46rem' }}>
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          <form className="card bg-light" onSubmit={this.addData} id="unique-92173677form_1">
            <div className="card-body p-2" id="unique-91433282div_4">
              <div className="row" id="unique-58770317div_5">
                {product_options.length > 1 ? (
                  <div className="col-3" id="unique-10503464div_6">
                    <label id="unique-43578593label_1">
                      Product Type{' '}
                      <span className="small text-muted" id="unique-23270269span_1">
                        (Mandatory *)
                      </span>
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        this.setState(
                          {
                            selected_product: e.target.value,
                            selected_cad_system: '',
                          },
                          this.populateExistingData
                        )
                      }
                      value={this.state.selected_product}
                      required={true}
                    >
                      <option value="">-- Select --</option>
                      {product_options.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                ) : null}

                <div className="col-3" id="unique-11153134div_7">
                  <label id="unique-11065731label_2">
                    CAD System{' '}
                    <span className="small text-muted" id="unique-11518593span_2">
                      (Mandatory *)
                    </span>
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      this.setState(
                        {
                          selected_cad_system: e.target.value,
                        },
                        this.populateExistingData
                      )
                    }}
                    value={this.state.selected_cad_system}
                    disabled={!this.state.selected_product}
                    required={true}
                  >
                    <option value="">-- Select --</option>
                    {cad_system_options.map((option) => {
                      return (
                        <option key={option.id} value={`${option.id}-${option.name}`}>
                          {option.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                {this.state.cad_system_options_fields.map((field) => {
                  if (!this.state.selected_cad_system || this.checkIfDisabled(field.name)) {
                    return null
                  }

                  if (['text', 'number', 'date'].includes(field.field_type)) {
                    return (
                      <div className="col-3 mb-3" key={field.name} id="unique-23236229div_8">
                        <Input
                          label={field.display_name[this.state.selected_cad_system.split('-')[1]]}
                          type={field.field_type}
                          maxValue={field.maxValue}
                          step={field.field_type === 'number' ? field.step : null}
                          className="form-control"
                          name={field.name}
                          value={field.value.toString()}
                          disabled={this.checkIfDisabled(field.name)}
                          onChange={this.onChange}
                          required={!this.checkIfDisabled(field.name)}
                          id="31140038"
                        />
                      </div>
                    )
                  } else if (field.field_type === 'select') {
                    return (
                      <div className="col-3 mb-3" key={field.name}>
                        <label>
                          {field.display_name[this.state.selected_cad_system.split('-')[1]]}{' '}
                          {!this.checkIfDisabled(field.name) ? <span className="small text-muted">(Mandatory *)</span> : null}
                        </label>
                        <select
                          className="form-control"
                          name={field.name}
                          value={field.value}
                          disabled={this.checkIfDisabled(field.name)}
                          onChange={this.updateFieldSelectType}
                          required={!this.checkIfDisabled(field.name)}
                        >
                          <option value="">-- Select --</option>
                          {field.name === 'cad_output_uom'
                            ? field.options.map((option) => {
                                return (
                                  <option value={option} key={option}>
                                    {option}
                                  </option>
                                )
                              })
                            : null}
                        </select>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
              <div className="row" id="unique-11275324div_10">
                {this.state.cad_system_options_fields.map((field) => {
                  if (field.field_type === 'checkbox') {
                    return (
                      <div className="col-3 mb-3" key={field.name} id="unique-11034444div_11">
                        <label
                          onClick={() => {
                            if (!this.checkIfDisabled(field.name)) {
                              this.updateFieldCheckboxType(field.name)
                            }
                          }}
                          className={`checkbox ${field.value ? 'checked' : null} ${this.checkIfDisabled(field.name) ? 'disabled' : null}`}
                        />
                        &nbsp; {field.display_name[this.state.selected_cad_system.split('-')[1]]}
                      </div>
                    )
                  }
                  return null
                })}
              </div>

              {this.renderSubmitButton()}
            </div>
          </form>

          <h4 className="mt-3">Added Configurations</h4>
          <div className="row">
            {this.state.product_options.map((product) => {
              if (product.id === this.state.selected_product) {
                return product.cad_options.map((cad_option) => {
                  var foundConfigDataIndex = -1
                  if (this.state.selected_product in this.state.cad_config_data) {
                    foundConfigDataIndex = this.state.cad_config_data[this.state.selected_product].findIndex((config) => config.cad_system_id === cad_option.id)
                  }

                  if (foundConfigDataIndex === -1) {
                    return null
                  }

                  return (
                    <div key={cad_option.id} className="col-3 mb-3">
                      <div className="card">
                        <div className="card-body p-2">
                          <div className="clearfix mb-2">
                            <button className="btn text-secondary float-right p-1" onClick={() => this.deleteConfigData(foundConfigDataIndex)}>
                              <i className="fa fa-trash-alt" />
                            </button>
                            <button
                              className="btn text-secondary float-right p-1"
                              onClick={() => this.setState({ selected_cad_system: `${cad_option.id}-${cad_option.name}` }, this.populateExistingData)}
                            >
                              <i className="fa fa-edit" />
                            </button>

                            <h5 className="card-title cursor-pointer">{cad_option.name}</h5>
                          </div>

                          <div className="table-container" style={{ height: '17rem' }}>
                            <table className="table table-striped">
                              <tbody>
                                {Object.entries(this.state.cad_config_data[this.state.selected_product][foundConfigDataIndex]).map(([key, value]) => {
                                  const foundField = this.state.cad_system_options_fields.find((field) => field.name === key)
                                  if (!foundField) {
                                    return null
                                  }

                                  return (
                                    <tr key={key}>
                                      <th className="bg-light">{foundField.display_name[cad_option.name]}</th>
                                      <td>{value}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              return null
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-74294737div_15">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
