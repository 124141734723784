import React from 'react'
import { connect } from 'react-redux'

import { fetchIUIData, updateIUIData, unlockIUI, remapIUIData, updateFileStatus, refreshUserAuth, fetchFile, checkIUIDataState, revertEnhancedData } from './../store/actions'

import ToggleSwitch from '../Components/WebComponents/ToggleSwitch'
import ToggleAllSwitch from '../Components/WebComponents/ToggleAllSwitch'
import ToggleWhiteOutSwitch from '../Components/WebComponents/ToggleWhiteOutSwitch'
import DisplayMessage from '../Components/ErrorHandling/DisplayMessage'
import IUIAnnotation from './IUIAnnotation'
import Select from '../Components/Forms/Fields/select'
import './canvas.css'

class IUIDashboard extends React.Component {
  state = {
    status: [], // multiple statuses with possible values Mark Cleaned, Mark ...
    statusToggled: false, // a flag if status dropdown is toggled
    allowed_status: [], // provides a list of statuses which are allowed

    checkInByForce: false,

    // Constatnts
    schema: {}, // Key value pair where key is component and value is . seperated path of component
    componentAttrTypes: {}, // key value pair where key is component and value is object of another key value pairs giving info about typoe of field and data type that field has
    selectTypeAttrs: [], // 1-d list telling us which attribute is select type field
    selectLookupDict: {}, // Look up dictionary for any select type field for an attribute
    standardGroup: [],
    geoGroup: [],
    metaGroup: [],
    editorColumns: {},
    colorSet: {},
    toggledSwitchList: [],
    lineBreakRectsList: [],
    componentTreeSchema: {},
    collapseList: [],
    selectedRemapAttributes: [],
    compNameMap: {},
    attrNameMap: {},
    allowEdit: false,

    type: { value: 'IUI', label: 'IUI Mode' },
    product: null,
    projectName: null,
    projectId: null,
    documentId: null,
    pages: [],
    drawingId: null,
    image_url: null,
    imageError: false,
    imageWidth: 0,
    imageHeight: 0,
    data: {},
    fetchingData: false,
    annotation: false,
    collapseLeftPanel: true,
    switchListUpdated: false,
    viewEditor: false,
    activeEditorComponent: '',
    activeEditorComponentType: '',
    // Zoom
    zoomIn: false,
    zoomOut: false,
    zoomOptions: [0.5, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 1.05, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.55, 1.6, 1.65, 1.7, 1.75, 1.8, 1.85, 1.9, 1.95, 2],
    zoom: 1,

    download: false,
    updateData: false,
    processText: false,
    enhance_btn_state: 'Enhance',
    enhance: false,
    enhancing: false,
    undo_enhance: false,
    enhanceWarning: false,
    errorMessage: null,
    successMessage: '',
    updating: false,
    processingTextList: false,
    toggledAll: true,
    whiteOutDetected: false,
    componentsToggled: true,
    keyboardShortcutsToggled: false,
    RemapAtrributesToggled: false,
    remapIUIData: false,
    remapingIUIData: false,
    allowCheckInCheckOut: false,
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const product = query.get('product')
    const projectName = query.get('projectName')
    const projectId = parseInt(query.get('projectId'))
    const documentId = parseInt(query.get('documentId'))
    const pages = query.get('pages') ? query.get('pages').split(',') : []
    const drawingId = pages.length ? pages[0] : parseInt(query.get('drawingId'))
    const annotation = query.get('annotation') === 'true'

    if (!projectId || !drawingId) {
      this.setState({
        errorMessage: 'Missing query parameters.',
      })
      return
    }
    await this.setState({
      product: product,
      projectName: projectName,
      projectId: projectId,
      documentId: documentId,
      pages: pages,
      drawingId: drawingId,
      annotation: annotation,
    })

    this.checkIUIDataState()
    await this.fetchData(true)

    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  componentDidUpdate(props) {
    if (props.timedOut !== this.props.timedOut && this.props.timedOut !== undefined && props.timedOut !== undefined) {
      this.unlockIUI(false)

      if (this.state.allowEdit) {
        this.updateFileStatus(undefined, undefined, true, undefined)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  handleBeforeUnload = (e) => {
    e.preventDefault()
    if (this.state.allowEdit) {
      this.updateFileStatus(undefined, undefined, true, undefined)
      this.setState({ checkInByForce: true })
    }

    e.returnValue = ''
    return ''
  }

  fetchFile = async () => {
    let { drawingId, projectId, product } = this.state

    const formData = new FormData()
    formData.append('document_id', null)
    formData.append('drawing_id', drawingId)
    formData.append('product_name', product)
    formData.append('client_id', this.props.user.singleProjectData.client_id)
    formData.append('user_id', this.props.user.userId)
    formData.append('project_id', projectId)
    formData.append('file_type', 'png')
    formData.append('is_process', false)

    await this.props.fetchFile(formData)

    if (!this.props.user.displayMessageCode && this.props.user.base64File) {
      // Convert base64 to Blob
      const base64Data = this.props.user.base64File.file
      const byteCharacters = atob(base64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: 'image/png' })

      // Create object URL
      const imageUrl = URL.createObjectURL(blob)

      // Set state with image URL
      this.setState({
        image_url: imageUrl,
      })
    }
  }

  fetchData = async (fetchFile = false) => {
    let { drawingId, projectId, type, annotation } = this.state
    await this.setState({ fetchingData: true })

    // Fetch File first
    if (fetchFile) {
      this.fetchFile()
    }

    const formData = new FormData()
    formData.append('userId', this.props.user.userId)
    formData.append('drawingId', drawingId)
    formData.append('projectId', projectId)
    formData.append('mode', type.value)
    formData.append('annotation', annotation)
    await this.props.fetchIUIData(formData)
    if (!this.props.user.displayMessageCode) {
      await this.setState({
        componentTreeSchema: this.props.user.iuiData.TreeStructure,
        toggledSwitchList: this.props.user.iuiData.ToggledSwitchList,
        data: this.props.user.iuiData,
        schema: this.props.user.iuiData.ComponentSchema,
        selectTypeAttrs: this.props.user.iuiData.SelectTypeAttrs,
        selectLookupDict: this.props.user.iuiData.SelectLookupDict,
        colorSet: this.props.user.iuiData.ColorSet,
        componentAttrTypes: this.props.user.iuiData.ComponentAttrType,
        standardGroup: this.props.user.iuiData.StandardGroup,
        geoGroup: this.props.user.iuiData.GeoGroup,
        metaGroup: this.props.user.iuiData.MetaGroup,
        editorColumns: this.props.user.iuiData.EditorColumns,
        compNameMap: this.props.user.iuiData.comp_name_map,
        attrNameMap: this.props.user.iuiData.attr_name_map,
        lineBreakRectsList: this.props.user.iuiData.lineBreakRectsList,
        allowEdit: this.props.user.iuiData.AllowEdit,
        status: this.props.user.iuiData.fileStatus,
        allowed_status: this.props.user.iuiData.allowedStatus,
        allowCheckInCheckOut: this.props.user.iuiData.allowCheckInCheckOut,
      })
    }
    await this.setState({ fetchingData: false })
  }

  updateCollapseList = (item) => {
    let collapseList = this.state.collapseList
    const index = collapseList.indexOf(item)
    if (index > -1) {
      collapseList.splice(index, 1)
    } else {
      collapseList.push(item)
    }
    this.setState({ collapseList: collapseList })
  }

  updateToggledSwitchList = async (switchName, switched, path = '') => {
    let toggledSwitchList = this.state.toggledSwitchList
    path = path.split('.')
    let data = this.state.componentTreeSchema

    if (switched) {
      toggledSwitchList.push(switchName)
    } else {
      const index = toggledSwitchList.findIndex((_switch) => _switch === switchName)
      if (index > -1) {
        toggledSwitchList.splice(index, 1)
      }
    }

    path.forEach((component, index) => {
      if (index === 0) {
        data = data[component]
      } else {
        data = data.Value[component]
      }
    })

    if (data.Value) {
      for (const key in data.Value) {
        let _data = data.Value[key]

        if (_data.Value) {
          for (const _key in _data.Value) {
            let __data = _data.Value[_key]

            if (switched) {
              toggledSwitchList.push(__data.for)
            } else {
              const index = toggledSwitchList.indexOf(__data.for)
              if (index > -1) {
                toggledSwitchList.splice(index, 1)
              }
            }
          }
        }

        if (switched) {
          toggledSwitchList.push(_data.for)
        } else {
          const index = toggledSwitchList.indexOf(_data.for)
          if (index > -1) {
            toggledSwitchList.splice(index, 1)
          }
        }
      }
    }

    await this.setState({ toggledSwitchList: toggledSwitchList })
    await this.setState({
      switchListUpdated: !this.state.switchListUpdated,
    })
  }

  updateToggleAll = async (switched) => {
    if (switched) {
      await this.setState({
        toggledSwitchList: this.state.data.ToggledSwitchList,
        toggledAll: true,
      })
    } else {
      await this.setState({ toggledSwitchList: [], toggledAll: false })
    }
    await this.setState({
      switchListUpdated: !this.state.switchListUpdated,
    })
  }

  updateWhiteOutSwitch = async (switched) => {
    this.setState({ whiteOutDetected: switched })
  }

  updateAdditionalInfo = (key) => {
    let data = this.state.data
    let additional_info = data.additional_info
    additional_info.Reviewed[key] = !additional_info.Reviewed[key]
    data.additional_info = additional_info
    this.setState({ data: data })
  }

  updateAdditionalInfoMovementTypesData = (obj) => {
    let data = this.state.data
    if ('additional_info' in data && 'PidUpdateInfo' in data.additional_info && 'MovementMarkups' in data.additional_info.PidUpdateInfo) {
      data.additional_info.PidUpdateInfo.MovementMarkups.push(obj)
    }
    this.setState({ data: data })
  }

  renderComponentsTree = () => {
    let { componentTreeSchema, data, annotation } = this.state
    let schema = componentTreeSchema
    return Object.keys(schema).map((key) => {
      let _schema = schema[key].Value
      return (
        <div key={key} className="mb-1 bg-light border rounded">
          <span className="btn" id="unique-10957761span_1">
            <span className="d-inline-block mr-1" style={{ minWidth: 15 }} id="unique-10924208span_2">
              {_schema ? (
                <i style={{ cursor: 'pointer' }} onClick={() => this.updateCollapseList(key)} className={`fa ${this.state.collapseList.includes(key) ? 'fa-minus' : 'fa-plus'}`} />
              ) : null}
            </span>
            <ToggleSwitch
              isToggled={this.state.toggledSwitchList.includes(schema[key].for)}
              className={schema[key].for.split('.')[0]}
              switchName={schema[key].for}
              path={key}
              updateToggledSwitchList={this.updateToggledSwitchList}
            />
            {schema[key].DisplayName}{' '}
            {!annotation && schema[key].for.split('.')[schema[key].for.split('.').length - 1] in this.state.editorColumns ? (
              <i
                className="fa fa-edit text-secondary ml-1"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.setState({
                    viewEditor: true,
                    activeEditorComponentType: schema[key].for,
                    activeEditorComponent: schema[key].for.split('.')[schema[key].for.split('.').length - 1],
                  })
                }
              />
            ) : null}
            {data.additional_info && data.additional_info.Reviewed ? (
              schema[key].for.split('.')[schema[key].for.split('.').length - 1] in data.additional_info.Reviewed ? (
                <i
                  className={`ml-2 far fa-check-circle ${
                    data.additional_info.Reviewed[schema[key].for.split('.')[schema[key].for.split('.').length - 1]] ? 'text-primary' : 'text-secondary'
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.updateAdditionalInfo(schema[key].for.split('.')[schema[key].for.split('.').length - 1])}
                />
              ) : null
            ) : null}
          </span>

          {_schema && this.state.collapseList.includes(key)
            ? Object.keys(_schema).map((_key) => {
                let __schema = _schema[_key].Value
                return (
                  <div key={`${key}.${_key}`}>
                    <span className="btn ml-3" id="unique-59419213span_3">
                      <span className="d-inline-block mr-1" style={{ minWidth: 15 }} id="unique-83867258span_4">
                        {__schema ? (
                          <i
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => this.updateCollapseList(`${key}_${_key}`)}
                            className={`fa ${this.state.collapseList.includes(`${key}_${_key}`) ? 'fa-minus' : 'fa-plus'}`}
                          />
                        ) : null}
                      </span>
                      <ToggleSwitch
                        isToggled={this.state.toggledSwitchList.includes(_schema[_key].for)}
                        className={_schema[_key].for.split('.')[0]}
                        switchName={_schema[_key].for}
                        path={`${key}.${_key}`}
                        updateToggledSwitchList={this.updateToggledSwitchList}
                      />
                      {_schema[_key].DisplayName}{' '}
                      {!annotation && _schema[_key].for.split('.')[_schema[_key].for.split('.').length - 1] in this.state.editorColumns ? (
                        <i
                          className="fa fa-edit text-secondary ml-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              viewEditor: true,
                              activeEditorComponentType: _schema[_key].for,
                              activeEditorComponent: _schema[_key].for.split('.')[_schema[_key].for.split('.').length - 1],
                            })
                          }
                        />
                      ) : null}
                      {data.additional_info && data.additional_info.Reviewed ? (
                        _schema[_key].for.split('.')[_schema[_key].for.split('.').length - 1] in data.additional_info.Reviewed ? (
                          <i
                            className={`ml-2 far fa-check-circle ${
                              data.additional_info.Reviewed[_schema[_key].for.split('.')[_schema[_key].for.split('.').length - 1]] ? 'text-primary' : 'text-secondary'
                            }`}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => this.updateAdditionalInfo(_schema[_key].for.split('.')[_schema[_key].for.split('.').length - 1])}
                          />
                        ) : null
                      ) : null}
                    </span>

                    {__schema && this.state.collapseList.includes(`${key}_${_key}`)
                      ? Object.keys(__schema).map((__key) => {
                          return (
                            <div key={`${key}.${_key}.${__key}`}>
                              <span className="btn" id="unique-11179560span_5">
                                <div className="ml-4">
                                  <div className="ml-4">
                                    <ToggleSwitch
                                      isToggled={this.state.toggledSwitchList.includes(__schema[__key].for)}
                                      className={__schema[__key].for.split('.')[0]}
                                      switchName={__schema[__key].for}
                                      path={`${key}.${_key}.${__key}`}
                                      updateToggledSwitchList={this.updateToggledSwitchList}
                                    />
                                    {__schema[__key].DisplayName}{' '}
                                    {!annotation && __schema[__key].for.split('.')[__schema[__key].for.split('.').length - 1] in this.state.editorColumns ? (
                                      <i
                                        className="fa fa-edit text-secondary ml-2"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            viewEditor: true,
                                            activeEditorComponentType: __schema[__key].for,
                                            activeEditorComponent: __schema[__key].for.split('.')[__schema[__key].for.split('.').length - 1],
                                          })
                                        }
                                      />
                                    ) : null}
                                    {data.additional_info && data.additional_info.Reviewed ? (
                                      __schema[__key].for.split('.')[__schema[__key].for.split('.').length - 1] in data.additional_info.Reviewed ? (
                                        <i
                                          className={`ml-2 far fa-check-circle ${
                                            data.additional_info.Reviewed[__schema[__key].for.split('.')[__schema[__key].for.split('.').length - 1]]
                                              ? 'text-primary'
                                              : 'text-secondary'
                                          }`}
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => this.updateAdditionalInfo(__schema[__key].for.split('.')[__schema[__key].for.split('.').length - 1])}
                                        />
                                      ) : null
                                    ) : null}
                                  </div>
                                </div>
                              </span>
                            </div>
                          )
                        })
                      : null}
                  </div>
                )
              })
            : null}
        </div>
      )
    })
  }

  handleImageLoaded = () => {
    var myImg = document.querySelector('#ref-img')
    var realWidth = myImg.naturalWidth
    var realHeight = myImg.naturalHeight
    this.setState({
      imageWidth: realWidth,
      imageHeight: realHeight,
    })
  }

  handleImageError = () => {
    this.setState({
      imageError: true,
    })
  }

  updateIUIData = async (data, process_text = false, processTextList, enhance = false, lineBreakRectsList = []) => {
    if (process_text) {
      await this.setState({ processingTextList: true })
    } else if (enhance) {
      await this.setState({ enhancing: true })
    } else {
      await this.setState({ updating: true })
    }

    let AdditionalInfo = this.state.data.additional_info
    if (process_text && AdditionalInfo) {
      AdditionalInfo.ProcessTextList = processTextList
    }

    const formData = new FormData()
    formData.append('annotation', this.state.annotation)
    formData.append('data', JSON.stringify(data))
    formData.append('AdditionalInfo', JSON.stringify(AdditionalInfo))
    formData.append('lineBreakRectsList', JSON.stringify(lineBreakRectsList))
    formData.append('process_text', process_text)
    formData.append('process_json', enhance)
    formData.append('drawingId', this.state.drawingId)
    formData.append('projectId', this.state.projectId)
    await this.props.updateIUIData(formData)
    if (this.state.enhance) {
      await this.checkIUIDataState()
    }

    await this.setState({
      processingTextList: false,
      updating: false,
      enhancing: false,
    })
    if (this.props.user.displayMessageCode) {
      return
    }

    this.setState({
      successMessage: this.props.user.updateIUIData.successMessage,
      status: this.props.user.updateIUIData.fileStatus,
      allowed_status: this.props.user.updateIUIData.allowedStatus,
    })

    if (process_text || enhance) {
      this.fetchData()
    }
  }

  revertEnhancedData = async (processTextList) => {
    await this.setState({ enhancing: true })
    let AdditionalInfo = this.state.data.additional_info
    if (AdditionalInfo) {
      AdditionalInfo.ProcessTextList = processTextList
    }

    const formData = new FormData()
    formData.append('AdditionalInfo', JSON.stringify(AdditionalInfo))
    formData.append('drawingId', this.state.drawingId)
    formData.append('projectId', this.state.projectId)
    await this.props.revertEnhancedData(formData)
    await this.checkIUIDataState()
    await this.setState({ enhancing: false })

    if (this.props.user.displayMessageCode) {
      return
    }

    this.fetchData()
  }

  checkIUIDataState = async () => {
    const formData = new FormData()
    formData.append('drawingId', this.state.drawingId)
    formData.append('projectId', this.state.projectId)
    await this.props.checkIUIDataState(formData)
    if (!this.props.user.displayMessageCode && this.props.user.IUIEnhanceDataState) {
      this.setState({ enhance_btn_state: this.props.user.IUIEnhanceDataState.button_state })
    }
  }

  remapIUIData = async (data, processTextList) => {
    let { selectedRemapAttributes, drawingId, projectId } = this.state
    await this.setState({
      RemapAtrributesToggled: false,
      remapingIUIData: true,
    })

    let AdditionalInfo = this.state.data.additional_info
    AdditionalInfo.ProcessTextList = processTextList

    const formData = new FormData()
    formData.append('drawingId', drawingId)
    formData.append('projectId', projectId)
    formData.append('data', JSON.stringify(data))
    formData.append('AdditionalInfo', JSON.stringify(AdditionalInfo))
    formData.append('remapAttribComponent', JSON.stringify(selectedRemapAttributes))
    await this.props.remapIUIData(formData)
    await this.setState({ remapingIUIData: false })

    if (!this.props.user.displayMessageCode) {
      this.setState({
        successMessage: this.props.user.remapIUIDataMessage,
      })
      this.fetchData()
    }
  }

  updateFileStatus = async (markCleaned = undefined, markApproved = undefined, checkIn = undefined, markReviewed = undefined) => {
    let { drawingId, projectId } = this.state

    const formData = new FormData()
    formData.append('projectId', projectId)
    if (markCleaned !== undefined) {
      formData.append('markCleaned', markCleaned)
    }
    if (markReviewed !== undefined) {
      formData.append('markReviewed', markReviewed)
    }
    if (markApproved !== undefined) {
      formData.append('markApproved', markApproved)
    }
    if (checkIn !== undefined) {
      formData.append('checkIn', checkIn)
    }
    formData.append('drawingList', [drawingId])
    formData.append('documentList', [])

    await this.props.updateFileStatus(formData)

    this.setState({
      allowed_status: this.props.user.fileStatus.files[0].allowedStatus,
      status: this.props.user.fileStatus.files[0].fileStatus,
    })

    if (checkIn !== undefined) {
      this.setState({
        allowEdit: !checkIn,
      })
    }
  }

  toggleEditorView = async (view = undefined) => {
    await this.setState({ viewEditor: view === undefined ? !this.state.viewEditor : view })
  }

  unlockIUI = async (redirect = false) => {
    let { product, documentId, projectId, projectName } = this.state
    const formData = new FormData()
    formData.append('drawingId', this.state.drawingId)
    formData.append('unlocksource', 'UI')
    formData.append('projectId', this.state.projectId)
    await this.props.unlockIUI(formData)
    if (redirect) {
      this.props.history.push(`/pid/project?product=${product}&name=${projectName}&id=${projectId}&documentId=${documentId}`)
    }
  }

  updateZoomFactor = (zoom) => {
    this.setState({ zoom: zoom })
  }

  getActiveDrawingObject = () => {
    let file = this.props.user.projectFiles.files.find((file) => file.id.toString() === this.state.drawingId.toString())
    if (file) {
      return {
        label: file.drawingName,
        value: file.id,
      }
    }
    return null
  }

  render() {
    let download_iui_data_hidden = false
    let iui_editor_hidden = false
    let iui_process_text_hidden = false
    let iui_enhance_hidden = false
    let iui_update_save_data_hidden = false

    if (this.state.product) {
      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_download_iui_data_hidden`)) {
        download_iui_data_hidden = true
      }

      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_iui_editor_hidden`)) {
        iui_editor_hidden = true
      }

      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_iui_process_text_hidden`)) {
        iui_process_text_hidden = true
      }

      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_iui_process_json_hidden`)) {
        iui_enhance_hidden = true
      }

      if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_iui_update_save_data_hidden`)) {
        iui_update_save_data_hidden = true
      }
    }

    const drawing_options = this.props.user.projectFiles.files.filter((file) => this.state.pages.includes(file.id.toString()))

    return (
      <div
        className="deliverables"
        onMouseOver={() => {
          if (this.state.checkInByForce && !this.state.allowEdit) {
            this.updateFileStatus(undefined, undefined, false, undefined)
            this.setState({ checkInByForce: false })
          }
        }}
      >
        <div className="inner-body">
          <button className="close custom text-center" disabled={this.state.allowEdit} onClick={() => this.unlockIUI(true)}>
            &times;
          </button>

          {this.state.enhanceWarning ? (
            <div className="modal">
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" onClick={() => this.setState({ processJSONWarning: false })}>
                      <span aria-hidden="true" id="unique-90002999span_6">
                        &times;
                      </span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Processed JSON for this drawing already exists so processing JSON again in IUI mode will overwrite all the changes done previously. Click "Yes" to process
                      again and "Cancel" to return to IUI mode
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() =>
                        this.setState({
                          enhanceWarning: false,
                          enhance: true,
                        })
                      }
                    >
                      Yes
                    </button>
                    <button type="button" className="btn btn-default" onClick={() => this.setState({ enhanceWarning: false })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.errorMessage ? (
            <div className="iui-alert">
              <div className="alert alert-warning">
                {this.state.errorMessage}
                <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
                  &times;
                </button>
              </div>
            </div>
          ) : null}

          {this.state.successMessage ? (
            <div className="modal">
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" onClick={() => this.setState({ successMessage: null })}>
                      <span aria-hidden="true" id="unique-56477051span_7">
                        &times;
                      </span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>{this.state.successMessage}</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => this.setState({ successMessage: null })}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="content p-0">
            <section className="row no-gutters">
              {!this.state.collapseLeftPanel ? (
                <div className="col-2">
                  <div
                    className="card"
                    style={{
                      borderRadius: 0,
                      height: 'calc(100vh - 30px)',
                      overflowY: 'auto',
                    }}
                  >
                    <div className="card-body p-2">
                      <div className="mb-3">
                        {this.state.pages.length && 'projectFiles' in this.props.user && this.props.user.projectFiles.files.length ? (
                          <div className="mb-0">
                            <label>Select Drawing</label>
                            <Select
                              id="unique-93581430select_1"
                              options={drawing_options.map((file) => {
                                return {
                                  label: file.drawingName,
                                  value: file.id,
                                }
                              })}
                              value={this.getActiveDrawingObject()}
                              onChange={(selectedOption) => {
                                if (!selectedOption) {
                                  return
                                }
                                this.setState({ drawingId: selectedOption.value }, () => this.fetchData(true))
                              }}
                              isMulti={false}
                              isDisabled={this.state.allowEdit}
                            />
                          </div>
                        ) : (
                          <div className="mb-0 d-inline-block">
                            Drawing Name: <i className="text-primary">{this.state.data.drawing_name}</i>
                          </div>
                        )}
                      </div>

                      <div className="dropdown mb-3 text-center">
                        <button className="btn btn-default w-100" onClick={() => this.setState({ statusToggled: !this.state.statusToggled })}>
                          Drawing Status {!this.state.statusToggled ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-up" />}
                        </button>
                        {this.state.statusToggled ? (
                          <div className="dropdown-menu">
                            <button
                              className="dropdown-item"
                              disabled={!this.state.allowed_status.includes('cleaned')}
                              onClick={() => {
                                this.updateFileStatus(true, undefined, undefined, undefined)
                              }}
                            >
                              <label className={`checkbox ${this.state.status.includes('cleaned') ? 'checked' : ''}`} /> Mark Cleaned
                            </button>
                            <button
                              className="dropdown-item"
                              disabled={!this.state.allowed_status.includes('reviewed')}
                              onClick={() => {
                                this.updateFileStatus(undefined, undefined, undefined, true)
                              }}
                            >
                              <label className={`checkbox ${this.state.status.includes('reviewed') ? 'checked' : ''}`} /> Mark Reviewed
                            </button>
                            <button
                              className="dropdown-item"
                              disabled={!this.state.allowed_status.includes('approved')}
                              onClick={() => {
                                this.updateFileStatus(undefined, true, undefined, undefined)
                              }}
                            >
                              <label className={`checkbox ${this.state.status.includes('approved') ? 'checked' : ''}`} /> Mark Approved
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <button
                        onClick={() =>
                          this.setState({
                            componentsToggled: !this.state.componentsToggled,
                          })
                        }
                        className="btn btn-default w-100 mb-2"
                      >
                        Components &nbsp; {!this.state.componentsToggled ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-up" />}
                      </button>
                      {this.state.componentsToggled ? (
                        <>
                          <div className="mb-1 bg-light border rounded">
                            <span className="btn" id="unique-60464655span_8">
                              <span
                                className="d-inline-block mr-1"
                                style={{
                                  minWidth: 15,
                                }}
                                id="unique-97400014span_9"
                              ></span>
                              <ToggleWhiteOutSwitch isToggled={this.state.whiteOutDetected} updateWhiteOutSwitch={this.updateWhiteOutSwitch} /> White Out Detected
                            </span>
                          </div>

                          <div className="mb-1 bg-light border rounded">
                            <span className="btn" id="unique-16711138span_10">
                              <span
                                className="d-inline-block mr-1"
                                style={{
                                  minWidth: 15,
                                }}
                                id="unique-61409118span_11"
                              ></span>
                              <ToggleAllSwitch isToggled={this.state.toggledAll} updateToggleAll={this.updateToggleAll} /> Toggle All
                            </span>
                          </div>

                          {this.renderComponentsTree()}
                        </>
                      ) : null}

                      <div className="mt-2">
                        <button
                          onClick={() =>
                            this.setState({
                              keyboardShortcutsToggled: !this.state.keyboardShortcutsToggled,
                            })
                          }
                          className="btn btn-default w-100 mb-2"
                        >
                          Keyboard Shortcuts &nbsp; {!this.state.keyboardShortcutsToggled ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-up"></i>}
                        </button>
                        {this.state.keyboardShortcutsToggled ? (
                          <table className="table table-sm">
                            <tbody>
                              <tr>
                                <td>
                                  <span className="key" id="unique-50220081span_12">
                                    ←
                                  </span>
                                  <span className="key" id="unique-95980566span_13">
                                    ↑
                                  </span>
                                  <span className="key" id="unique-38875173span_14">
                                    →
                                  </span>
                                  <span className="key" id="unique-74787623span_15">
                                    ↓
                                  </span>
                                </td>
                                <td className="key-function">Move Selected BBox</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-58444181span_16">
                                    ctrl
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-20452688span_17">
                                    Shift
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-60017955span_18">
                                    +
                                  </span>
                                </td>
                                <td className="key-function">Zoom In</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-30994335span_19">
                                    ctrl
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-73263058span_20">
                                    -
                                  </span>
                                </td>
                                <td className="key-function">Zoom Out</td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="key" id="unique-10456418span_21">
                                    ctrl
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-25520225span_22">
                                    mouse wheel up
                                  </span>
                                </td>
                                <td className="key-function">Zoom In</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-50040720span_23">
                                    ctrl
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-88952661span_24">
                                    mouse wheel down
                                  </span>
                                </td>
                                <td className="key-function">Zoom Out</td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="key" id="unique-45307433span_25">
                                    DELETE
                                  </span>
                                </td>
                                <td className="key-function">Delete Selected BBox</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-28843412span_26">
                                    c
                                  </span>
                                </td>
                                <td className="key-function">Copy Selected BBox</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-52563462span_27">
                                    v
                                  </span>
                                </td>
                                <td className="key-function">Paste Selected BBox</td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="key" id="unique-52958744span_28">
                                    ctrl
                                  </span>{' '}
                                  +{' '}
                                  <span className="key" id="unique-43685347span_29">
                                    d
                                  </span>
                                </td>
                                <td className="key-function">Add BBox to delete list</td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={`col`}>
                <img src={this.state.image_url} className="d-none" id="ref-img" alt="ref img" onLoad={this.handleImageLoaded} onError={this.handleImageError} />
                <div className="card" style={{ borderRadius: 0 }}>
                  <div className="card-body p-2 px-3 clearfix">
                    <div className="d-inline-block float-right">
                      {!iui_editor_hidden ? (
                        <button
                          id="iui_view_editor_toggler_btn"
                          className="btn btn-info mr-2 btn-sm"
                          onClick={() =>
                            this.setState({
                              viewEditor: !this.state.viewEditor,
                            })
                          }
                          disabled={this.state.fetchingData}
                        >
                          Editor <i className="far fa-edit"></i>
                        </button>
                      ) : null}

                      {!iui_enhance_hidden ? (
                        <button
                          id="iui_enhance_btn"
                          disabled={this.state.type.value === 'AI' || this.state.enhancing || !this.state.allowEdit || this.state.fetchingData}
                          onClick={() => {
                            if (this.state.enhance_btn_state === 'Undo Enhance') {
                              this.setState({ undo_enhance: !this.state.undo_enhance })
                            } else {
                              if (this.state.type.value === 'IUI') {
                                this.setState({
                                  enhanceWarning: true,
                                })
                              } else {
                                this.setState({
                                  enhance: !this.state.enhance,
                                })
                              }
                            }
                          }}
                          className="btn btn-warning mr-2 btn-sm text-light"
                        >
                          {this.state.enhance_btn_state}
                        </button>
                      ) : null}

                      {!iui_process_text_hidden ? (
                        <button
                          id="iui_process_text_btn"
                          className="btn btn-success mr-2 btn-sm"
                          disabled={!this.state.data.additional_info || this.state.processingTextList || !this.state.allowEdit || this.state.fetchingData}
                          onClick={() =>
                            this.setState({
                              processText: !this.state.processText,
                            })
                          }
                        >
                          Process Text {this.state.processingTextList ? <i className="fa fa-cog fa-spin"></i> : <i className="fa fa-cog"></i>}
                        </button>
                      ) : null}

                      {!iui_update_save_data_hidden ? (
                        <button
                          id="iui_update_data_btn"
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            this.setState({
                              updateData: !this.state.updateData,
                            })
                          }
                          disabled={this.state.updating || this.state.fetchingData || !['IUI', 'Process'].includes(this.state.type.value) || !this.state.allowEdit}
                        >
                          {this.state.annotation ? 'Validate & Save' : 'Update'} {!this.state.updating ? null : <i className="fa fa-spin fa-spinner"></i>}
                        </button>
                      ) : null}
                    </div>

                    {!download_iui_data_hidden ? (
                      <button
                        id="iui_download_data_btn"
                        className="btn btn-dark float-right mr-2 btn-sm"
                        onClick={() =>
                          this.setState({
                            download: !this.state.download,
                          })
                        }
                        disabled={this.state.updating || this.state.fetchingData}
                      >
                        Download Data <i className="fa fa-download" />
                      </button>
                    ) : null}

                    {this.props.user.iuiData.RemapAtrributes && this.props.user.iuiData.RemapAtrributes.length ? (
                      <div className="dropdown float-right mr-2 d-inline-block">
                        <button
                          id="iui_remap_attributes_dropdown_btn"
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            this.setState({
                              RemapAtrributesToggled: !this.state.RemapAtrributesToggled,
                            })
                          }
                          disabled={!this.state.allowEdit || this.state.updating || this.state.remapingIUIData || this.state.fetchingData}
                        >
                          Remap Attributes {!this.state.RemapAtrributesToggled ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-up" />}{' '}
                          {this.state.remapingIUIData ? <i className="fa fa-spin fa-spinner" /> : null}
                        </button>
                        {this.state.RemapAtrributesToggled ? (
                          <div className="dropdown-menu">
                            {this.props.user.iuiData.RemapAtrributes.map((attr) => {
                              return (
                                <div
                                  className="dropdown-item"
                                  key={attr}
                                  onClick={() => {
                                    let selectedRemapAttributes = this.state.selectedRemapAttributes
                                    const index = selectedRemapAttributes.findIndex((_attr) => _attr === attr)
                                    if (index > -1) {
                                      selectedRemapAttributes.splice(index, 1)
                                    } else {
                                      selectedRemapAttributes.push(attr)
                                    }
                                    this.setState({
                                      selectedRemapAttributes: selectedRemapAttributes,
                                    })
                                  }}
                                >
                                  <label className={`checkbox ${this.state.selectedRemapAttributes.includes(attr) ? 'checked' : null}`} /> &nbsp; {attr}
                                </div>
                              )
                            })}
                            <div className="dropdown-item text-center">
                              <button id="iui_remap_submit_btn" className="btn btn-primary btn-sm" onClick={() => this.setState({ remapIUIData: !this.state.remapIUIData })}>
                                Remap
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <button
                      id="iui_check_in_toggler_btn"
                      className="btn btn-sm float-right mr-2"
                      type="button"
                      onClick={() => this.updateFileStatus(undefined, undefined, this.state.allowEdit, undefined)}
                      disabled={this.state.updating || !this.state.allowCheckInCheckOut || this.state.fetchingData}
                    >
                      <span className="badge badge-light p-2" id="unique-10077136span_30">
                        {this.state.allowEdit ? 'Checked Out' : 'Checked In'}
                      </span>{' '}
                      {this.state.allowEdit ? (
                        <span className="badge badge-danger badge-pill" title="Check In" style={{ lineHeight: '18px', width: '24px' }} id="unique-68157003span_31">
                          <i className="fa fa-angle-up" />
                        </span>
                      ) : (
                        <span className="badge badge-success badge-pill" title="Check Out" style={{ lineHeight: '18px', width: '24px' }} id="unique-13256601span_32">
                          <i className="fa fa-angle-down" />
                        </span>
                      )}
                    </button>

                    <button
                      id="iui_collapse_left_panel_toggler_btn"
                      onClick={() =>
                        this.setState({
                          collapseLeftPanel: !this.state.collapseLeftPanel,
                        })
                      }
                      disabled={this.state.fetchingData}
                      className="btn btn-default mr-3 btn-sm"
                    >
                      {!this.state.collapseLeftPanel ? <i className="fa fa-angle-left" /> : <i className="fa fa-angle-right" />}
                    </button>

                    <button
                      id="iui_zoom_out_btn"
                      className="btn btn-secondary"
                      onClick={() =>
                        this.setState({
                          zoomOut: !this.state.zoomOut,
                        })
                      }
                      disabled={this.state.zoom === this.state.zoomOptions[0] || this.state.fetchingData}
                    >
                      <i className="fa fa-search-minus"></i>
                    </button>
                    <div className="d-inline-block mx-2" style={{ width: 120 }}>
                      <Select
                        id="unique-30065463select_2"
                        options={this.state.zoomOptions.map((option) => {
                          return {
                            label: `${Math.round(option * 100)} %`,
                            value: option,
                          }
                        })}
                        value={{
                          label: `${Math.round(this.state.zoom * 100)} %`,
                          value: this.state.zoom,
                        }}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            this.setState({ zoom: selectedOption.value })
                          }
                        }}
                        isMulti={false}
                        isClearable={false}
                        isDisabled={this.state.fetchingData || this.state.updating}
                      />
                    </div>
                    <button
                      id="iui_zoom_in_btn"
                      className="btn btn-secondary mr-3"
                      onClick={() =>
                        this.setState({
                          zoomIn: !this.state.zoomIn,
                        })
                      }
                      disabled={this.state.zoom === this.state.zoomOptions[this.state.zoomOptions.length - 1] || this.state.fetchingData}
                    >
                      <i className="fa fa-search-plus"></i>
                    </button>

                    <div className="d-inline-block" style={{ width: 200 }}>
                      <Select
                        options={[
                          { label: 'AI Mode', value: 'AI' },
                          { label: 'IUI Mode', value: 'IUI' },
                        ]}
                        value={this.state.type}
                        isMulti={false}
                        id="unique-10659418select_3"
                        onChange={(selectedMode) => {
                          this.setState({ type: selectedMode }, this.fetchData)
                        }}
                        isClearable={false}
                        isDisabled={this.state.fetchingData || this.state.updating}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="card"
                  style={{
                    borderRadius: 0,
                    height: 'calc(100vh - 30px - 56px)',
                  }}
                >
                  <div className="card-body p-2">
                    <div
                      className="model-configuration"
                      style={{
                        height: '53rem',
                      }}
                    >
                      {this.props.user.displayMessageCode && !this.props.user.displayMessageCode.message.includes('succ_') ? (
                        <DisplayMessage {...this.props.user.displayMessageCode} />
                      ) : null}

                      {this.state.fetchingData || !this.state.imageHeight || !this.state.imageWidth ? (
                        <div id="preload">
                          <div className="sk-folding-cube">
                            <div className="sk-cube1 sk-cube"></div>
                            <div className="sk-cube2 sk-cube"></div>
                            <div className="sk-cube4 sk-cube"></div>
                            <div className="sk-cube3 sk-cube"></div>
                          </div>
                        </div>
                      ) : this.state.imageError ? (
                        <div className="alert alert-danger mt-4">Unable to load the image. Please verify the image path to ensure it exists.</div>
                      ) : (
                        <IUIAnnotation
                          product={this.state.product}
                          img_url={this.state.image_url}
                          img_height={this.state.imageHeight}
                          img_width={this.state.imageWidth}
                          drawingData={this.state.data.data}
                          drawingName={this.state.data.drawing_name}
                          download={this.state.download}
                          annotation={this.state.annotation}
                          colorCodes={this.state.colorSet}
                          schema={this.state.schema}
                          ComponentAttributesType={this.state.componentAttrTypes}
                          selectTypeAttrs={this.state.selectTypeAttrs}
                          selectLookupDict={this.state.selectLookupDict}
                          compNameMap={this.state.compNameMap}
                          attrNameMap={this.state.attrNameMap}
                          toggledSwitchList={this.state.toggledSwitchList}
                          lineBreakRectsList={this.state.lineBreakRectsList}
                          switchListUpdated={this.state.switchListUpdated}
                          standardGroup={this.state.standardGroup}
                          geoGroup={this.state.geoGroup}
                          metaGroup={this.state.metaGroup}
                          // Zoom
                          zoomIn={this.state.zoomIn}
                          zoomOut={this.state.zoomOut}
                          zoomOptions={this.state.zoomOptions}
                          zoom={this.state.zoom}
                          updateZoomFactor={this.updateZoomFactor}
                          // Zoom End

                          canvasHeight={'53rem'}
                          currentRectHeight={'65vh'}
                          allow_edit={this.state.allowEdit}
                          editorColumns={this.state.editorColumns}
                          viewEditor={this.state.viewEditor}
                          activeEditorComponent={this.state.activeEditorComponent}
                          activeEditorComponentType={this.state.activeEditorComponentType}
                          toggleEditorView={this.toggleEditorView}
                          updateIUIData={this.updateIUIData}
                          remapIUIDataMethod={this.remapIUIData}
                          revertEnhancedData={this.revertEnhancedData}
                          remapIUIData={this.state.remapIUIData}
                          updateData={this.state.updateData}
                          processText={this.state.processText}
                          enhance={this.state.enhance}
                          undo_enhance={this.state.undo_enhance}
                          whiteOutDetected={this.state.whiteOutDetected}
                          updateCurrectRectInfo={this.updateCurrectRectInfo}
                          updateAdditionalInfoMovementTypesData={this.updateAdditionalInfoMovementTypesData}
                          update_rects_visibility_on_adding_bbox={false}
                          line_break_feature={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  fetchIUIData,
  updateIUIData,
  unlockIUI,
  remapIUIData,
  updateFileStatus,
  refreshUserAuth,
  fetchFile,
  revertEnhancedData,
  checkIUIDataState,
})(IUIDashboard)
