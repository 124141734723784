import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Input from './Fields/input'
import './Forms.css'
import { loginUser, fetchActions } from './../../store/actions'
import { getAccessToken } from './../../store/access'
// import LiveChat from './LiveChat'

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    isSignedIn: false,
    is_forcereset: false,
    captcha: false,
    loading: false,
    forgotPassword: false,
  }

  componentDidMount() {
    this.setState({ isSignedIn: this.props.user.isSignedIn }, this.changePage)
  }

  loginDataHandler = async (e) => {
    e.preventDefault()
    await this.setState({ loading: true })
    if (!this.props.user.isSignedIn && !getAccessToken()) {
      await this.props.loginUser(this.state.email, this.state.password)
      if (!this.props.user.is_forcereset) {
        await this.props.fetchActions()
      }
      this.changeState()
    }
  }

  changeState = () => {
    this.setState(
      {
        is_forcereset: this.props.user.is_forcereset,
        isSignedIn: this.props.user.isSignedIn,
        loading: false,
      },
      this.changePage
    )
  }

  changePage = () => {
    if (this.state.isSignedIn) {
      this.props.history.push('/dashboard')
    } else if (this.state.is_forcereset) {
      this.props.history.push('/reset-password')
    }
  }

  renderForgotPassword() {
    if (this.state.forgotPassword) {
      return (
        <div className="overlay" id="unique-76414420div_1">
          <div className="popup" id="unique-11306323div_2">
            <div className="header-background" id="unique-92369693div_3">
              <div className="header" id="unique-10789176div_4">
                <div
                  className="close"
                  id="unique-61131935div_5"
                  onClick={(event) =>
                    this.setState({
                      forgotPassword: !this.state.forgotPassword,
                    })
                  }
                >
                  <i className="fa fa-times" id="unique-19794329i_1"></i>
                </div>
              </div>
            </div>
            <div className="content" id="unique-74425791div_6">
              Please send email to <a href="mailto:admin@ips-ai.com">admin@ips-ai.com</a> for a new password.
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  ReCaptcha = () => {
    this.setState({ captcha: true }, this.changePage)
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  render() {
    if (this.state.captcha) {
      return (
        <div className="form-container" id="unique-69491185div_7">
          {this.renderForgotPassword()}
          <div className="form" id="unique-65201163div_8">
            <Link to="/" id="unique-83436002link_1">
              <div className="login-header text-center" id="unique-45799825div_9">
                <img src="ips-logo@3x.png" className="mb-4" alt="Logo" id="unique-50808380image_1" />
              </div>
            </Link>

            <div className="mb-3" id="unique-11261771div_10">
              Please enter an authorized username and password.{' '}
            </div>
            <form id="login_form" onSubmit={this.loginDataHandler} autoComplete="true">
              <div className="form-group" id="unique-76863866div_11">
                <Input type="email" label="Email" name="email" value={this.state.email} onChange={this.onChange} required={true} />
              </div>

              <div className="form-group" id="unique-58283271div_12">
                <Input type="password" label="Password" name="password" value={this.state.password} onChange={this.onChange} required={true} />
              </div>

              <div className="form-group" id="unique-38934456div_13">
                <Link
                  id="forgot_passward"
                  to="#"
                  onClick={() =>
                    this.setState({
                      forgotPassword: !this.state.forgotPassword,
                    })
                  }
                >
                  Forgot your password?
                </Link>
                <br />
                <Link id="reset_password" to="/reset-password">
                  Reset Password?
                </Link>
              </div>

              <div className="form-group">
                <button id="login_submit_btn" type="submit" className="btn btn-primary form-control" disabled={this.state.loading}>
                  Login {this.state.loading ? <i className="fa fa-spinner fa-spin"></i> : null}
                </button>
              </div>
            </form>
          </div>
          {/* <div><LiveChat /></div> */}
        </div>
      )
    }
    return (
      <>
        <div className="screen-loader" id="unique-19337128div_15">
          <div id="loading" />
        </div>
        <GoogleReCaptcha onVerify={(token) => this.ReCaptcha(token)} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, { loginUser, fetchActions })(Login)
