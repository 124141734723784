import React, { Component } from 'react'
import XLSX from 'xlsx'

class DisplayExcel extends Component {
  state = {
    loading: true,
    excelBase64: this.props.excelBase64,
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  downloadExcel() {
    const base64 = atob(this.props.excelBase64)
    let wb = XLSX.read(base64, { type: 'binary' })
    let wbSheet = wb.SheetNames[0]
    const worksheet = wb.Sheets[wbSheet]

    const fileData = XLSX.utils.sheet_to_html(worksheet)
    return (
      <div style={{ position: 'relative' }} id="unique-22003090div_1">
        <div style={{ height: '40.5rem', position: 'absolute', width: '100%', backgroundColor: '#fff', overflow: 'auto' }} className="border" id="unique-21130076div_2">
          <div dangerouslySetInnerHTML={{ __html: fileData }} className="viewDSExcel" id="unique-56715118div_3" />
        </div>
      </div>
    )
  }

  render() {
    return this.state.loading && this.state.excelBase64 === null ? (
      <div className="text-center" id="unique-38277194div_4">
        <div className="spinner" id="unique-68838522div_5"></div>
      </div>
    ) : (
      this.downloadExcel()
    )
  }
}

export default DisplayExcel
