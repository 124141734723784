import React from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'

import { fetchSingleProject, updateProject, fetchClientAllData } from './../../store/actions'
import Input from './Fields/input'
import Select from './Fields/select'
import Textarea from './Fields/textarea'
import './Forms.css'

class EditProject extends React.Component {
  state = {
    projectId: '',
    projectDisplayName: '',
    ips_project_number: '',
    description: '',
    ips_project_scope_of_work: '',
    tag_extraction: false,
    lastActivity: '',
    startDate: '',
    selectedUsers: [],
    selectedUsersError: null,
    selectedProduct: null,
    selectedClient: null,
    clients: [],
    ShowAdminUsers: this.props.user.role === 'SysAdmin-Admin',
    errorMessage: null,
    loading: false,
    formLoader: false,
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const projectId = parseInt(query.get('id'))

    await this.setState({ loading: true, projectId: projectId })
    await this.props.fetchClientAllData()
    await this.setState({ loading: false, clients: this.props.user.clientsAllData })
    this.fetchSingleProject()
  }

  fetchSingleProject = async () => {
    let formData = new FormData()
    formData.append('projectId', this.state.projectId)
    await this.setState({ loading: true })
    await this.props.fetchSingleProject(formData)
    await this.setState({
      loading: false,
      selectedClient: this.props.user.singleProjectData.clientIds,
      selectedUsers: this.props.user.singleProjectData.userIds,
      projectDisplayName: this.props.user.singleProjectData.displayName,
      description: this.props.user.singleProjectData.description,
      ips_project_number: this.props.user.singleProjectData.projectNumber,
      ips_project_scope_of_work: this.props.user.singleProjectData.projectScope,
      lastActivity: this.props.user.singleProjectData.lastActivity,
      startDate: this.props.user.singleProjectData.startDate,
      tag_extraction: this.props.user.singleProjectData.tag_extraction,
    })

    await this.setState({
      selectedProduct: this.productList(true),
    })
  }

  editProjectDataHandler = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('projectDisplayName', this.state.projectDisplayName)
    formData.append('description', this.state.description)
    formData.append('projectNumber', this.state.ips_project_number)
    formData.append('projectScope', this.state.ips_project_scope_of_work)
    formData.append('totalSpace', '0.5')
    formData.append('usedSpace', '0')
    formData.append('productId', JSON.stringify(this.state.selectedProduct))
    formData.append('clientId', JSON.stringify(this.state.selectedClient))
    formData.append('users', JSON.stringify(this.state.selectedUsers))

    if (!this.state.selectedUsers.length) {
      await this.setState({ selectedUsersError: 'Please assign users to this project' })
      return
    }
    await this.setState({ formLoader: true, selectedUsersError: null })
    await this.props.updateProject(formData)
    this.props.history.push(`/admin/projects`)
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderEditProjectForm() {
    return (
      <form onSubmit={(event) => this.editProjectDataHandler(event)} className="card">
        <div className="clearfix card-header bg-light" id="unique-91556714div_1">
          <button type="submit" className="btn btn-primary float-right" disabled={this.state.formLoader} id="unique-71329811button_1">
            {this.state.formLoader ? 'Saving...' : 'Save'}
          </button>
          <h3 id="unique-36236690h3_1">Edit Project</h3>
        </div>
        <div className="card-body" id="unique-77845251div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger mb-3" id="unique-67164308div_3">
              {this.state.errorMessage}
              <button type="button" className="close" onClick={() => this.setState({ errorMessage: null })}>
                {' '}
                &times;{' '}
              </button>
            </div>
          ) : null}

          <div className="row" id="unique-88008449div_4">
            <div className="col-md-6" id="unique-32901458div_5">
              <div className="form-group" id="unique-40803735div_6">
                <b>Start Date: </b> {<Moment date={this.state.startDate} format="DD-MMM-YYYY hh:mm" />}
              </div>
              <div className="form-group" id="unique-79867998div_7">
                <b>Last Activity: </b> {<Moment date={this.state.lastActivity} format="DD-MMM-YYYY hh:mm" />}
              </div>
              <div className="row" id="unique-10716653div_8">
                <div className="form-group col" id="unique-35275866div_9">
                  <Input label="Project Name" type="text" value={this.state.projectDisplayName} name="projectDisplayName" onChange={this.onChange} required={true} id="13572594" />
                </div>
                <div className="form-group col" id="unique-39817833div_10">
                  <Input
                    label="Project Number"
                    type="text"
                    value={this.state.ips_project_number}
                    name="ips_project_number"
                    onChange={this.onChange}
                    required={true}
                    id="13572594"
                  />
                </div>
              </div>

              <div className="form-group">
                <Textarea
                  name="description"
                  label="Project Description"
                  value={this.state.description ? this.state.description : ''}
                  onChange={this.onChange}
                  maxLength={500}
                  rows={4}
                  required={true}
                />
              </div>
              <div className="form-group">
                <Textarea
                  name="ips_project_scope_of_work"
                  label="Project Scope Of Work"
                  value={this.state.ips_project_scope_of_work ? this.state.ips_project_scope_of_work : ''}
                  onChange={this.onChange}
                  maxLength={250}
                  rows={4}
                  required={true}
                />
              </div>
            </div>

            <div className="col-md-6" id="unique-53337843div_13">
              <div className="form-group" id="unique-72521117div_14">
                <label id="unique-47746093label_3">
                  Assign Client{' '}
                  <span className="small text-muted" id="unique-16987001span_3">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.state.clients}
                  onChange={(selectedClient) => {
                    this.setState({
                      selectedClient: selectedClient,
                      selectedProduct: null,
                      selectedUsers: [],
                    })
                  }}
                  value={this.state.selectedClient}
                  isMulti={false}
                  isDisabled={true}
                />
              </div>
              <div className="form-group" id="unique-35191875div_15">
                <label id="unique-47428837label_4">
                  Assign Product{' '}
                  <span className="small text-muted" id="unique-76989221span_4">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.productList(false)}
                  onChange={(selectedProduct) => {
                    this.setState({ selectedProduct: selectedProduct })
                  }}
                  value={this.state.selectedProduct}
                  isMulti={false}
                  isDisabled={true}
                />
              </div>
              <div className={`form-group ${!this.tagExtractionOptionVisible() ? 'd-none' : ''}`} id="unique-60702021div_16">
                <label className={`mr-2 checkbox disabled ${this.state.tag_extraction ? 'checked' : ''}`} id="13572594" />
                Tag Extraction Mode
              </div>
              <div className="form-group" id="unique-74904590div_17">
                <div className="clearfix" id="unique-64472376div_18">
                  <label id="unique-68335631label_6">
                    Assign Users{' '}
                    <span className="small text-muted" id="unique-53840830span_5">
                      (*)
                    </span>
                  </label>
                  <span className="d-inline-block float-right d-none" id="unique-67369959span_6">
                    <label
                      onClick={() =>
                        this.setState({
                          ShowAdminUsers: !this.state.ShowAdminUsers,
                        })
                      }
                      className={`${this.state.ShowAdminUsers ? 'checked' : ''} checkbox`}
                    />{' '}
                    Show Admin Users
                  </span>
                </div>

                {this.state.selectedUsersError ? (
                  <div className="mb-2 text-danger" id="unique-82185269div_19">
                    {this.state.selectedUsersError}
                  </div>
                ) : null}
                <Select
                  options={this.listUsers()}
                  value={this.state.selectedUsers}
                  onChange={(selectedList) => {
                    this.setState({ selectedUsers: selectedList })
                  }}
                  isDisabled={!this.state.selectedClient}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  tagExtractionOptionVisible = () => {
    let visible = false
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient)
    if (index > -1) {
      this.state.clients[index].products.forEach((product) => {
        if (product.Product_id__id.toString() === this.state.selectedProduct.toString() && !['iDocuments-Datasheet', 'iDrawings-Common'].includes(product.Product_id__Name)) {
          visible = true
        }
      })
    }
    return visible
  }

  productList = (get_selected_product) => {
    if (!this.state.selectedClient) {
      return
    }
    let product_list = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())
    if (index > -1) {
      if (this.props.product) {
        this.state.clients[index].products.forEach((product) => {
          if (this.props.product === product.Product_id__Name) {
            product_list.push(product)
          }
        })
      } else {
        this.state.clients[index].products.forEach((product) => {
          if (!['iworkflow', 'iworkflow-new'].includes(product.Product_id__Name.toLowerCase())) {
            product_list.push(product)
          }
        })
      }
    }
    if (get_selected_product) {
      return product_list.find((product) => product.value === this.props.user.singleProjectData.productId)
    }
    return product_list
  }

  listUsers() {
    if (!this.state.selectedClient) {
      return
    }
    let users = []
    const index = this.state.clients.findIndex((client) => client.id.toString() === this.state.selectedClient.value.toString())

    if (index > -1) {
      users = this.state.clients[index].users
      if (!this.state.ShowAdminUsers) {
        users = users.filter((user) => !user.User_id__Default_role.split('-')[user.User_id__Default_role.split('-').length - 1].toLowerCase().includes('admin'))
      }
    }
    return users
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-50077479div_34">
          <div id="loading"></div>
        </div>
      )
    }
    return (
      <div className="dashboard admin" id="unique-11529536div_35">
        {this.renderEditProjectForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchSingleProject,
  updateProject,
  fetchClientAllData,
})(EditProject)
