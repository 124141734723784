import React from 'react'
import { connect } from 'react-redux'

import { fetchUser, updateUser, fetchRoles, fetchCompanyAllData, fetchDisciplineAllData } from './../../store/actions'
import Input from './Fields/input'
import Select from './Fields/select'
import './Forms.css'

class Account extends React.Component {
  state = {
    userId: null,
    name: '',
    email: '',
    default_role: null,
    discipline: null,
    organization: null,
    availableRoles: [],
    allOrganizations: [],
    allDisciplines: [],
    selected_projects: [],
    loading: false,
    formLoader: false,
    errorMessage: null,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const userId = parseInt(query.get('id'))
    this.setState({ userId: userId }, this.fetchData)
  }

  fetchData = async () => {
    let { userId } = this.state
    await this.setState({ loading: true })

    let formData = new FormData()
    formData.append('userId', userId)
    await this.props.fetchUser(formData)

    await this.props.fetchRoles()
    await this.setState({ allClients: this.props.user.clients })
    await this.props.fetchCompanyAllData()
    await this.props.fetchDisciplineAllData()

    const role = this.props.user.roles.find((role) => role.Name === this.props.user.user.role)
    let discipline = this.props.user.disciplineAllData.find((discipline) => discipline.id === this.props.user.user.discipline)
    let organization = this.props.user.companyAllData.find((organization) => organization.id === this.props.user.user.company_id)
    if (organization) {
      organization.label = organization.name
      organization.value = organization.id
    }

    this.setState({
      name: this.props.user.user.name,
      email: this.props.user.user.email,
      default_role: role,
      availableRoles: this.props.user.roles,
      allOrganizations: this.props.user.companyAllData,
      allDisciplines: this.props.user.disciplineAllData,
      loading: false,
      discipline: discipline,
      organization: organization,
      selected_projects: this.props.user.user.projects,
    })
  }

  editProfileDataHandler = async (e) => {
    e.preventDefault()
    await this.setState({ formLoader: true })

    let { userId, name, email, default_role, discipline, organization, selected_projects } = this.state

    if (!default_role) {
      this.setState({ errorMessage: 'Role is required!' })
      return
    }
    if (!organization) {
      this.setState({ errorMessage: 'Organization is required!' })
      return
    }

    let formData = new FormData()
    formData.append('userId', userId)
    formData.append('name', name)
    formData.append('email', email)
    formData.append('role', default_role.Name)
    formData.append('discipline', discipline.id)
    formData.append('company', organization.id)

    let projects = []
    selected_projects.forEach((project) => {
      projects.push(project.project_id)
    })
    formData.append('projects', projects)

    await this.props.updateUser(formData)
    await this.setState({ formLoader: false })

    this.props.history.push(`/admin/users`)
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderEditProfileForm() {
    let organization_selected_index = this.state.allOrganizations.findIndex((organization) => organization.id === this.state.organization.id)

    return (
      <form onSubmit={(event) => this.editProfileDataHandler(event)} className="card">
        <div className="card-header bg-light clearfix" id="unique-10411422div_1">
          <button type="submit" disabled={this.state.formLoader} className="btn btn-primary float-right" id="unique-43590410button_1">
            {this.state.formLoader ? 'Saving...' : 'Save'}
          </button>
          <h3 className="mb-0" id="unique-31919837h3_1">
            User Profile
          </h3>
        </div>
        <div className="card-body" id="unique-82957707div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger alert-dismiss" id="unique-81573807div_3">
              {this.state.errorMessage}{' '}
              <button className="close" onClick={() => this.setState({ errorMessage: null })}>
                &times;
              </button>
            </div>
          ) : null}
          <div className="row" id="unique-15466592div_4">
            <div className="col-md-5" id="unique-10718951div_5">
              <div className="form-group" id="unique-90868243div_6">
                <Input label="Name" type="text" value={this.state.name} name="name" onChange={this.onChange} required={true} id="94568449" />
              </div>
              <div className="form-group" id="unique-10557568div_7">
                <Input
                  label="Email"
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  disabled={this.state.role === 'Admin'}
                  required={true}
                  id="94568449"
                />
              </div>

              <div className="form-group" id="unique-52370500div_8">
                <label id="unique-46702352label_1">
                  Role{' '}
                  <span className="small text-muted" id="unique-20058331span_1">
                    (*)
                  </span>
                </label>
                <Select
                  value={this.state.default_role}
                  options={this.state.availableRoles}
                  onChange={(selectedRole) => {
                    this.setState({ default_role: selectedRole })
                  }}
                  isMulti={false}
                  isClearable={false}
                />
              </div>
            </div>

            <div className="col-md-7" id="unique-75303364div_9">
              <div className="form-group" id="unique-35822001div_10">
                <label id="unique-64601318label_2">Select Discipline </label>
                <Select
                  options={this.state.allDisciplines}
                  value={this.state.discipline}
                  onChange={(selectedDiscipline) => {
                    this.setState({ discipline: selectedDiscipline })
                  }}
                  isMulti={false}
                />
              </div>

              <div className="form-group" id="unique-77680284div_11">
                <label id="unique-83922324label_3">
                  Select Organization{' '}
                  <span className="small text-muted" id="unique-31233972span_2">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.state.allOrganizations}
                  value={this.state.organization}
                  onChange={(selectedOrganization) => {
                    this.setState({ organization: selectedOrganization, selected_projects: [] })
                  }}
                  isMulti={false}
                  isClearable={false}
                />
              </div>
              {this.state.organization ? (
                <div className="form-group" id="unique-25668682div_12">
                  <label id="unique-96913189label_4">Select Projects</label>
                  <Select
                    options={this.state.allOrganizations[organization_selected_index].projects}
                    value={this.state.selected_projects.map((project) => {
                      if (!('label' in project)) {
                        project.label = project.project_name
                      }
                      if (!('value' in project)) {
                        project.value = project.project_name
                      }
                      return project
                    })}
                    onChange={(selectedProjects) => {
                      this.setState({ selected_projects: selectedProjects })
                    }}
                    isMulti={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-62474170div_13">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-53636723div_14">
        {this.renderEditProfileForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchUser,
  updateUser,
  fetchRoles,
  fetchCompanyAllData,
  fetchDisciplineAllData,
})(Account)
