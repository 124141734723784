import React from 'react'
import { connect } from 'react-redux'

import './WebComponent.css'
import Input from '../Forms/Fields/input'
import { propertyConfigData, fetchPropertyConfigData, testPropertyConfigData, fetchSingleProjectData, fetchSingleProjectDrawing } from './../../store/actions'

class PropertyConfig extends React.Component {
  state = {
    propertyConfigData: null,
    projectId: null,
    product: null,
    projectPdfs: null,
    activeComponent: '',
    PropertyMap: [],
    PropertyMapOptions: {},
    testPropertyConfigResponseData: [],

    equipmentPropertyMapCollapsed: true,
    selectedDrawingViewCollapsed: true,
    equipmentPropertyMappedAttributesCollapsed: true,
    equipmentPropertyTablesCollapsed: true,

    loadingPdfDrawings: false,
    saving: false,
    finalizing: false,
    selectedDrawingId: null,
    selectDrawingView: false,
    img_url: null,
    fetchingDrawing: false,
    testing: false,
    reprocess: false,
    zoomIndex: 0.65,
  }

  componentDidMount = async () => {
    await this.fetchSingleProjectData()
    await this.setState({
      activeComponent: this.props.activeComponent,
      projectPdfs: this.props.user.singleProjectData.pdfs,
      projectId: this.props.projectId,
      product: this.props.product,
    })
    await this.fetchPropertyConfigData()
  }

  fetchSingleProjectData = async () => {
    await this.setState({ loadingPdfDrawings: true })
    await this.props.fetchSingleProjectData(this.props.projectId, true)
    await this.setState({ loadingPdfDrawings: false })
  }

  fetchPropertyConfigData = async () => {
    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('component', this.state.activeComponent)
    await this.props.fetchPropertyConfigData(formData)

    if (!this.props.user.propertyConfigDataErrorMessage) {
      if (this.props.user.propertyConfigConstants) {
        await this.setState({
          selectedDrawingId: this.props.user.propertyConfigDrawing,
          PropertyMapOptions: this.props.user.propertyConfigConstants.PropertyMapOptions,
        })

        if (this.props.user.propertyConfigData && this.props.user.propertyConfigData[this.state.activeComponent].testPropertyConfigResponseData) {
          await this.setState({
            PropertyMap: this.props.user.propertyConfigData[this.state.activeComponent].PropertyMap,
            testPropertyConfigResponseData: this.props.user.propertyConfigData[this.state.activeComponent].testPropertyConfigResponseData,
          })
        }
      }

      if (!this.state.selectedDrawingId) {
        this.setState({ selectDrawingView: true })
      } else {
        this.fetchDrawing(this.state.selectedDrawingId)
      }
    }
  }

  savePropertyConfigData = async () => {
    let PropertyConfigData = {
      Equipments: {
        PropertyMap: [],
        testPropertyConfigResponseData: [],
      },
      SafetyDevices: {
        PropertyMap: [],
        testPropertyConfigResponseData: [],
      },
    }
    PropertyConfigData[this.state.activeComponent].PropertyMap = this.state.PropertyMap
    PropertyConfigData[this.state.activeComponent].testPropertyConfigResponseData = this.state.testPropertyConfigResponseData

    let formData = new FormData()
    formData.append('drawingId', this.state.selectedDrawingId)
    formData.append('projectId', this.state.projectId)
    formData.append('propertyConfigData', JSON.stringify(PropertyConfigData))
    formData.append('component', this.state.activeComponent)

    await this.setState({ saving: true })
    await this.props.propertyConfigData(formData)
    if (this.props.user.taggingConfigDataErrorMessage) {
      await this.setState({
        errorMessage: this.props.user.taggingConfigDataErrorMessage,
      })
    }
    await this.setState({ saving: false })
  }

  fetchDrawing = async (drawing_id) => {
    await this.setState({
      selectedDrawingId: drawing_id,
      selectDrawingView: false,
      fetchingDrawing: true,
      selectedDrawingViewCollapsed: false,
      equipmentPropertyMapCollapsed: true,
      equipmentPropertyMappedAttributesCollapsed: true,
      equipmentPropertyTablesCollapsed: true,
    })
    await this.props.fetchSingleProjectDrawing(this.state.projectId, drawing_id)
    this.setState({
      img_url: this.props.user.singleProjectActiveDrawing,
      fetchingDrawing: false,
    })
  }

  testPropertyConfigData = async (mode) => {
    if (mode === 'TEST') {
      this.setState({ testing: true })
    } else {
      this.setState({ finalizing: true })
    }
    let propertyConfigData = {}
    this.state.PropertyMap.forEach((property) => {
      let data = {}
      let valueRegex = {
        LOV: property.LOV,
        isLetter: property.isLetter,
        isDigit: property.isDigit,
        special: property.special,
        length: property.length,
      }
      if (this.state.activeComponent === 'Equipments') {
        valueRegex.selectAll = property.selectAll
      }

      if (this.state.activeComponent === 'SafetyDevices') {
        valueRegex.bubbles = property.bubbles
      }

      data.valueRegex = valueRegex
      data.keyword = property.keyword
      data.unit = property.unit
      if (this.state.activeComponent === 'Equipments') {
        data.replace = property.replace
        data.by = property.by
      }

      propertyConfigData[`${property.id}_${property.name}`] = data
    })

    let formData = new FormData()
    formData.append('drawingId', this.state.selectedDrawingId)
    formData.append('product_type', this.state.product)
    formData.append('mode', mode)
    formData.append('reprocess', this.state.reprocess)
    formData.append('projectId', this.state.projectId)
    formData.append('propertyConfigData', JSON.stringify(propertyConfigData))
    formData.append('component', this.state.activeComponent)
    await this.props.testPropertyConfigData(formData)
    if (!this.props.user.propertyConfigDataErrorMessage && this.props.user.testPropertyConfigData) {
      await this.setState({
        testPropertyConfigResponseData: this.props.user.testPropertyConfigData,
      })
      this.savePropertyConfigData()
    }
    this.setState({
      testing: false,
      finalizing: false,
      equipmentPropertyMappedAttributesCollapsed: true,
      equipmentPropertyTablesCollapsed: true,
      equipmentPropertyMapCollapsed: true,
    })
  }

  zoomIn() {
    if (this.state.zoomIndex <= 2) {
      let zoomIndex = this.state.zoomIndex + 0.1
      this.setState({ zoomIndex: zoomIndex })
    }
  }

  zoomOut() {
    if (this.state.zoomIndex > 0.5) {
      let zoomIndex = this.state.zoomIndex - 0.1
      this.setState({ zoomIndex: zoomIndex })
    }
  }

  handleEquipmentFormField = (e, property_id, type = 'input', field_name, field_value) => {
    let PropertyMap = []
    this.state.PropertyMap.forEach((property) => {
      if (property.id === property_id) {
        if (type === 'input') {
          if (e.target.name === 'name' && property.name.includes('Others:')) {
            if (e.target.value.includes('Others:')) {
              property[e.target.name] = e.target.value
            }
          } else {
            property[e.target.name] = e.target.value
          }
        } else {
          property[field_name] = field_value
        }
      }
      PropertyMap.push(property)
    })
    this.setState({ PropertyMap: PropertyMap })
  }

  deleteProperty = (property_id) => {
    let PropertyMap = []
    this.state.PropertyMap.forEach((property) => {
      if (property.id !== property_id) {
        PropertyMap.push(property)
      }
    })
    this.setState({ PropertyMap: PropertyMap })
  }

  onChangeOrderHandler = (Id, up) => {
    let _propertyMapData = this.state.PropertyMap

    let index = _propertyMapData.findIndex((property) => property.id === Id)
    if (index > -1) {
      if (up) {
        if (index !== 0) {
          let target_property = _propertyMapData[index]
          _propertyMapData[index] = _propertyMapData[index - 1]
          _propertyMapData[index - 1] = target_property
        }
      } else {
        if (index < _propertyMapData.length - 1) {
          let target_property = _propertyMapData[index]
          _propertyMapData[index] = _propertyMapData[index + 1]
          _propertyMapData[index + 1] = target_property
        }
      }
    }

    this.setState({ propertyMap: _propertyMapData })
  }

  renderEquipmentPropertyMapForm = () => {
    return this.state.PropertyMap.map((property) => {
      return (
        <tr key={property.id} id="unique-33305754tr_1">
          <td id="unique-10043619td_1">
            {property.name === '' ? (
              <select onChange={(e) => this.handleEquipmentFormField(e, property.id)} name="name" className="form-control" value={property.name}>
                <option>--Select--</option>
                {this.state.activeComponent in this.state.PropertyMapOptions &&
                  this.state.PropertyMapOptions[this.state.activeComponent].map((property) => {
                    return (
                      <option key={property.name} value={property.name}>
                        {property.name}
                      </option>
                    )
                  })}
                <option value="Others:">Others</option>
              </select>
            ) : property.name.includes('Others:') ? (
              <Input type="text" name={'name'} value={property.name} path={property.id} onChange={this.handleEquipmentFormField} />
            ) : (
              property.name
            )}
          </td>
          <td id="unique-11123118td_2">
            <button className="btn btn-primary btn-sm mr-1" onClick={() => this.onChangeOrderHandler(property.id, false)}>
              <i className="fa fa-angle-down" id="unique-51666997i_1"></i>
            </button>
            <button className="btn btn-primary btn-sm" onClick={() => this.onChangeOrderHandler(property.id, true)}>
              <i className="fa fa-angle-up" id="unique-32905795i_2"></i>
            </button>
          </td>
          <td id="unique-61882198td_3">
            <input
              disabled={property.name !== '' ? false : true}
              onChange={(e) => this.handleEquipmentFormField(e, property.id)}
              type="text"
              name="keyword"
              className="form-control"
              value={property.keyword}
            />
          </td>
          <td id="unique-29503758td_4">
            <input
              disabled={property.name !== '' ? false : true}
              onChange={(e) => this.handleEquipmentFormField(e, property.id)}
              type="text"
              name="unit"
              className="form-control"
              value={property.unit}
            />
          </td>
          <td id="unique-84457185td_5">
            <input
              disabled={property.name !== '' ? false : true}
              onChange={(e) => this.handleEquipmentFormField(e, property.id)}
              type="text"
              name="LOV"
              className="form-control"
              value={property.LOV}
            />
          </td>
          <td id="unique-94126809td_6">
            {property.isLetter ? (
              <span className="d-inline-block text-center" id="unique-18300832span_1">
                <label className="checkbox checked" onClick={(e) => this.handleEquipmentFormField(e, property.id, 'checkbox', 'isLetter', false)}></label>
              </span>
            ) : (
              <span className="d-inline-block text-center" id="unique-10592699span_2">
                <label className="checkbox" onClick={(e) => this.handleEquipmentFormField(e, property.id, 'checkbox', 'isLetter', true)}></label>
              </span>
            )}
          </td>
          <td id="unique-42634261td_7">
            {property.isDigit ? (
              <span className="d-inline-block text-center" id="unique-77657575span_3">
                <label className="checkbox checked" onClick={(e) => this.handleEquipmentFormField(e, property.id, 'checkbox', 'isDigit', false)}></label>
              </span>
            ) : (
              <span className="d-inline-block text-center" id="unique-14827741span_4">
                <label className="checkbox" onClick={(e) => this.handleEquipmentFormField(e, property.id, 'checkbox', 'isDigit', true)}></label>
              </span>
            )}
          </td>
          {this.state.activeComponent === 'Equipments' ? (
            <td id="unique-29211631td_8">
              <span className="d-inline-block text-center" id="unique-21087572span_5">
                <label
                  className={`checkbox ${property.selectAll ? 'checked' : ''}`}
                  onClick={(e) =>
                    property.selectAll
                      ? this.handleEquipmentFormField(e, property.id, 'checkbox', 'selectAll', false)
                      : this.handleEquipmentFormField(e, property.id, 'checkbox', 'selectAll', true)
                  }
                ></label>
              </span>
            </td>
          ) : null}
          {this.state.activeComponent === 'SafetyDevices' ? (
            <td id="unique-10458243td_9">
              <span className="d-inline-block text-center" id="unique-56273442span_6">
                <label
                  className={`checkbox ${property.bubbles ? 'checked' : ''}`}
                  onClick={(e) =>
                    property.bubbles
                      ? this.handleEquipmentFormField(e, property.id, 'checkbox', 'bubbles', false)
                      : this.handleEquipmentFormField(e, property.id, 'checkbox', 'bubbles', true)
                  }
                ></label>
              </span>
            </td>
          ) : null}
          <td id="unique-14953415td_10">
            <input
              disabled={property.name !== '' ? false : true}
              onChange={(e) => this.handleEquipmentFormField(e, property.id)}
              type="text"
              name="special"
              className="form-control"
              value={property.special}
            />
          </td>
          <td id="unique-43451369td_11">
            <input
              disabled={property.name !== '' ? false : true}
              onChange={(e) => this.handleEquipmentFormField(e, property.id)}
              type="text"
              name="length"
              className="form-control"
              value={property.length}
            />
          </td>
          {this.state.activeComponent === 'Equipments' ? (
            <>
              <td id="unique-94395214td_12">
                <input
                  disabled={property.name !== '' ? false : true}
                  onChange={(e) => this.handleEquipmentFormField(e, property.id)}
                  type="text"
                  name="replace"
                  className="form-control"
                  value={property.replace}
                />
              </td>
              <td id="unique-10300490td_13">
                <input
                  disabled={property.name !== '' ? false : true}
                  onChange={(e) => this.handleEquipmentFormField(e, property.id)}
                  type="text"
                  name="by"
                  className="form-control"
                  value={property.by}
                />
              </td>
            </>
          ) : null}
          <td id="unique-97276649td_14">
            <i
              onClick={() => {
                this.deleteProperty(property.id)
              }}
              className="fa fa-trash-alt text-secondary"
              style={{ cursor: 'pointer' }}
            ></i>
          </td>
        </tr>
      )
    })
  }

  renderEquipmentPropertyMapTable = () => {
    return (
      <div className="table-responsive mt-4" id="unique-25760637div_1">
        <table className={'form table'}>
          <thead>
            <tr id="unique-56962447tr_2">
              <th id="unique-30648729th_1">Attribute</th>
              <th width="100" id="unique-54037242th_2">
                Order
              </th>
              <th id="unique-11354774th_3">Keyword</th>
              <th id="unique-30287336th_4">Unit</th>
              <th id="unique-20126893th_5">LOV</th>
              <th id="unique-34521793th_6">Letter</th>
              <th id="unique-98864216th_7">Digit</th>
              {this.state.activeComponent === 'Equipments' ? (
                <th width="80" id="unique-11255326th_8">
                  Select All
                </th>
              ) : (
                <th width="80" id="unique-69460328th_9">
                  Bubbles
                </th>
              )}
              <th id="unique-43718799th_10">Char Type</th>
              <th id="unique-32882118th_11">Length</th>
              {this.state.activeComponent === 'Equipments' ? <th id="unique-10917982th_12">Replace</th> : null}
              {this.state.activeComponent === 'Equipments' ? <th id="unique-74303181th_13">By</th> : null}
              <th id="unique-96578380th_14"></th>
            </tr>
          </thead>
          <tbody id="unique-40227368tbody_1">{this.renderEquipmentPropertyMapForm()}</tbody>
        </table>
      </div>
    )
  }

  renderPdfs = () => {
    if (this.state.projectPdfs) {
      return this.state.projectPdfs.map((pdf) => {
        return (
          <div className="property-pdf" key={pdf.pdfName} id="unique-10230154div_2">
            <h4 className="mb-3" id="unique-67157427h4_1">
              <b>PDF:</b> {pdf.pdfName}
            </h4>
            <div className="row mb-3" id="unique-76744012div_3">
              {this.renderPdfsDrawings(pdf.drawings)}
            </div>
          </div>
        )
      })
    }
  }

  renderPdfsDrawings = (drawings) => {
    return drawings.map((drawing) => {
      return (
        <div className="col-2" key={drawing.id} id="unique-97273320div_4">
          <div className="card mb-2" id="unique-11264298div_5">
            <div onClick={() => this.fetchDrawing(drawing.id)} className="card-body" title="Select drawing">
              {drawing.b_Id}
            </div>
          </div>
        </div>
      )
    })
  }

  moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  renderEquipmentPropertyMappedAttributesTable = () => {
    let formattedData = []
    let old_index = 0
    this.state.testPropertyConfigResponseData.forEach((property) => {
      for (const [key, value] of Object.entries(property.Properties)) {
        let index = formattedData.findIndex((obj) => obj.name === key)
        if (index > -1) {
          formattedData[index].valueList.push(value)
        } else {
          if (key === 'Tag') {
            old_index = formattedData.length
          }
          formattedData.push({
            name: key,
            valueList: [value],
          })
        }
      }
    })

    if (formattedData.length) {
      formattedData = this.moveArrayItemToNewIndex(formattedData, old_index, 0)
    }

    return (
      <div className="table-responsive mt-4" id="unique-36891298div_7">
        <table className={'form table'}>
          <tbody id="unique-40243209tbody_2">
            {formattedData.map((property) => {
              return (
                <tr key={property.name} id="unique-10261607tr_3">
                  <th id="unique-86054117th_15">{property.name}</th>
                  {property.valueList.map((value) => {
                    if (property.name === 'Tag') {
                      return (
                        <th key={value} id="unique-71277851th_16">
                          {value}
                        </th>
                      )
                    } else {
                      return (
                        <td key={value} id="unique-94172916td_15">
                          {value}
                        </td>
                      )
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  getCroppedImages = () => {
    let iterator = 0
    this.state.testPropertyConfigResponseData.forEach((property) => {
      iterator += 1
      var canvas = document.getElementById(`canvas_${iterator}`)
      var contex = canvas.getContext('2d')
      var img = document.getElementById('reference-img')
      let swidth = property.PropertyCoords.MaxX - property.PropertyCoords.MinX
      let sheight = property.PropertyCoords.MaxY - property.PropertyCoords.MinY
      contex.drawImage(img, property.PropertyCoords.MinX, property.PropertyCoords.MinY, swidth, sheight, 0, 0, swidth, sheight)
    })
  }

  renderEquipmentPropertyTables = () => {
    let iterator = 0
    return this.state.testPropertyConfigResponseData.map((property) => {
      iterator += 1
      let swidth = property.PropertyCoords.MaxX - property.PropertyCoords.MinX
      let sheight = property.PropertyCoords.MaxY - property.PropertyCoords.MinY
      return (
        <div key={property.Tag} className="col-4" id="unique-39680201div_8">
          <div className="card mb-3" style={{ overflow: 'auto' }} id="unique-37747468div_9">
            <div className="card-body" id="unique-22941119div_10">
              <canvas id={`canvas_${iterator}`} width={swidth} height={sheight}></canvas>
            </div>
          </div>
        </div>
      )
    })
  }

  addAttribute = () => {
    let max_Id = 0
    this.state.PropertyMap.forEach((attr) => {
      if (attr.id > max_Id) {
        max_Id = attr.id
      }
    })

    let newPropertyMap = this.state.PropertyMap
    let new_attr = {
      id: max_Id + 1,
      name: '',
      keyword: '',
      unit: '',
      LOV: '',
      isLetter: false,
      isDigit: false,
      special: '',
      length: '',
    }

    if (this.state.activeComponent === 'Equipments') {
      new_attr.selectAll = false
      new_attr.replace = ''
      new_attr.by = ''
    }

    if (this.state.activeComponent === 'SafetyDevices') {
      new_attr.bubbles = false
    }

    newPropertyMap.push(new_attr)

    this.setState({
      PropertyMap: newPropertyMap,
      equipmentPropertyMapCollapsed: false,
    })
  }

  render() {
    let width = 100
    width = width * this.state.zoomIndex
    width = `${width}%`
    return (
      <>
        <div className="mb-3 clearfix" id="unique-17457495div_11">
          {!this.state.selectDrawingView ? (
            <button
              className={'btn btn-primary float-right'}
              disabled={this.state.loadingPdfDrawings}
              onClick={() =>
                this.setState({
                  selectDrawingView: !this.state.selectDrawingView,
                })
              }
            >
              Select Drawing
            </button>
          ) : null}

          <button
            disabled={this.state.saving || this.state.loadingPdfDrawings}
            className={'btn btn-primary float-right mr-2'}
            onClick={this.savePropertyConfigData}
            id="unique-34152913button_4"
          >
            {this.state.saving ? 'Saving...' : 'Save'}
          </button>

          <button disabled={this.state.testing || this.state.loadingPdfDrawings} className={'btn btn-primary float-right mr-2'} onClick={() => this.testPropertyConfigData('TEST')}>
            {!this.state.testing ? 'Test' : 'Testing ...'}
          </button>

          <button
            disabled={this.state.finalizing || this.state.loadingPdfDrawings}
            className={'btn btn-primary float-right mr-2'}
            onClick={() => this.testPropertyConfigData('FINALIZE')}
          >
            {!this.state.finalizing ? 'Finalize' : 'Finalizing ...'}
          </button>

          <div className="d-inline-block float-right mr-2 mt-2" id="unique-68198882div_12">
            Reprocess &nbsp;
            <div className="d-inline-block text-center" id="unique-60278306div_13">
              <label
                className={`checkbox ${this.state.reprocess ? 'checked' : null}`}
                onClick={() =>
                  this.setState({
                    reprocess: !this.state.reprocess,
                  })
                }
              />
            </div>
          </div>

          <h4 className="font-weight-bold" id="unique-80125189h4_2">
            {this.state.activeComponent} Property Configuration
          </h4>
        </div>

        {this.state.loadingPdfDrawings ? (
          <div className="templates" id="unique-10506022div_14">
            <div className="template" id="unique-93928904div_15">
              <h1 className="text-center mt-4 mb-4" id="unique-10313147h1_1">
                <i className="fa fa-spin fa-spinner" id="14390211" />
              </h1>
            </div>
          </div>
        ) : this.state.selectDrawingView ? (
          <div className="templates" id="unique-43331448div_16">
            <div className="template" id="unique-30715243div_17">
              <div className="clearfix mb-3" id="unique-10441157div_18">
                <button
                  className="btn btn-secondary float-right"
                  onClick={() =>
                    this.setState({
                      selectDrawingView: false,
                    })
                  }
                >
                  back
                </button>
                <h3 id="unique-62785974h3_1">
                  <b>Select Drawing</b>
                </h3>
              </div>
              {this.renderPdfs()}
            </div>
          </div>
        ) : (
          <div className="templates" id="unique-56066840div_19">
            {this.props.user.propertyConfigDataErrorMessage ? (
              <div className="alert alert-danger mb-2" id="unique-17512161div_20">
                {this.props.user.propertyConfigDataErrorMessage}
              </div>
            ) : null}

            {this.state.selectedDrawingId ? (
              <div className="template" id="unique-23208444div_21">
                <div className="row" id="unique-21528209div_22">
                  <div className="col-md-3" id="unique-73160787div_23">
                    <span className="badge badge-light" id="unique-44877407span_7">
                      Selected Drawing
                    </span>
                  </div>
                  <div className="clearfix col-md-9" id="unique-66123922div_24">
                    <button
                      className="btn btn-primary float-right"
                      onClick={() =>
                        this.setState({
                          selectedDrawingViewCollapsed: !this.state.selectedDrawingViewCollapsed,
                        })
                      }
                    >
                      {!this.state.selectedDrawingViewCollapsed ? <i className="fa fa-angle-up" id="14390211" /> : <i className="fa fa-angle-down" id="14390211" />}
                    </button>
                    <button className="btn btn-secondary float-right mr-2" onClick={() => this.zoomIn()}>
                      <i className="fa fa-search-plus" id="unique-17125990i_7"></i>{' '}
                    </button>
                    <button className="btn btn-secondary float-right mr-2" onClick={() => this.zoomOut()}>
                      <i className="fa fa-search-minus" id="unique-10213667i_8"></i>{' '}
                    </button>
                  </div>
                </div>
                {!this.state.selectedDrawingViewCollapsed ? (
                  <div className="drawing-view mt-3" id="unique-36251273div_25">
                    {this.state.fetchingDrawing || !this.state.img_url ? (
                      <i className="fa fa-spin fa-spinner" id="unique-37204482i_9"></i>
                    ) : (
                      <img src={this.state.img_url} alt="Drawing" id="reference-img" style={{ width: width }} />
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="template" id="unique-96331026div_26">
              <div className="row" id="unique-11551932div_27">
                <div className="col-md-3" id="unique-24770127div_28">
                  <span className="badge badge-light" id="unique-44677775span_8">
                    {this.state.activeComponent} Property Tables
                  </span>
                </div>
                <div className="clearfix col-md-9" id="unique-19737697div_29">
                  <button
                    className="btn btn-primary float-right mr-1"
                    onClick={() => {
                      this.setState(
                        {
                          equipmentPropertyTablesCollapsed: !this.state.equipmentPropertyTablesCollapsed,
                        },
                        () => {
                          if (!this.state.equipmentPropertyTablesCollapsed) {
                            this.setState(
                              {
                                selectedDrawingViewCollapsed: false,
                              },
                              () => {
                                if (!this.state.equipmentPropertyTablesCollapsed && !this.state.selectedDrawingViewCollapsed) {
                                  this.getCroppedImages()
                                }
                              }
                            )
                          }
                        }
                      )
                    }}
                  >
                    {!this.state.equipmentPropertyTablesCollapsed ? <i className="fa fa-angle-up" id="14390211" /> : <i className="fa fa-angle-down" id="14390211" />}
                  </button>
                </div>
              </div>

              {!this.state.equipmentPropertyTablesCollapsed ? (
                <div className="row mt-3" id="unique-45050776div_30">
                  {this.renderEquipmentPropertyTables()}
                </div>
              ) : null}
            </div>

            <div className="template" id="unique-10211792div_31">
              <div className="row" id="unique-11551316div_32">
                <div className="col-md-4" id="unique-47849652div_33">
                  <span className="badge badge-light" id="unique-38339854span_9">
                    {this.state.activeComponent} Property Mapped Attributes
                  </span>
                </div>
                <div className="clearfix col-md-8" id="unique-20812385div_34">
                  <button
                    className="btn btn-primary float-right mr-1"
                    onClick={() =>
                      this.setState({
                        equipmentPropertyMappedAttributesCollapsed: !this.state.equipmentPropertyMappedAttributesCollapsed,
                      })
                    }
                  >
                    {!this.state.equipmentPropertyMappedAttributesCollapsed ? <i className="fa fa-angle-up" id="14390211" /> : <i className="fa fa-angle-down" id="14390211" />}
                  </button>
                </div>
              </div>

              {!this.state.equipmentPropertyMappedAttributesCollapsed ? this.renderEquipmentPropertyMappedAttributesTable() : null}
            </div>

            <div className="template" id="unique-90443832div_35">
              <div className="row" id="unique-70285637div_36">
                <div className="col-md-3" id="unique-42782483div_37">
                  <span className="badge badge-light" id="unique-79056995span_10">
                    {this.state.activeComponent} Property Map
                  </span>
                </div>
                <div className="clearfix col-md-9" id="unique-15707281div_38">
                  <button
                    className="btn btn-primary float-right"
                    onClick={() =>
                      this.setState({
                        equipmentPropertyMapCollapsed: !this.state.equipmentPropertyMapCollapsed,
                      })
                    }
                  >
                    {!this.state.equipmentPropertyMapCollapsed ? <i className="fa fa-angle-up" id="14390211" /> : <i className="fa fa-angle-down" id="14390211" />}
                  </button>
                  <button
                    className="btn btn-primary float-right mr-2"
                    onClick={() => {
                      this.addAttribute()
                    }}
                  >
                    Attribute <i className="fa fa-plus" id="unique-10559697i_16"></i>
                  </button>
                </div>
              </div>

              {!this.state.equipmentPropertyMapCollapsed ? this.renderEquipmentPropertyMapTable() : null}
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  propertyConfigData,
  fetchPropertyConfigData,
  testPropertyConfigData,
  fetchSingleProjectData,
  fetchSingleProjectDrawing,
})(PropertyConfig)
