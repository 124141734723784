import React from 'react'
import { connect } from 'react-redux'

import ProjectSetupS1 from './ProjectSetup_s1'
import ProjectSetupS2 from './ProjectSetup_s2'
import ProjectSetupS3 from './ProjectSetup_s3'
import ProjectSetupS4 from './ProjectSetup_s4'
import ProjectSetupS5 from './ProjectSetup_s5'
import ProjectSetupS6 from './ProjectSetup_s6'
import ProjectSetupS7 from './ProjectSetup_s7'
import ProjectSetupS8 from './ProjectSetup_s8'
import ProjectSetupS9 from './ProjectSetup_s9'
import ProjectSetupS10 from './ProjectSetup_s10'
import ProjectSetupS11 from './ProjectSetup_s11'
import ProjectSetupS12 from './ProjectSetup_s12'
import ProjectSetupS13 from './ProjectSetup_s13'

import {
  fetchProjectSetupStep,
  createProject,
  fetchUserClientAllData,
  fetchSingleProject,
  updateProject,
  fetchProjectPlantTemplate,
  updateProjectPlantTemplate,
  fetchProjectPlantComponents,
  updateProjectPlantComponents,
  fetchProjectDocumentConfig,
  updateProjectDocumentConfig,
  fetchProjectUOMInfo,
  updateProjectUOMInfo,
  fetchProjectCADInfo,
  updateProjectCADInfo,
  fetchProjectReportInfo,
  updateProjectReportInfo,
  fetchProjectDocumentInfo,
  updateProjectDocumentInfo,
  addSourceDocumentAttribute,
  fetchCompanyOptions,
  fetchProjectIPSTaginfo,
  updateProjectIPSTaginfo,
  fetchProjectTitleboxInfo,
  updateProjectTitleboxInfo,
  addTitileTypeOption,
  fetchProjectCustomParams,
  updateProjectCustomParams,
  fetchProjectAttributeMappings,
  updateProjectAttributeMappings,
  fetchProjectComponentConfigurationInfo,
  updateProjectComponentConfigurationInfo,
  clearProjectInfo,
} from './../../../store/actions'

import './../Forms.css'

class ProjectSetup extends React.Component {
  state = {
    step: undefined,
    projectId: '',
    product: '',
    dashboard: '',
    loading: false,
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const product = query.get('product')
    const projectId = query.get('id')
    const projectName = query.get('name')
    const dashboard = query.get('dashboard')

    await this.setState({
      projectId: parseInt(projectId),
      product: product,
      projectName: projectName,
      dashboard: dashboard,
    })

    if (this.state.projectId) {
      await this.setState({ loading: true })
      const formData = new FormData()
      formData.append('projectId', this.state.projectId)
      await this.props.fetchProjectSetupStep(formData)
      this.changeStep(this.props.user.projectSetupStep)
      await this.setState({ loading: false })
    } else {
      await this.props.clearProjectInfo()
    }
  }

  changeStep = (step) => {
    this.setState({ step: step })
  }

  setProjectId = (id) => {
    this.setState({ projectId: id })
  }

  closeProjectSetup = () => {
    const { product, dashboard, projectName, projectId } = this.state
    if (!this.state.product) {
      this.props.history.push(`/dashboard`)
    } else {
      if (dashboard) {
        this.props.history.push(`/${product.includes('iDrawings') ? 'pid' : this.state.product.toLowerCase()}/project-list?product=${this.state.product}`)
      } else {
        this.props.history.push(
          `/${product.includes('iDrawings') ? 'pid' : this.state.product.toLowerCase()}/project?product=${this.state.product}&name=${projectName}&id=${projectId}`
        )
      }
    }
  }

  renderProjectSetup = () => {
    let { step } = this.state
    if (step) {
      step = step.toString()
    }
    if (this.state.projectId && !this.state.step) {
      return null
    }
    if (step && step !== '1' && !this.state.projectId) {
      return null
    }

    if ((step && step === '1') || step === undefined) {
      return (
        <ProjectSetupS1
          loading={this.state.loading}
          user={this.props.user}
          product={this.state.product}
          closeProjectSetup={this.closeProjectSetup}
          editProjectId={this.state.projectId}
          fetchUserClientAllData={this.props.fetchUserClientAllData}
          createProject={this.props.createProject}
          fetchSingleProject={this.props.fetchSingleProject}
          updateProject={this.props.updateProject}
          setProjectId={this.setProjectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '2') {
      return (
        <ProjectSetupS2
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectPlantTemplate={this.props.fetchProjectPlantTemplate}
          updateProjectPlantTemplate={this.props.updateProjectPlantTemplate}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '3') {
      return (
        <ProjectSetupS3
          loading={this.state.loading}
          user={this.props.user}
          product={this.state.product}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectPlantComponents={this.props.fetchProjectPlantComponents}
          updateProjectPlantComponents={this.props.updateProjectPlantComponents}
          fetchCompanyOptions={this.props.fetchCompanyOptions}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '4') {
      return (
        <ProjectSetupS4
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectDocumentConfig={this.props.fetchProjectDocumentConfig}
          updateProjectDocumentConfig={this.props.updateProjectDocumentConfig}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '5') {
      return (
        <ProjectSetupS5
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectUOMInfo={this.props.fetchProjectUOMInfo}
          updateProjectUOMInfo={this.props.updateProjectUOMInfo}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '6') {
      return (
        <ProjectSetupS6
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectCADInfo={this.props.fetchProjectCADInfo}
          updateProjectCADInfo={this.props.updateProjectCADInfo}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '7') {
      return (
        <ProjectSetupS7
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectDocumentInfo={this.props.fetchProjectDocumentInfo}
          updateProjectDocumentInfo={this.props.updateProjectDocumentInfo}
          addSourceDocumentAttribute={this.props.addSourceDocumentAttribute}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '8') {
      return (
        <ProjectSetupS8
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectReportInfo={this.props.fetchProjectReportInfo}
          updateProjectReportInfo={this.props.updateProjectReportInfo}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '9') {
      return (
        <ProjectSetupS9
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectIPSTaginfo={this.props.fetchProjectIPSTaginfo}
          updateProjectIPSTaginfo={this.props.updateProjectIPSTaginfo}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '10') {
      return (
        <ProjectSetupS10
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectTitleboxInfo={this.props.fetchProjectTitleboxInfo}
          updateProjectTitleboxInfo={this.props.updateProjectTitleboxInfo}
          addTitileTypeOption={this.props.addTitileTypeOption}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '11') {
      return (
        <ProjectSetupS11
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectCustomParams={this.props.fetchProjectCustomParams}
          updateProjectCustomParams={this.props.updateProjectCustomParams}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '12') {
      return (
        <ProjectSetupS12
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectAttributeMappings={this.props.fetchProjectAttributeMappings}
          updateProjectAttributeMappings={this.props.updateProjectAttributeMappings}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    } else if (step && step === '13') {
      return (
        <ProjectSetupS13
          loading={this.state.loading}
          user={this.props.user}
          closeProjectSetup={this.closeProjectSetup}
          fetchProjectComponentConfigurationInfo={this.props.fetchProjectComponentConfigurationInfo}
          updateProjectComponentConfigurationInfo={this.props.updateProjectComponentConfigurationInfo}
          editProjectId={this.state.projectId}
          changeStep={this.changeStep}
        />
      )
    }
    return null
  }

  render() {
    return (
      <div className="dashboard admin" id="unique-10122571div_1">
        {this.renderProjectSetup()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchProjectSetupStep,
  createProject,
  fetchUserClientAllData,
  fetchSingleProject,
  updateProject,
  fetchProjectPlantTemplate,
  updateProjectPlantTemplate,
  fetchProjectPlantComponents,
  updateProjectPlantComponents,
  fetchProjectDocumentConfig,
  updateProjectDocumentConfig,
  fetchProjectUOMInfo,
  updateProjectUOMInfo,
  fetchProjectCADInfo,
  updateProjectCADInfo,
  fetchProjectReportInfo,
  updateProjectReportInfo,
  fetchProjectDocumentInfo,
  updateProjectDocumentInfo,
  addSourceDocumentAttribute,
  fetchCompanyOptions,
  fetchProjectIPSTaginfo,
  updateProjectIPSTaginfo,
  fetchProjectTitleboxInfo,
  updateProjectTitleboxInfo,
  addTitileTypeOption,
  fetchProjectCustomParams,
  updateProjectCustomParams,
  fetchProjectAttributeMappings,
  updateProjectAttributeMappings,
  fetchProjectComponentConfigurationInfo,
  updateProjectComponentConfigurationInfo,
  clearProjectInfo,
})(ProjectSetup)
