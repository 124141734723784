import React from 'react'
import { connect } from 'react-redux'

import {
  updateProcessDrawingsPackage,
  modelConfigurationDrawings,
  updateDrawingsPackageSelection,
  modelConfigurationStatusFlags,
  revertAllStatusFlags,
} from './../../../store/actions'

import Step21 from './step2_1'
import Step22 from './step2_2'
import Step3 from './step_3'
import Step4 from './step_4'

class Step2 extends React.Component {
  state = {
    projectName: null,
    projectId: null,
    product: null,
    clientName: null,
    selectedDrawings: [],
    selectedModelConfigurationDrawings: [],
    activeDrawing: null,
    selectedPackages: [],
    errorMessage: null,
    addingConfigDrawing: false,
    processDrawings: [],
    processDrawingsCountData: [],
    processing: false,
    fetchingData: false,
    remainingProcessingTime: 0,

    drawingsCollapsed: false,
    addMoreDrawingsToggled: false,
    screen: 1,

    updateDrawingsPackageSelection: false,
    checkingStatus: false,
    revertRemainingTime: 0,
    disableControls: false,
    underProgressDrawingsLength: 0,
  }

  componentDidMount() {
    this.setState(
      {
        projectName: this.props.projectName,
        projectId: this.props.projectId,
        product: this.props.product,
        clientName: this.props.clientName,
        selectedDrawings: this.props.selectedDrawings,
        activeDrawing: this.props.selectedDrawings.length ? this.props.selectedDrawings[0] : null,
        selectedPackages: this.props.selectedPackages,
      },
      () => {
        this.updateProcessDrawingsPackage()
      }
    )
  }

  refreshData = null

  updateProcessDrawingsPackage = async () => {
    await this.setState({ processing: true })

    const formData = new FormData()
    formData.append('projectId', this.state.projectId)
    formData.append('drawingList', this.state.selectedDrawings)
    formData.append('packageList', this.state.selectedPackages)
    formData.append('tagging', this.props.tagging)

    await this.props.updateProcessDrawingsPackage(formData)
    if (!this.props.user.displayMessageCode) {
      await this.setState({
        processDrawings: this.props.user.processDrawingsPacakgeData,
      })
      this.checkProgress()

      // Get progress on every 10 seconds
      this.refreshData = setInterval(async () => {
        await this.props.updateProcessDrawingsPackage(formData)

        if (this.props.user.displayMessageCode) {
          this.stopProgress()
        } else {
          await this.setState({
            processDrawings: this.props.user.processDrawingsPacakgeData,
          })
          this.checkProgress()
        }
      }, 10000)
    }
  }

  checkProgress = () => {
    //  status: 1 Passed
    //  status: 2: Under Progress
    //  status: -1 Failed

    let remainingProcessingTime = 0
    const TIME_FACTOR = 2.5 //mins

    this.state.processDrawings.forEach((drawing_package) => {
      if (drawing_package.status === 2) {
        remainingProcessingTime += TIME_FACTOR
      } else if (drawing_package.status === -1) {
        this.setState({ errorMessage: drawing_package.errorMessage }, this.stopProgress)
      }
    })

    this.setState({ remainingProcessingTime: remainingProcessingTime }, async () => {
      if (this.state.remainingProcessingTime === 0) {
        this.stopProgress()
        await this.setState({
          processDrawingsCountData: this.props.user.processDrawingsPacakgeCountData,
          checkingStatus: true,
        })
        this.modelConfigurationStatusFlags()
      }
    })
  }

  stopProgress = async () => {
    await this.setState({ processing: false })
    clearInterval(this.refreshData)
  }

  getSelectedDrawings = () => {
    let selectedDrawings = []
    if ('DrawingStatus' in this.props.user.processDrawingsPacakgeStatusFlags) {
      this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.forEach((drawing) => {
        if (drawing.additionalDrawing) {
          if (this.state.screen === 3) {
            selectedDrawings.push(drawing)
          }
        } else {
          selectedDrawings.push(drawing)
        }
      })
    }

    return selectedDrawings
  }

  renderSelectedDrawings = () => {
    return this.getSelectedDrawings().map((drawing) => {
      let b_Id = ''
      let pdfName = ''
      this.props.user.singleProjectData.pdfs.forEach((pdf) => {
        const index = pdf.drawings.findIndex((_drawing) => _drawing.id === drawing.id)
        if (index > -1) {
          b_Id = pdf.drawings[index].b_Id
          pdfName = pdf.pdfName
        }
      })
      return (
        <div
          onClick={() => {
            if (this.state.fetchingData) {
              return
            }

            this.setState({
              activeDrawing: drawing.id,
              addMoreDrawingsToggled: false,
            })
          }}
          className={`pdf ${this.state.activeDrawing === drawing.id ? 'active' : ''} ${this.state.fetchingData ? 'disabled' : ''}`}
          key={drawing.id}
        >
          <div className="name clearfix" id="unique-10540687div_2">
            {drawing.status === 2 && this.state.screen === 3 ? <i className="fa fa-spin fa-spinner float-right" id="41532818" /> : null}
            <i className="fa fa-image" id="unique-59314456i_2"></i>&nbsp;&nbsp;{b_Id}
            <br id="41532818" /> <b>PDF:</b> {pdfName}
          </div>
        </div>
      )
    })
  }

  modelConfigurationDrawings = async () => {
    await this.setState({
      addingConfigDrawing: true,
    })

    const formData = new FormData()
    formData.append('projectId', this.state.projectId)

    formData.append('drawingList', JSON.stringify(this.state.selectedModelConfigurationDrawings))
    formData.append('tagging', this.props.tagging)
    await this.props.modelConfigurationDrawings(formData)

    if (!this.props.user.displayMessageCode) {
      this.stopCustomSelectionProgress()
      this.modelConfigurationStatusFlags()
    }

    await this.setState({
      addingConfigDrawing: false,
    })
  }

  addModelConfigurationDrawing = async (id) => {
    let { selectedModelConfigurationDrawings } = this.state
    selectedModelConfigurationDrawings.push(id)
    await this.setState({
      selectedModelConfigurationDrawings: selectedModelConfigurationDrawings,
      addMoreDrawingsToggled: false,
    })
    this.modelConfigurationDrawings()
  }

  renderModelConfigurationDrawingOptions = () => {
    return (
      <div className="mt-2 text-center" id="unique-42378454div_3">
        <div className="btn-group" id="unique-34525810div_4">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() =>
              this.setState({
                addMoreDrawingsToggled: !this.state.addMoreDrawingsToggled,
              })
            }
            disabled={this.state.addingConfigDrawing}
          >
            {!this.state.addingConfigDrawing ? 'Add Drawing' : 'Adding Drawing ...'}
          </button>
          {this.state.addMoreDrawingsToggled ? (
            <div className="dropdown-menu" style={{ zIndex: 4001 }} id="unique-47517449div_5">
              {this.props.user.singleProjectData.pdfs.map((pdf) => {
                return pdf.drawings.map((drawing) => {
                  const index = this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.findIndex((_drawing) => _drawing.id === drawing.id)
                  return (
                    <button
                      className="dropdown-item"
                      key={drawing.id}
                      onClick={() => this.addModelConfigurationDrawing(drawing.id)}
                      disabled={this.state.selectedDrawings.includes(drawing.id) || index > -1}
                      title={`${pdf.pdfName} - ${drawing.b_Id}`}
                    >
                      <div className="d-inline-block text-truncate" style={{ maxWidth: 300 }}>
                        {this.state.selectedDrawings.includes(drawing.id) || index > -1 ? <i className="fa fa-check-circle text-success mr-2" /> : null}
                        <b>{pdf.pdfName}</b> - {drawing.b_Id}
                      </div>
                    </button>
                  )
                })
              })}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  changeScreen = async (screen) => {
    if (this.props.tagging && screen === 1) {
      this.props.changeStep(1)
      return
    }

    await this.setState({
      screen: screen,
      drawingsCollapsed: screen === 1 ? false : true,
    })

    if (!this.props.tagging && screen === 1) {
      await this.setState({ checkingStatus: true })
      this.modelConfigurationStatusFlags()
    }
  }

  updateProcessDrawingsCountData = (data) => {
    this.setState({ processDrawingsCountData: data })
  }

  updateDrawingsPackageSelection = async (save = false) => {
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('countInfo', JSON.stringify(this.state.processDrawingsCountData))
    formData.append('save', save)
    formData.append('tagging', this.props.tagging)
    await this.props.updateDrawingsPackageSelection(formData)
    if (save) {
      this.props.exit()
    } else {
      if (!this.props.user.displayMessageCode) {
        if (this.state.screen !== 2) {
          await this.setState({ disableControls: true })
          await this.modelConfigurationStatusFlags()
          await this.setState({ disableControls: false })
          this.changeScreen(3)
        }
      }
    }
  }

  refreshCustomSelectionData = null

  modelConfigurationStatusFlags = async () => {
    const formData = new FormData()
    formData.append('projectId', this.props.projectId)
    formData.append('tagging', this.props.tagging)
    await this.props.modelConfigurationStatusFlags(formData)

    if ('CheckRevertStatusFlags' in this.props.user.processDrawingsPacakgeStatusFlags && this.props.user.processDrawingsPacakgeStatusFlags.CheckRevertStatusFlags === 1) {
      this.revertAllStatusFlags()
      return
    }

    let underProgressDrawings = this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.filter((drawing) => drawing.status === 2)
    await this.setState({
      underProgressDrawingsLength: underProgressDrawings.length,
    })

    if (underProgressDrawings.length) {
      // Get progress on every 10 seconds
      this.refreshCustomSelectionData = setInterval(async () => {
        await this.props.modelConfigurationStatusFlags(formData)
        if (this.props.user.displayMessageCode) {
          this.stopCustomSelectionProgress()
        } else {
          underProgressDrawings = this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.filter((drawing) => drawing.status === 2)
          await this.setState({
            underProgressDrawingsLength: underProgressDrawings.length,
          })
          if (!underProgressDrawings.length) {
            this.stopCustomSelectionProgress()
            await this.setState({ checkingStatus: false })
            if (this.props.user.processDrawingsPacakgeStatusFlags.NextStatus === 1) {
              this.changeScreen(3)
            }

            if (this.props.user.processDrawingsPacakgeStatusFlags.NextStatus === 2) {
              this.changeScreen(4)
              return
            }
          }
        }
      }, 10000)
    } else {
      await this.setState({ checkingStatus: false })
      if (this.props.user.processDrawingsPacakgeStatusFlags.NextStatus === 1) {
        this.changeScreen(3)
      }

      if (this.props.user.processDrawingsPacakgeStatusFlags.NextStatus === 2) {
        this.changeScreen(4)
        return
      }
    }
  }

  stopCustomSelectionProgress = () => {
    clearInterval(this.refreshCustomSelectionData)
  }

  refreshRevertStatusData = null

  revertAllStatusFlags = () => {
    let { projectId } = this.state

    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('tagging', this.props.tagging)

    this.refreshRevertStatusData = setInterval(async () => {
      await this.props.revertAllStatusFlags(formData)
      await this.setState({ checkingStatus: false })
      if (this.props.user.displayMessageCode) {
        this.stopRevertAllStatusProgress()
      } else {
        let underRevertProgressDrawings = this.props.user.modelConfigurationRevertStatusFlags.DrawingsRevertStatus.filter((drawing) => drawing.reverted !== 1)
        this.setState({
          revertRemainingTime: underRevertProgressDrawings.length * 2.5,
        })
        if (!underRevertProgressDrawings.length) {
          this.stopRevertAllStatusProgress()
        }
      }
    }, 10000)
  }

  stopRevertAllStatusProgress = () => {
    clearInterval(this.refreshRevertStatusData)
  }

  renderProgressBar = (progress, remaining_progress, time, message_prefix) => {
    return (
      <div className="card" id="unique-29601395div_7">
        <div className="card-body" id="unique-46629658div_8">
          <div className="row text-center" id="unique-80045937div_9">
            <div className="col-md-4 offset-md-4" id="unique-10279801div_10">
              <div className="progress mb-3" style={{ height: 30 }} id="unique-77212517div_11">
                <div className="progress-bar" style={{ width: progress }} id="unique-30202190div_12"></div>
                <div
                  className="progress-bar bg-secondary"
                  style={{
                    width: remaining_progress,
                    overflow: 'hidden',
                  }}
                  id="unique-23466645div_13"
                >
                  <i className="fa fa-spin fa-spinner" id="unique-21620663i_4"></i>
                </div>
              </div>
              <h4 className="mb-0" id="unique-88787309h4_1">
                {message_prefix} is under progress,
                <br id="41532818" /> come back again after {time} mins.
              </h4>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderProgressBars = () => {
    let { processDrawings, remainingProcessingTime, revertRemainingTime, processing, checkingStatus } = this.state

    let progress = 0
    if (processDrawings.length) {
      progress = ((processDrawings.length * 2.5 - remainingProcessingTime) / (processDrawings.length * 2.5)) * 100
    }

    let remaining_progress = 100 - progress
    progress = `${progress}%`
    remaining_progress = `${remaining_progress}%`

    if (remainingProcessingTime > 0 || processing) {
      if (this.props.tagging) {
        return (
          <h1 className="mb-0" id="unique-93198219h1_1">
            <i className="fa fa-pulse fa-spinner" id="unique-39671871i_5"></i>
          </h1>
        )
      }
      return this.renderProgressBar(progress, remaining_progress, remainingProcessingTime, 'Processing')
    }

    if (checkingStatus) {
      return (
        <h1 className="mb-0" id="unique-10959985h1_2">
          <i className="fa fa-pulse fa-spinner" id="unique-88203286i_6"></i>
        </h1>
      )
    }

    if ('DrawingsRevertStatus' in this.props.user.modelConfigurationRevertStatusFlags) {
      let revertedDrawings = this.props.user.modelConfigurationRevertStatusFlags.DrawingsRevertStatus

      if (revertRemainingTime > 0) {
        let revert_progress = 0
        revert_progress = ((revertedDrawings.length * 2.5 - revertRemainingTime) / (revertedDrawings.length * 2.5)) * 100

        let remaining_revert_progress = 100 - revert_progress
        revert_progress = `${revert_progress}%`
        remaining_revert_progress = `${remaining_revert_progress}%`
        return this.renderProgressBar(revert_progress, remaining_revert_progress, revertRemainingTime, 'Revert')
      }
    }
  }

  updateFetchingData = async (fetchingData) => {
    await this.setState({ fetchingData: fetchingData })
  }

  componentWillUnmount() {
    this.stopProgress()
    this.stopCustomSelectionProgress()
    this.stopRevertAllStatusProgress()
  }

  render() {
    let {
      screen,
      errorMessage,
      successMessage,
      remainingProcessingTime,
      revertRemainingTime,
      processing,
      processDrawingsCountData,
      selectedPackages,
      processDrawings,
      activeDrawing,
      drawingsCollapsed,
      selectedDrawings,
      projectId,
      checkingStatus,
      disableControls,
      underProgressDrawingsLength,
    } = this.state

    let in_progress = false
    if ('DrawingStatus' in this.props.user.processDrawingsPacakgeStatusFlags) {
      this.props.user.processDrawingsPacakgeStatusFlags.DrawingStatus.forEach((drawing) => {
        if (drawing.id === activeDrawing && drawing.status === 2) {
          in_progress = true
        }
      })
    }

    return (
      <div id="unique-16570141div_14">
        {screen !== 3 && screen !== 4 && !this.props.tagging ? (
          <div className="mb-3" id="unique-88300998div_15">
            <h3 id="unique-61220830h3_1">Model Configuration</h3>
          </div>
        ) : null}

        {errorMessage ? (
          <div className="alert alert-danger mb-2" id="unique-10326587div_16">
            {errorMessage}
          </div>
        ) : null}
        {successMessage ? (
          <div className="alert alert-success mb-2" id="unique-45807572div_17">
            {successMessage}
          </div>
        ) : null}

        {this.renderProgressBars()}

        {remainingProcessingTime === 0 && !processing && !checkingStatus && revertRemainingTime === 0 ? (
          <>
            {(screen === 1 || screen === 2) && !this.props.tagging ? (
              <div className="mb-2" id="unique-19439251div_18">
                <div className="card" id="unique-77894308div_19">
                  <div className="card-body p-2" id="unique-97491498div_20">
                    <div className="clearfix" id="unique-16581568div_21">
                      <button onClick={() => this.props.changeStep(1)} className="btn btn-default" disabled={disableControls}>
                        Back
                      </button>

                      <button onClick={() => this.updateDrawingsPackageSelection(false)} className="btn btn-primary float-right" disabled={disableControls}>
                        Next
                      </button>

                      {this.state.screen === 1 ? (
                        <button
                          onClick={async () => {
                            await this.changeScreen(2)
                            this.updateDrawingsPackageSelection(false)
                            this.setState({
                              drawingsCollapsed: false,
                            })
                          }}
                          className="btn btn-primary float-right mr-2"
                          disabled={disableControls}
                        >
                          Drawing Mode <i className="fa fa-image" id="unique-37720230i_7"></i>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            this.changeScreen(1)
                            this.setState({
                              drawingsCollapsed: false,
                            })
                          }}
                          className="btn btn-primary float-right mr-2"
                          disabled={disableControls}
                        >
                          List View <i className="fa fa-list" id="unique-80465987i_8"></i>
                        </button>
                      )}
                      <button
                        onClick={() => {
                          this.updateDrawingsPackageSelection(true)
                        }}
                        className="btn btn-secondary float-right mr-2"
                        disabled={disableControls}
                      >
                        Save & Exit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={`${screen !== 4 ? 'flex-container aside' : null}`} id="unique-66613805div_22">
              {screen !== 4 ? (
                <div className={!drawingsCollapsed ? `left-flex-collapse` : `left-flex`} id="unique-87957175div_23">
                  {!drawingsCollapsed || screen === 1 ? (
                    <div
                      id="turn-around"
                      onClick={() =>
                        screen !== 1
                          ? this.setState({
                              drawingsCollapsed: !drawingsCollapsed,
                            })
                          : null
                      }
                    >
                      <ol id="unique-24922453ol_1">
                        <li id="unique-99762825li_1">
                          DRAWINGS &nbsp; <i className="fa fa-expand" id="unique-37866967i_9"></i>
                        </li>
                      </ol>
                    </div>
                  ) : (
                    <div className="pdf-list" id="unique-95275995div_24">
                      <div className="clearfix mb-3" id="unique-92902557div_25">
                        <button
                          className="close float-right"
                          onClick={() =>
                            this.setState({
                              drawingsCollapsed: !drawingsCollapsed,
                            })
                          }
                        >
                          &times;
                        </button>
                        <h5 id="unique-11119297h5_1">DRAWINGS</h5>
                      </div>
                      {this.renderSelectedDrawings()}
                      {screen === 3 ? this.renderModelConfigurationDrawingOptions() : null}
                    </div>
                  )}
                </div>
              ) : null}
              <div className={`${screen !== 4 ? 'right-flex' : null}`} id="unique-11483782div_26">
                {screen === 1 && !this.props.tagging ? (
                  <Step21
                    processDrawingsCountData={processDrawingsCountData}
                    selectedPackages={selectedPackages}
                    selectedDrawings={selectedDrawings}
                    updateProcessDrawingsCountData={this.updateProcessDrawingsCountData}
                  />
                ) : this.state.screen === 2 && !this.props.tagging ? (
                  <Step22
                    processDrawingsCountData={processDrawingsCountData}
                    processDrawings={processDrawings}
                    selectedPackages={selectedPackages}
                    activeDrawing={activeDrawing}
                    projectId={projectId}
                    drawingsCollapsed={drawingsCollapsed}
                  />
                ) : this.state.screen === 3 ? (
                  <Step3
                    activeDrawing={activeDrawing}
                    underProgressDrawingsLength={underProgressDrawingsLength}
                    projectId={projectId}
                    drawingsCollapsed={drawingsCollapsed}
                    changeScreen={this.changeScreen}
                    tagging={this.props.tagging}
                    in_progress={in_progress}
                    updateFetchingData={this.updateFetchingData}
                    fetchingData={this.state.fetchingData}
                  />
                ) : this.state.screen === 4 ? (
                  <Step4 changeScreen={this.changeScreen} projectId={projectId} tagging={this.props.tagging} />
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  updateProcessDrawingsPackage,
  modelConfigurationDrawings,
  updateDrawingsPackageSelection,
  modelConfigurationStatusFlags,
  revertAllStatusFlags,
})(Step2)
