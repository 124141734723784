import React from 'react'
import './WebComponent.css'

const NotAllowed = () => {
  return (
    <div className="dashboard main" id="unique-89286657div_1">
      <section className="row">
        <div className="col-md-4 offset-md-4 text-center" id="unique-10197181div_2">
          <div className="card not-found" id="unique-82004220div_3">
            <div className="card-body" id="unique-41475695div_4">
              <h3 id="unique-10571943h3_1">OPPS PAGE NOT ALLOWED</h3>
              <h1 id="unique-10027886h1_1">405</h1>
              <h4 id="unique-12965664h4_1">SORRY, YOU ARE NOT ALLOWED TO ACCESS THIS PAGE.</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotAllowed
