// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 45rem;
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: scroll;
}

.canvas-wrapper-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

.bird-view {
  border: solid 3px;
  z-index: 1000;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

#handle {
  position: absolute;
  bottom: -16px;
  left: -16px;
  width: 16px;
  height: 16px;
  border: 3px solid;
  cursor: grab;
}

.bird-view:hover {
  opacity: 1;
}

.key {
  font-family: monospace;
  padding: 1px 4px;
  background: linear-gradient(to bottom, #f0f0f0, #fcfcfc);
  border: 1px solid #e0e0e0;
  border-bottom-width: 1px;
  white-space: nowrap;
  color: #303030;
  border-bottom-width: 2px;
  border-radius: 3px;
  font-size: 0.8em;
  margin: 0px 3px;
}

.key-function {
  font-size: 0.85em;
}

.deliverables .bg-light .btn {
  font-size: 0.85em;
}
`, "",{"version":3,"sources":["webpack://./src/IUI/canvas.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,wDAAwD;EACxD,yBAAyB;EACzB,wBAAwB;EACxB,mBAAmB;EACnB,cAAc;EACd,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".canvas-wrapper {\n  position: relative;\n  width: 100%;\n  height: 45rem;\n  background-color: #fff;\n  border: 1px solid #ddd;\n  overflow: scroll;\n}\n\n.canvas-wrapper-inner {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  right: 0;\n  top: 0;\n}\n\n.bird-view {\n  border: solid 3px;\n  z-index: 1000;\n  opacity: 0.2;\n  position: absolute;\n  right: 0;\n  top: 0;\n  display: none;\n}\n\n#handle {\n  position: absolute;\n  bottom: -16px;\n  left: -16px;\n  width: 16px;\n  height: 16px;\n  border: 3px solid;\n  cursor: grab;\n}\n\n.bird-view:hover {\n  opacity: 1;\n}\n\n.key {\n  font-family: monospace;\n  padding: 1px 4px;\n  background: linear-gradient(to bottom, #f0f0f0, #fcfcfc);\n  border: 1px solid #e0e0e0;\n  border-bottom-width: 1px;\n  white-space: nowrap;\n  color: #303030;\n  border-bottom-width: 2px;\n  border-radius: 3px;\n  font-size: 0.8em;\n  margin: 0px 3px;\n}\n\n.key-function {\n  font-size: 0.85em;\n}\n\n.deliverables .bg-light .btn {\n  font-size: 0.85em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
