import React from 'react'
import { connect } from 'react-redux'
import './App.css'
import NavbarLeft from './Navbar/NavbarLeft'
import Navbar from './Navbar/Navbar'
import Dashboard from './Dashboard/RootDashboard'
import PidDashboard from './Dashboard/PidDashboard'
import AdminDashboard from './Dashboard/AdminDashboard'
import DatasheetDashboard from '../Datasheet/Dashboard'
import TradeDocumentDashboard from '../TradeDocument/Dashboard'
import IUIDashboard from '../IUI/IUIDashboard'
import IWorkflow from '../Components/WebComponents/iWorkflow'
import ReportList from '../Components/iWorkflowNew/ReportList'
import { refreshUserAuth } from './../store/actions'

class Main extends React.Component {
  state = {
    leftNavCollapsed: true,
    leftFlexClass: 'left-flex-collapse',
    rightFlexClass: 'right-flex',
    dashboardType: this.props.dashboardType,
    page: this.props.page,
  }

  componentDidMount() {
    this.changePage()
  }

  componentDidUpdate(props) {
    if (props.dashboardType !== this.props.dashboardType) {
      this.setState({ dashboardType: this.props.dashboardType }, this.renderPage)
    }
    if (props.page !== this.props.page) {
      this.setState({ page: this.props.page }, this.renderPage)
    }
    if (props.user.isSignedIn !== this.props.user.isSignedIn) {
      this.changePage()
    }
  }

  changePage = async () => {
    if (this.props.user.isSignedIn === undefined) {
      await this.props.refreshUserAuth()
    }

    if (this.props.user.isSignedIn === false) {
      this.props.history.push('/')
    }
  }

  collapseLeftNavTrigger = (show) => {
    this.setState({ leftNavCollapsed: show }, this.changeState)
  }

  changeState = () => {
    if (this.state.leftNavCollapsed) {
      this.setState({
        leftFlexClass: 'left-flex-collapse',
        rightFlexClass: 'right-flex',
      })
    } else {
      this.setState({
        leftFlexClass: 'left-flex flex',
        rightFlexClass: 'right-flex flex',
      })
    }
  }

  renderPage = () => {
    if (this.state.dashboardType === 'pid') {
      return <PidDashboard page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'iworkflow') {
      return <IWorkflow page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'iworkflow-new') {
      return <ReportList page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'datasheet') {
      return <DatasheetDashboard page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'tradedocument') {
      return <TradeDocumentDashboard page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'admin') {
      return <AdminDashboard page={this.state.page} {...this.props} />
    } else if (this.state.dashboardType === 'iui') {
      return <IUIDashboard {...this.props} />
    } else if (this.state.dashboardType === 'root') {
      return <Dashboard page={this.state.page} {...this.props} />
    }
    return null
  }

  renderFullPage() {
    if (this.props.user.isSignedIn) {
      return (
        <>
          <Navbar />
          <div className="flex-container" id="unique-99910093div_1">
            <aside
              className={this.state.leftFlexClass}
              // onMouseOver={() => this.collapseLeftNavTrigger(false)}
            >
              <NavbarLeft
                dashboardType={this.state.dashboardType}
                collapseLeftNavTrigger={this.collapseLeftNavTrigger}
                leftNavCollapsed={this.state.leftNavCollapsed}
                {...this.props}
              />
            </aside>
            <div
              className={this.state.rightFlexClass}
              // onMouseOver={() => this.collapseLeftNavTrigger(true)}
            >
              {this.renderPage()}
            </div>
          </div>
        </>
      )
    } else {
      return null
    }
  }

  render() {
    return this.renderFullPage()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, { refreshUserAuth })(Main)
