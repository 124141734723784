import React from 'react'
import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'
import { uploadProjectSetupFile } from './../../store/actions'

class UploadProjectSetupFiles extends React.Component {
  state = {
    projectId: null,
    errorMessage: null,
    libraryMapUploading: false,
    shapeCatalogUploading: false,
    collapseLibraryMapOptions: false,
  }

  componentDidMount() {
    this.setState({
      projectId: this.props.projectId,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.setState({
        projectId: this.props.projectId,
      })
    }
  }

  handleDocument = (files, type, libraryMapOption) => {
    const file = files[0]
    const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100MB
    const VALID_FILE_NAMES = {
      ShapeCatalog: 'ShapeCatalogue.xml',
      LibraryMap: 'LibraryMap.xlsx',
    }
    const ERROR_MESSAGES = {
      largeFile: `${file.name}: Max allowed file size is 100MB`,
      invalidShapeCatalog: 'File is not allowed (must be xml file named ShapeCatalogue.xml)',
      invalidLibraryMap: 'File is not allowed (must be Excel document named LibraryMap.xlsx)',
    }

    if (file.size > MAX_FILE_SIZE) {
      this.setState({
        errorMessage: ERROR_MESSAGES.largeFile,
      })
      return
    }

    if ((type === 'ShapeCatalog' && file.name !== VALID_FILE_NAMES.ShapeCatalog) || (type !== 'ShapeCatalog' && file.name !== VALID_FILE_NAMES.LibraryMap)) {
      this.setState({
        errorMessage: type === 'ShapeCatalog' ? ERROR_MESSAGES.invalidShapeCatalog : ERROR_MESSAGES.invalidLibraryMap,
      })
      return
    }

    this.setState(type === 'ShapeCatalog' ? { shapeCatalogUploading: true } : { libraryMapUploading: true }, () =>
      this.setState({ files, errorMessage: null }, () => this.uploadDocument(libraryMapOption))
    )
  }

  uploadDocument = async (libraryMapOption) => {
    const formData = new FormData()
    formData.append('file', this.state.files[0])
    formData.append('projectId', this.state.projectId)
    formData.append('fileName', this.state.files[0].name)
    if (libraryMapOption) {
      formData.append('libraryMapOption', libraryMapOption)
    }
    await this.props.uploadProjectSetupFile(formData)
    this.setState({
      libraryMapUploading: false,
      shapeCatalogUploading: false,
    })
  }

  renderProjectSetupUploadButtons() {
    return (
      <>
        {!this.props.uploadLibrarymap_hidden ? (
          <button
            className={`dropdown-item`}
            onClick={() => {
              this.setState({
                collapseLibraryMapOptions: !this.state.collapseLibraryMapOptions,
              })
            }}
          >
            <div className="clearfix" id="unique-79895926div_1">
              {this.state.collapseLibraryMapOptions ? (
                <i className="fa fa-caret-up float-right mt-1" id="16447486" />
              ) : (
                <i className="fa fa-caret-down float-right mt-1" id="16447486" />
              )}
              {this.state.libraryMapUploading ? (
                <i className="fa fa-spinner fa-spin" id="unique-11293016i_3"></i>
              ) : (
                <i className="fas fa-cloud-upload-alt" id="unique-66700503i_4"></i>
              )}{' '}
              Upload LibraryMap.xlsx
            </div>
          </button>
        ) : null}

        {this.state.collapseLibraryMapOptions ? (
          <div className="px-4" id="unique-91501641div_2">
            <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles, 'LibraryMap', 'SPPID')}>
              {({ getRootProps, getInputProps }) => (
                <button {...getRootProps()} className="dropdown-item" id="unique-11292765button_2">
                  <input
                    {...getInputProps()}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple={false}
                    id="16447486"
                  />
                  <i className="fa fa-file-excel" id="unique-65783394i_5"></i> SPPID
                </button>
              )}
            </Dropzone>

            <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles, 'LibraryMap', 'Aveva')}>
              {({ getRootProps, getInputProps }) => (
                <button {...getRootProps()} className="dropdown-item" id="unique-68839453button_3">
                  <input
                    {...getInputProps()}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple={false}
                    id="16447486"
                  />
                  <i className="fa fa-file-excel" id="unique-60820799i_6"></i> Aveva
                </button>
              )}
            </Dropzone>
          </div>
        ) : null}

        {!this.props.uploadShapeCatalog_hidden ? (
          <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles, 'ShapeCatalog', null)}>
            {({ getRootProps, getInputProps }) => (
              <button {...getRootProps()} className={`dropdown-item`} id="unique-34011522button_4">
                <input {...getInputProps()} multiple={false} accept="text/xml" id="16447486" />
                {this.state.shapeCatalogUploading ? (
                  <i className="fa fa-spinner fa-spin" id="unique-47832082i_7"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt" id="unique-65541898i_8"></i>
                )}{' '}
                Upload ShapeCatalogue.xml
              </button>
            )}
          </Dropzone>
        ) : null}
      </>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <div className="mt-2 alert alert-danger" role="alert">
            {this.state.errorMessage}{' '}
            <button className="close" onClick={() => this.setState({ errorMessage: null })}>
              &times;
            </button>
          </div>
        ) : null}
        {this.renderProjectSetupUploadButtons()}
      </React.Fragment>
    )
  }
}

export default connect(null, { uploadProjectSetupFile })(UploadProjectSetupFiles)
