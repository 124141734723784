import React from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'

import { createProduct } from '../../store/actions'
import Input from './Fields/input'
import Textarea from './Fields/textarea'
import './Forms.css'

class CreateProduct extends React.Component {
  state = {
    name: '',
    file: null,
    previewUrl: null,
    description: '',
    formLoader: false,
    errorMessage: null,
    fileErrorMessage: null,
  }

  handleDocument(files) {
    if (files[0].size > 5 * 1024 * 1024) {
      this.setState({
        fileErrorMessage: `${files[0].name}: Max allowed file size is 5MB`,
      })
      return
    }

    if (['image/jpg', 'image/png', 'image/jpeg'].includes(files[0].type)) {
      this.setState({
        previewUrl: URL.createObjectURL(files[0]),
        file: files,
        fileErrorMessage: null,
      })
    } else {
      this.setState({
        fileErrorMessage: 'File type is not allowed (must be PNG, JPEG or JPG image)',
      })
    }
  }

  createProductDataHandler = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('name', this.state.name)
    formData.append('description', this.state.description)
    if (this.state.file) {
      formData.append('file', this.state.file[0])
      await this.setState({ formLoader: true, fileErrorMessage: null })
      await this.props.createProduct(formData)
      this.setState({ formLoader: false })
      this.props.history.push('/admin/products')
    } else {
      this.setState({ fileErrorMessage: 'Please upload file' })
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderCreateProductForm() {
    return (
      <form onSubmit={(event) => this.createProductDataHandler(event)} className="card">
        <div className="clearfix card-header bg-light" id="unique-50057461div_1">
          <button type="submit" className="btn btn-primary float-right" id="unique-23041351button_1">
            Create {this.state.formLoader ? <i className="fa fa-spinner fa-spin" id="unique-92163575i_1"></i> : null}
          </button>
          <h3 className="mb-0" id="unique-36936542h3_1">
            Create Product
          </h3>
        </div>
        <div className="card-body" id="unique-66086469div_2">
          <div className="row" id="unique-18196131div_3">
            <div className="col-md-5" id="unique-94763488div_4">
              <div className="form-group" id="unique-20833714div_5">
                <Input label="Product Name" type="text" value={this.state.name} name="name" onChange={this.onChange} required={true} id="24569045" />
              </div>
              <div className="form-group">
                <Textarea
                  id="unique-57523012textarea_6"
                  name="description"
                  label="Product Description"
                  value={this.state.description}
                  onChange={this.onChange}
                  maxLength={500}
                  rows={4}
                  required={true}
                />
              </div>
            </div>
            <div className="col-md-7" id="unique-10063604div_7">
              <div className="form-group" id="unique-72634607div_8">
                <label id="unique-10899366label_2">
                  {' '}
                  Product Logo <span className="small text-muted">(*)</span>
                </label>
                <Dropzone onDrop={(acceptedFiles) => this.handleDocument(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      {this.state.file === null ? (
                        <div id="unique-31625786div_9">
                          <button {...getRootProps()} className="upload-content btn btn-primary" type="button" id="unique-11313821button_2">
                            <input accept="image/png, image/jpeg, image/jpg" {...getInputProps()} multiple={false} id="24569045" />
                            Select File <i className="fas fa-cloud-upload-alt" id="unique-34973172i_2"></i>
                          </button>
                          {this.state.fileErrorMessage ? (
                            <p className="mt-2 text-danger" id="unique-40382216p_1">
                              {this.state.fileErrorMessage}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div id="unique-61893381div_10">
                          <div className="img-container" id="unique-39780273div_11">
                            <img src={this.state.previewUrl} className="img-fluid" alt="preview" id="24569045" />
                          </div>
                          <div className="mt-2" id="unique-84986102div_12">
                            File Name: {this.state.file[0].name}
                            <i
                              className="text-danger"
                              onClick={() =>
                                this.setState({
                                  file: null,
                                  previewUrl: null,
                                })
                              }
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              {' '}
                              Remove{' '}
                            </i>
                          </div>
                        </div>
                      )}
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-18769785div_13">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-42067733div_14">
        {this.renderCreateProductForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, { createProduct })(CreateProduct)
