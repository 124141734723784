import React, { Component } from 'react'
import Select from '../Forms/Fields/select'
import { Document, Page, pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default class ViewPDF extends Component {
  state = {
    numPages: 0,
    pageNumber: 1,
    zoom: 0.75,
    rotationAngle: 0,
    zoomOptions: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8.5, 9.5, 10, 10.5, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16],
    isDragging: false,
    lastMouseX: 0,
    lastMouseY: 0,
    loading: true,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages, loading: false })
  }

  zoomInPage = () => {
    const { zoom, zoomOptions } = this.state
    const currentIndex = zoomOptions.indexOf(zoom)
    if (currentIndex < zoomOptions.length - 1) {
      this.setState({ zoom: zoomOptions[currentIndex + 1] })
    }
  }

  zoomOutPage = () => {
    const { zoom, zoomOptions } = this.state
    const currentIndex = zoomOptions.indexOf(zoom)
    if (currentIndex > 0) {
      this.setState({ zoom: zoomOptions[currentIndex - 1] })
    }
  }

  rotateClockwise = () => {
    this.setState((prevState) => ({
      rotationAngle: (prevState.rotationAngle + 90) % 360,
    }))
  }

  rotateCounterClockwise = () => {
    this.setState((prevState) => ({
      rotationAngle: (prevState.rotationAngle - 90 + 360) % 360,
    }))
  }

  goToPrevPage = () => (this.state.pageNumber > 1 ? this.setState((state) => ({ pageNumber: state.pageNumber - 1 })) : null)

  goToNextPage = () => (this.state.pageNumber < this.state.numPages ? this.setState((state) => ({ pageNumber: state.pageNumber + 1 })) : null)

  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      lastMouseX: e.clientX,
      lastMouseY: e.clientY,
    })
  }

  handleMouseMove = (e) => {
    if (this.state.isDragging) {
      const { lastMouseX, lastMouseY } = this.state
      const deltaX = e.clientX - lastMouseX
      const deltaY = e.clientY - lastMouseY

      const container = e.currentTarget
      container.scrollLeft -= deltaX
      container.scrollTop -= deltaY

      this.setState({
        lastMouseX: e.clientX,
        lastMouseY: e.clientY,
      })
    }
  }

  handleMouseUp = () => {
    this.setState({ isDragging: false })
  }

  render() {
    const { pageNumber, numPages, zoom, zoomOptions, rotationAngle } = this.state
    return (
      <div style={{ position: 'relative' }} id="unique-22580690div_1">
        <div className="btn-toolbar btnToolbarArea p-2" role="toolbar" aria-label="Toolbar with button groups" id="unique-98558684div_2">
          {/* Zoom buttons */}
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.zoomOutPage} disabled={zoom === zoomOptions[0]} id="unique-41467383button_1">
            <i className="fa fa-search-minus" id="unique-27503404i_1" />
          </button>
          <div className="d-inline-block mx-2" style={{ width: 120 }} id="unique-11171891div_3">
            <Select
              options={zoomOptions.map((option) => {
                return {
                  label: `${Math.round(option * 100)} %`,
                  value: option,
                }
              })}
              value={{
                label: `${Math.round(zoom * 100)} %`,
                value: zoom,
              }}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  this.setState({ zoom: selectedOption.value })
                }
              }}
              isMulti={false}
              isClearable={false}
            />
          </div>
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.zoomInPage} disabled={zoom === zoomOptions[zoomOptions.length - 1]} id="unique-43368060button_2">
            <i className="fa fa-search-plus" id="unique-73851158i_2" />
          </button>

          {/* Rotate buttons */}
          <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={this.rotateCounterClockwise} id="unique-32613663button_3">
            <i className="fa fa-undo" id="unique-31578776i_3" />
          </button>
          <button className="btn mx-1" disabled={true} id="unique-11250121button_4">
            {this.state.rotationAngle}
          </button>
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.rotateClockwise} id="unique-81588205button_5">
            <i className="fa fa-redo" id="unique-50470771i_4" />
          </button>

          {/* Navigation buttons */}
          <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={this.goToPrevPage} id="unique-73545080button_6">
            <i className="fa fa-angle-left" id="unique-88610922i_5" />
          </button>
          <div className="pagination" id="unique-59302256div_4">
            <button className="btn btn-secondary btn-sm mx-1" id="unique-11473480button_7">
              {pageNumber} / {numPages}
            </button>
          </div>
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.goToNextPage} id="unique-32201979button_8">
            <i className="fa fa-angle-right" id="unique-10892063i_6" />
          </button>
        </div>
        <div
          style={{ height: '40.5rem', width: '100%', overflow: 'auto', cursor: 'grab', position: 'absolute' }}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
        >
          <Document
            error={
              <div className="text-center text-danger mt-4">
                <i className="fa fa-exclamation-circle" /> An error occurred while fetching file content.
              </div>
            }
            file={`data:application/pdf;base64,${this.props.pdfBase64}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={zoom} rotate={rotationAngle} />
          </Document>
        </div>
      </div>
    )
  }
}
