import React, { Component } from 'react'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS2 extends Component {
  state = {
    plantTemplate: [],
    selected: null,
    loading: false,
    formLoader: false,
    exit: false,
    screen_blocked: false,
    warningMessage: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectPlantTemplate(formData)
    await this.setState({
      plantTemplate: this.props.user.projectInfo.template_data,
      selected: this.props.user.projectInfo.selected,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
    await this.setState({ loading: false })
  }

  formDataHandler = async () => {
    await this.setState({ formLoader: true, warningMessage: false })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('selectedTemplateId', this.state.selected)
    formData.append('step', 2)
    await this.props.updateProjectPlantTemplate(formData)

    await this.setState({ formLoader: false })
    if (!this.props.user.projectInfo.isLast && !this.state.exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (this.state.exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  renderWarningMessage = () => {
    return (
      <div className="modal">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => this.setState({ warningMessage: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>If you proceed with changing the template, Plant Information data will be lost. Are you sure you want to continue?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => this.formDataHandler()}>
                Confirm
              </button>
              <button type="button" className="btn btn-default" onClick={() => this.setState({ warningMessage: false })}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  checkWarningMessage = async (exit = false) => {
    if (!this.props.user.projectInfo.selected || this.props.user.projectInfo.selected === this.state.selected) {
      this.formDataHandler()
    } else {
      await this.setState({ exit: exit })
      await this.setState({ warningMessage: true })
    }
  }

  renderForm() {
    if (!this.props.user.projectInfo) {
      return null
    }
    return (
      <React.Fragment>
        {this.state.warningMessage ? this.renderWarningMessage() : null}

        <form onSubmit={(e) => this.formDataHandler(e)} className="card">
          <div className="clearfix card-header bg-light">
            <button type="button" onClick={() => this.checkWarningMessage(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
              {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
            </button>

            <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
              Back
            </button>

            <button type="button" onClick={() => this.checkWarningMessage(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
              Save & Exit
            </button>

            <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2">
              Cancel
            </button>
            <h3>Plant Business Structure (PBS) {this.state.screen_blocked ? <i className="fa fa-lock ml-2" /> : null}</h3>
          </div>
          <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`}>
            {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
              <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
            ) : null}

            <div className="row">
              {this.state.plantTemplate.map((template) => {
                return (
                  <div className="col-4 mb-3" key={template.id}>
                    <div className="card bg-light p-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={() =>
                            this.setState({
                              selected: template.id,
                            })
                          }
                          id={template.id}
                          checked={this.state.selected === template.id}
                        />
                        <label className="form-check-label" htmlFor={template.id}>
                          {template.Name}
                        </label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
