import React, { Component } from 'react'

import Select from '../../Forms/Fields/select'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS4 extends Component {
  state = {
    disciplines: [],
    duplicate_discipline_field_error: false,

    selected_disciplines: [],
    selected_documents: [],
    duplicate_document_type_field_error: false,

    removed_disciplines: [],
    removed_documents: [],
    warningMessage: false,
    loading: false,
    formLoader: false,
    exit: false,
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectDocumentConfig(formData)

    let selected_disciplines = []
    this.props.user.projectInfo.disciplines.forEach((discipline) => {
      if (this.props.user.projectInfo.selected_disciplines.includes(discipline.id)) {
        selected_disciplines.push(discipline)
      }
    })

    let selected_documents = []
    selected_disciplines.forEach((discipline) => {
      discipline.DocumentTypes.forEach((document) => {
        if (this.props.user.projectInfo.selected_documenttypes.includes(document.id)) {
          selected_documents.push(document)
        }
      })
    })

    await this.setState({
      disciplines: this.props.user.projectInfo.disciplines,
      selected_disciplines: selected_disciplines,
      selected_documents: selected_documents,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
    await this.setState({ loading: false })
  }

  formDataHandler = async (e) => {
    e.preventDefault()
    await this.setState({ formLoader: true })
    let { selected_disciplines, selected_documents } = this.state

    const formData = new FormData()
    formData.append('step', 4)
    formData.append('projectId', this.props.editProjectId)
    formData.append('disciplineInfo', JSON.stringify(selected_disciplines))
    formData.append('documentTypeInfo', JSON.stringify(selected_documents))
    await this.props.updateProjectDocumentConfig(formData)

    await this.setState({ formLoader: false })
    if (!this.props.user.projectInfo.isLast && !this.state.exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (this.state.exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  updateSelectedDocumentsList = (id) => {
    let selected_documents = this.state.selected_documents
    const index = selected_documents.findIndex((_id) => _id === id)
    if (index > -1) {
      selected_documents.splice(index, 1)
    } else {
      selected_documents.push(parseInt(id))
    }
    this.setState({ selected_documents: selected_documents })
  }

  handleDisciplinesInputChange = (e, id) => {
    let { disciplines } = this.state
    if (disciplines.findIndex((discipline) => discipline[e.target.name] === e.target.value) > -1) {
      this.setState({ duplicate_discipline_field_error: true })
      return null
    }
    disciplines.forEach((discipline) => {
      if (discipline.id === id) {
        discipline[e.target.name] = e.target.value
      }
    })
    this.setState({
      disciplines: disciplines,
      duplicate_discipline_field_error: false,
    })
  }

  handleDocumentsInputChange = (e, dis_id, doc_id) => {
    let { disciplines } = this.state
    let duplicate_document_type_field_error = false
    disciplines.forEach((discipline) => {
      if (discipline.id === dis_id) {
        if (discipline.DocumentTypes.findIndex((document) => document[e.target.name] === e.target.value) === -1 || e.target.name === 'Code') {
          discipline.DocumentTypes.forEach((document) => {
            if (document.id === doc_id) {
              document[e.target.name] = e.target.value
            }
          })
          duplicate_document_type_field_error = false
        } else {
          duplicate_document_type_field_error = true
        }
      }
    })
    if (duplicate_document_type_field_error) {
      this.setState({ duplicate_document_type_field_error: true })
    } else {
      this.setState({
        disciplines: disciplines,
        duplicate_document_type_field_error: false,
      })
    }
  }

  onSelectDiscipline = (selectedList) => {
    let { removed_disciplines, selected_disciplines } = this.state

    const selectedItem = selectedList.filter((discipline) => !selected_disciplines.some((selected_discipline) => discipline.id === selected_discipline.id))[0]

    const index = removed_disciplines.findIndex((discipline) => discipline.id === selectedItem.id)
    if (index > -1) {
      removed_disciplines.splice(index, 1)
    }
    this.setState({
      selected_disciplines: selectedList,
      removed_disciplines: removed_disciplines,
    })
  }

  onRemoveDiscipline = (selectedList) => {
    let { disciplines, selected_disciplines, selected_documents, removed_disciplines, removed_documents } = this.state
    const removedItem = selected_disciplines.filter((selected_discipline) => !selectedList.some((discipline) => discipline.id === selected_discipline.id))[0]

    this.setState({ selected_disciplines: selectedList })

    removed_disciplines.push(removedItem)
    disciplines.forEach((discipline) => {
      if (!(selectedList.findIndex((_discipline) => _discipline.id === discipline.id) > -1)) {
        discipline.DocumentTypes.forEach((document) => {
          const index = selected_documents.findIndex((_document) => _document.id === document.id)
          if (index > -1) {
            removed_documents.push(selected_documents[index])
          }
          selected_documents = selected_documents.filter((_document) => _document.id !== document.id)
        })
      }
    })

    this.setState({
      selected_documents: selected_documents,
      removed_disciplines: removed_disciplines,
      removed_documents: removed_documents,
    })
  }

  onSelectDocument = (selectedList) => {
    let { removed_documents, selected_documents } = this.state
    const selectedItem = selectedList.filter((document) => !selected_documents.some((selected_document) => document.id === selected_document.id))[0]

    const index = removed_documents.findIndex((document) => document.id === selectedItem.id)
    if (index > -1) {
      removed_documents.splice(index, 1)
    }
    this.setState({
      selected_documents: selectedList,
      removed_documents: removed_documents,
    })
  }

  onRemoveDocument = (selectedList) => {
    let { removed_documents, selected_documents } = this.state
    const removedItem = selected_documents.filter((selected_document) => !selectedList.some((document) => document.id === selected_document.id))[0]

    removed_documents.push(removedItem)
    this.setState({
      selected_documents: selectedList,
      removed_documents: removed_documents,
    })
  }

  renderForm() {
    if (!this.props.user.projectInfo) {
      return null
    }

    let { selected_disciplines, removed_disciplines, removed_documents, selected_documents, formLoader } = this.state
    let documents = []
    selected_disciplines.forEach((discipline) => {
      discipline.DocumentTypes.forEach((document) => {
        documents.push(document)
      })
    })
    return (
      <form onSubmit={(e) => this.formDataHandler(e)} className="card">
        {this.state.warningMessage ? (
          <div className="modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Removed <b>Discipline</b> and <b>Document</b> Types
                  </h5>
                  <button type="button" className="close" onClick={() => this.setState({ warningMessage: false })}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  {removed_disciplines.length ? (
                    <div className="mb-3">
                      <label>You have removed following disciplines:</label>
                      <ol style={{ fontSize: '18px' }}>
                        {removed_disciplines.map((discipline) => {
                          return <li key={discipline.id}>{discipline['IPS Name']}</li>
                        })}
                      </ol>
                    </div>
                  ) : null}

                  {removed_documents.length ? (
                    <div className="mb-3">
                      <label>You have removed following documents:</label>
                      <ol style={{ fontSize: '18px' }}>
                        {removed_documents.map((document) => {
                          return <li key={document.id}>{document['IPS Name']}</li>
                        })}
                      </ol>
                    </div>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btn btn-secondary" disabled={this.state.formLoader}>
                    OK
                  </button>
                  <button type="button" className="btn btn-default" onClick={() => this.setState({ warningMessage: false })}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="clearfix card-header bg-light">
          <button
            type={removed_disciplines.length || removed_documents.length ? 'button' : 'submit'}
            onClick={async () => {
              await this.setState({ exit: false })
              if (removed_disciplines.length || removed_documents.length) {
                await this.setState({ warningMessage: true })
              }
            }}
            disabled={formLoader || !selected_disciplines || !selected_disciplines.length || !selected_documents || !selected_documents.length}
            className="btn btn-primary float-right"
          >
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button
            type={removed_disciplines.length || removed_documents.length ? 'button' : 'submit'}
            onClick={() => {
              this.setState({ exit: true })
              if (removed_disciplines.length || removed_documents.length) {
                this.setState({ warningMessage: true })
              }
            }}
            disabled={formLoader || !selected_disciplines || !selected_disciplines.length || !selected_documents || !selected_documents.length}
            className="btn btn-primary float-right mr-2"
          >
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2">
            Cancel
          </button>

          <h3 className="mb-2">Document/Discipline Information & Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" /> : null}</h3>
          <h5 className="text-muted">
            <b>Note:</b> Removal of any discipline & document type, will remove all corresponding documents.
          </h5>
        </div>
        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`}>
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}
          <div className="card bg-light w-100">
            <div className="card-body p-2">
              <div className="clearfix mb-2">
                <label>Disciplines</label> <span className="text-muted small">(*)</span>
                <button
                  type="button"
                  className="btn btn-default btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      selected_disciplines: [],
                      selected_documents: [],
                    })
                  }
                >
                  <i className="fa fa-undo"></i>
                </button>
                <span className="float-right d-inline-block mr-2 mt-1">
                  <label
                    className={`checkbox mr-1 ${this.state.disciplines.length === this.state.selected_disciplines.length ? 'checked' : null}`}
                    onClick={() => {
                      if (this.state.disciplines.length === this.state.selected_disciplines.length) {
                        this.setState({
                          selected_disciplines: [],
                          selected_documents: [],
                        })
                      } else {
                        this.setState({
                          selected_disciplines: this.state.disciplines,
                        })
                      }
                    }}
                  />{' '}
                  Select All
                </span>
              </div>
              <Select
                options={this.state.disciplines}
                value={this.state.selected_disciplines}
                onChange={(selectedList) => {
                  if (selectedList.length > this.state.selected_disciplines.length) {
                    this.onSelectDiscipline(selectedList)
                  } else {
                    this.onRemoveDiscipline(selectedList)
                  }
                }}
                isMulti={true}
              />
            </div>
          </div>
          <div className="mt-3">
            {this.state.duplicate_discipline_field_error ? (
              <div className="alert alert-danger alert-dismissible">
                Duplicate entries are not allowed!
                <button
                  type="button"
                  className="close"
                  onClick={() =>
                    this.setState({
                      duplicate_discipline_field_error: false,
                    })
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <div className="table-container p-0">
              <table className="table table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>IPS Name (CFIHOS)</th>
                    <th>Discipline Name</th>
                    <th>Discipline Code</th>
                    <th>Discipline Description</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selected_disciplines.map((discipline) => {
                    return (
                      <tr key={discipline.id}>
                        <td>{discipline['IPS Name']}</td>
                        <td>
                          <input
                            className="form-control"
                            name="Name"
                            value={discipline.Name}
                            onChange={(e) => this.handleDisciplinesInputChange(e, discipline.id)}
                            disabled={!this.props.captcha}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            name="Code"
                            value={discipline.Code}
                            onChange={(e) => this.handleDisciplinesInputChange(e, discipline.id)}
                            disabled={!this.props.captcha}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            name="Description"
                            value={discipline.Description}
                            onChange={(e) => this.handleDisciplinesInputChange(e, discipline.id)}
                            disabled={!this.props.captcha}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-3">
            <div className="card bg-light w-100">
              <div className="card-body p-2">
                <div className="clearfix mb-2">
                  <label>Document Types</label> <span className="text-muted small">(*)</span>
                  <button
                    type="button"
                    className="btn btn-default btn-sm float-right"
                    onClick={() =>
                      this.setState({
                        selected_documents: [],
                      })
                    }
                  >
                    <i className="fa fa-undo"></i>
                  </button>
                  <span className="float-right d-inline-block mr-2 mt-1">
                    <label
                      className={`checkbox mr-1 ${documents.length && documents.length === this.state.selected_documents.length ? 'checked' : null}`}
                      onClick={() => {
                        if (documents.length === this.state.selected_documents.length) {
                          this.setState({
                            selected_documents: [],
                          })
                        } else {
                          this.setState({
                            selected_documents: documents,
                          })
                        }
                      }}
                    />{' '}
                    Select All
                  </span>
                </div>
                <Select
                  options={documents}
                  onChange={(selectedList) => {
                    if (selectedList.length > this.state.selected_documents.length) {
                      this.onSelectDocument(selectedList)
                    } else {
                      this.onRemoveDocument(selectedList)
                    }
                  }}
                  isMulti={true}
                  value={this.state.selected_documents}
                />
              </div>
            </div>
            <div className="mt-3">
              {this.state.duplicate_document_type_field_error ? (
                <div className="alert alert-danger alert-dismissible">
                  Duplicate entries are not allowed!
                  <button
                    type="button"
                    className="close"
                    onClick={() =>
                      this.setState({
                        duplicate_document_type_field_error: false,
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}

              <div className="table-container p-0">
                <table className="table table-striped">
                  <thead className="thead-light">
                    <tr>
                      <th>IPS Name (CFIHOS)</th>
                      <th>Document Type Name</th>
                      <th>Document Type Code</th>
                      <th>Document Type Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.selected_disciplines.map((discipline) => {
                      return discipline.DocumentTypes.map((document) => {
                        if (this.state.selected_documents.findIndex((_document) => document.id === _document.id) > -1) {
                          return (
                            <tr key={document.id}>
                              <td>{document['IPS Name']}</td>
                              <td>
                                <input
                                  className="form-control"
                                  name="Name"
                                  value={document.Name}
                                  onChange={(e) => this.handleDocumentsInputChange(e, discipline.id, document.id)}
                                  disabled={!this.props.captcha}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  name="Code"
                                  value={document.Code}
                                  onChange={(e) => this.handleDocumentsInputChange(e, discipline.id, document.id)}
                                  disabled={!this.props.captcha}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  name="Description"
                                  value={document.Description}
                                  onChange={(e) => this.handleDocumentsInputChange(e, discipline.id, document.id)}
                                  disabled={!this.props.captcha}
                                />
                              </td>
                            </tr>
                          )
                        }
                        return null
                      })
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-32436200div_22">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
