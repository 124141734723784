import React from 'react'

import { PagingState, CustomPaging, SortingState, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid'
import {
  Grid,
  VirtualTable,
  DragDropProvider,
  TableHeaderRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableColumnResizing,
  TableSelection,
  TableFixedColumns,
  TableColumnReordering,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

import UploadSPPID from '../Forms/UploadSPPID'
import UpdateProductDeliverables from './../WebComponents/UpdateProductDeliverables'

class DrawingListView extends React.Component {
  state = {
    columns: [],
    data: [],
    columnWidths: [],

    defaultHiddenColumnNames: [],
    tableColumnVisibilityColumnExtensions: [],
    _process_selection: [],
    selection: [],
    sorting: [],
    sortingStateColumnExtensions: [],
    columnOrder: [],
    loading: false,
    toggled_actions: '',
  }

  componentDidMount = async () => {
    await this.setState({
      data: this.props.pdfDrawings,
      sorting: this.props.sorting,
    })
    await this.setColumns()
    this.setInitialColumnWidths()
  }

  componentDidUpdate = async (props) => {
    if (this.props.process_selection.length !== this.state._process_selection.length) {
      await this.setState({
        _process_selection: JSON.parse(JSON.stringify(this.props.process_selection)),
        data: this.props.pdfDrawings,
      })
      await this.setColumns()
    }

    if (this.props.pageLoading !== props.pageLoading) {
      await this.setState({
        data: this.props.pdfDrawings,
        sorting: this.props.sorting,
        selection: [],
      })
      await this.setColumns()
      this.setInitialColumnWidths()
    }
  }

  setInitialColumnWidths = () => {
    let columnWidths = []

    if (this.props.user.drawingListColumnWidths.length) {
      columnWidths = this.props.user.drawingListColumnWidths
    } else {
      this.props.columns.forEach((column) => {
        columnWidths.push({
          columnName: column.name,
          width: 'width' in column ? column.width : column.name === 'drawingName' ? 360 : column.name === 'actions' ? 100 : 180,
        })
      })
    }

    this.setState({
      columnWidths: columnWidths,
    })
  }

  setColumns = async () => {
    let { defaultHiddenColumnNames, columnOrder } = this.state

    let columns = []
    let sortingStateColumnExtensions = []
    let tableColumnVisibilityColumnExtensions = []
    this.props.columns.forEach((column) => {
      if (!column.name) {
        return
      }

      if (!column.allowSort) {
        sortingStateColumnExtensions.push({
          columnName: column.name,
          sortingEnabled: false,
        })
      }

      if (!column.visible && !defaultHiddenColumnNames.includes(column.name)) {
        defaultHiddenColumnNames.push(column.name)
      }

      if (['actions', 'drawingName', 'status'].includes(column.name)) {
        tableColumnVisibilityColumnExtensions.push({ columnName: column.name, togglingEnabled: false })
      } else {
        if (!columnOrder.includes(column.name)) {
          columnOrder.push(column.name)
        }
      }

      columns.push({
        name: column.name,
        title: column.header,
        getCellValue: (row) => {
          if (column.name === 'actions') {
            return this.getDrawingActions(row)
          }
          if (column.name === 'status') {
            return this.getStatus(row)
          }

          return (
            <div
              className="cursor-pointer"
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                lineHeight: '38px',
                height: '38px',
              }}
              title={column.name === 'drawingName' ? row[column.name] : column.name in row.title ? row.title[column.name] : null}
              id="unique-70426052div_1"
            >
              {column.name === 'drawingName' ? (
                <button
                  className="btn btn-sm"
                  disabled={!['pdf', 'PDF'].includes(this.props.pdfDrawingsListViewObject['File Type'])}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    if (row.processed) {
                      this.props.history.push(
                        `/iui?product=${this.props.product}&projectId=${this.props.projectId}&projectName=${this.props.projectName}&documentId=${this.props.pdfDrawingsListViewObject.id}&drawingId=${row.id}&annotation=false`
                      )
                    } else {
                      this.props.setProcessViewFullScreen(true)
                    }
                  }}
                >
                  <u>{row[column.name]}</u>
                </button>
              ) : (
                row[column.name]
              )}
            </div>
          )
        },
      })
    })

    await this.setState({
      columns: columns,
      sortingStateColumnExtensions: sortingStateColumnExtensions,
      defaultHiddenColumnNames: defaultHiddenColumnNames,
      tableColumnVisibilityColumnExtensions: tableColumnVisibilityColumnExtensions,
      columnOrder: columnOrder,
    })
  }

  getStatus = (row) => {
    if (this.state._process_selection.length && this.state._process_selection.includes(row.id)) {
      return (
        <div className="spinner-grow text-secondary" title="Ongoing Processing" id="unique-60227583div_2">
          <span className="sr-only" id="unique-20294955span_1">
            Processing...
          </span>
        </div>
      )
    } else if ((row.errorMessage && row.reprocess) || (row.processed && row.reprocess)) {
      return <i className="fa fa-circle text-warning" title="Reprocessing allowed" id="84907533" />
    } else if (row.errorMessage) {
      return <i className="fa fa-circle text-danger" title={row.errorMessage} id="84907533" />
    } else if (row.approved) {
      return <i className="fa fa-check-circle" style={{ color: '#946db7' }} title="Approved" id="84907533" />
    } else if (row.ongoingReviewing) {
      return (
        <div className="spinner-grow" style={{ color: '#946db7' }} title="Ongoing Reviewing" id="unique-10973558div_3">
          <span className="sr-only" id="unique-49348917span_2">
            Ongoing Reviewing...
          </span>
        </div>
      )
    } else if (row.reviewed) {
      return <i className="fa fa-circle" style={{ color: '#946db7' }} title="Reviewed" id="84907533" />
    } else if (row.cleaned) {
      return <i className="fa fa-circle" style={{ color: '#0190b8' }} title="Cleaned" id="84907533" />
    } else if (row.ongoingCleaning) {
      return (
        <div className="spinner-grow" style={{ color: '#0190b8' }} title="Ongoing Cleaning" id="unique-56064685div_4">
          <span className="sr-only" id="unique-66196164span_3">
            Ongoing Cleaning...
          </span>
        </div>
      )
    } else if (row.processed) {
      return <i className="fa fa-circle text-success" title="Processed" id="84907533" />
    } else {
      return <i className="fa fa-circle text-secondary" title="Not Processed" id="84907533" />
    }
  }

  getDrawingActions = (row) => {
    let { product } = this.props
    let viewDetails_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_view-details_hidden`) ? true : false
    let interactiveui_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_interactiveui_hidden`) ? true : false
    let interactiveui_unlock_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_interactiveui_unlock_hidden`) ? true : false
    let deliverable_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_deliverable_hidden`) ? true : false
    let reUploadFile_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_reupload-file_hidden`) ? true : false
    let delete_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_delete_hidden`) ? true : false
    let mark_approved_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-approved_hidden`) ? true : false
    let mark_reviewed_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-reviewed_hidden`) ? true : false
    let mark_cleaned_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_mark-cleaned_hidden`) ? true : false
    let check_in_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_check-in_hidden`) ? true : false

    return (
      <>
        <div className="dropdown d-inline-block" id="unique-91277395div_5">
          <button
            className={`btn ${this.state.toggled_actions === row.id ? 'text-primary' : 'text-secondary'}`}
            onClick={async (e) => {
              e.stopPropagation()
              if (this.state.toggled_actions === row.id) {
                await this.setState({ toggled_actions: '' })
              } else {
                await this.setState({
                  toggled_actions: row.id,
                })
              }
              this.setColumns()
            }}
          >
            <i className="fa fa-ellipsis-v" id="84907533" />
          </button>
          {this.state.toggled_actions === row.id ? (
            <div className="dropdown-menu" id="unique-11292958div_6">
              {!viewDetails_hidden ? (
                <button
                  disabled={!['pdf', 'PDF'].includes(this.props.pdfDrawingsListViewObject['File Type'])}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.setProcessViewFullScreen(true)
                  }}
                  className={`dropdown-item`}
                >
                  View Details
                </button>
              ) : null}

              <button
                disabled={row.iuiLocked || !row.processed || row.errorMessage || !['pdf', 'PDF'].includes(this.props.pdfDrawingsListViewObject['File Type'])}
                title={row.iuiLocked ? 'IUI is locked by another person' : null}
                className={`dropdown-item ${interactiveui_hidden ? 'd-none' : ''}`}
                onClick={() => {
                  this.props.history.push(
                    `/iui?product=${this.props.product}&projectId=${this.props.projectId}&projectName=${this.props.projectName}&documentId=${this.props.pdfDrawingsListViewObject.id}&drawingId=${row.id}&annotation=false`
                  )
                }}
              >
                Interactive UI
              </button>

              {this.props.product === 'iDrawings-PID-Update' ? (
                <UpdateProductDeliverables {...this.props} drawing={row} product={this.props.product} projectName={this.props.projectName} projectId={this.props.projectId} />
              ) : !deliverable_hidden ? (
                <button
                  disabled={!row.processed || row.errorMessage || !['pdf', 'PDF'].includes(this.props.pdfDrawingsListViewObject['File Type'])}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props.setDeliverableView(true)
                  }}
                  className={`dropdown-item`}
                >
                  Deliverables
                </button>
              ) : null}

              {!reUploadFile_hidden ? (
                <button
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props._reUploadFile()
                  }}
                  className={`dropdown-item`}
                  disabled={this.state._process_selection.length && this.state._process_selection.includes(row.id)}
                >
                  Reupload File
                </button>
              ) : null}

              <div className="dropdown-divider" id="84907533" />
              {!mark_cleaned_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowCleaned || this.props.process_selection.includes(row.id)}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props._updateFileStatus(!row.cleaned, undefined, undefined, undefined)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.cleaned ? 'checked' : ''}`} id="84907533" /> Mark Cleaned
                </button>
              ) : null}
              {!mark_reviewed_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowReviewed || this.props.process_selection.includes(row.id)}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props._updateFileStatus(undefined, undefined, undefined, !row.reviewed)
                  }}
                >
                  <label className={`checkbox mr-2 ${row.reviewed ? 'checked' : ''}`} id="84907533" /> Mark Reviewed
                </button>
              ) : null}
              {!mark_approved_hidden ? (
                <button
                  className="dropdown-item"
                  disabled={!row.allowApproved || this.props.process_selection.includes(row.id)}
                  onClick={async () => {
                    await this.props.setSelection([row.id])
                    this.props._updateFileStatus(undefined, !row.approved, undefined, undefined)
                  }}
                >
                  <label className={`checkbox d-inline-block mr-2 text-center ${row.approved ? 'checked' : ''}`} id="84907533" /> Mark Approved
                </button>
              ) : null}

              {!check_in_hidden ? (
                <>
                  <div className="dropdown-divider" id="84907533" />
                  <button
                    className="dropdown-item"
                    disabled={!row.processed || !row.allowCheckInCheckOut}
                    onClick={async () => {
                      await this.props.setSelection([row.id])
                      this.props._updateFileStatus(undefined, undefined, !row.checkIn, undefined)
                    }}
                  >
                    {row.checkIn ? 'Check Out' : 'Check In'}
                  </button>
                </>
              ) : null}

              {!delete_hidden ? (
                <>
                  <div className="dropdown-divider" id="84907533" />
                  <button
                    onClick={async () => {
                      await this.props.setSelection([row.id])
                      this.props.setDeleteWarningMessage(true)
                    }}
                    className={`dropdown-item text-danger`}
                    disabled={this.state._process_selection.length && this.state._process_selection.includes(row.id)}
                  >
                    Delete
                  </button>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        {row.iuiLocked && !interactiveui_unlock_hidden ? (
          <button className={`btn text-secondary btn-sm ml-2`} onClick={() => this.props._unlockIUI(row)} title={`Unlock IUI`}>
            <i className="fa fa-lock " id="84907533" />
          </button>
        ) : null}

        {this.props.product === 'iDrawings-PID-Update' ? (
          <UploadSPPID
            {...this.props}
            projectName={this.props.projectName}
            drawing_Id={row.id}
            drawing_b_Id={row.drawingName}
            fileName={row.sppid_excel_name}
            product={this.props.product}
            projectId={this.props.projectId}
            reloadData={this.props.reloadData}
          />
        ) : null}

        {!row.checkIn ? (
          <span className="badge badge-danger badge-sm badge-pill" title="Checked Out" style={{ lineHeight: '12px' }} id="unique-36216278span_4">
            <i className="fa fa-angle-down" id="84907533" />
          </span>
        ) : null}
      </>
    )
  }

  getRowId = (row) => row.id

  setSorting = async (sorting) => {
    this.props.setSorting(sorting)
  }

  setColumnWidths = async (columnWidths) => {
    await this.props.updateColumnWidths(columnWidths, 'DrawingListView')
    this.setState({ columnWidths: columnWidths })
  }

  setColumnOrder = (order) => {
    this.setState({ columnOrder: order })
  }

  handleColumnVisibilityChange = (hiddenColumnNames) => {
    this.setState({ defaultHiddenColumnNames: hiddenColumnNames })
  }

  render() {
    let { loading, columns, data, columnWidths, sorting, sortingStateColumnExtensions, defaultHiddenColumnNames, columnOrder, tableColumnVisibilityColumnExtensions } = this.state
    let { pageLoading, pageSize, currentPage, totalCount, selection, leftColumns } = this.props
    leftColumns = [TableSelection.COLUMN_TYPE, ...leftColumns]

    return (
      <div style={{ position: 'relative' }} onClick={() => this.setState({ toggled_actions: '' }, this.setColumns)}>
        <div style={{ position: 'absolute', width: '100%' }} id="unique-11309025div_11">
          <div className="card" id="unique-87554720div_12">
            <Grid rows={data} columns={columns} getRowId={this.getRowId}>
              <DragDropProvider />
              <PagingState currentPage={currentPage} onCurrentPageChange={this.props.setCurrentPage} pageSize={pageSize} onPageSizeChange={this.props.setPageSize} />
              <CustomPaging totalCount={totalCount} />
              <SortingState sorting={sorting} onSortingChange={this.setSorting} columnExtensions={sortingStateColumnExtensions} />

              <SelectionState selection={selection} onSelectionChange={this.props.setSelection} />

              <VirtualTable />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.setColumnWidths} />
              <TableColumnReordering order={columnOrder} onOrderChange={this.setColumnOrder} />

              <TableHeaderRow showSortingControls />
              <IntegratedSelection />
              <TableSelection showSelectAll />

              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                onHiddenColumnNamesChange={this.handleColumnVisibilityChange}
                columnExtensions={tableColumnVisibilityColumnExtensions}
              />
              <PagingPanel pageSizes={this.props.pageSizes} />
              <TableFixedColumns leftColumns={leftColumns} />
              <Toolbar />
              <ColumnChooser />
            </Grid>
            {loading || pageLoading ? (
              <div className="card-loader" id="unique-46942388div_13">
                <div id="loading" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default DrawingListView
