import React from 'react'
import { connect } from 'react-redux'
import { fetchReports, downloadReports, fetchReportParams, fetchAvailableReports, fetchReportAdditionalParams } from './../../store/actions'
import { Link } from 'react-router-dom'

import NotAllowed from './NotAllowed'
import DynamicTable from './dynamicTable'
import { base64ToArrayBuffer } from './utils.js'
import UploadReportFile from '../Forms/UploadReportFile'
import Select from '../Forms/Fields/select'
import Input from '../Forms/Fields/input.js'

class iWorkflow extends React.Component {
  state = {
    product: 'iWorkflow',
    errorMessage: null,
    loading: false,
    submitting: false,
    projectId: null,
    available_report_types: [],
    search: '',
    filters: [],
    exhaustive_filters: [],
    more_filters_view: false,
    optionsSelected: [],
    search_message: false,
    no_data_message: false,
    reports: null,
    actual_reports: null,
    filtersCollapsed: false,
    errorMessageNotAllowed: false,

    client: null,
    report_type: null,
    output_type: null,

    selected_projects: [],
    report_form_params: [],
    report_additional_form_params: {},

    view_report_selection_form: false,
  }

  componentDidMount = async () => {
    if (this.props.user.user_actions.includes(`${this.state.product.toLowerCase()}_hidden`)) {
      this.setState({ errorMessageNotAllowed: true })
      return
    }

    const query = new URLSearchParams(this.props.location.search)
    const report_type = query.get('report') ? query.get('report') : null
    const projectId = query.get('project') ? query.get('project') : null

    await this.fetchAvailableReports()
    if (report_type) {
      const selected_report_type = this.state.available_report_types.filter((_report_type) => _report_type.name === report_type)
      await this.setState({
        report_type: selected_report_type && selected_report_type.length ? selected_report_type[0] : null,
        projectId: projectId,
      })
      this.onReportSelectionHandler(null, true)
      return
    }

    await this.fetchReportParams()
  }

  fetchReportParams = async () => {
    await this.setState({ loading: true })
    await this.props.fetchReportParams()
    await this.setState({ loading: false })
    if (this.props.user.displayMessageCode) {
      return
    }

    await this.setState({
      report_form_params: this.props.user.report_form_params.client,
      view_report_selection_form: true,
    })
  }

  fetchAvailableReports = async () => {
    await this.setState({ loading: true })
    await this.props.fetchAvailableReports()
    await this.setState({ loading: false })
    if (!this.props.user.displayMessageCode) {
      await this.setState({
        available_report_types: this.props.user.available_report_types,
      })
    }
  }

  onSearchSubmitHandler = (e) => {
    this.setState({ search_message: false, optionsSelected: [] })
    e.preventDefault()
    let reports = []
    this.state.actual_reports.forEach((report) => {
      for (const [key, value] of Object.entries(report)) {
        if (this.state.search !== '' && key && value && value !== '' && value.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
          reports.push(report)
          break
        }
      }
    })

    if (this.state.search !== '') {
      this.setState({ reports: reports })

      if (reports.length === 0) {
        this.setState({ search_message: true })
      }
    } else {
      this.setState({ reports: this.state.actual_reports })
    }
  }

  onFilterSubmitHandler = () => {
    this.setState({ search_message: false, search: '' })
    let optionsSelected = []
    this.state.optionsSelected.forEach((option) => {
      const index = optionsSelected.findIndex((_option) => _option.key === option.key)
      if (index > -1) {
        optionsSelected[index].values.push(option.val)
      } else {
        optionsSelected.push({ key: option.key, values: [option.val] })
      }
    })

    let searched_data = []

    this.state.actual_reports.forEach((report) => {
      let append = true
      optionsSelected.forEach((option) => {
        if (!report[option.key].toString() || !option.values.includes(report[option.key])) {
          append = false
        }
      })
      if (append) {
        searched_data.push(report)
      }
    })

    this.setState({ reports: searched_data })

    if (searched_data.length === 0) {
      this.setState({ search_message: true })
    }
  }

  onReportSelectionHandler = async (e, via_url = false) => {
    if (e) {
      e.preventDefault()
    }

    if (!via_url) {
      if (!this.state.client) {
        this.setState({ errorMessage: 'Please select client!' })
        return
      }

      if (!this.state.selected_projects.length) {
        this.setState({ errorMessage: 'Please select projects!' })
        return
      }

      if (!this.state.report_type) {
        this.setState({ errorMessage: 'Please select report type!' })
        return
      }

      if (!this.state.output_type) {
        this.setState({ errorMessage: 'Please select output type!' })
        return
      }
    }

    await this.setState({ no_data_message: false, search_message: false })

    const formData = new FormData()
    formData.append('report_type', this.state.report_type.name)
    formData.append('projectId', this.state.projectId)
    formData.append('client', this.state.client ? this.state.client.id : null)
    formData.append('additionalOptions', JSON.stringify(this.state.report_additional_form_params))
    formData.append('projectIds', JSON.stringify(this.state.selected_projects))
    formData.append('output_type', this.state.output_type ? this.state.output_type.label : null)

    await this.setState({ submitting: true })
    await this.props.fetchReports(formData)

    if (!this.props.user.displayMessageCode) {
      const reports = this.props.user.reports.report
      const actual_reports = this.props.user.reports.report
      const filters = this.props.user.reports.filters
      const exhaustive_filters = this.props.user.reports.exhaustivefilters
      const download_report = this.props.user.reports.downloadReport
      const view_report = this.props.user.reports.viewReport || via_url
      if (view_report) {
        await this.setState({
          reports: reports,
          actual_reports: actual_reports,
          filters: filters,
          exhaustive_filters: exhaustive_filters,
          view_report_selection_form: false,
        })
        if (this.state.reports.length === 0) {
          this.setState({ no_data_message: true })
        }
      } else {
        await this.setState({
          reports: null,
          actual_reports: null,
          filters: [],
          exhaustive_filters: [],
        })
      }

      if (download_report) {
        this.downloadReport()
      }

      await this.setState({ submitting: false, optionsSelected: [] })
    }
  }

  fetchReportAdditionalParams = async () => {
    let { client, selected_projects, report_type } = this.state
    await this.setState({ report_additional_form_params: {} })
    if (client && selected_projects.length && report_type) {
      let formData = new FormData()
      formData.append('report_type', this.state.report_type.name)
      formData.append('client', this.state.client.id)
      formData.append('projectIds', JSON.stringify(this.state.selected_projects))
      await this.props.fetchReportAdditionalParams(formData)
      if (!this.props.user.displayMessageCode) {
        await this.setState({
          report_additional_form_params: this.props.user.report_additional_form_params.additionalOptions,
        })
      }
    }
  }

  renderReportTypeSelectionForm = () => {
    let { report_form_params, report_additional_form_params, client, selected_projects } = this.state

    let client_options = []
    let project_options = []

    report_form_params.forEach((_client) => {
      client_options.push(_client)

      if (!client) {
        return
      }

      if (client.id === _client.id) {
        _client.projects.forEach((_project) => {
          project_options.push(_project)
        })
      }
    })

    let report_type_options = []
    let associated_projects = []
    selected_projects.forEach((project) => {
      project.associated_reports.forEach((report) => {
        const index = report_type_options.findIndex((option) => option.id === report.id)
        if (index === -1) {
          report_type_options.push(report)
        }
      })

      project.associated_projects.forEach((project) => {
        if (!associated_projects.includes(project)) {
          associated_projects.push(project)
        }
      })
    })

    let output_type_options = []
    report_type_options.forEach((option) => {
      if (this.state.report_type && option.name === this.state.report_type.name) {
        output_type_options = option.output_options
      }
    })

    return (
      <form className="card mb-3" onSubmit={(e) => this.onReportSelectionHandler(e)}>
        <div className="card-body p-2" id="unique-74109521div_1">
          <div className="row" id="unique-59472017div_2">
            <div className="col-4 mb-3" id="unique-11021642div_3">
              <label id="unique-20311312label_1">
                Client{' '}
                <span className="small text-muted" id="unique-53458363span_1">
                  (Mandatory *)
                </span>
              </label>
              <Select
                options={client_options}
                onChange={(selectedClient) => {
                  this.setState(
                    {
                      client: selectedClient,
                      selected_projects: [],
                      report_type: null,
                      output_type: null,
                    },
                    this.fetchReportAdditionalParams
                  )
                }}
                value={this.state.client}
                isMulti={false}
              />
            </div>

            <div className="col-4 mb-3" id="unique-18373755div_4">
              <div className="clearfix" id="unique-11216404div_5">
                <span className="float-right" id="unique-55037835span_2">
                  <label
                    onClick={() => {
                      if (selected_projects.length === project_options.length) {
                        this.setState({
                          selected_projects: [],
                          report_type: null,
                        })
                      } else {
                        this.setState({
                          selected_projects: project_options,
                          report_type: null,
                        })
                      }
                    }}
                    className={`checkbox d-inline-block text-center ${selected_projects.length === project_options.length && project_options.length ? 'checked' : null}`}
                  />{' '}
                  Select All
                </span>
                <label id="unique-44233177label_3">
                  Projects{' '}
                  <span className="small text-muted" id="unique-82839374span_3">
                    (*)
                  </span>
                </label>
              </div>

              <Select
                options={project_options}
                onChange={(selectedList) => {
                  this.setState(
                    {
                      selected_projects: selectedList,
                      report_type: null,
                      output_type: null,
                    },
                    this.fetchReportAdditionalParams
                  )
                }}
                value={selected_projects}
                isDisabled={!this.state.client}
                isMulti={true}
              />
            </div>

            <div className="col-4 mb-3" id="unique-96858806div_6">
              <label id="unique-60138549label_4">
                Report Type{' '}
                <span className="small text-muted" id="unique-12017155span_4">
                  (*)
                </span>
              </label>
              <Select
                options={report_type_options}
                value={this.state.report_type}
                onChange={(selectedReport) => {
                  this.setState(
                    {
                      report_type: selectedReport,
                      output_type: null,
                    },
                    this.fetchReportAdditionalParams
                  )
                }}
                isDisabled={!report_type_options.length}
                isMulti={false}
              />
            </div>

            <div className="col-4 mb-3" id="unique-28188850div_7">
              <label id="unique-49027973label_5">
                Output Type{' '}
                <span className="small text-muted" id="unique-54208227span_5">
                  (*)
                </span>
              </label>
              <Select
                options={output_type_options}
                onChange={(selectedOutputType) => {
                  this.setState({ output_type: selectedOutputType })
                }}
                value={this.state.output_type}
                isDisabled={!output_type_options.length}
                isMulti={false}
              />
            </div>

            {Object.entries(report_additional_form_params).map(([key, val]) => {
              return (
                <div key={key} className="col-4 mb-3" id="unique-91876605div_8">
                  <label id="unique-11447741label_6">
                    {key}s &nbsp;
                    {val.mandatory ? (
                      <span className="small text-muted" id="unique-86022175span_6">
                        (*)
                      </span>
                    ) : null}
                  </label>
                  {val.multi ? (
                    <Select
                      options={val.options}
                      value={val.value}
                      onChange={(selectedList) => {
                        this.onSelectAdditionalFieldOption(null, selectedList, key)
                      }}
                      isMulti={true}
                    />
                  ) : (
                    <Select
                      options={val.options}
                      value={val.value}
                      onChange={(selectedItem) => {
                        this.onSelectAdditionalFieldOption(selectedItem, null, key)
                      }}
                      isMulti={false}
                    />
                  )}
                </div>
              )
            })}

            {this.state.selected_projects.length === 1 && this.state.report_type ? (
              <div className="col-4 mb-3" id="unique-10674534div_9">
                <label id="unique-87779166label_7">Report Type File</label>
                <div id="unique-86559842div_10">
                  <UploadReportFile
                    projectId={this.state.projectId}
                    selected_projects={this.state.selected_projects}
                    client={this.state.client.id}
                    report_type={this.state.report_type.name}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="clearfix" id="unique-18119908div_11">
            <button className="btn btn-primary float-right" disabled={this.state.submitting} id="unique-35805035button_1">
              Run Report {this.state.submitting ? <i className="fa fa-spin fa-spinner" id="unique-33552647i_1"></i> : null}
            </button>
          </div>
        </div>
      </form>
    )
  }

  onSelect = (selectedList, selectedItem) => {
    let optionsSelected = this.state.optionsSelected
    let append = true
    optionsSelected.forEach((option) => {
      if (selectedItem.key === option.key && selectedItem.val === option.val) {
        append = false
      }
    })
    if (append) {
      optionsSelected.push(selectedItem)
      this.setState({ optionsSelected: optionsSelected }, this.onFilterSubmitHandler)
    }
  }

  onRemove = (selectedList, removedItem) => {
    let optionsSelected = this.state.optionsSelected
    let index = optionsSelected.findIndex((option) => option.key === removedItem.key && option.val === removedItem.val)
    if (index > -1) {
      optionsSelected.splice(index, 1)
      this.setState({ optionsSelected: optionsSelected }, this.onFilterSubmitHandler)
    }
  }

  onSelectAdditionalFieldOption = (selectedItem, selectedList, key) => {
    let { report_additional_form_params } = this.state
    if (selectedItem) {
      report_additional_form_params[key].value = selectedItem
    } else {
      report_additional_form_params[key].value = selectedList
    }
    this.setState({
      report_additional_form_params: report_additional_form_params,
    })
  }

  renderSearchForm = () => {
    return this.state.filters.map((filter) => {
      let options = []
      filter.Options.forEach((option) => {
        options.push({ key: filter.Name, val: option, label: option, value: option })
      })

      let optionsSelected = []
      this.state.optionsSelected.forEach((option) => {
        options.forEach((_option) => {
          if (option.key === _option.key && option.val === _option.val) {
            optionsSelected.push(option)
          }
        })
      })

      return (
        <div className="col-2 mb-2" key={filter.Name} id="unique-77294277div_12">
          <Select
            options={options}
            onChange={(selectedList) => {
              this.setState({ optionsSelected: selectedList }, this.onFilterSubmitHandler)
            }}
            isMulti={true}
            value={optionsSelected}
          />
        </div>
      )
    })
  }

  renderReportTypeHeading = () => {
    if (!this.state.report_type) {
      return null
    }
    const index = this.state.available_report_types.findIndex((report) => report.name === this.state.report_type.name)
    if (index > -1) {
      return this.state.available_report_types[index].displayName
    }
    return null
  }

  renderMoreFilterOptions() {
    return this.state.exhaustive_filters.map((more_filter) => {
      const index = this.state.filters.findIndex((filter) => filter.Name === more_filter.Name)
      if (index === -1) {
        return (
          <span className="dropdown-item" onClick={() => this.addFilter(more_filter.Name)} key={more_filter.Name}>
            {more_filter.Name}
          </span>
        )
      }
      return null
    })
  }

  addFilter = (filter_name) => {
    let filters = this.state.filters
    const index = this.state.exhaustive_filters.findIndex((filter) => filter.Name === filter_name)
    if (index > -1) {
      filters.push(this.state.exhaustive_filters[index])
    }
    this.setState({ filters: filters, more_filters_view: false })
  }

  downloadReport = async () => {
    await this.setState({ downloading: true })
    const formData = new FormData()
    formData.append('report_type', this.state.report_type.name)
    formData.append('projectId', this.state.projectId)
    formData.append('client', this.state.client ? this.state.client.id : null)
    formData.append('additionalOptions', JSON.stringify(this.state.report_additional_form_params))
    formData.append('projectIds', JSON.stringify(this.state.selected_projects))
    formData.append('output_type', this.state.output_type ? this.state.output_type.label : null)
    await this.props.downloadReports(formData)
    await this.setState({ downloading: false })
    if (this.props.user.displayMessageCode) {
      return
    }

    if (this.props.user.report_excel) {
      let buffer = base64ToArrayBuffer(this.props.user.report_excel.report)
      const url = window.URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url

      var currentdate = new Date()
      var datetime =
        '-' +
        (currentdate.getDate() < 10 ? '0' : '') +
        currentdate.getDate() +
        '' +
        (currentdate.getMonth() + 1 < 10 ? '0' : '') +
        (currentdate.getMonth() + 1) +
        '' +
        currentdate.getFullYear() +
        '-' +
        currentdate.getHours() +
        '' +
        currentdate.getMinutes() +
        ''

      link.setAttribute('download', `${this.props.user.report_excel.fileName}${datetime}.${this.props.user.report_excel.extension}`) //or any other extension
      link.click()
    }
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderReports() {
    let { product } = this.state
    let download_excel_hidden = this.props.user.user_actions.includes(`${product.toLowerCase()}_download-excel_hidden`) ? true : false

    if (this.state.reports) {
      return (
        <>
          <div className="card" id="unique-20612868div_13">
            <div className="card-body p-2" id="unique-20589789div_14">
              <div className="clearfix" id="unique-10226128div_15">
                {this.state.filters.length || this.state.exhaustive_filters.length ? (
                  <button
                    className="btn btn-primary float-right ml-4"
                    onClick={() =>
                      this.setState({
                        filtersCollapsed: !this.state.filtersCollapsed,
                      })
                    }
                  >
                    <i className={this.state.filtersCollapsed ? 'fa fa-angle-down ' : 'fa fa-angle-up'} id="10820986" />
                  </button>
                ) : null}

                <form onSubmit={this.onSearchSubmitHandler} className="form-inline d-inline-block float-right">
                  <Input type="text" placeholder="Search" value={this.state.search} name="search" onChange={this.onChange} />
                  <button className="btn btn-primary ml-2">
                    <i className="fa fa-search" />
                  </button>
                  <button
                    title="Reset search"
                    type="button"
                    onClick={() =>
                      this.setState({
                        search: '',
                        reports: this.state.actual_reports,
                        search_message: false,
                        optionsSelected: [],
                      })
                    }
                    className="btn float-right ml-2"
                    style={{ height: 33, padding: '0 10px' }}
                  >
                    <i className="fa fa-redo" id="unique-65227204i_4"></i>
                  </button>
                </form>

                {!download_excel_hidden ? (
                  <span className="float-right mr-4" id="unique-34493916span_8">
                    <button
                      disabled={this.state.actual_reports.length ? false : true}
                      className="btn btn-primary btn-sm"
                      onClick={this.downloadReport}
                      id="unique-90914450button_5"
                    >
                      Download Excel &nbsp;{' '}
                      {this.state.downloading ? <i className="fa fa-spin fa-spinner" id="unique-30771544i_5"></i> : <i className="fa fa-download" id="unique-46605072i_6"></i>}
                    </button>
                  </span>
                ) : null}

                {this.state.filters.length || this.state.exhaustive_filters.length ? (
                  <h3 className="mb-0 d-inline-block" id="unique-52503552h3_1">
                    Filters
                  </h3>
                ) : null}
              </div>
              {this.state.filters.length || this.state.exhaustive_filters.length ? (
                <div className={this.state.filtersCollapsed ? 'row mt-3 d-none' : 'row mt-3'} id="unique-34009602div_16">
                  {this.renderSearchForm()}

                  <div className="col-2" id="unique-90292208div_17">
                    <div className="dropdown" id="unique-29881215div_18">
                      <button
                        onClick={() =>
                          this.setState({
                            more_filters_view: !this.state.more_filters_view,
                          })
                        }
                        disabled={this.state.exhaustive_filters.length === this.state.filters.length ? true : false}
                        className="btn btn-default"
                      >
                        Add Filter <i className="fa fa-plus" id="10820986" />
                      </button>
                      {this.state.more_filters_view ? (
                        <div className="dropdown-menu" style={{ zIndex: 1002 }} id="unique-10170181div_19">
                          {this.renderMoreFilterOptions()}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="iworkflow" id="unique-83636460div_20">
            {this.state.search_message ? (
              <div className="table-container" id="unique-57874337div_21">
                <div className="text-center" id="unique-33732595div_22">
                  <i className="fa fa-search d-block mb-3 mt-3" style={{ fontSize: 90 }} id="unique-46686606i_8"></i>
                  <h3 id="unique-13809613h3_2"> No search results found! </h3>
                  <p id="unique-58597230p_1">We couldn't find any results. Change selected filters or try later.</p>
                </div>
              </div>
            ) : null}

            {this.state.no_data_message ? (
              <div className="table-container" id="unique-59798074div_23">
                <div className="text-center mt-4" id="unique-98730527div_24">
                  <i className="fa fa-folder d-block mb-3" style={{ fontSize: 90 }} id="unique-60134559i_9"></i>
                  <h3 id="unique-49201914h3_3"> No data available! </h3>
                  <p id="unique-11804455p_2">We couldn't find any data. Please try again later.</p>
                </div>
              </div>
            ) : null}

            {this.state.search_message || this.state.no_data_message ? null : (
              <DynamicTable data={this.state.reports} nested={this.state.report_type.name === 'InstrumentData'} pagination={this.state.report_type.name !== 'InstrumentData'} />
            )}
          </div>
        </>
      )
    }
    return null
  }

  render() {
    if (this.state.errorMessageNotAllowed) {
      return <NotAllowed />
    }

    return (
      <div className="dashboard main" id="unique-10636647div_25">
        <ol className="breadcrumb" id="unique-36417594ol_1">
          <li className="breadcrumb-item" id="unique-97372135li_1">
            <Link to="/dashboard" id="unique-33249857link_1">
              Product Dashboard
            </Link>
          </li>
          <li className={`breadcrumb-item ${this.renderReportTypeHeading() && this.state.reports ? '' : 'active'}`} id="unique-55587915li_2">
            <Link
              to="#"
              onClick={async () => {
                await this.setState({
                  reports: null,
                  report_type: null,
                  actual_reports: null,
                  selected_projects: [],
                  output_type: null,
                  report_additional_form_params: {},
                })
                await this.fetchAvailableReports()
                await this.fetchReportParams()
              }}
            >
              iWorkflow Dashboard
            </Link>
          </li>
          {this.renderReportTypeHeading() && this.state.reports ? (
            <li className="breadcrumb-item active" id="unique-77404763li_3">
              <span id="unique-81576457span_9">{this.renderReportTypeHeading()}</span>
            </li>
          ) : null}
        </ol>

        {this.renderReportTypeHeading() && this.state.reports && !this.state.view_report_selection_form ? (
          <div className="mb-4 card bg-primary" id="unique-67331779div_26">
            <div className="card-body" id="unique-24213634div_27">
              <h3 className="mb-0 text-light" id="unique-35009261h3_4">
                {this.renderReportTypeHeading()}
              </h3>
            </div>
          </div>
        ) : (
          <div className="mb-4 card bg-primary" id="unique-41855087div_28">
            <div className="card-body" id="unique-64429082div_29">
              <h3 className="mb-0 text-light" id="unique-11248157h3_5">
                Report Configuration
              </h3>
            </div>
          </div>
        )}

        {this.state.errorMessage ? (
          <div className="alert alert-danger alert-dissmiss" id="unique-78443326div_30">
            {this.state.errorMessage}
            <button className="close" onClick={() => this.setState({ errorMessage: null })}>
              &times;
            </button>
          </div>
        ) : null}

        {this.state.loading ? (
          <div className="screen-loader" id="unique-42994788div_31">
            <div id="loading" />
          </div>
        ) : null}

        {this.state.view_report_selection_form && !this.state.loading ? this.renderReportTypeSelectionForm() : null}

        {!this.state.loading ? this.renderReports() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  fetchReports,
  downloadReports,
  fetchReportParams,
  fetchAvailableReports,
  fetchReportAdditionalParams,
})(iWorkflow)
