import React from 'react'
import './Forms.css'
import { connect } from 'react-redux'

import Dropzone from 'react-dropzone'
import { parseLOVExcelFile } from '../../store/actions'

class UploadLOVExcel extends React.Component {
  state = {
    uploading: false,
    lovData: [],
    files: null,
    errorMessage: null,
  }

  uploadDocument = async (files) => {
    if (files[0].size > 100 * 1024 * 1024) {
      this.setState({
        errorMessage: `${files[0].name}: Max allowed file size is 100MB`,
      })
      return
    }

    await this.setState({ uploading: true })
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('fileName', files[0].name)
    formData.append('projectId', this.props.projectId)
    await this.props.parseLOVExcelFile(formData)
    await this.setState({ uploading: false })
    if (!this.props.user.displayMessageCode) {
      this.setState({ lovData: this.props.user.lovData }, () => {
        let lovDataStr = ''
        this.state.lovData.forEach((data) => {
          if (lovDataStr === '') {
            lovDataStr = data
          } else {
            lovDataStr += ', ' + data
          }
        })

        if (this.props.TemplateNumber >= 0 && this.props.attrId >= 0) {
          this.props.updateLOVData(this.props.TemplateNumber, this.props.attrId, lovDataStr)
        }

        if (this.props.index >= 0 && !this.props._index && !this.props.__index) {
          this.props.updateLOVData(
            {
              target: {
                value: lovDataStr,
                name: this.props.name,
              },
            },
            `${this.props.index}`
          )
        }

        if (this.props.index >= 0 && this.props._index >= 0 && !this.props.__index) {
          this.props.updateLOVData(this.props.index, this.props._index, lovDataStr)
        }

        if (this.props.index >= 0 && this.props._index >= 0 && this.props.__index >= 0) {
          this.props.updateLOVData(
            {
              target: {
                value: lovDataStr,
                name: this.props.name,
              },
            },
            `${this.props.index}.${this.props._index}.${this.props.__index}`
          )
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <div className="mb-2 alert alert-danger" role="alert">
            {this.state.errorMessage}{' '}
            <button className="close" onClick={() => this.setState({ errorMessage: null })}>
              &times;
            </button>
          </div>
        ) : null}
        <Dropzone onDrop={(acceptedFiles) => this.uploadDocument(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <button {...getRootProps()} className="btn btn-primary btn-sm" disabled={this.props.disabled}>
              <input {...getInputProps()} multiple={false} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              {this.state.uploading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-upload" />}
            </button>
          )}
        </Dropzone>
      </React.Fragment>
    )
  }
}

export default connect(null, { parseLOVExcelFile })(UploadLOVExcel)
