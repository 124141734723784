import { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

export default function IdleTimer(props) {
  const timeout = 60000 * 30 // 30 mins
  //   const [remaining, setRemaining] = useState(timeout)
  //   const [elapsed, setElapsed] = useState(0)
  //   const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => {
    setIsIdle(true)
    if (props.access_token) {
      props.showSessionExpiredMessage(true)
    } else {
      props.showSessionExpiredMessage(false)
    }
  }

  const {
    // reset,
    // pause,
    // resume,
    getRemainingTime,
    // getLastActiveTime,
    // getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  })

  //   const handleReset = () => reset()
  //   const handlePause = () => pause()
  //   const handleResume = () => resume()

  useEffect(() => {
    console.log(isIdle, getRemainingTime())
    // setRemaining(getRemainingTime())
    // setLastActive(getLastActiveTime())
    // setElapsed(getElapsedTime())

    setInterval(() => {
      //   setRemaining(getRemainingTime())
      //   setLastActive(getLastActiveTime())
      //   setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  return null

  //   return (
  //     <div id="unique-79497548div_1">
  //       <div id="unique-10813907div_2">
  //         <h1 id="unique-74473577h1_1">Timeout: {timeout}ms</h1>
  //         <h1 id="unique-10027707h1_2">Time Remaining: {remaining}</h1>
  //         <h1 id="unique-78157511h1_3">Time Elapsed: {elapsed}</h1>
  //         <h1 id="unique-72684413h1_4">Last Active: {lastActive}</h1>
  //         <h1 id="unique-10329229h1_5">Idle: {isIdle.toString()}</h1>
  //       </div>
  //       <div id="unique-19456889div_3">
  //         <button onClick={handleReset} id="unique-31849215button_1">RESET</button>
  //         <button onClick={handlePause} id="unique-34552746button_2">PAUSE</button>
  //         <button onClick={handleResume} id="unique-86683219button_3">RESUME</button>
  //       </div>
  //     </div>
  //   )
}
