import React, { Component } from 'react'

import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS5 extends Component {
  state = {
    components: [],
    loading: false,
    formLoader: false,

    has_searchable_pdf: false,
    has_pdf_with_autocad_text: false,
    has_non_searchable_pdf: false,

    document_orientation_options: [],
    selected_document_orientations: [],

    measurement_system_code_options: [],
    selected_measurement_system_codes: [],

    uom_options: [],
    selected_uom_options: [],
    screen_blocked: false,
  }

  componentDidMount = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectUOMInfo(formData)

    await this.setState({
      loading: false,
      has_searchable_pdf: this.props.user.projectInfo.has_searchable_pdf,
      has_pdf_with_autocad_text: this.props.user.projectInfo.has_pdf_with_autcad_text,
      has_non_searchable_pdf: this.props.user.projectInfo.has_non_searchable_pdf,
      selected_uom_options: this.props.user.projectInfo.selected_uom,

      document_orientation_options: this.props.user.projectInfo.orientation_options,
      selected_document_orientations: this.props.user.projectInfo.selected_orientation,

      measurement_system_code_options: this.props.user.projectInfo.system_options,
      selected_measurement_system_codes: this.props.user.projectInfo.selected_system,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })
  }

  formDataHandler = async (exit) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('selectedUOMId', this.state.selected_uom_options)
    formData.append('selectedmeasurementId', this.state.selected_measurement_system_codes)
    formData.append('selectedorientation', this.state.selected_document_orientations)
    formData.append('has_non_searchable_pdf', this.state.has_non_searchable_pdf)
    formData.append('has_searchable_pdf', this.state.has_searchable_pdf)
    formData.append('has_pdf_with_autcad_text', this.state.has_pdf_with_autocad_text)
    formData.append('step', 5)
    await this.props.updateProjectUOMInfo(formData)

    await this.setState({ formLoader: false })
    if (!this.props.user.projectInfo.isLast && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if (exit || this.props.user.projectInfo.isLast) {
      this.props.closeProjectSetup()
    }
  }

  updateMeasurementSystemCode = (sys_id) => {
    if (sys_id === '') {
      return
    }

    let { measurement_system_code_options, selected_measurement_system_codes, selected_uom_options } = this.state
    const sys_index = selected_measurement_system_codes.findIndex((sys) => sys === parseInt(sys_id))
    if (sys_index > -1) {
      selected_measurement_system_codes.splice(sys_index, 1)
    } else {
      selected_measurement_system_codes.push(parseInt(sys_id))
    }

    this.setState({
      selected_measurement_system_codes: selected_measurement_system_codes,
    })

    let _selected_uom_options = []
    measurement_system_code_options.forEach((option) => {
      if (selected_measurement_system_codes.includes(option.id)) {
        option.uom_options.forEach((_option) => {
          if (selected_uom_options.includes(_option.id)) {
            _selected_uom_options.push(_option.id)
          }
        })
      }
    })
    this.setState({ selected_uom_options: _selected_uom_options })
  }

  updateUOMSelection = (uom_id) => {
    if (uom_id === '') {
      return
    }

    let { selected_uom_options } = this.state
    const uom_index = selected_uom_options.findIndex((uom) => uom === parseInt(uom_id))
    if (uom_index > -1) {
      selected_uom_options.splice(uom_index, 1)
    } else {
      selected_uom_options.push(parseInt(uom_id))
    }

    this.setState({ selected_uom_options: selected_uom_options })
  }

  updateOrientationsSelection = (orientation) => {
    if (orientation === '') {
      return
    }

    let { selected_document_orientations } = this.state
    const orientation_index = selected_document_orientations.findIndex((_orientation) => _orientation === parseInt(orientation))
    if (orientation_index > -1) {
      selected_document_orientations.splice(orientation_index, 1)
    } else {
      selected_document_orientations.push(parseInt(orientation))
    }

    this.setState({
      selected_document_orientationss: selected_document_orientations,
    })
  }

  renderForm() {
    if (this.props.user.projectInfo) {
      return (
        <div className="card" id="unique-56010626div_1">
          <div className="clearfix card-header bg-light" id="unique-10855705div_2">
            <button type="button" id="unique-50517603button_1" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
              {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
            </button>

            <button
              type="button"
              id="unique-67444220button_2"
              onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)}
              className="btn btn-primary float-right mr-2"
            >
              Back
            </button>

            <button
              type="button"
              id="unique-85280320button_3"
              onClick={() => this.formDataHandler(true)}
              disabled={this.state.formLoader}
              className="btn btn-primary float-right mr-2"
            >
              Save & Exit
            </button>

            <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-41688463button_4">
              Cancel
            </button>

            <h3 id="unique-55685265h3_1">Document Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="10386670" /> : null}</h3>
          </div>

          <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-53831537div_3">
            {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
              <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
            ) : null}

            <div className="row" id="unique-10817714div_4">
              <div className="col" id="unique-75276730div_5">
                <div className="row" id="unique-61124139div_6">
                  <div className="col-12 mb-3" id="unique-55828522div_7">
                    <div className="card bg-light" id="unique-29757206div_8">
                      <div className="card-body p-2" id="unique-82358210div_9">
                        <select className="form-control" onChange={(e) => this.updateMeasurementSystemCode(e.target.value)} disabled={!this.props.captcha}>
                          <option>-- Add Measurement System Code --</option>
                          {this.state.measurement_system_code_options.map((option) => {
                            if (!this.state.selected_measurement_system_codes.includes(option.id)) {
                              return (
                                <option key={option.id} value={option.id}>
                                  {option.Name}
                                </option>
                              )
                            }
                            return null
                          })}
                        </select>
                      </div>
                    </div>
                    {this.state.measurement_system_code_options.map((option) => {
                      if (this.state.selected_measurement_system_codes.includes(option.id)) {
                        return (
                          <button className="btn btn-default m-2" id="unique-85449773button_5" key={option.id} onClick={() => this.updateMeasurementSystemCode(option.id)}>
                            {option.Name}
                          </button>
                        )
                      }
                      return null
                    })}
                  </div>

                  <div className="col-12 mb-3" id="unique-89420755div_10">
                    <div className="card bg-light" id="unique-87346208div_11">
                      <div className="card-body p-2" id="unique-53005337div_12">
                        <select
                          className="form-control"
                          onChange={(e) => this.updateUOMSelection(e.target.value)}
                          disabled={!this.state.selected_measurement_system_codes.length || !this.props.captcha}
                        >
                          <option value="">-- Add UOM --</option>
                          {this.state.measurement_system_code_options.map((option) => {
                            if (this.state.selected_measurement_system_codes.includes(option.id)) {
                              return option.uom_options.map((_option) => {
                                if (!this.state.selected_uom_options.includes(_option.id)) {
                                  return (
                                    <option key={_option.id} value={_option.id}>
                                      {_option.Name}
                                    </option>
                                  )
                                }
                                return null
                              })
                            }
                            return null
                          })}
                        </select>
                      </div>
                    </div>

                    {this.state.measurement_system_code_options.map((option) => {
                      if (this.state.selected_measurement_system_codes.includes(option.id)) {
                        return option.uom_options.map((uom) => {
                          if (this.state.selected_uom_options.includes(uom.id)) {
                            return (
                              <button className="btn btn-default m-2" key={uom.id} id="unique-11767021button_6" onClick={() => this.updateUOMSelection(uom.id)}>
                                {uom.Name}
                              </button>
                            )
                          }
                          return null
                        })
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
              <div className="col" id="unique-75801283div_13">
                <div className="row" id="unique-48076993div_14">
                  <div className="col-6 mb-3" id="unique-74462985div_15">
                    <label
                      id="unique-42889009label_1"
                      onClick={() =>
                        this.setState({
                          has_searchable_pdf: !this.state.has_searchable_pdf,
                        })
                      }
                      className={`checkbox ${this.state.has_searchable_pdf ? 'checked' : null}`}
                    />{' '}
                    Has Searchable PDF?
                  </div>
                  <div className="col-6 mb-3" id="unique-22258478div_16">
                    <label
                      id="unique-10523356label_2"
                      onClick={() =>
                        this.setState({
                          has_pdf_with_autocad_text: !this.state.has_pdf_with_autocad_text,
                        })
                      }
                      className={`checkbox ${this.state.has_pdf_with_autocad_text ? 'checked' : null}`}
                    />{' '}
                    Has PDF with AutoCAD text?
                  </div>
                  <div className="col-6 mb-3" id="unique-46656761div_17">
                    <label
                      id="unique-96941346label_3"
                      onClick={() =>
                        this.setState({
                          has_non_searchable_pdf: !this.state.has_non_searchable_pdf,
                        })
                      }
                      className={`checkbox ${this.state.has_non_searchable_pdf ? 'checked' : null}`}
                    />{' '}
                    Has None-Searchable PDF?
                  </div>
                </div>
                <div className="card bg-light" id="unique-69039454div_18">
                  <div className="card-body p-2" id="unique-11066684div_19">
                    <select className="form-control" onChange={(e) => this.updateOrientationsSelection(e.target.value)} disabled={!this.props.captcha}>
                      <option>-- Add Document Orientation --</option>
                      {this.state.document_orientation_options.map((option) => {
                        if (!this.state.selected_document_orientations.includes(option)) {
                          return (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          )
                        }
                        return null
                      })}
                    </select>
                  </div>
                </div>
                {this.state.document_orientation_options.map((option) => {
                  if (this.state.selected_document_orientations.includes(option)) {
                    return (
                      <button key={option} id="unique-33731526button_7" className="btn btn-default m-2" onClick={() => this.updateOrientationsSelection(option)}>
                        {option}
                      </button>
                    )
                  }
                  return null
                })}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-83278114div_20">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
