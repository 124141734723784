import React, { Component } from 'react'

import Input from '../Fields/input'
import LinkedProjectInfoBox from './LinkProjectInfoBox'

export default class ProjectSetupS10 extends Component {
  state = {
    loading: false,
    formLoader: false,

    selected_title_id: '',
    selected_title_name: '',
    newTitle: 'Titlebox',
    titleId: '',
    newAttr: '',

    data: {},
    title_options: [],
    additional_title_options: [],

    select_title_mode: true,
    hide_unchecked_rows: false,
    add_attribute_mode: null,
    screen_blocked: false,
  }

  componentDidMount = () => {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    await this.props.fetchProjectTitleboxInfo(formData)
    await this.setState({
      loading: false,
      data: this.props.user.projectInfo.title_data,
      title_options: this.props.user.projectInfo.title_options,
      additional_title_options: this.props.user.projectInfo.additional_title_options,
      screen_blocked: this.props.user.projectInfo.screen_blocked ? this.props.user.projectInfo.screen_blocked : this.state.screen_blocked,
    })

    if (!this.state.title_options.length) {
      return
    }

    let index = -1
    if (this.state.newTitle) {
      index = this.state.title_options.findIndex((option) => option.Name === this.state.newTitle)
    }

    if (index > -1) {
      await this.setState({
        selected_title_id: this.state.title_options[index].id,
        selected_title_name: this.state.title_options[index].Name,
      })
    } else {
      await this.setState({
        selected_title_id: this.state.title_options[0].id,
        selected_title_name: this.state.title_options[0].Name,
      })
    }
  }

  formDataHandler = async (exit, change_page = true) => {
    await this.setState({ formLoader: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('title_data', JSON.stringify(this.state.data))
    formData.append('title_options', JSON.stringify(this.state.title_options))
    formData.append('step', 10)
    await this.props.updateProjectTitleboxInfo(formData)
    await this.setState({ formLoader: false })

    if (!this.props.user.projectInfo.isLast && change_page && !exit) {
      this.props.changeStep(this.props.user.projectSetupStep)
    }

    if ((exit || this.props.user.projectInfo.isLast) && change_page) {
      this.props.closeProjectSetup()
    }
  }

  addTitileTypeOption = async (e) => {
    e.preventDefault()
    await this.formDataHandler(false, false)
    await this.setState({ loading: true })
    const formData = new FormData()
    formData.append('projectId', this.props.editProjectId)
    formData.append('titleTypeId', this.state.titleId)
    formData.append('newTitleType', this.state.newTitle)
    await this.props.addTitileTypeOption(formData)
    await this.setState({ loading: false, select_title_mode: true })
    this.fetchData()
  }

  deleteTitlebox = async (index, deleted_title_id) => {
    let { title_options, data, selected_title_id } = this.state
    if (selected_title_id !== deleted_title_id) {
      title_options.splice(index, 1)
      if (deleted_title_id in data) {
        delete data[deleted_title_id]
      }
      await this.setState({ title_options: title_options, data: data })
    }
  }

  onChange = (e, index, attr_options = []) => {
    if (e.target.value === 'add' && e.target.name === 'attr_name') {
      this.setState({ add_attribute_mode: index })
      return
    }

    let { data, selected_title_id } = this.state

    if (e.target.name === 'attr_name') {
      const attr_index = attr_options.findIndex((option) => option.name === e.target.value)
      if (attr_index > -1) {
        data[selected_title_id][index].attr_name = attr_options[attr_index].name
        data[selected_title_id][index].attr_code = attr_options[attr_index].code
        data[selected_title_id][index].attr_id = attr_options[attr_index].id
      }
    } else {
      data[selected_title_id][index][e.target.name] = e.target.value
    }

    this.setState({ data: data })
  }

  addData = () => {
    let { data, selected_title_id } = this.state
    data[selected_title_id] = [
      {
        id: null,
        keyword: '',
        attr_name: '',
        attr_id: null,
        checked: true,
        custom: true,
      },
    ].concat(data[selected_title_id])
    this.setState({ data: data })
  }

  addNewAttr = async (e, data_index) => {
    e.preventDefault()
    let { title_options, selected_title_id, newAttr, data } = this.state
    const index = title_options.findIndex((title_option) => title_option.id === selected_title_id)
    if (index > -1) {
      title_options[index].attribute_options.push({
        id: null,
        name: newAttr,
      })
    }
    data[selected_title_id][data_index].attr_name = newAttr
    await this.setState({ data: data })
    await this.setState({
      title_options: title_options,
      newAttr: '',
      add_attribute_mode: null,
    })
  }

  onChangeNewAttr = (obj) => {
    this.setState(obj)
  }

  checkIfAllSelected = (data) => {
    const index = data.findIndex((row) => row.checked === false)
    if (index > -1) {
      return false
    }
    return true
  }

  selectAll = () => {
    let { data, selected_title_id } = this.state
    if (this.checkIfAllSelected(data[selected_title_id])) {
      data[selected_title_id].forEach((row) => {
        row.checked = false
      })
    } else {
      data[selected_title_id].forEach((row) => {
        row.checked = true
      })
    }

    this.setState({ data: data })
  }

  renderData = () => {
    let { data, selected_title_id, title_options, hide_unchecked_rows } = this.state
    data = data[selected_title_id]
    let attribute_options = []
    title_options.forEach((title_option) => {
      if (title_option.id === selected_title_id) {
        attribute_options = title_option.attribute_options
      }
    })

    return (
      <table className="table table-striped">
        <thead className="thead-light">
          <tr id="unique-30945547tr_1">
            <th id="unique-28198802th_1">
              <label onClick={() => this.selectAll()} className={`checkbox ${this.checkIfAllSelected(data) ? 'checked' : null}`} />
            </th>
            <th id="unique-29608446th_2"></th>
            <th id="unique-78156325th_3">Keywords</th>
            <th id="unique-96512573th_4">Attribute Name</th>
          </tr>
        </thead>
        <tbody id="unique-26693837tbody_1">
          {data.map((row, index) => {
            if (!row.checked && hide_unchecked_rows) {
              return null
            }
            return (
              <tr key={index} id="unique-10508488tr_2">
                <td id="unique-48601762td_1">
                  <label
                    className={`checkbox ${row.checked ? 'checked' : null}`}
                    onClick={() =>
                      this.onChange(
                        {
                          target: {
                            name: 'checked',
                            value: !row.checked,
                          },
                        },
                        index
                      )
                    }
                  />
                </td>
                <td id="unique-38966432td_2">
                  {row.custom ? (
                    <span className="badge badge-primary d-inline-block ml-2" id="unique-42555258span_1">
                      C
                    </span>
                  ) : null}
                </td>
                <td id="unique-55316273td_3">
                  <Input type="text" value={row.keyword} name="keyword" onChange={this.onChange} path={`${index}`} disabled={!row.checked} id="62972562" />
                </td>
                <td id="unique-10209136td_4">
                  {this.state.add_attribute_mode === index ? (
                    <form className="input-group" onSubmit={(e) => this.addNewAttr(e, index)}>
                      <Input type="text" value={this.state.newAttr} name="newAttr" onChange={this.onChangeNewAttr} disabled={!row.checked} id="62972562" />
                      <div className="input-group-append" id="unique-64141070div_1">
                        <button className="btn btn-primary" id="unique-73702785button_1">
                          <i className="fa fa-plus" id="62972562" />
                        </button>
                      </div>
                    </form>
                  ) : (
                    <select className="form-control" value={row.attr_name} name="attr_name" onChange={(e) => this.onChange(e, index, attribute_options)} disabled={!row.checked}>
                      <option>--Select--</option>
                      {attribute_options.map((option) => {
                        return (
                          <option value={option.name} key={option.name}>
                            {option.name}
                          </option>
                        )
                      })}
                      <option value="add">Add</option>
                    </select>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderForm() {
    let { selected_title_id, selected_title_name, title_options, additional_title_options, hide_unchecked_rows } = this.state
    return (
      <div className="card" id="unique-85368867div_2">
        <div className="clearfix card-header" id="unique-24866113div_3">
          <button type="button" onClick={() => this.formDataHandler(false)} disabled={this.state.formLoader} className="btn btn-primary float-right">
            {this.props.user.projectInfo.isLast ? 'Done' : 'Save & Next'}
          </button>

          <button type="button" onClick={() => this.props.changeStep(this.props.user.projectInfo.prevStep)} className="btn btn-primary float-right mr-2">
            Back
          </button>

          <button type="button" onClick={() => this.formDataHandler(true)} disabled={this.state.formLoader} className="btn btn-primary float-right mr-2">
            Save & Exit
          </button>

          <button type="button" onClick={this.props.closeProjectSetup} className="btn btn-secondary float-right mr-2" id="unique-77981760button_5">
            Cancel
          </button>

          <h3 id="unique-35634856h3_1">Titlebox & Revision Table Configuration {this.state.screen_blocked ? <i className="fa fa-lock ml-2" id="62972562" /> : null}</h3>
        </div>

        <div className={`card-body ${this.state.screen_blocked ? 'disable-click' : ''}`} id="unique-11388624div_4">
          {'group_members' in this.props.user.projectInfo && this.props.user.projectInfo.group_members.length ? (
            <LinkedProjectInfoBox group_members={this.props.user.projectInfo.group_members} />
          ) : null}

          <div className="row" id="unique-97682332div_5">
            <div className="col-3" id="unique-52707346div_6">
              <div className="table-container bg-light" style={{ height: '46rem' }} id="unique-75930249div_7">
                <h4 className="mb-3" id="unique-11443986h4_1">
                  Titlebox Name
                </h4>
                {title_options.map((option, index) => {
                  return (
                    <div key={option.id} value={option.id} className="mb-2 clearfix" id="unique-83672299div_8">
                      <button
                        disabled={option.id === this.state.selected_title_id}
                        title={option.id === this.state.selected_title_id ? "Can't remove selected titlebox." : null}
                        className="float-right btn btn-default"
                        onClick={() => this.deleteTitlebox(index, option.id)}
                      >
                        <i className="fa fa-trash-alt" id="unique-66331081i_3"></i>
                      </button>
                      <label
                        onClick={() =>
                          this.setState({
                            selected_title_id: option.id,
                            selected_title_name: option.Name,
                          })
                        }
                        className={`checkbox mr-2 ${this.state.selected_title_id === option.id ? 'checked' : null}`}
                      />{' '}
                      {option.Name}
                    </div>
                  )
                })}
                <form className="mt-4" onSubmit={this.addTitileTypeOption} id="unique-11437751form_2">
                  <div className="input-group" id="unique-89662304div_9">
                    {!this.state.select_title_mode ? (
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.newTitle}
                        pattern="[a-zA-Z0-9 ]+"
                        title="Special character are not allowed"
                        onChange={(e) => {
                          if (e.target.value !== 'New') {
                            if (e.target.value.includes('Titlebox')) {
                              this.setState({
                                newTitle: e.target.value,
                              })
                            }
                          }
                        }}
                        required={true}
                      />
                    ) : (
                      <select
                        className="form-control"
                        value={this.state.titleId}
                        onChange={(e) => {
                          if (e.target.value === 'New' && this.state.select_title_mode) {
                            this.setState({
                              select_title_mode: false,
                              titleId: '',
                            })
                          } else {
                            if (e.target.value !== 'New') {
                              this.setState({
                                titleId: e.target.value,
                              })
                            }
                          }
                        }}
                        required={true}
                      >
                        <option value="">--Select--</option>
                        {additional_title_options.map((option) => {
                          return (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        })}
                        <option value="New">New</option>
                      </select>
                    )}
                    <div className="input-group-append" id="unique-48454856div_10">
                      <button className="btn btn-primary" type="submit" id="unique-86216674button_7">
                        <i className="fa fa-plus" id="unique-83889219i_4"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {selected_title_id ? (
              <div className="col" id="unique-12100544div_11">
                <div className="clearfix mb-3" id="unique-31998817div_12">
                  <button className="btn btn-primary float-right" onClick={this.addData} id="unique-10825108button_8">
                    Add
                  </button>
                  <span className="d-inline-block mr-3 float-right mt-2" id="unique-99734143span_2">
                    <label
                      onClick={() =>
                        this.setState({
                          hide_unchecked_rows: !hide_unchecked_rows,
                        })
                      }
                      className={`checkbox mr-2 ${hide_unchecked_rows ? 'checked' : null}`}
                    />{' '}
                    Hide Non-Selected
                  </span>
                  <h3 id="unique-40939143h3_2">{selected_title_name}</h3>
                </div>
                {this.renderData()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div className="screen-loader" id="unique-42871903div_13">
          <div id="loading"></div>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}
