import React from 'react'

import { connect } from 'react-redux'
import Input from './Fields/input'
import Select from './Fields/select'
import './Forms.css'
import { createUser, fetchRoles, fetchCompanyAllData, fetchDisciplineAllData } from './../../store/actions'

class CreateUser extends React.Component {
  state = {
    name: '',
    email: '',
    default_role: null,
    discipline: null,
    organization: null,
    availableRoles: [],
    allOrganizations: [],
    allDisciplines: [],
    selected_projects: [],
    loading: false,
    formLoader: false,
    errorMessage: null,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    await this.setState({ loading: true })
    await this.props.fetchClients()
    await this.props.fetchRoles()
    await this.props.fetchCompanyAllData()
    await this.props.fetchDisciplineAllData()
    this.setState({
      allOrganizations: this.props.user.companyAllData,
      allDisciplines: this.props.user.disciplineAllData,
      availableRoles: this.props.user.roles,
      loading: false,
    })
  }

  createUserDataHandler = async (e) => {
    e.preventDefault()
    let { name, email, default_role, discipline, organization, selected_projects } = this.state
    if (!default_role || !organization) {
      this.setState({ errorMessage: 'Please fill out required fields!' })
      return
    }
    await this.setState({ errorMessage: null, formLoader: true })
    let formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('role', default_role.value)
    formData.append('discipline', discipline.value)
    formData.append('company', organization.value)

    selected_projects = selected_projects.map((project) => {
      return project.project_id
    })
    formData.append('projects', selected_projects)

    await this.props.createUser(formData)
    await this.setState({ formLoader: false })
    this.props.history.push('/admin/users')
  }

  onChange = (obj) => {
    this.setState(obj)
  }

  renderEditProfileForm() {
    let project_options = []
    if (this.state.organization) {
      project_options = this.state.allOrganizations.filter((organization) => organization.id === this.state.organization.value)[0].projects
    }

    return (
      <form onSubmit={(event) => this.createUserDataHandler(event)} className="card">
        <div className="card-header bg-light clearfix" id="unique-40531777div_1">
          <button type="submit" disabled={this.state.formLoader ? true : false} className="btn btn-primary float-right" id="unique-11104862button_1">
            Create {this.state.formLoader ? <i className="fa fa-spinner fa-spin" id="unique-22724382i_1"></i> : null}
          </button>
          <h3 className="mb-0" id="unique-95047430h3_1">
            Create User
          </h3>
        </div>
        <div className="card-body" id="unique-10457696div_2">
          {this.state.errorMessage ? (
            <div className="alert alert-danger" id="unique-41724592div_3">
              {this.state.errorMessage}
            </div>
          ) : null}

          <div className="row" id="unique-58262232div_4">
            <div className="col-md-5" id="unique-62195593div_5">
              <div className="form-group" id="unique-20549632div_6">
                <Input label="Name" type={'text'} value={this.state.name} name={'name'} onChange={this.onChange} required={true} id="85170703" />
              </div>

              <div className="form-group" id="unique-59610096div_7">
                <Input label="Email" type={'email'} value={this.state.email} name={'email'} onChange={this.onChange} required={true} id="85170703" />
              </div>

              <div className="form-group" id="unique-72505138div_8">
                <label id="unique-22464751label_1">
                  Role{' '}
                  <span className="small text-muted" id="unique-79790005span_1">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.state.availableRoles}
                  value={this.state.default_role}
                  onChange={(selectedRole) => {
                    this.setState({ default_role: selectedRole })
                  }}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-md-7" id="unique-65801481div_9">
              <div className="form-group" id="unique-10283716div_10">
                <label id="unique-59250984label_2">Select Discipline </label>
                <Select
                  options={this.state.allDisciplines}
                  value={this.state.discipline}
                  onChange={(selectedDiscipline) => {
                    this.setState({ discipline: selectedDiscipline })
                  }}
                  isMulti={false}
                />
              </div>

              <div className="form-group" id="unique-59128709div_11">
                <label id="unique-42022092label_3">
                  Select Organization{' '}
                  <span className="small text-muted" id="unique-10968042span_2">
                    (*)
                  </span>
                </label>
                <Select
                  options={this.state.allOrganizations}
                  value={this.state.organization}
                  onChange={(selectedOrganization) => {
                    this.setState({ organization: selectedOrganization })
                  }}
                  isMulti={false}
                />
              </div>
              <div className="form-group" id="unique-10091742div_12">
                <label id="unique-85632356label_4">Select Projects</label>
                <Select
                  options={project_options}
                  value={this.state.selected_projects}
                  onChange={(selectedProjects) => {
                    this.setState({ selected_projects: selectedProjects })
                  }}
                  isMulti={true}
                  isDisabled={!project_options.length}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="screen-loader" id="unique-82130309div_13">
          <div id="loading" />
        </div>
      )
    }

    return (
      <div className="dashboard admin" id="unique-83286348div_14">
        {this.renderEditProfileForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps, {
  createUser,
  fetchRoles,
  fetchCompanyAllData,
  fetchDisciplineAllData,
})(CreateUser)
