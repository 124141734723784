import React, { Component } from 'react'
import Select from '../Components/Forms/Fields/select'
import Dropzone from 'react-dropzone'

class ConfigureDatasheet extends Component {
  state = {
    errorMessage: null,
    configuring: false,
    fetchingMappingInfo: false,
    progressDropdownToggled: false,
    upload_options: [],
    type: null,
  }

  componentDidMount() {
    this.setState({
      upload_options: this.props.upload_options,
    })
  }

  componentDidUpdate = (props) => {
    if (this.props.upload_options !== props.upload_options) {
      this.setState({ upload_options: this.props.upload_options })
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()
    let { upload_options, type } = this.state
    await this.setState({ configuring: true })

    const formData = new FormData()

    upload_options.forEach((option) => {
      if (option.name === type) {
        let upload_fields = option.fields.filter((_field) => _field.type === 'file')
        let files_length = 0
        if (upload_fields.length) {
          files_length = upload_fields[0].value.length
        }

        upload_fields.forEach(async (field) => {
          if (!field.value.length && field.mandatory) {
            await this.setState({
              errorMessage: `File(s) required!`,
            })
          } else if (field.value.length !== files_length && field.mandatory) {
            await this.setState({
              errorMessage: 'Please upload same number of file(s) for each upload field!',
            })
          } else {
            field.value.forEach((file, index) => {
              formData.append(`${field.name}_${index}`, file)
            })
            field.value = []
          }
        })
      }
    })

    if (!this.state.errorMessage) {
      await this.setState({ upload_options: upload_options })
      formData.append('type', this.state.type)
      formData.append('upload_options', JSON.stringify(upload_options))
      await this.props.uploadDatasheetSetupFile(formData)
      this.props.toggle()
      this.props.displayMessage()
    }
    await this.setState({ configuring: false })
  }

  onChangeHandler = async (e, index) => {
    let { upload_options, type } = this.state
    if (!type) {
      return
    }
    upload_options.forEach((option) => {
      if (option.name === type) {
        option.fields[index].value = e.target.value
      }
    })
    this.setState({ upload_options: upload_options })
  }

  onDropFiles = async (files, index) => {
    if (files[0].size > 100 * 1024 * 1024) {
      this.setState({
        errorMessage: `${files[0].name}: Max allowed file size is 100MB`,
      })
      return
    }

    let { upload_options, type } = this.state
    if (!type) {
      return
    }
    upload_options.forEach((option) => {
      if (option.name === type) {
        option.fields[index].value = files
      }
    })
    this.setState({ upload_options: upload_options })
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  downloadFileTemplate = (check_availibility = false) => {
    let { type, upload_options } = this.state
    let template = null
    upload_options.forEach((option) => {
      if (option.name === type) {
        template = option.template
      }
    })

    if (check_availibility) {
      if (!template) {
        return true
      } else {
        return false
      }
    }

    let buffer = this._base64ToArrayBuffer(template)
    const url = window.URL.createObjectURL(new Blob([buffer]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${type}.xlsx`)
    link.click()
  }

  fetchDatasheetMappingInfo = async () => {
    await this.setState({ fetchingMappingInfo: true })
    const formData = new FormData()
    formData.append('type', this.state.type)
    await this.props.fetchDatasheetMappingInfo(formData)
    await this.setState({
      fetchingMappingInfo: false,
      progressDropdownToggled: true,
    })
  }

  removeFieldFile = (index, _index, __index) => {
    let { upload_options } = this.state

    upload_options[index].fields[_index].value.splice(__index, 1)
    this.setState({ upload_options: upload_options })
  }

  render() {
    let { upload_options } = this.state
    return (
      <form onSubmit={this.onSubmit} className="modal" id="unique-33164764form_1">
        <div className="modal-dialog modal-dialog-centered modal-lg" id="unique-72689233div_1">
          <div className="modal-content" id="unique-19004138div_2">
            <div className="modal-header" id="unique-28570164div_3">
              <button type="button" className="close" onClick={this.props.toggle} id="unique-52269958button_1">
                <span aria-hidden="true" id="unique-64917446span_1">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body" id="unique-74056728div_4">
              {this.state.errorMessage ? (
                <div className="alert alert-danger mb-3 alert-dissmiss" id="unique-18705387div_5">
                  {this.state.errorMessage}
                  <button className="close" id="unique-99011364button_2" onClick={() => this.setState({ errorMessage: '' })}>
                    &times;
                  </button>
                </div>
              ) : null}

              <div className="row" id="unique-17175854div_6">
                <div className="col-6" id="unique-18010892div_7">
                  <div className="mb-3" id="unique-80444586div_8">
                    <label id="unique-80863840label_1">
                      Type{' '}
                      <span className="small text-muted" id="unique-11210937span_2">
                        (Mandatory *)
                      </span>
                    </label>
                    <Select
                      options={upload_options.map((option) => {
                        return {
                          label: option.name,
                          value: option.name,
                        }
                      })}
                      onChange={(selectedType) => {
                        this.setState({
                          type: selectedType ? selectedType.value : null,
                        })
                      }}
                      value={{ label: this.state.type, value: this.state.type }}
                      isMulti={false}
                    />
                  </div>

                  {this.state.type
                    ? upload_options.map((option) => {
                        if (option.name === this.state.type) {
                          return option.fields.map((field, index) => {
                            if (field.type !== 'select') {
                              return null
                            }

                            let options = []
                            if (field.related_parent_field) {
                              const index = option.fields.findIndex((_field) => _field.name === field.related_parent_field)
                              if (index > -1 && option.fields[index].value in field.options) {
                                options = field.options[option.fields[index].value]
                              }
                            } else {
                              options = field.options
                            }

                            if (options.length) {
                              return (
                                <div key={index} className="mb-3" id="unique-28834762div_9">
                                  <label id="unique-10709235label_2">
                                    {field.display_name}{' '}
                                    {field.mandatory ? (
                                      <span className="small text-muted" id="unique-46323584span_3">
                                        (Mandatory *)
                                      </span>
                                    ) : null}
                                  </label>
                                  <Select
                                    options={options.map((option) => {
                                      return {
                                        label: option,
                                        value: option,
                                      }
                                    })}
                                    onChange={(selectedOption) => {
                                      let e = {
                                        target: {
                                          value: selectedOption ? selectedOption.value : null,
                                        },
                                      }
                                      this.onChangeHandler(e, index)
                                    }}
                                    value={{ label: field.value, value: field.value }}
                                    isMulti={false}
                                  />
                                </div>
                              )
                            }
                            return null
                          })
                        }
                        return null
                      })
                    : null}
                </div>
                <div className="col-6" id="unique-82285886div_10">
                  <div style={{ height: '400px', overflowY: 'auto' }} id="unique-10712291div_11">
                    {this.state.type
                      ? upload_options.map((option, index) => {
                          if (option.name === this.state.type) {
                            return option.fields.map((field, _index) => {
                              if (field.type !== 'file') {
                                return null
                              }

                              return (
                                <div key={_index} className="mb-3" id="unique-49640691div_12">
                                  {!['Upload Template', 'Upload Index'].includes(this.state.type) ? (
                                    <div className="dropdown d-inline-block mb-2" id="unique-83634663div_13">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={this.state.fetchingMappingInfo}
                                        id="unique-57330011button_3"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          if (!this.state.progressDropdownToggled) {
                                            this.fetchDatasheetMappingInfo()
                                          } else {
                                            this.setState({
                                              progressDropdownToggled: false,
                                            })
                                          }
                                        }}
                                      >
                                        Status{' '}
                                        {!this.state.progressDropdownToggled ? (
                                          <i className="fa fa-caret-down" id="unique-42326626i_1" />
                                        ) : (
                                          <i className="fa fa-caret-up" id="unique-14031624i_2" />
                                        )}
                                        {this.state.fetchingMappingInfo ? <i className="fa fa-spinner fa-spin ml-2" id="unique-10402752i_3" /> : null}
                                      </button>
                                      {this.state.progressDropdownToggled ? (
                                        <div id="unique-77590533div_14" className="dropdown-menu dropdown-menu-right p-2" onClick={(e) => e.stopPropagation()}>
                                          <div
                                            className="table-container p-0"
                                            style={{
                                              height: 300,
                                            }}
                                            id="unique-23665121div_15"
                                          >
                                            <table className="table table-striped">
                                              <thead className="thead-light">
                                                <tr id="unique-24979114tr_1">
                                                  <th id="unique-10217537th_1">Name</th>
                                                  <th id="unique-76839566th_2">Discipline</th>
                                                  <th id="unique-72770886th_3">Category</th>
                                                  <th id="unique-84184783th_4">Uploaded Date</th>
                                                  <th id="unique-30001416th_5">Uploaded Status</th>
                                                  <th id="unique-45244651th_6">Reason</th>
                                                </tr>
                                              </thead>
                                              <tbody id="unique-10667199tbody_1">
                                                {this.props.user.datasheetMappingOptions.map((option, index) => {
                                                  return (
                                                    <tr key={index} id="unique-10435877tr_2">
                                                      <td id="unique-65618742td_1">{option.Name}</td>
                                                      <td id="unique-19732402td_2">{option.Discipline}</td>
                                                      <td id="unique-41321989td_3">{option.Category}</td>
                                                      <td id="unique-10201330td_4">{option['Uploaded date']}</td>
                                                      <td id="unique-33556117td_5">{option['Upload status']}</td>
                                                      <td id="unique-89321623td_6">{option['Reason']}</td>
                                                    </tr>
                                                  )
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}

                                  <Dropzone id="unique-55848735Dropzone_1" onDrop={(files) => this.onDropFiles(files, _index)}>
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()} id="unique-17716841div_16">
                                        <input {...getInputProps()} accept={field.extension} multiple={field.multi} id="unique-55846208input_1" />
                                        <button className="btn btn-primary" type="button" id="unique-35102367button_4">
                                          {field.display_name} <i className="fas fa-cloud-upload-alt" id="unique-22590148i_4"></i>
                                        </button>
                                      </div>
                                    )}
                                  </Dropzone>

                                  {field.value.map((file, __index) => {
                                    return (
                                      <span
                                        title={file.name}
                                        className="d-inline-block bg-light p-2 m-1 border rounded text-truncate d-inline-block"
                                        key={__index}
                                        style={{
                                          maxWidth: 200,
                                        }}
                                      >
                                        <i
                                          className="d-inline-block far fa-trash-alt cursor-pointer text-danger mr-2"
                                          onClick={() => this.removeFieldFile(index, _index, __index)}
                                        />
                                        {field.extension === 'text/plain' ? <i className="fa fa-file text-secondary" /> : <i className="fa fa-file-excel text-success" />}{' '}
                                        {file.name}
                                      </span>
                                    )
                                  })}
                                </div>
                              )
                            })
                          }
                          return null
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" id="unique-66959448div_17">
              <div className="modal-footer" id="unique-83436793div_18">
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={this.downloadFileTemplate(true)}
                  id="unique-26651542button_5"
                  onClick={() => this.downloadFileTemplate(false)}
                >
                  Download File Template
                </button>

                <button type="submit" value="Submit" className="btn btn-primary" id="unique-45014347button_6">
                  {this.state.configuring ? 'Uploading ... ' : 'Upload'}
                </button>

                <button className="btn btn-default" onClick={this.props.toggle} id="unique-48000252button_7">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default ConfigureDatasheet
