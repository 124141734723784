let ACCESS_TOKEN = null
let EMAIL_ID = null
let USERNAME = null
let USER_ID = null

export const setAccessToken = (token) => {
  ACCESS_TOKEN = token
}

export const getAccessToken = () => {
  return ACCESS_TOKEN
}

export const setEmailID = (emailId) => {
  EMAIL_ID = emailId
}

export const getEmailId = () => {
  return EMAIL_ID
}

export const setUsername = (name) => {
  USERNAME = name
}

export const getUsername = () => {
  return USERNAME
}

export const setUserId = (userId) => {
  USER_ID = userId
}

export const getUserId = () => {
  return USER_ID
}
